import axios from './defaultAxios';

export function signUpProvider(values) {
  return axios.post('/provider', values);
}

export function checkDupilcateProviderId(id) {
  return axios.get('/provider/signup', {
    params: {
      id: id.trim()
    }
  });
}

// 공급자 로그인
export function signInProvider(params) {
  return axios.get('/provider/signin', {
    params
  });
}

// 공급자 정보 가져오기
export function getProvider(providerId) {
  return axios.get(`/provider/${providerId}`);
}

// 공급자 정보 수정
export function putProvider(values) {
  return axios.put(`/provider/${values.id}`, values);
}

// 공급자 페이지 정보 가져오기
export function getProviderHome(providerId) {
  return axios.get(`/provider/${providerId}/home`);
}

// 공급자 상품 가져오기
export function getProviderProduct(params) {
  return axios.get(`/provider/${params.providerId}/products`, {
    params
  });
}

// 공급자 1p 리스트 가져오기
export function getProviderOneExperience(params) {
  return axios.get(`/provider/${params.providerId}/1exps`, {
    params
  });
}

// 공급자 이름으로 검색
export function getSearchProvider(keyword) {
  return axios.get('/provider/', {
    params: {
      keyword
    }
  });
}

export function getProviderOneProduct(params) {
  return axios.get(`/provider/${params.providerId}/1products`, {
    params
  });
}

// 공급자별 발굴(상품) 리스트 조회
export function getProviderDiscovers(params) {
  return axios.get(`/provider/${params.providerId}/discovers`, {
    params
  });
}

export function getProviderOneStore(params) {
  return axios.get(`/provider/${params.providerId}/1stores`, {
    params
  });
}

export function getProviderSalesList(params) {
  return axios.get(`/provider/${params.providerId}/sales`, {
    params
  });
}

// 공급자별 배송정보 조회
export function getProviderDeliveryList(params) {
  return axios.get(`/provider/${params.providerId}/delivery`, {
    params
  });
}

// 공급자 비밀번호 변경
export function resetPassword(values) {
  return axios.post('provider/pwd', values);
}

// 공급자 임시 비밀번호로 변경 후 SMS 전송
export function findPassword(values) {
  return axios.post('provider/pwd/temp', values);
}
