import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getSearchedDiscover } from 'api/discover';

import Button from 'components/commons/Button';
// import ModalDialog from 'components/modal/ModalDialog';
import CommonModal from 'components/modal/CommonModal';

import './SearchProductModal.scss';

export default class SearchProductModal extends Component {
  static propTypes = {
    isEdit: PropTypes.bool,
    isOpen: PropTypes.bool,
    modalType: PropTypes.string,
    onClickNew: PropTypes.func,
    onSelect: PropTypes.func,
    onToggle: PropTypes.func
  };

  static defaultProps = {
    isEdit: false,
    isOpen: false,
    modalType: 'link',
    onClickNew: () => {},
    onSelect: () => {},
    onToggle: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      isSearched: false,
      isError: false,
      keyword: '',
      productList: []
    };

    this.lastKey = 10;
  }

  handleChangeValue = (e) => {
    this.setState({
      keyword: e.target.value
    });
  };

  handleKeyDownValue = (e) => {
    if (!e.keyCode || e.keyCode !== 13) {
      return;
    }

    this.handleSearchProvider(e);

    e.preventDefault();
  };

  handleSearchProvider = async (e) => {
    const { keyword } = this.state;

    if (keyword.length < 2) {
      alert('두글자 이상 검색해주세요');

      this.setState({
        isSearched: false
      });

      return;
    }

    try {
      const response = await getSearchedDiscover(keyword);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`get product api fail - ${JSON.stringify(response)}`);
      }

      const { data } = response.data;

      this.setState({
        isSearched: true,
        productList: data
      });

      e.preventDefault();
    } catch (e) {
      this.setState({
        isSearched: true,
        isError: true
      });
    }
  };

  handleSelectProduct = (productId, productName) => {
    const { onSelect } = this.props;

    if (!productId) {
      onSelect('name', productName);

      this.handleCloseModal();

      return;
    }

    onSelect('id', productId);

    this.handleCloseModal();
  };

  handleCloseModal = () => {
    const { onToggle } = this.props;

    this.setState({
      isError: false,
      isSearched: false,
      keyword: '',
      productList: []
    });

    onToggle();
  };

  handleClickNew = () => {
    const { onClickNew } = this.props;
    const { keyword } = this.state;

    onClickNew(keyword);

    this.handleCloseModal();
  };

  drawDefultMessage = () => {
    const { isEdit } = this.props;
    let text = '등록할';

    if (isEdit) {
      text = '수정할';
    }

    return (
      <li className="search-list-text">{`${text} 상품을 검색해주세요.`}</li>
    );
  };

  drawEmptyList = () => {
    return <li className="search-list-text">검색 결과가 없습니다.</li>;
  };

  drawErrorMessage = () => {
    return (
      <li className="search-list-text">
        검색 도중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.
      </li>
    );
  };

  drawProductItem = (item) => {
    if (!item) {
      return;
    }

    const { modalType } = this.props;
    const {
      discovererId,
      id,
      productId,
      productName,
      nowStatusProcess,
      providerName
    } = item;

    let statusText = '발굴 대기';
    let statusClassName = 'wait';
    let statusLink = `/discover/${id}`;
    let ListElement = null;

    if (nowStatusProcess === 1) {
      statusText = '발굴 진행';
      statusClassName = 'audit';
    }

    if (nowStatusProcess === 2) {
      statusText = '발굴 완료';
      statusClassName = 'success';
      statusLink = `/store/${discovererId}/${productId}`;
    }

    if (nowStatusProcess === 3) {
      statusText = '발굴 실패';
      statusClassName = 'fail';
    }

    const Element = (
      <Fragment>
        <span className="search-list-item-text">{productName}</span>
        <span className="search-list-item-text">{providerName}</span>
        <span className="search-list-item-text">
          <span className={`search-list-item-status ${statusClassName}`}>
            {statusText}
          </span>
        </span>
      </Fragment>
    );

    if (modalType === 'link') {
      ListElement = <Link to={statusLink}>{Element}</Link>;
    }

    if (modalType === 'button') {
      ListElement = (
        <button
          type="button"
          onClick={() => {
            this.handleSelectProduct(productId, productName);
          }}
        >
          {Element}
        </button>
      );
    }

    return (
      <li className="search-list-item" key={productId}>
        {ListElement}
      </li>
    );
  };

  drawList = () => {
    const { isError, isSearched, productList } = this.state;

    if (!isSearched) {
      return this.drawDefultMessage();
    }

    if (isSearched && isError) {
      return this.drawErrorMessage();
    }

    if (isSearched && productList.length <= 0) {
      return this.drawEmptyList();
    }

    const list = productList.map((item) => {
      return this.drawProductItem(item);
    });

    return list;
  };

  render() {
    const { isOpen } = this.props;
    const { isError, isSearched, keyword } = this.state;

    return (
      <CommonModal
        className="modal-control discover-register-modal"
        isOpen={isOpen}
        onToggle={this.handleCloseModal}
        size="md"
        headerText="상품 검색"
      >
        <div className="form-group mt-4">
          <div className="form-control">
            <input
              className="w-100"
              placeholder="상품명"
              value={keyword}
              onChange={this.handleChangeValue}
              onKeyDown={this.handleKeyDownValue}
            />
            <Button
              buttonText="검색"
              buttonType="button"
              className="form-button btn-primary"
              onClick={this.handleSearchProvider}
            />
          </div>
        </div>
        <ul className="search-list-header">
          <li>상품명</li>
          <li>생산자/공급자</li>
          <li>발굴상태</li>
        </ul>
        <ul className="search-list body">{this.drawList()}</ul>
        <div className="search-list-new">
          {isSearched && !isError && (
            <Fragment>
              <div className="modal-text">
                검색 결과가 없을 경우 “신규등록” 버튼을 눌러주세요
              </div>
              <Button
                buttonText="신규등록"
                buttonType="button"
                className="modal-button modal-new"
                color="primary"
                size="sm"
                onClick={this.handleClickNew}
              />
            </Fragment>
          )}
        </div>
      </CommonModal>
    );
  }
}
