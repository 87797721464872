/* eslint-disable no-console */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useInfiniteQuery } from 'react-query';
import InfiniteList from 'shared/InfiniteList';
import fetchData from 'api/fetchData';
import { getAllDiscover } from 'api/discover';

import ListTitle from 'components/commons/ListTitle';
import DiscoverCard from 'components/commons/DiscoverCard';

import './DiscoverList.scss';

const STALE_TIME = 1000 * 60 * 10;
const ITEMS_PER_PAGE = 20;

const getDiscovers = async (
  key,
  { searchType, searchKey, itemsPerPage = ITEMS_PER_PAGE },
  LastEvaluatedKey
) => {
  try {
    // console.log({ LastEvaluatedKey });
    const params = {
      pageCount: itemsPerPage,
      lastKey: LastEvaluatedKey
    };

    const data = await fetchData(getAllDiscover, params);
    // console.log({ data });

    const Items = data.Items.filter((item) => {
      return (
        item.nowStatusProcess === 0 ||
        item.nowStatusProcess === 1 ||
        item.nowStatusProcess === -1
      );
    });

    return { Items, LastEvaluatedKey: data.lastKey };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

function DiscoverList({
  searchType = 'all',
  searchKey = '',
  title,
  subTitle,
  listClassName
}) {
  const query = useInfiniteQuery(
    ['discovers', { searchType, searchKey }],
    getDiscovers,
    {
      getFetchMore: (lastPage) => {
        // console.log({ lastPage });
        return lastPage.LastEvaluatedKey;
      },
      staleTime: STALE_TIME
    }
  );

  return (
    <Fragment>
      <ListTitle
        title={title}
        subTitle={subTitle}
        listClassName={listClassName}
      />
      <InfiniteList {...query}>
        {(item) => {
          return (
            <Link key={item.id} to={`/discover/${item.id}`}>
              <DiscoverCard {...item} />
            </Link>
          );
        }}
      </InfiniteList>
    </Fragment>
  );
}

export default DiscoverList;
