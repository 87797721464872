import React from 'react';
import Slider from 'react-slick';

import CDN_URL from 'components/commons/globalUrl';

import './TutorialForm.scss';

const tutorialImgPathList = [];

for (let i = 0; i < 6; i += 1) {
  tutorialImgPathList[i] = `${CDN_URL}/from/From_Tutorial2-0${i + 1}.png`;
}

function TutorialForm({ SETTING }) {
  return (
    <div className="view-detail tutorial-form ml-auto mr-auto">
      <div className="mb-4">
        <Slider className="tutorial-slide" {...SETTING}>
          {tutorialImgPathList.map((tutorialImage) => {
            return (
              <div key={tutorialImage}>
                <div
                  className="tutorial-slide-item"
                  style={{
                    backgroundImage: `url("${tutorialImage}")`
                  }}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default TutorialForm;
