import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Loader from 'components/commons/Loader';
import EmptyList from 'components/commons/EmptyList';
import ShippingDetail from './ShippingDetail2';

import './ProviderDeliveryProductsList.scss';

function ShippingList({
  onGet,
  hasLastKey,
  deliveryList,
  onOpenInputModal,
  isLoading,
  dataLoading
}) {
  const haveDeliveryList = deliveryList && deliveryList.length > 0;

  if (isLoading) {
    return <Loader />;
  }

  if (!haveDeliveryList) {
    return <EmptyList className="mt-5" />;
  }

  return (
    <div className="notify-list ml-auto mr-auto">
      <InfiniteScroll
        pageStart={0}
        loadMore={onGet}
        hasMore={!dataLoading && hasLastKey}
      >
        {deliveryList.map((delivery, index) => {
          if (delivery === null || delivery === undefined) {
            return null;
          }

          const {
            createDate,
            deliverer,
            paymentLogsId,
            order,
            product,
            recipient,
            status,
            buyer,
            payType
          } = delivery;
          const {
            id: deliveryCode,
            name: deliveryName,
            trackingNumber: invoiceNumber
          } = deliverer || {};
          const {
            quantity: amount,
            optionName: productOption,
            option: oldProductOption
          } = order || {};
          const { name: buyerName, mobile: buyerMobile } = buyer || {};
          const {
            name,
            productName,
            flagDelivery,
            officialProductImgPath
          } = product;
          const {
            addr: receiverAddr,
            addrSub: receiverAddrSub,
            zipcode: receiverZipcode,
            name: receiverName,
            mobile: receiverMobile,
            reserveDate: receiverReserveDate,
            deliveryMessage: receiverDeliveryMessage
          } = recipient || {};

          const flagCancelPayment = status === 'canceled';

          return (
            <ShippingDetail
              createDate={createDate}
              deliveryNumber={invoiceNumber}
              deliveryName={deliveryName}
              index={index}
              isShipping={!!deliveryCode}
              isPersonShipping={deliveryCode === '000'}
              key={paymentLogsId}
              onOpenInputModal={onOpenInputModal}
              paymentLogsId={paymentLogsId}
              productAmount={amount}
              productName={productName || name}
              receiverAddr={receiverAddr}
              receiverAddrSub={receiverAddrSub}
              receiverZipcode={receiverZipcode}
              receiverName={receiverName}
              receiverMobile={receiverMobile}
              receiverReserveDate={receiverReserveDate}
              receiverDeliveryMessage={receiverDeliveryMessage}
              productOption={productOption || oldProductOption}
              flagCancelPayment={flagCancelPayment}
              flagDelivery={flagDelivery === 1}
              officialProductImgPath={officialProductImgPath}
              buyerName={buyerName}
              buyerMobile={buyerMobile}
              payType={payType}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

export default ShippingList;
