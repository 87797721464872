import { combineReducers } from 'redux';
import { penderReducer } from 'redux-pender';
import { reducer as formReducer } from 'redux-form';

import ui from './ui';
import user from './user';
import experience from './experience';
import store from './store';
import discover from './discover';
import oneProduct from './oneProduct';
import product from './product';
import gift from './gift';
import provider from './provider';
import banner from './banner';

export default combineReducers({
  ui,
  user,
  store,
  discover,
  oneProduct,
  product,
  gift,
  provider,
  experience,
  banner,
  form: formReducer,
  pender: penderReducer
});
