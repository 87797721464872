import React, { Component } from 'react';
import TutorialIntroForm from 'components/tutorial/TutorialIntroForm';
import withUi from 'lib/hoc/withUi';

class TutorialIntroContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.UiAction.setHeader('튜토리얼');
  }

  render() {
    return <TutorialIntroForm />;
  }
}

export default withUi(TutorialIntroContainer);
