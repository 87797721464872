import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import ModalDialog from 'components/modal/ModalDialog';

import sentry from 'lib/sentry';
import { requestCertification } from 'lib/utils/iamportUtils';

import CommonModal from 'components/modal/CommonModal';

// import Button from 'components/commons/Button';

import './AdultModal.scss';

export default class AdultModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onCheck: PropTypes.func,
    onToggle: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    onCheck: () => { },
    onToggle: () => { }
  };

  handleClick = async () => {
    const { onCheck, onToggle } = this.props;

    try {
      onToggle();

      const response = await requestCertification();

      onCheck(response.success);
    } catch (e) {
      let errorMessage = '인증에 실패하였습니다.';

      if (e.error_msg) {
        errorMessage = e.error_msg;
      }

      sentry.captureException(`성인인증 - ${errorMessage}`);

      onCheck(e.success);
    }
  };

  render() {
    const { isOpen, onToggle } = this.props;

    return (
      <CommonModal
        className="adult-mdoal"
        contentText={`이 상품은\n성인 인증이 필요한 상품입니다`}
        headerText="성인 인증"
        headerImage="/images/header-image/alert-modal-header-image-sungyoung-adult.svg"
        isOpen={isOpen}
        onToggle={onToggle}
        okButtonText="성인 인증"
        onClickOk={this.handleClick}
      />
    );
  }
}
