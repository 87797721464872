import React from 'react';
import classNames from 'classnames';

import { getImagePath } from 'lib/utils/imageUtils';

import './OverlapPictureBox.scss';

function OverlapPictureBox({ className, mainImage, subImage }) {
  return (
    <div className={classNames('overlap-picture-image', className)}>
      <img
        className="overlap-picture-main-image"
        src={getImagePath(mainImage.src, 250, 250)}
        alt={mainImage.alt}
      />
      <img
        className={classNames(
          'overlap-picture-sub-image',
          subImage.border && `sub-border-${subImage.border}`,
          {
            'no-image-border': !subImage.border
          }
        )}
        src={getImagePath(subImage.src, 50, 50)}
        alt={subImage.alt}
      />
    </div>
  );
}

export default OverlapPictureBox;
