/* eslint-disable no-console */
import { useState, useEffect } from 'react';

import useModal from 'hooks/useModal';

import sentry from 'lib/sentry';

const isDev = process.env.NODE_ENV === 'development'; // 환경이 개발모드인지 확인

function useFetch(func, params) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { message, setMessage, isOpen, handleToggle } = useModal();

  useEffect(
    () => {
      if (!isLoading) {
        fetchData(func, params);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  async function fetchData(func, params) {
    try {
      const paramArray = Array.isArray(params) ? [...params] : [params];
      setIsLoading(true);

      const response = await func(...paramArray);

      if (!response || !response.data || !response.data.success) {
        console.log({ response });
        throw new Error(`Function: ${func} - Params: ${params}`);
      }
      if (isDev) {
        console.log({ func });
        console.log({ response });
      }
      setData({ ...response.data.data });

      setIsLoading(false);
    } catch (e) {
      sentry.captureException(e.message);

      setMessage('데이터를 불러올 수 없습니다.\n 잠시 후 다시 시도해주세요.');
      handleToggle();
      setIsLoading(false);
    }
  }

  return { data, isLoading, message, isOpen, handleToggle };
}

export default useFetch;
