import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Linkify from 'react-linkify';
import moment from 'moment';
import 'moment/locale/ko';

import withUser from 'lib/hoc/withUser';

import usePostCard from './usePostCard';
import useModal from 'hooks/useModal';
import ShareModal from 'components/modal/ShareModal';
// import AlertModal from 'components/modal/AlertModal';

import { truncate } from 'lib/utils/utils';
import { getImagePath, getProfileImage } from 'lib/utils/imageUtils';

import ImageZoom from 'components/commons/ImageZoom';

import {
  PostCardWrapper,
  PostProfile,
  PostShare,
  PostImageList,
  PostText,
  PostStats,
  PostActions
} from './PostCardStyle';

const sliderSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  lazyLoad: true,
  autoplay: false,
  autoplaySpeed: 5000
  // eslint-disable-next-line react/display-name
  // customPaging: () => <div className="custom-slick-dot" />
};

moment.locale('ko');

function PostCard({
  post,
  isLoggedIn,
  user,
  isProvider,
  isReply = false,
  isDetail = false
}) {
  // console.log({ post });
  const {
    showDetail,
    canEdit,
    liked,
    likeCount,
    onShowDetail,
    onDelete,
    onLike,
    isLoading,
    isDeleting,
    isDeleted
  } = usePostCard(post, { isLoggedIn, user, isProvider, isReply, isDetail });

  const {
    isOpen: isOpenShareModal,
    handleToggle: toggleShareModal
  } = useModal();

  // const {
  //   title: alertTitle,
  //   isOpen: isOpenAlertModal,
  //   handleToggle: toggleAlertModal
  // } = useModal('삭제되었습니다');

  if (!post || !post.comment) return null;

  const {
    storeId,
    productId,
    product = {},
    authorId,
    author,
    ownerId,
    owner,
    depth,
    commentsId,
    comment,
    attachImageList,
    createDate,
    replyCount = ''
  } = post;

  const postLink = `/post/${ownerId || storeId}/${
    productId || ownerId
  }/${commentsId}`;
  // console.log({ postLink });

  const metaData = {
    type: 'post',
    post,
    path: postLink,
    referrer: user.id
  };

  const imageList =
    attachImageList && attachImageList.length > 0
      ? attachImageList
      : !isDetail
      ? productId
        ? product.imagePaths
        : null
      : null;
  // console.log({ imageList });

  const {
    name: authorName = '',
    imagePath: authorImage,
    type: authorType = 'store'
  } = author || {};
  const authorLink = `/${authorType}/${authorId}`;
  // console.log({ authorLink });

  const { name: ownerName = '', type: ownerType = 'store' } = owner || {};
  const ownerLink = productId
    ? `/${ownerType}/${ownerId || storeId}/${productId}`
    : `/${ownerType}/${ownerId || storeId}`;
  // console.log({ ownerLink });

  const imageSize = isDetail ? 1200 : 600;

  return (
    <PostCardWrapper isReply={isReply || depth === 1}>
      <PostProfile>
        {(isReply || depth === 1) && <div className="reply-icon"></div>}
        <Link to={authorLink}>
          <img
            className={authorType === 'provider' ? 'provider' : ''}
            src={getProfileImage(authorImage)}
            alt="사용자 이미지"
          />
        </Link>
        <p>{authorName}</p>
        {(!isReply || depth === 0) && (
          <Fragment>
            <Link to={ownerLink} className="text-link">{` To: ${ownerName} ${
              productId ? ' @' + product.name : ''
            }`}</Link>
            {/* {productId && (
              <Link to={ownerLink}>
                {` X ${
                  !productId
                    ? ''
                    : product.name.length < 200
                    ? product.name
                    : product.name.substring(0, 20).concat('...')
                }`}
              </Link>
            )} */}
          </Fragment>
        )}
      </PostProfile>
      <PostImageList isDetail={isDetail} {...sliderSettings}>
        {imageList &&
          imageList.map((image) => {
            const imgSrc = `${getImagePath(
              image,
              isDetail ? 1200 : 600,
              isDetail ? null : 600
            )}`;
            return isDetail ? (
              <ImageZoom key={imgSrc} src={imgSrc} alt="사진" />
            ) : (
              <Link key={imgSrc} to={postLink}>
                <img src={imgSrc} alt="사진" />
              </Link>
            );
          })}
      </PostImageList>
      <PostStats>
        <div className="stat-with-icon" onClick={onLike}>
          {isLoading ? (
            <img
              src="/images/comment_like.svg"
              alt="좋아요"
              className="loading"
            />
          ) : liked ? (
            <img src="/images/comment_like.svg" alt="좋아요" />
          ) : (
            <img src="/images/like_heart.svg" alt="좋아요" />
          )}
          <span>{likeCount}</span>
        </div>
        {!isReply && (
          <Link to={postLink}>
            <div className="stat-with-icon speech">
              <img src="/images/speech_bubble.svg" alt="댓글" />
              <span>{replyCount}</span>
            </div>
          </Link>
        )}
        <button
          className="stat-with-icon share"
          type="button"
          disabled={isLoading}
          onClick={toggleShareModal}
        >
          <img src="/images/share-icon.svg" alt="공유하기" />
        </button>
      </PostStats>

      <PostText>
        {!showDetail && comment.length > 100
          ? truncate(comment, 100)
          : comment.split('\n').map((line, index) => {
              // if (!line) return null;
              return (
                <Linkify
                  className="product-text-introduce"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  properties={{ target: '_blank' }}
                >
                  {line}
                  <br />
                </Linkify>
              );
            })}
        {comment.length > 100 && (
          <button type="button" onClick={onShowDetail}>
            {showDetail ? ' 접기...' : ' 펼치기...'}
          </button>
        )}
      </PostText>
      {/* <PostStats>{`${moment(createDate).fromNow()}`}</PostStats> */}
      <PostActions>
        <span>{`${moment(createDate).fromNow()}`}</span>
        <Link to={{ pathname: '/post/edit', state: { mode: 'reply', post } }}>
          <button
            type="button"
            onClick={() => {
              // console.log('답글버튼');
            }}
          >
            답글달기
          </button>
        </Link>

        {canEdit && (
          <Fragment>
            <Link
              to={{ pathname: '/post/edit', state: { mode: 'edit', post } }}
            >
              <button
                type="button"
                onClick={() => {
                  // console.log('수정버튼');
                }}
              >
                수정
              </button>
            </Link>
            <button type="button" disabled={isDeleting} onClick={onDelete}>
              삭제
            </button>
          </Fragment>
        )}
        {!isDetail && (
          <Link to={postLink}>
            <button
              className="detail"
              type="button"
              onClick={() => {
                // console.log('자세히버튼');
              }}
            >
              자세히
            </button>
          </Link>
        )}
      </PostActions>
      {/* <PostReply></PostReply> */}
      <ShareModal
        isOpen={isOpenShareModal}
        onToggle={toggleShareModal}
        metaData={metaData}
      />
      {/* <AlertModal
        isOpen={isOpenAlertModal}
        headerText={alertTitle}
        // contentText={alertMessage}
        onClick={toggleAlertModal}
        onToggle={toggleAlertModal}
      /> */}
    </PostCardWrapper>
  );
}
export default withUser(PostCard);
