/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, Fragment } from 'react';
import CommonModal from 'components/modal/CommonModal';
import sentry from 'lib/sentry';

import shareFacebook from 'lib/facebook/shareFacebook';
import shareKakao from 'lib/kakao/shareKakao';
import useModal from 'hooks/useModal';
import AlertModal from 'components/modal/AlertModal';
import { getImagePath } from 'lib/utils/imageUtils';
import { getHost } from 'lib/utils/envUtils';

import CDN_URL from 'components/commons/globalUrl';

import './ShareModal.scss';

const ERR_MSG =
  '오류가 발생하여 공유를 할 수 없습니다.\n주소를 복사 후 공유해 주세요.';

function ShareModal({ isOpen, onToggle, metaData }) {
  const [isCopied, setIsCopied] = useState(false);
  const {
    message: errorMessage,
    setMessage: setErrorMessage,
    isOpen: isOpenErrorModal,
    handleToggle: toggleErrorModal
  } = useModal();

  // useEffect(() => {
  //   setIsCopied(false);
  // }, []);

  const getUrl = () => {
    const { path, referrer } = metaData;

    let url = getHost();

    if (path) {
      url += path;
    }

    if (referrer) {
      url += `?ref=${referrer}`;
    }
    // console.log({ isCopied });
    // console.log({ url });

    return url;
  };

  const handleClickShareFacebook = () => {
    try {
      shareFacebook(getUrl());

      onToggle();
    } catch (e) {
      sentry.captureException(e);
      console.log({ e });
      setErrorMessage(ERR_MSG);
      toggleErrorModal();
    }
  };

  const handleClickShareKakao = () => {
    const { oneProduct, product, post, discover, type } = metaData || {};

    if (!type) {
      setErrorMessage(ERR_MSG);
      toggleErrorModal();

      return;
    }

    try {
      const {
        productName,
        providerName,
        officialProductImgPath,
        recommendation
      } = product || {};

      const { productImgPath, storeName, storeRecommendation } =
        oneProduct || {};

      let kakaoParams = null;
      let description = '';
      let link = getUrl();

      if (type === 'oneProduct') {
        description =
          storeRecommendation && typeof storeRecommendation === 'string'
            ? storeRecommendation.substring(0, 200).concat('...')
            : storeRecommendation && storeRecommendation.comment
            ? storeRecommendation.comment.substring(0, 200).concat('...')
            : `${storeName || '프롬'}산타님이 전합니다.`;

        kakaoParams = {
          buttonText: `${storeName || '프롬'}님의 단골상품 보기`, // '선물과 기부를 동시에! 찐산타되기',
          description,
          imageUrl:
            getImagePath(officialProductImgPath, 250, 250) ||
            `${CDN_URL}/logo.png`,
          link,
          title: `${storeName || '프롬'}님이 전하는 ${productName || '상품'}`
        };
      } else if (type === 'discover') {
        const {
          discovererName,
          productName,
          providerName,
          recommendation,
          productImgPathList
        } = discover || {};

        description = recommendation;
        kakaoParams = {
          buttonText: `${discovererName || '프롬'}님이 발굴신청한 상품 보기`,
          description,
          imageUrl:
            getImagePath(
              productImgPathList && productImgPathList.length > 0
                ? productImgPathList[0]
                : null
            ) || `${CDN_URL}/logo.png`,
          link,
          title: `${discovererName}님이 발굴신청한 ${productName} by ${providerName}`
        };
      } else if (type === 'post') {
        const { comment, author, attachImageList, product } = post || {};
        const { name, imagePaths } = product || {};
        description = comment
          ? comment.substring(0, 200).concat('...')
          : '프롬에서 응원합니다.';
        kakaoParams = {
          buttonText: `${author ? author.name : '프롬'}님의 이야기 보기`,
          description,
          imageUrl:
            attachImageList.length > 0
              ? getImagePath(attachImageList[0], 250, 250)
              : getImagePath(
                  imagePaths ? imagePaths[0] : officialProductImgPath,
                  250,
                  250
                ) || `${CDN_URL}/logo.png`,
          link,
          title: `${author ? author.name : '프롬'}님이 전하는 ${
            name || productName || '상품'
          } 이야기`
        };
      } else {
        description = recommendation
          ? recommendation.substring(0, 200).concat('...')
          : '프롬에서 응원합니다.';
        kakaoParams = {
          buttonText: `${providerName || '프롬'}님의 공급상품 보기`,
          description,
          imageUrl:
            getImagePath(officialProductImgPath, 250, 250) ||
            `${CDN_URL}/logo.png`,
          link,
          title: `${providerName || '프롬'}님이 공급하는 ${
            productName || '상품'
          }`
        };
      }

      // console.log({ type });
      // console.log({ storeRecommendation });
      // console.log({ recommendation });
      // console.log({ post });
      // console.log({ kakaoParams });

      shareKakao(kakaoParams);

      onToggle();
    } catch (e) {
      sentry.captureException(e);
      console.log({ e });
      setErrorMessage(ERR_MSG);
      toggleErrorModal();
    }
  };

  const copyUrl = () => {
    // console.log({ isCopied });
    const dummyTextArea = document.createElement('textarea');
    document.body.appendChild(dummyTextArea);
    dummyTextArea.value = getUrl();
    dummyTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(dummyTextArea);
    setIsCopied(true);
    // console.log({ isCopied });
  };

  return (
    <Fragment>
      <CommonModal
        className="share-modal"
        isOpen={isOpen}
        onToggle={() => {
          setIsCopied(false);
          onToggle();
        }}
        headerText=""
        // headerImage="/images/santa/santa-profile.jpg"
        headerImage="/images/header-image/support-patch-1.svg"
        isYellow
      >
        <div className="share-modal-support-wrapper">
          {/* <i
            className="share-modal-support-image-icon"
            style={{
              backgroundImage: `url('/images/yellow-support-btn2.svg')`
            }}
          /> */}
          공유하기
        </div>
        <div className="share-modal-button-wrapper">
          <button onClick={handleClickShareFacebook}>
            <div className="">페이스북 공유</div>
            <img
              className="share-button-img"
              src="/images/facebook-btn2.svg"
              alt="페이스북 공유"
            />
          </button>
          <button onClick={handleClickShareKakao}>
            <div className="">카카오톡 공유</div>
            <img
              className="share-button-img"
              src="/images/kakao-btn2.svg"
              alt="카카오 공유"
            />
          </button>
          <button onClick={copyUrl}>
            <div className="">URL 복사하기</div>
            <div
              className="share-button-img"
              style={{
                backgroundImage: `url("/images/copy-clip-icon2.svg")`
              }}
            >
              {isCopied && (
                <img
                  className="success-copy-url-image"
                  src="/images/copy-url-success.svg"
                  alt="복사버튼"
                />
              )}
            </div>
          </button>
        </div>
      </CommonModal>
      <AlertModal
        type="errorModal"
        contentText={errorMessage}
        isOpen={isOpenErrorModal}
        onClick={toggleErrorModal}
        onToggle={toggleErrorModal}
      />
    </Fragment>
  );
}

export default ShareModal;
