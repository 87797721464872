import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { getFormatTimeStempDate } from 'lib/time';

import { getImagePath } from 'lib/utils/imageUtils';
import './DiscoverHistoryItem.scss';

function DiscoverHistoryItem({ discovers, storeId }) {
  const {
    id,
    productImgPathList,
    productName,
    nowStatusProcess,
    createDate
  } = discovers;
  return (
    <Link to={`/store/${storeId}/discoverhistory/${id}`}>
      <div className="onestore-list-template-item">
        <div className="onestore-list-template-item-image success-list-image">
          <img
            src={
              getImagePath(productImgPathList[0]) || '/images/default-image.svg'
            }
            alt="발굴사진"
            className="onestore-list-template-item-main-image"
          />
          {nowStatusProcess === 2 && (
            <img
              className="onestore-list-template-item-sub-image no-image-border"
              src="/images/discover-success-circle-icon.svg"
              alt="발굴왕관"
            />
          )}
        </div>
        <div className="onestore-list-template-item-info">
          <div className="onestore-list-template-item-name">{productName}</div>
          <div className="onestore-list-template-item-date">
            <span>{getFormatTimeStempDate(createDate, 'YYYY-MM-DD')}</span>
          </div>
        </div>
        <div
          className={classNames(
            'onestore-list-template-item-label',
            `discover-label-${nowStatusProcess}`
          )}
        >
          {
            {
              0: '발굴대기',
              1: '발굴진행',
              2: '발굴성공',
              3: '발굴실패'
            }[nowStatusProcess]
          }
        </div>
      </div>
    </Link>
  );
}

export default DiscoverHistoryItem;
