'use strict';

let _window = null;
let _ua = null;
// const ANDROID_APP_INTENT = 'intent://https://from.kr#Intent;scheme=onestore;package=io.connexus.onestore;end';
// const IOS_APP_SCHEME = 'onestore://';
const IOS_APP_ITUNES =
  'https://itunes.apple.com/us/app/%ED%94%84%EB%A1%AC/id1456988582?l=ko&ls=1&mt=8';

const IOS_FROM_AGENT = '1store_iphone';
const ANDROID_FROM_AGENT = '1store_android';

if (typeof window !== 'undefined') {
  _window = window;
}

if (typeof navigator !== 'undefined') {
  _ua = navigator.userAgent.toLowerCase();
}

export function isMobileDevice() {
  let _isMobile = false;

  // ios, android 프롬 앱 체크
  if (_ua.search(IOS_FROM_AGENT) > -1 || _ua.search(ANDROID_FROM_AGENT) > -1) {
    //alert('app')
    return false;
  }

  if (_ua.search('android') > -1) {
    _isMobile = true;
    return _isMobile;
  }
  // only iphone
  if (_ua.search('iphone') > -1) {
    _isMobile = true;
    return _isMobile;
  }

  return _isMobile;
}

export function isMobileApp() {
  let _isMobile = false;

  // ios, android 프롬 앱 체크
  if (_ua.search(IOS_FROM_AGENT) > -1 || _ua.search(ANDROID_FROM_AGENT) > -1) {
    return true;
  }

  return _isMobile;
}

export function getMobileAppType() {
  let mobileType = null;
  if (isMobileApp()) {
    if (_ua.search(IOS_FROM_AGENT) > -1) {
      mobileType = IOS_FROM_AGENT;
    } else if (_ua.search(ANDROID_FROM_AGENT) > -1) {
      mobileType = ANDROID_FROM_AGENT;
    }
  }

  return mobileType;
}

export function execMobileApp() {
  const prevLinkPath = _window.location.href;
  const ANDROID_APP_INTENT = `intent://${prevLinkPath}#Intent;scheme=onestore;package=io.connexus.onestore;end`;
  const IOS_APP_SCHEME = `onestore://${prevLinkPath}`;

  if (_ua.search('android') > -1) {
    _window.location.href = ANDROID_APP_INTENT;
  }
  // only iphone
  else if (_ua.search('iphone') > -1) {
    setTimeout(function () {
      _window.location.href = IOS_APP_ITUNES;
    }, 2000);

    _window.location.href = IOS_APP_SCHEME;
  } else {
    //eslint-disable-next-line
    console.log('not support os');
    return false;
  }
}

// const mobileApp = {
//   /* check only iOS, Android */
//   isMobileDevice: () => {
//     let _isMobile = false;
//     if (_ua.search('android') > -1) {
//       _isMobile = true;
//       return _isMobile;
//     }
//     // only iphone
//     if (_ua.search('iphone') > -1) {
//       _isMobile = true;
//       return _isMobile;
//     }

//     return _isMobile;
//   },

//   execMobileApp: () => {
//     if (_ua.search('android') > -1) {
//       _window.location.href = ANDROID_APP_INTENT;
//     }
//     // only iphone
//     else if (_ua.search('iphone') > -1) {
//       setTimeout(function() {
//         location.href = IOS_APP_ITUNES;
//       }, 2000);

//       _window.location.href = IOS_APP_SCHEME;
//     } else {
//       //eslint-disable-next-line
//       console.log('not support os');
//       return false;
//     }
//   }
// };

// export default mobileApp;
