import React from 'react';
import classNames from 'classnames';
import mediumZoom from 'medium-zoom';

function ImageZoom({ className, src, alt, width, height }) {
  const zoom = mediumZoom({
    background: 'rgba(0, 0, 0, 0.78)',
    scrollOffset: 100,
    margin: -100
  });

  const attachZoom = (image) => {
    zoom.attach(image);
  };

  return (
    <img
      // className={classNames('image-zoom', className)}
      width={width}
      height={height}
      src={src}
      data-zoom-src={src}
      alt={alt}
      ref={attachZoom}
    />
  );
}

export default ImageZoom;
