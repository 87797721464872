import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './RenderField.scss';

// redux-form 에 사용되는 input Component
function RenderField({
  autoComplete,
  children,
  className,
  input,
  isRequired,
  label,
  maxLength,
  pattern,
  placeholder,
  readOnly,
  redirectUrl,
  type,
  description,
  meta: { asyncValidating, error, touched }
}) {
  const hasRedirectUrl = !!redirectUrl;

  // console.log({ description });

  return (
    <div
      className={classNames(
        'form-group d-flex flex-wrap align-items-top',
        className
      )}
    >
      {asyncValidating && (
        <img
          src="/images/button-loader.svg"
          className="async-validating loading-spinner"
          alt="input 로딩 중"
        />
      )}
      {label && (
        <span className="form-label d-inline-block text-left pr-1">
          {label}
          {isRequired && <span className="text-danger">*</span>}
        </span>
      )}
      {description && (
        <div className="form-description">
          <span className="text-left pr-1">{`*${description}`}</span>
        </div>
      )}
      <input
        {...input}
        className={`form-control ${label ? 'form-input' : ''} ${
          touched && error ? 'is-invalid' : ''
        }`}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete || 'nope'}
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        readOnly={readOnly}
        maxLength={maxLength || 100}
        value={
          input.value && pattern
            ? String(input.value).replace(pattern, '')
            : input.value
        }
      />
      {children || null}
      {touched && error && (
        <div
          className={`invalid-feedback mt-1 ml-auto ${
            label ? 'invalid-feedback-with-label' : ''
          }`}
        >
          {error}
          {hasRedirectUrl && error.indexOf('로그인') > -1 && (
            <Link
              to={{
                pathname: '/store/signin',
                state: {
                  redirectUrl,
                  isGiftType: redirectUrl.indexOf('gift') > -1
                }
              }}
              className="button primary render-field-login-btn d-block mt-1"
            >
              로그인 하러 가기
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

export default RenderField;
