import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import OneStoreListTemplate from 'components/oneStore/OneStoreListTemplate';
import OneStoreFromListItem from 'components/oneStore/OneStoreFromListItem';

import sentry from 'lib/sentry';
import withUi from 'lib/hoc/withUi';

import { getStore, getStoreFromList } from 'api/store';

function OneStoreFromListContainer({ storeId, UiAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [fromList, setFromList] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [lastKey, setLastKey] = useState(null);
  const [store, setStore] = useState({});

  const headerText =
    store && store.userName
      ? `${store.userName}님에게 영향을 준 사람`
      : '영향을 준 사람';

  useEffect(
    () => {
      UiAction.setHeader(headerText);
    },
    [UiAction, headerText]
  );

  useEffect(
    () => {
      async function getStoreInfo() {
        try {
          const response = await getStore(storeId);

          if (!response || !response.data.data || !response.data.success) {
            throw new Error(
              `1S-fromlist api Error(storeInfo) - storeId: ${storeId}`
            );
          }

          setStore(response.data.data);
        } catch (e) {
          sentry.captureException(e);
        }
      }

      getStoreInfo();
    },
    [storeId]
  );

  const infiniteRef = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMoreItems,
    onLoadMore: getFromList,
    scrollContainer: 'window'
  });

  async function getFromList() {
    const params = {
      lastKey,
      pageCount: 20,
      storeId
    };

    try {
      setIsLoading(true);
      const response = await getStoreFromList(params);

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `1S-fromlist api Error(1sFromList) - storeId: ${storeId}`
        );
      }

      const { data } = response.data;
      const { items } = data;

      setIsLoading(false);
      setLastKey(data.lastKey);

      setFromList([...fromList, ...items]);
      setHasMoreItems(!!data.lastKey);
    } catch (e) {
      // setIsLoading(false);
      sentry.captureException(e.message);
    }
  }

  return (
    <OneStoreListTemplate
      haveList={fromList && fromList.length > 0}
      infiniteRef={infiniteRef}
      isLoading={isLoading}
      headerText={headerText}
    >
      {fromList.map((from) => (
        <OneStoreFromListItem key={from.storeNumber} from={from} />
      ))}
    </OneStoreListTemplate>
  );
}

export default withRouter(withUi(OneStoreFromListContainer));
