import React, { Component, Fragment } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

import { isMobileDevice } from 'lib/mobileApp';

import AppCheckModal from './AppCheckModal';

// import NoticeModal from 'components/modal/NoticeModal';

const COOKIE_VALUE = 'from_flagAppCheckModal';

// const COOKIE_VALUE = 'from_noticeModal_sulnal';

// 설 공지
// const STATIC_CONTENT =
//   '안전한 배송을 위해\n설선물 주문은 1월 21일 자정에\n마감합니다!';
// const STATIC_CONTENT2 =
//   '그 이후 주문은 명절 이후\n순차적으로 발송 됩니다!\n\n*일부 품목 제외';

class GlobalAppCheckModal extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor() {
    super();

    this.state = {
      isOpenAppCheckModal: false
    };
  }

  componentDidMount() {
    this.initialize();
  }

  getCookie = () => {
    const { cookies } = this.props;
    const flagShowAppCheckModal = cookies.get(COOKIE_VALUE);

    return flagShowAppCheckModal;
  };

  setCookie = () => {
    const { cookies } = this.props;
    const exdate = new Date();

    exdate.setDate(exdate.getDate() + 1); // 하루동안 쿠키 보관

    cookies.set(COOKIE_VALUE, false, {
      expires: exdate,
      path: '/'
    });
  };

  initialize = () => {
    const isMobile = isMobileDevice();

    const flagShowAppCheckModal = this.getCookie();

    let isOpenAppCheckModal = true;

    if (flagShowAppCheckModal === 'false') {
      isOpenAppCheckModal = false;
    }

    this.setState({
      isOpenAppCheckModal: isOpenAppCheckModal && isMobile
    });

    // this.setState({
    //   isOpenAppCheckModal: isOpenAppCheckModal
    // });
  };

  handleCloseAppCheckModal = () => {
    this.setCookie();
    this.setState({
      isOpenAppCheckModal: false
    });
  };

  render() {
    const { isOpenAppCheckModal } = this.state;

    return (
      <Fragment>
        <AppCheckModal
          isOpen={isOpenAppCheckModal}
          onToggle={this.handleCloseAppCheckModal}
        />
        {/* <NoticeModal
          isOpen={isOpenAppCheckModal}
          onToggle={this.handleCloseAppCheckModal}
          contentText={STATIC_CONTENT}
          contentText2={STATIC_CONTENT2}
        /> */}
      </Fragment>
    );
  }
}

export default withCookies(GlobalAppCheckModal);
