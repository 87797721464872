import { getPriceStructure } from './priceUtils';
import { isDevelop } from 'lib/utils/envUtils';

function groupBy(array, values, groups) {
  const map = new Map();
  groups = groups ? [].concat(groups) : [null];

  return array.reduce((r, o) => {
    const g = groups.reduce((m, k, i, { length }) => {
      let child;
      if (m.has(o[k])) return m.get(o[k]);
      if (i + 1 === length) {
        child = Object.assign(
          ...groups.map((k) => ({ [k]: o[k] })),
          ...values.map((l) => ({ [l]: 0 }))
        );
        r.push(child);
      } else {
        child = new Map();
      }
      m.set(o[k], child);
      return child;
    }, map);

    for (let i = 0; i < values.length; i++) {
      g[values[i]] += +o[values[i]];
    }

    return r;
  }, []);
}

const isDev = isDevelop();

const sumValues = [
  'quantity',
  'amount',
  'taxFreeAmount',
  'giftPoint',
  'giftPointToSeller',
  'deliveryCount',
  'optionDelivery',
  'productDelivery',
  'providerDelivery',
  'deliveryFee',
  'totalAmount'
];

export const isSameItem = (payload, item) =>
  (payload.productId === item.product.id ||
    (payload.product && payload.product.id === item.product.id)) &&
  (payload.optionId === item.option.id ||
    (payload.option && payload.option.id === item.option.id));

export const isSameReceiver = (payload, order) =>
  order.receiver && payload.mobile === order.receiver.mobile;

export const cleanseItem = (item) => {
  // console.log({ item });
  const { options, discoverer, provider, ...product } = item.product;

  return { ...item, product };
};

export const lineItemize = (item) => {
  // const providerId =item.provider.id;
  // const productId = item.product.id;
  // const optionId=item.option.id;
  const isChecked = true;
  const price = getPriceStructure({
    option: item.option,
    shareRate: item.seller.shareRate,
    isTaxFree: item.product.flagTaxFree === 1,
    buyerIsSeller: item.buyerIsSeller,
    isOneProduct: item.isOneProduct
  });
  const deliveryCount = item.product.flagDelivery ? 1 : 0;
  // console.log({ item, price });
  const quantity = item.quantity ? item.quantity : 1;
  const amount = quantity * price.discountPrice;
  const taxFreeAmount = quantity * price.taxFreeAmount;
  const giftPoint = quantity * price.giftPoint;
  const giftPointToSeller = quantity * price.unitPayable.toSeller;
  const optionDelivery = price.deliveryFee;
  const deliveryFee = optionDelivery;
  const totalAmount = amount + deliveryFee;
  // console.log({ totalAmount });
  // console.log({ giftPoint });
  return {
    ...item,
    // providerId,
    // productId,
    // optionId,
    isChecked,
    price,
    quantity,
    amount,
    taxFreeAmount,
    giftPoint,
    giftPointToSeller,
    deliveryCount,
    optionDelivery,
    deliveryFee,
    totalAmount
  };
};
export const summarizeItems = (state) => {
  const items = state.items.map((item) => {
    const { isChecked = true, product, provider, seller } = item;
    if (!product || !provider)
      return { ...state, errorMessage: '상품 또는 공급자 정보가 없습니다' };

    // console.log({ seller, product, provider });

    const {
      id: providerId,
      providerName,
      deliveryFee: providerDeliveryFee,
      freeDeliveryAmount: providerFreeDeliveryAmount = isDev ? 5000 : 50000
    } = provider;
    const {
      id: productId,
      productName,
      basicDeliveryFee: productDeliveryFee,
      deliveryMax: productDeliveryMax
    } = product;

    const { id: sellerId } = seller;

    return Object.assign(
      {
        isChecked,
        sellerId,
        productId,
        productName: product.name || productName,
        productDeliveryFee,
        productDeliveryMax,
        providerId,
        providerName: provider.name || providerName,
        providerDeliveryFee,
        providerFreeDeliveryAmount,
        count: 1
      },
      ...sumValues.map((value) =>
        item[value] ? { [value]: item[value] } : { [value]: 0 }
      )
    );
  });

  const subtotalsBySeller = groupBy(
    items,
    ['quantity', 'amount', 'giftPoint', 'giftPointToSeller'],
    ['isChecked', 'sellerId', 'productId', 'productName', 'providerId']
  )
    .filter((item) => item.isChecked)
    .map((item) => {
      const { isChecked, ...rest } = item;
      return rest;
    });

  // console.log({ subtotalsBySeller });

  const tempSubtotalsByProduct = groupBy(items, sumValues, [
    'isChecked',
    'providerId',
    'providerName',
    'productId',
    'sellerId',
    'productDeliveryFee',
    'productDeliveryMax',
    'providerDeliveryFee',
    'providerFreeDeliveryAmount'
  ]);

  const subtotalsByProduct = tempSubtotalsByProduct.map((subtotal) =>
    adjustProductDeliveryFee(subtotal)
  );

  const maxDeliveryFeeByProvider = Object.entries(
    subtotalsByProduct.reduce((c, { providerId, deliveryFee }) => {
      (c[providerId] = c[providerId] || []).push(deliveryFee);
      return c;
    }, {})
  ).map(([providerId, deliveryFee]) => ({
    providerId,
    deliveryFee: Math.max(...deliveryFee)
  }));
  // console.log({ maxDeliveryFeeByProvider });

  const tempSubtotals = groupBy(subtotalsByProduct, sumValues, [
    'isChecked',
    'providerId',
    'providerName',
    'providerDeliveryFee',
    'providerFreeDeliveryAmount'
  ]).map((subtotal) => {
    return {
      ...subtotal,
      deliveryFee: maxDeliveryFeeByProvider.find(
        (item) => item.providerId === subtotal.providerId
      ).deliveryFee
    };
  });

  // console.log({ tempSubtotals });
  const subtotals = tempSubtotals.map((subtotal) => {
    return adjustDeliveryFee(subtotal);
  });
  // console.log({ subtotals });
  const providers = groupBy(
    subtotals,
    [],
    [
      'providerId',
      'providerName',
      'providerDeliveryFee',
      'providerFreeDeliveryAmount'
    ]
  );

  const total = groupBy(subtotals, sumValues, ['isChecked']);

  // console.log({ total });

  const checkedTotal = total.find((item) => item.isChecked);
  const uncheckedTotal = total.find((item) => !item.isChecked);

  return {
    ...state,
    subtotalsByProduct,
    subtotalsBySeller,
    subtotals,
    total,
    checkedTotal,
    uncheckedTotal,
    providers,
    errorMessage: ''
  };
};

export const summarizeOrders = (state) => {
  const orders = state.orders.map((order) => {
    return Object.assign(
      { orderCount: 1 },
      ...sumValues.map((value) =>
        order.checkedTotal && order.checkedTotal[value]
          ? { [value]: order.checkedTotal[value] }
          : { [value]: 0 }
      )
    );
  });
  const [orderTotal] = groupBy(orders, ['orderCount', ...sumValues]);

  const tempTotalsBySeller = state.orders.reduce(
    (totals, order) => [...totals, ...order.subtotalsBySeller],
    []
  );

  const totalsBySeller = groupBy(
    tempTotalsBySeller,
    ['quantity', 'amount', 'giftPoint', 'giftPointToSeller'],
    ['sellerId', 'productId', 'productName', 'providerId']
  );

  // console.log({ orders, orderTotal, totalsBySeller });
  return { ...state, totalsBySeller, orderTotal, errorMessage: '' };
};

export const replaceItems = (state, items) => {
  // console.log('replaceItems');
  // console.log({ items });
  const { orders, currentOrder } = state;
  orders[currentOrder] = summarizeItems({ ...orders[currentOrder], items });
  // console.log({orders});
  return { ...state, orders: [...orders] };
};

export const replaceItem = (items, itemToReplace) => {
  // console.log('replaceItem')
  // console.log({items});
  // console.log({itemToReplace})

  const { product, option } = itemToReplace;
  const keys = { productId: product.id, optionId: option.id };

  const index = items.findIndex((item) => isSameItem(keys, item));
  if (index === -1) return items;
  const newItems = [...items];
  // console.log({newItems})
  newItems[index] = { ...itemToReplace };
  // console.log({newItems})
  return newItems;
};

export const changeValues = (items, keys, values) => {
  const item = items.find((item) => isSameItem(keys, item));
  if (!item) return items;

  const { quantity = 1 } = values;

  const newItem = { ...item, quantity: quantity > 1 ? quantity : 1 };

  return replaceItems(items, newItem);
};

export const incrementQuantity = (items, keys, value = 1) => {
  const item = items.find((item) => isSameItem(keys, item));
  if (!item) return null;
  // console.log({item})

  const quantity = Math.max(item.quantity + value, 1);
  const amount = item.price.discountPrice * quantity;
  const taxFreeAmount = item.price.taxFreeAmount * quantity;
  const giftPoint = item.price.giftPoint * quantity;

  // console.log({quantity})

  return replaceItem(items, {
    ...item,
    quantity,
    amount,
    taxFreeAmount,
    giftPoint,
    totalAmount: amount + item.deliveryFee
  });
};

export const toggle = (items, keys) => {
  const item = items.find((item) => isSameItem(keys, item));
  if (!item) return null;
  // console.log({item})

  return replaceItem(items, {
    ...item,
    isChecked: !item.isChecked
  });
};

export const adjustDeliveryFee = (subtotal) => {
  const {
    deliveryFee = 0,
    amount,
    providerDeliveryFee,
    providerFreeDeliveryAmount = isDev ? 5000 : 50000
  } = subtotal;
  // console.log({ subtotal });
  const providerDelivery = providerDeliveryFee || 0;
  // console.log({ providerDelivery });
  const newProviderDeliveryFee = deliveryFee + providerDelivery;
  const newDeliveryFee =
    amount < providerFreeDeliveryAmount ? newProviderDeliveryFee : 0;
  // console.log({ newDeliveryFee });
  return {
    ...subtotal,
    providerDelivery,
    deliveryFee: newDeliveryFee,
    totalAmount: amount + newDeliveryFee
  };
};

export const adjustProductDeliveryFee = (subtotal) => {
  const {
    deliveryFee,
    amount,
    productDeliveryFee = 0,
    productDeliveryMax,
    quantity
  } = subtotal;
  // console.log({ subtotal });
  const productDelivery = productDeliveryMax
    ? Math.ceil(quantity / productDeliveryMax) * productDeliveryFee
    : productDeliveryFee;
  const newDeliveryFee = deliveryFee + productDelivery;
  // console.log({ newDeliveryFee });
  return {
    ...subtotal,
    productDelivery,
    deliveryFee: newDeliveryFee,
    totalAmount: amount + newDeliveryFee
  };
};

export const oneStoreToBuyer = (oneStore = {}) => {
  return {
    id: oneStore.id,
    name: oneStore.userName || oneStore.name,
    mobile: oneStore.mobile,
    email: oneStore.email,
    // experienceType: 'buy',
    addr: oneStore.addr,
    addrSub: oneStore.addrSub,
    zipcode: oneStore.zipcode,
    storeNo: oneStore.storeNo,
    profileImgPath: oneStore.profileImgPath
  };
};
