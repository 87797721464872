import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
// 선물상세 - 현장확인과 같은 컨테이너 사용
import GiftConfirmContainer from 'containers/gift/GiftConfirmContainer';

function OrderDeliveryConfirm({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <GiftConfirmContainer
        deliveryId={match.params.deliveryId}
        paymentUid={match.params.paymentUid}
        isOrderConfirm
      />
    </Fragment>
  );
}

export default OrderDeliveryConfirm;
