import React from 'react';
import Loader from 'components/commons/Loader';
import EmptyList from 'components/commons/EmptyList';

import './OneStoreListTemplate.scss';

function OneStoreListTemplate({
  haveList,
  isLoading,
  infiniteRef,
  headerText,
  children
}) {
  return (
    <div className="onestore-list-template-wrapper" ref={infiniteRef}>
      <div className="onestore-list-template-label">{headerText}</div>
      <div className="onestore-list-template">
        {isLoading && <Loader />}
        {haveList ? children : <EmptyList className="mt-5" />}
      </div>
    </div>
  );
}

export default OneStoreListTemplate;
