/* eslint-disable no-unused-vars */
import { useDialog } from 'hooks/useModal';

function useDiscoverModal({ isLoggedIn, isProvider, history }) {
  const {
    isOpenDialog,
    handleToggleDialog,
    isLoading,
    handleConfirm,
    handleError,
    alertType,
    alertMessage,
    setAlertMessage,
    isOpenAlert,
    handleToggleAlert
  } = useDialog();

  const gotoSigninPage = () => {
    history.push({
      pathname: '/store/signin',
      state: {
        redirectUrl: '/discover/register'
      }
    });
  };

  const handleGotoDiscoverPage = () => {
    if (!isLoggedIn) {
      handleToggleDialog();
      gotoSigninPage();

      return;
    }

    if (isProvider) {
      // eslint-disable-next-line no-console
      // console.log('provider');
      // handleError('발굴하기는 일반회원 계정으로만 가능합니다!');
      // return;
    }

    handleToggleDialog();
    history.push(`/discover/register`);
  };

  return {
    isOpenDiscoverModal: isOpenDialog,
    toggleDiscoverModal: handleToggleDialog,
    handleGotoDiscoverPage
  };
}
export default useDiscoverModal;
