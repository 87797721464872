import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { getProfileImage } from 'lib/utils/imageUtils';
// import { stringToHslColor } from 'lib/utils/commonUtils';

import './CommonProfilePicture.scss';

function CommonProfilePicture({
  className,
  profileImgPath,
  size,
  onDrawButton,
  isMyProfile
}) {
  return (
    <div
      className={classNames('dynamic-profile-picture', className)}
      style={{
        width: `${size || 47}px`,
        height: `${size || 47}px`
      }}
    >
      <LazyLoadComponent visibleByDefault>
        <div
          className="dynamic-profile-picture-image"
          style={{
            backgroundImage: `url(${getProfileImage(profileImgPath, 100, 100)})`
          }}
        />
      </LazyLoadComponent>
      {isMyProfile && onDrawButton()}
    </div>
  );
}

CommonProfilePicture.propTypes = {
  className: PropTypes.string,
  profileImgPath: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

CommonProfilePicture.defaultProps = {
  className: '',
  profileImgPath: {} || ''
};

export default CommonProfilePicture;
