import styled, { css } from 'styled-components';

export const CartSummaryWrapper = styled.div`
  width: 100%;
  max-width: var(--max-content-width, 420px);

  margin: 16px auto;
  padding: 0px 20px;

  &.checkout {
    &:before {
      content: ' ';
      display: block;
      margin-left: -20px;
      margin-right: -20px;
      border-bottom: 9px solid var(--border-color, #f2f2f2);
    }
  }

  &.provider {
    margin-top: 0px;
    padding-bottom: 16px;
    background-color: var(--border-color, #f2f2f2);
  }

  &.product {
    margin-top: 0px;
    padding-bottom: 10px;
  }
`;
export const SummaryTitle = styled.h3`
  font-size: 0.9375rem;
  font-weight: 800;
  padding-top: 16px;
  margin-left: auto;
  max-width: 480px;

  &.provider {
    font-weight: normal;
  }
`;

export const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 0.625rem;
  font-weight: normal;
  color: var(--text-light-color, #858985);

  &.desc {
    justify-content: flex-end;
    margin-top: 5px;
    color: var(--from-color, #37e011);
  }

  .label {
    font-size: 0.75rem;
    font-weight: normal;
    color: var(--text-light-color, #858985);
  }

  .value {
    font-size: 0.875rem;
    font-weight: bold;
    color: var(--text-color, black);
  }

  &.total {
    .label {
      font-size: 0.875rem;
      font-weight: bold;
      color: var(--text-color, black);
    }

    .value {
      font-size: 1.125rem;
      font-weight: 800;
    }
  }

  .info {
    font-size: 0.75rem;
    font-weight: normal;
    color: var(--from-color, #37e011);
  }
`;
