/* eslint-disable no-console */
import React, { Fragment } from 'react';

import ProviderServiceInfo from 'components/provider/ProviderServiceInfo';
import ProviderSettingMenu from 'components/provider/ProviderSettingMenu';
import ToggleList from 'components/commons/ToggleList';
import withUser from 'lib/hoc/withUser';

function ProviderInfo({ providerId, provider, user, isLoggedIn, isProvider }) {
  return (
    <Fragment>
      {isLoggedIn && user.id === providerId ? (
        <ToggleList buttonText="공급자 관리" className="setting-menu-toggle">
          <ProviderSettingMenu providerId={providerId} user={user} />
        </ToggleList>
      ) : (
        <ToggleList
          buttonText="상품 및 배송 문의"
          className="setting-menu-toggle"
          initialOpen={false}
        >
          <ProviderServiceInfo provider={provider} />
        </ToggleList>
      )}
      {/* <div className="onestore-follow-link-wrapper">
        <Link to={`/provider/${providerId}/onestore`}>
          응원자 자세히보기
          <img
            src="/images/follow-link-customer-image.png"
            alt="응원자 링크"
            className="follow-link-small-image"
          />
        </Link>
      </div> */}
    </Fragment>
  );
}

export default withUser(ProviderInfo);
