import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, change } from 'redux-form';

import ProductHtmlEditForm from 'components/admin/ProductHtmlEditForm';
import AlertModal from 'components/modal/AlertModal';
import ConfirmModal from 'components/modal/ConfirmModal';

import {
  getProduct,
  // updateProductSetting,
  putProduct
} from 'api/product';

class ProductHtmlEditContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenErrorModal: false,
      isOpenSuccessModal: false,
      errorMessage: '',
      successMessage: ''
    };

    this.productData = null;
  }

  componentDidMount = () => {
    this.initialize();
  };

  // productId 있을때 - product정보 api호출해서 폼 채워주는 함수
  getProductInfo = async (productId) => {
    try {
      const response = await getProduct(productId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`상품등록 검색 실패 - ${response}`);
      }

      const { changeField } = this.props;
      const { data } = response.data;

      const { flagHtmlProductInfo, htmlProductInfo } = data;

      this.productData = data;

      changeField(
        'flagHtmlProductInfo',
        flagHtmlProductInfo ? flagHtmlProductInfo.toString() : 'false'
      );

      this.setState({
        htmlProductInfo
      });
    } catch (e) {
      this.setState({
        errorMessage:
          '상품 정보 데이터를 가져오던 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true
      });
    }
  };

  initialize = () => {
    const { productId } = this.props;
    const isHaveProductId = productId.charAt(0) === 'p';

    if (productId) {
      // pid, did 둘중 하나
      this.setState(
        {
          id: isHaveProductId ? productId : null
        },
        () => {
          const { id } = this.state;

          if (id) {
            this.getProductInfo(id);
          } else {
            this.setState({
              isOpenErrorModal: true,
              errorMessage: '발굴상태입니다.\n먼저 기본정보를 등록해주세요',
              isDiscover: true
            });
          }
        }
      );
    }
  };

  handleSubmit = (values) => {
    this.setState(
      {
        isSubmitLoading: true
      },
      async () => {
        const params = { ...this.productData };

        const { productId } = this.props;
        const { htmlProductInfo } = this.state;
        const { flagHtmlProductInfo } = values;

        params.id = productId;

        // string 형식 bool로 형변환
        params.flagHtmlProductInfo = flagHtmlProductInfo === 'true';
        params.htmlProductInfo = htmlProductInfo;

        try {
          const response = await putProduct(params);

          if (!response || !response.data || !response.data.success) {
            throw new Error('상품상세정보 변경 실패');
          }

          this.setState({
            isOpenSuccessModal: true,
            successMessage: '수정 되었습니다.',
            isSubmitLoading: false
          });
        } catch (e) {
          this.setState({
            isOpenErrorModal: true,
            errorMessage: '수정에 실패했습니다.\n잠시 후 다시 시도해주세요.',
            isSubmitLoading: false
          });
        }
      }
    );
  };

  handleMoveProductPage = () => {
    this.setState({
      isOpenSuccessModal: false
    });

    window.location.reload();
  };

  handleCloseModal = (modalType) => {
    // 발굴상태일시 기본정보로 이동
    const { isDiscover } = this.state;

    if (isDiscover) {
      const { history } = this.props;
      // history.replace(`/provider/product/${productId}/register`);

      history.goBack();
    }

    this.setState({
      [modalType]: false
    });
  };

  handleModelChange = (model) => {
    // eslint-disable-next-line
    // console.log(`model`);
    // eslint-disable-next-line
    // console.log(model);
    this.setState({
      htmlProductInfo: model
    });
  };

  render() {
    const {
      isOpenErrorModal,
      isOpenSuccessModal,
      errorMessage,
      successMessage,
      isSubmitLoading,
      htmlProductInfo
    } = this.state;
    return (
      <Fragment>
        <ProductHtmlEditForm
          onSubmit={this.handleSubmit}
          isSubmitLoading={isSubmitLoading}
          onModelChange={this.handleModelChange}
          htmlProductInfo={htmlProductInfo}
        />
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onClick={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          isProviderModal
        />
        <ConfirmModal
          headerText="수정완료"
          headerImage="/images/header-image/delivery-num-modal-header-image.svg"
          okButtonText="확인"
          contentText={successMessage}
          isOpen={isOpenSuccessModal}
          onClickOk={() => {
            this.handleMoveProductPage();
          }}
          onToggle={() => {
            this.handleMoveProductPage();
          }}
          isProviderModal
        />
      </Fragment>
    );
  }
}

const selector = formValueSelector('productHtmlEditForm');

const mapStateToProps = (state) => ({
  flagStopSelling: selector(state, 'flagStopSelling')
});

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => {
    dispatch(change('productHtmlEditForm', field, value));
  }
});

ProductHtmlEditContainer.propTypes = {
  productId: PropTypes.string,
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ProductHtmlEditContainer.defaultProps = {
  productId: '',
  history: null
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductHtmlEditContainer);
