import React, { Fragment, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CheckBox } from 'shared/components';
import {
  SubFormWrapper,
  StyledSubTitle,
  StyledText
} from 'shared/styled/componentStyle';

function TermsSubForm({ isLoggedIn, isPayment }) {
  const {
    register,
    control,
    errors,
    watch,
    setValue,
    getValues,
    className
  } = useFormContext();

  const [acceptAll, setAcceptAll] = useState(false);

  const watchCheckboxes = watch([
    'acceptTermsOfService',
    'acceptPrivacyPolicy',
    'acceptMarketingTerms'
  ]);

  const onAcceptAll = () => {
    setValue('acceptTermsOfService', !acceptAll);
    setValue('acceptPrivacyPolicy', !acceptAll);
    setValue('acceptMarketingTerms', !acceptAll);
    setAcceptAll(!acceptAll);
  };

  useEffect(() => {
    const isChecked =
      watchCheckboxes['acceptTermsOfService'] &&
      watchCheckboxes['acceptPrivacyPolicy'] &&
      watchCheckboxes['acceptMarketingTerms'];
    // console.log({ isChecked });
    setAcceptAll(isChecked);
    setValue('acceptAll', isChecked);
  }, [watchCheckboxes]);

  // console.log({ watchCheckboxes });

  return (
    <SubFormWrapper>
      <StyledSubTitle>이용약관동의</StyledSubTitle>
      {!isLoggedIn && (
        <Fragment>
          <CheckBox
            className={`${className} round-checkbox`}
            name="acceptAll"
            label="모두 동의합니다."
            onClick={onAcceptAll}
            {...{ register, control, errors }}
          />
          <CheckBox
            className={`${className} required`}
            name="acceptTermsOfService"
            label="[필수] 이용약관"
            buttonLabel="약관보기 >"
            onButtonClick={(e) => {
              e.stopPropagation();
              console.log('clicked');
            }}
            {...{ register, control, errors }}
          />
        </Fragment>
      )}
      <CheckBox
        className={`${className} required`}
        name="acceptPrivacyPolicy"
        label="[필수] 개인정보처리방침"
        buttonLabel="약관보기 >"
        onButtonClick={() => {
          console.log('clicked');
        }}
        {...{ register, control, errors }}
      />
      {!isLoggedIn && (
        <Fragment>
          <CheckBox
            className={`${className}`}
            name="acceptMarketingTerms"
            label="[선택] 마케팅 동의"
            buttonLabel="약관보기 >"
            onButtonClick={() => {
              console.log('clicked');
            }}
            {...{ register, control, errors }}
          />
        </Fragment>
      )}
      {isPayment && (
        <StyledText>위 주문 내용을 확인하였으며, 결제에 동의합니다.</StyledText>
      )}
    </SubFormWrapper>
  );
}

export default TermsSubForm;
