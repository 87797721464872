import React, { Fragment, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import {
  useCheckoutStore,
  useCheckoutDispatch
} from 'components/checkout/CheckoutStore';
// import { useLoginStore } from '../../components/Login/LoginStore';

import withUser from 'lib/hoc/withUser';
import ToggleList from 'components/commons/ToggleList';

import CheckoutHeader from 'components/checkout/CheckoutHeader';
import UserSubForm from 'components/shared/UserSubForm';
import AddressSubForm from 'components/shared/AddressSubForm';
import CartSummary from 'components/cart/CartSummary';
import TermsSubForm from 'components/shared/TermsSubForm';
import GiftPointSubForm from './GiftPointSubForm';
import AuthSubForm from './AuthSubForm';

import {
  StyledForm,
  FormHeaderWrapper,
  StyledFormTitle,
  FormBodyWrapper,
  StyledTextButton,
  FormButtonWrapper
} from 'shared/styled/componentStyle';

import { ButtonWrapper, StyledButton } from 'shared/buttons/ButtonStyle';

import {
  stringRequired,
  quantityRequired,
  nameRequired,
  emailRequired,
  emailNewRequired,
  mobileRequired,
  mobileNewRequired,
  passwordRequired
} from 'shared/rules/validationRules';

const defaultCheckboxValues = {
  acceptAll: false,
  acceptTermsOfService: false,
  acceptPrivacyPolicy: false,
  acceptMarketingTerms: false
};

function PaymentForm({
  isLoggedIn,
  isSignedKakao,
  giftPointBalance = 0,
  onRequestPayment,
  className
}) {
  const checkout = useCheckoutStore();
  const totalAmount = checkout?.orderTotal?.totalAmount || 0;

  const { receiver, orderType } = checkout?.orders[0] || {};

  // console.log({ receiver });
  const buyer = isLoggedIn
    ? checkout.buyer
    : orderType === 'buy'
    ? receiver
    : {};

  const checkboxValues = isLoggedIn
    ? { acceptPrivacyPolicy: true }
    : defaultCheckboxValues;

  const schema = yup.object().shape({
    name: !isLoggedIn ? nameRequired : null,
    mobile: !isLoggedIn ? mobileNewRequired : null,
    email: !isLoggedIn ? emailNewRequired : null,
    giftPointUsed: isLoggedIn && totalAmount > 0 ? quantityRequired : null,
    pwd: !(isLoggedIn || isSignedKakao) ? passwordRequired : null,
    acceptTermsOfService: !isLoggedIn
      ? yup.boolean().oneOf([true], '이용 약관에 동의해주세요.')
      : null,
    acceptPrivacyPolicy: yup
      .boolean()
      .oneOf([true], '개인정보 수집 및 이용안내에 동의해주세요.')
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...buyer,
      ...checkboxValues,
      giftPointUsed: 0
    }
  });

  const { formState, reset, watch, handleSubmit, errors } = methods;
  const giftPointUsedWatch = watch('giftPointUsed');
  // console.log({ giftPointUsedWatch });

  useEffect(() => {
    // console.log({ buyer });
    if (buyer.id) {
      // console.log('resetting');
      reset({
        ...buyer,
        ...checkboxValues,
        giftPointUsed: 0
      });
    }
  }, [buyer.id]);

  const { isSubmitting } = formState;

  // console.log({ errors });
  // console.log({ isSubmitting });
  // console.log({ isLoggedIn });
  // console.log({ isSignedKakao });
  return (
    <FormProvider {...methods} className={className}>
      <CheckoutHeader step="payment" />

      <StyledForm className="payment-form">
        <FormBodyWrapper>
          <ToggleList
            buttonLabel=""
            buttonText=""
            buttonDescription={`주문자: ${
              buyer.name || '정보를 입력해 주세요'
            }`}
            initialOpen={!isLoggedIn}
            className="payment"
          >
            <UserSubForm className="payment-form" showEmail />
            <AddressSubForm className="payment-form" isOptional />
          </ToggleList>
          <CartSummary
            title="주문 합계"
            type="checkout"
            {...checkout?.orderTotal}
          />
          {isLoggedIn ? (
            totalAmount > 0 && (
              <GiftPointSubForm
                isLoggedIn={isLoggedIn}
                totalAmount={totalAmount}
                giftPointBalance={giftPointBalance}
                giftPointUsedWatch={giftPointUsedWatch}
              />
            )
          ) : (
            <AuthSubForm isSignedKakao={isSignedKakao} />
          )}

          <TermsSubForm
            isPayment
            isLoggedIn={isLoggedIn}
            className="payment-form"
          />
        </FormBodyWrapper>

        <FormButtonWrapper>
          <ButtonWrapper className="payment-button-wrapper">
            {totalAmount - giftPointUsedWatch === 0 ? ( // 적립금 전액사용 || 총 결제금액 0원
              <StyledButton
                disabled={isSubmitting}
                type="button"
                className="from-button tall-button"
                onClick={handleSubmit((values) => {
                  onRequestPayment(values, 'giftPoint');
                })}
              >
                {giftPointUsedWatch > 0 ? '결제하기' : '신청하기'}
              </StyledButton>
            ) : (
              <Fragment>
                <StyledButton
                  disabled={isSubmitting}
                  type="button"
                  className="payment-button"
                  aria-label="html_inicis"
                  onClick={handleSubmit((values) =>
                    onRequestPayment(values, 'html_inicis')
                  )}
                >
                  <img src="/images/payment-card-btn.svg" alt="신용카드 결제" />
                  <span>신용카드</span>
                </StyledButton>
                {/* <StyledButton
                  type="button"
                  className="payment-button payco"
                  aria-label="payco"
                  onClick={handleSubmit((values) =>
                    onRequestPayment(values, 'payco'))}
                >
                  <img src="/images/payment-payco-btn.png" alt="페이코 결제" />
                  <span>페이코</span>
                </StyledButton> */}
                <StyledButton
                  disabled={isSubmitting}
                  type="button"
                  className="payment-button"
                  aria-label="html_inicis"
                  onClick={handleSubmit((values) =>
                    onRequestPayment(values, 'html_inicis', 'trans')
                  )}
                >
                  <img src="/images/payment-trans-btn.svg" alt="계좌이체" />
                  <span>계좌이체</span>
                </StyledButton>
                <StyledButton
                  disabled={isSubmitting}
                  type="button"
                  className="payment-button"
                  aria-label="kakaopay"
                  onClick={handleSubmit((values) => {
                    // console.log('clicked');
                    onRequestPayment(values, 'kakaopay');
                  })}
                >
                  <img
                    className="kakaopay"
                    src="/images/payment-kakaopay-btn.png"
                    alt="카카오페이 결제"
                  />
                  <span>카카오페이</span>
                </StyledButton>
              </Fragment>
            )}
          </ButtonWrapper>
        </FormButtonWrapper>

        {/* <DevTool control={methods.control} /> */}
      </StyledForm>
    </FormProvider>
  );
}

export default withUser(PaymentForm);
