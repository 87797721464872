// 결제 연동
export function requestIamportPay(paymentRequest) {
  return new Promise((resolve, reject) => {
    const { IMP } = window;

    IMP.request_pay(paymentRequest, (rsp) => {
      if (!rsp.success) {
        reject(rsp);

        return;
      }

      resolve(rsp);
    });
  });
}

// 본인인증 연동
export function requestCertification() {
  return new Promise((resolve, reject) => {
    const { IMP } = window;

    IMP.certification(
      {
        min_age: 19,
        popup: false
      },
      (rsp) => {
        if (!rsp.success) {
          reject(rsp);

          return;
        }

        resolve(rsp);
      }
    );
  });
}
