import React, { useState, Fragment } from 'react';
import { ToggleListButtonStyle, ToggleListWrapper } from './ToggleListStyle';

function ToggleList({
  initialOpen,
  className = '',
  buttonLabel,
  buttonText,
  buttonDescription,
  children
}) {
  const [isOpen, setIsOpen] = useState(initialOpen || false);

  return (
    <Fragment>
      <ToggleListButtonStyle className={className}>
        {buttonLabel && (
          <span className="thick-border-label">{buttonLabel}</span>
        )}
        <button
          type="button"
          className={isOpen ? 'active' : ''}
          onClick={() => setIsOpen(!isOpen)}
        >
          {buttonText}
          <br />
          <span>{buttonDescription}</span>
        </button>
      </ToggleListButtonStyle>
      <ToggleListWrapper className={className}>
        {isOpen && children}
      </ToggleListWrapper>
    </Fragment>
  );
}

export default ToggleList;
