const cdnUrl = 'cdn.from.kr';

export const getImagePath = (image = null, width, height) => {
  if (!image) {
    return '';
  }

  const path = typeof image === 'string' ? image : image.path ? image.path : '';

  const imagePath = path
    .trim()
    .replace(/\s/g, '%20')
    .replace('s3.ap-northeast-2.amazonaws.com/1store.io.web.upload', cdnUrl)
    .replace('cdn.1store.io', cdnUrl);

  const sizeParam =
    !width && !height
      ? ''
      : width && !height
      ? `?width=${width}`
      : !width && height
      ? `?height=${height}`
      : `?width=${width}&height=${height}`;
  return imagePath.concat(sizeParam);
};

export const truncate = (string, n) => {
  if (!string) return '';

  const newString =
    string.length > n ? string.substring(0, n - 1) + '...' : string;

  return newString;
};

export function replaceCDNPath(path) {
  if (!path) {
    return '';
  }

  return path
    .replace('s3.ap-northeast-2.amazonaws.com/1store.io.web.upload', cdnUrl)
    .replace('cdn.1store.io', cdnUrl);
}

export const formatMobileNumber = (mobile, symbol = '') => {
  // 01033416922 | 010-3341-6922 | 010.3341.6922 | 010 3341 6922 | 821033416922 를 01033416922 로 포맷
  // symbol = "-"인 경우에는 010-3341-6922 로 포맷
  try {
    if (mobile.length < 10) return mobile;
    return mobile.replace(
      /.*(82|0)(1[01689])([ -.]?)(\d+)([ -.]?)(\d{4})/,
      '0$2' + symbol + '$4' + symbol + '$6'
    );
  } catch (err) {
    throw err;
  }
};
