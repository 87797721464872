import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import RedirectToHome from 'components/commons/RedirectToHome';
import ProviderSalesList from 'components/provider/ProviderSalesList';
import withUi from 'lib/hoc/withUi';
import sentry from 'lib/sentry';
import DeliveryNumInputModal from 'components/modal/DeliveryNumInputModal';
import { getProviderSalesList } from 'api/provider';
import storage from 'lib/storage';
import AlertModal from 'components/modal/AlertModal';

import * as providerActions from 'modules/provider';

class ProviderSalesListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenModal: true,
      isLoading: false,
      dataLoading: false,
      isOpenDeliveryInputModal: false,
      hasLastKey: false,
      salesList: [],
      changeStatusProcess: 0
    };
    this.lastKey = null;
    this.pageCount = 20;
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.provider !== this.props.provider) {
      this.setHeader();
    }
  }

  componentWillUnmount = () => {
    const { ProviderAction } = this.props;

    ProviderAction.initializeProvider();
  };

  getProvider = async () => {
    const { ProviderAction, match } = this.props;
    const { providerId } = match.params;

    try {
      const response = await ProviderAction.getProvider(providerId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `공급자 결제내역 페이지 - pp 데이터 불러오기 실패: ${JSON.stringify(
            response
          )}}`
        );
      }
    } catch (e) {
      sentry.captureException(e.message);
    }
  };

  getSalesList = () => {
    this.setState(
      {
        dataLoading: true
      },
      async () => {
        try {
          const { match } = this.props;
          const { providerId } = match.params;

          const response = await getProviderSalesList({
            providerId,
            pageCount: this.pageCount,
            lastKey: this.LastEvaluatedKey
          });

          if (!response || !response.data.success) {
            throw new Error({
              errorMessage: '결제 내역을 불러오는 중 오류가 발생했습니다.'
            });
          }

          const { LastEvaluatedKey, Items } = response.data.data;
          const { salesList } = this.state;

          this.LastEvaluatedKey = LastEvaluatedKey;

          this.setState({
            isLoading: false,
            dataLoading: false,
            hasLastKey: !!LastEvaluatedKey,
            salesList: salesList.concat(Items)
          });
        } catch (e) {
          this.setState({
            isLoading: false,
            dataLoading: false
          });
        }
      }
    );
  };

  setHeader = () => {
    const { UiAction, provider } = this.props;

    const headerText =
      provider && provider.providerName
        ? `${provider.providerName} 결제내역`
        : '결제내역';

    UiAction.setHeader(headerText);
  };

  initialize = () => {
    this.getProvider();
    this.setHeader();
    // this.setState(
    //   {
    //     isLoading: true
    //   },
    //   this.getSalesList
    // );
  };

  handleDeliveryInputModal = () => {};

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  onCloseModal = () => {
    this.setState({
      isOpenModal: false
    });
    this.props.history.goBack();
  };

  handleChangeSelect = (e) => {
    this.setState({
      changeStatusProcess: e.target.value
    });
  };

  handleOpenInputModal = () => {
    this.setState({
      isOpenDeliveryInputModal: true
    });
  };

  render() {
    const {
      isLoading,
      dataLoading,
      salesList,
      hasLastKey,
      isOpenDeliveryInputModal,
      changeStatusProcess
    } = this.state;
    const userInfo = storage.get('user_userInfo');
    const { match } = this.props;
    const { providerId } = match.params;

    return (
      <Fragment>
        <RedirectToHome isGotoHome={!userInfo || userInfo.id !== providerId} />
        <ProviderSalesList
          isLoading={isLoading}
          dataLoading={dataLoading}
          salesList={salesList}
          onGet={this.getSalesList}
          hasLastKey={hasLastKey}
          onOpenInputModal={this.handleOpenInputModal}
          onToggleModal={() => {
            this.handleCloseModal('isOpenDeliveryInputModal');
          }}
        />
        <DeliveryNumInputModal
          isOpen={isOpenDeliveryInputModal}
          onChangeSelect={this.handleChangeSelect}
          changeStatusProcess={changeStatusProcess}
          onToggle={() => {
            this.handleCloseModal('isOpenDeliveryInputModal');
          }}
        />
        <AlertModal
          isOpen={this.state.isOpenModal}
          headerText="준비중입니다."
          contentText={`이메일로 매일 업데이트되는\n주문서(운송장 입력 기준)에서\n 확인해 주세요.`}
          onToggle={this.onCloseModal}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ provider }) => ({
  provider: provider.provider
});

const mapDispatchToProps = (dispatch) => ({
  ProviderAction: bindActionCreators(providerActions, dispatch)
});

export default compose(
  withUi,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProviderSalesListContainer);
