/* eslint-disable no-unused-vars */
import { useState } from 'react';

export default function useModal(
  modalTitle = '',
  modalMessage = '',
  initialMode = false
) {
  const [type, setType] = useState('');
  const [title, setTitle] = useState(modalTitle);
  const [message, setMessage] = useState(modalMessage);
  const [buttonLabel, setButtonLabel] = useState('');
  const [isOpen, setIsOpen] = useState(initialMode);

  const handleToggle = (message) => {
    // console.log({ message });
    if (message && typeof message === 'string') setMessage(message);
    setIsOpen((oldIsOpen) => !oldIsOpen);
  };

  return {
    type,
    setType,
    title,
    setTitle,
    message,
    setMessage,
    buttonLabel,
    setButtonLabel,
    isOpen,
    setIsOpen,
    handleToggle
  };
}

export function useSimpleDialog(title = '', message = '', initialMode = false) {
  const {
    message: dialogMessage,
    isOpen: isOpenDialog,
    setIsOpen: setIsOpenDialog,
    handleToggle: handleToggleDialog
  } = useModal(title, message, initialMode);

  const [isConfirmed, setIsConfirmed] = useState();

  const handleDecline = () => {
    setIsConfirmed(false);
    handleToggleDialog();
  };
  const handleConfirm = () => {
    setIsConfirmed(true);
    handleToggleDialog();
  };

  return {
    dialogMessage,
    isOpenDialog,
    setIsOpenDialog,
    handleToggleDialog,
    setIsConfirmed,
    isConfirmed,
    handleConfirm,
    handleDecline
  };
}

export function useDialog(title = '', message = '', initialMode = false) {
  const {
    message: dialogMessage,
    isOpen: isOpenDialog,
    setIsOpen: setIsOpenDialog,
    handleToggle: handleToggleDialog
  } = useModal(title, message, initialMode);

  const {
    type: alertType,
    setType: setAlertType,
    message: alertMessage,
    setMessage: setAlertMessage,
    isOpen: isOpenAlert,
    setIsOpen: setIsOpenAlert,
    handleToggle: handleToggleAlert
  } = useModal();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleConfirm = () => {
    setIsConfirmed(true);
    setIsLoading(true);
    setAlertType('successModal');
  };

  const handleError = (errorMessage = '') => {
    setIsError(true);
    setIsLoading(false);
    setAlertType('errorModal');
    setAlertMessage(errorMessage);
  };

  return {
    dialogMessage,
    isOpenDialog,
    setIsOpenDialog,
    handleToggleDialog,
    isConfirmed,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    handleConfirm,
    handleError,
    alertType,
    setAlertType,
    alertMessage,
    setAlertMessage,
    isOpenAlert,
    setIsOpenAlert,
    handleToggleAlert
  };
}
