import React, { useState } from 'react';
import { useQuery } from 'react-query';
import Slider from 'react-slick';

import { getBannerList } from 'api/banner';
import { getImagePath } from 'lib/utils/imageUtils';
import ConditionLink from 'components/commons/ConditionLink';
import AlertModal from 'components/modal/AlertModal';
import Loader from 'components/commons/Loader';

import './FromPickBanner.scss';

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  lazyLoad: false,
  autoplay: true,
  autoplaySpeed: 5000,
  // onReInit: () => {
  //   if (slider && slider.innerSlider.state.currentSlide < 0) {
  //     slider.slickGoTo(0);
  //   }
  // },
  // eslint-disable-next-line react/display-name
  customPaging: () => <div className="custom-slick-dot" />
};

const getBanners = async (key, bannerType) => {
  try {
    // const params = {
    //   bannerType
    // };

    const response = await getBannerList();
    // console.log({ response });

    const { Items = [] } = response.data.data;
    const bannerList =
      bannerType === 'recipe'
        ? Items.filter((b) => b.isRecipe)
        : Items.filter((b) => !b.isRecipe);

    return bannerList;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

function FromPickBanner({ bannerType }) {
  const [isOpen, setIsOpen] = useState(false);
  const { data, error, isLoading, isError } = useQuery(
    ['banners', bannerType],
    getBanners,
    {
      staleTime: 1000 * 60 * 5,
      retry: false,
      onError: () => setIsOpen(true)
    }
  );

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="from-pick-banner-wrapper">
      {isLoading ? (
        <Loader />
      ) : (
        <Slider
          className="banner-slide"
          // ref={(sl) => (slider = sl)}
          {...settings}
        >
          {data &&
            data.map((event) => {
              return (
                <div key={getImagePath(event.bannerImg)}>
                  <ConditionLink to={event.bannerLink}>
                    <div
                      className="banner-slide-item"
                      style={{
                        backgroundImage: `url("${getImagePath(
                          event.bannerImg,
                          800,
                          800
                        )}")`
                      }}
                    />
                  </ConditionLink>
                </div>
              );
            })}
        </Slider>
      )}
      <AlertModal
        type="errorModal"
        contentText={isError ? error.message : ''}
        isOpen={isOpen}
        onClick={onClose}
        onToggle={onClose}
      />
    </div>
  );
}

export default FromPickBanner;
