import React, { Component, Fragment } from 'react';
import { withRouter, Link, Route } from 'react-router-dom';
import { compose } from 'redux';

import { Tabs, Tab } from 'components/ui/Tabs';
import RegisterProductContainer from 'containers/provider/RegisterProductContainer';
import OptionPriceListContainer from 'containers/provider/OptionPriceListContainer';
import ProductHtmlEditContainer from 'containers/provider/ProductHtmlEditContainer';

import withUi from 'lib/hoc/withUi';

class ModifyProductContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.initialize();
    this.handleChangeTab();
  }

  componentDidUpdate = (prevProps) => {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;

    if (!location) {
      return;
    }

    if (location.pathname !== prevLocation.pathname) {
      this.handleChangeTab();
    }
  };

  handleChangeTab = () => {
    const { location } = this.props;
    let tabIndex = 0;

    if (!location || !location.pathname) {
      return;
    }

    const { pathname } = location;

    if (pathname.indexOf('register') > -1) {
      tabIndex = 0;
    } else if (pathname.indexOf('optionprice') > -1) {
      tabIndex = 1;
    } else if (pathname.indexOf('contentedit') > -1) {
      tabIndex = 2;
    }

    this.setState({
      tabIndex
    });
  };

  initialize = () => {
    const { UiAction } = this.props;

    UiAction.setHeader('공급자 - 상품수정');
  };

  render() {
    const { tabIndex } = this.state;
    const { discoverId } = this.props;

    return (
      <Fragment>
        <Tabs className="provider-product-tab-2 modify-product-tab">
          <Tab isSelected={tabIndex === 0}>
            <Link to={`/provider/product/${discoverId}/register`}>
              <span className="tab-bottom-dot-icon" />
              1. 기본정보
            </Link>
          </Tab>
          <Tab isSelected={tabIndex === 1}>
            <Link to={`/provider/product/${discoverId}/optionprice`}>
              <span className="tab-bottom-dot-icon" />
              2. 옵션/가격
            </Link>
          </Tab>
          <Tab isSelected={tabIndex === 2}>
            <Link to={`/provider/product/${discoverId}/contentedit`}>
              <span className="tab-bottom-dot-icon" />
              3. 상세정보
            </Link>
          </Tab>
        </Tabs>
        <Route
          exact
          path={`/provider/product/${discoverId}/register`}
          render={() => <RegisterProductContainer discoverId={discoverId} />}
        />
        <Route
          exact
          path={`/provider/product/${discoverId}/optionprice`}
          render={() => <OptionPriceListContainer productId={discoverId} />}
        />
        <Route
          exact
          path={`/provider/product/${discoverId}/contentedit`}
          render={() => <ProductHtmlEditContainer productId={discoverId} />}
        />
      </Fragment>
    );
  }
}

export default compose(withRouter, withUi)(ModifyProductContainer);
