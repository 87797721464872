/* eslint-disable no-console */
/* eslint-disable react/jsx-indent */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, change } from 'redux-form';

import OptionPriceListForm from 'components/admin/OptionPriceListForm';
import AlertModal from 'components/modal/AlertModal';
import ConfirmModal from 'components/modal/ConfirmModal';
import { getImageFile } from 'lib/utils/imageUtils';
import { s3Upload } from 'lib/aws/s3Upload';

import { getProduct, putProduct } from 'api/product';
import {
  getIncentivePercentObject,
  getIncentivePointObject
} from 'lib/utils/newPriceUtils';
import { isEmpty } from 'lib/utils/commonUtils';

class OptionPriceListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenErrorModal: false,
      isOpenSuccessModal: false,
      errorMessage: '',
      successMessage: ''
    };
  }

  componentDidMount = () => {
    this.initialize();
  };

  // productId 있을때 - product정보 api호출해서 폼 채워주는 함수
  getProductInfo = async (productId) => {
    try {
      const response = await getProduct(productId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`상품정보 조히 실패 - ${response}`);
      }

      const { changeField } = this.props;
      const { data } = response.data;

      this.productData = data;

      const { options } = data;
      let { defaultRate } = data;

      // defaultRate 소숫점 변환
      defaultRate = getIncentivePercentObject(defaultRate);

      // 옵션 변환
      const newOptions =
        options &&
        options.map((op) => ({
          ...op,
          rate: getIncentivePercentObject(op.rate),
          isOnDisplay: isEmpty(op.isOnDisplay)
            ? 'true'
            : op.isOnDisplay.toString(),
          isInStock: isEmpty(op.isInStock) ? 'true' : op.isInStock.toString(),
          isShowRate: false
        }));

      changeField('defaultRate', defaultRate);
      changeField('options', newOptions);
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      this.setState({
        errorMessage:
          '상품 정보 데이터를 가져오던 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true
      });
    }
  };

  initialize = () => {
    const { productId } = this.props;
    const isHaveProductId = productId.charAt(0) === 'p';

    if (productId) {
      this.setState(
        {
          id: isHaveProductId ? productId : null
        },
        () => {
          const { id } = this.state;

          if (id) {
            this.getProductInfo(id);
          } else {
            this.setState({
              isOpenErrorModal: true,
              errorMessage: '발굴상태입니다.\n먼저 기본정보를 등록해주세요',
              isDiscover: true
            });
          }
        }
      );
    }
  };

  handleSwapIndex = (e, previousNo) => {
    const { options, changeField } = this.props;

    const swapIndex = options.findIndex(
      (op) => parseInt(op.seqNo, 10) === parseInt(e.target.value, 10)
    );

    if (swapIndex < 0) {
      return;
    }

    changeField(`options[${swapIndex}].seqNo`, previousNo);
  };

  handleClickDeleteOption = (fields, index) => {
    this.setState({
      isOpenConfrimModal: true
    });

    this.deleteData = { fields, index };
  };

  deleteOption = () => {
    const { fields, index } = this.deleteData;
    const { options, changeField } = this.props;

    if (fields.length <= 1) {
      this.setState({
        isOpenConfrimModal: false,
        isOpenErrorModal: true,
        errorMessage: '옵션이 적어도 하나이상 있어야 합니다.'
      });

      return;
    }

    const previousNo = options[index].seqNo;

    let tempOptions = JSON.parse(JSON.stringify(options));

    fields.remove(index);
    tempOptions.splice(index, 1);

    this.setState({
      isOpenConfrimModal: false
    });

    // 옵션 변환
    tempOptions = tempOptions.map((op) => ({
      ...op,
      seqNo: op.seqNo > previousNo ? op.seqNo - 1 : op.seqNo
    }));

    changeField('options', tempOptions);
  };

  handleSubmit = (values) => {
    // console.log({ values });
    this.setState(
      {
        isSubmitLoading: true
      },
      async () => {
        const params = { ...this.productData };

        const { productId } = this.props;
        const { options } = values;

        params.id = productId;

        // 공개한 옵션수
        const isOnDisplayTrueCount =
          options && options.filter((op) => op.isOnDisplay === 'true').length;

        if (isOnDisplayTrueCount === 0) {
          this.setState({
            isOpenErrorModal: true,
            errorMessage: '옵션이 적어도 하나이상 공개해야 합니다.',
            isSubmitLoading: false
          });

          return;
        }
        // console.log({ options });
        let newOptions = [];
        for (const option of options) {
          // console.log({ option });
          const { image, isShowRate, imagePath, ...rest } = option;

          const newImagePath = image
            ? {
                path: await s3Upload(getImageFile(image), 'providerUpload'),
                width: image.width,
                height: image.height
              }
            : imagePath;
          // console.log({ rest });
          // console.log({ newImagePath });
          const newOption = {
            ...rest,
            price: parseInt(option.price, 10),
            rate: getIncentivePointObject(option.rate),
            // string 형식 bool로 형변환
            isOnDisplay: option.isOnDisplay === 'true',
            isInStock: option.isInStock === 'true',
            // string 형식 int 형변환
            seqNo: parseInt(option.seqNo, 10),
            imagePath: newImagePath
          };

          newOptions.push(newOption);
        }

        // console.log({ newOptions });
        // if (newOptions) {
        //   this.setState({
        //     isOpenErrorModal: true,
        //     errorMessage: '테스트중',
        //     isSubmitLoading: false
        //   });

        //   return;
        // }

        // options 변환
        // const newOptions = options.map((op) => {
        //   const element = {
        //     ...op,
        //     price: parseInt(op.price, 10),
        //     rate: getIncentivePointObject(op.rate),
        //     // string 형식 bool로 형변환
        //     isOnDisplay: op.isOnDisplay === 'true',
        //     // string 형식 int 형변환
        //     seqNo: parseInt(op.seqNo, 10)
        //   };

        //   delete element.isShowRate;

        //   return element;
        // });

        // 정렬
        newOptions.sort((a, b) => {
          if (a.seqNo < b.seqNo) {
            return -1;
          }

          if (a.seqNo > b.seqNo) {
            return 1;
          }

          return 0;
        });

        params.options = newOptions;

        // const newPriceList = newOptions.reduce((filtered, op) => {
        //   if (op.isOnDisplay) {
        //     const element = {
        //       price: parseInt(op.price, 10),
        //       option: op.name
        //     };

        //     filtered.push(element);
        //   }
        //   return filtered;
        // }, []);

        // params.priceList = newPriceList;

        try {
          const response = await putProduct(params);

          if (!response || !response.data || !response.data.success) {
            throw new Error('옵션 변경 실패');
          }

          this.setState({
            isOpenSuccessModal: true,
            successMessage: '수정 되었습니다.',
            isSubmitLoading: false
          });
        } catch (e) {
          this.setState({
            isOpenErrorModal: true,
            errorMessage: '수정에 실패했습니다.\n잠시 후 다시 시도해주세요.',
            isSubmitLoading: false
          });
        }
      }
    );
  };

  handleMoveProductPage = () => {
    this.setState({
      isOpenSuccessModal: false
    });

    window.location.reload();
  };

  handleCloseModal = (modalType) => {
    // 발굴상태일시 기본정보로 이동
    const { isDiscover } = this.state;

    if (isDiscover) {
      // const { history, productId } = this.props;
      const { history } = this.props;

      // history.replace(`/provider/product/${productId}/register`);

      history.goBack();
    }

    this.setState({
      [modalType]: false
    });
  };

  // handleChangeImage = (imageFile, index) => {
  //   const { changeField, options } = this.props;
  //   console.log('Inside Handle OptionImage');
  //   console.log({ index });
  //   console.log(imageFile);
  //   const newOptions = [...options];
  //   newOptions[index].image = imageFile;
  //   changeField(`options`, newOptions);
  //   console.log({ newOptions });
  // };

  render() {
    const {
      isOpenErrorModal,
      isOpenSuccessModal,
      isOpenConfrimModal,
      errorMessage,
      successMessage,
      isSubmitLoading
    } = this.state;
    const { options, defaultRate } = this.props;
    return (
      <Fragment>
        <OptionPriceListForm
          onSubmit={this.handleSubmit}
          isSubmitLoading={isSubmitLoading}
          options={options}
          defaultRate={defaultRate}
          onChangePrice={this.handleChangePrice}
          onSwapIndex={this.handleSwapIndex}
          onClickDeleteOption={this.handleClickDeleteOption}
          onChangeImage={(imageFile, index) => {
            const { changeField } = this.props;
            // console.log('Inside Handle OptionImage');
            // console.log({ index });
            // console.log(imageFile);
            const newOptions = [...options];
            newOptions[index].image = imageFile;
            changeField(`options`, newOptions);
            // console.log({ newOptions });
          }}

          // onChangeImage={this.hangleChangeImage}
        />
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onClick={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          isProviderModal
        />
        <ConfirmModal
          headerText="주의"
          headerImage="/images/header-image/sori-header-image.svg"
          contentText="옵션을 삭제 하시겠습니까?"
          isOpen={isOpenConfrimModal}
          okButtonText="확인"
          onClickOk={this.deleteOption}
          onToggle={() => {
            this.handleCloseModal('ConfirmModal');
          }}
          isProviderModal
        />
        <ConfirmModal
          headerText="수정완료"
          headerImage="/images/header-image/agnes-header-image.svg"
          okButtonText="확인"
          contentText={successMessage}
          isOpen={isOpenSuccessModal}
          onClickOk={() => {
            this.handleMoveProductPage();
          }}
          onToggle={() => {
            this.handleMoveProductPage();
          }}
          isProviderModal
        />
      </Fragment>
    );
  }
}

const selector = formValueSelector('optionPriceListForm');

const mapStateToProps = (state) => ({
  options: selector(state, 'options'),
  defaultRate: selector(state, 'defaultRate')
});

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => {
    dispatch(change('optionPriceListForm', field, value));
  }
});

OptionPriceListContainer.propTypes = {
  productId: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  defaultRate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  history: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  changeField: PropTypes.func
};

OptionPriceListContainer.defaultProps = {
  productId: '',
  options: [],
  defaultRate: {},
  history: null,
  changeField: () => {}
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OptionPriceListContainer);
