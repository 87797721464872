export const cdnUrl = 'https://cdn.from.kr/mbti/santa';

export const questions = [
  {
    id: 1,
    text: '당신은 이번 크리스마스 산타! 전세계의 아이들에게 따뜻함을 선물하죠. 몇 월부터 크리스마스 선물을 준비할까요?',
    image: 'KakaoTalk_20201209_225750617.jpg'
  },
  {
    id: 2,
    text: '썰매가 오래되어 하룻밤 안에 모든 집에 선물을 주기는 어려울 것 같아요. 무슨 방법이 없을까요?',
    image: 'KakaoTalk_20201209_225750229.jpg'
  },
  {
    id: 3,
    text: '드디어 기다리던 크리스마스 당일! 어떤 옷을 입을까요?',
    image: 'KakaoTalk_20201210_182234281.jpg'
  },
  {
    id: 4,
    text: '빨리 배달을 가야하는데 한 루돌프가 약속시간에 나타나지 않았어요! 아마 자고 있는 것 같은데… 어떻게 하죠?',
    image: 'KakaoTalk_20201210_183557446.jpg'
  },
  {
    id: 5,
    text: '슝슝~ 빠르게 배달 장소로 가던 중, 눈길을 홀로 걷는 아이를 발견했어요. 도움이 필요한 걸까요?',
    image: 'KakaoTalk_20201210_185121571.jpg'
  },
  {
    id: 6,
    text: '아이 머리 맡에 선물을 놓아주다 아이와 눈이 마주쳤어요! 인사를 할까요?',
    image: 'KakaoTalk_20201210_191109100.jpg'
  },
  {
    id: 7,
    text: '이번에 찾아간 친구는 넉넉지 못한 형편으로 자주 끼니를 거르는 친구예요. 선물과 함께 간식을 남겨두려고 해요. 어떤 간식을 남겨둘까요?',
    image: 'KakaoTalk_20201210_193043639.jpg'
  },
  {
    id: 8,
    text: '모든 선물을 배달하고 긴 하루가 끝이 났어요~ 집으로 들어와 누운 당신. 가장 기억에 남은 아이는?',
    image: 'KakaoTalk_20201210_193537902.jpg'
  }
];

export const options = [
  {
    questionId: 1,
    id: 1,
    text: '전세계 아이들이 나만 바라봐, 1월부터',
    weight: [10, 1, 10, 1, 10, 1, 10, 1, 10, 10, 10, 10, 1, 1, 1, 1]
  },
  {
    questionId: 1,
    id: 2,
    text: '저도 조금은 쉬어야죠! 6월부터',
    weight: [3, 1, 3, 1, 3, 1, 3, 1, 3, 3, 3, 3, 1, 1, 1, 1]
  },
  {
    questionId: 1,
    id: 3,
    text: '찬 바람불면 시작할게요~ 9월부터',
    weight: [1, 3, 1, 3, 1, 3, 1, 3, 1, 1, 1, 1, 3, 3, 3, 3]
  },
  {
    questionId: 1,
    id: 4,
    text: '영감은 마감에 나오는 법! 12월부터',
    weight: [1, 10, 1, 10, 1, 10, 1, 10, 1, 1, 1, 1, 10, 10, 10, 10]
  },
  {
    questionId: 2,
    id: 1,
    text: '썰매를 어서 개조해야죠!',
    weight: [3, 3, 3, 3, 1, 1, 1, 1, 3, 1, 3, 1, 3, 1, 3, 1]
  },
  {
    questionId: 2,
    id: 2,
    text: '조금 늦더라도, 한 명 한 명 직접 방문할래요',
    weight: [1, 1, 1, 1, 5, 5, 5, 5, 1, 5, 1, 5, 1, 5, 1, 5]
  },
  {
    questionId: 2,
    id: 3,
    text: '총알배송 택배 몰라요?\n택배사와 협업할래요',
    weight: [5, 5, 5, 5, 1, 1, 1, 1, 5, 1, 5, 1, 5, 1, 5, 1]
  },
  {
    questionId: 2,
    id: 4,
    text: '크리스마스 안에는 도착하게! \n루돌프들과 분담할게요',
    weight: [1, 1, 1, 1, 3, 3, 3, 3, 1, 3, 1, 3, 1, 3, 1, 3]
  },
  {
    questionId: 3,
    id: 1,
    text: '밤 사이에 달리려면 편해야죠~ \n러닝화에 트레이닝복',
    weight: [3, 1, 6, 4, 3, 1, 6, 4, 3, 3, 6, 6, 1, 1, 4, 4]
  },
  {
    questionId: 3,
    id: 2,
    text: '산타의 정석, 빨간색 산타복과 산타모자',
    weight: [5, 1, 5, 1, 5, 1, 5, 1, 5, 5, 5, 5, 1, 1, 1, 1]
  },
  {
    questionId: 3,
    id: 3,
    text: '빨간색 산타복은 고정관념! 내가 고른 옷을 입을래요',
    weight: [1, 3, 1, 3, 1, 3, 1, 3, 1, 1, 1, 1, 3, 3, 3, 3]
  },
  {
    questionId: 3,
    id: 4,
    text: '내가 제일 잘나가! 밤에도 잘보이게 야광 산타복',
    weight: [1, 5, 6, 10, 1, 5, 6, 10, 1, 1, 6, 6, 5, 5, 10, 10]
  },
  {
    questionId: 4,
    id: 1,
    text: '아이들이 기다리는데! \n전화 걸어서 나오라고 해야죠~',
    weight: [1, 1, 1, 1, 3, 3, 3, 3, 1, 3, 1, 3, 1, 3, 1, 3]
  },
  {
    questionId: 4,
    id: 2,
    text: '어떻게 늦을수가 있죠? 루돌프 당신은 해고야!',
    weight: [5, 5, 5, 5, 1, 1, 1, 1, 5, 1, 5, 1, 5, 1, 5, 1]
  },
  {
    questionId: 4,
    id: 3,
    text: '일단 온 루돌프들과 출발할게요, 바쁘다 바빠!',
    weight: [3, 3, 3, 3, 1, 1, 1, 1, 3, 1, 3, 1, 3, 1, 3, 1]
  },
  {
    questionId: 4,
    id: 4,
    text: '너무 피곤했나봐… 그냥 쉬게 해줄래요!',
    weight: [1, 1, 1, 1, 5, 5, 5, 5, 1, 5, 1, 5, 1, 5, 1, 5]
  },
  {
    questionId: 5,
    id: 1,
    text: '이 추운 날에 혼자? 당장 내려가서 도와줄래요',
    weight: [1, 1, 1, 1, 1, 1, 1, 1, 5, 5, 5, 5, 5, 5, 5, 5]
  },
  {
    questionId: 5,
    id: 2,
    text: '크리스마스를 맞아서 산책 중인 거 아닐까요?',
    weight: [5, 5, 5, 5, 5, 5, 5, 5, 1, 1, 1, 1, 1, 1, 1, 1]
  },
  {
    questionId: 5,
    id: 3,
    text: '정체를 들키기는 곤란해요… 루돌프를 대신 보내볼게요',
    weight: [3, 3, 3, 3, 3, 3, 3, 3, 1, 1, 1, 1, 1, 1, 1, 1]
  },
  {
    questionId: 5,
    id: 4,
    text: '그래도 배달이 우선이죠! 배달부터 하고 다시 와볼게요',
    weight: [1, 1, 1, 1, 1, 1, 1, 1, 3, 3, 3, 3, 3, 3, 3, 3]
  },
  {
    questionId: 6,
    id: 1,
    text: '이대로 정체를 들켜선 안돼! 빠르게 도망갈래요',
    weight: [10, 10, 6, 6, 5, 5, 1, 1, 10, 5, 6, 1, 10, 5, 6, 1]
  },
  {
    questionId: 6,
    id: 2,
    text: '인사 정도는 괜찮지 않을까요? 수줍게 손인사 할래요',
    weight: [6, 6, 4, 4, 3, 3, 1, 1, 6, 3, 4, 1, 6, 3, 4, 1]
  },
  {
    questionId: 6,
    id: 3,
    text: '이 날을 위해 사인 연습을 해뒀죠, 멋지게 사인 해줄래요',
    weight: [1, 1, 3, 3, 4, 4, 6, 6, 1, 4, 3, 6, 1, 4, 3, 6]
  },
  {
    questionId: 6,
    id: 4,
    text: '이왕 들킨 거, 자리 잡고 아이랑 수다 좀 떨다 갈게요',
    weight: [1, 1, 5, 5, 6, 6, 10, 10, 1, 6, 5, 10, 1, 6, 5, 10]
  },
  {
    questionId: 7,
    id: 1,
    text: '상큼한 한라봉 감귤 주스',
    weight: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  },
  {
    questionId: 7,
    id: 2,
    text: '달콤하고 고소한 초코칩 쿠키',
    weight: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  },
  {
    questionId: 7,
    id: 3,
    text: '바삭바삭한 마늘토스트',
    weight: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  },
  {
    questionId: 7,
    id: 4,
    text: '건강을 담은 100% 사과즙',
    weight: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  },
  {
    questionId: 8,
    id: 1,
    text: '정성스레 쓴 편지를 남겨놓은 아이가 기억나요',
    weight: [6, 6, 4, 4, 6, 6, 4, 4, 3, 3, 1, 1, 3, 3, 1, 1]
  },
  {
    questionId: 8,
    id: 2,
    text: '혼자서 저를 기다리다 \n까무룩 잠이 든 아이가 기억나요',
    weight: [5, 5, 1, 1, 5, 5, 1, 1, 8, 8, 4, 4, 8, 8, 4, 4]
  },
  {
    questionId: 8,
    id: 3,
    text: '스콘에 고추냉이 넣어 놓은 \n장난꾸러기 아이가 기억나요',
    weight: [1, 1, 3, 3, 1, 1, 3, 3, 4, 4, 6, 6, 4, 4, 6, 6]
  },
  {
    questionId: 8,
    id: 4,
    text: '밤새 기다리다 저와 수다떤 아이가 기억나요',
    weight: [4, 4, 8, 8, 4, 4, 8, 8, 1, 1, 5, 5, 1, 1, 5, 5]
  }
];

export const results = [
  {
    id: 1,
    type: 'INTJ',
    title: '알파고',
    subtitle: '치밀한 신의 한 수',
    image: 'INTJ.jpg'
  },
  {
    id: 2,
    type: 'INTP',
    title: '노벨상',
    subtitle: '썰매 대신 드론은 어때?',
    image: 'INTP.jpg'
  },
  {
    id: 3,
    type: 'ENTJ',
    title: '협회장',
    subtitle: '모두 나를 따르라!',
    image: 'ENTJ.jpg'
  },
  {
    id: 4,
    type: 'ENTP',
    title: '백분토론',
    subtitle: '어른이한테는 선물 없나요?',
    image: 'ENTP.jpg'
  },
  {
    id: 5,
    type: 'INFJ',
    title: '마니또',
    subtitle: '우는 아이도 선물이 필요해~',
    image: 'INFJ.jpg'
  },
  {
    id: 6,
    type: 'INFP',
    title: '로맨틱',
    subtitle: '진심듬뿍 정성가득,',
    image: 'INFP.jpg'
  },
  {
    id: 7,
    type: 'ENFJ',
    title: '루돌프 노조장',
    subtitle: '루돌프에게도 권리가 있다!',
    image: 'ENFJ.jpg'
  },
  {
    id: 8,
    type: 'ENFP',
    title: '파티쟁이',
    subtitle: '나는 애들 직접 보고 선물 줄래~!',
    image: 'ENFP.jpg'
  },
  {
    id: 9,
    type: 'ISTJ',
    title: '컴활1급',
    subtitle: '계획과 정산은 맡겨주세요,',
    image: 'ISTJ.jpg'
  },
  {
    id: 10,
    type: 'ISFJ',
    title: '관심법',
    subtitle: '주는 선물마다 만족도 100%!',
    image: 'ISFJ.jpg'
  },
  {
    id: 11,
    type: 'ESTJ',
    title: '단호박',
    subtitle: '안 돼, 안 바꿔줘.',
    image: 'ESTJ.jpg'
  },
  {
    id: 12,
    type: 'ESFJ',
    title: '인기쟁이',
    subtitle: '준비한 선물이 오조오억개!',
    image: 'ESFJ.jpg'
  },
  {
    id: 13,
    type: 'ISTP',
    title: '맥가이버',
    subtitle: '썰매 속도의 한계를 보여줄게!',
    image: 'ISTP.jpg'
  },
  {
    id: 14,
    type: 'ISFP',
    title: '패션왕',
    subtitle: '틀에 박힌 산타복은 NO!',
    image: 'ISFP.jpg'
  },
  {
    id: 15,
    type: 'ESTP',
    title: '세계정복',
    subtitle: '히말라야가 나를 부른다,',
    image: 'ESTP.jpg'
  },
  {
    id: 16,
    type: 'ESFP',
    title: '수퍼스타',
    subtitle: '헤이 모두들 안녕!',
    image: 'ESFP.jpg'
  }
];

export const descriptions = [
  {
    resultId: 1,
    resultType: 'INTJ',
    description: '당신은 큰 그림을 볼 줄 아는 전략적 산타!'
  },
  {
    resultId: 1,
    resultType: 'INTJ',
    description: '어떤 일을 하든 우선 비전부터 제시해요.'
  },
  {
    resultId: 1,
    resultType: 'INTJ',
    description: '이번 배달에는 얼마가 들까요? 돈관리를 철저히 잘하는군요.'
  },
  {
    resultId: 1,
    resultType: 'INTJ',
    description: '크리스마스 선물도 착불로 보낼 당신이에요.'
  },
  {
    resultId: 1,
    resultType: 'INTJ',
    description:
      '따뜻한 마음을 나누는 선물이긴 해도, 그냥 대충 넘어갈 순 없죠!\\n당신은 미리 정확하게 계산된 움직임과 철저한 계획에 따라 선물을 배달합니다.'
  },
  {
    resultId: 1,
    resultType: 'INTJ',
    description: '동물들에게 한없이 친절한 당신이에요, 루돌프를 가장 아껴줘요.'
  },
  {
    resultId: 1,
    resultType: 'INTJ',
    description:
      '밤늦게 선물 배달하고 얼른 도망올래요, 사람들과 마주치기는 민망해요.'
  },
  {
    resultId: 2,
    resultType: 'INTP',
    description: '당신은 반짝이는 아이디어로 가득찬 산타!'
  },
  {
    resultId: 2,
    resultType: 'INTP',
    description: '귀찮은 일은 딱 질색이에요.'
  },
  {
    resultId: 2,
    resultType: 'INTP',
    description:
      '꼭 내가 썰매를 타고 배달을 가야 하나요? 갑자기 드론을 만들기 시작하는 당신...'
  },
  {
    resultId: 2,
    resultType: 'INTP',
    description:
      '언제나 아이디어를 내놓지만 나를 이해 못하는 이웃 산타들도 있어요.'
  },
  {
    resultId: 2,
    resultType: 'INTP',
    description:
      '하지만 신경 안 써요, 나를 이해해주는 친구들만 몇 명 있으면 돼요.'
  },
  {
    resultId: 2,
    resultType: 'INTP',
    description: '우는 아이들을 잘 달래요. 근데 사실 왜 우는지 모르겠어요.'
  },
  {
    resultId: 2,
    resultType: 'INTP',
    description: '배달이 얼마 안 남았는데, 아직 드론을 만들고 있는 당신.'
  },
  {
    resultId: 2,
    resultType: 'INTP',
    description: '하나 시작했으면 일단 끝은 보는군요!'
  },
  {
    resultId: 3,
    resultType: 'ENTJ',
    description: '산타 협회장을 맡은 당신!'
  },
  {
    resultId: 3,
    resultType: 'ENTJ',
    description: '천성적으로 타고난 리더예요.'
  },
  {
    resultId: 3,
    resultType: 'ENTJ',
    description: '모든 일은 미리 계획해놓은 대로 진행해야 직성이 풀리는 당신 .'
  },
  {
    resultId: 3,
    resultType: 'ENTJ',
    description: '썰매가 고장 났다고요? 어서 새로운 방법을 찾아야 해요!'
  },
  {
    resultId: 3,
    resultType: 'ENTJ',
    description: '팀플에 답답한 사람이 있으면 속이 터져 버리는군요.'
  },
  {
    resultId: 3,
    resultType: 'ENTJ',
    description: '오늘따라 게으름을 피우는 루돌프가 있는데, 개인 면담 각이에요.'
  },
  {
    resultId: 3,
    resultType: 'ENTJ',
    description: '피해 주는 거, 피해 받는 거 딱 질색이에요!'
  },
  {
    resultId: 3,
    resultType: 'ENTJ',
    description: '혼자 있을 땐 딴 생각에 잘 빠지는군요. 상상력이 끝도 없어요.'
  },
  {
    resultId: 4,
    resultType: 'ENTP',
    description: '왜?를 먼저 던지는 당신은 호기심이 강한 산타!'
  },
  {
    resultId: 4,
    resultType: 'ENTP',
    description:
      '왜 어른은 선물을 주지 않는 거죠?! 옆 동네 산타들과 토론을 시작해요.'
  },
  {
    resultId: 4,
    resultType: 'ENTP',
    description: '마음에 걸리는 일이 있으면 조목조목 따져야 직성이 풀립니다.'
  },
  {
    resultId: 4,
    resultType: 'ENTP',
    description: '열변을 토하다보면 가끔 선물 배달에 늦는 경우가 생겨요'
  },
  {
    resultId: 4,
    resultType: 'ENTP',
    description: '하지만 지나간 일에 후회는 없어요, 빨리 과거를 털어내요'
  },
  {
    resultId: 4,
    resultType: 'ENTP',
    description: '하나에 꽂히면 그것만 하는 성격이에요'
  },
  {
    resultId: 4,
    resultType: 'ENTP',
    description: '다방면에 재능이 많은 산타로군요.'
  },
  {
    resultId: 4,
    resultType: 'ENTP',
    description: '옆 동네 산타들과 잘 지내는데, 혼자만의 시간도 필요해요'
  },
  {
    resultId: 5,
    resultType: 'INFJ',
    description: '서로서로 도우며 살기를 원하는 당신은 외유내강형 산타!'
  },
  {
    resultId: 5,
    resultType: 'INFJ',
    description: '가끔 생각이 너무 많아요.'
  },
  {
    resultId: 5,
    resultType: 'INFJ',
    description: '우는 아이에게도 선물을 줘야 하는 거 아닌가요?'
  },
  {
    resultId: 5,
    resultType: 'INFJ',
    description: '섬세한 당신은 우는 아이의 선물에 달콤한 쿠키를 더 넣어놨어요.'
  },
  {
    resultId: 5,
    resultType: 'INFJ',
    description: '할 일은 미리미리 해치워요.'
  },
  {
    resultId: 5,
    resultType: 'INFJ',
    description: '선의를 추구하는 당신에게는 단호함과 강한 결단력도 있습니다.'
  },
  {
    resultId: 5,
    resultType: 'INFJ',
    description:
      '배달이 끝나면, 재충전을 위해 혼자 여행을 갈거예요. 생각할 시간이 필요해요!'
  },
  {
    resultId: 5,
    resultType: 'INFJ',
    description: '예술적으로 감수성이 풍부한 산타로군요.'
  },
  {
    resultId: 6,
    resultType: 'INFP',
    description: '정성스런 손편지를 선물하는 당신은 낭만적인 산타!'
  },
  {
    resultId: 6,
    resultType: 'INFP',
    description:
      '내 편지를 받고 기뻐할 아이들을 생각하면, 손목쯤이야 오늘 하루 버려도 괜찮아요.'
  },
  {
    resultId: 6,
    resultType: 'INFP',
    description: '자기표현에 특출나군요.'
  },
  {
    resultId: 6,
    resultType: 'INFP',
    description: '산타 MBTI를 누구보다 먼저 했어요, 심리테스트 좋아하는 편!'
  },
  {
    resultId: 6,
    resultType: 'INFP',
    description: '의사소통 능력이 뛰어나요.'
  },
  {
    resultId: 6,
    resultType: 'INFP',
    description: '본인이 세운 원리원칙에 따라 행동해야 마음이 편해지는군요.'
  },
  {
    resultId: 6,
    resultType: 'INFP',
    description: '산타라도 자기 계발은 꾸준히 해야해요.'
  },
  {
    resultId: 6,
    resultType: 'INFP',
    description:
      '침착하고 가끔 수줍어 보이기도 하지만 속에는 열정이 불타는 유노윤호 스타일!'
  },
  {
    resultId: 7,
    resultType: 'ENFJ',
    description: '루돌프 주 52시간 근무를 주장하는 당신은 정의로운 산타!'
  },
  {
    resultId: 7,
    resultType: 'ENFJ',
    description: '루돌프에게도 산재 처리를 제대로 해줘야 한다고요.'
  },
  {
    resultId: 7,
    resultType: 'ENFJ',
    description: '다같이 살기 좋은 공동체를 꿈꾸는군요.'
  },
  {
    resultId: 7,
    resultType: 'ENFJ',
    description:
      '다른 산타들도 당신을 멈추지는 못합니다. 특유의 설득력을 갖고 있어요.'
  },
  {
    resultId: 7,
    resultType: 'ENFJ',
    description: '가끔 좀 시끄러운 산타예요.'
  },
  {
    resultId: 7,
    resultType: 'ENFJ',
    description: '말과 행동은 언제나 일치해야 해요, 언행일치 필수!'
  },
  {
    resultId: 7,
    resultType: 'ENFJ',
    description: '센스 있고 눈치가 빨라요.'
  },
  {
    resultId: 7,
    resultType: 'ENFJ',
    description: '산타 협회 회원이긴 하지만, 마이웨이 기질도 약간 있어요.'
  },
  {
    resultId: 8,
    resultType: 'ENFP',
    description:
      '벌써 크리스마스라니! 너무 신나 흥분을 감추지 못하는 당신이네요.'
  },
  {
    resultId: 8,
    resultType: 'ENFP',
    description:
      '이날만을 기다렸어요. 밤에 배달하면 아이들을 못보니까, 낮에 배달하기로 했어요!'
  },
  {
    resultId: 8,
    resultType: 'ENFP',
    description: '정신이 산만하고 생각도 많아요.'
  },
  {
    resultId: 8,
    resultType: 'ENFP',
    description: '선물 배달하다가 가끔 몇 집을 빼먹기도 해요.'
  },
  {
    resultId: 8,
    resultType: 'ENFP',
    description: '옆 동네 산타들이 다들 고민 상담을 하러와요.'
  },
  {
    resultId: 8,
    resultType: 'ENFP',
    description: '배달이 다 끝나면, 파티를 열 거예요.'
  },
  {
    resultId: 8,
    resultType: 'ENFP',
    description: '아직 세세한 파티 계획은 없어요, 즉흥적인 게 최고예요!'
  },
  {
    resultId: 8,
    resultType: 'ENFP',
    description: '거짓말 잘 못해서 솔직하단 얘기 많이 들어요.'
  },
  {
    resultId: 9,
    resultType: 'ISTJ',
    description: '배달 후 엑셀로 회계를 하고 있는 당신은 원리원칙적 산타!'
  },
  {
    resultId: 9,
    resultType: 'ISTJ',
    description: '선물 배달 계획도 시간대별로 세세히 짜요.'
  },
  {
    resultId: 9,
    resultType: 'ISTJ',
    description: '약속 시간은 꼭 지키려고 노력해요.'
  },
  {
    resultId: 9,
    resultType: 'ISTJ',
    description: '팀플은 오히려 힘들어요, 조용히 나 혼자 처리할래요.'
  },
  {
    resultId: 9,
    resultType: 'ISTJ',
    description: '남한테 관심이 별로 없어요.'
  },
  {
    resultId: 9,
    resultType: 'ISTJ',
    description: '루돌프 한 마리가 배달에 늦었어요, 화가 나네요!'
  },
  {
    resultId: 9,
    resultType: 'ISTJ',
    description: '책임감이 투철해서 일처리가 깔끔한 편!'
  },
  {
    resultId: 9,
    resultType: 'ISTJ',
    description: '올해 배달도 아무탈 없이 넘어갔어요, 참 다행이에요.'
  },
  {
    resultId: 10,
    resultType: 'ISFJ',
    description: '당신은 아이들의 마음을 꿰뚫어보고 취향 저격 선물을 준비하죠'
  },
  {
    resultId: 10,
    resultType: 'ISFJ',
    description: '계획 세우는 거 좋아하고 메모도 잘해요.'
  },
  {
    resultId: 10,
    resultType: 'ISFJ',
    description: '섬세하다보니 일 진행 속도가 느리게 보이기도 해요.'
  },
  {
    resultId: 10,
    resultType: 'ISFJ',
    description: '그래도 선물 배달에는 늦지 않았어요, 시간 약속은 잘 지켜요!'
  },
  {
    resultId: 10,
    resultType: 'ISFJ',
    description: '원칙주의자예요.'
  },
  {
    resultId: 10,
    resultType: 'ISFJ',
    description: '옆 동네 산타들하고 놀다보면 스트레스를 많이 받아요.'
  },
  {
    resultId: 10,
    resultType: 'ISFJ',
    description: '산타 협회 회원이긴 한데 앞에 나서는 건 싫어요.'
  },
  {
    resultId: 10,
    resultType: 'ISFJ',
    description: '사람을 좋아하는 만큼, 외로움을 타는 편!'
  },
  {
    resultId: 11,
    resultType: 'ESTJ',
    description: '당근과 채찍을 잘 쓰는 당신은 단호박 산타!'
  },
  {
    resultId: 11,
    resultType: 'ESTJ',
    description:
      '어떤 아이가 선물 교환을 요구했지만, 단순 변심으로 인한 교환은 곤란하죠~'
  },
  {
    resultId: 11,
    resultType: 'ESTJ',
    description:
      '옆 동네 산타들은 융통성 없다고 말하지만, 계획적이라고 해줄래요?'
  },
  {
    resultId: 11,
    resultType: 'ESTJ',
    description: '지적받는 걸 싫어해요.'
  },
  {
    resultId: 11,
    resultType: 'ESTJ',
    description: '옳고 그름을 잘 따지는 편!'
  },
  {
    resultId: 11,
    resultType: 'ESTJ',
    description: '특유의 책임감으로 팀플을 이끌어가는군요.'
  },
  {
    resultId: 11,
    resultType: 'ESTJ',
    description: '혹시, 팀플을 혼자 다 짊어지고 가지는 않나요?'
  },
  {
    resultId: 11,
    resultType: 'ESTJ',
    description: '동료 산타들에 대한 의리가 넘쳐요, 배신은 금물!'
  },
  {
    resultId: 12,
    resultType: 'ESFJ',
    description: '주변 사람들을 알뜰살뜰 챙기는 당신은 사교성 갑 산타!'
  },
  {
    resultId: 12,
    resultType: 'ESFJ',
    description: '새로운 사람들 만나는 걸 좋아해요.'
  },
  {
    resultId: 12,
    resultType: 'ESFJ',
    description: '옆 동네 산타들과 모였을 때 분위기가 어색해지는 걸 못 참아요.'
  },
  {
    resultId: 12,
    resultType: 'ESFJ',
    description:
      '루돌프 친구들이 가끔 고민 상담을 하러 와요, 힘들어도 다 들어줘야죠!'
  },
  {
    resultId: 12,
    resultType: 'ESFJ',
    description: '공감력이 뛰어나요.'
  },
  {
    resultId: 12,
    resultType: 'ESFJ',
    description: '그 만큼 남 눈치도 많이 봐서 스트레스를 받기도 해요.'
  },
  {
    resultId: 12,
    resultType: 'ESFJ',
    description: '책, 영화, 공연을 보며 영감 얻기를 좋아하는 예술적인 편!'
  },
  {
    resultId: 13,
    resultType: 'ISTP',
    description: '당신은 어디서든 살아남을 재주 많은 산타!'
  },
  {
    resultId: 13,
    resultType: 'ISTP',
    description:
      '모든 일은 효율적이어야 해요, 선물 배달도 효율적으로 하기 위해 썰매 개조중!'
  },
  {
    resultId: 13,
    resultType: 'ISTP',
    description: '혼자만의 시간을 매우 좋아해요.'
  },
  {
    resultId: 13,
    resultType: 'ISTP',
    description: '관심 받는 건 너무 좋은데, 시끄러운 건 또 싫어해요.'
  },
  {
    resultId: 13,
    resultType: 'ISTP',
    description:
      '산타 MBTI 나오자마자 혼자 조용히 해봤어요, 이런 거 좋아하는 편.'
  },
  {
    resultId: 13,
    resultType: 'ISTP',
    description: '좋아하는 건 끝까지 해도, 싫은 건 절대 죽어도 안해요.'
  },
  {
    resultId: 13,
    resultType: 'ISTP',
    description: '많진 않아도 내가 아끼고 좋아하는 산타들이 몇 명은 있어요.'
  },
  {
    resultId: 13,
    resultType: 'ISTP',
    description: '마지막으로 방 정리 한 게 언제인지 기억이 안나요.'
  },
  {
    resultId: 14,
    resultType: 'ISFP',
    description: '당신은 조용한 관종형 산타!'
  },
  {
    resultId: 14,
    resultType: 'ISFP',
    description: '그저 똑같은 빨간 옷에 빨간 모자 산타복은 절대 싫어요~!'
  },
  {
    resultId: 14,
    resultType: 'ISFP',
    description:
      '춥긴 해도 내 개성은 포기할 수 없어서 비닐 시스루 산타복을 택했어요.'
  },
  {
    resultId: 14,
    resultType: 'ISFP',
    description: '당신의 안목과 센스는 모두가 인정해요.'
  },
  {
    resultId: 14,
    resultType: 'ISFP',
    description:
      '산타도 노후를 대비해야 한다는데, 그런 장기 계획은 나중에 하는 걸로!'
  },
  {
    resultId: 14,
    resultType: 'ISFP',
    description: '일단 하기 싫은 건 끝까지 미뤄요.'
  },
  {
    resultId: 14,
    resultType: 'ISFP',
    description: '약속 잡는 거 진짜 싫은데, 막상 또 나가면 잘 놀아요.'
  },
  {
    resultId: 14,
    resultType: 'ISFP',
    description: '올해도 다이어리를 끝까지 못 썼어요.'
  },
  {
    resultId: 15,
    resultType: 'ESTP',
    description: '아무도 가지 않은 길을 가는 당신은 완전 사업가 체질 산타!'
  },
  {
    resultId: 15,
    resultType: 'ESTP',
    description: '누구도 시도해보지 않은 히말라야 선물 배달을 계획해요.'
  },
  {
    resultId: 15,
    resultType: 'ESTP',
    description: '리더십 최고예요, 산타 협회장 후보!'
  },
  {
    resultId: 15,
    resultType: 'ESTP',
    description: '사람 만나는 거 좋아하는데 외로움을 많이 타요.'
  },
  {
    resultId: 15,
    resultType: 'ESTP',
    description: '하고 싶은 일은 무조건 해야해요!'
  },
  {
    resultId: 15,
    resultType: 'ESTP',
    description: '약간 관종끼도 있어요.'
  },
  {
    resultId: 15,
    resultType: 'ESTP',
    description: '그냥 대충 살아도 나름 괜찮아요~ 스트레스도 잘 안 받아요.'
  },
  {
    resultId: 16,
    resultType: 'ESFP',
    description: '당신은 연예인으로 태어났어야 하는 산타!'
  },
  {
    resultId: 16,
    resultType: 'ESFP',
    description: '본인 등장할 때마다 스스로 BGM을 깔아요, 천상 연예인이에요.'
  },
  {
    resultId: 16,
    resultType: 'ESFP',
    description: '침묵 흐르는 게 제일 싫어요, 아무말이나 해서 분위기를 살려요.'
  },
  {
    resultId: 16,
    resultType: 'ESFP',
    description: '모르는 사람이랑도 엄청 빨리 친해져요, 사교성 갑!'
  },
  {
    resultId: 16,
    resultType: 'ESFP',
    description: '오지랖도 넓어요.'
  },
  {
    resultId: 16,
    resultType: 'ESFP',
    description: '혼자 가만히 있으면 지루해요, 옆 집 산타네 집에 매일 놀러가요.'
  },
  {
    resultId: 16,
    resultType: 'ESFP',
    description:
      '파티쟁이 산타랑 크리스마스 파티를 열거예요, 아직 구체적인 계획은 없지만요!'
  }
];
