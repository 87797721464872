import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import CDN_URL from 'components/commons/globalUrl';

import { withRouter } from 'react-router-dom';

import './EmptyList.scss';

function EmptyList({
  className,
  contentText,
  subText,
  isOneStore,
  isMyStore,
  onOpenDiscoverModal
}) {
  return (
    <Fragment>
      {isOneStore ? (
        <div className={classNames(className)}>
          {isMyStore && (
            <button
              type="button"
              className="one-store-add-button"
              onClick={onOpenDiscoverModal}
            />
          )}
          <div className="one-store-empty-text mt-4 text-center text-secondary">
            상품이 없습니다.
            <br />
            추천하고 싶은 경험 상품을
            <br />
            즐겁게 발굴해보세요!
          </div>
        </div>
      ) : (
        // <div
        //   className={classNames(
        //     className,
        //     'empty-list w-100 text-center text-secondary pt-3 pl-3 pr-3 pb-3'
        //   )}
        // >
        //   {contentText}
        // </div>
        <div className={classNames(className, 'empty-list')}>
          <img
            src="/images/error-icon.svg"
            alt="에러 로고"
            className="d-block"
          />
          <div className="empty-list-text-box text-center">
            <div className="empty-list-contentText">{contentText}</div>
            <div className="empty-list-subText">{subText}</div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

EmptyList.propTypes = {
  className: PropTypes.string,
  contentText: PropTypes.string,
  subText: PropTypes.string
};

EmptyList.defaultProps = {
  className: '',
  contentText: '리스트가 없습니다.',
  subText: ''
};

export default withRouter(EmptyList);
