import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
// import { useLoginStore } from '../components/Login/LoginStore';
import withUser from 'lib/hoc/withUser';

function PrivateRoute({ isLoggedIn, isProvider, user, children, ...rest }) {
  // const { isLoggedIn } = useLoginStore();
  // console.log({ isLoggedIn });
  // console.log({children});
  // console.log({rest})
  // const location = useLocation();
  // console.log({location})
  return isLoggedIn ? (
    <Route {...rest} />
  ) : (
    <Route
      exact={rest.exact ? true : false}
      path={rest.path}
      render={({ location }) => {
        console.log('private route');
        // console.log({ location });
        return (
          <Redirect
            to={{
              pathname: '/store/signin',
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
}
export default withUser(PrivateRoute);
