/* eslint-disable no-console */
import React from 'react';

import PostList from 'components/Post/PostList';
import Button from 'components/commons/Button';
import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';

import { MainSectionWrapper } from 'styles/AppStyle';

function OneProductPosts({ storeId, productId, product, oneProduct }) {
  // console.log('OneProductPosts');
  // console.log({ storeId });
  // console.log({ productId });
  // console.log({ product });
  // console.log({ oneProduct });
  return (
    <MainSectionWrapper className="one-product">
      <ScrollToTopOnMount />
      <PostList
        productId={productId}
        store={oneProduct?.store}
        product={product}
        canAdd
      />
      <div className="best-product-button one-product-button">
        <Button
          color="w-from"
          buttonText={`${
            product ? product.providerName : '공급자'
          } 자세히 보기`}
          buttonType="link"
          to={`/provider/${product ? product.providerId : ''}`}
        />
      </div>
    </MainSectionWrapper>
  );
}

export default OneProductPosts;
