import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import { getStore, getBookmark } from 'api/store';

import sentry from 'lib/sentry';
import withUi from 'lib/hoc/withUi';

// import AlertModal from 'components/modal/AlertModal';
import OneStoreListTemplate from 'components/oneStore/OneStoreListTemplate';
import OneStoreFollowItem from 'components/oneStore/OneStoreFollowItem';

function OneStoreFollowContainer({
  storeId,
  UiAction,
  isProvider,
  isComponent
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [followList, setFollowList] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [lastKey, setLastKey] = useState(null);
  const [store, setStore] = useState({});

  const headerPrefixText =
    store && store.userName ? `${store.userName}님의 ` : '';
  const headerText = !isProvider
    ? `${headerPrefixText}소비자 친구들`
    : `${headerPrefixText}공급자 친구들`;

  const targetType = !isProvider ? '1S' : 'PP';

  useEffect(
    () => {
      if (!isComponent) {
        UiAction.setHeader(headerText);
      }
    },
    [UiAction, headerText, isComponent]
  );

  useEffect(
    () => {
      async function getStoreInfo() {
        try {
          const response = await getStore(storeId);

          if (!response || !response.data.data || !response.data.success) {
            throw new Error(
              `1S-fromlist api Error(storeInfo) - storeId: ${storeId}`
            );
          }

          setStore(response.data.data);
        } catch (e) {
          sentry.captureException(e);
        }
      }

      if (!isComponent) {
        getStoreInfo();
      }
    },
    [isComponent, storeId]
  );

  const infiniteRef = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMoreItems,
    onLoadMore: getCustomerFollowList,
    scrollContainer: 'window'
  });

  async function getCustomerFollowList() {
    const params = {
      lastKey,
      pageCount: 20,
      storeId,
      targetType
    };

    try {
      setIsLoading(true);
      const response = await getBookmark(params);

      if (!response || !response.data || !response.data.success) {
        throw new Error('error!');
      }

      const { data } = response.data;
      const { items } = data;

      setIsLoading(false);
      setLastKey(data.lastKey);

      setFollowList([...followList, ...items]);
      setHasMoreItems(!!data.lastKey);
    } catch (e) {
      // setIsLoading(false);
      sentry.captureException(e.message);
    }
  }

  return (
    <OneStoreListTemplate
      haveList={followList && followList.length > 0}
      infiniteRef={infiniteRef}
      isLoading={isLoading}
      headerText={headerText}
    >
      {followList.map((follow) => (
        <OneStoreFollowItem
          key={follow.bookmarkId}
          follow={follow}
          isProvider={isProvider}
        />
      ))}
    </OneStoreListTemplate>
  );
}

export default withRouter(withUi(OneStoreFollowContainer));
