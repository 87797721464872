import React, { Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';

import Button from 'components/commons/Button';
import RenderField from 'components/reduxForm/RenderField';
import RenderFieldTextArea from 'components/reduxForm/RenderFieldTextArea';
import RenderSelectField from 'components/reduxForm/RenderSelectField';
import PictureInput from 'components/commons/PictureInput';
import AddressModal from 'components/modal/AddressModal';
import Agreement from 'components/users/Agreement';

import {
  required,
  checkEmailType,
  numeric,
  checkDupilcateWhenProviderSignup,
  minLength6,
  maxLength16,
  alphanumeric,
  pwdConfirm,
  tel,
  idMinLength6
} from 'lib/form/validations';

import 'components/users/SignUpForm/SignUpForm.scss';

function ProviderSignupForm({
  pristine,
  invalid,
  submitting,
  isCheckedSameAddress,
  isHiddenForm,
  isLoading,
  isOpenSearchAddress,
  isPrivate,
  providerProfileImage,
  businessLicenseImage,
  passbookImage,
  onChangeImage,
  onSubmit,
  handleSubmit,
  onChangeId,
  onChangeAddress,
  onToggleAddress,
  urlId,
  isCheckdPrivacy,
  onChangeCheckbox,
  isCheckedAgreement,
  onOpenAgreementModal
}) {
  return (
    <Fragment>
      <div className="signup provider-signup p-3">
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <h3 className="form-headline">필수 입력 항목</h3>
          <Field
            name="id"
            label="아이디"
            type="text"
            className="form-with-button"
            component={RenderField}
            onChange={onChangeId}
            placeholder="6자 이상 영문, 숫자, 특수문자"
            validate={[required, alphanumeric, idMinLength6]}
            pattern={/\s/g}
            isRequired
          />
          <div className="signup-warning-field">
            <span className="warning-mark">※</span>
            <p className="provider-id-warning">
              아이디는 공급자의 URL로 적용됩니다. <br />
              신중하게 입력주세요 ^^
              <br />
              <span>
                (from.kr/provider/
                {urlId})
              </span>
            </p>
          </div>
          <Field
            name="pwd"
            type="password"
            label="비밀번호"
            component={RenderField}
            placeholder="6 ~ 16자 비밀번호 입력"
            validate={isHiddenForm ? [] : [required, minLength6, maxLength16]}
            isRequired
          />
          <Field
            name="pwd2"
            type="password"
            label="비밀번호 확인"
            component={RenderField}
            placeholder="입력한 번호 다시 확인"
            validate={isHiddenForm ? [] : [required, pwdConfirm]}
            isRequired
          />
          <Field
            name="providerName"
            label="공급자명"
            type="text"
            className="form-with-button"
            component={RenderField}
            placeholder="공급하는 회사 또는 대표 이름"
            validate={[required]}
            isRequired
          />
          <Field
            name="ceoName"
            autocomplete="name"
            label="대표자명"
            type="text"
            component={RenderField}
            placeholder="대표자 이름"
            validate={[required]}
            isRequired
          />
          <Field
            name="mobile"
            type="tel"
            component={RenderField}
            label="대표번호"
            placeholder="서비스에 노출됩니다."
            pattern={/[^0-9]/g}
            validate={[required, tel]}
            isRequired
          />
          <Field
            component={RenderField}
            label="담당자 이름"
            name="picName"
            placeholder="프롬과 함께 고객을 도울 담당자"
            type="text"
            validate={[required]}
            isRequired
          />
          <Field
            component={RenderField}
            label="담당자 휴대폰 번호"
            name="picMobile"
            pattern={/[^0-9]/g}
            placeholder="응원글알림, 배송알림 등 문자를 받습니다."
            type="text"
            validate={[required, numeric, tel]}
            isRequired
          />
          <Field
            component={RenderField}
            label="담당자 이메일"
            name="picEmail"
            placeholder="주문내역, 정산 내역을 메일로 받습니다."
            type="email"
            validate={[required, checkEmailType]}
            isRequired
          />
          <h3 className="member-edit-optional-form form-headline">
            추가 입력 항목
          </h3>
          <div>
            <div className="form-group clearfix mb-1">
              <label
                className="form-label form-picture-label float-left text-left pr-3"
                htmlFor="profile-image"
              >
                프로필 사진
              </label>
              <div className="float-left">
                <PictureInput
                  imageFile={providerProfileImage}
                  onChange={(imageFile) => {
                    onChangeImage('providerProfileImage', imageFile);
                  }}
                />
              </div>
            </div>
          </div>
          <Field
            name="zipcode"
            type="text"
            className="form-with-button"
            component={RenderField}
            label="우편번호"
            placeholder="클릭해서 우편번호 입력"
            readOnly
            onFocus={onToggleAddress}
          >
            {!isCheckedSameAddress && (
              <button
                type="button"
                className="form-button btn-primary form-input-in-button"
                onClick={onToggleAddress}
              >
                찾기
              </button>
            )}
          </Field>
          <Field
            name="addr"
            type="text"
            className="received-gift-addr"
            component={RenderFieldTextArea}
            minHeight={39}
            label="주소"
            placeholder="클릭해서 주소 입력"
            readOnly
            onFocus={onToggleAddress}
          />
          <Field
            name="addrSub"
            type="text"
            component={RenderField}
            label="상세주소"
            placeholder="나머지 주소 입력"
            readOnly={isCheckedSameAddress}
          />
          <Field
            label="공급자 형태"
            name="providerType"
            component={RenderSelectField}
          >
            <option value="1">개인</option>
            <option value="2">개인사업자</option>
            <option value="3">법인사업자</option>
          </Field>
          {!isPrivate && (
            <Fragment>
              <div className="form-group clearfix">
                <label
                  className="form-label form-picture-label float-left text-left pr-3"
                  htmlFor="profile-image"
                >
                  사업자등록증
                </label>
                <div className="float-left">
                  <PictureInput
                    imageFile={businessLicenseImage}
                    onChange={(imageFile) => {
                      onChangeImage('businessLicenseImage', imageFile);
                    }}
                  />
                </div>
              </div>
              <Field
                name="businessLicense"
                type="text"
                component={RenderField}
                label="사업자등록번호"
                pattern={/[^0-9]/g}
                placeholder="- 없이 숫자만 입력"
              />
            </Fragment>
          )}
          <div className="form-group clearfix mb-1">
            <label
              className="form-label form-picture-label float-left text-left pr-3"
              htmlFor="profile-image"
            >
              정산통장사본
            </label>
            <div className="float-left">
              <PictureInput
                imageFile={passbookImage}
                onChange={(imageFile) => {
                  onChangeImage('passbookImage', imageFile);
                }}
              />
            </div>
          </div>
          <Field
            name="bank"
            type="text"
            component={RenderField}
            label="정산계좌 은행"
            placeholder="은행명"
          />
          <Field
            name="passbook"
            type="text"
            component={RenderField}
            label="통장계좌번호"
            placeholder="- 없이 숫자만 입력"
            pattern={/[^0-9]/g}
          />
          <Field
            name="freeDeliveryAmount"
            type="number"
            normalize={(value) => {
              const num = parseInt(value);
              return num;
            }}
            component={RenderField}
            label="무료배송 주문액"
            placeholder="금액 미입력시 50,000원 이상 무료배송"
            pattern={/[^0-9]/g}
          />
          <div className="agreement-form">
            <h3 className="form-headline">이용약관동의</h3>
            <Agreement
              checkboxName="isCheckedAgreement"
              labelText={
                <Fragment>
                  <span>[필수]</span> 공급자이용약관
                </Fragment>
              }
              isCheckedAgreement={isCheckedAgreement}
              onChangeCheckbox={() => {
                onChangeCheckbox('isCheckedAgreement');
              }}
              onClickButton={() => {
                onOpenAgreementModal('/terms/term-provider.html');
              }}
            />
            <Agreement
              checkboxName="isCheckdPrivacy"
              className="mt-2"
              labelText={
                <Fragment>
                  <span>[필수]</span> 개인정보처리방침
                </Fragment>
              }
              isCheckedAgreement={isCheckdPrivacy}
              onChangeCheckbox={() => {
                onChangeCheckbox('isCheckdPrivacy');
              }}
              onClickButton={() => {
                onOpenAgreementModal('/terms/terms-privacy-confirm-new.html');
              }}
            />
          </div>
          <div className="signup-buttons mt-5">
            <Button
              buttonText="가입하기"
              buttonType="submit"
              isDisable={
                pristine ||
                invalid ||
                submitting ||
                !isCheckdPrivacy ||
                !isCheckedAgreement
              }
              isLoading={isLoading}
              className="w-100"
            />
          </div>
        </form>
        <AddressModal
          isOpen={isOpenSearchAddress}
          onToggle={onToggleAddress}
          onSelect={onChangeAddress}
        />
      </div>
    </Fragment>
  );
}

export default reduxForm({
  form: 'providerSignUpForm',
  asyncBlurFields: ['id'],
  asyncValidate: checkDupilcateWhenProviderSignup,
  initialValues: {
    providerType: '1'
  }
})(ProviderSignupForm);
