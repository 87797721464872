import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

// import SuccessList from 'components/oneStore/SuccessList';
import OneStoreListTemplate from 'components/oneStore/OneStoreListTemplate';
import OneStoreDiscoverSuccessItem from 'components/oneStore/OneStoreDiscoverSuccessItem';

import sentry from 'lib/sentry';
import withUi from 'lib/hoc/withUi';

import { getStore, getStoreSuccessList } from 'api/store';

function OneStoreDiscoverSuccessListContainer({ storeId, UiAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [discoversList, setDiscoversList] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [lastKey, setLastKey] = useState(null);
  const [store, setStore] = useState({});

  const headerText =
    store && store.userName
      ? `${store.userName}님이 발굴한 상품`
      : '발굴한 상품';

  useEffect(
    () => {
      UiAction.setHeader(headerText);
    },
    [UiAction, headerText]
  );

  useEffect(
    () => {
      async function getStoreInfo() {
        try {
          const response = await getStore(storeId);

          if (!response || !response.data.data || !response.data.success) {
            throw new Error(
              `1S-discoversuccesslist api Error(storeInfo) - storeId: ${storeId}`
            );
          }

          setStore(response.data.data);
        } catch (e) {
          sentry.captureException(e);
        }
      }

      getStoreInfo();
    },
    [storeId]
  );

  const infiniteRef = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMoreItems,
    onLoadMore: getSuccessList,
    scrollContainer: 'window'
  });

  async function getSuccessList() {
    const params = {
      lastKey,
      pageCount: 20,
      storeId
    };

    try {
      setIsLoading(true);
      const response = await getStoreSuccessList(params);

      if (!response || !response.data || !response.data.success) {
        throw new Error('error!');
      }

      const { data } = response.data;
      const { items } = data;

      setIsLoading(false);
      setLastKey(data.lastKey);

      setDiscoversList([...discoversList, ...items]);
      setHasMoreItems(!!data.lastKey);
    } catch (e) {
      // setIsLoading(false);
      sentry.captureException(e.message);
    }
  }

  return (
    <OneStoreListTemplate
      haveList={discoversList && discoversList.length > 0}
      infiniteRef={infiniteRef}
      isLoading={isLoading}
      headerText={headerText}
    >
      {discoversList.map((discovers) => (
        <OneStoreDiscoverSuccessItem
          key={discovers.id}
          discovers={discovers}
          storeId={storeId}
        />
      ))}
    </OneStoreListTemplate>
  );
}

export default withRouter(withUi(OneStoreDiscoverSuccessListContainer));
