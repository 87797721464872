import styled, { css } from 'styled-components';
import Slider from 'react-slick';

export const PostDetailWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  margin: 16px auto;
  justify-items: center;
`;

export const StyledCardInput = styled.input`
  display: block;
  padding: 5px;
  margin: 0px 15px;
  width: 210px;
  height: 35px;
  max-width: 500px;
  background-color: var(--gray-250, #ebebeb);

  border-radius: 8px;
  /* border: 1px solid var(--button-border-color, darkgray); */
  font-size: 0.875rem;
  &::placeholder {
    color: black;
    font-size: 0.725rem;
    padding-left: 8px;
    line-height: 1.1rem;
  }
`;

export const PostButtonWrapper = styled.div`
  width: 90%;
  max-width: var(--max-width);
  margin: 10px 20px;
  display: flex;
  justify-content: center;
`;

export const PostCardWrapper = styled.div`
  display: grid;
  width: 100%;
  max-width: 480px;
  grid-template-columns: 10px minmax(326px, 460px) 10px;
  grid-template-rows: repeat(6, auto);
  column-gap: 0px;
  row-gap: 2px;
  align-items: center;
  justify-items: start;
  justify-content: center;

  margin: 10px auto 20px auto;
  color: var(--text-color);
  font-weight: bold;
  font-size: 0.875rem;

  grid-template-areas:
    '. item-header .'
    '. item-profile .'
    'item-image item-image item-image'
    '. item-stats .'
    '. item-text .'
    '. item-footer .'
    'item-detail item-detail item-detail';

  ${({ isReply }) =>
    isReply &&
    css`
      padding-left: 30px;
      grid-template-columns: 10px minmax(296px, 430px) 10px;
    `}
`;

export const PostProfile = styled.div`
  grid-area: item-profile;
  display: flex;
  align-items: center;
  height: 42px;
  width: 100%;

  &.list-input-control {
    justify-content: center;
  }

  .reply-icon {
    display: block;
    /* text-indent: -9999px; */
    width: 25px;
    height: 25px;
    background: url(/images/recomment-arrow-icon.svg);
    background-size: 25px 25px;
  }

  img {
    width: 31px;
    height: 31px;
    object-fit: cover;
    border-radius: 50%;
    z-index: 1;

    &.small {
      width: 24px;
      height: 24px;
    }

    &.large {
      width: 54px;
      height: 54px;
    }

    &.provider {
      border: 2px solid var(--provider-color);
    }
  }

  p {
    padding-left: 10px;
    font-weight: 800;
    white-space: nowrap;
  }

  a {
    &.text-link {
      text-decoration: none;
      padding-left: 5px;
      color: var(--from-color);
      font-weight: 400;
      font-size: 9pt;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const PostImageList = styled(Slider)`
  grid-area: item-image;
  justify-self: center;
  position: relative;
  width: 100%;
  /* height: auto; */
  margin-bottom: 15px;

  .slick-arrow {
    width: 32px;
    height: 32px;
    text-indent: -1000rem;
    z-index: 1;

    &.slick-prev {
      left: 5px;
      background-image: url('/images/slide-prev-btn.svg');
      background-size: 32px 32px;
    }

    &.slick-next {
      right: 5px;
      background-image: url('/images/slide-next-btn.svg');
      background-size: 32px 32px;
    }
  }

  .slick-dots {
    bottom: -18px;
    li {
      width: 10px;
      height: 10px;

      margin: 1px;

      button::before {
        color: var(--text-light-color, #979797);
        opacity: 0.1;
      }

      /* .custom-slick-dot {
        width: 10px;
        height: 10px;
        background-color: #ffffff;
        border-color: 1px solid #ffffff;
        border-radius: 50%;
        opacity: 0.5;
      } */
    }

    li.slick-active {
      button::before {
        color: var(--from-color, #74c358);
        opacity: 1;
      }
    }
  }

  img {
    width: 100vw;
    height: 100vw;
    max-width: 480px;
    max-height: 480px;
    object-fit: cover;
    border: solid 1px lightgrey;
    border-radius: 2px;

    ${({ isDetail }) =>
      isDetail &&
      css`
        height: auto;
        max-height: auto;
      `}
  }
`;

export const PostImage = styled.div`
  grid-area: item-image;
  justify-self: center;
  position: relative;

  img {
    width: 100%;
    height: auto;
    max-height: 480px;
    object-fit: cover;
    border: solid 1px lightgrey;
    border-radius: 2px;
  }

  /* .item-selected-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    opacity: 1;
    z-index: 2;
  } */
`;

export const PostTitle = styled.div`
  grid-area: item-title;
  height: 58px;
  width: 100%;
  line-height: 1.3;
  font-size: 0.875rem;
  font-weight: bold;
  white-space: initial;
  overflow: hidden;

  &.small {
    height: 44px;
    font-size: 0.75rem;
  }

  &.large {
    height: 64px;
    font-size: 1rem;
  }

  .title-icon {
    margin-left: 3px;
    width: 19px;
    height: 19px;
    vertical-align: middle;
  }
`;

export const PostText = styled.p`
  grid-area: item-text;
  height: auto;
  width: 100%;
  margin-bottom: 10px;

  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.5;

  white-space: initial;
  overflow: hidden;
`;

// export const PostShare = styled.div`
//   grid-area: item-stats;
//   justify-self: right;
//   margin-bottom: 15px;

//   button {
//     display: flex;
//     justify-self: right;
//     margin-left: auto;
//     background: white;
//     border: none;
//     img {
//       display: inline-block;
//       width: 40px;
//       height: 38px;
//       margin-left: 1px;
//       padding-bottom: 15px;
//     }
//     &.title {
//       display: inline-block;
//       color: var(--from-color);
//       font-size: 8pt;
//       padding-top: 10px;
//     }
//   }
// `;

export const PostStats = styled.div`
  grid-area: item-stats;
  display: flex;
  margin-right: 8px;
  color: var(--text-light-color, #9b9b9b);
  font-size: 0.75rem;
  align-items: center;
  margin-bottom: 15px;

  .stat-with-icon {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    img {
      display: inline-block;
      width: 24px;
      height: 24px;

      &.loading {
        opacity: 30%;
      }
    }

    span {
      display: inline-block;
      color: var(--text-light-color, #a2a4a6);
      padding-left: 4px;
      padding-right: 20px;
      font-size: 0.75rem;
    }
    &.speech {
      span {
        padding-left: 8px;
      }
    }
  }
`;

export const PostActions = styled.div`
  grid-area: item-footer;
  display: flex;
  margin-right: 8px;
  color: var(--text-light-color, #9b9b9b);
  align-items: center;
  /* justify-self: right; */

  button {
    border: none;
    background: none;
    color: var(--text-color);
    font-size: 0.75rem;
    padding: 5px 10px;

    &:hover {
      border: solid 1px var(--button-border-color);
      border-radius: 3px;
      font-weight: bold;
    }

    &.detail {
      color: var(--from-color);
    }
  }

  span {
    font-size: 0.625rem;

    /* color: var(--text-light-color, #9b9b9b); */
    padding-right: 5px;
  }
`;

export const PostReply = styled.div`
  grid-area: item-detail;
  width: 100%;
  line-height: 1.3;
  font-size: 0.875rem;
  white-space: initial;
  overflow: hidden;
`;
