import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import * as discoverApi from 'api/discover';

const INITIALIZE_DISCOVER = 'discover/INITIALIZE_DISCOVER';
const GET_DISCOVER = 'discover/GET_DISCOVER';

export const initializeDiscover = createAction(INITIALIZE_DISCOVER);
export const getDiscover = createAction(GET_DISCOVER, discoverApi.getDiscover);
const initialState = {
  discover: null
};

export default handleActions(
  {
    [INITIALIZE_DISCOVER]: (state) => {
      return {
        ...state,
        discover: null
      };
    },
    ...pender({
      type: GET_DISCOVER,
      onSuccess: (state, action) => {
        const { data, success } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            discover: null
          };
        }

        return {
          ...state,
          discover: data
        };
      },
      onFailure: (state) => {
        return { ...state, discover: null };
      }
    })
  },
  initialState
);
