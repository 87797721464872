import React, { Component, Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';
import Bookmark from 'components/users/Bookmark';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import storage from 'lib/storage';
import RedirectToHome from 'components/commons/RedirectToHome';
import OneStoreFollowContainer from 'containers/oneStore/OneStoreFollowContainer';

import AlertModal from 'components/modal/AlertModal';

import * as storeActions from 'modules/store';

import withUi from 'lib/hoc/withUi';

class FollowContainer extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { pathname } = nextProps.location;
    const splitPathname = pathname ? pathname.split('/') : [];
    const subPath = splitPathname[splitPathname.length - 1]
      ? splitPathname[splitPathname.length - 1]
      : '';

    if (subPath !== prevState.currentTab) {
      return {
        currentTab: subPath
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { location } = this.props;
    const { pathname } = location;
    const splitPathname = pathname ? pathname.split('/') : [];
    const subPath = splitPathname[splitPathname.length - 1]
      ? splitPathname[splitPathname.length - 1]
      : '';

    this.state = {
      isOpenErrorModal: false,
      errorMessage: '',
      currentTab: subPath
    };
    this.oneStoreLastKey = null;
    this.oneProductLastKey = null;
    this.productLastkey = null;
    this.providerLastkey = null;
  }

  componentDidMount() {
    this.props.UiAction.setHeader('나의 팔로우');
  }

  handleChangeTab = (path) => {
    const { match } = this.props;
    const { storeId } = match.params;
    this.setState({
      currentTab: path
    });

    this.props.history.push(`/store/${storeId}/bookmark/` + path);
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  render() {
    const { currentTab, isOpenErrorModal, errorMessage } = this.state;
    const userInfo = storage.get('user_userInfo');
    const { match } = this.props;
    const { storeId } = match.params;

    return (
      <Fragment>
        <RedirectToHome isGotoHome={!userInfo || userInfo.id !== storeId} />
        <Bookmark onClick={this.handleChangeTab} currentTab={currentTab}>
          {/* 상위탭 */}
          <Route
            path="/store/:storeId/bookmark/stores"
            render={() => (
              <OneStoreFollowContainer storeId={storeId} isComponent />
            )}
          />
          <Route
            path="/store/:storeId/bookmark/provider"
            render={() => (
              <OneStoreFollowContainer
                storeId={storeId}
                isProvider
                isComponent
              />
            )}
          />
        </Bookmark>
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ store }) => {
  return {
    storeOneExperiences: store.storeOneExperiences
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    StoreActions: bindActionCreators(storeActions, dispatch)
  };
};

export default compose(
  withRouter,
  withUi,
  connect(mapStateToProps, mapDispatchToProps)
)(FollowContainer);
