/* eslint-disable no-console */
import React, { Fragment, useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import withUser from 'lib/hoc/withUser';

import fetchData from 'api/fetchData';
import { getOneProduct } from 'api/oneProduct';

import sentry from 'lib/sentry';
import { isEmpty } from 'lib/utils/commonUtils';
import { isDevelop, isIPhoneX } from 'lib/utils/envUtils';
import { searchReferrer } from 'lib/referrer';

import useModal from 'hooks/useModal';

import AlertModal from 'components/modal/AlertModal';
import ShareModal from 'components/modal/ShareModal';
import StopSellingModal from 'components/modal/StopSellingModal';

import ProductButtonGroup from 'components/product/ProductButtonGroup';

const isDev = isDevelop();
const isUp = isIPhoneX();

const getReferrerById = async (storeId, productId) => {
  try {
    const data = await fetchData(getOneProduct, [storeId, productId]);
    // console.log({ storeId, productId, data });
    const { store, shareRate = { seller: 0.5, buyer: 0.5 } } = data;
    // const shareRate = {
    //   seller: onePSaveStruct.fromSave,
    //   buyer: onePSaveStruct.toSave
    // };
    // console.log({ shareRate });
    return store ? { ...store, shareRate } : null;
  } catch (error) {
    // console.log({ storeId, productId });
    // console.log({ error });
    return null;
  }
};

function OrderButton({
  user,
  isLoggedIn,
  isProvider,
  storeId,
  productId,
  product,
  oneProduct,
  isOneProduct,
  showHeader
}) {
  const match = useRouteMatch();
  // console.log({ storeId, match });
  const history = useHistory();

  const [paymentType, setPaymentType] = useState('buy');
  const [seller, setSeller] = useState({});
  const [hasReferrer, setHasReferrer] = useState(isOneProduct);
  const [buttonUrl, setButtonUrl] = useState('');

  const {
    title: errorTitle,
    message: errorMessage,
    setTitle: setErrorTitle,
    setMessage: setErrorMessage,
    isOpen: isOpenErrorModal,
    handleToggle: toggleErrorModal
  } = useModal();

  const {
    title: stopSellingModalHeaderText,
    setTitle: setStopSellingModalHeaderText,
    message: stopSellingModalText,
    setMessage: setStopSellingModalText,
    buttonLabel: stopSellingModalButtonText,
    setButtonLabel: setStopSellingModalButtonText,
    isOpen: isOpenStopSellingModal,
    // setIsOpen: setIsOpenStopSellingModal,
    handleToggle: toggleStopSellingModal
  } = useModal();

  const { isOpen: isOpenShareModal, handleToggle: toggleShareModal } =
    useModal();

  useEffect(() => {
    if (isOneProduct && oneProduct) {
      getReferrer();
    } else {
      getReferrer();
    }
  }, [oneProduct]);

  // console.log({ isOneProduct, hasReferrer, seller });

  const getReferrer = async () => {
    try {
      // default
      // console.log({ oneProduct });
      setSeller({ ...oneProduct?.store, shareRate: oneProduct?.shareRate });
      if (isLoggedIn && user.id === storeId) return;

      // 로그인 되어 있고 로그인한 사용자의 1p가 존재할 때
      if (isLoggedIn && user.id !== storeId) {
        const referrer = await getReferrerById(user.id, productId);
        // console.log({ referrer });
        if (referrer) {
          setSeller(referrer);
          setHasReferrer(true);
          return;
        }
      }

      // 로그인한 사용자의 1p가 없거나 비로그인 상태일 경우
      const anotherReferrerId = searchReferrer(productId);
      if (anotherReferrerId) {
        const referrer = await getReferrerById(anotherReferrerId, productId);
        // console.log({ anotherReferrerId, referrer });
        if (referrer) {
          setSeller(referrer);
          setHasReferrer(true);
          return;
        }
      }
      const discovererId = product?.discovererId;
      if (discovererId) {
        const referrer = await getReferrerById(discovererId, productId);
        // console.log({ discovererId, referrer });
        if (referrer) {
          setSeller(referrer);
        }
      }
    } catch (e) {
      console.log(e);
      sentry.captureException(e.messaage);
      // history.goBack();
    }
  };

  // 상품정보체크 -> flagStopSelling체크 -> countofSales체크
  const checkAvailablePayment = (paymentType) => {
    // console.log('checking');
    if (!product) {
      return;
    }
    const {
      // countOfSales,
      countOfSalesLimit,
      quantityOnHand,
      descStopSelling,
      descStopAction,
      flagStopSelling,
      titleStopSelling,
      providerId
    } = product;

    // console.log({ flagStopSelling });

    if (countOfSalesLimit && quantityOnHand < 1) {
      setErrorTitle('완판되었습니다.');
      toggleErrorModal();
      return;
    }

    if (flagStopSelling === 1) {
      // console.log({ product });
      setStopSellingModalHeaderText(titleStopSelling);
      setStopSellingModalText(descStopSelling);
      setStopSellingModalButtonText('주문');
      toggleStopSellingModal();
      return;
    }

    if (flagStopSelling === 11) {
      setStopSellingModalHeaderText(titleStopSelling);
      setStopSellingModalText(
        descStopSelling || '공급자의 사정으로 일시 품절된 상품입니다.'
      );
      // console.log({ providerId });
      setButtonUrl(`/provider/${providerId}`);
      setStopSellingModalButtonText('공급자의 다른 상품 구경하기');

      toggleStopSellingModal();
      return;
    }

    if (flagStopSelling === 12) {
      const { buttonUrl } = descStopAction;
      // console.log({ buttonUrl });
      setStopSellingModalHeaderText(titleStopSelling);
      setStopSellingModalText(descStopSelling);
      setButtonUrl(buttonUrl);
      setStopSellingModalButtonText('확인');
      toggleStopSellingModal();

      return;
    }
    if (paymentType === 'buy') {
    }
    addToCart(paymentType);
  };

  const addToCart = (paymentType) => {
    // console.log('Adding item to cart');
    history.push({
      pathname: `/store/${
        seller?.id || storeId || 'frommarket'
      }/${productId}/options/choose`,
      state: {
        itemType: 'product',
        product,
        options: product.options,
        provider: product.provider,
        seller,
        isOneProduct: hasReferrer
      }
    });
  };

  const onClickOrderButton = (paymentType) => {
    // console.log('Buy button clicked');
    setPaymentType(paymentType);

    if (typeof window !== 'undefined' && !isDev) {
      window.fbq('track', 'InitiateCheckout');
    }

    checkAvailablePayment(paymentType);
  };

  // const onClickGiftButton = () => {
  //   // console.log('Gift button clicked');

  //   setPaymentType('gift');

  //   if (typeof window !== 'undefined' && !isDev) {
  //     window.fbq('track', 'InitiateCheckout');
  //   }

  //   checkAvailablePayment('gift');
  // };

  const redirectToButtonUrl = () => {
    history.push(`${buttonUrl}`);

    toggleStopSellingModal();
  };

  const onClickStopSellingModalButton = () => {
    const { flagStopSelling } = product;

    if (flagStopSelling === 1) {
      toggleStopSellingModal();
      addToCart(paymentType);
    }

    if (flagStopSelling === 11 || flagStopSelling === 12) {
      redirectToButtonUrl();
    }
  };

  const { isCarrige } = product || {};
  const isRecipe = isCarrige === true;

  const type = isOneProduct ? 'oneProduct' : 'product';
  const metaData = {
    type,
    product,
    oneProduct,
    path: match.url,
    referrer: user.id
  };

  // console.log({ seller, product });

  return (
    <Fragment>
      <ProductButtonGroup
        isVisible
        isProvider={isProvider}
        isUp={isUp}
        showHeader={showHeader}
        isRecipe={isRecipe}
        isOneProduct={isOneProduct}
        onClickOrderButton={onClickOrderButton}
        onClickShareButton={toggleShareModal}
      />
      <AlertModal
        isOpen={isOpenErrorModal}
        headerText={errorTitle}
        contentText={errorMessage}
        onClick={toggleErrorModal}
        onToggle={toggleErrorModal}
      />
      <StopSellingModal
        isOpen={isOpenStopSellingModal}
        contentText={stopSellingModalText}
        headerText={stopSellingModalHeaderText || '안내'}
        headerImage={product ? product.providerProfileImgPath : {}}
        buttonText={stopSellingModalButtonText}
        onClick={onClickStopSellingModalButton}
        onToggle={toggleStopSellingModal}
      />
      <ShareModal
        isOpen={isOpenShareModal}
        onToggle={toggleShareModal}
        metaData={metaData}
      />
    </Fragment>
  );
}

export default withUser(OrderButton);
