import { useQuery, useInfiniteQuery } from 'react-query';

import fetchData from 'api/fetchData';
import { getGift, postGift } from 'api/gift';

const STALE_TIME = 1000 * 60 * 10;
const ITEMS_PER_PAGE = 20;

const getGiftById = async (key, deliveryId) => {
  try {
    // console.log({ key, deliveryId });
    const data = await fetchData(getGift, deliveryId);
    // console.log({ data });
    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

// const getGifts = async (
//   key,
//   { searchType, searchKey, itemsPerPage = ITEMS_PER_PAGE },
//   LastEvaluatedKey
// ) => {
//   try {
//     // console.log({ LastEvaluatedKey });
//     const params = {
//       [searchType]: searchKey,
//       pageCount: itemsPerPage,
//       lastKey: LastEvaluatedKey
//     };

//     const func = searchType === 'storeId' ? getStorePaymentList : null;

//     if (func === null)
//       throw new Error(`Unknown searchType - ${searchType} !!!`);

//     const data = await fetchData(func, params);

//     // console.log({ data });

//     return data;
//   } catch (error) {
//     console.log({ error });
//     throw error;
//   }
// };

export function useGift(deliveryId) {
  const { data, isLoading, isError, error } = useQuery(
    ['gift', deliveryId],
    getGiftById,
    {
      staleTime: 0,
      retry: false
    }
  );

  return { data, isLoading, isError, error };
}

// export function useInfiniteOrders(searchConditions) {
//   const query = useInfiniteQuery(['Gifts', searchConditions], getGifts, {
//     getFetchMore: (lastPage) => {
//       // console.log({ lastPage });
//       return lastPage.LastEvaluatedKey;
//     },
//     staleTime: 0,
//     retry: false
//   });
//   // console.log({ query });
//   return query;
// }
