/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import classNames from 'classnames';
import { reduxForm, Field } from 'redux-form';

import { required, checkRequiredShippingRate } from 'lib/form/validations';

import Button from 'components/commons/Button';
import PictureInput from 'components/commons/PictureInput';

// import RenderImage from 'components/reduxForm/RenderImage';
import RenderField from 'components/reduxForm/RenderField';
import RenderFieldIncludeUnit from 'components/reduxForm/RenderFieldIncludeUnit';

import RenderFieldTextArea from 'components/reduxForm/RenderFieldTextArea';
import DefaultRateField from './DefaultRateField';
import './RegisterProductForm.scss';

function RegisterProductForm({
  flagDelivery,
  handleSubmit,
  hasOfficialProductImage,
  isEdit,
  isEditIncentive,
  isSubmitLoading,
  officialProductImage,
  onChangeOfficialProductImage,
  onOpenModal,
  onSubmit
}) {
  return (
    <div className="register-product-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field name="flagNew" type="hidden" component="input" />
        <Field name="id" type="hidden" component="input" />
        <Field
          component={RenderField}
          type="text"
          name="productName"
          placeholder="상품명"
          label="상품명"
          className="mr-1"
          onFocus={(e) => {
            onOpenModal('isOpenProductModal');
            e.target.blur();
          }}
          readOnly
          validate={[required]}
        >
          <button
            type="button"
            className="product-search-btn form-button btn-primary"
            onClick={() => {
              onOpenModal('isOpenProductModal');
            }}
          >
            상품명검색
          </button>
        </Field>
        {/* <div className="form-group d-flex align-items-center">
          <span className="form-label form-picture-label float-left text-left pr-3">
            공식상품이미지
          </span>
          <Field
            component={RenderImage}
            type="file"
            name="officialProductImage"
            onChangeImage={onChangeOfficialProductImage}
            imagePath={officialProductImage}
          />
        </div> */}
        <div className="form-group d-flex align-items-center">
          <span className="form-label form-picture-label float-left text-left pr-3">
            공식상품이미지
          </span>
          <PictureInput
            imageFile={officialProductImage || null}
            className={classNames({
              'border border-danger': !hasOfficialProductImage
            })}
            onChange={(imageFile) => {
              // console.log('RegisterProductForm onChangeImage');
              // console.log({ imageFile });
              onChangeOfficialProductImage(imageFile);
            }}
          />
        </div>
        {/* {!hasOfficialProductImage && (
          <span className="d-block invalid-feedback invalid-feedback-with-label">
            *필수 정보 입니다.
          </span>
        )} */}
        <Field
          name="productDetailInfo.author"
          label="크리에이터"
          description="상품 생산자명(저자, 회사 대표자명 등)을 적어주세요."
          type="text"
          component={RenderField}
          placeholder="상품 제작자"
          validate={[required]}
          className="w-100"
        />
        {/* <Field
          name="productDetailInfo.publishDate"
          label="생성일"
          type="date"
          component={RenderField}
          validate={[required]}
        />*/}
        <Field
          name="productDetailInfo.introduce"
          label="상품요약"
          description="소비자가 왜 이 상품을 선택해야하나요? 자랑해주세요."
          type="text"
          component={RenderFieldTextArea}
          validate={[required]}
          className="w-100"
        />
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            면세 상품
          </span>
          <div className="form-control form-input form-radio float-left mr-2">
            <Field
              name="flagTaxFree"
              component="input"
              type="radio"
              value="1"
              id="taxFree"
            />
            <label htmlFor="taxFree" className="form-radio-label">
              예
            </label>
          </div>
          <div className="form-control form-input form-radio float-left">
            <Field
              name="flagTaxFree"
              component="input"
              type="radio"
              value="0"
              id="noTaxFree"
            />
            <label htmlFor="noTaxFree" className="form-radio-label">
              아니오
            </label>
          </div>
        </div>
        <div className="form-group d-flex align-items-center mt-3">
          <span className="form-label d-inline-block text-left pr-2 ">
            배송필요 유/무
          </span>
          <div className="form-control form-input form-radio float-left mr-2">
            <Field
              name="flagDelivery"
              component="input"
              type="radio"
              value="1"
              id="delivery"
            />
            <label htmlFor="delivery" className="form-radio-label">
              유
            </label>
          </div>
          <div className="form-control form-input form-radio float-left">
            <Field
              name="flagDelivery"
              component="input"
              type="radio"
              value="0"
              id="noDelivery"
            />
            <label htmlFor="noDelivery" className="form-radio-label">
              무
            </label>
          </div>
        </div>
        <Field
          name="basicDeliveryFee"
          label="기본 배송료"
          type="text"
          description="구매 단가에 따른 무료배송 설정은 공급자홈 > 정보수정에서 입력해주세요 :)"
          component={RenderFieldIncludeUnit}
          placeholder="기본 배송료"
          readOnly={!parseInt(flagDelivery, 10)} // parseInt 한 값이 1이면 배송 유, 0이면 배송 무
          validate={[checkRequiredShippingRate]}
          pattern={/[^0-9,]/g}
          unit="원"
        />
        <DefaultRateField isEditIncentive={isEditIncentive} />
        <div className="product-regist-buttons">
          <Button
            buttonText={isEdit ? '저장 하기' : '등록 하기'}
            color="provider"
            buttonType="submit"
            isLoading={isSubmitLoading}
            isDisable={isSubmitLoading}
          />
        </div>
      </form>
    </div>
  );
}

RegisterProductForm.propTypes = {
  flagDelivery: PropTypes.string,
  hasOfficialProductImage: PropTypes.bool,
  isEdit: PropTypes.bool,
  isEditIncentive: PropTypes.bool,
  isSubmitLoading: PropTypes.bool,
  officialProductImage: PropTypes.shape({}),
  onChangeOfficialProductImage: PropTypes.func,
  onOpenModal: PropTypes.func,
  onSubmit: PropTypes.func
};

RegisterProductForm.defaultProps = {
  flagDelivery: '1',
  hasOfficialProductImage: false,
  isEdit: false,
  isEditIncentive: true,
  isSubmitLoading: false,
  officialProductImage: null,
  onChangeOfficialProductImage: () => {},
  onOpenModal: () => {},
  onSubmit: () => {}
};

export default reduxForm({
  form: 'registerProductForm',
  initialValues: {
    basicDeliveryFee: '0',
    flagNew: true,
    flagDelivery: '1', // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
    flagTaxFree: '0', // string 형식으로 값을 초기화 해줘야 radio에서 초기값 설정 및 값 선택이 됨...
    defaultRate: {
      cnx: '5',
      discount: '5',
      seller: '5',
      provider: '85'
    }
  }
})(RegisterProductForm);
