export function copyObj(obj) {
  if (!obj) {
    return obj;
  }

  return Object.keys(obj).reduce((target, key) => {
    target[key] =
      obj[key].constructor === Object ? copyObj(obj[key]) : obj[key];

    return target;
  }, {});
}

export function getQuerys(path) {
  const querys = {};

  if (!path) {
    return querys;
  }

  path.replace(/[?&]+([^=&]+)=?([^&]*)?/gi, (m, key, value) => {
    querys[key] = value !== undefined ? value : '';
  });

  return querys;
}

// mapFn함수로 새로운 object생성
export function objectMap(obj, mapFn) {
  return Object.keys(obj).reduce(function (result, key) {
    result[key] = mapFn(obj[key]);
    return result;
  }, {});
}

// objectMap함수 이용, object 값 문자열 공백제거후 리턴하는 함수
export function spaceCheckObj(obj) {
  let newObj = objectMap(obj, function (value) {
    if (typeof value === 'string') {
      return value.trim();
    }
    return value;
  });

  return newObj;
}

// throttle 함수 연속 호출시 일정 시간 텀을 줌
export const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime;
  return function () {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(function () {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const stringToHslColor = (str, s = 30, l = 80) => {
  // s 30, l 80 - pastel tone
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export function isEmpty(value) {
  if (
    value === '' ||
    value === null ||
    value === undefined ||
    (value !== null && typeof value === 'object' && !Object.keys(value).length)
  ) {
    return true;
  }
  return false;
}

export const scrollToRef = (ref, offset = 0) => {
  // console.log({ ref });
  if (!(ref && ref.current) && ref !== 'top') return;
  const top =
    ref === 'top'
      ? offset
      : ref.current.offsetTop + offset; /*, behavior: 'smooth' */

  // console.log({ top });
  window.scrollTo({ top });
};
