import React, { Fragment, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { compose } from 'redux';

import { useOneStore } from 'hooks/useOneStores';

import NavBar from 'components/ui/NavBar';
import OneStoreProducts from './OneStoreProducts';

import OneStoreMain from './OneStoreMain';
import OneStorePosts from './OneStorePosts';

import Loader from 'components/loader/Loader';
import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import { getStoreActivityCount } from 'api/store';

function useStoreStats(storeId) {
  const [data, setData] = useState({});

  useEffect(() => {
    async function getStoreStats() {
      try {
        const response = await getStoreActivityCount(storeId);

        if (!response || !response.data || !response.data.success) {
          throw new Error(
            `get Store count api Error(OneStore) storeId: ${storeId}`
          );
        }

        setData(response.data.data);
      } catch (e) {
        throw e;
      }
    }
    getStoreStats();
  }, []);

  return data;
}

function OneStoreContainer({
  storeId,
  user,
  isLoggedIn,
  isProvider,
  UiAction
}) {
  const { data: store, isLoading, isError, error } = useOneStore({
    storeId
  });

  // const { toCount, fromCount, productCount, discoveryCount } = useStoreStats(
  //   storeId
  // );

  useEffect(() => {
    if (isLoggedIn && user.id === storeId) {
      UiAction.setHeader(`내 바구니`);
    } else {
      UiAction.setHeader(
        `${store ? `${store.userName}님의 바구니` : '바구니'}`
      );
    }
  }, [store]);

  const oneStoreNavLinks = [
    { name: '단골상품', path: `/user/${storeId}/products` },
    {
      name: '받은이야기',
      path: `/user/${storeId}/posts/in`
    },
    {
      name: '보낸이야기',
      path: `/user/${storeId}/posts/out`
    }
  ];

  if (isError) return <span>Error: {error.message}</span>;

  return (
    <Fragment>
      <NavBar className="with-border" navLinks={oneStoreNavLinks}>
        <OneStoreMain {...{ storeId, store }} />
      </NavBar>
      {isLoading && <Loader />}

      <Switch>
        <Route
          exact
          path="/user/:storeId"
          render={() => <Redirect to={`/user/${storeId}/products`} />}
        />
        <Route
          exact
          path="/store/:storeId"
          render={() => <Redirect to={`/user/${storeId}/products`} />}
        />

        <Route
          exact
          path="/user/:storeId/products"
          render={() => <OneStoreProducts {...{ storeId, store }} />}
        />
        <Route
          exact
          path="/user/:storeId/posts/out"
          render={() => <OneStorePosts {...{ authorId: storeId, store }} />}
        />
        <Route
          exact
          path="/user/:storeId/posts/in"
          render={() => <OneStorePosts {...{ storeId, store }} />}
        />
      </Switch>
    </Fragment>
  );
}

export default compose(withUser, withUi)(OneStoreContainer);
