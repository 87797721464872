/* eslint-disable no-console */
import React, { Fragment } from 'react';
import { compose } from 'redux';

import Slider from 'react-slick';
import withUser from 'lib/hoc/withUser';

import { getImagePath } from 'lib/utils/imageUtils';

// import Button from 'components/commons/Button';
import RecommendBox from 'components/oneProduct/RecommendBox';

import './DiscoverDetailView.scss';
import './Slider.scss';

function DiscoverDetailView({
  user,
  isLoggedIn,
  isProvider: isProviderUser,
  discover,
  onClickShareModal
}) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 5000,
    // eslint-disable-next-line react/display-name
    customPaging: () => <div className="custom-slick-dot" />
  };
  const {
    discovererId,
    discovererName,
    profileImgPath,
    recommendation,
    createDate,
    productName,
    productImgPathList,
    productDetailInfo,
    providerId,
    providerName,
    providerProfileImgPath
  } = discover;

  // console.log({ discover });
  return (
    <Fragment>
      <div className="discover-detail">
        <div className="discover-title">
          <div className="product-header-title">{productName}</div>
        </div>
        <div className="discover-image">
          <Slider className="product-image-slide" {...settings}>
            {productImgPathList &&
              productImgPathList.map((productImg) => {
                return (
                  <div key={getImagePath(productImg)}>
                    <img
                      className="product-image-slide-item"
                      src={`${getImagePath(productImg, 600, 600)}`}
                      alt="상품"
                    />
                  </div>
                );
              })}
          </Slider>
        </div>
        <div className="discover-recommendation">
          <RecommendBox
            createDate={createDate}
            providerId={providerId}
            name={providerName}
            profileImgPath={providerProfileImgPath}
            recommendation={productDetailInfo && productDetailInfo.introduce}
            isProvider
            user={user}
            isLoggedIn={isLoggedIn}
            isProviderUser={isProviderUser}
            productStatus="requested"
          />
          <RecommendBox
            createDate={createDate}
            discovererId={discovererId}
            name={discovererName}
            profileImgPath={profileImgPath}
            recommendation={recommendation}
            isDiscoverer
            user={user}
            isLoggedIn={isLoggedIn}
            isProviderUser={isProviderUser}
          />
        </div>
      </div>

      <div className="discover-support-button-wrapper">
        <button
          type="button"
          onClick={onClickShareModal}
          className="support-btn btn discover-support-button"
        >
          <i
            className="discover-support-button-image-icon"
            style={{
              backgroundImage: `url('/images/yellow-support-btn2.svg')`
            }}
          />
          응원하기
        </button>
      </div>
    </Fragment>
  );
}

export default compose(withUser)(DiscoverDetailView);
