import React from 'react';
import { Link } from 'react-router-dom';

import { diffDate, getFormatTimeStempDate } from 'lib/time';
import { getImagePath } from 'lib/utils/imageUtils';
import { getStatus, getStatusText } from 'lib/utils/productUtils';

import './ProviderProductItem.scss';

function ProviderProductItem({ products }) {
  const {
    createDate,
    id: discoverId,
    productId,
    productImgPathList,
    productName,
    nowStatusProcess,
    profileImgPath
  } = products;

  const productImage =
    productImgPathList.length > 0 ? productImgPathList[0] : '';

  const statusText = getStatus(nowStatusProcess);
  // const isSuccess = statusText === 'success' || statusText === 'audit';
  const showDday = statusText === 'wait' || statusText === 'audit';
  // const hasProductId = !!productId;

  return (
    <Link
      to={{
        pathname: `/provider/product/${productId}/register`,
        search: discoverId ? `?discoverId=${discoverId}` : ''
      }}
    >
      <div className="provider-product-item-wrapper">
        <div className="provider-product-item-image success">
          <img
            src={getImagePath(productImage)}
            alt="상품 사진"
            className="provider-product-item-main-image"
          />
          <img
            className="provider-product-item-sub-image"
            src={getImagePath(profileImgPath)}
            alt="공급자왕관"
          />
        </div>
        <div className="provider-product-item-info">
          <div className="provider-product-item-info-name">{productName}</div>
          <div className="provider-product-item-info-date">
            <span>{getFormatTimeStempDate(createDate, 'YYYY-MM-DD')}</span>
            {showDday && (
              <span className="provider-product-item-info-d-date">
                {`${getStatusText(nowStatusProcess)} D+${diffDate(createDate)}`}
              </span>
            )}
          </div>
        </div>
        <img
          className="provider-product-link-icon"
          src="/images/onestore-setting-link-icon.svg"
          alt="화살표"
        />
      </div>
    </Link>
  );
}

export default ProviderProductItem;
