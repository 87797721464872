import { checkDuplicateEmail, checkDuplicateMobile } from 'api/store';
import { getSocialTiral } from 'api/product';
import { checkDupilcateProviderId } from 'api/provider';
import { isEmpty } from 'lib/utils/commonUtils';
// ▲
const VALID = '';

export const required = (value) => {
  return value ? VALID : '*필수 정보입니다';
};

export const emptyValidation = (value) => {
  return isEmpty(value) ? '*필수 정보입니다' : VALID;
};

export const blankCheck = (value) => {
  return !value || !value.trim() ? '*필수 정보입니다' : VALID;
};

export const idMinLength6 = (value) => {
  return value && value.length < 6
    ? '*6자 이상 영문, 숫자, 특수문자를 사용하세요'
    : VALID;
};

export const minLength6 = (value) => {
  return value && value.length < 6
    ? '*6~16자 영문, 숫자, 특수문자를 사용하세요'
    : VALID;
};

export const maxLength16 = (value) => {
  return value && value.length > 15
    ? '*6~16자 영문, 숫자, 특수문자를 사용하세요'
    : VALID;
};

export const numeric = (value) => {
  return value && !/^[0-9]+/i.test(value) ? '*숫자만 사용 가능합니다' : VALID;
};

export const alphanumeric = (value) => {
  return value &&
    !/^[A-Z0-9_-]+/i.test(value) &&
    /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)
    ? '*영문 대/소문자, 숫자와 (-), (_)만 사용 가능합니다'
    : VALID;
};

export const checkEmailType = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value)
    ? '*이메일 형식이 아닙니다'
    : VALID;
};

export const website = (value) => {
  return value &&
    !/[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(
      value
    )
    ? '*웹사이트 주소 형식이 아닙니다'
    : VALID;
};

export const tel = (value) => {
  return value && !/^\d{2,3}(-|\s+)?\d{3,4}(-|\s+)?\d{4}$/.test(value)
    ? '*전화번호 형식이 아닙니다'
    : VALID;
};

export const pwdConfirm = (value, allValues) => {
  if (!allValues.pwd) return VALID;

  if (value && value !== allValues.pwd) {
    return '*패스워드가 일치하지 않습니다';
  }

  return VALID;
};

export function checkRequiredShippingRate(value, allValues) {
  if (parseInt(allValues.flagDelivery, 10) === 0) {
    return VALID;
  }

  return value ? VALID : '*필수 정보입니다';
}

export const minValue = (value) => {
  // const { maxNum } = fieldProps;
  if (value > 1) {
    return '1인당 1개 이상 구매하실 수 없습니다.';
  }
};

/* async validate 체크 */

// 이메일  중복 체크
const checkEmail = async (email) => {
  try {
    const response = await checkDuplicateEmail(email);

    if (!response || !response.data) {
      throw new Error('api error');
    }

    if (!response.data.success) {
      throw new Error('duplicated');
    }

    return Promise.resolve('');
  } catch (e) {
    let errorMessage = '통신 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.';

    if (e.message === 'duplicated') {
      errorMessage = '이미 회원이시네요. 로그인 해주세요.';
    }

    return Promise.resolve(errorMessage);
  }
};

// 핸드폰 중복체크
const checkMobile = async (mobile) => {
  try {
    const response = await checkDuplicateMobile(mobile);

    if (!response || !response.data) {
      throw new Error('api error');
    }

    if (response.data.success) {
      throw new Error('duplicated');
    }

    return Promise.resolve('');
  } catch (e) {
    let errorMessage = '통신 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.';

    if (e.message === 'duplicated') {
      errorMessage = '이미 회원이시네요. 로그인 해주세요.';
    }

    return Promise.resolve(errorMessage);
  }
};

// 소셜 트라이얼 체크
export const checkSocialTrial = async (productId, mobile) => {
  try {
    const response = await getSocialTiral({
      productId,
      receiverMobile: mobile.trim()
    });
    if (!response || !response.data || !response.data.success) {
      throw new Error('api error');
    }

    const { data } = response.data;

    if (!data.validation) {
      throw new Error('duplicated');
    }

    return Promise.resolve('');
  } catch (e) {
    let errorMessage = '통신 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.';

    if (e.message === 'duplicated') {
      errorMessage = '이 상품옵션은 신규 고객에게만 제공되는 체험상품입니다.';
    }

    return Promise.resolve(errorMessage);
  }
};

export const checkDupilcationWhenSignUp = async (values) => {
  let isSuccess = true;
  const hasEmail = !!values.email;
  const hasMobile = !!values.mobile;
  const error = {};

  return new Promise(async (resolve, reject) => {
    if (hasEmail) {
      const email = values.email.trim();
      const errorMessage = await checkEmail(email);

      if (errorMessage !== VALID) {
        isSuccess = false;
        error.email = errorMessage;
      }
    }

    if (hasMobile) {
      const errorMessage = await checkMobile(values.mobile);

      if (errorMessage !== VALID) {
        isSuccess = false;
        error.mobile = errorMessage;
      }
    }

    if (isSuccess) {
      resolve();

      return;
    }

    reject(error);
  });
};

// 구매, 선물 시 회원 중복 확인
export const checkDuplicateStoreInfo = async (values) => {
  let isSuccess = true;
  const hasEmail = !!values.my1PInfo && !!values.my1PInfo.email;
  const hasMobile = !!values.my1PInfo && !!values.my1PInfo.mobile;
  const error = {
    my1PInfo: {}
  };

  return new Promise(async (resolve, reject) => {
    if (hasEmail) {
      const { email } = values.my1PInfo;
      const errorMessage = await checkEmail(email.trim());

      if (errorMessage !== VALID) {
        isSuccess = false;
        error.my1PInfo.email = errorMessage;
      }
    }

    if (hasMobile) {
      const { mobile } = values.my1PInfo;
      const errorMessage = await checkMobile(mobile);

      if (errorMessage !== VALID) {
        isSuccess = false;
        error.my1PInfo.mobile = errorMessage;
      }
    }

    if (isSuccess) {
      resolve();

      return;
    }

    reject(error);
  });
};

// 트라이얼 구매 시 회원 중복 확인
export const checkDuplicateTrialInfo = async (
  values,
  allvalues,
  formValues
) => {
  let isSuccess = true;
  const { isLoggedIn, productId } = formValues;
  const hasEmail = !!values.my1PInfo && !!values.my1PInfo.email;
  const hasMobile = !!values.my1PInfo && !!values.my1PInfo.mobile;
  const error = {
    my1PInfo: {},
    selectOptList: [{}]
  };

  return new Promise(async (resolve, reject) => {
    if (hasEmail && !isLoggedIn) {
      const { email } = values.my1PInfo;
      const errorMessage = await checkEmail(email.trim());

      if (errorMessage !== VALID) {
        isSuccess = false;
        error.my1PInfo.email = errorMessage;
      }
    }

    if (hasMobile) {
      const { mobile } = values.my1PInfo;

      if (!isLoggedIn) {
        const errorMessage = await checkMobile(mobile);

        if (errorMessage !== VALID) {
          isSuccess = false;
          error.my1PInfo.mobile = errorMessage;
        }
      }

      const trialErrorMessage = await checkSocialTrial(productId, mobile);

      if (trialErrorMessage !== VALID) {
        isSuccess = false;
        error.selectOptList[0].option = trialErrorMessage;
      }
    }

    if (isSuccess) {
      resolve();

      return;
    }

    reject(error);
  });
};

// 트라이얼 선물 시 회원 중복 확인
export const checkDuplicateTrialGiftInfo = async (
  values,
  allValues,
  formValues
) => {
  return new Promise(async (resolve, reject) => {
    const { productId } = formValues;
    const { your1PList } = values;
    const error = {
      your1PList: []
    };

    for (let i = 0; i < your1PList.length; i += 1) {
      const { mobile } = your1PList[i];
      const errorMessage = await checkSocialTrial(productId, mobile);

      if (errorMessage) {
        error.your1PList[i] = {
          selectOpt: '이 상품옵션은 신규 고객에게만 제공되는 체험상품입니다.'
        };

        reject(error);
      }
    }

    resolve();
  });
};

// 공급자 회원 id 중복확인
export const checkDupilcateWhenProviderSignup = async (values) => {
  let id = '';
  let isSuccess = true;
  const error = {
    provider: {}
  };

  if (values.provider) {
    id = values.provider.id;
  } else {
    id = values.id;
  }

  const response = await checkDupilcateProviderId(id);

  if (!response || !response.data) {
    error.id = '통신 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.';
    error.provider.id = '통신 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.';

    isSuccess = false;
  }

  if (response.data.success === false) {
    error.id = '이미 회원이시네요. 로그인 해주세요.';
    error.provider.id = '이미 존재하는 공급자 아이디 입니다.';

    isSuccess = false;
  }

  return new Promise((resolve, reject) => {
    if (isSuccess) {
      resolve();

      return;
    }

    reject(error);
  });
};
