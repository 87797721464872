import axios from './defaultAxios';

// product 정보 가지고 옴
export function getProduct(productId) {
  return axios.get(`/product/${productId}`);
}

// product 등록
export function registerProduct(values) {
  return axios.post(`/product`, values);
}

export function getSocialTiral(params) {
  return axios.get(`/product/${params.productId}/socailtrial`, { params });
}

export function putProduct(values) {
  return axios.put(`/product/productid`, values);
}

export function getProductComments(params) {
  return axios.get(`/product/${params.productId}/comments`, {
    params
  });
}

export function getConnectedOneProduct(params) {
  return axios.get(`/product/${params.productId}/1exps`, {
    params
  });
}

export function getOneProductsListOfProduct(params) {
  return axios.get(`/product/${params.productId}/1products`, {
    params
  });
}

export function getOneProductsAmountOfProduct(params) {
  return axios.get(`/product/${params.productId}/1products/amount`, {
    params
  });
}

//
// (best or all) 상품 목록 가져오기
//  api - 13
export function getAllProductsList(params) {
  return axios.get(`product/best`, {
    params
  });
}
