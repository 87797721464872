// import Autolinker from 'autolinker';
export function getTrimmedString(string = '') {
  return string && typeof string === 'string' ? string.trim() : '';
}

export function getTelFormat(mobile) {
  if (!mobile) {
    return '';
  }

  return mobile.replace(
    /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
    '$1-$2-$3'
  );
}

export function truncateString(str, num) {
  if (!str) {
    return '';
  }

  let trunstr =
    str.length > num ? str.slice(0, num > 1 ? num - 1 : num) + '...' : str;

  return trunstr;
}
