import loadImage from 'blueimp-load-image';

const cdnUrl = 'cdn.from.kr';

/**
 * @param {file} file
 * 파일을 받아 읽어 orientation 방향을 수정한 blob 객체를 반환한다.
 */
export function fixRotationOfFile(file) {
  const fileType = file.type;

  // console.log('Inside fixRotationOfFile');
  // console.log({ file });

  return new Promise((resolve, reject) => {
    loadImage(
      file,
      (img, data) => {
        if (!img) {
          resolve(null);
        }

        if (img.type === 'error') {
          reject(img);
        }
        // console.log('Inside loadImage');
        // console.log({ img });
        // console.log({ data });
        // resolve(file);
        img.toBlob((blob) => {
          // console.log({ blob });

          resolve(blob);
        }, fileType);
      },
      { orientation: true, canvas: true }
    );
  });
}

/**
 * @param {blob} blob
 * @param {string} fileName
 * blob객체를 사용해 fileName의 이름으로 파일을 만든 후 반환한다.
 */
export function changeFileFromBlob(blob, fileName, fileType) {
  const createdFile = new File([blob], fileName, {
    type: fileType
  });

  return createdFile;
}

/**
 * 파일을 받아서 orientation을 수정한 파일을 반환해주는 함수.
 * @param {file} file
 */
export function getCorrectOrientationFile(file) {
  // console.log('Inside getCorrectOrientationFile');
  // console.log({ file });
  return new Promise((resolve, reject) => {
    fixRotationOfFile(file)
      .then((blob) => {
        const newFile = changeFileFromBlob(blob, file.name, file.type);
        // console.log('Inside getCorrectOrientationFile after fix');
        // console.log({ newFile });
        resolve(newFile);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 이미지 업로드 컴포넌트에서 사용하는 함수.
 * @param {object || string} image
 */
export function getImageFile(image) {
  if (!image) {
    return null;
  }

  if (typeof image === 'object') {
    if (image.path) {
      return image.path;
    }

    return image.image;
  }

  return image;
}

/**
 * 이미지 객체에서 path를 뽑아내는 함수.
 * 이미지 객체는 타입이 object이거나 string일 수 있다.
 * @param {object || string} image
 */
export const getImagePath = (image = null, width, height) => {
  if (!image) {
    return '';
  }

  const path = typeof image === 'string' ? image : image.path ? image.path : '';

  if (!path) {
    return '';
  }

  const imagePath = path
    .trim()
    .replace(/\s/g, '%20')
    .replace('s3.ap-northeast-2.amazonaws.com/1store.io.web.upload', cdnUrl)
    .replace('cdn.1store.io', cdnUrl);

  const sizeParam =
    !width && !height
      ? ''
      : width && !height
      ? `?width=${width}`
      : !width && height
      ? `?height=${height}`
      : `?width=${width}&height=${height}`;
  return imagePath.concat(sizeParam);
};

/**
 * s3.ap-northeast-2.amazonaws.com/1store.io.web.upload 를
 * cdn.from.kr 로 변경
 * @param {string} path
 */
export function replaceCDNPath(path) {
  if (!path) {
    return '';
  }

  return path
    .replace('s3.ap-northeast-2.amazonaws.com/1store.io.web.upload', cdnUrl)
    .replace('cdn.1store.io', cdnUrl);
}

/**
 * 상품 사진 가지고 오는 함수.
 * 만약 상품 사진이 없으면 기본 상품 사진을 가지고 온다.
 * @param {object} imagePath
 */
export function getProductImage(imagePath, width = 250, height = 250) {
  const path =
    getImagePath(imagePath, width, height) || '/images/default-image.svg';

  return path;
}

/**
 * 프로팔 사진 가지고 오는 함수.
 * 만약 프로필 사진이 없으면 기본 프로필 사진을 가지고 온다.
 * @param {object} imagePath
 */
export function getProfileImage(imagePath, width = 50, height = 50) {
  const path =
    getImagePath(imagePath, width, height) || '/images/default-profile.svg';

  return path;
}
