import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import OneStoreListTemplate from 'components/oneStore/OneStoreListTemplate';
import DiscoverHistoryItem from 'components/oneStore/DiscoverHistoryList/DiscoverHistoryItem';
import sentry from 'lib/sentry';
import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import storage from 'lib/storage';
import RedirectToHome from 'components/commons/RedirectToHome';
import AlertModal from 'components/modal/AlertModal';

import {
  //  getStore,
  getStoreDiscoveryList
} from 'api/store';

function DiscoverHistoryContainer({ storeId, UiAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [discoversList, setDiscoversList] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [lastKey, setLastKey] = useState(null);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // const [store, setStore] = useState({});

  // const headerText =
  //   store && store.userName
  //     ? `${store.userName}님이 발굴한 상품`
  //     : '발굴한 상품';

  const headerText = '나의 발굴함';

  useEffect(
    () => {
      UiAction.setHeader(headerText);
    },
    [UiAction, headerText]
  );

  // useEffect(
  //   () => {
  //     async function getStoreInfo() {
  //       try {
  //         const response = await getStore(storeId);

  //         if (!response || !response.data.data || !response.data.success) {
  //           throw new Error(
  //             `1S-discoversuccesslist api Error(storeInfo) - storeId: ${storeId}`
  //           );
  //         }

  //         setStore(response.data.data);
  //       } catch (e) {
  //         sentry.captureException(e);
  //       }
  //     }

  //     getStoreInfo();
  //   },
  //   [storeId]
  // );

  const infiniteRef = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMoreItems,
    onLoadMore: getDiscoverHistoryList,
    scrollContainer: 'window'
  });

  async function getDiscoverHistoryList() {
    const params = {
      lastKey,
      pageCount: 20,
      storeId
    };

    try {
      setIsLoading(true);
      const response = await getStoreDiscoveryList(params);

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          'discoverhistorylist - getStoreDiscoveryList api - error'
        );
      }

      const { data } = response.data;
      const { items } = data;

      setIsLoading(false);
      setLastKey(data.lastKey);

      setDiscoversList([...discoversList, ...items]);
      setHasMoreItems(!!data.lastKey);
    } catch (e) {
      setIsLoading(false);
      setIsOpenErrorModal(true);
      setErrorMessage('발굴목록을 가져오는중 에러가 발생했습니다');
      sentry.captureException(e.message);
    }
  }

  const userInfo = storage.get('user_userInfo');

  return (
    <Fragment>
      <RedirectToHome
        isGotoHome={!userInfo || userInfo.id !== storeId}
        redirectPath="/store/$storeId/setting/discoverhistory"
      />
      <OneStoreListTemplate
        haveList={discoversList && discoversList.length > 0}
        infiniteRef={infiniteRef}
        isLoading={isLoading}
        headerText={headerText}
      >
        {discoversList.map((discovers) => (
          <DiscoverHistoryItem
            key={discovers.id}
            discovers={discovers}
            storeId={storeId}
          />
        ))}
      </OneStoreListTemplate>
      <AlertModal
        isOpen={isOpenErrorModal}
        contentText={errorMessage}
        onToggle={() => setIsOpenErrorModal(!isOpenErrorModal)}
      />
    </Fragment>
  );
}

export default compose(
  withRouter,
  withUi,
  withUser
)(DiscoverHistoryContainer);
