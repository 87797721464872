import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import FollowContainer from 'containers/users/FollowContainer';

function Bookmark() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <FollowContainer />
    </Fragment>
  );
}

export default Bookmark;
