import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import sentry from 'lib/sentry';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import { useSimpleDialog } from 'hooks/useModal';
import ConfirmModal from 'components/modal/ConfirmModal';
import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import Loader from 'components/commons/Loader';
import { isIPhoneX } from 'lib/utils/envUtils';

import { useOneStore } from 'hooks/useOneStores';
import { oneStoreToBuyer } from 'components/checkout/CheckoutStoreUtils';

import { useCartStore, useCartDispatch } from 'components/cart/CartStore';
import { useCheckoutStore, useCheckoutDispatch } from './CheckoutStore';

import CheckoutForm from './CheckoutForm';
import CheckoutHeader from './CheckoutHeader';
import CartItem from 'components/cart/CartItem';
import CartSummary from 'components/cart/CartSummary';

import {
  CheckoutWrapper,
  CheckoutMainWrapper,
  StyledCheckoutHeader
} from './CheckoutStyle';
import { ButtonWrapper } from 'shared/buttons/ButtonStyle';
import { ItemsWrapper } from 'components/cart/ItemStyle';

const isUp = isIPhoneX();

function Checkout({ user, isLoggedIn, UiAction, showHeader }) {
  // console.log({ showHeader });
  const location = useLocation();
  const history = useHistory();
  const cart = useCartStore();
  const cartDispatch = useCartDispatch();
  const checkout = useCheckoutStore();
  const checkoutDispatch = useCheckoutDispatch();

  const { data: oneStore, isLoading, isError, error } = useOneStore({
    storeId: user.id
  });

  const {
    isOpenDialog,
    handleToggleDialog,
    handleConfirm,
    handleDecline,
    isConfirmed
  } = useSimpleDialog();

  const orderIsEmpty = !(checkout?.orders?.length > 0);
  const orderType = location?.state?.orderType;
  const currentOrder = checkout.currentOrder;
  const order = checkout?.orders[currentOrder];
  const receiver = order?.receiver;

  // console.log('Rendering Checkout');
  // console.log('current receiver');
  // console.log({ receiver });

  const initialize = (orderType) => {
    updateBuyer(isLoggedIn ? oneStore : {});

    const receiver =
      orderType === 'buy' ? oneStoreToBuyer(oneStore) : undefined;
    // console.log({ orderType });
    // console.log({ receiver });
    checkoutDispatch({
      type: 'initialize',
      payload: { items: cart.items, orderType, receiver }
    });
    history.replace('/order/checkout'); // to clear state
  };

  const updateBuyer = (oneStore) => {
    const buyer = oneStoreToBuyer(oneStore);
    // console.log({ oneStore });
    checkoutDispatch({
      type: 'updateBuyer',
      payload: buyer
    });
    if (order?.orderType === 'buy') {
      checkoutDispatch({
        type: 'updateReceiver',
        payload: buyer
      });
    }
  };

  const addOrder = (orderType) => {
    checkoutDispatch({
      type: 'addOrder',
      payload: orderType
    });
  };

  const removeOrder = (index) => {
    checkoutDispatch({
      type: 'removeOrder',
      payload: index
    });
  };

  // const addItem = (item) => {
  //   console.log({ item });
  //   console.log('dispatching');
  //   checkoutDispatch({
  //     type: 'add',
  //     payload: item
  //   });
  //   console.log('dispatched');
  // };

  // const changeQuantity = (productId, optionId, incrementTo) => {
  //   checkoutDispatch({
  //     type: 'increment',
  //     payload: { productId, optionId, incrementTo }
  //   });
  // };

  const emptyCart = () => {
    checkoutDispatch({ type: 'empty' });
  };

  const restoreCart = () => {
    checkoutDispatch({ type: 'restore' });
  };

  const addReceiver = (receiver) => {
    // console.log('adding Receiver');
    // console.log({ receiver });
    checkoutDispatch({ type: 'addReceiver', payload: receiver });
  };

  const prevOrder = () => {
    checkoutDispatch({ type: 'nextOrder', payload: -1 });
  };
  const nextOrder = () => {
    checkoutDispatch({ type: 'nextOrder', payload: 1 });
  };

  const goToOrder = (index, receiver) => {
    checkoutDispatch({ type: 'goToOrder', payload: index });
    history.push(`/order/checkout`);
  };

  const removeItem = (productId, optionId) => {
    checkoutDispatch({ type: 'remove', payload: { productId, optionId } });
  };

  const increment = (productId, optionId, incrementBy = 1) => {
    checkoutDispatch({
      type: 'increment',
      payload: { productId, optionId, incrementBy }
    });
  };
  const decrement = (productId, optionId) => {
    checkoutDispatch({
      type: 'increment',
      payload: { productId, optionId, incrementBy: -1 }
    });
  };

  const onClickOption = (seller, product, provider) => {
    history.push({
      pathname: `/store/${seller.id}/${product.id}/options/choose`,
      state: {
        itemType: 'checkout',
        product,
        options: product.options,
        provider,
        seller
      }
    });
  };

  useEffect(() => {
    UiAction.setHeader('받는 분 입력하기');
    // console.log('Checkout');
    // console.log({ location });

    if (orderType) {
      if (orderIsEmpty) {
        initialize(orderType);
      } else {
        handleToggleDialog();
      }
    } else if (orderIsEmpty) {
      history.replace('/order/cart');
    }
  }, []);

  useEffect(() => {
    // console.log({ oneStore });
    updateBuyer(isLoggedIn ? oneStore : {});
  }, [oneStore]);

  useEffect(() => {
    if (!orderType && orderIsEmpty) {
      history.replace('/order/cart');
    }
  }, [orderIsEmpty]);

  useEffect(() => {
    // console.log({ isConfirmed });
    // console.log({ isOpenDialog });
    if (isConfirmed) {
      initialize(orderType);
    } else if (!isOpenDialog && isConfirmed !== undefined) {
      // console.log('replacing path');
      // console.log({ isOpenDialog });
      history.replace('/order/checkout');
    }
  }, [isOpenDialog]);

  // console.log('current receiver');
  // console.log({ receiver });
  if (isError) return <p>{error.message};</p>;

  return (
    <CheckoutWrapper>
      <ScrollToTopOnMount />
      {/* <CheckoutHeader /> */}

      <CheckoutMainWrapper>
        {isLoading && <Loader />}

        <CheckoutForm
          isGift={order?.orderType !== 'buy'}
          isDelivery={order?.checkedTotal?.deliveryCount > 0}
          currentOrder={currentOrder}
          receiver={receiver}
          totalAmount={checkout?.orderTotal?.totalAmount || 0}
          addReceiver={addReceiver}
          addOrder={addOrder}
          removeOrder={removeOrder}
          isLoggedIn={isLoggedIn}
          showHeader={showHeader}
          isUp={isUp}
        />
        {/* <ButtonWrapper>
        <button className="white-button" onClick={emptyCart}>
          비우기
        </button>
        <button className="white-button" onClick={restoreCart}>
          다시 담기
        </button>
      </ButtonWrapper> */}
        <ItemsWrapper>
          {order &&
            order.items.map((item, index) => (
              <CartItem
                key={item.option.id}
                itemType="checkout"
                index={index}
                {...item}
                {...{ removeItem, increment, decrement, onClickOption }}
              />
            ))}
        </ItemsWrapper>
        <CartSummary
          title={`${orderType === 'gift' ? '선물' : '주문'} 합계`}
          type="checkout"
          {...order?.checkedTotal}
        />
        <ConfirmModal
          headerText="알림"
          headerImage="/images/header-image/sori-header-image.svg"
          contentText={`받는 분 정보가 있습니다.`}
          isOpen={isOpenDialog}
          okButtonText="새로 작성하기"
          closeButtonText="이어서 작성하기"
          onClickOk={handleConfirm}
          onToggle={handleDecline}
        />
      </CheckoutMainWrapper>

      {/* <div>
        <pre>{JSON.stringify(checkout.orders.length, null, 2)}</pre>
      </div>

      <div>
        <pre>{JSON.stringify(checkout.errorMessage, null, 2)}</pre>
      </div>
      <div>
        <pre>{JSON.stringify(order?.orderType, null, 2)}</pre>
      </div>

      <div>
        <pre>{JSON.stringify(receiver, null, 2)}</pre>
      </div>

      <div>
        <pre>{JSON.stringify(checkout.orderTotal, null, 2)}</pre>
      </div>
      <div>
        <pre>{JSON.stringify(order?.total, null, 2)}</pre>
      </div>
      <div>
        <pre>{JSON.stringify(order, null, 2)}</pre>
      </div> */}
    </CheckoutWrapper>
  );
}
export default withUser(withUi(Checkout));
