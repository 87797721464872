import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import sentry from 'lib/sentry';

import queryString from 'query-string';
import { usePost } from 'hooks/usePosts';
import Loader from 'components/commons/Loader';
import { setReferrer } from 'lib/referrer';
import withUi from 'lib/hoc/withUi';
import EmptyList from 'components/commons/EmptyList';

import PostCard from './PostCard';
import { StyledButton } from 'shared/styled/componentStyle';
import { PostDetailWrapper, PostButtonWrapper } from './PostCardStyle';

function PostDetail({ UiAction }) {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  // console.log({ params });
  const { ownerId, productId, commentsId } = params;
  const id = `${ownerId}_${productId}-${commentsId}`;
  // console.log({ id });

  const { isLoading, data, isError, error, isFetching } = usePost({ id });

  // console.log({ data });
  const post = (data && data.Items.find((item) => item.depth === 0)) || {};
  const replies = data && data.Items.filter((item) => item.depth !== 0);
  const replyCount = replies?.length || 0;
  const authorName = post?.author?.name || '';
  // console.log({ post });
  // console.log({ replies });
  // console.log({ authorName });
  const ownerType = post?.owner?.type || 'store';

  const detailLink = post?.productId
    ? `/${ownerType}/${ownerId}/${productId}/detail`
    : `/${ownerType}/${ownerId}`;

  const detailName = post?.productId
    ? `${post?.product?.name || ''}`
    : `${post?.owner?.name || ''}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (productId) {
      const parsed = location ? queryString.parse(location.search) : null;
      // console.log({ parsed });
      const referrerId = parsed?.ref || ownerId;
      // console.log({ referrerId });
      setReferrer(productId, referrerId);
    }
  }, [productId]);

  useEffect(() => {
    if (authorName) {
      UiAction.setHeader(`${authorName}님의 이야기`);
    }
  }, [authorName]);

  const isEmpty = !(data?.Items?.length > 0);

  if (isError) return <p>{error.message}</p>;

  return isLoading ? (
    <Loader />
  ) : isEmpty ? (
    <EmptyList contentText="존재하지 않거나 삭제된 이야기입니다." />
  ) : (
    <PostDetailWrapper>
      <PostCard post={{ ...post, replyCount }} isDetail />
      {replies &&
        replies.length > 0 &&
        replies.map((reply) => {
          return <PostCard post={reply} isReply isDetail />;
        })}
      <PostButtonWrapper>
        <StyledButton
          size="medium"
          color="white"
          onClick={() => history.push(detailLink)}
        >
          {post?.productId ? ' 상품 구경가기' : `${detailName}님에게 놀러가기`}
        </StyledButton>
        {post?.productId && (
          <StyledButton
            size="medium"
            color="white"
            onClick={() => history.push(detailLink.replace('detail', 'posts'))}
          >
            이야기 더보기
          </StyledButton>
        )}
      </PostButtonWrapper>
    </PostDetailWrapper>
  );
}

export default withUi(PostDetail);
