import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './BuyerInfoSaveCheckBox.scss';

function BuyerInfoSaveCheckBox({
  checkboxName,
  className,
  labelText,
  isCheckedBuyerInfoSave,
  onChangeCheckbox
}) {
  return (
    <div className={classNames('buyerinfo-save', className)}>
      <div className="buyerinfo-save-checkboxs">
        <label htmlFor={checkboxName}>
          {labelText}
          <input
            type="checkbox"
            name={checkboxName}
            id={checkboxName}
            onChange={onChangeCheckbox}
            checked={isCheckedBuyerInfoSave}
          />
          <span className="checkmark" />
        </label>
      </div>
    </div>
  );
}

BuyerInfoSaveCheckBox.propTypes = {
  checkboxName: PropTypes.string,
  className: PropTypes.string,
  labelText: PropTypes.string,
  isCheckedBuyerInfoSave: PropTypes.bool,
  onChangeCheckbox: PropTypes.func
};

BuyerInfoSaveCheckBox.defaultProps = {
  checkboxName: '',
  className: '',
  labelText: '안내를 모두 읽었으며, 동의합니다.',
  isCheckedBuyerInfoSave: false,
  onChangeCheckbox: () => {}
};

export default BuyerInfoSaveCheckBox;
