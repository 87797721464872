import React, { Component, Fragment } from 'react';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { change } from 'redux-form';
import { connect } from 'react-redux';
// import { Helmet } from 'react-helmet';
import { bindActionCreators, compose } from 'redux';
import { withCookies, Cookies } from 'react-cookie';

import * as userActions from 'modules/user';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

// import storage from 'lib/storage';

// import RedirectToHome from 'components/commons/RedirectToHome';
import AlertModal from 'components/modal/AlertModal';
import SignInForm from 'components/users/SignInForm';
// import FacebookAuthContainer from 'containers/users/FacebookAuthContainer';
import KakaoAuthContainer from 'containers/users/KakaoAuthContainer';
import { saveSession } from 'lib/users/auth';

class SignInContainer extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = this.props;
    this.savedEmail = cookies.get('store_email');

    this.state = {
      isCheckedStoreEmail: !!this.savedEmail || true,
      isLoading: false,
      isErrorSignIn: false,
      isOpenModal: false
    };
  }

  componentDidMount() {
    const { changeField, UiAction } = this.props;

    changeField('email', this.savedEmail);

    UiAction.setHeader('로그인');
  }

  setCookie = (email) => {
    const { cookies } = this.props;
    const exdate = new Date();

    exdate.setDate(exdate.getDate() + 7); // 7일동안 쿠키 보관

    cookies.set('store_email', email, {
      expires: exdate,
      path: '/'
    });
  };

  removeCookie = () => {
    const { cookies } = this.props;
    const exdate = new Date();

    exdate.setDate(exdate.getDate() - 1);

    cookies.remove('store_email', {
      expires: exdate
    });
  };

  handleSignIn = (values) => {
    const { UserActions } = this.props;

    values.email = values.email.trim();
    values.loginType = '1store';

    this.setState(
      {
        isLoading: true
      },
      async () => {
        try {
          // login api 호출
          const response = await UserActions.signIn(values);

          if (!response || !response.data || !response.data.success) {
            throw new Error(`로그인 실패 - ${JSON.stringify(response)}`);
          }

          // 로컬 스토리지에 로그인한 유저 정보 저장
          saveSession(response.data);

          // mobile login callback
          const { userInfo } = response.data;
          const userAgent = navigator.userAgent.toLowerCase();
          if (userAgent.indexOf('1store_iphone') > -1) {
            const loginUserInfo = {
              userId: userInfo.id,
              userType: 'store'
            };
            // eslint-disable-next-line
            webkit.messageHandlers.userLogIn.postMessage(
              JSON.stringify(loginUserInfo)
            );
          } else if (userAgent.indexOf('1store_android') > -1) {
            const loginUserInfo = {
              userId: userInfo.id,
              userType: 'store'
            };
            // eslint-disable-next-line
            __1STORE_ANDROID.userLogIn(JSON.stringify(loginUserInfo));
          }

          // 이메일 저장에 체크되어 있으면 쿠키에 이메일 주소 저장, 그렇지 않으면 이메일 주소 삭제
          const { isCheckedStoreEmail } = this.state;
          if (isCheckedStoreEmail === true) {
            this.setCookie(values.email);
          } else {
            this.removeCookie();
          }

          // page redirection
          const { history, location } = this.props;
          // console.log({ history });
          // console.log({ location });
          // alert(location);
          if (location.state && location.state.redirectUrl) {
            const { redirectUrl } = location.state;
            const values = redirectUrl.split('-');
            let pathname = values[0];
            pathname = pathname.replace('$storeId', userInfo.id);
            // console.log({ pathname });
            history.replace(pathname);
          } else if (location.state && location.state.from) {
            // console.log({ location });
            history.replace(
              location.state.from.pathname,
              location.state.from.state
            );
          } else {
            history.replace(`/store/${userInfo.id}`);
          }
        } catch (e) {
          this.setState({
            isLoading: false,
            isErrorSignIn: true
          });
        }
      }
    );
  };

  handleToggleModal = () => {
    const { isOpenModal } = this.state;

    this.setState({
      isOpenModal: !isOpenModal
    });
  };

  handleToggleCheckbox = (checked) => {
    this.setState({
      isCheckedStoreEmail: checked
    });
  };

  render() {
    const { isLoggedIn, user, UserActions } = this.props;
    const {
      isCheckedStoreEmail,
      isOpenModal,
      isLoading,
      isErrorSignIn
    } = this.state;

    return (
      <Fragment>
        {/* <Helmet bodyAttributes={{ style: 'background-color : #f6f6f6' }} /> */}
        {/* <RedirectToHome isGotoHome={isLoggedIn} /> */}
        <SignInForm
          isCheckedStoreEmail={isCheckedStoreEmail}
          isLoading={isLoading}
          isErrorSignIn={isErrorSignIn}
          onSignIn={this.handleSignIn}
          onToggleCheckbox={this.handleToggleCheckbox}
        >
          {/* <FacebookAuthContainer
            onClick={this.handleToggleModal}
            history={this.props.history}
          /> */}
          <KakaoAuthContainer
            onClick={this.handleToggleModal}
            userAction={UserActions}
            history={this.props.history}
            location={this.props.location}
            user={user}
            isLoggedIn={isLoggedIn}
          />
        </SignInForm>
        <AlertModal
          isOpen={isOpenModal}
          contentText="준비 중 입니다."
          onToggle={this.handleToggleModal}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => {
    dispatch(change('signInForm', field, value));
  },
  UserActions: bindActionCreators(userActions, dispatch)
});

export default compose(
  withCookies,
  withRouter,
  withUser,
  withUi,
  connect(() => ({}), mapDispatchToProps)
)(SignInContainer);
