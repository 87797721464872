import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import OneStoreFollowContainer from 'containers/oneStore/OneStoreFollowContainer';

function OneStoreFollowProvider({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <OneStoreFollowContainer storeId={match.params.storeId} isProvider />
    </Fragment>
  );
}

export default OneStoreFollowProvider;
