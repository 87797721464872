import React, { Component, Fragment } from 'react';
import { instanceOf } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import axios from 'api/defaultAxios';

import sentry from 'lib/sentry';
import storage from 'lib/storage';
import withUi from 'lib/hoc/withUi';

import { getProviderDeliveryList } from 'api/provider';

import AlertModal from 'components/modal/AlertModal';
import ShippingList from 'components/provider/ShippingList';
import RedirectToHome from 'components/commons/RedirectToHome';
import DeliveryNumInputModal from 'components/modal/DeliveryNumInputModal';

import * as providerActions from 'modules/provider';

class ProviderShippingContainer extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isPersonShipping: false, // 직접 배송
      dataLoading: false,
      isInputLoading: false,
      isOpenDeliveryInputModal: false,
      isOpenErrorModal: false,
      errorMessage: '',
      deliveryList: [],
      changeStatusProcess: 0,
      invoiceNumber: '',
      deliveryName: ''
    };

    this.currentIndex = -1;
    this.lastKey = null;
    this.pageCount = 20;
    this.deliveryId = '';
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.provider !== this.props.provider) {
      this.setHeader();
    }
  }

  componentWillUnmount = () => {
    const { ProviderAction } = this.props;

    ProviderAction.initializeProvider();
  };

  getDeliveryCookie = () => {
    const { cookies } = this.props;
    const cookieDelivery = cookies.get('provider_delivery');

    return cookieDelivery;
  };

  setDeliveryCookie = () => {
    const { cookies } = this.props;
    const { changeStatusProcess, deliveryName } = this.state;
    const exdate = new Date();

    exdate.setDate(exdate.getDate() + 7); // 7일동안 쿠키 보관

    const cookieDelivery = {
      cookieCode: changeStatusProcess,
      name: deliveryName
    };

    cookies.set('provider_delivery', cookieDelivery, {
      expires: exdate,
      path: '/'
    });
  };

  setHeader = () => {
    const { UiAction, provider } = this.props;

    const headerText =
      provider && provider.providerName
        ? `${provider.providerName} 배송관리`
        : '배송관리';

    UiAction.setHeader(headerText);
  };

  getProvider = async () => {
    const { ProviderAction, match } = this.props;
    const { providerId } = match.params;

    try {
      const response = await ProviderAction.getProvider(providerId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `공급자 배송관리 페이지 - pp 데이터 불러오기 실패: ${JSON.stringify(
            response
          )}}`
        );
      }
    } catch (e) {
      sentry.captureException(e.message);
    }
  };

  getProviderDelivery = () => {
    this.setState(
      {
        dataLoading: true
      },
      async () => {
        try {
          const { match } = this.props;
          const { providerId } = match.params;

          const response = await getProviderDeliveryList({
            providerId,
            pageCount: this.pageCount,
            lastKey: this.lastKey
          });

          if (!response || !response.data.success) {
            throw new Error({
              errorMessage: '배송 내역을 불러오는 중 오류가 발생했습니다.'
            });
          }

          const { LastEvaluatedKey, Items } = response.data.data;
          const { deliveryList } = this.state;

          this.lastKey = LastEvaluatedKey;

          this.setState({
            isLoading: false,
            dataLoading: false,
            hasLastKey: !!LastEvaluatedKey,
            deliveryList: deliveryList.concat(Items || [])
          });
        } catch (e) {
          this.setState({
            isOpenErrorModal: true,
            errorMessage: e.errorMessage || '배송 내역을 불러올 수 없습니다.',
            isLoading: false,
            dataLoading: false
          });
        }
      }
    );
  };

  initialize = () => {
    const { isLoading } = this.state;

    if (isLoading) {
      return;
    }

    this.setState({
      isLoading: true
    });

    this.getProvider();
    this.setHeader();
    this.getProviderDelivery();
  };

  handleOpenModal = (modalType) => {
    this.setState({
      [modalType]: true
    });
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  handleChangeSelect = (e) => {
    const targetIndex = e.target.selectedIndex;
    const targetValue = e.target.value;

    this.setState({
      deliveryName: e.target[targetIndex].text,
      changeStatusProcess: e.target.value,
      isPersonShipping: targetValue === '000',
      invoiceNumber: ''
    });
  };

  // handleChangeNumber = (value) => {
  //   this.setState({
  //     invoiceNumber: value
  //   });
  // };

  handleChangeNumber = (e) => {
    const { isPersonShipping } = this.state;
    this.setState({
      invoiceNumber: !isPersonShipping
        ? String(e.target.value).replace(/\D/g, '')
        : e.target.value
    });
  };

  handleOpenInputModal = (index) => {
    const { deliveryList } = this.state;
    const { deliverer, id } = deliveryList[index];

    const {
      id: deliveryCode,
      name: deliveryName,
      trackingNumber: invoiceNumber
    } = deliverer || {};

    const cookieDelivery = this.getDeliveryCookie() || '';
    const changeStatusProcess = deliveryCode || cookieDelivery.cookieCode;
    const tempDeliveryName = deliveryName || cookieDelivery.name;

    this.currentIndex = index;
    this.deliveryId = id;

    this.setState({
      changeStatusProcess,
      deliveryName: tempDeliveryName,
      invoiceNumber: invoiceNumber,
      isOpenDeliveryInputModal: true,
      isPersonShipping: changeStatusProcess === '000'
    });
  };

  handleSaveInvoiceNum = async () => {
    const {
      invoiceNumber,
      isPersonShipping,
      changeStatusProcess,
      deliveryName,
      deliveryList
    } = this.state;
    const { match } = this.props;
    const { providerId } = match.params;

    if (!changeStatusProcess || !deliveryName) {
      const errorMessage = '택배사를 입력해주세요.';

      this.setState({
        isOpenErrorModal: true,
        errorMessage
      });
      return;
    }

    if (!isPersonShipping && !invoiceNumber) {
      const errorMessage = '운송장번호를 입력해주세요.';

      this.setState({
        isOpenErrorModal: true,
        errorMessage
      });
      return;
    }

    const values = {
      id: this.deliveryId,
      deliverer: {
        id: changeStatusProcess,
        name: deliveryName,
        trackingNumber: invoiceNumber || ' '
      }
    };

    try {
      this.setState({ isInputLoading: true });
      await axios
        .put(`/provider/${providerId}/delivery/${this.deliveryId}`, values)
        .then((res) => {
          if (!res.data.success) {
            const errorMessage = '운송장을 업데이트 하는데 실패하였습니다.';

            this.setState({
              isInputLoading: false,
              isOpenErrorModal: true,
              errorMessage
            });
            return;
          }
          const list = deliveryList;

          list[this.currentIndex] = {
            ...list[this.currentIndex],
            deliverer: values.deliverer
          };

          this.setDeliveryCookie();

          this.deliveryId = '';
          this.setState({
            isInputLoading: false,
            changeStatusProcess,
            isOpenDeliveryInputModal: false,
            invoiceNumber: ''
          });
        });
    } catch (e) {
      this.deliveryId = '';
      this.setState({
        isInputLoading: false,
        isOpenErrorModal: true,
        errorMessage: '운송장을 업데이트 하는데 실패하였습니다.',
        invoiceNumber: ''
      });
    }
  };

  render() {
    const {
      deliveryList,
      hasLastKey,
      isOpenDeliveryInputModal,
      changeStatusProcess,
      isLoading,
      isPersonShipping,
      dataLoading,
      isInputLoading,
      invoiceNumber,
      isOpenErrorModal,
      errorMessage
    } = this.state;
    const userInfo = storage.get('user_userInfo');
    const { match } = this.props;
    const { providerId } = match.params;

    return (
      <Fragment>
        <RedirectToHome
          isGotoHome={!userInfo || userInfo.id !== providerId}
          isProvider
          redirectPath="/provider/$providerId/setting/deliveryproducts"
        />
        <ShippingList
          isLoading={isLoading}
          dataLoading={dataLoading}
          deliveryList={deliveryList}
          onGet={this.getProviderDelivery}
          hasLastKey={hasLastKey}
          onOpenInputModal={this.handleOpenInputModal}
          onToggleModal={() => {
            this.handleOpenModal('isOpenDeliveryInputModal');
          }}
        />
        <DeliveryNumInputModal
          isOpen={isOpenDeliveryInputModal}
          isLoading={isInputLoading}
          isPersonShipping={isPersonShipping}
          onChangeSelect={this.handleChangeSelect}
          onChangeNumber={this.handleChangeNumber}
          changeStatusProcess={changeStatusProcess}
          invoiceNumber={invoiceNumber}
          onSaveInvoiceNum={this.handleSaveInvoiceNum}
          onToggle={() => {
            this.handleCloseModal('isOpenDeliveryInputModal');
          }}
        />
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          isProviderModal
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ provider }) => ({
  provider: provider.provider
});

const mapDispatchToProps = (dispatch) => ({
  ProviderAction: bindActionCreators(providerActions, dispatch)
});

export default compose(
  withCookies,
  withUi,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProviderShippingContainer);
