import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';

import { checkDuplicateMobile, mappingSns } from 'api/store';

import sentry from 'lib/sentry';
import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import KakaoAuthForm from 'components/users/KakaoAuthForm';
import { saveSession } from 'lib/users/auth';

class KakaoAuthModal extends Component {
  constructor() {
    super();

    this.state = {
      flagDupplicated: -1 // 중복 검사 안했을 땐 -1
    };

    this.storeInfo = null;
  }

  componentDidMount = () => {
    const { UiAction } = this.props;

    UiAction.setHeader('카카오톡으로 시작하기');

    this.checkKakaoInfo();
  };

  checkKakaoInfo = () => {
    const { location, history } = this.props;

    if (!location || !location.state || !location.state.userInfo) {
      history.goBack(-1);
    }
  };

  showFieldError = (fieldName) => {
    const element = document.getElementsByName(fieldName);

    if (element.length < 0) {
      return;
    }

    const targetElement = element[0];

    // 오류 메시지 출력
    targetElement.focus();
    targetElement.blur();

    // 버튼 비활성화
    this.setState({
      flagDupplicated: -1
    });

    // 포커스 이동
    targetElement.focus();
  };

  signInStoreWithKakao = async () => {
    const { history, location } = this.props;

    try {
      const loginValue = {
        loginType: 'kakao',
        snsId: location.state.userInfo.snsId.toString(),
        snsToken: ''
      };

      const { UserActions } = this.props;
      const response = await UserActions.signIn(loginValue);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`카카오 로그인 실패 - ${JSON.stringify(response)}`);
      }

      // 로컬 스토리지에 로그인한 유저 정보 저장
      saveSession(response.data);

      // mobile callback 
      const { userInfo } = response.data;
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('1store_iphone') > -1) {
        const loginUserInfo = {
          "userId" : userInfo.id,
          "userType" : 'store'
        };
        // eslint-disable-next-line
        webkit.messageHandlers.userLogIn.postMessage(JSON.stringify(loginUserInfo));
      }
      else if (userAgent.indexOf('1store_android') > -1) {
        const loginUserInfo = {
          "userId" : userInfo.id,
          "userType" : 'store'
        };
        // eslint-disable-next-line
        __1STORE_ANDROID.userLogIn(JSON.stringify(loginUserInfo));
      }

      if (location.state && location.state.redirectUrl) {
        let { redirectUrl } = location.state;

        redirectUrl = redirectUrl.replace('$storeId', userInfo.id);

        history.replace({
          pathname: decodeURIComponent(redirectUrl),
          state: {
            userInfo: {
              ...location.state.userInfo
            }
          }
        });
      } else {
        history.replace(`/store/${userInfo.id}`);
      }
    } catch (e) {
      sentry.captureException(e.message);

      alert('로그인에 실패하였습니다.');
    }
  };

  handleOnChange = () => {
    this.setState({
      flagDupplicated: -1
    });
  };

  handleChangeSubmitButton = async () => {
    const { userName, mobile } = this.props;
    let flagDupplicated = -1; // 회원가입 한 적 없으면 0

    if (!userName) {
      return Promise.resolve();
    }

    return new Promise(async (resolve, reject) => {
      try {
        const response = await checkDuplicateMobile(mobile);

        if (!response || !response.data) {
          throw new Error();
        }

        if (response.data.success) {
          flagDupplicated = 1; // 회원가입 한적 있으면 1

          this.storeInfo = response.data.data;
        } else {
          if (response.data.message === 'not exist') {
            flagDupplicated = 0;
          }
        }

        this.setState({
          flagDupplicated
        });

        resolve();
      } catch (err) {
        const error = {
          mobile: '데이터 요청이 원할하지 않습니다. 잠시 후 다시 시도해주세요.'
        };

        reject(error);
      }
    });
  };

  handleClickCheckButton = () => {
    const { userName, mobile } = this.props;

    if (!userName) {
      this.showFieldError('userName');

      return;
    }

    if (!mobile) {
      this.showFieldError('mobile');

      return;
    }

    if (!/^\d{2,3}(-|\s+)?\d{3,4}(-|\s+)?\d{4}$/.test(mobile)) {
      this.showFieldError('mobile');

      return;
    }

    this.handleChangeSubmitButton();
  };

  handleTest = async () => {
    const { location } = this.props;

    const values = {
      storeId: this.storeInfo.id,
      snsType: 'kakao',
      snsId: location.state.userInfo.snsId.toString()
    };

    try {
      const response = await mappingSns(values);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`카카오 정보 매핑 실패 - ${JSON.stringify(response)}`);
      }

      this.signInStoreWithKakao();
    } catch (e) {
      sentry.captureException(e.message);

      alert('로그인에 실패하였습니다.');
    }
  };

  handleRedirectSignUp = (value) => {
    const { location, history } = this.props;

    history.replace({
      pathname: '/store/signup',
      state: {
        ...location.state,
        userInfo: {
          ...location.state.userInfo,
          userName: value.userName,
          mobile: value.mobile
        }
      }
    });
  };

  render() {
    const { flagDupplicated } = this.state;

    return (
      <KakaoAuthForm
        asyncValidate={this.handleChangeSubmitButton}
        isDupplicated={flagDupplicated === 1}
        isNotDuplicated={flagDupplicated === 0}
        onChangeField={this.handleOnChange}
        onClickCheck={this.handleClickCheckButton}
        onSubmit={
          flagDupplicated === 1 ? this.handleTest : this.handleRedirectSignUp
        }
      />
    );
  }
}

const selector = formValueSelector('kakaoAuthForm');

export default compose(
  withUi,
  withUser,
  withRouter,
  connect((state) => {
    return {
      mobile: selector(state, 'mobile'),
      userName: selector(state, 'userName')
    };
  })
)(KakaoAuthModal);
