/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import classNames from 'classnames';

import { getPriceFormat } from 'lib/utils/numberUtils';
import { getTotalPrice } from 'lib/utils/newPriceUtils';

import './OrderSummaryView.scss';

function OrderSummaryView({
  total,
  totalQuantity,
  totalPayment,
  totalPayable
}) {
  // console.log({ totalPayment });

  // const { total, point, net } = totalPayment || {};
  // const { deliveryFee, giftPoint } = totalPayable || {};
  // const discountedTotalPrice = getTotalPrice(your1PList);

  const { quantity, amount, deliveryFee, giftPoint, totalAmount } = total || {};

  return (
    <div className="payment-price-info">
      <div className="payment-price-info-title">주문 금액</div>
      <ul>
        <li className="payment-total-price mb-3 text-left">
          총 수량
          <span className="payment-total-price-number">
            <span className="font-weight-bold">
              {quantity || totalQuantity}개
            </span>
          </span>
        </li>
        <li className="payment-total-price mb-3 text-left">
          총 상품 금액
          <span className="payment-total-price-number">
            <span className="font-weight-bold">
              {`${
                getPriceFormat(
                  amount || totalPayment?.total - totalPayable?.deliveryFee
                ) || 0
              }`}
              원
            </span>
          </span>
        </li>
        <li className="payment-total-price mb-3 text-left">
          총 배송비
          <span className="payment-total-price-number">
            <span className="font-weight-bold">
              {getPriceFormat(deliveryFee || totalPayable?.deliveryFee || 0)}원
            </span>
          </span>
        </li>
        {/* {discountedTotalPrice > freeDeliveryAmount && (
          <div className="payment-free-delivery mb-3 text-right">
            {`${getPriceFormat(
              freeDeliveryAmount
            )}원 이상 구매시 - 배송비 무료`}
          </div>
        )} */}
        <li className="payment-total-price mb-3 text-left">
          총 주문 금액
          <span className="payment-total-price-number">
            <span className="font-weight-bold">
              {getPriceFormat(totalAmount || totalPayment?.total || 0)}원
            </span>
          </span>
        </li>
        {/* <li className="payment-total-price mb-3 text-left">
          사용한 적립금
          <span className="payment-total-price-number">
            <span className="font-weight-bold">{getPriceFormat(point)}원</span>
          </span>
        </li>
        <li className="payment-total-price payment-total-total-price mb-3 text-left">
          총 결제 금액
          <span className="payment-total-price-number">
            <span className="font-weight-bold">
              {getPriceFormat(Math.floor(net))}원
            </span>
          </span>
        </li> */}
        {giftPoint > 0 && (
          <li className="payment-total-price mb-3 text-left">
            총 적립 금액
            <span className="payment-total-price-number">
              <span className="font-weight-bold">
                {getPriceFormat(giftPoint)}원
              </span>
            </span>
          </li>
        )}
      </ul>
    </div>
    // <div className={className}>
    //   {/* <h2>결제 내역</h2> */}
    //   <ul>
    //     <li>
    //       <span className="label">총 주문액</span>
    //       <span className="number-value">{getPriceFormat(total)}원</span>
    //     </li>

    //     <li>
    //       <span className="label">사용한 적립금</span>
    //       <span className="number-value">{getPriceFormat(point)}원</span>
    //     </li>
    //     <li>
    //       <span className="label">결제 금액</span>
    //       <span className="number-value">{getPriceFormat(net)}원</span>
    //     </li>
    //   </ul>
    // </div>
  );
}

export default OrderSummaryView;
