import React, { Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';

import Button from 'components/commons/Button';
import RenderField from 'components/reduxForm/RenderField';
import RenderFieldTextArea from 'components/reduxForm/RenderFieldTextArea';
import RenderSelectField from 'components/reduxForm/RenderSelectField';

import PictureInput from 'components/commons/PictureInput';
import AddressModal from 'components/modal/AddressModal';

import {
  checkEmailType,
  required,
  numeric,
  minLength6,
  maxLength16,
  tel,
  alphanumeric,
  idMinLength6
} from 'lib/form/validations';

function ProviderEditForm({
  businessLicenseImage,
  handleSubmit,
  isPrivate,
  isSubmitLoading,
  isOpenSearchAddress,
  onChangeImage,
  onChangeAddress,
  onClickBack,
  onSubmit,
  onToggle,
  passbookImage,
  providerProfileImage
}) {
  return (
    <Fragment>
      <div className="member-edit-form pt-3 pl-3 pr-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="form-headline">필수 입력 항목</h3>
          <Field
            name="id"
            label="아이디"
            type="text"
            className="form-with-button"
            component={RenderField}
            placeholder="6자 이상 영문, 숫자, 특수문자"
            validate={[required, alphanumeric, idMinLength6]}
            pattern={/\s/g}
            readOnly
            isRequired
          />
          <Field
            name="providerName"
            label="공급자명"
            type="text"
            className="form-with-button"
            component={RenderField}
            placeholder="공급하는 회사 또는 대표 이름"
            validate={[required]}
            isRequired
          />
          <Field
            name="ceoName"
            autocomplete="name"
            label="대표자명"
            type="text"
            component={RenderField}
            placeholder="대표자 이름"
            validate={[required]}
            isRequired
          />
          <Field
            name="mobile"
            type="tel"
            component={RenderField}
            label="공급자 전화번호"
            placeholder="생산자/공급자 대표 전화번호"
            pattern={/[^0-9]/g}
            validate={[required, tel]}
            isRequired
          />
          <Field
            component={RenderField}
            label="담당자 이름"
            name="picName"
            placeholder="프롬과 함께 고객을 도울 담당자"
            type="text"
            validate={[required]}
            isRequired
          />
          <Field
            component={RenderField}
            label="담당자 휴대폰 번호"
            name="picMobile"
            pattern={/[^0-9]/g}
            placeholder="응원글알림, 배송알림 등 문자를 받습니다."
            type="text"
            validate={[required, numeric, tel]}
            isRequired
          />
          <Field
            component={RenderField}
            label="담당자 이메일"
            name="picEmail"
            placeholder="주문내역, 정산 내역을 메일로 받습니다."
            type="email"
            validate={[required, checkEmailType]}
            isRequired
          />
          <h3 className="member-edit-optional-form form-headline">
            추가 입력 항목
          </h3>
          <div>
            <div className="form-group clearfix">
              <label
                className="form-label form-picture-label float-left text-left pr-3"
                htmlFor="profile-image"
              >
                프로필 사진
              </label>
              <div className="float-left">
                <PictureInput
                  imageFile={providerProfileImage}
                  onChange={(imageFile) => {
                    onChangeImage('providerProfileImage', imageFile);
                  }}
                />
              </div>
            </div>
          </div>
          <Field
            name="zipcode"
            type="text"
            className="form-with-button"
            component={RenderField}
            label="우편번호"
            placeholder="클릭해서 우편번호 입력"
            onFocus={onToggle}
            readOnly
          >
            <button
              type="button"
              className="form-button btn-primary form-input-in-button"
              onClick={onToggle}
            >
              찾기
            </button>
          </Field>
          <Field
            name="addr"
            type="text"
            className="received-gift-addr"
            component={RenderFieldTextArea}
            minHeight={39}
            label="주소"
            placeholder="클릭해서 주소 입력"
            readOnly
            onFocus={onToggle}
          />
          <Field
            name="addrSub"
            type="text"
            component={RenderField}
            label="상세주소"
            placeholder="나머지 주소 입력"
          />
          <Field
            label="공급자 형태"
            name="providerType"
            component={RenderSelectField}
          >
            <option value="1">개인</option>
            <option value="2">개인사업자</option>
            <option value="3">법인사업자</option>
          </Field>
          {!isPrivate && (
            <Fragment>
              <div>
                <div className="form-group clearfix">
                  <label
                    className="form-label form-picture-label float-left text-left pr-3"
                    htmlFor="profile-image"
                  >
                    사업자등록증
                  </label>
                  <div className="float-left">
                    <PictureInput
                      imageFile={businessLicenseImage}
                      onChange={(imageFile) => {
                        onChangeImage('businessLicenseImage', imageFile);
                      }}
                    />
                  </div>
                </div>
              </div>
              <Field
                name="businessLicense"
                type="text"
                component={RenderField}
                label="사업자등록번호"
                placeholder="사업자등록번호"
              />
            </Fragment>
          )}
          <div>
            <div className="form-group clearfix mb-1">
              <label
                className="form-label form-picture-label float-left text-left pr-3"
                htmlFor="profile-image"
              >
                정산통장사본
              </label>
              <div className="float-left">
                <PictureInput
                  imageFile={passbookImage}
                  onChange={(imageFile) => {
                    onChangeImage('passbookImage', imageFile);
                  }}
                />
              </div>
            </div>
          </div>
          <Field
            name="bank"
            type="text"
            component={RenderField}
            label="정산계좌 은행"
            placeholder="은행명"
          />
          <Field
            name="passbook"
            type="text"
            component={RenderField}
            label="통장계좌번호"
            placeholder="- 없이 숫자만 입력"
            pattern={/[^0-9]/g}
          />
          <Field
            name="freeDeliveryAmount"
            type="number"
            normalize={(value) => {
              const num = parseInt(value);
              return num;
            }}
            component={RenderField}
            label="무료배송 주문액"
            placeholder="금액 미입력시 50,000원 이상 무료배송"
            pattern={/[^0-9]/g}
          />
          {/* <div className="member-edit-optional-form">
            <Field
              name="pwd"
              type="password"
              label="현재 비밀번호"
              component={RenderField}
              placeholder="비밀번호"
              validate={[required, minLength6, maxLength16]}
              isRequired
            />
          </div> */}
          <div className="excavation-form-buttons mt-5 text-center">
            <Button
              buttonText="수정"
              buttonType="submit"
              color="from"
              isDisable={isSubmitLoading}
              isSubmitLoading={isSubmitLoading}
            />
            <Button
              buttonText="취소"
              buttonType="button"
              color="close-from"
              className="mt-3"
              onClick={onClickBack}
            />
          </div>
        </form>
        <AddressModal
          isOpen={isOpenSearchAddress}
          onToggle={onToggle}
          onSelect={onChangeAddress}
        />
      </div>
    </Fragment>
  );
}

export default reduxForm({
  form: 'providerEditForm',
  initialValues: {
    providerType: 1
  }
})(ProviderEditForm);
