import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './LimitCountView.scss';

function LimitCountView({ quantityOnHand, countOfSalesLimit, isShown }) {
  if (!isShown) {
    return null;
  }

  return (
    <div className="limit-count">
      <div
        className={classNames('limit-count-view', {
          out: quantityOnHand <= 0
        })}
      >
        <span className="limit-count-text limit">
          {countOfSalesLimit}개 한정
        </span>
        <span className="limit-count-text count">
          {quantityOnHand > 0 ? (
            <Fragment>
              {`현재 `}
              <span className="number">{quantityOnHand}개</span>
              {` 남았습니다.`}
            </Fragment>
          ) : (
            <Fragment>판매가 완료되었습니다.</Fragment>
          )}
        </span>
      </div>
    </div>
  );
}

LimitCountView.propTypes = {
  quantityOnHand: PropTypes.number,
  countOfSalesLimit: PropTypes.number
};

LimitCountView.defaultProps = {
  quantityOnHand: 0,
  countOfSalesLimit: 0
};
export default LimitCountView;
