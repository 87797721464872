import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { compose } from 'redux';

import GlobalAppCheckModal from 'components/modal/AppCheckModal/GlobalAppCheckModal';

import withUser from 'lib/hoc/withUser';

import MainRoutes from 'routes/MainRoutes';
import { StyledMain } from './MainContainerStyle';

function MainContainer({ showHeader }) {
  return (
    <StyledMain className="container">
      {/* <Switch> */}
      <MainRoutes showHeader={showHeader} />
      {/* </Switch> */}
      <GlobalAppCheckModal />
      {/* <KakaoPlusChat /> */}
    </StyledMain>
  );
}

export default compose(withRouter, withUser)(MainContainer);
// export default App;
