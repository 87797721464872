import styled, { css } from 'styled-components';

export const ProfileWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 420px;
  margin: auto;
  padding: 25px 14px 30px 14px;
  border-bottom: 1px solid var(--border-color, #f5f4f4);

  img {
    max-width: 50px;
    &.santa-hat {
      position: absolute;
      top: -5px;
      left: 45px;
      z-index: 20;
    }
  }

  .profile-picture {
    position: relative;
    margin: 0;
    width: 47px;
    height: 47px;
    border: 3px solid #fff;
    box-shadow: 0 0 0 3px var(--from-color);
    border-radius: 50%;

    &.provider {
      box-shadow: 0 0 0 3px var(--provider-color);
    }

    .profile-picture-image {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      height: 100%;
      width: 100%;
    }
  }

  .profile-name {
    margin-left: 11px;
    font-weight: 800;

    .profile-name-value {
      font-size: min(22px, 4.8vw);
      line-height: 1.2;
      /* overflow: hidden;
      white-space: nowrap;
      word-wrap: normal;
      text-overflow: ellipsis; */
      max-width: 190px;
    }

    .profile-name-label {
      margin-top: 7px;
      /* margin-bottom: 3px; */
      font-size: 12px;
    }
  }

  .profile-right-menu {
    margin-left: auto;

    display: flex;
    justify-content: space-between;
    color: black;
    flex-wrap: nowrap;
    flex-direction: column;
    height: 75px;

    .profile-edit-btn {
      width: 168px;
      border: 1px solid var(--button-border-color);
      border-radius: 2px;
      padding: 3px;
      font-size: 12px;
      text-align: center;
      color: black;

      &.provider {
        width: 80px;
      }
    }

    .profile-savemoney {
      margin-bottom: 5px;
      font-size: 22px;
      font-weight: 800;
      color: var(--from-color);
      text-align: right;

      .profile-link-icon {
        width: 7px;
        height: 14px;
        vertical-align: middle;
        margin-left: 12px;
      }
    }

    .profile-count {
      margin-left: auto;
      font-size: 12px;
      font-weight: bold;
      color: #000000;

      span {
        font-size: 22px;
        font-weight: 800;
        color: var(--provider-color);
      }
    }
  }
`;
