import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { useStore } from 'hooks/useOneStores';

import Loader from 'components/loader/Loader';
import OneStoreCountInfo from 'components/oneStore/OneStoreCountInfo';
import OneStoreProfile from 'components/oneStore/OneStoreProfile';
import OneStoreSettingMenu from 'components/oneStore/OneStoreSettingMenu';
import OneStoreOneProductList from 'components/oneStore/OneStoreOneProductList';

import ToggleList from 'components/commons/ToggleList';

import sentry from 'lib/sentry';
import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import { getStoreActivityCount } from 'api/store';
import './style.scss';
import { MainSectionWrapper } from 'styles/AppStyle';

function useStoreStats(storeId) {
  const [data, setData] = useState({});

  useEffect(() => {
    async function getStoreStats() {
      try {
        const response = await getStoreActivityCount(storeId);

        if (!response || !response.data || !response.data.success) {
          throw new Error(
            `get Store count api Error(OneStore) storeId: ${storeId}`
          );
        }

        setData(response.data.data);
      } catch (e) {
        throw e;
      }
    }
    getStoreStats();
  }, []);

  return data;
}

function OneStoreMain({
  storeId,
  store,
  isLoading,
  user,
  isLoggedIn,
  isProvider
}) {
  const { toCount, fromCount, productCount, discoveryCount } = useStoreStats(
    storeId
  );

  return (
    // <MainSectionWrapper>
    <OneStoreProfile
      storeId={store ? store.id : ''}
      storeName={store ? store.userName : ''}
      storeNo={store ? store.storeNo : ''}
      isSanta={store?.isSanta}
      isLoading={isLoading}
      profileImgPath={store ? store.profileImgPath : {}}
      isMyStore={user.id === storeId}
      balanceSavedMoney={store ? store.balanceSavedMoney : 0}
      isProvider={isProvider}
      user={user}
      isLoggedIn={isLoggedIn}
      bookmarkedIdList={store ? store.bookmarkedIdList : []}
    />
    // </MainSectionWrapper>
  );
}

export default compose(withRouter, withUser, withUi)(OneStoreMain);
