/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent */
import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { getFormatTimeStempDate, diffDate } from 'lib/time';

import sentry from 'lib/sentry';

import { postCancelPayment } from 'api/store';

import Button from 'components/commons/Button';
import AlertModal from 'components/modal/AlertModal';
import ConfirmModal from 'components/modal/ConfirmModal';

import OrderSummaryView from './OrderSummaryView';
import OrderStatusItem from './OrderStatusItem';
import { useDialog } from 'hooks/useModal';

import './OrderStatusView.scss';

// 구매 취소 가능 기간
const PERMIT_CANCEL_DATE = 7;

function useCancelOrder(params) {
  const {
    isOpenDialog: isOpenCancelOrderDialog,
    handleToggleDialog: handleToggleCancelOrderDialog,
    isLoading,
    setIsLoading,
    handleConfirm,
    handleError,
    alertType,
    alertMessage,
    setAlertMessage,
    isOpenAlert,
    handleToggleAlert
  } = useDialog();

  const [isCanceled, setIsCanceled] = useState(false);
  const [postParams, setPostParams] = useState(params);

  async function handleConfirmCancelOrder() {
    // const params = {
    //   paymentUid,
    //   storeId
    // };
    if (isLoading) return;
    try {
      handleConfirm();

      const response = await postCancelPayment(postParams);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`fail post cancel order`);
      }
      setIsLoading(false);
      setIsCanceled(true);
      handleToggleCancelOrderDialog();
      setAlertMessage('취소 내역은 주문 조회에서 가능합니다');
      handleToggleAlert();
    } catch (e) {
      sentry.captureException(e.message);
      setIsLoading(false);
      handleToggleCancelOrderDialog();
      handleError();
      setAlertMessage(
        '취소할 수 없는 주문내역이 있습니다. 취소를 원하시면 프롬고객센터로 연락주세요.'
      );
      handleToggleAlert();
    }
  }

  return {
    isCanceled,
    isOpenCancelOrderDialog,
    handleConfirmCancelOrder,
    handleToggleCancelOrderDialog,
    isLoading,
    alertType,
    alertMessage,
    isOpenAlert,
    handleToggleAlert
  };
}

export default function OrderStatusView({
  storeId,
  orderId,
  orderItem,
  onClickConfirmReceiptButton
}) {
  const history = useHistory();

  // console.log({ orderId, orderItem });

  const {
    isCanceled,
    isOpenCancelOrderDialog,
    handleConfirmCancelOrder,
    handleToggleCancelOrderDialog,
    isLoading,
    alertType,
    alertMessage,
    isOpenAlert,
    handleToggleAlert
  } = useCancelOrder({ orderId, storeId });

  const {
    logDate,
    payType,
    flagCancelPayment,
    totalPayment,
    totalQuantity,
    totalPayable,
    recipient,
    checkedTotal,
    items: deliveryItems
  } = orderItem || {};

  function handleContactCustomerCenter() {
    handleToggleCancelOrderDialog();
    history.push(`/service/contact`);
  }

  // console.log({ orderItem });
  const isGift = payType === 'gift';
  const isPermitCancel = logDate && diffDate(logDate) <= PERMIT_CANCEL_DATE;

  const listHasDeliverers =
    deliveryItems &&
    deliveryItems.some(
      (item) => item.status === 'requested' || item.status === 'delivered'
    );
  // console.log('{listHasDeliverers} :', { listHasDeliverers });
  // console.log({ isLoading });
  return (
    <div className="order-status">
      {/* <div className="order-status-info">
        <ul>
          <li>
            <span className="label">주문일</span>
            <span className="value">
              {getFormatTimeStempDate(logDate, 'YYYY-MM-DD H:mm')}
            </span>
          </li>
          <li>
            <span className="label">주문번호</span>
            <span className="value">{paymentUid}</span>
          </li>
          <li>
            <span className="label">주문유형</span>
            <span className="value">{isGift ? '선물' : '구매'}</span>
          </li>
        </ul>
      </div> */}
      {/* {recipient && (
        <div className="order-status-info">
          <ul>
            <li>
              <span className="label">받는 분</span>
              <span className="value">{recipient.name}</span>
            </li>
            <li>
              <span className="label">연락처</span>
              <span className="value">
                {recipient.mobile.replace(
                  /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                  '$1-$2-$3'
                )}
              </span>
            </li>

            <li>
              <span className="label">우편번호</span>
              <span className="value">{recipient.zipcode}</span>
            </li>
            <li>
              <span className="label">주소</span>
              <span className="value">{recipient.addr}</span>
            </li>
            <li>
              <span className="label">상세주소</span>
              <span className="value">{recipient.addrSub}</span>
            </li>
            {recipient.deliveryMessage && (
              <li>
                <span className="label">배송 메세지</span>
                <span className="value">{recipient.deliveryMessage}</span>
              </li>
            )}
            {recipient.reserveDate && (
              <li>
                <span className="label-bold">예약 발송일</span>
                <span className="value">{recipient.reserveDate}</span>
              </li>
            )}
          </ul>
        </div>
      )} */}
      <div>
        {deliveryItems &&
          deliveryItems.map((deliveryItem, index) => {
            return (
              <div key={deliveryItem.id}>
                <OrderStatusItem
                  index={index}
                  deliveryItem={deliveryItem}
                  history={history}
                />
              </div>
            );
          })}
      </div>
      <OrderSummaryView
        total={checkedTotal}
        totalQuantity={totalQuantity}
        totalPayment={totalPayment}
        totalPayable={totalPayable}
      />

      {isGift && (
        <Button
          buttonText="선물함으로 가기"
          buttonType="link"
          className="order-status-gift-btn"
          to={`/store/${storeId}/setting/giftbox/send`}
        />
      )}
      {!flagCancelPayment && !isCanceled && (
        <div className="mt-3 text-center">
          <Button
            buttonText="주문 취소"
            color="close-dark"
            className="payment-cancel-button"
            onClick={handleToggleCancelOrderDialog}
          />
          <p className="pt-3">
            취소시 이 주문 전체가 취소됩니다. 현재 부분 취소는 지원되지
            않습니다.
          </p>
        </div>
      )}
      {/* {listHasDeliverers && !flagCancelPayment && (
        <div className="mt-3 text-center">
          <Button
            buttonText="이미 배송 중이거나 배송된 상품이 있습니다*"
            color="disable-confirm-button"
          />
          <p className="pt-3">
            * 주문 취소를 원하시면 공급자나 고객센터에 연락해 주세요.
          </p>
        </div>
      )} */}

      {(isCanceled || flagCancelPayment) && (
        <div className="mt-3 text-center">
          <Button
            buttonText="취소된 주문 입니다"
            color="disable-confirm-button"
          />
        </div>
      )}
      {isPermitCancel && !listHasDeliverers ? (
        <Fragment>
          <ConfirmModal
            headerText="주문을 취소하시겠습니까?"
            headerImage="/images/header-image/sori-header-image.svg"
            contentText={`주문 취소 시,\n이 주문 전체가 취소됩니다. \n부분 취소는 프롬고객센터로 연락주세요.`}
            isOpen={isOpenCancelOrderDialog}
            okButtonText="주문 취소"
            okButtonLoading={isLoading}
            onClickOk={handleConfirmCancelOrder}
            onToggle={handleToggleCancelOrderDialog}
          />
          <AlertModal
            type={alertType}
            contentText={alertMessage}
            isOpen={isOpenAlert}
            onClick={handleToggleAlert}
            onToggle={handleToggleAlert}
          />
        </Fragment>
      ) : (
        <Fragment>
          <ConfirmModal
            headerText="주문을 취소할 수 없습니다"
            headerImage="/images/header-image/sori-header-image.svg"
            contentText={`주문 취소를 원하시면,\n공급자나 고객센터에 문의해 주세요.`}
            isOpen={isOpenCancelOrderDialog}
            okButtonText="고객 센터"
            onClickOk={handleContactCustomerCenter}
            onToggle={handleToggleCancelOrderDialog}
          />
        </Fragment>
      )}
    </div>
  );
}
