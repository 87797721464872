import React from 'react';
import classNames from 'classnames';

// redux-form 에 사용되는 input Component
function RenderSelectField({
  children,
  className,
  input,
  label,
  readOnly,
  meta: { error }
}) {
  return (
    <div
      className={classNames(
        'form-group d-flex flex-wrap align-items-top',
        className
      )}
    >
      {label && (
        <span className="form-label d-inline-block text-left pr-1">
          {label}
        </span>
      )}
      <select
        {...input}
        className={`form-control ${label ? 'form-input' : ''} ${
          error ? 'is-invalid' : ''
        }`}
        readOnly={readOnly}
      >
        {children}
      </select>
      {error && (
        <div
          className={`invalid-feedback mt-1 ml-auto ${
            label ? 'invalid-feedback-with-label' : ''
          }`}
        >
          {error}
        </div>
      )}
    </div>
  );
}

export default RenderSelectField;
