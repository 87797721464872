import React from 'react';
import classNames from 'classnames';

import Button from 'components/commons/Button';

import { getImagePath } from 'lib/utils/imageUtils';
import { getPriceFormat } from 'lib/utils/numberUtils';
import { getFormatDate } from 'lib/time';

function ProviderSaleItem2({
  buyerName,
  isDelivery,
  paymentAfterDate,
  paymentUid,
  productName,
  totalQuantity,
  // totalAmount,
  totalProvider,
  flagCancelPayment,
  lineItems,
  officialProductImgPath
}) {
  return (
    <div
      className={classNames('shipping-item-wrapper', {
        'is-cancel-shipping-item': flagCancelPayment
      })}
    >
      <div className="shipping-item-top">
        <div className="shipping-item-date-uid">
          <div className="shipping-item-date">
            {getFormatDate(paymentAfterDate, 'YYYY.MM.DD HH:mm')}
          </div>
          <div className="shipping-item-uid">주문번호</div>
          <span className="shipping-item-uid">{paymentUid}</span>
        </div>
        <div className="shipping-item-button-wrapper">
          {flagCancelPayment && (
            <Button
              className="shipping-item-cancel-order"
              buttonText="주문취소완료"
              buttonType="button"
              color="none"
            />
          )}
        </div>
      </div>
      <div className="shipping-item-content">
        <div className="shipping-item-image">
          <img
            src={
              getImagePath(officialProductImgPath) ||
              '/images/default-image.svg'
            }
            alt="발굴사진"
            className="shipping-item-main-image"
          />
          <img
            className="shipping-item-sub-image"
            src="/images/provider-crown-circle-icon.svg"
            alt="발굴왕관"
          />
        </div>
        <div className="shipping-item-info">
          {lineItems.map((line, index) => {
            return (
              <div key={line.lineNo}>
                <div className="shipping-item-info-bold-text">
                  {`${index + 1} ${productName} ${
                    line.order && line.order.optionName
                      ? `- ${line.order.optionName}`
                      : ''
                  } ${
                    line.order && line.order.quantity
                      ? `X ${line.order.quantity}`
                      : ''
                  }`}
                </div>
              </div>
            );
          })}
          <div className="shipping-item-info-bold-text">
            <span>구매자 : </span>
            {buyerName}
          </div>
          <div className="shipping-item-info-bold-text">
            <span>총수량 : </span>
            {totalQuantity}개
          </div>
          <div className="shipping-item-info-bold-text">
            <span>{`정산금액${isDelivery ? '(배송료 포함) : ' : ' : '}`}</span>
            {getPriceFormat(totalProvider)}원
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProviderSaleItem2;
