import { useQuery, useInfiniteQuery } from 'react-query';

import fetchData from 'api/fetchData';
import { getProviderProduct } from 'api/provider';
import { getCategorizeSearch } from 'api/search';
import { getProduct, getAllProductsList } from 'api/product';

const STALE_TIME = 1000 * 60 * 10;
const ITEMS_PER_PAGE = 20;

const getProductById = async (key, productId) => {
  try {
    const data = await fetchData(getProduct, productId);

    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

const getProducts = async (
  key,
  { searchType, searchKey, itemsPerPage = ITEMS_PER_PAGE },
  LastEvaluatedKey
) => {
  try {
    // console.log({ LastEvaluatedKey });
    const params =
      searchType === 'tag'
        ? [
            'product',
            {
              q: encodeURIComponent(
                // api 전송시 앞뒤 공백제거
                `tags=#${searchKey.trim()}`
              ),
              s: encodeURIComponent(`1pCount=desc`),
              k: LastEvaluatedKey
            }
          ]
        : searchType === 'best' ||
          searchType === 'recipe' ||
          searchType === 'all'
        ? {
            searchType,
            searchKey,
            pageCount: itemsPerPage,
            lastKey: LastEvaluatedKey
          }
        : searchType === 'providerId'
        ? {
            searchType: 'provider',
            [searchType]: searchKey,
            pageCount: itemsPerPage,
            lastKey: LastEvaluatedKey
          }
        : {};

    const func =
      searchType === 'tag'
        ? getCategorizeSearch
        : searchType === 'best' ||
          searchType === 'recipe' ||
          searchType === 'all'
        ? getAllProductsList
        : searchType === 'providerId'
        ? getProviderProduct
        : null;

    const data = await fetchData(func, params);

    // console.log({ data });
    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export function useProduct({ productId }) {
  const { data, isLoading, isError, error } = useQuery(
    ['product', productId],
    getProductById,
    {
      staleTime: STALE_TIME
    }
  );

  return { data, isLoading, isError, error };
}

export function useProducts(searchConditions) {
  const { data, isLoading, isError, error } = useQuery(
    ['products', searchConditions],
    getProducts,
    {
      staleTime: STALE_TIME
    }
  );

  return { data, isLoading, isError, error };
}

export function useInfiniteProducts(searchConditions) {
  const query = useInfiniteQuery(
    ['products-infinite', searchConditions],
    getProducts,
    {
      getFetchMore: (lastPage) => {
        // console.log({ lastPage });
        return lastPage.LastEvaluatedKey;
      },
      staleTime: STALE_TIME
    }
  );
  return query;
}
