import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './OneStoreCountInfo.scss';

function OneStoreCountInfo({
  storeId,
  storeName,
  toCount,
  fromCount,
  // productCount,
  discoveryCount,
  isLoading
}) {
  if (isLoading) {
    return null;
  }

  return (
    <Fragment>
      <div className="onestore-count-info">
        <Link
          to={{
            pathname: `/store/${storeId}/discoversuccess`,
            state: { storename: `${storeName}` }
          }}
          className="onestore-count-2"
        >
          <img src="/images/onestore-crown.svg" className="" alt="1s왕관" />
          {discoveryCount}
        </Link>
        <Link
          to={{
            pathname: `/store/${storeId}/from`,
            state: { storename: `${storeName}` }
          }}
          className="onestore-count-2"
        >
          From {fromCount}
        </Link>
        <Link
          to={{
            pathname: `/store/${storeId}/to`,
            state: { storename: `${storeName}` }
          }}
          className="onestore-count-2"
        >
          To {toCount}
        </Link>
        {/* <Link
          to={{
            pathname: `/store/${storeId}/product`,
            state: { storename: `${storeName}` }
          }}
          className="onestore-count-2"
        >
          Product {productCount}
        </Link> */}
      </div>
    </Fragment>
  );
}

OneStoreCountInfo.propTypes = {
  // storeId: PropTypes.string,
  // storeName: PropTypes.string,
  toCount: PropTypes.number,
  fromCount: PropTypes.number,
  // productCount: PropTypes.number,
  discoveryCount: PropTypes.number
};

OneStoreCountInfo.defaultProps = {
  // storeId: '',
  // storeName: '',
  toCount: 0,
  fromCount: 0,
  // productCount: 0,
  discoveryCount: 0
};

export default OneStoreCountInfo;
