import React from 'react';
import PropTypes from 'prop-types';
// import ModalDialog from 'components/modal/ModalDialog';
// import CDN_URL from 'components/commons/globalUrl';
import classNames from 'classnames';

import CommonModal from 'components/modal/CommonModal';

import './LogoModal.scss';

function LogoModal({
  contentText,
  buttonText,
  secondButtonText,
  isOpen,
  onToggle,
  onClick,
  onClickSecond,
  isShownProcessImg,
  className,
  headerText
}) {
  return (
    <CommonModal
      className={classNames('logo-modal text-center', className)}
      size="sm"
      isOpen={isOpen}
      onToggle={onToggle}
      contentText={contentText}
      okButtonText={buttonText}
      onClickOk={onClick}
      secondButtonText={secondButtonText}
      onClickSecond={onClickSecond}
      headerText={headerText}
      headerImage="/images/header-image/agnes-header-image.svg"
    >
      <div className="mt-4">
        {isShownProcessImg ? ( // 발굴요청 성공시 팝업에서 발굴 과정 이미지 출력
          <img src="/images/discover-status-process.svg" alt="발굴과정" />
        ) : (
            ''
          )}
      </div>
    </CommonModal>
  );
}

LogoModal.propTypes = {
  contentText: PropTypes.string,
  buttonText: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onClick: PropTypes.func
};

LogoModal.defaultProps = {
  contentText: '',
  buttonText: '',
  isOpen: false,
  onToggle: () => { },
  onClick: () => { }
};

export default LogoModal;
