import React, { Component } from 'react';
import classNames from 'classnames';
import mediumZoom from 'medium-zoom';

class DompurifyHTML extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.addZoomImgList();
  }

  addZoomImgList = () => {
    const images = this.el.querySelectorAll('.html-product-info img');
    mediumZoom(images, {
      background: 'rgba(0, 0, 0, 0.78)'
    });
    // eslint-disable-next-line
    // console.log('doooom', images);
  };

  render() {
    // eslint-disable-next-line
    // console.log(this.el);
    const { innerHtml, className } = this.props;

    if (!innerHtml) {
      return null;
    }

    // https://s3.ap-northeast-2.amazonaws.com/from.kr.editor를 cdnpath로 변환

    const cdnPathInnerHtml = innerHtml.replace(
      /https:\/\/s3.ap-northeast-2.amazonaws.com\/from.kr.editor/gi,
      'https://editor.from.kr'
    );

    return (
      <div
        className={classNames('html-product-info', className)}
        // warnning react/no-danger
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: cdnPathInnerHtml }}
        ref={(el) => {
          this.el = el;
        }}
      />
    );
  }
}

export default DompurifyHTML;
