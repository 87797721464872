import React from 'react';
import Slider from 'react-slick';

import { getImagePath } from 'lib/utils/imageUtils';
import CDN_URL from 'components/commons/globalUrl';

import './GuideUser.scss';

const guideUserImgPathList = [];

// 이미지 개수 7개
for (let i = 0; i < 7; i += 1) {
  guideUserImgPathList[i] = `${CDN_URL}/1store-0${i + 1}.png`;
}
function GuideUser({ SETTING, activeSlide }) {
  return (
    <div className="guide-wrapper">
      <Slider className="guide-slide" {...SETTING}>
        {guideUserImgPathList.map((guideImg) => {
          return (
            <div key={getImagePath(guideImg)}>
              <div
                className="guide-slide-item"
                style={{
                  backgroundImage: `url("${getImagePath(guideImg)}")`
                }}
              >
                <div className="slide-index guide-index">
                  {`${activeSlide + 1}/${guideUserImgPathList.length}`}
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default GuideUser;
