import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProviderShippingContainer from 'containers/provider/ProviderShippingContainer';

function ProviderShipping() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ProviderShippingContainer />
    </Fragment>
  );
}

export default ProviderShipping;
