import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import CompanyInfo from './CompanyInfo';

const HIDE_PATH_LIST = [
  '/search',
  '/event/',
  '/gift/d',
  '/service/contact',
  '/faq',
  '/setting',
  '/provider/product/',
  '/market/category',
  '/post/edit',
  '/cart',
  '/checkout',
  '/payment'
];

function CompanyInfoContainer() {
  const history = useHistory();
  const { pathname } = history.location;

  const found = HIDE_PATH_LIST.find((k) => pathname.match(k));

  if (found) {
    return null;
  }
  return <CompanyInfo />;
}

export default CompanyInfoContainer;
