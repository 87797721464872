import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import { findPassword } from 'api/provider';

import sentry from 'lib/sentry';

import AlertModal from 'components/modal/AlertModal';
import LogoModal from 'components/modal/LogoModal';
import ProviderResetPassword from 'components/provider/ProviderResetPassword';

import withUi from 'lib/hoc/withUi';

class ProviderResetPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      isLoading: false,
      isOpenSuccessModal: false,
      isOpenErrorModal: false
    };
  }

  componentDidMount() {
    const { UiAction } = this.props;

    // changeField('id', this.savedId);
    UiAction.setHeader('공급자 비밀번호 찾기');
  }

  handleSubmit = (values) => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        try {
          const response = await findPassword(values);

          if (!response || !response.data) {
            const errorMessage =
              '공급자 정보 조회 중 에러가 발생했습니다.\n잠시 후 다시 시도해주세요.';

            sentry.captureException(errorMessage);

            throw new Error(errorMessage);
          }

          if (!response.data.success) {
            const { errorCode } = response.data;
            let errorMessage = '';

            const notMatchInfo = 1; // 공급자 정보 매칭 오류

            if (errorCode === notMatchInfo) {
              errorMessage = `일치하는 공급자가 없습니다.\n다시 확인 해주세요.`;
            } else if (errorCode >= 10) {
              errorMessage = `공급자 조회 중 에러가 발생했습니다.\n다시 시도해 해주세요.`;
            }
            throw new Error(errorMessage);
          }

          // success
          this.setState({
            isLoading: false,
            isOpenSuccessModal: true
          });
        } catch (err) {
          this.setState({
            errorMessage: err.message,
            isLoading: false,
            isOpenErrorModal: true
          });
        }
      }
    );
  };

  handleOpenModal = (modalType) => {
    this.setState({
      [modalType]: true
    });
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  handleBackSignIn = () => {
    const { history } = this.props;

    history.push('/provider/signin');
  };

  render() {
    const {
      errorMessage,
      isLoading,
      isOpenErrorModal,
      isOpenSuccessModal
    } = this.state;

    return (
      <Fragment>
        <ProviderResetPassword
          isLoading={isLoading}
          onSubmit={this.handleSubmit}
        />
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          isProviderModal
        />
        <LogoModal
          buttonText="로그인 하러 가기"
          headerText="임시 비밀번호 문자 전송"
          contentText={`담당자 휴대폰으로 임시 비밀번호를 보내드렸습니다.\n임시 비밀번호로 로그인하세요.`}
          isOpen={isOpenSuccessModal}
          onClick={this.handleBackSignIn}
          onToggle={this.handleBackSignIn}
        />
      </Fragment>
    );
  }
}

export default withRouter(withUi(ProviderResetPasswordContainer));
