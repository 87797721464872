import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
// import OneProductToListContainer from 'containers/oneProduct/OneProductToListContainer';

function OneStoreToList() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      {/* <OneProductToListContainer /> */}
    </Fragment>
  );
}

export default OneStoreToList;
