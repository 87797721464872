import React, { useState } from 'react';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import ProviderProductList from 'components/provider/ProviderProductList';
import ProviderWatingProductItem from 'components/provider/ProviderProductList/ProviderWatingProductItem';
import sentry from 'lib/sentry';

import { getProviderDiscovers } from 'api/provider';

function ProviderWaitingProductContainer({ providerId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [lastKey, setLastKey] = useState(null);

  const infiniteRef = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMoreItems,
    onLoadMore: getProductList,
    scrollContainer: 'window'
  });

  async function getProductList() {
    const params = {
      lastKey,
      pageCount: 20,
      providerId
    };

    try {
      setIsLoading(true);
      const response = await getProviderDiscovers(params);

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `공급자 상품관리에서 발굴 진행 리스트 가져오기 실패 - ${JSON.stringify(
            response
          )}`
        );
      }

      const { data } = response.data;
      const { items } = data;

      setIsLoading(false);
      setLastKey(data.lastKey);

      const fliterlist = items.filter((item) => {
        return item.nowStatusProcess < 2;
      });

      setProductList([...productList, ...fliterlist]);
      setHasMoreItems(!!data.lastKey);
    } catch (e) {
      // setIsLoading(false);
      sentry.captureException(e.message);
    }
  }

  return (
    <ProviderProductList
      haveList={productList && productList.length > 0}
      infiniteRef={infiniteRef}
      isLoading={isLoading}
    >
      {productList.map((products) => (
        <ProviderWatingProductItem
          key={products.createDate}
          products={products}
        />
      ))}
    </ProviderProductList>
  );
}

export default ProviderWaitingProductContainer;
