import makeStore from 'shared/store';
import {
  isSameItem,
  cleanseItem,
  summarizeItems,
  lineItemize,
  incrementQuantity,
  toggle
} from 'components/checkout/CheckoutStoreUtils';

const initialState = {
  items: [],
  subtotals: [],
  total: { count: 0, quantity: 0, amount: 0 },
  prevItems: [],
  errorMessage: ''
};

const reducer = (state, action) => {
  // console.log('CartStore reducer');
  // console.log({ state });
  // console.log({ action });
  switch (action.type) {
    case 'add': {
      const isInCart = state.items.some((item) =>
        isSameItem(action.payload, item)
      );
      // console.log({isInCart})
      // const { payload } = action;
      // console.log({ payload });
      // const cleansedPayload = cleanseItem(payload);
      // console.log({ cleansedPayload });
      if (isInCart)
        return { ...state, errorMessage: '이미 카트에 담겨있습니다!' };
      const item = lineItemize(action.payload);
      const items = [...state.items, item].sort(
        (a, b) =>
          a.provider.id.localeCompare(b.provider.id) ||
          a.product.id.localeCompare(b.product.id) ||
          a.option.seqNo - b.option.seqNo
      );
      return summarizeItems({
        ...state,
        items
      });
    }
    case 'reset': {
      return summarizeItems({
        ...state,
        items: state.items.filter((item) => !item?.isChecked)
      });
    }

    case 'remove': {
      return summarizeItems({
        ...state,
        items: state.items.filter((item) => !isSameItem(action.payload, item))
      });
    }
    case 'increment': {
      const items = incrementQuantity(
        [...state.items],
        action.payload,
        action.payload.incrementBy
      );
      if (!items)
        return { ...state, errorMessage: '카트에 담기지 않은 상품입니다.' };
      return summarizeItems({
        ...state,
        items
      });
    }
    case 'toggle': {
      const items = toggle([...state.items], action.payload);

      return summarizeItems({
        ...state,
        items
      });
    }

    case 'empty': {
      return summarizeItems({
        ...initialState,
        prevItems: state.items
      });
    }
    case 'restore': {
      return summarizeItems({
        ...state,
        items: state.prevItems
      });
    }
    default:
      return state;
  }
};

const [CartProvider, useCartStore, useCartDispatch] = makeStore(
  reducer,
  initialState,
  'shoppingCart'
);

export { CartProvider, useCartStore, useCartDispatch };
