import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { getSearchProvider } from 'api/provider';

import CommonModal from 'components/modal/CommonModal';

import Button from 'components/commons/Button';
// import ModalDialog from 'components/modal/ModalDialog';

import 'components/modal/SearchProductModal/SearchProductModal.scss';
import './SearchProviderModal.scss';

export default class SearchProviderModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClickNew: PropTypes.func,
    onSelect: PropTypes.func,
    onToggle: PropTypes.func
  };

  static defaultProps = {
    isOpen: false,
    onClickNew: () => {},
    onSelect: () => {},
    onToggle: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      isSearched: false,
      isError: false,
      keyword: '',
      providerList: []
    };

    this.lastKey = 10;
  }

  handleChangeValue = (e) => {
    this.setState({
      keyword: e.target.value
    });
  };

  handleKeyDownValue = (e) => {
    if (!e.keyCode || e.keyCode !== 13) {
      return;
    }

    this.handleSearchProvider(e);

    e.preventDefault();
  };

  handleSearchProvider = async (e) => {
    const { keyword } = this.state;

    if (keyword.length < 2) {
      alert('두글자 이상 검색해주세요');

      this.setState({
        isSearched: false
      });

      return;
    }

    try {
      const response = await getSearchProvider(keyword);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`get provider api fail - ${JSON.stringify(response)}`);
      }

      const { data } = response.data;

      this.setState({
        isSearched: true,
        providerList: data
      });

      e.preventDefault();
    } catch (e) {
      this.setState({
        isSearched: true,
        isError: true
      });
    }
  };

  handleCloseModal = () => {
    const { onToggle } = this.props;

    this.setState({
      isError: false,
      isSearched: false,
      keyword: '',
      providerList: []
    });

    onToggle();
  };

  handleClickNew = () => {
    const { onClickNew } = this.props;
    const { keyword } = this.state;

    onClickNew(keyword);

    this.handleCloseModal();
  };

  handleSelectProvider = (providerId) => {
    const { onSelect } = this.props;

    onSelect(providerId);

    this.handleCloseModal();
  };

  drawDefultMessage = () => {
    return <li className="search-list-text">공급자를 검색해주세요.</li>;
  };

  drawEmptyList = () => {
    return <li className="search-list-text">검색 결과가 없습니다.</li>;
  };

  drawErrorMessage = () => {
    return (
      <li className="search-list-text">
        검색 도중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.
      </li>
    );
  };

  drawProviderItem = (item) => {
    if (!item) {
      return;
    }

    const { id, providerName } = item;
    return (
      <li className="search-list-item provider" key={id}>
        <button
          type="button"
          onClick={() => {
            this.handleSelectProvider(id);
          }}
        >
          <span className="search-list-item-text name">{providerName}</span>
          <span className="search-list-item-text id">{id}</span>
        </button>
      </li>
    );
  };

  drawList = () => {
    const { isError, isSearched, providerList } = this.state;

    if (!isSearched) {
      return this.drawDefultMessage();
    }

    if (isSearched && isError) {
      return this.drawErrorMessage();
    }

    if (isSearched && providerList.length <= 0) {
      return this.drawEmptyList();
    }

    const list = providerList.map((item) => {
      return this.drawProviderItem(item);
    });

    return list;
  };

  render() {
    const { isOpen } = this.props;
    const { isError, isSearched, keyword } = this.state;

    return (
      <CommonModal
        className="modal-control discover-register-modal"
        isOpen={isOpen}
        onToggle={this.handleCloseModal}
        size="md"
        headerText="공급자 검색"
      >
        <div className="form-group mt-4">
          <div className="form-control">
            <input
              className="w-100"
              placeholder="공급자"
              value={keyword}
              onChange={this.handleChangeValue}
              onKeyDown={this.handleKeyDownValue}
            />
            <Button
              buttonText="검색"
              buttonType="button"
              className="form-button btn-primary"
              onClick={this.handleSearchProvider}
            />
          </div>
        </div>
        <ul className="search-list-header provider">
          <li>공급자</li>
          <li>공급자 ID</li>
        </ul>
        <ul className="search-list body">{this.drawList()}</ul>
        <div className="search-list-new">
          {isSearched && !isError && (
            <Fragment>
              <div className="modal-text">
                검색 결과가 없을 경우 “신규등록” 버튼을 눌러주세요
              </div>
              <Button
                buttonText="신규등록"
                buttonType="button"
                className="modal-button modal-new"
                color="primary"
                size="sm"
                onClick={this.handleClickNew}
              />
            </Fragment>
          )}
        </div>
      </CommonModal>
    );
  }
}
