import React, { Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { onClickKakaoLogin } from 'lib/kakao/getKakaoAuth';
import KakaoAuthButton from 'components/users/KakaoAuthButton';
import KakaoLoading from 'components/users/KakaoLoading';

import { getPriceFormat } from 'lib/utils/numberUtils';

import { Input } from 'shared/components';

import { SubFormWrapper, StyledSubTitle } from 'shared/styled/componentStyle';

import { StyledLineItem } from './PaymentStyle';
import { SignInSocialButton, SignInFormLine } from './AuthSubFormStyle';

function AuthSubForm({ isSignedKakao, redirectUrl = '/order/payment' }) {
  const {
    register,
    control,
    errors,
    watch,
    setValue,
    getValues,
    className
  } = useFormContext();

  return (
    <SubFormWrapper>
      <StyledSubTitle>카카오톡 연결 또는 비밀번호 설정</StyledSubTitle>
      <StyledLineItem className="desc">
        *카카오톡과 연결하거나 비밀번호 추가만으로 회원가입까지 한번에!
      </StyledLineItem>
      <StyledLineItem className="desc" />
      <StyledLineItem className="desc" />

      {isSignedKakao ? (
        <Fragment>
          <SignInSocialButton className="kakao-login">
            <img src="/images/kakao-login-btn.svg" alt="카카오톡 로고" />
            카카오톡과 연결됨
          </SignInSocialButton>
          <StyledLineItem className="desc" />
          <StyledLineItem className="desc" />
        </Fragment>
      ) : (
        <Fragment>
          <KakaoAuthButton
            buttonType="button"
            buttonText="카카오톡 계정으로 로그인"
            onClick={() => onClickKakaoLogin(redirectUrl)}
          />
          <SignInFormLine className="payment-signin">
            <span>OR</span>
          </SignInFormLine>
          <Input
            name="pwd"
            type="password"
            label="비밀번호"
            placeholder="비밀번호"
            {...{ register, control, errors }}
          />
        </Fragment>
      )}
    </SubFormWrapper>
  );
}

export default AuthSubForm;
