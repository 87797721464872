import styled, { css } from 'styled-components';

export const ImageInputWithPreviewWrapper = styled.div`
  position: relative;
  width: 100%;
  /* display: flex;
  flex-wrap: wrap;
  margin: 0px 0px;
  justify-content: space-between; */

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(106px, 1fr));
  grid-column-gap: 3px;
  grid-row-gap: 3px;

  button {
    display: block;
    position: relative;
    width: 106px;
    height: 106px;
    margin: 0px;
  }
`;

export const ImagePreviewWrapper = styled.div`
  display: block;
  position: relative;
  margin: 0px;
  width: 106px;
  height: 106px;

  ${(props) =>
    props.isLarge &&
    css`
      width: 100%;
      height: 100%;
    `}

  img {
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 22px;
    height: 22px;
    font-size: 12px;
    font-weight: bold;
    opacity: 0.7;
  }
`;

export const InvisibleImageInput = styled.input`
  display: none;
`;
