/* eslint-disable no-console */
import React from 'react';
import {
  // ItemStatusBox,
  ItemImageBox,
  ItemTitleBox,
  ItemProfileBox,
  ItemTextBox,
  ItemStatsBox
} from 'components/commons/ItemCard/ItemCard';

import { ItemCardWrapper } from 'components/commons/ItemCard/ItemCardStyle';

export default function DiscoverCard({
  className,
  createDate,
  // status,
  discovererId,
  discovererName,
  profileImagePath,
  recommendation,
  productName,
  productImgPathList,
  providerName,
  countOfComment = 0,
  countOfFollow = 0,
  onCardClick
}) {
  const stats = {
    numberOfComments: countOfComment,
    numberOfFollowers: countOfFollow
  };
  const profileLink = discovererId ? `/store/${discovererId}` : '/';

  return (
    <ItemCardWrapper onClick={onCardClick || null}>
      <ItemImageBox className={className} imagePath={productImgPathList[0]} />
      <ItemTitleBox
        className={className}
        title={`[${providerName}] ${productName}`}
        date={createDate}
      />
      <ItemProfileBox
        className={className}
        profileName={discovererName}
        profileImagePath={profileImagePath}
        profileLink={profileLink}
      />
      <ItemTextBox className={className} intro={recommendation} />
      <ItemStatsBox className={className} stats={stats} />
    </ItemCardWrapper>
  );
}
