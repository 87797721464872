/* eslint-disable no-undef */
/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Compressor from 'compressorjs';

import sentry from 'lib/sentry';
import { getCorrectOrientationFile } from 'lib/utils/imageUtils';

import AlertModal from 'components/modal/AlertModal';

import './PictureInput.scss';

const DEFAULT_IMAGE = '/images/default-add-image.png';

class PictureInput extends Component {
  static propTypes = {
    imageFile: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    isCompress: PropTypes.bool,
    isShownImage: PropTypes.bool,
    onChange: PropTypes.func,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    imageFile: null || '',
    isCompress: true,
    isShownImage: true,
    onChange: () => {},
    onSelect: () => {}
  };

  constructor() {
    super();

    this.state = {
      errorMessage: '',
      imageSrc: '',
      isLoading: false,
      isOpenErrorModal: false
    };

    this.countOfImage = 0;
  }

  componentDidMount() {
    this.setDefaultImage();
  }

  // shouldComponentUpdate = (nextProps) => {
  //   const { imageFile } = this.props;
  //   const { imageFile: nextImageFile } = nextProps;
  //   const isChange = this.getCheckedImageName(imageFile, nextImageFile);

  //   // eslint-disable-next-line
  //   console.log(1, isChange);

  //   return isChange;
  // };

  componentDidUpdate(prevProps) {
    const { imageFile } = this.props;
    const { imageFile: prevImageFile } = prevProps;
    const isChange = this.getCheckedImageName(imageFile, prevImageFile);

    if (isChange) {
      this.setDefaultImage();
    }
  }

  getImageFileName = (imageFile) => {
    if (!imageFile) {
      return '';
    }

    if (typeof imageFile === 'string') {
      return imageFile;
    }

    return imageFile.name;
  };

  getCheckedImageName = (firstImage, secondImage) => {
    const hasFirstImage = !!firstImage;
    const hasSecondimage = !!secondImage;
    let firstImageName = '';
    let secondImageName = '';

    if (hasFirstImage) {
      firstImageName = this.getImageFileName(firstImage);
    }

    if (hasSecondimage) {
      secondImageName = this.getImageFileName(secondImage);
    }

    if (firstImageName !== secondImageName) {
      return true;
    }

    return false;
  };

  setImageSrc = (imageSrc) => {
    this.setState({
      imageSrc
    });
  };

  setImageFile = async (imageFile) => {
    const { isCompress, isShownImage } = this.props;
    const { isLoading } = this.state;
    let compressedImage = imageFile;

    if (isLoading) {
      return;
    }

    this.setState({
      isLoading: true
    });

    /**
     * 1. 이미지 압축을 먼저 한다.
     *    만약 isCompress prop가 false값이면 이미지 압축이 필요없다는 뜻이므로 압축하지 않는다.
     * 2. 압축한 이미지의 orientation을 수정한다.
     * 3. 이미지의 width, height를 구해와 부모 컴포넌트에게 전달한다.
     */

    try {
      if (isCompress) {
        compressedImage = await this.compressImage(imageFile);
      }

      const changedImage = await getCorrectOrientationFile(compressedImage);
      const imageObject = await this.readerImage(changedImage);

      this.setState({
        isLoading: false
      });

      if (!isShownImage) {
        this.selectImage(imageObject);

        return;
      }

      this.changeImage(imageObject);
    } catch (e) {
      sentry.captureException(e);
      console.log({ e });
      this.setState({
        errorMessage:
          '사진 첨부 중 오류가 생겼습니다.\n잠시 후 다시 시도해주세요.',
        isLoading: false,
        isOpenErrorModal: true
      });
    }
  };

  setDefaultImage = () => {
    const { imageFile } = this.props;

    if (imageFile === null || imageFile === undefined || imageFile === '') {
      this.setImageSrc(DEFAULT_IMAGE);

      return;
    }

    if (typeof imageFile === 'string') {
      this.setImageSrc(imageFile);

      return;
    }

    this.setImageFile(imageFile);
  };

  /* 이미지 작업 */

  loadImage = (image, src) => {
    const { isShownImage } = this.props;
    const img = new Image();

    img.src = src;

    return new Promise((resolve) => {
      img.onload = () => {
        const imageObject = {
          image,
          width: img.width,
          height: img.height
        };

        if (!isShownImage) {
          resolve(imageObject);
        }

        resolve(imageObject);
      };
    });
  };

  compressImage = (imageFile) => {
    return new Promise((resolve, reject) => {
      new Compressor(imageFile, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          sentry.captureException(err);

          reject(err);
        }
      });
    });
  };

  readerImage = (image) => {
    const { isShownImage } = this.props;

    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onload = async (event) => {
        const imageSrc = event.target.result;
        const imageObject = await this.loadImage(image, imageSrc);

        if (!isShownImage) {
          resolve(imageObject);

          return;
        }

        resolve(imageObject);

        this.setState({ imageSrc });
      };

      reader.readAsDataURL(image);
    });
  };

  changeImage = (imageFile) => {
    const { onChange, index } = this.props;

    // console.log('changeImage');

    if (onChange) {
      // console.log({ imageFile }, { index });
      onChange(imageFile, index);
    }
  };

  selectImage = (imageFile) => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(imageFile, this.countOfImage);
    }
  };

  /* 이벤트 함수  */

  handleCloseModal = () => {
    this.setState({
      isOpenErrorModal: false
    });
  };

  handleClickFileEl = () => {
    // const { fileEl } = this.fileEl;
    // console.log({ fileEl });
    // console.log(this.fileEl);

    try {
      this.fileEl.click();
    } catch (e) {
      sentry.captureException(e);
      console.log({ e });
      this.setState({
        errorMessage:
          '사진 첨부 중 오류가 생겼습니다.\n잠시 후 다시 시도해주세요.',
        isLoading: false,
        isOpenErrorModal: true
      });
    }
  };

  handleChangeImage = () => {
    const imageFilelist =
      this.fileEl && this.fileEl.files.length > 0 ? this.fileEl.files : null;

    if (!imageFilelist) {
      return;
    }

    this.countOfImage = imageFilelist.length;

    try {
      for (let i = 0; i < imageFilelist.length; i++) {
        let imageFile = imageFilelist[i];
        if (!imageFile) {
          break;
        }

        this.setImageFile(imageFile);
      }
    } catch (e) {
      sentry.captureException(JSON.stringify(e));
      console.log({ e });
      this.setState({
        errorMessage:
          '사진 선택 중 오류가 생겼습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true
      });
    }
  };

  render() {
    const { errorMessage, isLoading, isOpenErrorModal, imageSrc } = this.state;
    const {
      className,
      flagMultiple,
      flag1pInput,
      isSlideLoading
      // isSlideLoading(1P에서 이미지 업로드 함수, 1P 이미지 get api 재호출 함수 로딩변수)
    } = this.props;

    const isLoader = flag1pInput ? isLoading || isSlideLoading : isLoading;
    // 1P에서만 isLoading(업로드 할 이미지 압축,로드 로딩) + isSlideLoading(1P 이미지 업로드, 1P 이미지 get api 재호출 로딩)
    // 다른곳은 업로드 할 압축,로드 로딩만
    return (
      <Fragment>
        <div className={classNames('picture-input', className)}>
          <div className="picture-input-cover">
            <input
              autoComplete="nope"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              type="file"
              accept="image/*"
              ref={(fileEl) => {
                this.fileEl = fileEl;
              }}
              onChange={() => this.handleChangeImage()}
              multiple={flagMultiple}
              disabled={isLoader}
            />
            <div
              className="picture-input-image"
              style={{
                backgroundImage: `url("${
                  flag1pInput ? '/images/image-add-btn-op.svg' : imageSrc
                }")` // 1P에서만 더하기 버튼. 다른 곳은 디폴트이미지
              }}
              onClick={this.handleClickFileEl}
            />
            {isLoader && (
              <img
                src="/images/button-loader.svg"
                className="async-validating loading-spinner"
                alt="업로드 중"
              />
            )}
          </div>
          {!flag1pInput && (
            <button type="button" onClick={this.handleClickFileEl}>
              <img
                src="/images/setting-btn-black-2.svg"
                alt="이미지 수정"
                className="picture-input-edit-btn"
              />
            </button>
          )}
        </div>
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          headerText="오류 메시지"
          onToggle={this.handleCloseModal}
        />
      </Fragment>
    );
  }
}

export default PictureInput;
