import React from 'react';
// import CDN_URL from 'components/commons/globalUrl';
// import { Link } from 'react-router-dom';

import './ServiceContactForm.scss';

function ServiceContactForm({ onClickKakaoPlusButton }) {
  return (
    <div className="service-contact-form">
      <div className="service-contact-box">
        <div className="service-contact-big-text">
          프롬의 귀는 항상 활짝 열려 있습니다.
        </div>
        <div className="service-contact-regular-text">
          모든 이용불편 및 취소, 교환, 환불 등의 문의에 대해
          <br /> 카카오톡채널, 이메일을 통해 언제든지 알려주세요.
        </div>
      </div>

      <div className="service-kakao-box">
        <div className="service-kakao-big-text">카톡문의 </div>
        <div className="service-kakao-regular-text">월~금 10시 ~ 18시<br/>
        해당시간 외에는 답변이 지연될 수 있습니다.<br/> 카카오톡채널 ID : 프롬</div>
        <button className="btn" onClick={onClickKakaoPlusButton}>
          <img src="/images/kakao-inquiry-btn.svg" alt="고객센터 로고" />

        </button>
        <div className="service-kakao-light-text">
          성함과 전화번호, 상품 이름을 남겨주시면 더욱 빠른 처리가 가능합니다.
        </div>
      </div>

      <div className="service-contact-list-box">
        <div className="service-contact-list-item">
          <div className="service-contact-list-big-text">이메일 문의</div>
          <a
            href="mailto:help@from.kr"
            className="service-contact-list-link-text"
          >
            help@from.kr
          </a>
          <div className="service-contact-list-small-text">
            24시간 접수 가능합니다
          </div>
        </div>
        {/* <div className="service-contact-list-item">
          <div className="service-contact-list-big-text">전화문의</div>
          <a href="tel:02-6951-1234" className="service-contact-list-link-text">
            02-6951-1234
          </a>
          <div className="service-contact-list-small-text">
            월~금 10시 ~ 18시
          </div>
        </div> */}
      </div>

      <div className="service-time-box">
        <div className="service-time-big-text">고객센터 운영시간 안내</div>
        <div className="service-time-regular-text">
          월~금 10시 ~ 18시 / 점심 12시 ~ 2시
          <br/>
          (토/일,공휴일은 휴무)
        </div>
      </div>
    </div>
  );
}

export default ServiceContactForm;
