// 수정된 PoviderFooter 있음
import React from 'react';

import FooterProfile from './FooterProfile';
import FooterItem from './FooterItem';

import { FooterItemsWrapper } from './FooterStyle';

function ProviderFooterItems({
  providerId,
  isLoggedIn,
  providerImage,
  selectedTab
}) {
  return (
    <FooterItemsWrapper>
      <ul>
        <FooterItem
          footerName="프롬마켓"
          footerImgSrc="footer-from-market-icon-2"
          isActive={selectedTab === 'market'}
          isLoggedIn={isLoggedIn}
          isProtected={false}
          to={{
            pathname: '/market/best',
            state: { isRefresh: true }
          }}
        />
        <FooterItem
          className="provider"
          footerName="상품관리"
          footerImgSrc="footer-product-icon-2"
          isActived={selectedTab === 'product'}
          isLoggedIn={isLoggedIn}
          redirectUrl="/provider/signin"
          to={`/provider/${providerId}/setting/product/success`}
        />
        <FooterProfile
          className="provider"
          footerName="공급자홈"
          isActived={selectedTab === 'provider'}
          isLoggedIn={isLoggedIn}
          isProvider
          profileImgPath={providerImage}
          redirectUrl="/provider/signin"
          to={`/provider/${providerId}`}
        />
        <FooterItem
          className="provider"
          footerName="배송관리"
          footerImgSrc="footer-delivery-icon-2"
          isActived={selectedTab === 'delivery'}
          isLoggedIn={isLoggedIn}
          style={{
            marginTop: '10px',
            width: '44px',
            height: '26px'
          }}
          redirectUrl="/provider/signin"
          to={`/provider/${providerId}/setting/deliveryproducts`}
        />
        <FooterItem
          className="provider"
          footerName="결제내역"
          footerImgSrc="footer-saleslist-icon-2"
          isActived={selectedTab === 'sales'}
          isLoggedIn={isLoggedIn}
          redirectUrl="/provider/signin"
          to={`/provider/${providerId}/setting/saleslist`}
        />
        {/* <FooterItem
            className="provider"
            footerName="설정"
            footerImgSrc="footer-setting-icon-2"
            isActived={selectedTab === 'setting'}
            isLoggedIn={isLoggedIn}
            redirectUrl="/provider/signin"
            to={`/provider/${providerId}/setting`}
          /> */}
      </ul>
    </FooterItemsWrapper>
  );
}

export default ProviderFooterItems;
