import React, { Component } from 'react';

import GuideUser from 'components/guide/GuideUser';

import withUi from 'lib/hoc/withUi';

class GuideUserContainer extends Component {
  constructor() {
    super();

    this.state = {
      activeSlide: 0
    };

    this.SETTING = {
      infinite: true,
      afterChange: (current) => this.setState({ activeSlide: current })
    };
  }

  componentDidMount() {
    const { UiAction } = this.props;

    window.scrollTo(0, 0); // 맨위로 스크롤 이동

    UiAction.setHeader('프롬 소개');
  }

  render() {
    const { activeSlide } = this.state;

    return <GuideUser SETTING={this.SETTING} activeSlide={activeSlide} />;
  }
}

export default withUi(GuideUserContainer);
