import { getHost } from 'lib/utils/envUtils';
import { getMobileAppType } from 'lib/mobileApp';
import { getQuerys } from 'lib/utils/commonUtils';

const IOS_FROM_AGENT = '1store_iphone';
const ANDROID_FROM_AGENT = '1store_android';
export function getKakaoAuth(url, type) {
  const state = {
    url,
    type
  };
  // console.log('getKakaoAuth');
  // console.log({ state });

  return `https://kauth.kakao.com/oauth/authorize?client_id=74c8ac5d959fcd96c7e4e96e79e054ab&redirect_uri=${getHost()}/store/signin&response_type=code&encode_state=true&state=${JSON.stringify(
    state
  )}`;
}

export function handleKakaoLogin(url) {
  // check - this agent : web or app
  const mobileAppType = getMobileAppType();
  // eslint-disable-next-line
  console.log(`current agent type : ${mobileAppType}`);
  if (!mobileAppType) {
    // web
    window.location.href = url;
  } else {
    if (mobileAppType === IOS_FROM_AGENT) {
      try {
        // params = state value
        const querys = getQuerys(url);
        const state = querys.state;
        // eslint-disable-next-line
        webkit.messageHandlers.kakaoLogin.postMessage(state);
      } catch (err) {
        // 최신 앱이 아닌 경우, 기존 웹으로 로그인 연동
        // eslint-disable-next-line
        console.log(`error - ios execute kakaoLogin : ${JSON.stringify(err)}`);
        window.location.href = url;
      }
    } else if (mobileAppType === ANDROID_FROM_AGENT) {
      // @TODO native call
      window.location.href = url;
    }
  }
}

export function onClickKakaoLogin(link = '/order/payment') {
  console.log({ link });
  const kakaoUrl = getKakaoAuth(link, 'kakao');
  console.log({ kakaoUrl });
  // window.location.href = kakaoUrl;
  handleKakaoLogin(kakaoUrl);
}
