import storage from 'lib/storage';

export const KEY_STORAGE_REFERER_INFO = 'referer_Info';

export const setReferrer = (productId, referrerId) => {
  let referrers = storage.get(KEY_STORAGE_REFERER_INFO) || {};

  // console.log({ referrerId });

  if (!referrers[`${productId}`]) {
    referrers[`${productId}`] = referrerId;
    storage.set(KEY_STORAGE_REFERER_INFO, referrers);
  }
};

export const clearReferrers = () => {
  storage.remove(KEY_STORAGE_REFERER_INFO);
};

/**
 * 저장되어 있는 정보 반환
 */
export const loadReferrers = () => {
  const referrers = storage.get(KEY_STORAGE_REFERER_INFO);

  if (!referrers) {
    return null;
  }

  return referrers;
};

export const searchReferrer = (productId) => {
  const referrers = storage.get(KEY_STORAGE_REFERER_INFO);

  if (!referrers) {
    return null;
  }

  return referrers[`${productId}`];
};
