import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import * as experienceApi from 'api/experience';

const GET_RECENT_ONE_EXPERIENCE = 'experience/GET_RECENT_ONE_EXPERIENCE';

export const getRecentExperiences = createAction(
  GET_RECENT_ONE_EXPERIENCE,
  experienceApi.getRecentExperiences
);

const initialState = {
  recentExperiences: null
};

export default handleActions(
  {
    ...pender({
      type: GET_RECENT_ONE_EXPERIENCE,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            recentExperiences: null
          };
        }

        return {
          ...state,
          recentExperiences: data
        };
      },
      onFailure: (state) => {
        return { ...state, recentExperiences: null };
      }
    })
  },
  initialState
);
