import styled, { css } from 'styled-components';

export const StyledMessageItem = styled.div`

  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 16px 24px 16px 24px;
  color: #a4a5a8;
  
  &:last-of-type {
      border-bottom: 0;
  }

  .message-link {
    color: #000;  
  }

  .message-title {
    color: #000;
    font-weight: 800;
    margin-bottom: 8px;
    font-size: 13px;
  }

  .message-body {
    color: #000;
    margin-bottom: 8px;
    line-height: 1.4;

    span {
      word-break: break-word;
      font-size: 13px;
    }
  }

  .message-date {
    font-size: 10px;
  }
`


