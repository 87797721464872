/* eslint-disable react/jsx-indent */
import React, { Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import Button from 'components/commons/Button';
import DiscoverField from 'components/discover/DiscoverField';

import { getFormatDate } from 'lib/time';
import Loader from 'components/commons/Loader';

import './DiscoverStatusForm.scss';

const STATUS_TEXT_LIST = ['발굴대기', '발굴진행', '발굴성공', '발굴실패'];

function DiscoverStatusForm({
  createDate,
  updateDate,
  doneDate,
  nowStatusProcess,
  showMyProductButton,
  isPossibleEdit,
  isLoading,
  onClickBack,
  onOpenProductModal,
  onOpenProviderModal,
  storeId,
  productId,
  productImgPathList,
  onSelectProductImage,
  onSubmit,
  haveProductImages,
  handleSubmit,
  onRemovePicture,
  productImage,
  isIniLoading
}) {
  if (isIniLoading) {
    return <Loader />;
  }
  return (
    <Fragment>
      <div className="discover-all-container">
        <div className="discover-request-header align-item-center">
          <div className="discover-profile">
            <div
              className={`discover-status-history status-${nowStatusProcess}`}
            >
              {STATUS_TEXT_LIST[nowStatusProcess]}
            </div>
            <div
              className="status-area"
              style={{ backgroundImage: 'url("/images/status-bar.svg")' }}
            >
              <div className="WD-33 back-line">
                <img
                  src={`${
                    nowStatusProcess === 0
                      ? '/images/ready-status.svg'
                      : '/images/unselect-status.svg'
                    }`}
                  alt=""
                />
              </div>
              <div className="WD-33 back-line">
                <img
                  src={`${
                    nowStatusProcess === 1
                      ? '/images/ready-status.svg'
                      : '/images/unselect-status.svg'
                    }`}
                  alt=""
                />
              </div>
              <div className="WD-33">
                {nowStatusProcess !== 3 ? (
                  <img
                    src={`${
                      nowStatusProcess === 2
                        ? '/images/success-status.svg'
                        : '/images/unselect-status.svg'
                      }`}
                    alt=""
                  />
                ) : (
                    <img src="/images/fail-status.svg" alt="" />
                  )}
              </div>
            </div>
            <div className="status-date">
              <div className="WD-33">
                <div
                  className={`status-text ${
                    nowStatusProcess === 0 ? 'font-weight-bold' : ''
                    }`}
                >
                  발굴요청
                </div>
                <div className="little-date">
                  {getFormatDate(createDate, 'YYYY.MM.DD HH:mm')}
                </div>
              </div>
              <div className="WD-33">
                <div
                  className={`status-text ${
                    nowStatusProcess === 1 ? 'font-weight-bold' : ''
                    }`}
                >
                  발굴진행
                </div>
                <div className="little-date">
                  {getFormatDate(updateDate, 'YYYY.MM.DD HH:mm')}
                </div>
              </div>
              <div className="WD-33">
                <div
                  className={`status-text ${
                    nowStatusProcess > 1 ? 'font-weight-bold' : ''
                    }`}
                >
                  {nowStatusProcess !== 3 ? '발굴성공' : '발굴실패'}
                </div>
                <div className="little-date">
                  {getFormatDate(doneDate, 'YYYY.MM.DD HH:mm')}
                </div>
              </div>
            </div>
          </div>
          <div className="discover-profile-image-form">
            <img
              className="discover-profile-image"
              src={`${productImage}` || '/images/default-image.svg'}
              alt="상품사진"
            />
          </div>
        </div>
      </div>

      <div className="discover-form pl-3 pr-3">
        <form onSubmit={handleSubmit(onSubmit)} id="discoverForm">
          <DiscoverField
            haveProductImages={haveProductImages}
            onOpenProductModal={onOpenProductModal}
            onOpenProviderModal={onOpenProviderModal}
            onSelectProductImage={onSelectProductImage}
            onRemovePicture={onRemovePicture}
            productImageList={productImgPathList}
            isPossibleEdit={isPossibleEdit}
          />
          {isPossibleEdit && (
            <div className="excavation-form-buttons mt-5 text-center">
              <Button
                buttonText="수정"
                color="from"
                buttonType="submit"
                isDisable={isLoading}
                isLoading={isLoading}
              />
              <Button
                buttonText="취소"
                buttonType="button"
                color="close-from"
                className="mt-3"
                onClick={onClickBack}
              />
            </div>
          )}
          {showMyProductButton && (
            <div className="excavation-form-buttons mt-5 text-center">
              <Link
                to={`/store/${storeId}/${productId}`}
                className="button primary redirect-product-btn from"
              >
                내 상품 확인하기
              </Link>
            </div>
          )}
        </form>
      </div>
    </Fragment>
  );
}

export default reduxForm({
  form: 'discoverStatusForm'
})(DiscoverStatusForm);
