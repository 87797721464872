/* eslint-disable no-console */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { useProduct } from 'hooks/useProducts';
import { useOneProduct } from 'hooks/useOneProducts';

import Loader from 'components/commons/Loader';
import NavBar from 'components/ui/NavBar';
import OneProductMain from './OneProductMain';
import OneProductPosts from './OneProductPosts';
import ProductDetail from './ProductDetail';

import ProductButtonContainer from 'containers/product/ProductButtonContainer';
import OrderButton from 'containers/product/OrderButton';

import withUi from 'lib/hoc/withUi';
import { isDevelop } from 'lib/utils/envUtils';
import { scrollToRef } from 'lib/utils/commonUtils';
import { setReferrer } from 'lib/referrer';

const isDev = isDevelop();

function OneProductContainer({
  storeId,
  productId,
  showHeader,
  UiAction,
  initialOpen
}) {
  const [selectOptionIndex, setSelectOptionIndex] = useState(0);

  // console.log('OneProductContainer');
  // console.log({ storeId });
  // console.log({ productId });
  const location = useLocation();
  const history = useHistory();
  const { data: product, isLoading, isError, error } = useProduct({
    productId
  });

  const {
    data: oneProduct,
    isLoading: isOneProductLoading,
    isError: isOneProductError,
    error: oneProductError
  } = useOneProduct({
    storeId,
    productId
  });

  useEffect(() => {
    if (typeof window !== 'undefined' && !isDev) {
      window.fbq('track', 'ViewContent', {
        content_ids: productId,
        content_type: 'product'
      });
    }
  }, []);

  useEffect(() => {
    // console.log('useEffect for product container fired');
    if (oneProduct) {
      UiAction.setHeader(
        `${oneProduct.storeName}님${
          product && product.discovererId === storeId
            ? '이 발굴했어요'
            : '의 단골상품'
        }`
      );

      const parsed = location ? queryString.parse(location.search) : null;
      // console.log({ parsed });
      const referrerId = parsed && parsed.ref ? parsed.ref : storeId;
      // console.log({ referrerId });
      setReferrer(productId, referrerId);
    }
  }, [oneProduct]);

  const onChangeOptionIndex = (index) => {
    setSelectOptionIndex(index);
  };

  const oneProductNavLinks = [
    { name: '기본정보', path: `/store/${storeId}/${productId}/main` },
    {
      name: '상세정보',
      path: `/store/${storeId}/${productId}/detail`
    },

    {
      name: '단골이야기',
      path: `/store/${storeId}/${productId}/posts`
    }
  ];
  // console.log({ product });
  // console.log({ oneProduct });
  // console.log({ isOneProductError });
  if (isOneProductError) {
    history.replace(`/store/${storeId}`);
  }

  return (
    <Fragment>
      <NavBar
        className="with-border"
        navLinks={oneProductNavLinks}
        title={product?.productName}
      />
      {(isLoading || isOneProductLoading) && <Loader />}

      <Route
        exact
        path="/store/:storeId/:productId"
        render={() => <Redirect to={`/store/${storeId}/${productId}/detail`} />}
      />
      <Route
        exact
        path="/store/:storeId/:productId/main"
        render={() => (
          <OneProductMain
            {...{
              storeId,
              productId,
              product,
              oneProduct,
              selectOptionIndex,
              onChangeOptionIndex
            }}
          />
        )}
      />
      <Route
        exact
        path="/store/:storeId/:productId/detail"
        render={() => <ProductDetail {...{ storeId, productId, product }} />}
      />
      <Route
        exact
        path="/store/:storeId/:productId/posts"
        render={() => (
          <OneProductPosts {...{ storeId, productId, product, oneProduct }} />
        )}
      />
      <OrderButton
        product={product}
        productId={productId}
        optionIndex={selectOptionIndex}
        isOneProduct
        oneProduct={oneProduct}
        storeId={storeId}
        showHeader={showHeader}
        initialOpen={initialOpen}
      />

      {/* <ProductButtonContainer
        product={product}
        productId={productId}
        optionIndex={selectOptionIndex}
        isOneProduct
        oneProduct={oneProduct}
        storeId={storeId}
        showHeader={showHeader}
      /> */}
    </Fragment>
  );
}

export default withUi(OneProductContainer);
