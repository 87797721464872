import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import OrderStatusContainer from 'containers/oneStore/OrderStatusContainer';

function OrderStatus({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <OrderStatusContainer
        storeId={match.params.storeId}
        orderId={match.params.orderId}
      />
    </Fragment>
  );
}

export default OrderStatus;
