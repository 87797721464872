import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';

import Button from 'components/commons/Button';
import ToggleButton from 'components/commons/ToggleButton';
import RenderField from 'components/reduxForm/RenderField';
import CDN_URL from 'components/commons/globalUrl';

import { required } from 'lib/form/validations';

import './SignInForm.scss';

function SignInForm({
  children,
  handleSubmit,
  isCheckedStoreEmail,
  isErrorSignIn,
  isLoading,
  onSignIn,
  onToggleCheckbox
}) {
  return (
    <div className="signin">
      <Link to="/store/signin" className="signin-logo store">
        <img
          src="/images/from-logo-2.svg"
          className="signin-logo-image"
          alt="프롬 로고"
        />
        <h3>소비자 로그인</h3>
      </Link>
      <Link to="/provider/signin" className="signin-logo provider">
        <img
          src="/images/provider-logo.svg"
          className="signin-logo-image"
          alt="1인상점 로고"
        />
        <h3>공급자 로그인</h3>
      </Link>
      <form className="signin-form" onSubmit={handleSubmit(onSignIn)}>
        <Field
          className="signin-form-control"
          name="email"
          type="email"
          component={RenderField}
          placeholder="이메일"
          validate={[required]}
          pattern={/\s/g}
        />
        <Field
          className="signin-form-control"
          name="pwd"
          type="password"
          component={RenderField}
          placeholder="비밀번호"
          validate={[required]}
        />
        {isErrorSignIn && (
          <div className="signin-invalid-feedback">
            *이메일과 패스워드를 다시 확인해주세요.
          </div>
        )}
        {/* <div className="singin-form-checkbox form-group">
          <label className="signin-form-label" htmlFor="storeId">
            <input
              checked={isCheckedStoreEmail}
              type="checkbox"
              id="storeId"
              onChange={onToggleCheckbox}
            />
            아이디 저장
            <span className="checkmark" />
          </label>
        </div> */}
        <ToggleButton
          id="storeId"
          // isLoading={isKakaoButtonLoading}
          checked={isCheckedStoreEmail}
          onClick={onToggleCheckbox}
          color="from"
        >
          아이디 저장
        </ToggleButton>
        <div className="signin-form-buttons">
          <Button
            buttonType="submit"
            buttonText="로그인"
            className="signin-form-button signin-button"
            color="from"
            isDisable={isLoading}
            isLoading={isLoading}
          />
          <Button
            color="no-color"
            buttonText="회원가입"
            buttonType="link"
            to="/store/signup"
            className="signin-form-button"
          />
        </div>
      </form>
      <div className="signin-form-help">
        <Link className="mr-2" to="/store/signin/help/id">
          아이디 찾기
        </Link>
        <Link className="ml-2" to="/store/signin/help/password">
          비밀번호 찾기
        </Link>
      </div>
      <div className="signin-form-social">
        <div className="signin-form-social-label">SNS로 간편 로그인</div>
        {children}
      </div>
    </div>
  );
}

SignInForm.propTypes = {
  // children: PropTypes.node,
  isCheckedStoreEmail: PropTypes.bool,
  isErrorSignIn: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSignIn: PropTypes.func,
  onToggleCheckbox: PropTypes.func
};

SignInForm.defaultProps = {
  // children: null,
  isCheckedStoreEmail: false,
  isErrorSignIn: false,
  isLoading: false,
  onSignIn: () => {},
  onToggleCheckbox: () => {}
};

export default reduxForm({
  form: 'signInForm'
})(SignInForm);
