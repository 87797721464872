import React, { Fragment } from 'react';

import './Bookmark.scss';

function Bookmark({ onClick, children, currentTab }) {
  return (
    <Fragment>
      <div className="bookmark-tab">
        <ul className=" d-flex align-items-center justify-content-center">
          <li className="bookmark-tab-line">
            <button
              type="button"
              onClick={() => {
                onClick('stores');
              }}
            >
              <img
                src={
                  currentTab === 'stores'
                    ? '/images/bookmark/bookmark-1s-on.svg'
                    : '/images/bookmark/bookmark-1s-off.svg'
                }
                alt="북마크 탭"
              />
              소비자
            </button>
          </li>
          {/* <li className="bookmark-tab-line">
            <button
              type="button"
              onClick={() => {
                onClick('oneproducts');
              }}
            >
              <img
                src={
                  currentTab === 'oneproducts'
                    ? '/images/bookmark/bookmark-1p-on.svg'
                    : '/images/bookmark/bookmark-1p-off.svg'
                }
                alt="북마크 탭"
              />
              소개 상품
            </button>
          </li>
          <li className="bookmark-tab-line">
            <button
              type="button"
              onClick={() => {
                onClick('products');
              }}
            >
              <img
                src={
                  currentTab === 'products'
                    ? '/images/bookmark/bookmark-p-on.svg'
                    : '/images/bookmark/bookmark-p-off.svg'
                }
                alt="북마크 탭"
              />
              상품
            </button>
          </li> */}
          <li>
            <button
              type="button"
              onClick={() => {
                onClick('provider');
              }}
            >
              <img
                src={
                  currentTab === 'provider'
                    ? '/images/bookmark/bookmark-pp-on.svg'
                    : '/images/bookmark/bookmark-pp-off.svg'
                }
                alt="북마크 탭"
              />
              공급자
            </button>
          </li>
        </ul>
      </div>
      {children}
    </Fragment>
  );
}

export default Bookmark;
