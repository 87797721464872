import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { findId } from 'api/store';

import sentry from 'lib/sentry';

import AlertModal from 'components/modal/AlertModal';
import LogoModal from 'components/modal/LogoModal';
import SignInHelpId from 'components/users/SignInHelpId';

export default class SignInHelpIdContainer extends Component {
  static propTypes = {
    onGotoSignIn: PropTypes.func
  };

  static defaultProps = {
    onGotoSignIn: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      isLoading: false,
      isOpenSuccessModal: false,
      isOpenErrorModal: false
    };
  }

  handleSubmit = (values) => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        try {
          const response = await findId(values);

          if (!response || !response.data) {
            const errorMessage =
              '사용자 정보 조회 중 에러가 발생했습니다.\n잠시 후 다시 시도해주세요.';

            sentry.captureException(errorMessage);

            throw new Error(errorMessage);
          }

          if (!response.data.success) {
            const notExistReqiredValue = 1; // 필수값 누락
            const apiError = 2; // 사용자 정보 조회 중 에러 발생
            const notExistInfo = 3; // 정보 없음
            const failToSendSMS = 4; // sms 전송 실패

            const { code } = response.data;
            let errorMessage = '';

            if (code === notExistReqiredValue) {
              errorMessage = `필수값이 누락되었습니다.\n다시 확인 해주세요.`;
            } else if (code === apiError) {
              errorMessage = `사용자 정보 조회 중 에러가 발생했습니다.\n잠시 후 다시 시도해주세요.`;

              sentry.captureException(errorMessage);
            } else if (code === notExistInfo) {
              errorMessage = `입력하신 내용과 일치하는 정보가 없습니다.\n다시 확인해 주세요.`;
            } else if (code === failToSendSMS) {
              errorMessage = `sms 전송에 실패하였습니다.\n잠시 후 다시 시도해주세요.`;

              sentry.captureException(errorMessage);
            }

            throw new Error(errorMessage);
          }

          this.setState({
            isLoading: false,
            isOpenSuccessModal: true
          });
        } catch (e) {
          this.setState({
            errorMessage: e.message,
            isLoading: false,
            isOpenErrorModal: true
          });
        }
      }
    );
  };

  handleOpenModal = (modalType) => {
    this.setState({
      [modalType]: true
    });
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  render() {
    const { onGotoSignIn } = this.props;
    const {
      errorMessage,
      isLoading,
      isOpenErrorModal,
      isOpenSuccessModal
    } = this.state;

    return (
      <Fragment>
        <SignInHelpId isLoading={isLoading} onSubmit={this.handleSubmit} />
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
        <LogoModal
          buttonText="로그인 하러 가기"
          headerText="전송 완료"
          contentText={`휴대폰 SMS로 아이디를 보내드렸습니다.${'\n'} SMS를 확인해주세요`}
          isOpen={isOpenSuccessModal}
          onClick={onGotoSignIn}
          onToggle={() => {
            this.handleCloseModal('isOpenSuccessModal');
          }}
        />
      </Fragment>
    );
  }
}
