/* eslint-disable no-console */
import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withUser from 'lib/hoc/withUser';

import { getOneProduct } from 'api/oneProduct';
import { putStoreAdultAuth } from 'api/store';

import sentry from 'lib/sentry';
import { isEmpty } from 'lib/utils/commonUtils';
import { isDevelop, isIPhoneX } from 'lib/utils/envUtils';
import { searchReferrer } from 'lib/referrer';

import useModal from 'hooks/useModal';

import AlertModal from 'components/modal/AlertModal';
import LoginModal from 'components/modal/LoginModal';
import ShareModal from 'components/modal/ShareModal';
import AdultModal from 'components/modal/AdultModal';
import StopSellingModal from 'components/modal/StopSellingModal';

import ProductButtonGroup from 'components/product/ProductButtonGroup';

// import CDN_URL from 'components/commons/globalUrl';
// import execProductInfo from 'lib/static/execProductInfo';

const isDev = isDevelop();
const isUp = isIPhoneX();

function ProductButtonContainer(props) {
  // console.log({ props });

  const {
    storeId,
    productId,
    optionIndex,
    product,
    isLoggedIn,
    user,
    oneProduct,
    isOneProduct,
    isProvider,
    location,
    match,
    history,
    showHeader,
    initialOpen
  } = props || {};

  const {
    title: errorTitle,
    message: errorMessage,
    setTitle: setErrorTitle,
    setMessage: setErrorMessage,
    isOpen: isOpenErrorModal,
    handleToggle: toggleErrorModal
  } = useModal();

  const {
    title: stopSellingModalHeaderText,
    setTitle: setStopSellingModalHeaderText,
    message: stopSellingModalText,
    setMessage: setStopSellingModalText,
    buttonLabel: stopSellingModalButtonText,
    setButtonLabel: setStopSellingModalButtonText,
    isOpen: isOpenStopSellingModal,
    // setIsOpen: setIsOpenStopSellingModal,
    handleToggle: toggleStopSellingModal
  } = useModal();

  const {
    isOpen: isOpenAdultModal,
    handleToggle: toggleAdultModal
  } = useModal();

  const {
    isOpen: isOpenLoginModal,
    handleToggle: toggleLoginModal
  } = useModal();

  const { isOpen: isOpenShareModal, handleToggle: toggleShareModal } = useModal(
    '',
    '',
    initialOpen
  );

  const [paymentType, setPaymentType] = useState('buy');
  const [referrerId, setReferrerId] = useState('');
  const [buttonUrl, setButtonUrl] = useState('');

  useEffect(() => {
    // console.log('useEffect in ProductButtonContaner');
    getReferrerId();
    setClipboardJS();
  }, []);

  const getReferrerId = async () => {
    try {
      // default는 발굴자
      if (product) {
        setReferrerId(product.discovererId);
      }

      // storeId가 있는 경우 (1P에서 구매하는 경우)
      if (storeId) {
        setReferrerId(storeId);
      }

      // 로그인 되어 있을 때
      if (isLoggedIn) {
        const response = await getOneProduct(user.id, productId);

        if (!response || !response.data) {
          throw new Error(
            `상품 페이지- referrer를 위한 1p 데이터 가져오기 오류: ${JSON.stringify(
              response
            )} `
          );
        }

        // 로그인 되어 있고 로그인한 사람의 1p가 존재할 때
        if (response.data.success) {
          setReferrerId(user.id);

          return;
        }
      }

      // 로그인한 사람의 1p가 없거나 비로그인 상태일 경우
      const anotherReferrerId = searchReferrer(productId);
      if (anotherReferrerId) {
        const response = await getOneProduct(anotherReferrerId, productId);

        if (!response || !response.data) {
          throw new Error(
            `상품 페이지- referrer를 위한 1p 데이터 가져오기 오류: ${JSON.stringify(
              response
            )} `
          );
        }

        if (response.data.success) {
          setReferrerId(anotherReferrerId);
        }
      }
    } catch (e) {
      console.log(e);
      sentry.captureException(e.messaage);
      history.goBack();
    }
  };

  const setClipboardJS = () => {
    if (typeof window === 'undefined') {
      return;
    }

    const { ClipboardJS } = window;

    if (!ClipboardJS) {
      return;
    }

    const shareButton = new ClipboardJS('.share-url-button', {
      target: () => document.getElementById('target')
    });

    shareButton.on('success', toggleShareModal);
  };

  const onCheckAdultAuth = async (result) => {
    if (!result || result.success === false) {
      toggleAdultModal();
      setErrorMessage('인증에 실패하였습니다.');
      toggleErrorModal();
      return;
    }

    try {
      const response = await putStoreAdultAuth(user.id);

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `유저 정보 성인 인증 업데이트 실패  - ${JSON.stringify(response)}`
        );
      }

      toggleAdultModal();
      redirectPayment(paymentType);
    } catch (e) {
      console.log({ e });
      sentry.captureException(e.message);
      throw e;
    }
  };

  // 상품정보체크 -> flagStopSelling체크 -> countofSales체크
  const checkAvailablePayment = (paymentType) => {
    // console.log('checking');
    if (!product) {
      return;
    }

    if (isEmpty(product[`1pCount`]) || product[`1pCount`] < 1) {
      setErrorMessage('아직 발굴중인 상품 입니다.\n 조금만 더 기다려 주세요');
      toggleErrorModal();
      return;
    }

    const {
      // countOfSales,
      countOfSalesLimit,
      quantityOnHand,
      descStopSelling,
      descStopAction,
      flagStopSelling,
      titleStopSelling,
      providerId
    } = product;

    // console.log({ flagStopSelling });

    if (countOfSalesLimit && quantityOnHand < 1) {
      setErrorTitle('완판되었습니다.');
      toggleErrorModal();
      return;
    }

    if (flagStopSelling === 1) {
      // console.log({ product });
      setStopSellingModalHeaderText(titleStopSelling);
      setStopSellingModalText(descStopSelling);
      setStopSellingModalButtonText('주문');
      toggleStopSellingModal();
      return;
    }

    if (flagStopSelling === 11) {
      setStopSellingModalHeaderText(titleStopSelling);
      setStopSellingModalText(
        descStopSelling || '공급자의 사정으로 일시 품절된 상품입니다.'
      );
      // console.log({ providerId });
      setButtonUrl(`/provider/${providerId}`);
      setStopSellingModalButtonText('공급자의 다른 상품 구경하기');

      toggleStopSellingModal();
      return;
    }

    if (flagStopSelling === 12) {
      const { buttonUrl } = descStopAction;
      // console.log({ buttonUrl });
      setStopSellingModalHeaderText(titleStopSelling);
      setStopSellingModalText(descStopSelling);
      setButtonUrl(buttonUrl);
      setStopSellingModalButtonText('확인');
      toggleStopSellingModal();

      return;
    }

    checkAdult(paymentType);
  };

  const checkAdult = (paymentType) => {
    if (!product) {
      return;
    }

    const { flagAdultAuth } = product;

    // 성인인증을 받아야하는 상품이 아니면 바로 구매 페이지로 이동
    if (!flagAdultAuth) {
      // console.log({ paymentType });
      redirectPayment(paymentType);

      return;
    }

    /* 여기서 부터는 성인인증이 필요한 상품 처리 */

    // 로그인 안했으면 로그인 팝업 출력
    if (!isLoggedIn) {
      toggleLoginModal();

      return;
    }

    // 로그인은 되어 있는데 유저 정보가 없으면 오류 처리
    if (!user) {
      setErrorMessage('유저 정보가 없습니다.\n잠시 후 다시 시도해주세요.');
      toggleErrorModal();
      return;
    }

    // 로그인이 되어 있고 성인인증을 받은 유저라면 구매(선물) 페이지로 이동
    const { flagAdultAuth: userAdultAuth } = user;

    if (userAdultAuth === 1) {
      redirectPayment(paymentType);

      return;
    }

    // 로그인이 되어있고 성인인증을 받지 않은 유저라면 성인인증 모달 출력
    toggleAdultModal();
  };

  const redirectPayment = (paymentType) => {
    let pathname = `/payment/${referrerId}/${productId}/buy/message`;

    if (paymentType === 'gift') {
      pathname = `/payment/${referrerId}/${productId}/gift/message`;
    }

    history.push({
      pathname,
      state: { prevPath: location.pathname, paymentType },
      search: optionIndex > -1 ? `?option=${optionIndex}` : ''
    });
  };

  const onClickOrderButton = (payType) => {
    // console.log('Buy button clicked');
    setPaymentType(payType);

    if (typeof window !== 'undefined' && !isDev) {
      window.fbq('track', 'InitiateCheckout');
    }

    checkAvailablePayment(payType);
  };

  const onClickSubscribeButton = () => {
    setErrorTitle('안내');
    setErrorMessage('서비스 준비중입니다.');
    toggleErrorModal();
  };
  // const onClickGiftButton = () => {
  //   // console.log('Gift button clicked');

  //   setPaymentType('gift');

  //   if (typeof window !== 'undefined' && !isDev) {
  //     window.fbq('track', 'InitiateCheckout');
  //   }

  //   checkAvailablePayment('gift');
  // };

  const redirectToButtonUrl = () => {
    history.push(`${buttonUrl}`);

    toggleStopSellingModal();
  };

  const onClickStopSellingModalButton = () => {
    const { flagStopSelling } = product;

    if (flagStopSelling === 1) {
      toggleStopSellingModal();
      checkAdult(paymentType);
    }

    if (flagStopSelling === 11 || flagStopSelling === 12) {
      redirectToButtonUrl();
    }
  };

  const { isCarrige } = product || {};
  const isRecipe = isCarrige === true;
  // console.log({ isCarrige });

  const type = isOneProduct ? 'oneProduct' : 'product';
  const metaData = {
    type,
    product,
    oneProduct,
    path: match.url,
    referrer: user.id
  };

  // console.log({ referrerId });

  return (
    <Fragment>
      <ProductButtonGroup
        isVisible
        isProvider={isProvider}
        isUp={isUp}
        showHeader={showHeader}
        isRecipe={isRecipe}
        isOneProduct={isOneProduct}
        onClickOrderButton={onClickOrderButton}
        onClickSubscribeButton={onClickSubscribeButton}
        onClickShareButton={toggleShareModal}
      />
      <AlertModal
        isOpen={isOpenErrorModal}
        headerText={errorTitle}
        contentText={errorMessage}
        onClick={toggleErrorModal}
        onToggle={toggleErrorModal}
      />
      <StopSellingModal
        isOpen={isOpenStopSellingModal}
        contentText={stopSellingModalText}
        headerText={stopSellingModalHeaderText || '안내'}
        headerImage={product ? product.providerProfileImgPath : {}}
        buttonText={stopSellingModalButtonText}
        onClick={onClickStopSellingModalButton}
        onToggle={toggleStopSellingModal}
      />
      <AdultModal
        isOpen={isOpenAdultModal}
        onCheck={onCheckAdultAuth}
        onToggle={toggleAdultModal}
      />
      <LoginModal
        headerImage="/images/header-image/adult-header-image.svg"
        className="adult-modal"
        headerText="성인 인증"
        contentText={'이 상품은 \n성인 인증이 필요합니다.'}
        isOpen={isOpenLoginModal}
        redirectUrl={location.pathname}
        onToggle={toggleLoginModal}
      />
      <ShareModal
        isOpen={isOpenShareModal}
        onToggle={toggleShareModal}
        metaData={metaData}
      />
    </Fragment>
  );
}
const mapStateToProps = ({ ui }) => {
  return {
    showHeader: ui.showHeader
  };
};
export default compose(
  withRouter,
  withUser,
  connect(mapStateToProps)
)(ProductButtonContainer);
