import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProviderContainer from 'containers/provider/ProviderContainer';

function Provider({ match }) {
  const providerId = match.params.providerId;
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ProviderContainer providerId={providerId} />
    </Fragment>
  );
}

export default Provider;
