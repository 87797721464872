/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import ProductPicture from 'components/picture/ProductPicture';
import Button from 'components/commons/Button';

import { getFormatTimeStempDate } from 'lib/time';
import { getImagePath } from 'lib/utils/imageUtils';
import { getPriceFormat } from 'lib/utils/numberUtils';

import { StyledOrderItem } from './OrderItemStyle';

function OrderItem({ orderItem, storeId }) {
  // // logDate -> 2019-5-10 17:47:32
  // const flagRequireConfirm = payType === 'buy' && logDate > 1557478052709;

  const {
    id,
    payType,
    orderType,
    paymentUid,
    logDate,
    // seller,
    // product,
    // provider,
    lineItems,
    items,
    flagCancelPayment,
    totalQuantity,
    totalAmount
  } = orderItem || {};

  // eslint-disable-next-line no-console
  // console.log({ orderItem });
  // const { name: providerName, providerProfileImgPath } = provider || {};
  // const { name: productName } = product || {};
  const isNewOrderType = orderItem?.receiver ? true : false;
  const isGift = payType === 'gift' || orderType === 'gift' ? true : false;
  const statusLabel = isGift ? '선물' : '구매';
  const orderLineItems = lineItems || items;

  const imgPath =
    orderItem?.receiver?.profileImgPath ||
    (orderLineItems && orderLineItems[0].product
      ? orderLineItems[0].product?.officialProductImgPath
      : orderItem?.product?.officialProductImgPath);

  // eslint-disable-next-line no-console
  // console.log({ orderLineItems });

  return (
    <StyledOrderItem
      className={classNames('order-item', {
        'order-item-canceled': flagCancelPayment
      })}
    >
      <div className="order-item-header">
        <Link to={`/store/${storeId}/setting/order/${id}`}>
          <div className="main">
            <span>{`${statusLabel} `}</span>
            <span>{getFormatTimeStempDate(logDate, 'YYYY-MM-DD H:mm')}</span>
          </div>
          <div className="sub">
            <span>주문번호</span>
            <br />
            <span>{id}</span>
          </div>
        </Link>
      </div>
      <div className="order-item-link">
        <Link to={`/store/${storeId}/setting/order/${id}`}>
          {flagCancelPayment ? (
            <Button
              className="canceled-button"
              buttonText="주문취소완료"
              buttonType="button"
              color="none"
            />
          ) : (
            <Button buttonText="결과보기" buttonType="button" />

            // <div>
            //   <span>배송상세</span>
            //   <img
            //     src="/images/onestore-setting-link-icon.svg"
            //     alt="화살표"
            //     className="onestore-setting-link-icon"
            //   />
            // </div>
          )}
        </Link>
      </div>

      <div className="order-item-image">
        <Link
          to={
            isNewOrderType
              ? `/store/${orderItem.receiver.id}`
              : `/store/${storeId}/${
                  orderLineItems && orderLineItems[0].product
                    ? orderLineItems[0].product?.id
                    : orderItem?.product?.id
                }`
          }
        >
          <img
            className={`main-image ${isNewOrderType ? 'receiver-image' : ''}`}
            src={
              `${getImagePath(imgPath, 250, 250)}` ||
              '/images/default-profile.svg'
            }
            alt="받는분"
          />
          {isGift ? (
            <img
              className="sub-image"
              src="/images/gift-circle-icon.svg"
              alt="선물"
            />
          ) : !isNewOrderType ? (
            <img
              className="sub-image"
              src="/images/onestore-crown-circle-icon.svg"
              alt="응원자왕관"
            />
          ) : null}
          {isNewOrderType && <p>{orderItem.receiver.name}</p>}
        </Link>
      </div>
      {orderLineItems && (
        <ul
          className={classNames('order-line-items', {
            canceled: flagCancelPayment
          })}
        >
          {orderLineItems.map((item, i) => {
            const { order, product, receiver } = item || {};
            const { optionId, optionName, quantity } = order || {};
            const productId = product ? product.id : orderItem.product.id;

            const productName = product
              ? product.name || product.productName
              : orderItem?.product?.name;
            // const productImgPath = product
            //   ? product.officialProductImgPath
            //   : orderItem.product.officialProductImgPath;

            const receiverId = receiver ? receiver.id : orderItem.receiverId;
            const receiverName = receiver
              ? receiver.name
              : orderItem?.receiver?.name;
            // const receiverImgPath = receiver
            //   ? receiver.profileImgPath
            //   : orderItem.buyer.profileImgPath;

            // const productPath = `/product/${productId}`;
            // const receiverPath = `/store/${receiverId}`;

            return (
              <li
                className="line-item-container"
                // eslint-disable-next-line react/no-array-index-key
                key={`${i}-${optionId}`}
              >
                {isGift ? (
                  <div className="line-item">
                    <span className="value">
                      {`${i + 1}. ${
                        isNewOrderType ? '' : receiverName
                      } ${productName} - ${optionName || item.option.name}`}
                    </span>
                    <span className="label">{` / ${
                      quantity || item.quantity
                    }개 주문`}</span>
                  </div>
                ) : (
                  <div className="line-item">
                    <span className="value">
                      {`${i + 1}. ${productName} - ${
                        optionName || item.option.name
                      }`}
                    </span>
                    <span className="label">{` / ${
                      quantity || item.quantity
                    }개 주문`}</span>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      )}
      <div className="order-item-footer">
        <div>
          <span className="label">총수량 :</span>
          <span className="value">{` ${
            totalQuantity || orderItem?.checkedTotal?.quantity
          }개`}</span>
        </div>
        <div>
          <span className="label">주문금액 (배송비 포함) :</span>
          <span className="value">
            {` ${getPriceFormat(
              totalAmount || orderItem?.checkedTotal?.totalAmount
            )}원`}
          </span>
        </div>
      </div>
    </StyledOrderItem>
  );
}

export default OrderItem;
