import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import GiftBoxContainer from 'containers/setting/GiftBoxContainer';

function GiftBox({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <GiftBoxContainer storeId={match.params.storeId} />
    </Fragment>
  );
}

export default GiftBox;
