/* eslint-disable no-console */
import React, { Fragment } from 'react';
import { useQuery } from 'react-query';

import Loader from 'components/commons/Loader';

import { getSearchTags } from 'api/search';
import MarketCategoryView from 'components/home/MarketCategoryView';
import { MainSectionWrapper } from 'styles/AppStyle';

const getTags = async (key) => {
  try {
    const response = await getSearchTags();
    // console.log({ response });

    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

function MarketCategoryContainer() {
  const { data, error, isLoading, isError } = useQuery('tags', getTags, {
    staleTime: 1000 * 60 * 5,
    retry: false
  });

  const { categories = [], recommendations = [], brands = [] } = data || {};

  return (
    <MainSectionWrapper className="market">
      {isLoading && <Loader />}
      {isError && error}
      <MarketCategoryView
        categoryList={categories}
        recommendationList={recommendations}
        brandList={brands}
        // onClickTag={onClickTag}
      />
    </MainSectionWrapper>
  );
}

export default MarketCategoryContainer;
