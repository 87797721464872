import * as yup from 'yup';
import debounce from 'lodash/debounce';
import { formatMobileNumber } from 'lib/utils/utils';
import { checkDuplicateMobile, checkDuplicateEmail } from 'api/store';

const requiredMessage = '필수항목';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const nameRequired = yup.string().trim().required(requiredMessage);

export const stringRequired = yup.string().required(requiredMessage);

export const quantityRequired = yup
  .number()
  .required(requiredMessage)
  .typeError('숫자가 아닙니다')
  .integer('정수가 아닙니다')
  .moreThan(-1, '음수입니다');

export const mobileRequired = yup
  .string()
  .matches(
    /.*(82|0)(1[01689])([ -.]?)(\d+)([ -.]?)(\d{4})/,
    '휴대폰번호가 아닙니다'
  )
  .trim()
  .required(requiredMessage);

export const mobileNewRequired = yup
  .string()
  .required(requiredMessage)
  .trim()
  .test(
    'checkDuplicateMobile',
    '휴대폰 번호가 존재합니다. 로그인 해주세요.',

    debounce(async (value) => {
      // console.log('checking mobile');
      // console.log({ value });
      if (
        !value ||
        !value.match(/.*(82|0)(1[01689])([ -.]?)(\d+)([ -.]?)(\d{4})/)
      )
        return true;
      const response = await checkDuplicateMobile(formatMobileNumber(value));
      // console.log({ response });

      return response?.data?.success !== true;
    })
  )
  .matches(
    /.*(82|0)(1[01689])([ -.]?)(\d+)([ -.]?)(\d{4})/,
    '휴대폰번호가 아닙니다'
  );

export const emailRequired = yup
  .string()
  .required(requiredMessage)
  .email('이메일 형식이 아닙니다');

export const emailNewRequired = yup
  .string()
  .required(requiredMessage)
  .test(
    'checkDuplicateEmail',
    '이메일이 존재합니다. 로그인 해주세요.',
    debounce(async (value) => {
      // console.log('checking email');
      if (
        !value

        // ||
        // !value.match(
        //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        // )
      )
        return true;
      // console.log({ value });
      const response = await checkDuplicateEmail(value);
      // console.log({ response });

      return response?.data?.success === true;
    })
  )
  .email('이메일 형식이 아닙니다');

export const passwordRequired = yup
  .string()
  .required('필수항목')
  .min(5, '6자 이상');

export const dateRequired = yup.date().required(requiredMessage).nullable();
