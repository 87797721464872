// 원하는 인센티브와 계산한 값 구하기
// 가격
export function getPrice(price, percent, priceUnit = 10) {
  if (!price || Number.isNaN(price)) {
    return 0;
  }

  if (!percent || Number.isNaN(percent) || parseInt(percent, 10) > 100) {
    return 0;
  }
  // console.log({ percent });
  const newPrice = (price * percent) / 100;
  // console.log({ newPrice });

  return Math.floor(newPrice / priceUnit) * priceUnit;
}

// 퍼센트
export function getPercentile(rate) {
  if (!rate || Number.isNaN(rate)) {
    return 0;
  }

  const percentile = rate < 1 ? 100 * rate : rate;

  return Math.floor(percentile);
}

export function getPriceStructure({
  option,
  shareRate = { seller: 0.5, buyer: 0.5 },
  isTaxFree = false,
  buyerIsSeller = false,
  isOneProduct = false
}) {
  const { price, rate, delivery = {} } = option;
  // console.log({ option });
  // console.log({ rate });
  // console.log({ shareRate });
  // console.log({ isTaxFree });
  // console.log({ buyerIsSeller });
  const { discount, seller, provider } = rate || {};
  const rateMultiplier = provider > 1 ? 1 : 100;
  // const discountPrice = isOneProduct
  //   ? getPrice(price, 100 - discount * rateMultiplier)
  //   : price;
  const discountPrice = getPrice(price, 100 - discount * rateMultiplier);

  const toProvider = getPrice(price, provider * rateMultiplier);
  const toSeller = getPrice(price, seller * rateMultiplier * shareRate.seller);
  const toBuyer = getPrice(price, seller * rateMultiplier * shareRate.buyer);
  // console.log({ toBuyer });
  const toCnx = discountPrice - toProvider - toSeller - toBuyer;
  const giftPoint = buyerIsSeller ? toSeller + toBuyer : toBuyer;

  const unitPayable = {
    toProvider,
    toSeller: buyerIsSeller ? 0 : toSeller,
    toBuyer: giftPoint,
    toCnx
  };
  return {
    unitPrice: price,
    // discountPercent: getPercentile(isOneProduct ? discount : 0),
    discountPercent: getPercentile(discount),
    discountPrice,
    giftPoint,
    deliveryFee: delivery.fee ? delivery.fee : 0,
    taxFreeAmount: isTaxFree ? discountPrice : 0,
    unitPayable
  };
}
