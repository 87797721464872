import styled, { css } from 'styled-components';

export const SignInFormLine = styled.div`
  width: 100%;
  height: 1px;
  position: relative;
  margin: 30px 0;
  /* background-color: #cecece; */
  line-height: 1px;

  span {
    display: inline-block;
    width: 50px;
    height: 5px;
    margin-left: -25px;
    position: absolute;
    background-color: #f6f6f6;
    color: #cecece;
    text-align: center;
    top: 0;
    left: 50%;
  }

  &.payment-signin {
    span {
      background-color: white;
    }
  }
`;

export const SignInSocialButton = styled.div`
  width: 100%;
  height: 39px;
  font-size: $font-15;
  font-weight: bold;
  line-height: 39px;
  text-align: initial;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    margin-left: 10px;
  }

  &.kakao-login {
    display: block;
    background-color: #ffe609;
    color: #392120;
    text-align: center;
    border-radius: 3px;

    img {
      margin-right: 8px;
    }
  }
`;

// .signin {
//   display: flex;
//   max-width: $max-container-width;
//   width: 100%;
//   margin: 30px auto 16px;
//   padding: 0 24px;
//   flex-wrap: wrap;
//   text-align: center;

//   .form-group {
//     margin-bottom: 8px;
//     text-align: left;
//   }

//   .form-control {
//     border-color: $brand-color;
//   }

//   .signin-invalid-feedback {
//     display: block;
//     width: 100%;
//     margin-bottom: 8px;
//     margin-top: 0.25rem;
//     font-size: 80%;
//     color: $danger-color;
//     text-align: left;
//     font-weight: bold;
//   }
// }

// .signin-logo {
//   width: 50%;
//   margin-bottom: 45px;
//   text-align: center;

//   h3 {
//     font-weight: 800;
//     color: black;
//     font-size: $font-15;
//   }

//   &.provider {
//     opacity: 0.25;

//     &:active {
//       opacity: 1;
//     }
//   }
// }

// .signin-logo-image {
//   margin-bottom: 10px;

//   .store & {
//     width: 73px;
//     height: 73px;
//   }

//   .provider & {
//     width: 73px;
//     height: 73px;
//   }
// }

// .signin-form {
//   width: 100%;

//   .signin-form-control {
//     input {
//       border: initial;
//       border-radius: initial;
//       border-bottom: 1px solid #ebebeb;
//       height: 39px;
//       background-image: none;
//       font-weight: bold;
//       font-size: 16px;

//       &:focus,
//       &.is-invaild:focus {
//         box-shadow: initial;
//         border-color: #ebebeb;
//       }
//     }
//   }

//   .toggle-button-box {
//     text-align: left;
//     margin-top: 28px;
//     margin-bottom: 8px;

//     label.toggle-button {
//       width: initial;
//       padding-left: 28px;
//       line-height: 15px;
//       font-weight: bold;
//       font-size: 11px;
//       color: #808080;

//       .slider {
//         width: 25px;
//         height: 15px;

//         &:before {
//           width: 15px;
//           height: 15px;
//         }
//       }

//       input:checked + .slider:before {
//         -webkit-transform: translateX(13px);
//         -ms-transform: translateX(13px);
//         transform: translateX(13px);
//       }
//     }
//   }
// }

// .signin-form-buttons {
//   width: 100%;

//   .signin-form-button {
//     width: 100%;
//     height: 39px;
//     border-radius: 3px;

//     &.signin-button {
//       margin-bottom: 15px;
//       font-weight: bold;
//       font-size: 15px;
//     }
//   }

//   a {
//     border: 0.5px solid #dedede;
//     color: $from-color;
//     font-weight: bold;
//     font-size: 15px;
//     line-height: 1.7;
//   }
// }

// .signin-form-help {
//   display: flex;
//   width: 80%;
//   margin: 24px auto 0;
//   color: $gray-800;
//   font-size: $font-16;
//   text-align: center;
//   justify-content: center;

//   a {
//     color: black;
//     font-weight: bold;
//     font-size: 12px;
//   }

//   span {
//     flex-basis: 20px;
//   }
// }

// /* 체크박스 */
// .signin-form-label {
//   height: 30px;
//   line-height: 30px;
//   padding-left: 26px !important;
//   position: relative;
//   cursor: pointer;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;

//   .checkmark {
//     display: block;
//     position: absolute;
//     left: 0;
//     top: 50%;
//     width: 20px;
//     height: 21px;
//     background-color: $white-color;
//     border: 1px solid $brand-color;
//     box-sizing: border-box;
//     transform: translateY(-50%);

//     &.provider {
//       border: 1px solid $provider-color;
//     }
//   }

//   input {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 0;
//     width: 0;
//   }

//   input[type='checkbox']:checked + .checkmark {
//     background-image: url('/images/checkbox-checked.svg');
//     background-position: center center;
//     background-repeat: no-repeat;
//     background-size: 13px 14px;
//   }

//   input[type='checkbox']:checked + .provider {
//     background-image: url('/images/provider-checkbox-checked.svg');
//   }
// }

// /* 소셜미디어 로그인 */
// .signin-form-social {
//   width: 100%;
//   text-align: center;
//   margin-top: 41px;

//   .signin-form-social-label {
//     text-align: left;
//     font-weight: bold;
//     font-size: 12px;
//     margin-bottom: 8px;
//   }
// }

// .signin-social {
//   padding: 5px;
//   width: 100%;
//   text-align: center;
// }

// .signin-social-button {
//   width: 100%;
//   height: 39px;
//   font-size: $font-15;
//   font-weight: bold;
//   line-height: 39px;
//   text-align: initial;
//   position: relative;

//   img {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 0;
//     margin-left: 10px;
//   }
// }

// .signin-social-label {
//   display: inline-block;
//   margin-top: 5px;
//   font-size: $font-12;
//   line-height: 1.5;
//   color: #959797;
// }

// .signin-form-provider {
//   width: 100%;
//   margin-top: 16px;
//   color: #ffffff;
//   background-color: #a6a7aa;
//   border-color: #a6a7aa;
// }
