import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

function RedirectToHome({
  isGotoHome,
  redirectPath,
  isProvider
  // 유저인지 공급자인지 판단
}) {
  if (!isGotoHome) {
    return null;
  }

  if (redirectPath) {
    return (
      <Redirect
        to={{
          pathname: !isProvider ? '/store/signin' : '/provider/signin',
          state: {
            redirectUrl: redirectPath || '/'
          }
        }}
      />
    );
  }

  return <Redirect to="/" />;
}

RedirectToHome.propTypes = {
  isGotoHome: PropTypes.bool,
  isProvider: PropTypes.bool
};

RedirectToHome.defaultProps = {
  isGotoHome: false,
  isProvider: false
};

export default RedirectToHome;
