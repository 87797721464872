import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import SignUpContainer from 'containers/users/SignUpContainer';

function SignUp() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <SignUpContainer />
    </Fragment>
  );
}

export default SignUp;
