import React from 'react';

const RenderSelect = ({
    input,
    readOnly,
    label,
    meta: {touched, error},
    children,
    className
}) => (
  <div className={`form-group ${className}`}>
    {label && (
      <span className="d-inline-block text-left mb-1 pr-2 ">
        {label}
      </span>
    )}
    <div className="control">
      <div className={
        /* eslint-disable */
        `select ${touched ? (error ? 'is-danger' : 'is-success') : ''}`}
      >
        <select className={`form-control ${touched ? (error  ? 'is-invalid' : '') : ''}`} disabled={readOnly === 'readonly' ? true : false} {...input} id="fieldSelect">
          {children}
        </select>
        {touched && (error && <div className="text-danger mt-1 ml-auto" style={{fontSize : '12.8px'}}>{error}</div>)}
      </div>
    </div>
  </div>
    );

export default RenderSelect;