import CDN_URL from 'components/commons/globalUrl';
/**
 *
 */
export default ({
  buttonText,
  description,
  imageUrl,
  link,
  title,
  imageWidth = 375,
  imageHeight = 375
}) => {
  if (typeof window === 'undefined' || typeof window.Kakao === 'undefined') {
    return;
  }

  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent === 'undefined'
  ) {
    return;
  }

  const kakaoLinkObj = {
    objectType: 'feed',
    content: {
      title: title || '프롬',
      description: description || '프롬',
      imageUrl: imageUrl || `${CDN_URL}/logo.png`,
      imageWidth,
      imageHeight,
      link: {
        mobileWebUrl: link,
        webUrl: link
      }
    },
    buttons: [
      {
        title: buttonText || '프롬 바로가기',
        link: {
          mobileWebUrl: link,
          webUrl: link
        }
      }
    ]
  };

  const userAgent = navigator.userAgent.toLowerCase();

  // iOS-App
  if (userAgent.indexOf('1store_iphone') > -1) {
    kakaoLinkObj.snsType = 'kakao';

    // eslint-disable-next-line
    webkit.messageHandlers.shareSNS.postMessage(JSON.stringify(kakaoLinkObj));

    return;
  }

  // Android-App
  if (userAgent.indexOf('1store_android') > -1) {
    kakaoLinkObj.snsType = 'kakao';
    // eslint-disable-next-line
    __1STORE_ANDROID.shareSNS(JSON.stringify(kakaoLinkObj));
    return;
  }
  // finally, run for web
  window.Kakao.Link.sendDefault(kakaoLinkObj);
};
