import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';

import useModal from 'hooks/useModal';
import { putOneProductIncentive, putOneProductPublic } from 'api/oneProduct';

import {
  ItemImageBox,
  ItemProfileBox,
  ItemTitleBox,
  ItemTextBox,
  ItemStatsBox
} from 'components/commons/ItemCard/ItemCard';
import { getImagePath, getProfileImage } from 'lib/utils/imageUtils';

import { Progress } from 'reactstrap';
import ToggleButton from 'components/commons/ToggleButton';
import AlertModal from 'components/modal/AlertModal';

import CDN_URL from 'components/commons/globalUrl';
import {
  ItemCardWrapper,
  StyledItemReward,
  StyledItemLoader,
  StyledItemSetting
} from 'components/commons/ItemCard/ItemCardStyle';

function useEffectUpdate(effect, deps) {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      effect();
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isFirstRender.current = false;
  }, []);
}

export default function OneProductCard({
  className,
  storeId,
  storeName,
  profileImgPath,
  productId,
  productName,
  productImgPath,
  storeRecommendation,
  onePSaveStruct,
  countOfComment = 0,
  countOfTo = 0,
  isOneStore,
  isMyStore,
  flagPublic
}) {
  const buyerRate = onePSaveStruct ? onePSaveStruct.toSave * 100 : 100;
  const history = useHistory();
  const [isPublic, setIsPublic] = useState(flagPublic === 1);
  const [isLoading, setIsLoading] = useState(false);
  const [incentiveRate, setIncentiveRate] = useState(buyerRate);
  const {
    message: errorMessage,
    setMessage: setErrorMessage,
    isOpen: isOpenErrorModal,
    handleToggle: toggleErrorModal
  } = useModal();

  const onChangePrivacy = () => {
    setIsPublic((old) => !old);
  };

  const onChangeIncentive = (value) => {
    // console.log({ incentiveRate });
    // console.log({ value });
    if (!isMyStore || incentiveRate === parseInt(value, 10)) {
      return;
    }
    // console.log('setIncentiveRate');
    setIncentiveRate(parseInt(value, 10));
  };

  useEffectUpdate(() => {
    async function post() {
      try {
        // console.log({ isPublic });
        setIsLoading(true);
        const response = await putOneProductPublic({
          storeId,
          productId,
          flagPublic: isPublic ? 1 : 0
        });
        // console.log({ response });
      } catch (error) {
        console.log({ error });
        setErrorMessage(error.message);
        toggleErrorModal();
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
    post();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublic]);

  useEffectUpdate(() => {
    async function post() {
      try {
        // console.log({ incentiveRate });
        const onePSaveStruct = {
          fromSave: 1 - incentiveRate / 100,
          toSave: incentiveRate / 100
        };

        setIsLoading(true);
        const response = await putOneProductIncentive({
          storeId,
          productId,
          onePSaveStruct
        });
        // console.log({ response });
      } catch (error) {
        console.log({ error });
        setErrorMessage(error.message);
        toggleErrorModal();
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
    post();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incentiveRate]);

  const profileLink = storeId ? `/store/${storeId}` : '/';
  const postLink = `/store/${storeId}/${productId}/posts`;

  const recommendation =
    storeRecommendation && storeRecommendation.comment
      ? storeRecommendation.comment
      : storeRecommendation;

  const oneProductImagePath =
    storeRecommendation &&
    storeRecommendation.attachImageList &&
    storeRecommendation.attachImageList.length > 0
      ? storeRecommendation.attachImageList[0]
      : productImgPath;

  const category = {};
  const status = {};
  const stats = {
    numberOfOneProducts: countOfTo,
    numberOfComments: countOfComment
  };

  return (
    <Fragment>
      <ItemCardWrapper
        className={`${className} ${!isPublic ? 'hidden-card' : ''}`}
      >
        {isLoading && (
          <StyledItemLoader>
            <img src={`${CDN_URL}/from/from_loading_1.gif`} alt="Loading ..." />
          </StyledItemLoader>
        )}
        {isMyStore && (
          <StyledItemSetting onClick={(e) => e.stopPropagation()}>
            <ToggleButton
              id={productId}
              isLoading={isLoading}
              checked={isPublic}
              onClick={onChangePrivacy}
              color="from"
            />
          </StyledItemSetting>
        )}
        <ItemImageBox
          className={className}
          imagePath={
            getImagePath(oneProductImagePath) || '/images/default-image.svg'
          }
          onClick={() => {
            history.push(`/store/${storeId}/${productId}`);
          }}
        />
        {isOneStore ? (
          <ItemTitleBox
            className={className}
            title={productName}
            category={category}
            onClick={() => {
              history.push(`/store/${storeId}/${productId}`);
            }}
          />
        ) : (
          <ItemProfileBox
            className={className}
            profileName={storeName}
            profileImagePath={getProfileImage(profileImgPath)}
            profileLink={profileLink}
          />
        )}
        <ItemTextBox
          onClick={() => {
            console.log({ postLink });
            history.push(postLink);
          }}
          className={className}
          text={recommendation || '이야기를 나눠주세요'}
        />
        <ItemStatsBox className={className} stats={stats} />
        <StyledItemReward onClick={(e) => e.stopPropagation()}>
          <div className="reward-status">
            <img
              className="profile-image"
              src={getProfileImage(profileImgPath)}
              alt="사용자 이미지"
              // placeholderSrc="black-and-white"
            />
            <div>
              <span>님이 쏘는 적립금 {incentiveRate}%</span>
            </div>
          </div>
          <div
            className={`reward-control-bar ${!isMyStore ? 'disabled-bar' : ''}`}
          >
            <div className="control-bar">
              <Progress className="control-bar-line" value={incentiveRate} />
              <label
                className={classNames('control-bar-label label-left', {
                  active: incentiveRate === 0
                })}
              >
                <input
                  type="radio"
                  name="incentive"
                  value="0"
                  className="control-bar-radio"
                  onChange={(e) => {
                    onChangeIncentive(e.target.value);
                  }}
                  disabled={!isMyStore || isLoading}
                  checked={incentiveRate === 0}
                />
              </label>
              <label
                className={classNames('control-bar-label label-center', {
                  active: incentiveRate === 50
                })}
              >
                <input
                  type="radio"
                  name="incentive"
                  defaultValue="50"
                  className="control-bar-radio"
                  onChange={(e) => {
                    onChangeIncentive(e.target.value);
                  }}
                  disabled={!isMyStore || isLoading}
                  checked={incentiveRate === 50}
                />
              </label>
              <label
                className={classNames('control-bar-label label-right', {
                  active: incentiveRate === 100
                })}
              >
                <input
                  type="radio"
                  name="incentive"
                  value="100"
                  className="control-bar-radio"
                  onChange={(e) => {
                    onChangeIncentive(e.target.value);
                  }}
                  disabled={!isMyStore || isLoading}
                  checked={incentiveRate === 100}
                />
              </label>
            </div>
            <div className="control-bar-value">{`${incentiveRate}%`}</div>
          </div>
        </StyledItemReward>
      </ItemCardWrapper>
      <AlertModal
        isOpen={isOpenErrorModal}
        contentText={errorMessage}
        onToggle={toggleErrorModal}
      />
    </Fragment>
  );
}
