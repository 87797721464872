import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import SignInHelpContainer from 'containers/users/SignInHelpContainer';

function SignIn({ location }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <SignInHelpContainer location={location} />
    </Fragment>
  );
}

export default SignIn;
