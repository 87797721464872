import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import OneStoreDiscoverSuccessListContainer from 'containers/oneStore/OneStoreDiscoverSuccessListContainer';

function OneStoreDiscoverSuccessList({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <OneStoreDiscoverSuccessListContainer storeId={match.params.storeId} />
    </Fragment>
  );
}

export default OneStoreDiscoverSuccessList;
