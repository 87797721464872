/* eslint-disable react/jsx-indent */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { useInfiniteOneProducts } from 'hooks/useOneProducts';
import OneProductCard from 'components/oneProduct/OneProductCard';
// import AlertModal from 'components/modal/AlertModal';
import { ItemSliderWrapper } from 'components/commons/ProductSlider/ItemSliderStyle';

function OneProductSlider({
  className,
  title,
  subTitle,
  searchType,
  searchKey,
  filterBy = null,
  sortBy
}) {
  // console.log({ searchType });
  // console.log({ searchKey });
  const { data, isLoading, isError, error } = useInfiniteOneProducts({
    searchType,
    searchKey
  });

  // console.log({ data });
  const items = data ? data[0].Items : [];
  // console.log({ items });
  const filteredItems = !filterBy ? items : items.filter(filterBy);
  const oneProducts = !sortBy ? filteredItems : [...filteredItems.sort(sortBy)];

  return (
    <ItemSliderWrapper>
      <div className="title">{title}</div>
      <div className="subtitle">{subTitle}</div>
      <div className="slider-container">
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>{error.message}</div>
        ) : (
          <Fragment>
            {oneProducts.length > 0 &&
              oneProducts.map((item) => {
                return (
                  <Link
                    key={`/store/${item.storeId}/${item.productId}`}
                    to={`/store/${item.storeId}/${item.productId}`}
                  >
                    <OneProductCard
                      isOneStore
                      className={className}
                      {...item}
                    />
                  </Link>
                );
              })}
          </Fragment>
        )}
        {/* <AlertModal
          type="errorModal"
          contentText={error.message}
          isOpen={isOpen}
          onClick={handleToggle}
          onToggle={handleToggle}
        /> */}
      </div>
    </ItemSliderWrapper>
  );
}

export default OneProductSlider;
