/* eslint-disable no-console */
/**
 * @description 가격 절삭
 * @param {*} price 정가
 * @param {*} cut 절삭 단위
 */
export function priceCutting(price, cut) {
  let cuttingPrice = 0;
  cuttingPrice = Math.ceil(parseInt(price, 10) / cut);

  cuttingPrice *= cut;

  return cuttingPrice;
}

// 선택한 옵션들의 총 정가 합 구하기
export function getSumPrice(selectOpList) {
  let totalPrice = 0;

  if (selectOpList === undefined || selectOpList == null) {
    return totalPrice;
  }

  // 가격 type이 스트링으로 되어있을 확률이 높아서 먼저 number 형식으로 바꿔줌
  selectOpList.forEach((opt) => {
    let { quantity, price } = opt;

    price = parseInt(price, 10);
    quantity = parseInt(quantity, 10);

    totalPrice += price * quantity;
  });

  return totalPrice;
}

// 선택한 옵션들의 총 결제 금액 구하기
export function getTotalPrice(selectOpList) {
  let totalPrice = 0;

  if (selectOpList === undefined || selectOpList == null) {
    return totalPrice;
  }

  // 가격 type이 스트링으로 되어있을 확률이 높아서 먼저 number 형식으로 바꿔줌
  selectOpList.forEach((selectOpt) => {
    let { quantity, option } = selectOpt;
    let { price = 0, rate = {} } = option || {};

    price = parseInt(price, 10);
    quantity = parseInt(quantity, 10);

    // totalPrice += price * quantity * rate.discount;
    totalPrice +=
      priceCutting(getDiscountedPrice(price, rate, 'discount'), 10) * quantity;
  });

  // console.log({ totalPrice });

  return totalPrice;
}

export function getTotalSavedMoney(selectOpList, loginUserId) {
  let totalSavedPrice = 0;

  if (selectOpList === undefined || selectOpList == null) {
    return totalSavedPrice;
  }

  selectOpList.forEach((selectOpt) => {
    let { quantity, option, seller } = selectOpt;

    let savePrice = 0;

    if (loginUserId === seller.storeId) {
      savePrice = getSavePrice(option, { toSave: 1, fromSave: 0 });
    } else {
      savePrice = getSavePrice(option, seller.onePSaveStruct);
    }

    totalSavedPrice += savePrice.toSavePrice * quantity;
  });

  return totalSavedPrice;
}

export function getPaidOpt(selectedItem, paidSavedMoney) {
  const { quantity, option, seller } = selectedItem;
  const { onePSaveStruct } = seller;
  // const { cnx, discount, provider, saveSum } = incentiveStruct;
  const calcObject = getCalcPriceObject(option);
  const savePrice = getSavePrice(option, onePSaveStruct);

  const paidOpt = {
    cnx: calcObject.cnxPrice * quantity,
    discount: calcObject.discountPrice * quantity,
    provider: calcObject.providerPrice * quantity,
    saveSum: calcObject.sellerPrice * quantity,
    paidSaveMoney: paidSavedMoney
  };

  paidOpt.toSave = savePrice.toSavePrice * quantity;
  paidOpt.fromSave = savePrice.fromSavePrice * quantity;

  return paidOpt;
}

// 배송비, 적립금 사용까지 계산한 총 금액
export function getFinalTotalPrice(
  totalPrice,
  basicDeliveryFee,
  balanceSavedMoney
) {
  let newTotalPrice = totalPrice;

  try {
    if (!Number.isNaN(basicDeliveryFee)) {
      newTotalPrice += basicDeliveryFee; // 배송비 추가
    }

    if (!Number.isNaN(balanceSavedMoney)) {
      newTotalPrice -= balanceSavedMoney; // 사용한 적립금 만큼 총 금액에서 뺌
    }

    if (newTotalPrice <= 0) {
      newTotalPrice = 0;
    }
  } catch (e) {
    newTotalPrice = totalPrice;
  }
  return newTotalPrice;
}

// 적립금액 퍼센트 구하기
export function getSavePercent(onePSaveStruct, rate) {
  const savePercent = {
    toSavePercent: 0,
    fromSavePercent: 0
  };

  if (!rate || !onePSaveStruct) {
    return savePercent;
  }

  const { toSave, fromSave } = onePSaveStruct;

  savePercent.toSavePercent = getIncentivePercent(rate, `seller`) * toSave;
  savePercent.fromSavePercent = getIncentivePercent(rate, `seller`) * fromSave;

  return savePercent;
}

// 적립금액 구하기
export function getSavePrice(option, onePSaveStruct) {
  const savePrice = {
    toSavePrice: 0,
    fromSavePrice: 0
  };

  if (!onePSaveStruct || !option) {
    return savePrice;
  }

  const saveSumPrice = priceCutting(
    getIncentivePrice(option.price, option.rate, 'seller'),
    10
  );

  const { toSave, fromSave } = onePSaveStruct;

  savePrice.toSavePrice = saveSumPrice * toSave;
  savePrice.fromSavePrice = saveSumPrice * fromSave;

  return savePrice;
}

export function getCalcPriceObject(option, rateUnit = 'rate') {
  let totalRate = 1;

  const calcPriceObject = { ...option };

  if (!option || !option.rate) {
    return calcPriceObject;
  }

  if (rateUnit === 'percent') {
    totalRate = 100;
  }

  calcPriceObject.rate = option.rate;

  calcPriceObject.rate.provider =
    totalRate -
    parseFloat(calcPriceObject.rate.cnx || 0) -
    parseFloat(calcPriceObject.rate.discount || 0) -
    parseFloat(calcPriceObject.rate.seller || 0);

  calcPriceObject.rate.total =
    parseFloat(calcPriceObject.rate.cnx || 0) +
    parseFloat(calcPriceObject.rate.discount || 0) +
    parseFloat(calcPriceObject.rate.seller || 0) +
    parseFloat(calcPriceObject.rate.provider || 0);

  if (totalRate === 1) {
    calcPriceObject.discountPrice = priceCutting(
      getIncentivePrice(
        calcPriceObject.price,
        calcPriceObject.rate,
        'discount'
      ),
      10
    );

    calcPriceObject.sellerPrice = priceCutting(
      getIncentivePrice(calcPriceObject.price, calcPriceObject.rate, 'seller'),
      10
    );
    calcPriceObject.cnxPrice = priceCutting(
      getIncentivePrice(calcPriceObject.price, calcPriceObject.rate, 'cnx'),
      10
    );

    // 할인된가격
    calcPriceObject.discountedPrice =
      calcPriceObject.price - calcPriceObject.discountPrice;

    calcPriceObject.providerPrice =
      calcPriceObject.price -
      (calcPriceObject.discountPrice +
        calcPriceObject.sellerPrice +
        calcPriceObject.cnxPrice);

    calcPriceObject.percentRate = getIncentivePercentObject(option.rate);
  } else {
    // calcPriceObject.discountPrice = option.price * (option.rate.discount / 100);
    // calcPriceObject.sellerPrice = option.price * (option.rate.seller / 100);
    // calcPriceObject.cnxPrice = option.price * (option.rate.cnx / 100);
    // calcPriceObject.providerPrice = option.price * (option.rate.provider / 100);
    //
    // cutting
    calcPriceObject.discountPrice = priceCutting(
      option.price * (option.rate.discount / 100),
      10
    );
    calcPriceObject.sellerPrice = priceCutting(
      option.price * (option.rate.seller / 100),
      10
    );
    calcPriceObject.cnxPrice = priceCutting(
      option.price * (option.rate.cnx / 100),
      10
    );
    calcPriceObject.discountedPrice =
      calcPriceObject.price - calcPriceObject.discountPrice;
    calcPriceObject.providerPrice =
      calcPriceObject.price -
      (calcPriceObject.discountPrice +
        calcPriceObject.sellerPrice +
        calcPriceObject.cnxPrice);
  }

  return calcPriceObject;
}

export function getCalcPricePercentObject(option) {
  const totalRate = 100;

  const calcPriceObject = {};

  if (!option || !option.rate) {
    return calcPriceObject;
  }

  calcPriceObject.rate = option.rate;

  calcPriceObject.rate.provider =
    totalRate -
    parseFloat(option.rate.cnx || 0) -
    parseFloat(option.rate.discount || 0) -
    parseFloat(option.rate.seller || 0);

  calcPriceObject.rate.total =
    parseFloat(option.rate.cnx || 0) +
    parseFloat(option.rate.discount || 0) +
    parseFloat(option.rate.seller || 0) +
    parseFloat(option.rate.provider || 0);

  calcPriceObject.discountPrice = option.price * (option.rate.discount / 100);
  calcPriceObject.sellerPrice = option.price * (option.rate.seller / 100);
  calcPriceObject.cnxPrice = option.price * (option.rate.cnx / 100);
  calcPriceObject.providerPrice = option.price * (option.rate.provider / 100);

  // TODO
  // provider percent
  // total Percent

  return calcPriceObject;
}

// 인센티브 퍼신트 구하기
export function getIncentivePercent(incentiveStruct, incentive) {
  if (!incentiveStruct) {
    return 0;
  }

  let percent = parseFloat((100 * incentiveStruct[incentive]).toFixed(10));

  if (incentive === 'provider') {
    const withoutProvider =
      parseFloat(incentiveStruct.cnx) +
      parseFloat(incentiveStruct.seller) +
      parseFloat(incentiveStruct.discount);

    percent = parseFloat((100 * (1 - withoutProvider)).toFixed(10));
  }

  if (Number.isNaN(percent)) {
    percent = 0;
  }

  return percent;
}

// 인센티브 소수 구하기
export function getIncentivePoint(incentiveStruct, incentive) {
  if (!incentiveStruct) {
    return 0;
  }

  let point = parseFloat(
    (parseFloat(incentiveStruct[incentive]) / 100).toFixed(10)
  );

  if (incentive === 'provider') {
    const withoutProvider =
      parseFloat(incentiveStruct.cnx) +
      parseFloat(incentiveStruct.seller) +
      parseFloat(incentiveStruct.discount);

    point = parseFloat((parseFloat(100 - withoutProvider) / 100).toFixed(10));
  }

  if (Number.isNaN(point)) {
    point = 0;
  }

  return point;
}

//  인센티브 가격 구하기
export function getIncentivePrice(price, rate, incentive) {
  if (!price) {
    return 0;
  }

  if (!rate) {
    return price;
  }

  return Math.floor(price * rate[incentive]);
}

export function getIncentivePercentObject(pointIncentiveStruct) {
  const incentivePercentObject = {
    cnx: getIncentivePercent(pointIncentiveStruct, 'cnx'),
    seller: getIncentivePercent(pointIncentiveStruct, 'seller'),
    provider: getIncentivePercent(pointIncentiveStruct, 'provider'),
    discount: getIncentivePercent(pointIncentiveStruct, 'discount')
  };

  return incentivePercentObject;
}

export function getIncentivePointObject(percentIncentiveStruct) {
  const incentivePointObject = {
    cnx: getIncentivePoint(percentIncentiveStruct, 'cnx'),
    seller: getIncentivePoint(percentIncentiveStruct, 'seller'),
    provider: getIncentivePoint(percentIncentiveStruct, 'provider'),
    discount: getIncentivePoint(percentIncentiveStruct, 'discount')
  };
  return incentivePointObject;
}

// 할인금액 구하기
export function getDiscountedPrice(price, rate) {
  if (!price) {
    return 0;
  }

  if (!rate) {
    return price;
  }

  const discountPrice = priceCutting(
    getIncentivePrice(price, rate, 'discount'),
    10
  );

  return price - discountPrice;
}

// 옵션이름 - 할인된가격
// 정가
// 할인퍼센트 - 할인된가격
// 배송비
// 결제할금액
// 적립퍼센트 - 적립가격
// 할인금액 구하기

export function getProductDetailPrice(option) {
  const calcPriceObject = {};

  if (!option || !option.rate) {
    return calcPriceObject;
  }
}

export function getFreeDeliveryAmount(provider) {
  // console.log({ provider });
  const freeDeliveryAmount =
    provider &&
    (provider.freeDeliveryAmount || provider.freeDeliveryAmount === 0)
      ? provider.freeDeliveryAmount
      : 50000;
  // console.log({ freeDeliveryAmount });

  return freeDeliveryAmount;
}

export function isFreeDelivery(provider, orderAmount) {
  const freeDeliveryAmount = getFreeDeliveryAmount(provider);
  // console.log({ freeDeliveryAmount });
  // console.log({ orderAmount });

  return orderAmount >= freeDeliveryAmount;
}
