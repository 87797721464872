import React, { useState } from 'react';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import ProviderProductList from 'components/provider/ProviderProductList';
import ProviderSuccessProductItem from 'components/provider/ProviderProductList/ProviderSuccessProductItem';
import sentry from 'lib/sentry';

import { getProviderProduct } from 'api/provider';

function ProviderProductSuccessContainer({ providerId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [lastKey, setLastKey] = useState(null);

  const infiniteRef = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMoreItems,
    onLoadMore: getProductList,
    scrollContainer: 'window'
  });

  async function getProductList() {
    const params = {
      lastKey,
      pageCount: 20,
      providerId,
      searchType: 'provider'
    };

    try {
      setIsLoading(true);
      const response = await getProviderProduct(params);

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `공급자 상품관리에서 발굴 성공 리스트 가져오기 실패 - ${JSON.stringify(
            response
          )}`
        );
      }

      const { Items: items, LastEvaluatedKey: lastKey } = response.data.data;

      setIsLoading(false);
      setLastKey(lastKey);

      setProductList([...productList, ...items]);
      setHasMoreItems(!!lastKey);
    } catch (e) {
      // setIsLoading(false);
      sentry.captureException(e.message);
    }
  }

  return (
    <ProviderProductList
      haveList={productList && productList.length > 0}
      infiniteRef={infiniteRef}
      isLoading={isLoading}
    >
      {productList.map((products) => (
        <ProviderSuccessProductItem
          key={products.createDate}
          products={products}
        />
      ))}
    </ProviderProductList>
  );
}

export default ProviderProductSuccessContainer;
