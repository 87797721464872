import React, { Fragment, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import { getPriceFormat } from 'lib/utils/numberUtils';

import CheckoutHeader from './CheckoutHeader';

import UserSubForm from 'components/shared/UserSubForm';
import AddressSubForm from 'components/shared/AddressSubForm';
import { ButtonWrapper, StyledButton } from 'shared/buttons/ButtonStyle';

import {
  Input,
  TextArea,
  Select,
  CheckBox,
  RadioButtons,
  DatePicker,
  SubmitButton,
  CancelButton
} from 'shared/components';

import {
  StyledForm,
  FormHeaderWrapper,
  StyledFormTitle,
  FormBodyWrapper,
  StyledTextButton,
  FormButtonWrapper
} from 'shared/styled/componentStyle';

import {
  stringRequired,
  nameRequired,
  mobileNewRequired,
  mobileRequired
} from 'shared/rules/validationRules';

function CheckoutForm({
  isGift = true,
  isDelivery = true,
  currentOrder,
  receiver = { mobile: '010', sendMessage: true },
  totalAmount,
  addReceiver,
  addOrder,
  removeOrder,
  isLoggedIn,
  showHeader,
  isUp,
  className
}) {
  const history = useHistory();

  const schema = yup.object().shape({
    name: nameRequired,
    mobile: !isGift && !isLoggedIn ? mobileNewRequired : mobileRequired,
    // mobile: yup.string().when('experienceType', {
    //   is: 'buy',
    //   then: mobileNewRequired,
    //   otherwise: mobileRequired
    // }),

    addr: isDelivery ? yup.string().when('experienceType', {
      is: 'gift-recv',
      then: yup.string(),
      otherwise: stringRequired
    }) : null
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: receiver
  });

  const {
    register,
    control,
    errors,
    formState,
    reset,
    watch,
    handleSubmit,
    setValue
  } = methods;

  const experienceTypeWatch = watch(
    'experienceType',
    !isGift
      ? 'buy'
      : !isDelivery
      ? 'gift-direct'
      : receiver?.experienceType
      ? receiver.experienceType
      : 'gift-recv'
  );

  // console.log({ isGift, isDelivery, experienceTypeWatch });

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log('Restoring receiver');
    // console.log({ receiver });
    if (receiver) {
      reset(receiver);
    } else {
      reset();
    }
  }, [currentOrder, receiver.name, receiver.id]);

  const onPayForOrders = (values) => {
    try {
      // console.log({ values });
      addReceiver({ ...values });
      history.push('/order/payment');
      // addOrder('gift');
    } catch (error) {
      console.log({ error });
    }
  };

  const onAddOrder = (values) => {
    try {
      // console.log({ values });
      addReceiver({ ...values });
      addOrder('gift');
    } catch (error) {
      console.log({ error });
    }
  };

  // console.log({ errors });

  return (
    <FormProvider {...methods} className={className}>
      <CheckoutHeader />

      <StyledForm>
        <FormHeaderWrapper>
          <StyledTextButton
            className="close"
            type="button"
            onClick={() => {
              console.log('remove button clicked');
              removeOrder();
            }}
          >
            <img src="/images/x-icon.svg" alt="close" />
          </StyledTextButton>
        </FormHeaderWrapper>
        {/* <StyledFormTitle>받는 분</StyledFormTitle> */}
        <FormBodyWrapper>
          <UserSubForm isCheckout />
          {isGift && (
            <Fragment>
              <TextArea
                className={className}
                name="giftMessage"
                label="선물 메세지"
                placeholder="받는 분에게 마음을 전하세요 ^^ (최대 300자)"
                rows="5"
                {...{ register, control, errors }}
              />
              {isDelivery && (
                <RadioButtons
                  className={`${className} required`}
                  options={{
                    'gift-recv': '받는 분이 입력',
                    'gift-direct': '직접 입력'
                  }}
                  name="experienceType"
                  label="배송지"
                  value={experienceTypeWatch}
                  {...{ register, errors }}
                />
              )}
            </Fragment>
          )}

          {((!isGift && isDelivery) ||
            (isDelivery && experienceTypeWatch === 'gift-direct')) && (
            <Fragment>
              {isGift && (
                <CheckBox
                  className={`${className} round-checkbox`}
                  name="sendMessage"
                  label="선물받는 분에게 문자 보내기"
                  {...{ register, control, errors }}
                />
              )}
              <AddressSubForm />
              <Input
                className={className}
                name="deliveryMessage"
                label="배송 메세지"
                placeholder="예) 경비실에 맡겨주세요"
                {...{ register, control, errors }}
              />
              <DatePicker
                className={className}
                name="preferredShipDate"
                label="발송요청일"
                placeholder="희망하는 발송일을 선택해 주세요."
                {...{ control, errors }}
              />
            </Fragment>
          )}

          {/* <ButtonWrapper>
            <StyledButton className="white-button" onClick={emptyCart}>
              비우기
            </button>
            <StyledButton className="white-button" onClick={restoreCart}>
              다시 담기
            </button>
          </ButtonWrapper>

          {order &&
            order.items.map((item, index) => (
              <CartItem
                key={item.option.id}
                itemType="checkout"
                index={index}
                {...item}
                {...{ removeItem, increment, decrement, onClickOption }}
              />
            ))}
          <CartSummary title="선물 합계" {...order?.checkedTotal} /> */}
        </FormBodyWrapper>
        {/* <FormButtonWrapper> */}
        <ButtonWrapper
          className={`footer-button-wrapper ${
            !showHeader ? 'hidden-buttons' : ''
          } ${isUp ? 'iphone-x-buttons' : ''}`}
        >
          <StyledButton
            type="button"
            className="from-button narrow-button"
            onClick={handleSubmit(onAddOrder)}
          >
            받는분 추가
          </StyledButton>
          <StyledButton
            type="button"
            className="black-button"
            onClick={handleSubmit(onPayForOrders)}
          >
            결제하기
            <span>{`(${getPriceFormat(totalAmount)}원)`}</span>
          </StyledButton>
        </ButtonWrapper>
        {/* </FormButtonWrapper> */}
        {/* <DevTool control={control} /> */}
      </StyledForm>
    </FormProvider>
  );
}

export default CheckoutForm;
