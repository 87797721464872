import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --from-color: #37e211;
    --from-light-color: #62ff11;
    --provider-color: #f68b1f;

    --page-background-color: #f8f8f9;
    --yellow-support-color: #ffde00;

    --black-color: #000;
    --white-color: #fff;
    --text-color: #212529;
    --text-light-color: #858985;
    --border-color: #f5f4f4;
    --button-border-color: #d1d1d1;
    --placeholder-color: #d1d2d3;
    --danger-color: #ff0000;
    
    --gray-200: #f0f0f0;
    --gray-250: #ebebeb;
    --gray-300: #cfd1d2;
    --gray-800: #656565;

    --max-width: 950px;
    --max-content-width: 420px;
    --header-height: 56px;
    --footer-height: 58px;
    --iphone-x-offset-height: 33px;
  }
`;
