import React from 'react';

import { getPriceFormat } from 'lib/utils/numberUtils';

import './BalanceSavedMoneyStatus.scss';

function BalanceSavedMoneyStatus({ balanceSavedMoney, userName }) {
  return (
    <div className="balance-saved-money-status">
      <div className="balance-saved-money-status-title">
        <span>{userName}</span>님의 현재 사용가능 적립금은,
      </div>
      <div className="balnce-saved-money-status-price">
        {getPriceFormat(Math.floor(balanceSavedMoney))}원
      </div>
    </div>
  );
}

export default BalanceSavedMoneyStatus;
