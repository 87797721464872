import { useQuery, useInfiniteQuery } from 'react-query';

import fetchData from 'api/fetchData';
import { getPostList } from 'api/post';

const STALE_TIME = 1000 * 60 * 10;
const ITEMS_PER_PAGE = 10;

// Sum value By groups of keys: groupBy(data, ['Phase', 'Step'], 'Value')
// https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
// const groupBy = (array, groups, valueKey) => {
//   var map = new Map();
//   groups = [].concat(groups);
//   return array.reduce((r, o) => {
//     groups.reduce((m, k, i, { length }) => {
//       var child;
//       if (m.has(o[k])) return m.get(o[k]);
//       if (i + 1 === length) {
//         child = Object.assign(...groups.map((k) => ({ [k]: o[k] })), {
//           [valueKey]: 0
//         });
//         r.push(child);
//       } else {
//         child = new Map();
//       }
//       m.set(o[k], child);
//       return child;
//     }, map)[valueKey] += +o[valueKey];
//     return r;
//   }, []);
// };

const getPosts = async (
  key,
  {
    ownerId,
    authorId,
    productId,
    providerId,
    itemsPerPage = ITEMS_PER_PAGE,
    isAscending = false
  },
  LastEvaluatedKey
) => {
  try {
    const params = {
      ownerId,
      authorId,
      productId,
      providerId,
      pageCount: itemsPerPage,
      isAscending,
      lastKey: LastEvaluatedKey
    };

    const data = await fetchData(getPostList, params);

    // const data =
    //   ownerId & productId
    //     ? await fetchData(getCommentList, [params, ownerId, productId])
    //     : productId || ownerId
    //     ? await fetchData(getProductComments, params)
    //     : providerId
    //     ? await fetchData(getProductComments, params)
    //     : await fetchData(getProductComments, params);

    // const mappedItems = data.Items.map((item) => {
    //   return {
    //     expId: item.expId,
    //     commentsId: `${item.commentsId.split('-')[0]}-0000`,
    //     count: 1
    //   };
    // });

    // console.log({ mappedItems });
    // const replyCounts = groupBy(mappedItems, ['expId', 'commentsId'], 'count');
    // console.log({ replyCounts });

    // const filteredItems = data.Items.filter((item) => item.depth === 0);

    // const Items = filteredItems.map((item) => {
    //   const replyCount =
    //     replyCounts.find(
    //       (count) =>
    //         count.expId === item.expId && count.commentsId === item.commentsId
    //     ).count - 1;
    //   return { ...item, replyCount };
    // });

    // console.log({ Items });

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getReplies = async (
  key,
  { id, itemsPerPage = ITEMS_PER_PAGE },
  LastEvaluatedKey
) => {
  try {
    const params = {
      id,
      pageCount: itemsPerPage,
      lastKey: LastEvaluatedKey
    };

    const data = await fetchData(getPostList, params);

    return data;
  } catch (error) {
    throw error;
  }
};

export function usePosts({
  ownerId,
  authorId,
  productId,
  providerId,
  itemsPerPage
}) {
  const query = useInfiniteQuery(
    ['posts', { ownerId, authorId, productId, providerId, itemsPerPage }],
    getPosts,
    {
      getFetchMore: (lastPage) => {
        // console.log({ lastPage });
        return lastPage.LastEvaluatedKey;
      }
    }
  );

  return query;
}

export function usePost({ id }) {
  const { data, status, isLoading, isError, error, isFetching } = useQuery(
    ['post', { id }],
    getReplies,
    {
      enabled: id
    }
  );
  return { data, status, isLoading, isError, error, isFetching };
}
