// isTablet()과 isMobile()은 HP와 SRP에서 사용 중
const isMobileDevice = () => {
  const navigator = typeof window !== 'undefined' ? window.navigator : null;
  var phoneArray = [
    'samsung-',
    'sch-',
    'shw-',
    'sph-',
    'sgh-',
    'lg-',
    'canu',
    'im-',
    'ev-',
    'iphone',
    'nokia',
    'blackberry',
    'lgtelecom',
    'natebrowser',
    'sonyericsson',
    'mobile',
    'android',
    'ipad'
  ];

  if (navigator === null) {
    return false;
  }

  for (let i = 0; i < phoneArray.length; i++) {
    if (navigator.userAgent.toLowerCase().indexOf(phoneArray[i]) > -1) {
      return true;
    }
  }

  return false;
};

const isTabletDevice = () => {
  const navigator = typeof window !== 'undefined' ? window.navigator : null;

  if (navigator === null) {
    return false;
  }

  if (!isMobileDevice()) {
    return false;
  }
  // 태블릿검사
  if (
    navigator.userAgent.toLowerCase().indexOf('ipad') > -1 ||
    (navigator.userAgent.toLowerCase().indexOf('android') > -1 &&
      navigator.userAgent.toLowerCase().indexOf('mobile') == -1)
  ) {
    return true;
  }

  // 갤럭시 탭만을 위한 리다이렉트. Mobile 이라는 단어가 안들어오게 되면 지우셔도 됨
  var galaxyTabModel = new Array('shw-');

  for (let i = 0; i < galaxyTabModel.length; i++) {
    if (navigator.userAgent.toLowerCase().indexOf(galaxyTabModel[i]) > -1) {
      return true;
    }
  }
  return false;
};

export default () => {
  if (isTabletDevice()) {
    return 'Tablet';
  }

  if (isMobileDevice()) {
    return 'Mobile';
  }

  return 'Desktop';
};
