import React, { Fragment } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import OneProductContainer from 'containers/oneProduct/OneProductContainer';

function OneProduct({ showHeader }) {
  const params = useParams();
  const location = useLocation();
  // console.log({ location });
  const initialOpen = location?.state?.initialOpen;
  // console.log({ params });
  // console.log({ showHeader });
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <OneProductContainer
        storeId={params.storeId}
        productId={params.productId}
        showHeader={showHeader}
        initialOpen={initialOpen}
      />
    </Fragment>
  );
}

export default OneProduct;
