/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'redux-form';

import RenderField from 'components/reduxForm/RenderField';
import RenderFieldRadio from 'components/reduxForm/RenderFieldRadio';
import RenderFieldIncludeUnit from 'components/reduxForm/RenderFieldIncludeUnit';
import RenderSelect from 'components/reduxForm/RenderSelect';
import RenderImage from 'components/reduxForm/RenderImage';
// import PictureInput from 'components/commons/PictureInput';
// import { getImageFile } from 'lib/utils/imageUtils';

import {
  checkRequiredShippingRate,
  emptyValidation
} from 'lib/form/validations';
import { getPriceFormat } from 'lib/utils/numberUtils';
import { getCalcPriceObject } from 'lib/utils/newPriceUtils';

import 'components/admin/RegisterProductForm/IncentiveStructField.scss';
import './RenderOptionPriceListField.scss';

function RenderOptionPriceListField({
  fields,
  onChangePrice,
  defaultRate,
  onSwapIndex,
  onClickDeleteOption,
  // eslint-disable-next-line no-unused-vars
  onChangeImage
}) {
  let lastIndex = -1;

  return (
    <div className="form-group">
      <ul className="render-option-pricelist-field">
        {fields.map((priceOption, index) => {
          const calcPriceObject = getCalcPriceObject(
            fields.get(index),
            'percent'
          );
          const priceOptionId = fields.get(index).id;
          const isToggleShowRate = fields.get(index).isShowRate;
          const previousNo = fields.get(index).seqNo;
          const optionImagePath =
            fields.get(index).imagePath || fields.get(index).image;
          const hasOptionImage = optionImagePath ? true : false;
          // const optionImage = getImageFile(optionImagePath);

          lastIndex = index;

          return (
            <li key={priceOptionId || `new_${index}`}>
              <span>
                옵션{index + 1} - {priceOptionId}
              </span>
              {!priceOptionId && (
                <button
                  type="button"
                  className="product-regist-price-button remove"
                  onClick={() => {
                    onClickDeleteOption(fields, index);
                  }}
                >
                  -
                </button>
              )}
              <Field
                component={RenderSelect}
                type="text"
                name={`${priceOption}.seqNo`}
                label="옵션 순서"
                className="mt-3 mr-1"
                validate={[emptyValidation]}
                onChange={(e) => {
                  onSwapIndex(e, previousNo);
                }}
              >
                {fields.map((field, changeIndex) => (
                  <option key={field.value} value={changeIndex}>
                    {changeIndex + 1}
                  </option>
                ))}
              </Field>
              <Field
                component={RenderField}
                type="text"
                label="옵션 이름"
                name={`${priceOption}.name`}
                validate={[emptyValidation]}
                placeholder="종류"
                className="mt-3 mr-1"
              />
              <div className="form-group d-flex align-items-center">
                <span className="form-label form-picture-label float-left text-left pr-3">
                  옵션이미지
                </span>
                <Field
                  component={RenderImage}
                  type="file"
                  name={`${priceOption}.image`}
                  onChangeImage={onChangeImage}
                  imagePath={optionImagePath}
                  index={index}
                />
              </div>
              {!hasOptionImage && (
                <div>
                  <span className="d-block invalid-feedback invalid-feedback-with-label">
                    *미입력시 공식 상품이미지가 사용됩니다.
                  </span>
                  <br />
                </div>
              )}
              <div className="form-group d-flex align-items-center mt-3">
                <span className="form-label d-inline-block text-left pr-2 ">
                  공개함
                </span>
                <Field
                  name={`${priceOption}.isOnDisplay`}
                  id={`yesOnDisplay${index}`}
                  component={RenderFieldRadio}
                  value="true"
                  type="radio"
                  label="예"
                />
                <Field
                  name={`${priceOption}.isOnDisplay`}
                  id={`noOnDisplay${index}`}
                  component={RenderFieldRadio}
                  value="false"
                  type="radio"
                  label="아니오"
                />
              </div>
              <div className="form-group d-flex align-items-center mt-3">
                <span className="form-label d-inline-block text-left pr-2 ">
                  재고있음
                </span>
                <Field
                  name={`${priceOption}.isInStock`}
                  id={`yesInStock${index}`}
                  component={RenderFieldRadio}
                  value="true"
                  type="radio"
                  label="예"
                />
                <Field
                  name={`${priceOption}.isInStock`}
                  id={`noInStock${index}`}
                  component={RenderFieldRadio}
                  value="false"
                  type="radio"
                  label="아니오"
                />
              </div>
              {/* <div className="form-group d-flex align-items-center mt-3">
                <span className="form-label d-inline-block text-left pr-2 ">
                  면세상품
                </span>
                <div className="ml-auto">
                  <Field
                    name="flagReservationnn"
                    id="yesReservation"
                    component={RenderFieldRadio}
                    value="true"
                    type="radio"
                    label="예"
                  />
                  <Field
                    name="flagReservationnn"
                    id="noReservation"
                    component={RenderFieldRadio}
                    value="false"
                    type="radio"
                    label="아니오"
                  />
                </div>
              </div> */}
              <div className="incentive-price-form">
                <h2 className="font-weight-bold mb-2">옵션가격</h2>
                <div className="rate-group">
                  <div className="incentive-price-field-set">
                    <div className="w-50">
                      <span className="form-label d-inline-block text-left pr-1">
                        정가
                      </span>
                      <span className="form-incentive-input form-incentive-provider mb-0">
                        {`${
                          Number.isNaN(calcPriceObject.rate.total)
                            ? 0
                            : calcPriceObject.rate.total.toFixed(2)
                        }`}
                      </span>
                      <span className="ml-1">%</span>
                    </div>
                    <Field
                      component={RenderFieldIncludeUnit}
                      type="text"
                      name={`${priceOption}.price`}
                      onBlur={onChangePrice}
                      placeholder="가격"
                      validate={[emptyValidation, checkRequiredShippingRate]}
                      className="mb-0 ml-auto"
                      pattern={/[^0-9]/g}
                      unit="원"
                    />
                  </div>
                  <div className="incentive-price-field-set">
                    <div className="w-50">
                      <span className="form-label d-inline-block text-left pr-1">
                        판매가
                      </span>
                      <span className="form-incentive-input form-incentive-provider mb-0">
                        {`${
                          Number.isNaN(calcPriceObject.rate.discount)
                            ? 0
                            : (100 - calcPriceObject.rate.discount).toFixed(2)
                        }`}
                      </span>
                      <span className="ml-1">%</span>
                    </div>
                    <span className="incentive-struc-form-preview">
                      {`${getPriceFormat(
                        Math.floor(calcPriceObject.discountedPrice)
                      )}원`}
                    </span>
                  </div>
                  <div className="incentive-price-field-set">
                    <div className="w-50">
                      <span className="form-label d-inline-block text-left pr-1">
                        공급가
                      </span>
                      <span className="form-incentive-input form-incentive-provider mb-0">
                        {`${
                          Number.isNaN(calcPriceObject.rate.provider)
                            ? 0
                            : calcPriceObject.rate.provider.toFixed(2)
                        }`}
                      </span>
                      <span className="ml-1">%</span>
                    </div>
                    <span className="incentive-struc-form-preview">
                      {`${getPriceFormat(
                        Math.floor(calcPriceObject.providerPrice)
                      )}원`}
                    </span>
                  </div>
                </div>
                <div className="incentive-price-field-set">
                  <div className="incentive-price-field-set-right-button">
                    <span className="ml-1">인센티브 구조</span>
                    <Field
                      className={classNames(
                        'product-regist-price-button toggle'
                      )}
                      name={`${priceOption}.isShowRate`}
                      component="input"
                      type="checkBox"
                    />
                  </div>
                </div>
                {isToggleShowRate && (
                  <div className="rate-group">
                    <div className="incentive-price-field-set">
                      {/* <span className="incentive-struc-form-label">구매 할인율</span> */}
                      <Field
                        className="mb-0"
                        name={`${priceOption}.rate.discount`}
                        type="text"
                        component={RenderFieldIncludeUnit}
                        validate={[emptyValidation, checkRequiredShippingRate]}
                        pattern={/[^0-9.]/g}
                        label="구매 할인"
                        unit="%"
                      />
                      <span className="incentive-struc-form-preview">
                        {`${getPriceFormat(
                          Math.floor(calcPriceObject.discountPrice)
                        )}원`}
                      </span>
                    </div>
                    <div className="incentive-price-field-set">
                      {/* <span className="incentive-struc-form-label">판매자 적립율</span> */}
                      <Field
                        className="mb-0"
                        name={`${priceOption}.rate.seller`}
                        type="text"
                        component={RenderFieldIncludeUnit}
                        validate={[emptyValidation, checkRequiredShippingRate]}
                        pattern={/[^0-9.]/g}
                        label="소비자 적립"
                        unit="%"
                      />
                      <span className="incentive-struc-form-preview">
                        {`${getPriceFormat(
                          Math.floor(calcPriceObject.sellerPrice)
                        )}원`}
                      </span>
                    </div>
                    <div className="incentive-price-field-set">
                      {/* <span className="incentive-struc-form-label">커넥서스 컴퍼니</span> */}
                      <Field
                        className="mb-0"
                        name={`${priceOption}.rate.cnx`}
                        type="text"
                        component={RenderFieldIncludeUnit}
                        validate={[emptyValidation, checkRequiredShippingRate]}
                        pattern={/[^0-9.]/g}
                        label="프롬"
                        unit="%"
                      />
                      <span className="incentive-struc-form-preview">
                        {`${getPriceFormat(
                          Math.floor(calcPriceObject.cnxPrice)
                        )}원`}
                      </span>
                    </div>
                  </div>
                )}
                {/* <div className="sum-calc-group">
                  <div className="incentive-price-field-set">
                    <span className="incentive-struc-form-label">판매가</span>
                    <span className="form-incentive-input form-incentive-provider mb-0">
                      {`${
                        Number.isNaN(calcPriceObject.rate.discount)
                          ? 0
                          : (100 - calcPriceObject.rate.discount).toFixed(2)
                      }`}
                    </span>
                    <span className="ml-1">%</span>
                    <span className="incentive-struc-form-preview">
                      {`${getPriceFormat(
                        Math.floor(calcPriceObject.discountedPrice)
                      )}원`}
                    </span>
                  </div>
                  <div className="incentive-price-field-set">
                    <span className="incentive-struc-form-label">공급가</span>
                    <span className="form-incentive-input form-incentive-provider mb-0">
                      {`${
                        Number.isNaN(calcPriceObject.rate.provider)
                          ? 0
                          : calcPriceObject.rate.provider.toFixed(2)
                      }`}
                    </span>
                    <span className="ml-1">%</span>
                    <span className="incentive-struc-form-preview">
                      {`${getPriceFormat(
                        Math.floor(calcPriceObject.providerPrice)
                      )}원`}
                    </span>
                  </div>
                  <div className="incentive-price-field-set">
                    <span className="incentive-struc-form-label">전체</span>
                    <span className="form-incentive-input form-incentive mb-0">
                      {`${
                        Number.isNaN(calcPriceObject.rate.total)
                          ? 0
                          : calcPriceObject.rate.total.toFixed(2)
                      }`}
                    </span>
                    <span className="ml-1">%</span>
                  </div>
                </div> */}
              </div>
            </li>
          );
        })}
      </ul>
      <button
        type="button"
        className="btn btn-provider add"
        onClick={() => {
          fields.push({
            name: '',
            price: '',
            seqNo: lastIndex + 1,
            rate: defaultRate,
            isOnDisplay: 'true',
            isInStock: 'true',
            isShowRate: true
          });
        }}
      >
        상품 옵션 추가 +
      </button>
    </div>
  );
}

RenderOptionPriceListField.propTypes = {
  onChangePrice: PropTypes.func,
  onSwapIndex: PropTypes.func,
  onClickDeleteOption: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.object),
  // options: PropTypes.arrayOf(PropTypes.object),
  defaultRate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  // onChangeOptionImage: PropTypes.func
};

RenderOptionPriceListField.defaultProps = {
  onChangePrice: () => {},
  onSwapIndex: () => {},
  onClickDeleteOption: () => {},
  fields: null,
  // options: null,
  defaultRate: {}
  // onChangeOptionImage: () => {}
};

export default RenderOptionPriceListField;
