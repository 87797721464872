/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { Fragment } from 'react';
import classNames from 'classnames';
// import { Field } from 'redux-form';
import { isDevelop } from 'lib/utils/envUtils';

import { getPriceFormat } from 'lib/utils/numberUtils';
import { getTotalPrice } from 'lib/utils/newPriceUtils';

import {
  CartSummaryWrapper,
  SummaryTitle,
  // SummaryItems,
  SummaryItem
} from './CartSummaryStyle';

const isDev = isDevelop();
function CartSummary({
  title,
  type,
  quantity = 0,
  amount = 0,
  deliveryFee = 0,
  totalAmount = 0,
  giftPoint = 0,
  providerFreeDeliveryAmount = isDev ? 5000 : 50000,
  className = ''
}) {
  return (
    <CartSummaryWrapper className={`${className} ${type ? type : ''}`}>
      {type !== 'product' && (
        <Fragment>
          {title && <SummaryTitle className={type}>{title}</SummaryTitle>}
          <SummaryItem>
            <div className="label">수량</div>
            <div className="value">{quantity}개</div>
          </SummaryItem>
        </Fragment>
      )}
      {type !== 'provider' && (
        <Fragment>
          <SummaryItem>
            <div className="label">상품금액</div>
            <div className="value">{`${getPriceFormat(amount)}`}원</div>
          </SummaryItem>
          <SummaryItem>
            <div className="label">적립금액</div>
            <div className="value">{`${getPriceFormat(giftPoint)}`}원</div>
          </SummaryItem>
        </Fragment>
      )}
      {type !== 'product' && (
        <SummaryItem>
          <div className="label">배송비</div>
          {deliveryFee > 0 ? (
            <div className="value">{`${getPriceFormat(deliveryFee)}`}원</div>
          ) : (
            <div className="info">무료배송</div>
          )}
        </SummaryItem>
      )}

      {type === 'provider' &&
        deliveryFee > 0 &&
        providerFreeDeliveryAmount > 0 &&
        providerFreeDeliveryAmount < 1000000 && (
          <SummaryItem className="desc">
            {`*${getPriceFormat(
              providerFreeDeliveryAmount - amount
            )} 원 이상 추가주문 시, 무료배송`}
          </SummaryItem>
        )}
      {type !== 'product' && (
        <SummaryItem className="total">
          <div className="label">
            {type === 'provider' ? '공급자 합계' : '합계금액'}
          </div>
          <div className="value">{`${getPriceFormat(totalAmount)}`}원</div>
        </SummaryItem>
      )}
    </CartSummaryWrapper>
  );
}

export default CartSummary;
