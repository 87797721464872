import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
// import mediumZoom from 'medium-zoom';

import withUser from 'lib/hoc/withUser';

import DompurifyHTML from 'components/commons/DompurifyHTML';

import { getImagePath } from 'lib/utils/imageUtils';
import { getEventDataById } from 'lib/utils/eventUtils';
import CommonProfilePicture from 'components/picture/CommonProfilePicture';
import RecommendBox from 'components/oneProduct/RecommendBox';
import RecommendEditBox from 'components/oneProduct/RecommendEditBox';

import LimitCountView from 'components/product/LimitCountView';

import ProviderCrown from 'components/commons/ProviderCrown';

import './OneProductDetail.scss';

function OneProductDetail({
  storeId,
  productId,
  oneProduct,
  product,
  user,
  isLoggedIn,
  isProvider
}) {
  const {
    createDate,
    officialProductImgPath,
    discovererId,
    discovererName,
    discovererProfileImgPath,
    recommendation,
    providerId,
    providerName,
    providerProfileImgPath,
    productDetailInfo,
    flagHtmlProductInfo,
    htmlProductInfo,
    countOfSalesLimit,
    quantityOnHand
  } = product || {};

  // console.log({ oneProduct });
  // console.log({ product });
  const { storeName, profileImgPath, storeRecommendation } = oneProduct || {};
  // console.log({ storeRecommendation });
  const oneProductRecommendation =
    storeRecommendation && storeRecommendation.comment
      ? storeRecommendation.comment
      : storeRecommendation;

  const oneProductImagePath =
    storeRecommendation &&
    storeRecommendation.attachImageList &&
    storeRecommendation.attachImageList.length > 0
      ? storeRecommendation.attachImageList[0]
      : officialProductImgPath;

  const discovererRecommendation =
    recommendation && recommendation.comment
      ? recommendation.comment
      : recommendation;

  const eventComp = getEventDataById(productId).eventComp;

  // const [flagInitialZoom, setFlagInitialZoom] = useState(false);

  // useEffect(
  //   () => {
  //     console.log('initializeMediumZoomImage');
  //     // initializeMediumZoomImage();
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  // function initializeMediumZoomImage() {
  //   if (!flagInitialZoom) {
  //     const images = document.querySelectorAll('.product-image img');
  //     mediumZoom(images, {
  //       background: 'rgba(0, 0, 0, 0.78)'
  //     });

  //     setFlagInitialZoom(true);
  //   }
  // }

  return (
    <Fragment>
      <div className="product-detail product-image">
        <div className="product-detail-image-wrapper">
          <div
            className="product-detail-image-2"
            style={{
              backgroundImage: `url("${getImagePath(
                oneProductImagePath,
                600,
                600
              )}")`
            }}
          />
          <Link
            to={`/provider/${providerId}`}
            className="product-detail-image-provider"
          >
            <CommonProfilePicture
              profileImgPath={providerProfileImgPath || ''}
              name={providerName}
              isProvider
              size={36}
            />
            <div className="product-detail-provider-link">
              공급자 자세히 보기
            </div>
            <ProviderCrown />
          </Link>
        </div>
        <LimitCountView
          countOfSalesLimit={countOfSalesLimit || 0}
          isShown={!!countOfSalesLimit}
          quantityOnHand={quantityOnHand}
          remainingProductCount={quantityOnHand}
        />
        <div className="recommendbox-wrapper">
          {storeId && storeId !== discovererId && (
            <RecommendEditBox
              storeId={storeId}
              productId={productId}
              profileImgPath={profileImgPath}
              name={storeName}
              createDate={
                storeRecommendation ? storeRecommendation.createDate : 0
              }
              recommendation={oneProductRecommendation || ''}
              isOneStore
              isMyOneProduct={isLoggedIn && user.id === storeId}
              user={user}
              isLoggedIn={isLoggedIn}
              isProviderUser={isProvider}
            />
          )}
          <RecommendBox
            createDate={createDate}
            discovererId={discovererId}
            productId={productId}
            // providerId={providerId}
            profileImgPath={discovererProfileImgPath}
            name={discovererName}
            recommendation={discovererRecommendation}
            user={user}
            isLoggedIn={isLoggedIn}
            // isProvider
            isProviderUser={isProvider}
            isDiscoverer
          />
          <RecommendBox
            createDate={createDate}
            // discovererId={discovererId}
            // productId={productId}
            providerId={providerId}
            profileImgPath={providerProfileImgPath}
            name={providerName}
            recommendation={productDetailInfo && productDetailInfo.introduce}
            user={user}
            isLoggedIn={isLoggedIn}
            isProviderUser={isProvider}
            isProvider
          />
        </div>
        {/* {flagHtmlProductInfo &&  eventComp} */}
        {/* {flagHtmlProductInfo &&
          (htmlProductInfo ? (
            <DompurifyHTML innerHtml={htmlProductInfo} />
          ) : (
            eventComp
          ))} */}
      </div>
    </Fragment>
  );
}

export default withUser(OneProductDetail);
