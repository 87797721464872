import React, { Fragment } from 'react';
import DiscoverList from 'components/discover/DiscoverList';

function MarketDiscoverContainer() {
  return (
    <Fragment>
      <DiscoverList
        title={`소비자가 발굴한\n단골 상품들!`}
        subTitle={`내가 좋아하는 상품을 응원하는 가장 좋은 방법.\n상품이 판매될 때마다 발굴자 인센티브가 주어집니다.`}
        listClassName="market-discover-list"
      />
    </Fragment>
  );
}

export default MarketDiscoverContainer;
