export function getEnvironment() {
  const isDevelop =
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'development';

  return isDevelop;
}
export function isDevelop() {
  // console.log('process.env.REACT_APP_ENV: ', process.env.REACT_APP_ENV);

  const isDevelop =
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'development';

  return isDevelop;
}

export function getHost() {
  const prodHost = 'https://from.kr';
  const devHost = 'https://dcjuhiyeg3mm6.cloudfront.net';
  // const devHost = 'http://dev.from.kr.s3-website.ap-northeast-2.amazonaws.com';
  // const devHost = 'http://localhost:3000';

  const host = isDevelop() ? devHost : prodHost;
  // eslint-disable-next-line no-console
  // console.log({ host });

  return host;
}

// 아이폰 native앱을 위해 아이폰인지 아닌지 체크 하는 함수
export function checkIphone() {
  if (typeof navigator === 'undefined') {
    return false;
  }

  const userAgent = navigator.userAgent.toLowerCase();

  return userAgent.indexOf('1store_iphone') > -1;
}

export function isIPhoneApp() {
  if (typeof navigator === 'undefined') {
    return false;
  }

  const userAgent = navigator.userAgent.toLowerCase();

  return userAgent.indexOf('1store_iphone') > -1;
}

// iPhone X Detection
export function isIPhoneX() {
  if (!navigator.userAgent.match(/iPhone/i)) {
    return false;
  }

  if (typeof window === 'undefined') {
    return false;
  }

  const ratio = window.devicePixelRatio || 1;
  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio
  };
  // iPhone X Detection
  if (screen.width === 1125 && screen.height === 2436) {
    return true;
  }
  return false;
}

// browser가 ie인지 확인
export function isInternetExplorer() {
  const agent = navigator.userAgent.toLowerCase();
  // console.log({ agent });
  return (
    (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) ||
    agent.indexOf('msie') !== -1
  );
}
