import axios from './defaultAxios';

// eslint-disable-next-line
export function postPayment(values, storeId, productId) {
  return axios.post(`/store/${storeId}/${productId}/payment`, values);
}

export function postPaymentWithRedirectUrl(params, storeId, productId) {
  return axios.get(`/store/${storeId}/${productId}/payment/complete`, {
    params
  });
}
