let raven = null;

if (typeof window !== 'undefined') {
  ({ Raven: raven } = window);
}

const sentry = {
  captureException: (error) => {
    if (!raven) {
      return;
    }

    raven.captureException(error);
  }
};

export default sentry;
