import React, { Fragment } from 'react';

// import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import MessageList from 'components/message/MessageList';

function Notify({ match }) {
  return (
    <Fragment>
      {/* <ScrollToTopOnMount /> */}
      {/* <HeaderContainer headerTitle="알림" /> */}
      <MessageList storeId={match.params.storeId} />
    </Fragment>
  );
}

export default Notify;
