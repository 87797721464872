/* eslint-disable no-console */
import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { postPaymentWithRedirectUrl } from 'api/payment';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import { useCartStore, useCartDispatch } from 'components/cart/CartStore';

import {
  useCheckoutStore,
  useCheckoutDispatch
} from 'components/checkout/CheckoutStore';

import useModal from 'hooks/useModal';
import AlertModal from 'components/modal/AlertModal';
import PaymentSuccessModal from './PaymentSuccessModal';
import PaymentLoader from './PaymentLoader';

import { isDevelop, isIPhoneApp } from 'lib/utils/envUtils';

import sentry from 'lib/sentry';

const isDev = isDevelop();
const isIPhone = isIPhoneApp();

function PaymentCompleted({ user, UiAction }) {
  const location = useLocation();
  const history = useHistory();
  // console.log({ location });

  const parsed = queryString.parse(location?.search || '');
  console.log({ parsed });
  const {
    imp_success,
    point_success,
    type,
    product_id,
    buyer_id,
    total,
    imp_uid,
    merchant_uid,
    error_msg,
    product_type
  } = parsed || {};

  const cartDispatch = useCartDispatch();
  const checkoutDispatch = useCheckoutDispatch();

  const {
    message: errorMessage,
    isOpen: isOpenErrorModal,
    handleToggle: toggleErrorModal
  } = useModal();

  const {
    isOpen: isOpenSuccessModal,
    handleToggle: toggleSuccessModal
  } = useModal();

  const [isLoading, setIsLoading] = useState(true);
  const [buyerId, setBuyerId] = useState('');

  const [paymentId, setPaymentId] = useState('');
  const [isGift, setIsGift] = useState(false);

  useEffect(() => {
    UiAction.setHeader('결제완료');
    initialize();
  }, []);

  const initialize = async () => {
    if (!location || !location.search) {
      return;
    }

    try {
      if (imp_success !== 'true' && point_success !== 'true') {
        throw new Error(`imp_success-error - ${parsed.error_msg}`);
      }

      if (imp_success === 'true') {
        const response = await postPaymentWithRedirectUrl(
          {
            imp_uid,
            merchant_uid,
            total
          },
          'storeId',
          'productId'
        );

        if (!response || !response.data || !response.data.success) {
          throw new Error(
            `post payment sucess api Error(PaymentComplete at html5_inicis) merchant_uid: ${parsed.merchant_uid}`
          );
        }

        const { data } = response.data.data;
        // console.log({ data });
        sentry.captureException(`paymentcompleted - ${JSON.stringify(data)}`);
        setBuyerId(data.buyerId || buyer_id); // buyer
        setPaymentId(data.uid || merchant_uid);
      } else {
        setBuyerId(buyer_id); // buyer
        setPaymentId(merchant_uid);
      }
      setIsGift(type === 'gift');

      // console.log('resetting checkout');
      cartDispatch({ type: 'reset' });
      checkoutDispatch({ type: 'reset' });

      if (isIPhone) {
        // eslint-disable-next-line
        // console.log('1store_store - paymentComplete - after');

        // eslint-disable-next-line
        webkit.messageHandlers.hideNavigationBar.postMessage('');
      }

      // eslint-disable-next-line
      console.log(`successmodal open ${total}`);

      if (typeof window !== 'undefined' && !isDev) {
        window.fbq('track', 'Purchase', {
          value: total,
          currency: 'KRW',
          content_ids: product_id,
          content_type: 'product'
        });
      }
      // data;
      setIsLoading(false);
      toggleSuccessModal();
    } catch (e) {
      sentry.captureException(`paymentcompleted - ${JSON.stringify(e)}`);
      console.log({ e });
      let catchErrorMessage =
        '결제 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.';

      if (error_msg !== '') {
        const decodedErrorMessage = decodeURIComponent(error_msg);

        if (decodedErrorMessage.includes('취소')) {
          catchErrorMessage = '결제를 취소 하였습니다.';

          if (isIPhone) {
            // eslint-disable-next-line
            console.log('1store_store - paymentComplete - cancel');

            // eslint-disable-next-line
            webkit.messageHandlers.hideNavigationBar.postMessage('');
          }
        }
      }

      setIsLoading(false);
      toggleErrorModal(catchErrorMessage);
    }
  };

  const closeErrorModal = () => {
    // console.log('In PaymentCompleted');
    // console.log('inside closeErrorModal');

    toggleErrorModal();
    history.replace(`/order/payment`);
  };

  const closeSuccessModal = () => {
    // console.log('In PaymentCompleted');
    // console.log('inside closeSuccessModal');

    toggleSuccessModal();
    history.replace({
      pathname: `/store/${buyerId}`
    });
  };

  return (
    <Fragment>
      <PaymentLoader isLoading={isLoading} isSuccess={!isOpenErrorModal} />
      <AlertModal
        contentText={errorMessage}
        isOpen={isOpenErrorModal}
        onToggle={closeErrorModal}
      />
      <PaymentSuccessModal
        buyerId={buyerId}
        paymentId={paymentId}
        isOpen={isOpenSuccessModal}
        isFortune={product_type === 'fortune'}
        isGift={isGift}
        productName={'상품명'}
        onToggle={closeSuccessModal}
      />
    </Fragment>
  );
}

export default withUi(withUser(PaymentCompleted));
