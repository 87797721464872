import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { usePosts } from 'hooks/usePosts';
import InfiniteList from 'shared/InfiniteList';
import { CardListWrapper } from 'shared/styled/InfiniteListStyle';

import { StyledCardInput, PostProfile } from 'components/Post/PostCardStyle';
import withUser from 'lib/hoc/withUser';
import { getProfileImage } from 'lib/utils/imageUtils';

// import { StyledButton } from 'shared/styled/componentStyle';
import PostView from './PostView';

function PostList({
  storeId: ownerId,
  authorId,
  productId,
  providerId,
  store,
  product,
  provider,
  isLoggedIn,
  user,
  isProvider,
  canAdd,
  itemsPerPage
}) {
  const history = useHistory();
  const query = usePosts({
    ownerId,
    authorId,
    productId,
    providerId,
    itemsPerPage
  });

  // console.log({ authorId });
  // console.log({ providerId });
  // console.log({ ownerId });
  const filterBy =
    authorId || productId
      ? null
      : providerId
      ? (post) => post.authorId !== post.providerId
      : (post) => post.authorId !== post.ownerId;

  const onClick = () =>
    history.push({
      pathname: '/post/edit',
      state: { mode: 'new', store, product, provider }
    });
  // console.log({store})
  return (
    <CardListWrapper>
      {/* <PostForm mode="new" store={store} product={product} /> */}
      {/* <StyledButton onClick={onClick}>응원글 쓰기</StyledButton> */}
      {canAdd && (
        <PostProfile className="list-input-control">
          <img
            className={isProvider ? 'provider' : ''}
            src={getProfileImage(
              isProvider ? user.providerProfileImgPath : user.profileImgPath
            )}
            alt="사용자 이미지"
          />
          <StyledCardInput
            onClick={onClick}
            placeholder={
              productId
                ? '당신의 이야기를 나눠주세요 ...'
                : ownerId
                ? `${store?.userName}님에게 이야기 남기기 ...`
                : `상품 및 배송 문의하기 ...`
            }
          />
        </PostProfile>
      )}
      <InfiniteList
        isOneColumn
        isDisabled={false}
        filterBy={filterBy}
        {...query}
      >
        {(item) => (
          <PostView key={`${item.expId}${item.commentsId}`} post={item} />
        )}
      </InfiniteList>
    </CardListWrapper>
  );
}

export default withUser(PostList);
