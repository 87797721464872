import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
// import DiscoverHistoryContainer from 'containers/users/DiscoverHistoryContainer';
import DiscoverHistoryContainer from 'containers/oneStore/DiscoverHistoryContainer';

function DiscoverHistory({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <DiscoverHistoryContainer storeId={match.params.storeId} />
    </Fragment>
  );
}

export default DiscoverHistory;
