import React from 'react';
import { Link } from 'react-router-dom';

import { postLogOutUser } from 'api/store';
import sentry from 'lib/sentry';
import { clearSession } from 'lib/users/auth';

import './OneStoreSettingMenu.scss';

function OneStoreSettingMenu({ storeId, user }) {
  function handleSignOut() {
    const userAgent = navigator.userAgent.toLowerCase();

    let browserType = 'web';

    if (userAgent.indexOf('1store_iphone') > -1) {
      browserType = 'ios';
    }
    if (userAgent.indexOf('1store_android') > -1) {
      browserType = 'android';
    }

    const params = {
      userType: 'store',
      userId: user.id,
      userAgent: browserType
    };

    try {
      const response = postLogOutUser(params);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`로그아웃실패 - ${JSON.stringify(response)}`);
      }

      clearSession();

      window.location.href = '/';
    } catch (e) {
      sentry.captureException(JSON.stringify(e));

      clearSession();

      window.location.href = '/';
    }
  }

  return (
    <div className="onestore-setting-menu-wrapper">
      <ul>
        {/* <li>
          <Link
            to={{
              pathname: `/store/${storeId}/setting/order`,
              state: { isRefresh: true }
            }}
            className="onestore-setting-menu"
          >
            <img
              src="/images/onestore-setting-delivery-icon.svg"
              alt="주문 조회"
              className="onestore-setting-menu-icon"
            />
            주문 조회
            <img
              src="/images/onestore-setting-link-icon.svg"
              alt="화살표"
              className="onestore-setting-link-icon"
            />
          </Link>
        </li> */}
        <li>
          <Link
            to={`/store/${storeId}/setting/balance`}
            className="onestore-setting-menu"
          >
            <img
              src="/images/onestore-setting-savemoney-icon.svg"
              alt="적립금 현황"
              className="onestore-setting-menu-icon"
            />
            적립금 현황
            <img
              src="/images/onestore-setting-link-icon.svg"
              alt="화살표"
              className="onestore-setting-link-icon"
            />
          </Link>
        </li>
        <li>
          <Link
            to={`/store/${storeId}/discoverhistory`}
            className="onestore-setting-menu"
          >
            <img
              src="/images/onestore-setting-discover-icon.svg"
              alt="나의 발굴함"
              className="onestore-setting-menu-icon"
            />
            나의 발굴함
            <img
              src="/images/onestore-setting-link-icon.svg"
              alt="화살표"
              className="onestore-setting-link-icon"
            />
          </Link>
        </li>
        {/* <li>
          <Link
            to={`/store/${storeId}/bookmark/stores`}
            className="onestore-setting-menu"
          >
            <img
              src="/images/onestore-setting-follow-icon.svg"
              alt="팔로우"
              className="onestore-setting-menu-icon"
            />
            팔로우
            <img
              src="/images/onestore-setting-link-icon.svg"
              alt="화살표"
              className="onestore-setting-link-icon"
            />
          </Link>
        </li> */}
        {/* <li>
          <Link
            to={`/store/${storeId}/setting/edit/profile`}
            className="onestore-setting-menu"
          >
            <img src="/images/member-edit-icon-gray.svg" alt="회원 정보 수정" />
            회원 정보 수정
            <img
              src="/images/onestore-setting-link-icon.svg"
              alt="화살표"
              className="onestore-setting-link-icon"
            />
          </Link>
        </li> */}
        <li>
          <Link to="/service/contact" className="onestore-setting-menu">
            <img
              src="/images/onestore-setting-service-icon.svg"
              alt="고객센터"
              className="onestore-setting-menu-icon"
            />
            고객센터
            <img
              src="/images/onestore-setting-link-icon.svg"
              alt="화살표"
              className="onestore-setting-link-icon"
            />
          </Link>
        </li>
        <li>
          <button className="onestore-setting-menu" onClick={handleSignOut}>
            <img
              src="/images/onestore-setting-logout-icon.svg"
              alt="로그아웃"
              className="onestore-setting-menu-icon"
            />
            로그아웃
            <img
              src="/images/onestore-setting-link-icon.svg"
              alt="화살표"
              className="onestore-setting-link-icon"
            />
          </button>
        </li>
      </ul>
    </div>
  );
}

export default OneStoreSettingMenu;
