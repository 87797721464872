import React, { Component, Fragment } from 'react';
// import { Link } from 'react-router-dom';

import ModalDialog from 'components/modal/ModalDialog';
import CDN_URL from 'components/commons/globalUrl';
// import { getImagePath } from 'lib/utils/imageUtils';

import { execMobileApp } from 'lib/mobileApp';
import Button from 'components/commons/Button';

import './AppCheckModal.scss';

class AppCheckModal extends Component {
  handleAppConnectClick = () => {
    const { onToggle } = this.props;

    execMobileApp();

    onToggle();
  };

  handleWebConnectClick = () => {
    const { onToggle } = this.props;

    onToggle();
  };

  render() {
    const { isOpen, onToggle } = this.props;

    return (
      <Fragment>
        <ModalDialog
          className="app-check-modal"
          backdropClassName="opacity-7"
          size="sm"
          isOpen={isOpen}
          onToggle={onToggle}
        >
          <div className="text-center">
            <img
              src="/images/from-logo-2.svg"
              alt="프롬 로고"
              className="d-block ml-auto mr-auto mb-3"
            />
            <div className="app-check-big-text">
              <span>
                앱에서는 중요한 소식을
                <br /> 알림으로 받아볼 수 있어요!
              </span>
            </div>
            <Button
              buttonText="편리한 앱으로 보기"
              buttonType="button"
              color="from"
              className="app-connect-button"
              onClick={this.handleAppConnectClick}
            />
            <Button
              buttonText="조금 불편하지만 모바일 웹에서 볼래요"
              buttonType="button"
              color="web-connect-button"
              onClick={this.handleWebConnectClick}
            />
          </div>
        </ModalDialog>
      </Fragment>
    );
  }
}

export default AppCheckModal;
