/* eslint-disable no-console */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useInfiniteProducts } from 'hooks/useProducts';
import InfiniteList from 'shared/InfiniteList';

import ListTitle from 'components/commons/ListTitle';
import ProductCard from 'components/commons/ProductCard';

import './ProductList.scss';

function ProductList({
  searchType = 'best',
  searchKey = '',
  itemsPerPage,
  filterBy,
  title,
  subTitle,
  listClassName
}) {
  // console.log({searchType});
  // console.log({searchKey});

  const query = useInfiniteProducts({
    searchType,
    searchKey,
    itemsPerPage
  });

  return (
    <Fragment>
      <ListTitle
        title={title}
        subTitle={subTitle}
        listClassName={listClassName}
      />
      <InfiniteList filterBy={filterBy} {...query}>
        {(item) => (
          <Link key={item.id} to={`/product/${item.id}`}>
            <ProductCard {...item} />
          </Link>
        )}
      </InfiniteList>
    </Fragment>
  );
}
export default ProductList;
