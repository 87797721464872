import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import withUi from 'lib/hoc/withUi';

import ListTitle from 'components/commons/ListTitle';
import OneProductList from 'components/oneProduct/OneProductList';

function ProviderOneProductList({ providerId, UiAction }) {
  const location = useLocation();

  const { providerName } = location.state || {};

  useEffect(() => {
    UiAction.setHeader(
      providerName ? `${providerName}의 상품매장` : '상품매장'
    );
  }, []);

  return (
    <Fragment>
      <ListTitle title="응원하는 상품" subTitle="" listClassName="" />
      <OneProductList searchType="providerId" searchKey={providerId} />
    </Fragment>
  );
}

export default withUi(ProviderOneProductList);
