import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import * as storeApi from 'api/store';

const INTIALIZE_STORE = 'store/INTIALIZE_STORE';
const GET_STORE = 'store/GET_STORE';
const GET_STORE_ONE_EXPERIENCELIST = 'store/GET_STORE_ONE_EXPERIENCE_LIST';
const GET_STORE_ONE_PRODUCT_LIST = 'store/GET_STORE_ONE_PRODUCT_LIST';
const SET_BOOKMARK = 'store/SET_BOOKMARK';

export const initializeStore = createAction(INTIALIZE_STORE);
export const setBookmark = createAction(SET_BOOKMARK);
export const getStore = createAction(GET_STORE, storeApi.getStore);
export const getStoreOneExperienceList = createAction(
  GET_STORE_ONE_EXPERIENCELIST,
  storeApi.getStoreOneExperienceList
);
export const getStoreOneProductList = createAction(
  GET_STORE_ONE_PRODUCT_LIST,
  storeApi.getStoreOneProductsList
);

const initialState = {
  store: null,
  storeOneExperiences: null,
  storeOneProducts: null
};

export default handleActions(
  {
    [INTIALIZE_STORE]: (state) => {
      return {
        ...state,
        store: null,
        storeOneExperiences: null,
        storeOneProducts: null
      };
    },
    [SET_BOOKMARK]: (state, action) => {
      const { store } = state;

      return {
        ...state,
        store: {
          ...store,
          bookmarkedIdList: action.payload
        }
      };
    },
    ...pender({
      type: GET_STORE,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            store: null
          };
        }

        return {
          ...state,
          store: data
        };
      },
      onFailure: (state) => {
        return { ...state, store: null };
      }
    }),
    ...pender({
      type: GET_STORE_ONE_EXPERIENCELIST,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            storeOneExperiences: null
          };
        }

        return {
          ...state,
          storeOneExperiences: data
        };
      },
      onFailure: (state) => {
        return { ...state, storeOneExperiences: null };
      }
    }),
    ...pender({
      type: GET_STORE_ONE_PRODUCT_LIST,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            storeOneProducts: null
          };
        }

        return {
          ...state,
          storeOneProducts: data
        };
      },
      onFailure: (state) => {
        return { ...state, storeOneProducts: null };
      }
    })
  },
  initialState
);
