import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import * as providerApi from 'api/provider';

const INITIALIZE_PROVIDER = 'provider/INITIALIZE_PROVIDER';
const GET_PROVIDER = 'provider/GET_PROVIDER';
const GET_SEARCH_PROVIDER = 'provider/GET_SEARCH_PROVIDER';
const RESET_SEARCH_PROVIDER = 'provider/RESET_SEARCH_PROVIDER';
const SET_PROVIDER_PRODUCT = 'provider/SET_PROVIDER_PRODUCT';
const GET_PROVIDER_CONNECTED_EXPERIENCE =
  'privder/GET_PROVIDER_CONNECTED_EXPERIENCE';
const GET_PROVIDER_ONE_PRODUCT = 'provider/GET_PROVIDER_ONE_PRODUCT';
const GET_PROVIDER_SALES_LIST = 'provider/GET_PROVIDER_SALES_LIST';
const GET_PROVIER_DELIVERY_LIST = 'provider/GET_PROVIER_DELIVERY_LIST';
const SET_BOOKMARK = 'provider/SET_BOOKMARK';

export const initializeProvider = createAction(INITIALIZE_PROVIDER);
export const setBookmark = createAction(SET_BOOKMARK);
export const setProducts = createAction(SET_PROVIDER_PRODUCT);
export const getProvider = createAction(GET_PROVIDER, providerApi.getProvider);
export const getSearchProvider = createAction(
  GET_SEARCH_PROVIDER,
  providerApi.getSearchProvider
);
export const resetSearchProvider = createAction(RESET_SEARCH_PROVIDER);
export const getProviderConnectedExperience = createAction(
  GET_PROVIDER_CONNECTED_EXPERIENCE,
  providerApi.getProviderOneExperience
);
export const getProviderOneProduct = createAction(
  GET_PROVIDER_ONE_PRODUCT,
  providerApi.getProviderOneProduct
);
export const getProviderSalesList = createAction(
  GET_PROVIDER_SALES_LIST,
  providerApi.getProviderSalesList
);
export const getProviderDeliveryList = createAction(
  GET_PROVIER_DELIVERY_LIST,
  providerApi.getProviderDeliveryList
);

const initialState = {
  provider: null,
  products: '',
  searchProvider: null,
  connectedExperience: null,
  providerOneProduct: null,
  providerSales: null,
  providerDelivery: null
};

export default handleActions(
  {
    [INITIALIZE_PROVIDER]: (state) => {
      return {
        ...state,
        provider: null,
        products: '',
        searchProvider: null,
        connectedExperience: null,
        providerOneProduct: null,
        providerSales: null
      };
    },
    [SET_BOOKMARK]: (state, action) => {
      const { provider } = state;

      return {
        ...state,
        provider: {
          ...provider,
          bookmarkedIdList: action.payload
        }
      };
    },
    [SET_PROVIDER_PRODUCT]: (state, action) => {
      return {
        ...state,
        products: action.payload
      };
    },
    [RESET_SEARCH_PROVIDER]: (state) => {
      return {
        ...state,
        searchProvider: null
      };
    },
    ...pender({
      type: GET_PROVIDER,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            provider: null
          };
        }

        return {
          ...state,
          provider: data
        };
      }
    }),
    ...pender({
      type: GET_SEARCH_PROVIDER,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            searchProvider: null
          };
        }

        return {
          ...state,
          searchProvider: data
        };
      }
    }),
    ...pender({
      type: GET_PROVIDER_CONNECTED_EXPERIENCE,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            connectedExperience: null
          };
        }

        return {
          ...state,
          connectedExperience: data
        };
      }
    }),
    ...pender({
      type: GET_PROVIDER_ONE_PRODUCT,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            providerOneProduct: null
          };
        }

        return {
          ...state,
          providerOneProduct: data
        };
      }
    }),
    ...pender({
      type: GET_PROVIDER_SALES_LIST,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            providerSales: null
          };
        }

        return {
          ...state,
          providerSales: data
        };
      }
    }),
    ...pender({
      type: GET_PROVIER_DELIVERY_LIST,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            providerDelivery: null
          };
        }

        return {
          ...state,
          providerDelivery: data
        };
      }
    })
  },
  initialState
);
