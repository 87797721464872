import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change, formValueSelector, getFormSyncErrors } from 'redux-form';
import { compose } from 'redux';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';
import { getQuerys } from 'lib/utils/commonUtils';
import { s3Upload } from 'lib/aws/s3Upload';
import sentry from 'lib/sentry';

import AlertModal from 'components/modal/AlertModal';
import DiscoverForm from 'components/oneStore/DiscoverForm';
import LogoModal from 'components/modal/LogoModal';
import SearchDiscoverModal from 'components/modal/SearchDiscoverModal';
import SearchProviderModal from 'components/modal/SearchProviderModal';
import RedirectToHome from 'components/commons/RedirectToHome';

import { postDiscover } from 'api/discover';
import { getProvider } from 'api/provider';

class DiscoverContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      haveProductImages: true,
      isOpenErrorModal: false,
      isOpenProductModal: false,
      isOpenProviderModal: false,
      isOpenSuccessModal: false,
      isSubmitLoading: false,
      productImageList: []
    };

    this.providerId = null;
  }

  componentDidMount() {
    const { UiAction } = this.props;

    UiAction.setHeader('발굴요청');

    this.getPhotos();
  }

  getPhotos = () => {
    const { location } = this.props;

    if (!location || !location.search) {
      return;
    }

    const querys = getQuerys(location.search);

    if (!querys || !querys.photos) {
      return;
    }

    const { photos } = querys;
    const photoList = photos.split(',');

    if (photoList.length <= 0) {
      return;
    }

    for (let i = 0; i < photoList.length; i++) {
      this.getImageInfo(photoList[i], this.handleAddProductImage);
    }
  };

  getImageInfo = (image, fn) => {
    var imgLoader = new Image(); // create a new image object

    imgLoader.onload = () => {
      // assign onload handler
      const { height, width } = imgLoader;
      const imageObj = {
        height,
        image,
        width
      };

      fn(imageObj);
    };

    imgLoader.src = image; // set the image source
  };

  handleChangeProduct = (productName) => {
    const { changeField } = this.props;

    changeField('productName', productName);

    this.handleCloseModal('isOpenProductModal');
  };

  handleChangeProvider = (providerName) => {
    const { changeField } = this.props;

    changeField('providerName', providerName);

    this.handleCloseModal('isOpenProviderModal');
  };

  handleAddProductImage = (productImage) => {
    const { productImageList } = this.state;

    // react list key 를 설정하기 위해 랜덤으로 id를 만듬
    const uniqueId = Math.random()
      .toString(36)
      .substr(2, 9);

    productImage.id = uniqueId;

    this.setState({
      productImageList: [...productImageList, productImage],
      haveProductImages: true
    });
  };

  // 이미지 제거 함수
  handleRemovePicture = (index) => {
    const { productImageList } = this.state;

    productImageList.splice(index, 1);

    this.setState({
      productImageList,
      haveProductImages: productImageList.length > 0
    });
  };

  // 발굴 등록 취소 함수
  handleBack = () => {
    const { history } = this.props;

    history.goBack(-1);
  };

  handleCheckError = () => {
    const { formError } = this.props;

    if (!formError) {
      return;
    }

    const formErrorArray = Object.entries(formError);

    if (formErrorArray.length > 0) {
      this.openSearchProductModal = false;

      document.getElementsByName(`${formErrorArray[0][0]}`)[0].focus();
    }

    this.openSearchProductModal = true;
  };

  handleSelectProvider = async (providerId) => {
    const { changeField } = this.props;
    try {
      const response = await getProvider(providerId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`공급자 검색 실패 - ${JSON.stringify(response)}`);
      }

      const { data } = response.data;
      const { id, providerName, mobile, picName, picMobile, picEmail } = data;

      this.providerId = id;

      changeField('providerName', providerName);
      changeField('providerTel', mobile);
      changeField('picName', picName);
      changeField('picMobile', picMobile);
      changeField('picEmail', picEmail);
    } catch (e) {
      sentry.capture(e.message);

      this.setState({
        isOpenErrorModal: true,
        errorMessage:
          '공급자 검색에 실패하였습니다.\n잠시 후 다시 시도해주세요.'
      });
    }
  };

  // 발굴 등록 함수
  handleSubmit = (values) => {
    const { productImageList } = this.state;

    const haveProductImages = productImageList.length > 0;
    const pictureInputElement = document.getElementsByClassName('form-image');

    if (!haveProductImages) {
      pictureInputElement[0].scrollIntoView();

      this.setState({
        haveProductImages: false
      });

      return;
    }

    this.setState(
      {
        isSubmitLoading: true
      },
      async () => {
        try {
          const { user } = this.props;
          const { email, id, mobile, profileImgPath, storeNo, userName } = user;

          // 발굴등록에 필요한 값 추가
          values.category = '1';
          values.discovererId = id;
          values.discovererName = userName;
          values.discovererEmail = email;
          values.discovererMobile = mobile;
          values.storeNo = storeNo;
          values.profileImgPath = profileImgPath || {};

          // 발굴드록에 필요한 공급자 데이터 추가
          values.providerId = this.providerId;

          // 상품 이미지 업로드
          const productImgPathList = [];
          const uploadProductImages = [];

          productImageList.forEach((image) => {
            if (!image) {
              return;
            }
            uploadProductImages.push(s3Upload(image.image, 'productImage'));

            productImgPathList.push({
              width: image.width,
              height: image.height
            });
          });

          const uploadProductImgPathList = await Promise.all(
            uploadProductImages
          );

          for (let i = 0; i < uploadProductImgPathList.length; i += 1) {
            productImgPathList[i].path = uploadProductImgPathList[i];
          }

          // 이미지 path값 받아오기
          values.productImgPathList = productImgPathList;

          // 공급자 있을 경우 공급자 데이터 필요

          const response = await postDiscover(values);

          if (!response || !response.data || !response.data.success) {
            throw new Error(
              `post Discover api Error(DiscoverContainer) user: ${JSON.stringify(
                user
              )}`
            );
          }

          this.setState({
            isSubmitLoading: false,
            isOpenSuccessModal: true
          });
        } catch (err) {
          sentry.captureException(err);

          this.setState({
            isSubmitLoading: false,
            isOpenErrorModal: true,
            errorMessage: '발굴 등록 요청 중 에러가 발생했습니다.'
          });
        }
      }
    );
  };

  handleOpenModal = (modalType) => {
    this.setState({
      [modalType]: true
    });
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  handleMoveLink = (path) => {
    this.props.history.push(path);
  };

  render() {
    const { isLoggedIn, user } = this.props;
    const {
      isSubmitLoading,
      isOpenErrorModal,
      isOpenSuccessModal,
      // isOpenDiscoverModal,
      isOpenProductModal,
      isOpenProviderModal,
      // isCheckedNotShowModal,
      errorMessage,
      // hasReceiptImage,
      haveProductImages,
      productImageList
    } = this.state;

    return (
      <Fragment>
        <RedirectToHome isGotoHome={!isLoggedIn} />
        <DiscoverForm
          isSubmitLoading={isSubmitLoading}
          // isOpenDiscoverModal={isOpenDiscoverModal}
          // isCheckedNotShowModal={isCheckedNotShowModal}
          // hasReceiptImage={hasReceiptImage}
          haveProductImages={haveProductImages}
          onOpenProductModal={(e) => {
            this.handleOpenModal('isOpenProductModal');

            e.target.blur();
          }}
          onOpenProviderModal={(e) => {
            this.handleOpenModal('isOpenProviderModal');

            e.target.blur();
          }}
          onSubmit={this.handleSubmit}
          // onSubmitFail={this.handleCheckError}
          onClickBack={this.handleBack}
          // onChangeReceiptImage={this.handleChangeReceiptImage}
          onSelectProductImage={this.handleAddProductImage}
          // onToggleDiscoverModal={this.handleToggleDiscoverModal}
          onRemovePicture={this.handleRemovePicture}
          productImageList={productImageList}
        />

        <SearchDiscoverModal
          isOpen={isOpenProductModal}
          onClickNew={this.handleChangeProduct}
          onToggle={() => {
            this.handleCloseModal('isOpenProductModal');
          }}
        />
        <SearchProviderModal
          isOpen={isOpenProviderModal}
          onClickNew={this.handleChangeProvider}
          onSelect={this.handleSelectProvider}
          onToggle={() => {
            this.handleCloseModal('isOpenProviderModal');
          }}
        />
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
        <LogoModal
          isOpen={isOpenSuccessModal}
          isShownProcessImg
          buttonText="발굴함 가기"
          headerText="발굴 요청 완료"
          onToggle={() => {
            this.props.history.push(`/store/${user.id}/discoverhistory`);
          }}
          onClick={() => {
            this.props.history.push(`/store/${user.id}/discoverhistory`);
          }}
        />
      </Fragment>
    );
  }
}

const selector = formValueSelector('discoverForm');

const mapStateToProps = (state) => {
  return {
    formError: getFormSyncErrors('discoverForm')(state),
    productName: selector(state, 'productName')
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => {
    dispatch(change('discoverForm', field, value));
  }
});

export default compose(
  withUi,
  withUser,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DiscoverContainer);
