import axios from './defaultAxios';

export function getRecentExperiences(params) {
  return axios.get('/1exp', {
    params
  });
}

export function getStoreOneExperienceList(params) {
  return axios.get(`/store/${params.storeId}/1exps`, {
    params
  });
}

export function getProductOneExpereinces(params) {
  return axios.get(`/product/${params.productId}/1exps`, {
    params
  });
}
