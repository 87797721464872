import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'components/ui/Tabs';

function ProviderEditHeader({ currentTab, providerId }) {
  return (
    // <Fragment>
    //   <div className="pwdedit-header-tab ml-auto mr-auto mb-3">
    //     <ul className=" d-flex align-items-center justify-content-center">
    //       <li>
    //         <button
    //           type="button"
    //           onClick={() => {
    //             onClick('profile');
    //           }}
    //           className={`${
    //             currentTab === 'profile' ? 'selected-border' : 'unselect-border'
    //           } `}
    //         >
    //           정보 수정
    //         </button>
    //       </li>
    //       <li>
    //         <button
    //           type="button"
    //           onClick={() => {
    //             onClick('pwd');
    //           }}
    //           className={`${
    //             currentTab === 'pwd' ? 'selected-border' : 'unselect-border'
    //           } `}
    //         >
    //           비밀번호 변경
    //         </button>
    //       </li>
    //     </ul>
    //   </div>
    //   {children}
    // </Fragment>

    <Tabs className="pwdedit-header-tab provider-pwdedit-header-tab">
      <Tab isSelected={currentTab === 'profile'}>
        <Link to={`/provider/${providerId}/setting/edit/profile`}>
          정보 수정
        </Link>
        <div className="tab-bottom-dot-icon" />
      </Tab>
      <Tab isSelected={currentTab === 'pwd'}>
        <Link to={`/provider/${providerId}/setting/edit/pwd`}>
          비밀번호 변경
        </Link>
        <div className="tab-bottom-dot-icon" />
      </Tab>
    </Tabs>
  );
}

export default ProviderEditHeader;
