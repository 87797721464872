/* eslint-disable no-console */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { diffDate } from 'lib/time';
import { truncateString } from 'lib/utils/stringUtils';
import { getImagePath, getProfileImage } from 'lib/utils/imageUtils';
import { getPriceFormat } from 'lib/utils/numberUtils';
import { getPriceStructure } from 'components/checkout/priceUtils';

// import './ItemCard.scss';
import {
  StyledItemImage,
  StyledItemProfile,
  StyledItemText,
  StyledItemStatus,
  StyledItemTitle,
  StyledItemStats,
  StyledItemPrice
} from './ItemCardStyle';

function isNew(date) {
  return date && diffDate(date) <= 14 ? true : false;
}

export function ItemStatusBox({ className = '', status, type = 'product' }) {
  const { flagStopSelling, flagReservation, isSamedayDelivery, isInStock } =
    status || {};
  return (
    <StyledItemStatus className={className}>
      {flagReservation && (
        <div className="status-circle reservation-circle">
          <div className="text-wrapper">
            <div className="text-in-circle">예약</div>
            <div className="text-in-circle mt-1">주문</div>
          </div>
        </div>
      )}
      {isSamedayDelivery && (
        <div className="status-circle sameday-delivery-circle">
          <div className="text-wrapper">
            <div className="text-in-circle">오늘</div>
            <div className="text-in-circle mt-1">배송</div>
          </div>
        </div>
      )}
      {(flagStopSelling === 11 || flagStopSelling === 12) && (
        <div className="status-circle out-of-stock-circle">
          <div className="text-wrapper">
            <div className="text-in-circle">판매</div>
            <div className="text-in-circle mt-1">준비</div>
          </div>
        </div>
      )}
      {type === 'option' && !isInStock && (
        <div className="status-circle out-of-stock-circle">
          <div className="text-wrapper">
            {/* <div className="text-in-circle">일시</div> */}
            <div className="text-in-circle">품절</div>
          </div>
        </div>
      )}
    </StyledItemStatus>
  );
}

export function ItemImageBox({ className = '', imagePath, onClick }) {
  const itemSelected = className.includes('selected');
  const imageSize = className.includes('very-large') ? 400 : 250;
  return (
    <StyledItemImage onClick={onClick}>
      <img
        className={`image ${className}`}
        src={`${getImagePath(imagePath, imageSize, imageSize)}`}
        alt="아이템 이미지"
      />
      {/* <LazyLoadImage
        src={`${getImagePath(imagePath)}`}
        className={`image ${className}`}
        alt="아이템 이미지"
        // placeholderSrc="black-and-white"
      /> */}
      {itemSelected && (
        <img
          className="item-selected-icon"
          src="/images/icon_option_check.svg"
          alt="선택표시"
        />
      )}
    </StyledItemImage>
  );
}

export function ItemProfileBox({
  className = '',
  profileName,
  profileImagePath,
  profileLink
}) {
  return (
    <StyledItemProfile>
      <Link to={profileLink}>
        <LazyLoadImage
          src={getProfileImage(profileImagePath)}
          className={`profile-image ${className}`}
          alt="사용자 이미지"
          // placeholderSrc="black-and-white"
        />
      </Link>
      <div className="profile-name">{profileName}</div>
    </StyledItemProfile>
  );
}

export function ItemTextBox({ className = '', text, onClick }) {
  return (
    <StyledItemText className={className} onClick={onClick}>
      {truncateString(text, 28)}
    </StyledItemText>
  );
}

export function ItemTitleBox({
  className = '',
  title,
  date,
  category,
  onClick
}) {
  const displayTitle = isNew(date)
    ? truncateString(title, 38)
    : truncateString(title, 40);
  const { isCarrige } = category || {};

  return (
    <StyledItemTitle className={className} onClick={onClick}>
      {displayTitle}
      {isNew(date) && (
        <img
          className="title-icon"
          src="/images/icon-product-new2.svg"
          alt="New"
        />
      )}
      {isCarrige && (
        <img className="title-icon" src="/images/icon-reward.svg" alt="Event" />
      )}
    </StyledItemTitle>
  );
}

export function ItemPriceBox({ className = '', options, category = {} }) {
  const optionsOnDisplay =
    options && options.filter((op) => op.isOnDisplay === true);
  // console.log({ optionsOnDisplay });

  const sortedOptions =
    optionsOnDisplay && optionsOnDisplay.sort((a, b) => a.seqNo - b.seqNo);
  // console.log({ sortedOptions });

  const firstOption = sortedOptions ? sortedOptions[0] : {};
  // console.log({ firstOption });

  const priceStructure = getPriceStructure({ option: firstOption });
  // console.log({ priceStructure });
  const { unitPrice, discountPrice, discountPercent, giftPoint } =
    priceStructure || {};

  return (
    <StyledItemPrice className={className}>
      {category && category.isCarrige ? (
        <Fragment>프롬 소식</Fragment>
      ) : (
        <Fragment>
          <div>
            {!isNaN(discountPrice) && (
              <span className={`discount-price ${className}`}>
                {getPriceFormat(discountPrice)}원
              </span>
            )}
            {giftPoint > 0 && (
              <span className={`gift-point ${className}`}>
                {`+ ${getPriceFormat(giftPoint)}원 적립`}
              </span>
            )}
          </div>
          {discountPercent > 0 && (
            <div className={`regular-price ${className}`}>
              {getPriceFormat(unitPrice)}원
            </div>
          )}
        </Fragment>
      )}
    </StyledItemPrice>
  );
}

export function ItemStatsBox({ className = '', stats }) {
  const { numberOfOneProducts, numberOfComments, numberOfFollowers } =
    stats || {};
  return (
    <StyledItemStats className={className}>
      {numberOfOneProducts >= 0 && (
        <div className="stat-with-icon">
          <img src="/images/icon-people-to.svg" alt="응원자 수" />
          <span>{numberOfOneProducts}</span>
        </div>
      )}
      {numberOfComments >= 0 && (
        <div className="stat-with-icon">
          <img src="/images/icon-count-comment.svg" alt="응원글 수" />
          <span>{numberOfComments}</span>
        </div>
      )}
      {numberOfFollowers >= 0 && (
        <div className="stat-with-icon">
          <img src="/images/icon-people-to.svg" alt="팔로우 수" />
          <span>{numberOfFollowers}</span>
        </div>
      )}
    </StyledItemStats>
  );
}
