import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

NormalButton.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func
};

NormalButton.defaultProps = {
  buttonText: '확인',
  className: '',
  color: '',
  onClick: () => {}
};

export default function NormalButton({
  buttonText,
  className,
  color,
  onClick,
  isLoading,
  isDisable
}) {
  return (
    <button
      type="button"
      className={classNames('button normal-button', color, className)}
      onClick={onClick}
      disabled={isDisable || isLoading}
    >
      {isLoading ? (
        <img
          src="/images/button-loader.svg"
          className="loading-spinner"
          alt="로딩 이미지"
        />
      ) : (
        <Fragment>{buttonText}</Fragment>
      )}
    </button>
  );
}
