import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import OneStoreContainer from 'containers/oneStore/OneStoreContainer';

function OneStore({ match }) {
  const storeId = match.params.storeId;
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <OneStoreContainer storeId={storeId} />
    </Fragment>
  );
}

export default OneStore;
