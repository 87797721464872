import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import ProfilePicture from 'components/picture/ProfilePicture';
import SummaryText from 'components/commons/SummaryText';
import FollowButton from 'components/commons/FollowButton';

import { getFormatTimeStempDate } from 'lib/time';

import { getStore } from 'api/store';
import { getProvider } from 'api/provider';
import './RecommendBox.scss';

export default function RecommendBox({
  createDate,
  discovererId,
  providerId,
  name,
  profileImgPath,
  recommendation,
  isProvider,
  isDiscoverer,
  isOneStore,
  user,
  isLoggedIn,
  isProviderUser,
  productStatus,
  className
}) {
  const [showRecommendation, setShowRecommendation] = useState(true);
  const [longRecommendation, setLongRecommendation] = useState(false);
  const [targetInfo, setTargetInfo] = useState({});

  useEffect(() => {
    async function getTargetInfo() {
      let response;

      if (isProvider) {
        if (!providerId) {
          return;
        }

        response = await getProvider(providerId);
      } else {
        if (!discovererId) {
          return;
        }

        response = await getStore(discovererId);
      }

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `추천사 컴포넌트 -  데이터 불러오기 실패: ${JSON.stringify(
            response
          )}}`
        );
      }

      setTargetInfo(response.data.data);
    }

    getTargetInfo();
  }, [discovererId, isProvider, providerId]);

  useEffect(() => {
    if (recommendation && recommendation.length > 50) {
      setShowRecommendation(false);
      setLongRecommendation(true);
    }
  }, [recommendation]);

  const linkPath = isProvider
    ? `/provider/${providerId}`
    : `/store/${discovererId}`;

  const defaultMessage =
    isProvider && productStatus === 'requested'
      ? '공급자님께 발굴 소식을 전하는 중입니다.'
      : '아직 이야기를 남기지 않으셨습니다.';

  const {
    id: targetId,
    bookmarkedIdList,
    storeNo,
    profileImgPath: storeProfileImgPath,
    providerProfileImgPath
  } = targetInfo;

  return (
    <div
      className={classNames(
        'discoverer-recommend-box product-discoverer',
        className
      )}
    >
      <div className="product-discoverer-profile">
        <Link to={linkPath}>
          <ProfilePicture
            isProvider={isProvider}
            isDiscoverer={isDiscoverer}
            isOneStore={isOneStore}
            className={classNames('product-discoverer-image', {
              'profile-provider-image': isProvider
            })}
            profileImgPath={profileImgPath || ''}
            targetInfo={targetInfo}
          />
        </Link>
      </div>
      <div className="product-discoveror-recommend">
        <span className="product-discoverer-label">
          <Link to={linkPath}>
            {isProvider ? '공급자' : isDiscoverer ? '발굴자' : '프롬이'} {name}
          </Link>
          {!isProvider && (
            <span className="product-discoveror-recommend-date">
              {getFormatTimeStempDate(createDate, 'YYYY.MM.DD')}
            </span>
          )}
          {!isProviderUser && (
            <FollowButton
              user={user}
              isLoggedIn={isLoggedIn}
              bookmarkedIdList={bookmarkedIdList}
              targetType={isProvider ? 'PP' : '1S'}
              targetId={targetId}
              targetName={name}
              targetDetailInfo={
                isProvider
                  ? { providerImgPath: providerProfileImgPath }
                  : { storeNo, profileImgPath: storeProfileImgPath }
              }
            />
          )}
        </span>
        <div className="product-discoverer-recommend-messagge">
          <SummaryText
            contentText={recommendation}
            contentTextSlideIndex={47}
            defaultMessage={defaultMessage}
            isLongText={longRecommendation}
            isShownDetail={showRecommendation}
            onClick={() => setShowRecommendation(!showRecommendation)}
          />
        </div>
      </div>
    </div>
  );
}
