const isDev = process.env.NODE_ENV === 'development'; // 환경이 개발모드인지 확인

const fetchData = async (func, params) => {
  try {
    const paramArray = Array.isArray(params) ? [...params] : [params];
    const response = await func(...paramArray);
    if (!response || !response.data || !response.data.success) {
      console.log({ response });
      throw new Error(
        `Function: ${func.name} - Params: ${JSON.stringify(params, null, 2)}`
      );
    }
    if (isDev) {
      console.log(`${func.name} in fetchData`);
      console.log({ func });
      console.log({ params });
      console.log({ response });
    }

    // response.data.data를 return 하면 되지만 data.items가 있는 경우를 대비
    const { data } = response.data;
    const Items = data && (data.items || data.Items || []);

    return { ...data, Items, items: undefined };
  } catch (error) {
    console.log({ error });
    throw error;
  }
};
export default fetchData;
