// eslint-disable-next-line
export function getStatus(status) {
  if (!status) {
    return 'wait';
  }

  status = parseInt(status, 10);

  if (status === 1) {
    return 'audit';
  }

  if (status === 2) {
    return 'success';
  }

  if (status === 3) {
    return 'fail';
  }

  return 'wait';
}

export function getStatusText(status) {
  let statusText = '등록대기';

  if (!status) {
    return statusText;
  }

  status = parseInt(status, 10);

  if (status === 1) {
    statusText = '등록진행 중';
  }

  if (status === 2) {
    statusText = '등록성공';
  }

  if (status === 3) {
    statusText = '등록실패';
  }

  return statusText;
}
