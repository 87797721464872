import styled from 'styled-components';

export const ProductOptionsWrapper = styled.div`
  margin: 16px auto;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 32px;
  padding-bottom: 26px;
  border-top: 0px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);

  .thick-border-label {
    display: inline-block;
    font-weight: 800;
    font-size: 17px;
    color: var(--from-color);
    padding-bottom: 7px;
    margin-left: 5px;
    margin-bottom: 18px;
    border-bottom: 5px solid var(--from-color);
  }

  .product-name {
    font-weight: 800;
    font-size: 22px;
    line-height: 1.3;
    margin-left: 5px;
    margin-bottom: 55px;
  }

  /* same as .slider-container */
  .card-slider-container {
    display: flex;
    margin: 10px auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      height: 5px;
      background-color: var(--gray-200, #f0f0f0);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: var(--gray-300, #cfd1d2);
    }
  }
`;

export const OptionDetailWrapper = styled.div`
  margin-top: 36px;
  padding-left: 20px;
  padding-right: 20px;

  .option-price {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 15px;

    .option-price-label {
      color: #9c9b9b;
    }

    .option-price-value {
      text-align: right;
    }

    &.payment-amount {
      margin-bottom: 8px;

      .option-price-label {
        color: black;
      }

      .option-price-value {
        font-size: 18px;
        font-weight: 800;
      }
    }

    &.incentive-amount {
      .option-price-label,
      .option-price-value {
        font-size: 12px;
        color: var(--from-color);
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
