import React from 'react';
import { ListTitleWrapper, StyledListTitle } from './ListTitleStyle';
// import './ListTitle.scss';

function ListTitle({ title, subTitle, listClassName }) {
  return (
    <ListTitleWrapper>
      {title && (
        <StyledListTitle className={listClassName}>
          {title.split('\n').map((line) => {
            return (
              <span key={line}>
                {line}
                <br />
              </span>
            );
          })}
        </StyledListTitle>
      )}
      {subTitle && (
        <StyledListTitle className={`sub-title ${listClassName}`}>
          {subTitle.split('\n').map((line) => {
            return (
              <span key={line}>
                {line}
                <br />
              </span>
            );
          })}
        </StyledListTitle>
      )}
    </ListTitleWrapper>
  );
}

export default ListTitle;
