import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import OneStoreToListContainer from 'containers/oneStore/OneStoreToListContainer';

function OneStoreToList({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <OneStoreToListContainer storeId={match.params.storeId} />
    </Fragment>
  );
}

export default OneStoreToList;
