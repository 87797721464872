import React, { Fragment, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { useInfiniteMessages } from 'hooks/useMessages';
import InfiniteList from 'shared/InfiniteList';

import { getHost } from 'lib/utils/envUtils';

import withUser from 'lib/hoc/withUser';
import withUi from 'lib/hoc/withUi';

import { fromNow } from 'lib/time';
import { StyledMessageItem } from './MessageStyle';

function MessageItem(message) {
  // console.log({ message });

  const linkUrl = new URL(
    message.action.includes('{{') ? getHost() : message.action
  );
  // console.log({ linkUrl });
  const messagePathName = `${linkUrl.pathname}${
    linkUrl.search ? linkUrl.search : ''
  }`;
  // console.log({ messagePathName });
  return (
    <StyledMessageItem>
      <Link to={`${messagePathName}`}>
        <div className="message-title">{message.subject}</div>
        <div className="message-body">
          {message.content.split('\n').map((line) => {
            return (
              <span key={line}>
                {line}
                <br />
              </span>
            );
          })}
        </div>
        <div className="message-date">{fromNow(message.createDate)}</div>
      </Link>
    </StyledMessageItem>
  );
}

function MessageList({ storeId, UiAction }) {
  // console.log({ storeId });
  const query = useInfiniteMessages({
    storeId
  });

  useEffect(() => {
    UiAction.setHeader('알림');
  }, [storeId]);

  return (
    <Fragment>
      <InfiniteList isOneColumn {...query}>
        {(item) => <MessageItem key={item.id} {...item} />}
      </InfiniteList>
    </Fragment>
  );
}

export default compose(withRouter, withUser, withUi)(MessageList);
