import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import TutorialIntroContainer from 'containers/tutorial/TutorialIntroContainer';

function TutorialIntro() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <TutorialIntroContainer />
    </Fragment>
  );
}

export default TutorialIntro;
