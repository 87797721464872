import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProviderOneProductList from 'containers/provider/ProviderOneProductList';

function ProviderOneProductContainer({ providerId, match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ProviderOneProductList
        providerId={providerId || match.params.providerId}
      />
    </Fragment>
  );
}

export default ProviderOneProductContainer;
