/* eslint-disable react/jsx-indent */
import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import AlertModal from 'components/modal/AlertModal';
import LoginModal from 'components/modal/LoginModal';

import postBookmark from 'api/bookmark/postBookmark'; // (targetType, action, values)

import sentry from 'lib/sentry';

import './FollowButton.scss';

function FollowButton({
  location,
  bookmarkedIdList,
  user,
  isLoggedIn,
  targetType,
  targetId, // storeId || providerId
  targetName, // storeName || providerName
  targetDetailInfo // {storeNo,profileImgPath} || {providerImgPath}
}) {
  const [isBookmarked, setIsBookmaked] = useState(false);
  const [isBookmarkLoading, setIsBookmarkLoading] = useState(false);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [bookmarkList, setBookmarkList] = useState(bookmarkedIdList);

  useEffect(() => {
    function getBookmarkInfo() {
      let bookmarkedIndex = -1;
      let isHavebookmarkedIdList = false;

      if (Array.isArray(bookmarkedIdList)) {
        isHavebookmarkedIdList = true;
      }

      if (isHavebookmarkedIdList) {
        bookmarkedIndex = bookmarkedIdList.findIndex((bookmarkItem) => {
          if (!user || !user.id) {
            return false;
          }

          return bookmarkItem.storeId === user.id;
        });
      }

      setIsBookmaked(bookmarkedIndex > -1);
    }

    getBookmarkInfo();
    setBookmarkList(bookmarkedIdList);
  }, [bookmarkedIdList, user]);

  function handleClickBookmark() {
    if (!targetId || targetId === 'NOT_ASSIGN') return; // 에러모달 추가해야함

    const havebookmarkList = bookmarkList && bookmarkList.length > 0;

    const bookId = havebookmarkList
      ? bookmarkList.find((k) => k.storeId === user.id)
      : '';

    const bookIndex = havebookmarkList
      ? bookmarkList.findIndex((k) => k.storeId === user.id)
      : -1;

    setIsBookmarkLoading(true);

    try {
      if (!isLoggedIn || !user) {
        setIsOpenLoginModal(true);
        setIsBookmarkLoading(false);

        return;
      }

      const values = {};

      if (isBookmarked) {
        // remove
        //
        // 북마크 제거함수
        if (!bookId) {
          setIsBookmarkLoading(false);

          return;
        }

        values.actorStoreId = user.id;
        values.targetId = targetId;
        values.bookmarkId = bookId.bookmarkId;

        postBookmark(targetType, 'remove', values).then(() => {
          setIsBookmarkLoading(false);
          setIsBookmaked(!isBookmarked);
          setBookmarkList(bookmarkList.filter((x, i) => i !== bookIndex));
        });
      } else {
        // add
        //
        // 북마크 추가함수

        values.actorStoreId = user.id;
        values.targetId = targetId;
        values.actorStoreName = user.userName;
        values.actorProfileImgPath = user.profileImgPath;
        values.targetName = targetName;
        values.targetDetailInfo = targetDetailInfo;

        postBookmark(targetType, 'add', values).then((res) => {
          setIsBookmarkLoading(false);
          setIsBookmaked(!isBookmarked);
          setBookmarkList([
            ...(!!bookmarkList && bookmarkList),
            { storeId: user.id, bookmarkId: res.bookmarkId }
          ]);
        });
      }
    } catch (e) {
      sentry.captureException(e);

      setErrorMessage('북마크 작업중 에러가 발생했습니다.');
      setIsBookmarkLoading(false);
      setIsOpenErrorModal(true);
    }
  }

  return (
    <Fragment>
      <div className="follow-button-wrapper">
        {isBookmarkLoading ? (
          <img
            src="/images/bookmark-loader.svg"
            className="loading-spinner"
            alt="로딩 이미지"
          />
        ) : (
          <div>
            <button
              type="button"
              className={`follow-button ${isBookmarked ? 'active' : ''} ${
                targetType === 'PP' ? 'follow-provider-color' : ''
              }`}
              onClick={() => handleClickBookmark()}
            >
              {isBookmarked ? '팔로잉' : '팔로우'}
            </button>
          </div>
        )}
      </div>
      <AlertModal
        isOpen={isOpenErrorModal}
        contentText={errorMessage}
        onToggle={() => {
          setIsOpenLoginModal(!isOpenErrorModal);
        }}
      />
      <LoginModal
        contentText="북마크를 등록하려면 로그인 해야 합니다."
        headerImage="/images/header-image/alert-modal-header-image-minae.svg"
        isOpen={isOpenLoginModal}
        redirectUrl={`${location.pathname}${location.search}`}
        onToggle={() => {
          setIsOpenLoginModal(!isOpenLoginModal);
        }}
      />
    </Fragment>
  );
}

export default withRouter(FollowButton);
