import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import withUi from 'lib/hoc/withUi';
import EmptyList from 'components/commons/EmptyList/EmptyList';
import sentry from 'lib/sentry';

import * as providerActions from 'modules/provider';

import './ProviderLeavePage.scss';

class ProviderLeavePage extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentDidMount() {
    const { UiAction } = this.props;

    this.getProvider();
    UiAction.setHeader('탈퇴 공급자');
    this.initialize();
  }

  componentWillUnmount = () => {
    const { ProviderAction } = this.props;

    ProviderAction.initializeProvider();
  };

  getProvider = async () => {
    const { providerId, ProviderAction } = this.props;

    try {
      const response = await ProviderAction.getProvider(providerId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `get Provider api Error(ProviderInfo) - providerId: ${providerId}`
        );
      }
    } catch (e) {
      sentry.captureException(e);
    }
  };

  initialize() {
    const { location, providerId, history } = this.props;

    // 히스토리 스테이트 값 확인후 pp페이지에서 온게 아니면 pp페이지로 리턴
    if (location.state && location.state.from) {
      return;
    } else if (location.state && location.state.id !== providerId) {
      history.push(`/provider/${location.state.id}`);
    } else {
      history.push(`/provider/${providerId}`);
    }
  }

  render() {
    const { provider } = this.props;

    return (
      <div className="provider-leave-page">
        <EmptyList
          contentText="회원탈퇴한 공급자입니다"
          subText={`공급자명 : ${provider ? provider.providerName : ''}`}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ provider }) => ({
  provider: provider.provider
});

const mapDispatchToProps = (dispatch) => ({
  ProviderAction: bindActionCreators(providerActions, dispatch)
});

export default compose(
  withUi,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProviderLeavePage);
