import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { getProfileImage } from 'lib/utils/imageUtils';

import './ProfilePicture.scss';

function ProfilePicture({
  className,
  isDiscoverer,
  profileImgPath,
  isProvider,
  isOneStore
}) {
  const isSSr = typeof window === 'undefined';

  if (isSSr) {
    return null;
  }

  return (
    <LazyLoadComponent visibleByDefault>
      <div className={classNames('profile-picture', className)}>
        {isDiscoverer && (
          <img
            // src="/images/little-discoverer-crown.svg"
            src="/images/discoverer-crown-bd.svg"
            className="profile-picture-crown"
            alt="발굴자"
          />
        )}
        {isProvider && (
          <img
            src="/images/provider-crown.svg"
            className="profile-picture-crown profile-provider-crown"
            alt="공급자"
          />
        )}
        {isOneStore && (
          <img
            src="/images/onestore-crown.svg"
            className="profile-picture-crown profile-onestore-crown"
            alt="응원자"
          />
        )}
        <div
          className="profile-picture-image"
          style={{
            backgroundImage: `url("${getProfileImage(profileImgPath)}")`
          }}
        />
      </div>
    </LazyLoadComponent>
  );
}

ProfilePicture.propTypes = {
  className: PropTypes.string,
  isDiscoverer: PropTypes.bool,
  profileImgPath: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

ProfilePicture.defaultProps = {
  className: '',
  isDiscoverer: false,
  profileImgPath: {} || ''
};

export default ProfilePicture;
