import React from 'react';
import PropTypes from 'prop-types';
// import ModalDialog from 'components/modal/ModalDialog';
import CommonModal from 'components/modal/CommonModal';

import './AgreementModal.scss';

function AgreementModal({ isOpen, onToggle, agreeUrl }) {
  return (
    <CommonModal size="lg" isOpen={isOpen} onToggle={onToggle}>
      <div className="agreement-form-text mt-3 mb-2">
        <iframe title="이용약관 동의" src={agreeUrl} frameBorder="0" />
      </div>
    </CommonModal>
  );
}

AgreementModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func
};

AgreementModal.defaultProps = {
  isOpen: false,
  onToggle: () => {}
};

export default AgreementModal;
