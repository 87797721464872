import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { getPriceFormat } from 'lib/utils/numberUtils';

import { Input } from 'shared/components';

import { SubFormWrapper, StyledSubTitle } from 'shared/styled/componentStyle';

import { StyledLineItem } from './PaymentStyle';
import { Fragment } from 'react';

function GiftPointSubForm({
  isLoggedIn,
  totalAmount,
  giftPointBalance = 0,
  giftPointUsedWatch = 0
}) {
  const {
    register,
    control,
    errors,
    watch,
    setValue,
    getValues,
    className
  } = useFormContext();

  useEffect(() => {
    // console.log({ giftPointUsedWatch });
    const value = parseInt(giftPointUsedWatch, 10);
    // console.log({ value });
    setValue(
      'giftPointUsed',
      Math.min(Number.isNaN(value) ? 0 : value, giftPointBalance, totalAmount)
    );
  }, [giftPointUsedWatch]);

  const onUseAllPoint = () => {
    setValue('giftPointUsed', Math.min(giftPointBalance, totalAmount));
  };

  return (
    <SubFormWrapper>
      {isLoggedIn && (
        <Fragment>
          <StyledSubTitle>적립금 사용</StyledSubTitle>
          <StyledLineItem>
            <div className="label">나의 적립금</div>
            <div className="value">
              {getPriceFormat(Math.floor(giftPointBalance || 0))}원
            </div>
          </StyledLineItem>
          <Input
            className={`${className} gift-point`}
            type="number"
            name="giftPointUsed"
            label="사용할 적립금"
            buttonLabel="전액사용"
            onButtonClick={onUseAllPoint}
            disabled={!isLoggedIn}
            {...{ register, control, errors }}
          />
        </Fragment>
      )}
      <StyledLineItem className="total">
        <div className="label">결제할 금액</div>
        <div className="value">
          {getPriceFormat(totalAmount - giftPointUsedWatch)}원
        </div>
      </StyledLineItem>
    </SubFormWrapper>
  );
}

export default GiftPointSubForm;
