import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import withUser from 'lib/hoc/withUser';
import withUi from 'lib/hoc/withUi';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { getImagePath } from 'lib/utils/imageUtils';
import { isIPhoneX } from 'lib/utils/envUtils';

import DiscoverModal from 'components/modal/DiscoverModal';
import useDiscoverModal from 'hooks/useDiscoverModal';

import FooterItems from './FooterItems';
import ProviderFooterItems from './ProviderFooterItems';
import { FooterWrapper } from './FooterStyle';

const isUp = isIPhoneX();

function FooterContainer({ user, isLoggedIn, isProvider, showHeader }) {
  // console.log({ user });
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState('market');
  const {
    isOpenDiscoverModal,
    toggleDiscoverModal,
    handleGotoDiscoverPage
  } = useDiscoverModal({
    isLoggedIn,
    isProvider,
    history
  });

  useEffect(() => {
    const { pathname } = history.location;

    let selectedTab = '';

    if (isProvider) {
      if (pathname.search('/product') >= 0) {
        selectedTab = 'product';
      } else if (pathname.search('/market') >= 0) {
        selectedTab = 'market';
      } else if (pathname.search('/saleslist') >= 0) {
        selectedTab = 'sales';
      } else if (pathname.search('/delivery') >= 0) {
        selectedTab = 'delivery';
        // } else if (pathname.search('/setting') >= 0) {
        //   selectedTab = 'setting';
      } else {
        selectedTab = 'provider';
      }

      setSelectedTab(selectedTab);
    }

    if (pathname.search('/discover/register') >= 0) {
      selectedTab = 'discover';
    } else if (pathname.search('/notification') >= 0) {
      selectedTab = 'notification';
    } else if (pathname.search('/gift') >= 0) {
      selectedTab = 'gift';
    } else if (pathname.search('/market') >= 0) {
      selectedTab = 'market';
    } else if (
      pathname.search('/store/s') >= 0 ||
      pathname.search('/user/s') >= 0
    ) {
      selectedTab = 'store';
    }

    setSelectedTab(selectedTab);
  }, [history.location, isProvider]);

  return (
    <FooterWrapper
      className={`footer-class ${!showHeader ? 'hidden-footer' : ''} ${
        isUp ? 'footer-x' : ''
      }`}
    >
      {isProvider ? (
        <ProviderFooterItems
          providerId={user?.id || ''}
          isLoggedIn={isLoggedIn}
          isProvider
          selectedTab={selectedTab}
          providerImage={
            user && user.providerProfileImgPath
              ? getImagePath(user.providerProfileImgPath)
              : '/images/default-profile.svg'
          }
        />
      ) : (
        <FooterItems
          storeId={user?.id || ''}
          isLoggedIn={isLoggedIn}
          storeImage={user?.profileImgPath || ''}
          selectedTab={selectedTab}
          onOpenDiscoverModal={toggleDiscoverModal}
        />
      )}

      <DiscoverModal
        isOpen={isOpenDiscoverModal}
        onToggle={toggleDiscoverModal}
        onRedirect={handleGotoDiscoverPage}
      />
    </FooterWrapper>
  );
}

export default compose(withUser, withUi)(FooterContainer);
