import React, { Component, Fragment } from 'react';

import { getQuerys } from 'lib/utils/commonUtils';
import sentry from 'lib/sentry';
import { signinWithKakao } from 'api/store';

import AlertModal from 'components/modal/AlertModal';
import KakaoAuthButton from 'components/users/KakaoAuthButton';
import KakaoLoading from 'components/users/KakaoLoading';
import { saveSession } from 'lib/users/auth';
import { handleKakaoLogin } from 'lib/kakao/getKakaoAuth';
import { getHost } from 'lib/utils/envUtils';

class KakaoAuthContainer extends Component {
  constructor() {
    super();

    this.state = {
      errorMessage: '',
      isLoading: false,
      isOpenErrorModal: false
    };

    // 로그인 후 리다이렉트 할 url
    this.redirectUrl = '';
    // 카카오 정보
    this.kakaoInfo = null;
    this.kakaoToken = '';
  }

  componentDidMount = () => {
    this.checkKakaoCode();
  };

  signInWithKakao = async (querys) => {
    try {
      const loginValue = {
        loginType: 'kakao',
        snsId: this.kakaoInfo.snsId.toString(),
        snsToken: this.kakaoToken
      };
      // console.log({ loginValue });
      const { userAction } = this.props;
      const response = await userAction.signIn(loginValue);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`카카오 로그인 실패 - ${JSON.stringify(response)}`);
      }

      // 로컬 스토리지에 로그인한 유저 정보 저장
      saveSession(response.data);

      // mobile callback
      const { userInfo } = response.data;
      const userAgent = navigator.userAgent.toLowerCase();
      // 아이폰으로 로그인 시 로그인한 사용자의 id를 webkit으로 전달
      if (userAgent.indexOf('1store_iphone') > -1) {
        const loginUserInfo = {
          userId: userInfo.id,
          userType: 'store'
        };
        // eslint-disable-next-line
        webkit.messageHandlers.userLogIn.postMessage(
          JSON.stringify(loginUserInfo)
        );
      } else if (userAgent.indexOf('1store_android') > -1) {
        const loginUserInfo = {
          userId: userInfo.id,
          userType: 'store'
        };
        // eslint-disable-next-line
        __1STORE_ANDROID.userLogIn(JSON.stringify(loginUserInfo));
      }

      // eslint-disable-next-line
      console.log(`redirectPage : ${JSON.stringify(querys)}`);

      // 로그인 후 전 페이지 또는 1S로 리다이렉트 시켜야함
      this.redirectPage(querys);
    } catch (e) {
      sentry.captureException(e.message);
    }
  };

  checkKakaoCode = async () => {
    const { location } = this.props;

    // console.log({ location });

    if (!location || !location.search) {
      return;
    }

    const querys = getQuerys(location.search);
    // eslint-disable-next-line
    // console.log(`querys : ${JSON.stringify(querys)}`);
    // eslint-disable-next-line
    // console.log(`querys code: ${querys.code}`);
    if (!querys.code) {
      return;
    }

    this.setState({
      isLoading: true
    });

    // add 19.10.04, by gjahn : for native kakao login
    let nativeLoginInfo = null;
    // eslint-disable-next-line
    // console.log(`querys nativeLoginInfo:`);
    if (querys.nativeLoginInfo) {
      nativeLoginInfo = querys.nativeLoginInfo;
    }
    // eslint-disable-next-line
    // console.log(
    //   `querys nativeLoginInfo: ${decodeURIComponent(nativeLoginInfo)}`
    // );
    try {
      const response = await signinWithKakao({
        code: querys.code,
        nativeLoginInfo: nativeLoginInfo,
        baseUrl: getHost()
      });

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `카카오 로그인 검증 실패 - ${JSON.stringify(response)}`
        );
      }

      // eslint-disable-next-line
      // console.log(
      //   `after kakao login response: ${JSON.stringify(response.data)}`
      // );

      const { data, flagExist } = response.data;
      const { email, id, profile_image } = data;

      this.kakaoInfo = {
        email,
        profileImgPath: null,
        snsId: id,
        snsType: 'kakao'
      };

      if (profile_image) {
        this.kakaoInfo.profileImgPath = {
          width: 640,
          height: 640,
          path: profile_image
        };
      }

      // eslint-disable-next-line
      console.log(`after kakao flagExist: ${flagExist}`);

      // 이미 가입한 사람이라면 카카오 계정으로 로그인을 시도한다
      if (flagExist === true) {
        this.signInWithKakao(querys);

        return;
      }

      this.redirectPage(querys);
    } catch (e) {
      sentry.captureException(e.message);

      this.setState({
        errorMessage:
          '카카오 연결에 실패하였습니다.\n 잠시 후 다시 시도해주세요.',
        isLoading: false
      });
    }
  };

  redirectPage = (querys) => {
    if (!querys) {
      return;
    }

    const { history } = this.props;
    let { state } = querys;

    if (!state) {
      return;
    }

    state = decodeURIComponent(state);
    const redirectValue = JSON.parse(state);
    // eslint-disable-next-line
    console.log(`redirectPage : ${JSON.stringify(redirectValue)}`);

    const { url, type } = redirectValue;
    const { isLoggedIn } = this.props;

    // 구매, 선물, 선물 받기에서 카카오톡 로그인으로 넘어왔을 시
    if (type === 'kakao') {
      history.replace({
        pathname: url,
        state: {
          userInfo: this.kakaoInfo
        }
      });

      return;
    }

    if (type === '1store') {
      if (isLoggedIn) {
        this.redirectLoggedIn(url);

        return;
      }

      history.replace({
        pathname: '/store/signup/kakao',
        state: {
          userInfo: this.kakaoInfo,
          redirectUrl: url
        }
      });

      return;
    }

    // 로그인 되어 있을 땐 무조건 전 페이지로 돌아감
    this.redirectLoggedIn(url);
  };

  redirectLoggedIn = (url) => {
    const { history, isLoggedIn, user } = this.props;

    if (!isLoggedIn) {
      return;
    }

    let redirectUrl = url;

    if (redirectUrl.indexOf('$storeId') > -1) {
      redirectUrl = redirectUrl.replace('$storeId', user.id);
    }

    history.replace(redirectUrl);
  };

  handleCloseModal = () => {
    this.setState({
      isOpenErrorModal: false
    });
  };

  handleKakaoButton = (url) => {
    // eslint-disable-next-line
    console.log(`handleKakaoButton url : ${url}`);
    handleKakaoLogin(url);
  };

  render() {
    const { location } = this.props;
    const { errorMessage, isLoading, isOpenErrorModal } = this.state;
    return (
      <Fragment>
        <KakaoAuthButton
          redirectUrl={
            location && location.state ? location.state.redirectUrl : ''
          }
          onClick={this.handleKakaoButton}
        />
        <KakaoLoading isLoading={isLoading} />
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={this.handleCloseModal}
        />
      </Fragment>
    );
  }
}

export default KakaoAuthContainer;
