import React, { Fragment } from 'react';

// import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import MarketContainer from 'containers/home/MarketContainer';

function Market() {
  return (
    <Fragment>
      {/* <ScrollToTopOnMount /> */}
      <MarketContainer />
    </Fragment>
  );
}

export default Market;
