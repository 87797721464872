import axios from 'api/defaultAxios';

export default (id, allVisbleOption) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/product/${id}`)
      .then((res) => {
        if (!res.data.success) {
          res.errorMessage = '정보 처리 중 오류가 발생했습니다.';
          reject(res);

          return;
        }

        if (allVisbleOption) {
          res.allVisbleOption = true;
          // eslint-disable-next-line
          // console.log(`allVisbleOption`);
          // eslint-disable-next-line
          // console.log(allVisbleOption);
        }

        resolve(res);
      })
      .catch((err) => {
        err.errorMessage = '정보 처리 중 오류가 발생했습니다.';
        reject(err);
      });
  });
};
