import React from 'react';
import { Link } from 'react-router-dom';

import './TutorialIntroForm.scss';

function TutorialIntroForm() {
  return (
    <div className="tutorial-intro-form ml-auto mr-auto">
      <div className="tutorial-intro-text text-center mt-4 mb-5">
        <div>
          회원가입이 완료되었습니다.
          <br />
          프롬 이용을 위한
          <br />
          간단한 튜토리얼 체험을 해보세요.
        </div>
      </div>
      <div className="text-center mt-3 mb-4">
        <Link
          to="/tutorial"
          className="btn btn-primary tutorial-intro-btn mr-3"
        >
          튜토리얼 보기
        </Link>
        <Link to="/store/signin" className="btn btn-secondary text-white">
          로그인 하러 가기
        </Link>
      </div>
    </div>
  );
}

export default TutorialIntroForm;
