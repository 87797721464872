export default (link) => {
  if (typeof window === 'undefined' || typeof window.FB === 'undefined') {
    return;
  }

  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent === 'undefined'
  ) {
    return;
  }

  const fbLinkObj = {
    method: 'share',
    display: 'popup',
    href: link
  };

  const userAgent = navigator.userAgent.toLowerCase();

  // OS App
  if (userAgent.indexOf('1store_iphone') > -1) {
    fbLinkObj.snsType = 'facebook';

    // eslint-disable-next-line
    webkit.messageHandlers.shareSNS.postMessage(JSON.stringify(fbLinkObj));

    return;
  }

  // Android App
  if (userAgent.indexOf('1store_android') > -1) {
    fbLinkObj.snsType = 'facebook';
    // eslint-disable-next-line
    __1STORE_ANDROID.shareSNS(JSON.stringify(fbLinkObj));
    return;
  }

  window.FB.ui(fbLinkObj);
};
