/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector, change } from 'redux-form';

import AlertModal from 'components/modal/AlertModal';
import LogoModal from 'components/modal/LogoModal';
// import RedirectToHome from 'components/commons/RedirectToHome';
import RegisterProductForm from 'components/admin/RegisterProductForm';
import SearchProductModal from 'components/modal/SearchProductModal';
import { getDiscover, putDiscoverStatus } from 'api/discover';
import { getProduct, registerProduct, putProduct } from 'api/product';

import { getImageFile } from 'lib/utils/imageUtils';
import {
  getIncentivePercentObject,
  getIncentivePointObject
} from 'lib/utils/newPriceUtils';
import { s3Upload } from 'lib/aws/s3Upload';
import device from 'lib/device';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

class RegisterProductContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // 인센티브 관련
      defaultPrice: 0,

      // ui
      errorMessage: '',
      hasOfficialProductImage: true,
      isEdit: false,
      isEditIncentive: true,
      isOpenErrorModal: false,
      isOpenSuccessModal: false,
      isOpenProductModal: false,
      isSubmitLoading: false,
      officialProductImage: null
    };

    this.discoverData = null;
    this.responseData = null; // 등록후 리턴 받는 상품 id 데이터
    this.initHtmlProductInfo = '';
    this.userInfo = {
      id: ''
    };
  }

  componentDidMount = () => {
    this.initialize();
  };

  getDiscoverInfo = async (discoverId, isChangeName) => {
    const { changeField } = this.props;
    const response = await getDiscover(discoverId);

    try {
      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `상품 수정에서 발굴 정보 가져오기 실패 - ${JSON.stringify}`
        );
      }

      this.discoverData = response.data.data;

      if (isChangeName) {
        changeField('productName', this.discoverData.productName);
      }

      this.setState({
        isEditIncentive: this.discoverData.nowStatusProcess !== 2
      });
    } catch (e) {
      this.setState({
        errorMessage:
          '발굴 데이터를 가져오던 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true
      });
    }
  };

  getProductInfo = async () => {
    const { changeField, discoverId: productId, UiAction } = this.props;
    let defaultPrice = 0;

    if (!productId) {
      return;
    }

    // 상품 수정을 위해 상품 정보를 가지고옴
    const response = await getProduct(productId);

    if (!response || !response.data || !response.data.success) {
      throw new Error(
        `상품 수정에서 상품 정보 가져오기 실패 - ${JSON.stringify}`
      );
    }

    const { data } = response.data;
    const {
      basicDeliveryFee,
      flagDelivery,
      flagTaxFree,
      officialProductImgPath,
      productDetailInfo,
      productName,
      discoverId
    } = data;

    this.productData = data;

    let { defaultRate } = data;
    // defaultRate 소숫점 변환
    defaultRate = getIncentivePercentObject(defaultRate);
    //
    //

    let stringFlagTaxFree = '0';
    let stringFlagDelivery = '1';
    let stringBasicDeliveryFee = '0';

    if (flagTaxFree === 0 || flagTaxFree) {
      stringFlagTaxFree = flagTaxFree.toString();
    }

    if (flagDelivery === 0 || flagDelivery) {
      stringFlagDelivery = flagDelivery.toString();
    }

    if (basicDeliveryFee) {
      stringBasicDeliveryFee = basicDeliveryFee.toString();
    }

    UiAction.setHeader(productName);

    // eslint-disable-next-line
    changeField('flagTaxFree', stringFlagTaxFree);
    changeField('flagDelivery', stringFlagDelivery);
    changeField('basicDeliveryFee', stringBasicDeliveryFee);
    changeField('flagNew', false);
    changeField('productDetailInfo', productDetailInfo);
    changeField('productName', productName);
    changeField('defaultRate', defaultRate);

    // // 상품 수정을 위해 발굴 정보를 가지고 옴
    // const { location } = this.props;
    // const querys = getQuerys(location.search);

    if (discoverId) {
      this.getDiscoverInfo(discoverId, false);
    }

    this.setState({
      defaultPrice,
      officialProductImage: officialProductImgPath,
      isEditIncentive: data.hasOwnProperty('1pCount')
    });
  };

  getProviderInfo = () => {
    const { user } = this.props;

    if (!user) {
      return;
    }

    this.userInfo = user;
  };

  initialize = () => {
    const { discoverId: productId, UiAction } = this.props;

    if (!productId) {
      return;
    }

    const firstSpell = productId[0];

    // id의 첫번째 글짜가 p이면 상품 수정으로 간주한다.
    if (firstSpell === 'p') {
      UiAction.setHeader('공급자 - 상품수정');

      this.getProductInfo();

      this.setState({
        isEdit: true
      });

      return;
    }

    UiAction.setHeader('공급자 - 상품등록');

    this.getDiscoverInfo(productId, true);
  };

  // 이벤트 함수
  handleChangeOfficialProductImage = (image) => {
    // console.log('RegisterProductContainer onChangeOfficialProductImage');
    // console.log({ image });
    this.setState({
      officialProductImage: image
    });
  };

  handleChangeProduct = async (type, productId) => {
    const { changeField } = this.props;

    if (type === 'name') {
      changeField('productName', productId);

      return;
    }

    try {
      const response = await getProduct(productId);

      if (!response || !response.data || !response.data.success) {
        throw new Error();
      }

      const { data } = response.data;

      changeField('productName', data.productName);
    } catch (e) {
      this.setState({
        errorMessage:
          '상품 데이터를 가져오던 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true
      });
    }
  };

  // handleChangePrice = () => {
  //   const { priceList } = this.props;

  //   if (!priceList || priceList.length <= 0) {
  //     return;
  //   }

  //   let defaultPrice = priceList[0].price.replace(/,/g, '');
  //   defaultPrice = parseInt(defaultPrice, 10);

  //   this.setState({
  //     defaultPrice
  //   });
  // };

  handleClickNew = (fieldName, name) => {
    const { changeField } = this.props;

    changeField(fieldName, name);
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  handleOpenModal = (modalType) => {
    this.setState({
      [modalType]: true
    });
  };

  handleRegisterProduct = async (values) => {
    let response = null;
    const { discoverId, user } = this.props;
    const {
      // hasOfficialProductImage,
      isEdit,
      isSubmitLoading,
      officialProductImage
    } = this.state;

    if (isSubmitLoading /* || !hasOfficialProductImage */) {
      return;
    }

    const hasImage = !!officialProductImage;

    if (!hasImage) {
      this.setState({
        hasOfficialProductImage: hasImage
      });

      return;
    }

    this.setState({
      isSubmitLoading: true
    });

    try {
      const copyValue = {
        ...this.productData,
        ...values
      };
      const {
        basicDeliveryFee,
        flagDelivery,
        flagTaxFree,
        defaultRate
      } = values;

      const officialProductImgPath = await s3Upload(
        getImageFile(officialProductImage),
        'officialProductImage'
      );

      // // eslint-disable-next-line
      // console.log(`values`);
      // // eslint-disable-next-line
      // console.log(values);
      // // eslint-disable-next-line
      // console.log(`this.productData`);
      // // eslint-disable-next-line
      // console.log(this.productData);
      // // eslint-disable-next-line
      // console.log(`this.discoverData`);
      // // eslint-disable-next-line
      // console.log(this.discoverData);

      /* 상품 등록을 위한 데이터 가공 */

      // 필수값
      copyValue.category = 1;
      copyValue.flagFromProvider = 1;

      const numberFlagDelivery = parseInt(flagDelivery, 10);

      // string 형식 int로 형변환
      copyValue.flagDelivery = numberFlagDelivery;
      copyValue.basicDeliveryFee = 0;
      copyValue.flagTaxFree = parseInt(flagTaxFree, 10);

      if (numberFlagDelivery === 1) {
        const numberDeliveryFee = basicDeliveryFee.replace(/,/g, '');

        copyValue.basicDeliveryFee = parseInt(numberDeliveryFee, 10);
      }

      // 공식 상품 이미지 정보 생성
      copyValue.officialProductImgPath = {
        path: officialProductImgPath,
        width: officialProductImage.width,
        height: officialProductImage.height
      };

      copyValue.defaultRate = getIncentivePointObject(defaultRate);
      copyValue.incentiveStruct = {
        cnx: copyValue.defaultRate.cnx,
        discount: copyValue.defaultRate.discount,
        fromSave: 0, // 기본 값
        saveSum: copyValue.defaultRate.seller,
        toSave: copyValue.defaultRate.seller,
        provider: copyValue.defaultRate.provider
      };

      if (isEdit) {
        response = await putProduct(copyValue);
      } else {
        const {
          id,
          providerName,
          email,
          mobile,
          providerProfileImgPath
        } = user;

        /* 발굴 데이터에서 필요한 정보 가지고 옴 */
        if (this.discoverData) {
          const {
            discovererEmail,
            discovererId,
            discovererName,
            discovererMobile,
            id,
            profileImgPath,
            recommendation,
            tags
          } = this.discoverData;

          copyValue.discoverId = id;
          copyValue.discovererEmail = discovererEmail;
          copyValue.discovererId = discovererId;
          copyValue.discovererName = discovererName;
          copyValue.discovererMobile = discovererMobile;
          copyValue.discovererProfileImgPath = profileImgPath;
          copyValue.recommendation = recommendation;
          copyValue.tags = tags;
        }

        // 공급자 정보
        copyValue.providerId = id;
        copyValue.providerName = providerName;
        copyValue.providerEmail = email;
        copyValue.providerMobile = mobile;

        // 공급자 프로필 사진이 있으면 프로필 사진도 같이 전달
        if (providerProfileImgPath) {
          copyValue.providerProfileImgPath = providerProfileImgPath;
        }

        copyValue.id = discoverId;

        response = await registerProduct(copyValue);
      }

      if (!response || !response.data || !response.data.success) {
        throw new Error();
      }

      this.responseData = response.data.data;

      this.handleUpdateDiscoverStatus();
    } catch (e) {
      this.setState({
        errorMessage:
          '통신이 불안정하여 상품등록에 실패하였습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true,
        isSubmitLoading: false
      });
    }
  };

  handleUpdateDiscoverStatus = async () => {
    const { isEdit } = this.state;

    if (isEdit) {
      this.setState({
        isOpenSuccessModal: true,
        isSubmitLoading: false
      });

      return;
    }

    let discoverId = '';

    if (this.discoverData) {
      discoverId = this.discoverData.id;
    }

    try {
      const response = await putDiscoverStatus({
        changeStatusProcess: 1,
        message: null,
        discoverId
      });

      if (!response || !response.data || !response.data.success) {
        throw new Error();
      }

      this.setState({
        isOpenSuccessModal: true,
        isSubmitLoading: false
      });
    } catch (e) {
      this.setState({
        isOpenErrorModal: true,
        isSubmitLoading: false,
        errorMessage:
          '상품 등록은 완료 했으나 발굴 상태 업데이트에 실패하였습니다.\n관리자에게 문의 부탁드립니다.'
      });
    }
  };

  handleRediredtProductPage = () => {
    const { history } = this.props;
    const { responseData } = this;

    this.setState({
      isOpenSuccessModal: false
    });

    if (responseData === null || responseData === undefined) {
      this.setState({
        errorMessage: '상품 페이지로 이동 하는데 실패하였습니다.',
        isOpenErrorModal: true
      });

      return;
    }

    history.replace(`/provider/product/${responseData.id}/register`);
  };

  handleClickEditorButton = () => {
    if (device() === 'Mobile') {
      this.setState({
        isOpenErrorModal: true,
        errorMessage: '모바일에선 못씀;'
      });
      return;
    }

    this.handleToggleEditorMode();
  };

  handleToggleEditorMode = () => {
    const { isEditorMode } = this.state;
    this.setState({
      isEditorMode: !isEditorMode
    });
  };

  handleChangeEditor = (value) => {
    this.setState({
      htmlProductInfo: value
    });
  };

  render() {
    const {
      // discoverid,
      flagDelivery,
      incentiveStruct
      // isLoggedIn
    } = this.props;
    const {
      defaultPrice,
      errorMessage,
      hasOfficialProductImage,
      officialProductImage,
      isEdit,
      isEditIncentive,
      isOpenErrorModal,
      isOpenProductModal,
      isOpenSuccessModal,
      isSubmitLoading,
      isEditorMode,
      htmlProductInfo
    } = this.state;

    return (
      <Fragment>
        {/* <RedirectToHome
          isGotoHome={!isLoggedIn}
          redirectPath={`/provider/product/${discoverid}/register`}
        /> */}
        <RegisterProductForm
          isDiffHtmlProductInfo={this.initHtmlProductInfo !== htmlProductInfo}
          htmlProductInfo={htmlProductInfo}
          defaultPrice={defaultPrice}
          flagDelivery={flagDelivery}
          isEdit={isEdit}
          isEditIncentive={isEditIncentive}
          isSubmitLoading={isSubmitLoading}
          incentiveStruct={incentiveStruct}
          hasOfficialProductImage={hasOfficialProductImage}
          officialProductImage={getImageFile(officialProductImage)}
          onChangeOfficialProductImage={(image) =>
            this.handleChangeOfficialProductImage(image)
          }
          // onChangePrice={this.handleChangePrice}
          onOpenModal={this.handleOpenModal}
          onClickEditorButton={this.handleClickEditorButton}
          onChangeEditor={this.handleChangeEditor}
          onSubmit={this.handleRegisterProduct}
          isEditorMode={isEditorMode}
        />
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onClick={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
        <SearchProductModal
          isEdit={isEdit}
          isOpen={isOpenProductModal}
          modalType="button"
          onSelect={this.handleChangeProduct}
          onClickNew={(productName) => {
            this.handleClickNew('productName', productName);
          }}
          onToggle={() => {
            this.handleCloseModal('isOpenProductModal');
          }}
        />
        <LogoModal
          contentText="상품정보가 저장 되었습니다."
          headerText="저장 완료"
          isOpen={isOpenSuccessModal}
          onClick={this.handleRediredtProductPage}
          onToggle={this.handleRediredtProductPage}
        />
      </Fragment>
    );
  }
}

const selector = formValueSelector('registerProductForm');

const mapStateToProps = (state) => {
  return {
    flagDelivery: selector(state, 'flagDelivery'),
    incentiveStruct: selector(state, 'incentiveStruct'),
    priceList: selector(state, 'priceList')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeField: (field, value) => {
      dispatch(change('registerProductForm', field, value));
    }
  };
};

export default compose(
  withUi,
  withUser,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RegisterProductContainer);
