import React from 'react';
import PostList from 'components/Post/PostList';
import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';

import { MainSectionWrapper } from 'styles/AppStyle';

function OneStorePosts({ storeId, authorId, store }) {
  return (
    <MainSectionWrapper className="one-store">
      <ScrollToTopOnMount />
      <PostList
        storeId={storeId}
        authorId={authorId}
        store={store}
        canAdd={authorId ? false : true}
      />
    </MainSectionWrapper>
  );
}

export default OneStorePosts;
