import axios from './defaultAxios';

// delivery 정보 가지고옴
export function getDeliveryInfo(params) {
  return axios.get(`/delivery/${params.deliveryId}`, { params });
}

export function getCheckConfirm(params) {
  return axios.get(`/order/verify`, { params });
}
