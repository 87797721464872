import React, { Component, Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { bindActionCreators, compose } from 'redux';

import storage from 'lib/storage';
import RedirectToHome from 'components/commons/RedirectToHome';

import { s3Upload } from 'lib/aws/s3Upload';

import ProviderEditForm from 'components/provider/ProviderEditForm';
import ProviderResetPasswordForm from 'components/provider/ProviderResetPassword/ProviderResetPasswordForm';
import ProviderEditHeader from 'components/provider/ProviderEditForm/ProviderEditHeader';
import AlertModal from 'components/modal/AlertModal';
import LogoModal from 'components/modal/LogoModal';

import { getProvider, putProvider, resetPassword } from 'api/provider';

import * as userActions from 'modules/user';

import sentry from 'lib/sentry';
import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

class ProviderEditContainer extends Component {
  // 뒤로가기 했을때 state 설정
  static getDerivedStateFromProps(nextProps, prevState) {
    const { pathname } = nextProps.location;
    const splitPathname = pathname ? pathname.split('/') : [];
    const subPath = splitPathname[splitPathname.length - 1]
      ? splitPathname[splitPathname.length - 1]
      : '';

    if (subPath !== prevState.currentTab) {
      return {
        currentTab: subPath
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { location } = this.props;
    const { pathname } = location;
    const splitPathname = pathname ? pathname.split('/') : [];
    const subPath = splitPathname[splitPathname.length - 1]
      ? splitPathname[splitPathname.length - 1]
      : '';

    this.state = {
      currentTab: subPath,
      businessLicenseImage: null,
      errorMessage: '',
      isSubmitLoading: false,
      isOpenSearchAddress: false,
      isOpenErrorModal: false,
      isOpenSuccessModal: false,
      passbookImage: null,
      providerProfileImage: null,
      isPwdLoading: false,
      successMessage: '',
      successButtonText: ''
    };

    this.providerData = {};
  }

  componentDidMount() {
    const { UiAction } = this.props;

    UiAction.setHeader('공급자 정보 수정');
    this.getProvider();
    this.handleChangeTab();
  }

  //뒤로가기 했을때 주소 비교하고 함수 실행시켜줌
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;

    if (location.pathname !== prevLocation.pathname) {
      this.handleGetList();
      this.handleChangeTab();
    }
  }

  /* 이미지 관련 함수 */
  getImage = (image) => {
    if (!image) {
      return null;
    }

    if (typeof image === 'object') {
      if (image.hasOwnProperty('path')) {
        return image.path;
      }

      return image.image;
    }

    return image;
  };

  getProvider = async () => {
    const { match } = this.props;
    const { providerId } = match.params;

    try {
      const response = await getProvider(providerId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `공급자 회원정보 수정에서 공급자 정보 가져오기 실패  - ${JSON.stringify(
            response
          )}`
        );
      }

      const { data } = response.data;
      const {
        addr,
        addrSub,
        businessLicense,
        bank,
        ceoName,
        id,
        mobile,
        passbook,
        picEmail,
        picMobile,
        picName,
        providerName,
        providerType,
        zipcode,
        flagExistPwd,
        providerProfileImgPath,
        passbookImgPath,
        businessLicenseImgPath,
        freeDeliveryAmount = 50000
      } = data;

      this.providerData = data;

      // changeField('addr', addr);
      // changeField('addrSub', addrSub);
      // changeField('bank', bank);
      // changeField('businessLicense', businessLicense);
      // changeField('ceoName', ceoName);
      // changeField('id', id);
      // changeField('mobile', mobile);
      // changeField('passbook', passbook);
      // changeField('picEmail', picEmail);
      // changeField('picMobile', picMobile);
      // changeField('picName', picName);
      // changeField('providerName', providerName);
      // changeField('providerType', providerType.toString());
      // changeField('zipcode', zipcode);

      this.setState({
        addr,
        addrSub,
        businessLicense,
        bank,
        ceoName,
        id,
        mobile,
        passbook,
        picEmail,
        picMobile,
        picName,
        providerName,
        providerType,
        zipcode,
        isHavePassword: flagExistPwd,
        providerProfileImage: providerProfileImgPath,
        businessLicenseImage: businessLicenseImgPath,
        passbookImage: passbookImgPath,
        freeDeliveryAmount
      });

      this.handleChangeEdit();
    } catch (e) {
      sentry.captureException(e.message);

      this.setState({
        errorMessage:
          '공급자 정보를 불러오는데 실패했습니다.\n잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true
      });
    }
  };

  handleToggleSearchAddress = (e) => {
    if (e) {
      e.target.blur();
    }

    const { isOpenSearchAddress } = this.state;

    this.setState({
      isOpenSearchAddress: !isOpenSearchAddress
    });
  };

  handleChangeaddrGeolocation = (addrGeolocation) => {
    const { address, zonecode } = addrGeolocation;
    const { changeField } = this.props;

    changeField('zipcode', zonecode || addrGeolocation.zipcode);
    changeField('addr', address);
    changeField('addrSub', '');
  };

  handleChangeImage = (imageType, imageFile) => {
    this.setState({
      [imageType]: imageFile
    });
  };

  handleBack = (e) => {
    e.stopPropagation();

    const { history } = this.props;

    history.goBack(-1);
  };

  handleGotoHome = () => {
    const { history, match } = this.props;
    const { providerId } = match.params;

    history.replace(`/provider/${providerId}`);
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  handleSignOut = () => {
    storage.remove('user_identityId');
    storage.remove('user_token');
    storage.remove('user_userInfo');
    const isSSr = typeof window === 'undefined';

    if (!isSSr) {
      window.location.href = '/provider/signin';
    }
  };

  handleSuccessModalButton = (type) => {
    const { id } = this.state;

    // type = "memberedit" or "pwdedit"
    if (type === 'pwdedit') {
      this.handleSignOut();
      return;
    } else {
      this.props.history.replace(`/provider/${id}`);
    }
  };

  handleGetList = () => {
    const { id, providerName, mobile, currentTab } = this.state;

    if (currentTab === 'profile') {
      if (id && providerName && mobile) {
        this.handleChangeEdit();
      }
      return;
    }
  };

  handleChangeEdit = () => {
    const { changeField } = this.props;
    const {
      addr,
      addrSub,
      bank,
      businessLicense,
      ceoName,
      id,
      mobile,
      passbook,
      picEmail,
      picMobile,
      picName,
      providerName,
      providerType,
      zipcode,
      freeDeliveryAmount
    } = this.state;

    changeField('addr', addr);
    changeField('addrSub', addrSub);
    changeField('bank', bank);
    changeField('businessLicense', businessLicense);
    changeField('ceoName', ceoName);
    changeField('id', id);
    changeField('mobile', mobile);
    changeField('passbook', passbook);
    changeField('picEmail', picEmail);
    changeField('picMobile', picMobile);
    changeField('picName', picName);
    changeField('providerName', providerName);
    changeField('providerType', providerType.toString());
    changeField('zipcode', zipcode);
    changeField('freeDeliveryAmount', freeDeliveryAmount);
  };

  handleSubmit = async (values) => {
    const { providerType } = this.props;
    const { isSubmitLoading } = this.state;

    // 이미 로딩 중이면 등록이 실행 중이라는 뜻
    if (isSubmitLoading) {
      return;
    }

    this.setState({
      isSubmitLoading: true
    });

    try {
      const {
        businessLicenseImage,
        passbookImage,
        providerProfileImage
      } = this.state;

      const businessLicenseImgPath = await s3Upload(
        this.getImage(businessLicenseImage),
        'businessLicenseImage'
      );

      const passbookImgPath = await s3Upload(
        this.getImage(passbookImage),
        'passbookImage'
      );

      const providerProfileImgPath = await s3Upload(
        this.getImage(providerProfileImage),
        'providerProfileImage'
      );

      if (businessLicenseImgPath !== '') {
        values.businessLicenseImgPath = businessLicenseImgPath;
      }

      if (passbookImgPath !== '') {
        values.passbookImgPath = passbookImgPath;
      }

      values.providerType = parseInt(providerType, 10);

      if (providerProfileImgPath !== '') {
        values.providerProfileImgPath = {
          path: providerProfileImgPath,
          width: providerProfileImage.width,
          height: providerProfileImage.height
        };
      }

      const response = await putProvider(values);

      if (!response || !response.data || !response.data.success) {
        if (response.data && response.data.code === -1000) {
          throw new Error(
            `공급자 수정 비밀번호 오류  - ${JSON.stringify(response)}`
          );
        }

        throw new Error(`공급자 수정 실패  - ${JSON.stringify(response)}`);
      }

      const user = storage.get('user_userInfo');

      if (user) {
        user.id = values.id;
        user.profileImgPath = providerProfileImgPath || {};
        user.providerName = values.providerName;
      }

      storage.set('user_userInfo', user);

      const { UserAction } = this.props;

      UserAction.setProviderProfileImage({
        providerProfileImgPath: providerProfileImgPath
          ? values.providerProfileImgPath
          : ''
      });

      this.setState({
        isOpenSuccessModal: true,
        successMessage: '수정이 완료되었습니다.'
      });
    } catch (err) {
      let errorMessage =
        '공급자 정보를 수정하는 동안 에러가 발생하였습니다.\n잠시 후 다시 시도해주세요.';
      sentry.captureException(err.message);

      if (err && err.message.indexOf('비밀번호') > -1) {
        errorMessage = '비밀번호가 맞지 않습니다.\n다시 확인해 주세요.';
      }

      this.setState({
        isSubmitLoading: false,
        isOpenErrorModal: true,
        errorMessage
      });
    }
  };

  handlePwdSubmit = (values) => {
    this.setState(
      {
        isPwdLoading: true
      },
      async () => {
        try {
          let params = {};
          const { id, isHavePassword } = this.state;
          params.providerId = id;
          params.pwd = isHavePassword ? values.oldPwd : null;
          params.newPwd = values.pwd;

          if (params.pwd === params.newPwd) {
            let errorMessage =
              '현재 비밀번호가 신규 비밀번호와 일치합니다.\n새로운 비밀번호를 입력해주세요';
            this.errorType = 'error';
            this.setState({
              isPwdLoading: false,
              isOpenErrorModal: true,
              errorMessage
            });
            return;
          }

          const postValues = {
            providerId: id,
            pwd: values.oldPwd,
            newPwd: values.pwd
          };
          const response = await resetPassword(postValues);

          // eslint-disable-next-line
          // console.log(`resetPassword response: ${JSON.stringify(response)}`);
          if (!response || !response.data || !response.data.success) {
            let errorMessage =
              '비밀번호 변경 중 에러가 발생하였습니다.\n다시 확인해 주세요.';

            const resErrorCode = response.data.errorCode;

            if (resErrorCode === 2) {
              errorMessage =
                '입력하신 현재 비밀번호가 맞지 않습니다.\n다시 확인해 주세요.';
            }

            this.errorType = 'error';
            this.setState({
              isPwdLoading: false,
              isOpenErrorModal: true,
              errorMessage
            });
            return;
          }

          // success
          this.successType = 'pwdedit';
          this.setState({
            isPwdLoading: false,
            isOpenSuccessModal: true,
            successMessage:
              '요청하신 비밀번호로 변경했습니다.\n신규 비밀번호로 로그인하세요.',
            successButtonText: '로그인 하러 가기'
          });
        } catch (e) {
          this.errorType = 'error';
          sentry.captureException(e.message);
          this.setState({
            isPwdLoading: false,
            isOpenErrorModal: true,
            errorMessage:
              '입력하신 현재 비밀번호가 맞지 않습니다.\n 다시 확인해 주세요.'
          });
        }
      }
    );
  };

  handleChangeTab = () => {
    const { location } = this.props;
    let currentTab = 0;

    if (!location || !location.pathname) {
      return;
    }

    const { pathname } = location;

    if (pathname.indexOf('profile') > -1) {
      currentTab = 'profile';
    } else if (pathname.indexOf('pwd') > -1) {
      currentTab = 'pwd';
    }

    this.setState({
      currentTab
    });
  };

  render() {
    const { match, providerType } = this.props;
    const {
      businessLicenseImage,
      errorMessage,
      isSubmitLoading,
      isOpenSearchAddress,
      isOpenErrorModal,
      isOpenSuccessModal,
      passbookImage,
      providerProfileImage,
      currentTab,
      isPwdLoading,
      successMessage,
      successButtonText,
      isHavePassword
    } = this.state;
    const userInfo = storage.get('user_userInfo');
    const { providerId } = match.params;

    return (
      <Fragment>
        <RedirectToHome isGotoHome={!userInfo || userInfo.id !== providerId} />
        <ProviderEditHeader
          // onClick={this.handleChangeTab}
          currentTab={currentTab}
          providerId={providerId}
        />
        <Route
          path="/provider/:providerId/setting/edit/profile"
          render={() => (
            <ProviderEditForm
              businessLicenseImage={this.getImage(businessLicenseImage)}
              isSubmitLoading={isSubmitLoading}
              isOpenSearchAddress={isOpenSearchAddress}
              isPrivate={providerType === '1'}
              onChangeImage={this.handleChangeImage}
              onChangeRequiredImage={this.handleChangeRequiredImage}
              onChangeAddress={this.handleChangeaddrGeolocation}
              onClickBack={this.handleBack}
              onRemove={this.handleRemovePicture}
              onSubmit={this.handleSubmit}
              onToggle={this.handleToggleSearchAddress}
              passbookImage={this.getImage(passbookImage)}
              providerId={providerId}
              providerProfileImage={this.getImage(providerProfileImage)}
            />
          )}
        />
        <Route
          path="/provider/:providerId/setting/edit/pwd"
          render={() => (
            <ProviderResetPasswordForm
              onSubmit={this.handlePwdSubmit}
              isPwdLoading={isPwdLoading}
              isHavePassword={isHavePassword}
            />
          )}
        />
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
        <LogoModal
          isOpen={isOpenSuccessModal}
          contentText={successMessage}
          buttonText={successButtonText}
          headerText="수정 완료"
          onToggle={() => {
            this.handleSuccessModalButton(this.successType);
          }}
          onClick={() => {
            this.handleSuccessModalButton(this.successType);
          }}
        />
      </Fragment>
    );
  }
}

const selector = formValueSelector('providerEditForm');

const mapStateToProps = (state) => {
  return {
    providerType: selector(state, 'providerType')
  };
};

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators(userActions, dispatch),
  changeField: (field, value) => {
    dispatch(change('providerEditForm', field, value));
  }
});

export default compose(
  withRouter,
  withUi,
  withUser,
  connect(mapStateToProps, mapDispatchToProps)
)(ProviderEditContainer);
