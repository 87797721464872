import axios from 'api/defaultAxios';

export default (targetType, action, values) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/bookmarks', {
        targetType,
        action,
        ...values
      })
      .then((res) => {
        if (!res.data.success) {
          res.errorMessage = '정보를 갱신하는데 실패했습니다';
          reject(res);

          return;
        }

        resolve(res.data.data);
      })
      .catch((err) => {
        err.errorMessage = '정보를 갱신하는데 실패했습니다';
        reject(err);
      });
  });
};
