/* eslint-disable react/jsx-indent */
import React, { Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import sentry from 'lib/sentry';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import { useGift } from 'hooks/useGifts';
import { getStore } from 'api/store';
import { getGift, postGift } from 'api/gift';

import useModal, { useSimpleDialog } from 'hooks/useModal';

import AlertModal from 'components/modal/AlertModal';
import Loader from 'components/commons/Loader';

import GiftMessage from './GiftMessage';
import GiftDeliveryForm from './GiftDeliveryForm';
import GiftConfirmForm from './GiftConfirmForm';
import ConfirmGiftSuccessModal from './ConfirmGiftSuccessModal';

function ConfirmGift({
  deliveryId,
  user,
  isLoggedIn,
  isSignedKakao,
  isProvider,
  UserActions,
  UiAction
}) {
  const location = useLocation();

  const { data: gift, isLoading, isError, error } = useGift(deliveryId);

  // console.log({deliveryId, gift, error});
  // const {
  //   isOpenDialog,
  //   handleToggleDialog,
  //   handleConfirm,
  //   handleDecline,
  //   isConfirmed
  // } = useSimpleDialog();

  const {
    isOpen: isOpenErrorModal,
    handleToggle: toggleErrorModal,
    message: errorMessage
  } = useModal();
  // const { isOpen, handleToggle, message, setMessage } = useModal();
  // const { isOpen, handleToggle, message, setMessage } = useModal();

  const [receiverId, setReceiverId] = useState('');
  const [isConfirmGiftLoading, setIsConfirmGiftLoading] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);

  const { experienceType, status, buyer, product, receiver } = gift || {};

  useEffect(() => {
    UiAction.setHeader('선물 받기');

    if (location?.state?.userInfo?.snsId) {
      UserActions.changeKakaoButton(true);
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      if (experienceType === 'buy') {
        throw new Error('선물이 아닙니다.');
      }
      if (
        experienceType === 'gift-direct' ||
        (experienceType === 'gift-recv' && status === 'delivered')
      ) {
        throw new Error('이미 받은 선물 입니다.');
      }

      if (
        values.mobile.replace(/\D/g, '') !== receiver.mobile.replace(/\D/g, '')
      ) {
        throw new Error(
          '보내신 휴대폰 번호와 입력하신 휴대폰 번호가 다릅니다.\n같은 번호를 입력해야 선물받기가 완료됩니다.\n다시 확인해 주세요'
        );
      }

      const { snsType = 'kakao', snsId } = location?.state?.userInfo || {};

      setIsConfirmGiftLoading(true);

      // // 구매자 정보와 배송지 정보가 같을 때
      const { isSameAddress = true, flagAgreeMarketing, ...rest } = values;

      const recipient = isSameAddress ? rest : rest;

      const newGiftParams = {
        id: deliveryId,
        productId: product.id,
        receiver,
        user,
        recipient,
        flagUpdate1StoreAddress: false,
        flagLogin: isLoggedIn,
        flagAgreeMarketing
      };

      // receiver id값 추가
      newGiftParams.user.id = isLoggedIn ? user.id : receiver.id;

      // 카카오 아이디 가져오기
      if (location && location.state && location.state.userInfo) {
        const { userInfo } = location.state;

        newGiftParams.user.snsType = 'kakao';
        newGiftParams.user.snsId = userInfo.snsId.toString();
      }

      // 로그인상태면 마케팅 동의값 보내지 않도록
      if (isLoggedIn) {
        delete newGiftParams.flagAgreeMarketing;
      }

      if (product.flagDelivery === 0) {
        delete newGiftParams.flagUpdate1StoreAddress;
      }

      // if (newGiftParams) {
      //   // eslint-disable-next-line
      //   console.log(newGiftParams);
      //   setIsConfirmGiftLoading(false);

      //   return;
      // }

      const response = await postGift(newGiftParams);

      if (!response || !response.data.success) {
        throw new Error(
          '선물 받기에 실패했습니다.\n잠시 후 다시 시도해주세요.'
        );
      }

      const { data } = response.data;
      const { Attributes } = data;
      const { receiverId } = Attributes;

      setReceiverId(receiverId);
      setIsConfirmGiftLoading(false);
      setIsOpenSuccessModal(true);
    } catch (error) {
      console.log({ error });
      setIsConfirmGiftLoading(false);
      toggleErrorModal(error.message);
    }
  };

  const isConfirmed = status !== 'ordered';

  // console.log({ status, receiver });
  return (
    <Fragment>
      {(isLoading || isConfirmGiftLoading) && <Loader />}
      <GiftMessage isConfirmed={isConfirmed} {...gift} />
      {!isConfirmed &&
      (!isLoggedIn || // 비로그인
        user?.id === receiver?.id || // or 받은선물 (same id)
        (receiver?.id === 'NOT_ASSIGN' &&
          user?.mobile === receiver?.mobile)) ? ( // or 받은선물(same mobile)
        <GiftDeliveryForm
          isLoggedIn={isLoggedIn}
          isSignedKakao={isSignedKakao}
          {...gift}
          onSubmit={onSubmit}
        />
      ) : (
        <GiftConfirmForm
          isReceivedGift={user?.id === receiver?.id}
          isLoggedIn={isLoggedIn}
          {...gift}
        />
      )}

      <ConfirmGiftSuccessModal
        isOpen={isOpenSuccessModal}
        setIsOpen={setIsOpenSuccessModal}
        buyerId={buyer?.id}
        buyerName={buyer?.name || buyer?.userName}
        receiverId={receiverId || receiver?.id}
        receiverName={
          isLoggedIn ? user?.userName : receiver?.name || receiver?.userName
        }
        productId={product?.id || ''}
        productName={product?.productName || ''}
      />
      <AlertModal
        isOpen={isOpenErrorModal}
        contentText={errorMessage}
        onToggle={toggleErrorModal}
      />
      {/* <AlertModal
          isOpen={isOpenRedirectModal}
          contentText={errorMessage}
          onToggle={this.handleRedirectHome}
        /> */}
      {/* <pre>{JSON.stringify(gift, null, 2)}</pre> */}
    </Fragment>
  );
}

export default withUi(withUser(ConfirmGift));
