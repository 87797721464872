import React, { Fragment } from 'react';
import './SearchInput.scss';

function SearchInput({
  keyword,
  selectOptionIndex,
  onChangeSelect,
  onChangeValue,
  onClickClearButton
}) {
  return (
    <Fragment>
      <div className="search-bar form-group mt-2">
        <div className="search-bar-warpper second-inner">
          <div className="form-control">
            <i className="search-bar-icon" />
            <input
              className="search-bar-input w-100"
              placeholder="검색어를 입력하세요."
              required
              value={keyword}
              onChange={onChangeValue}
            />
            <button
              className="search-bar-reset-icon"
              type="reset"
              onClick={onClickClearButton}
            />
          </div>
        </div>
        <div className="search-bar-warpper first-inner">
          <select
            className="search-bar-option select form-control"
            onChange={onChangeSelect}
            value={selectOptionIndex}
          >
            <option key="product" value={0}>
              상품
            </option>
            <option key="provider" value={1}>
              공급자
            </option>
            <option key="onestore" value={2}>
              친구
            </option>
          </select>
        </div>
      </div>
    </Fragment>
  );
}

export default SearchInput;
