import React from 'react';

import CommonModal from 'components/modal/CommonModal';
import DaumPostcode from 'react-daum-postcode';

function AddressModal({ isOpen, onSelect, onToggle }) {
  const handleAddress = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    onSelect({ address: fullAddress, zipcode: data.zonecode });
    // onSelect(data);
    onToggle();
  };

  return (
    <CommonModal
      className="search-address"
      size="md"
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <DaumPostcode onComplete={handleAddress} />
    </CommonModal>
  );
}
export default AddressModal;
