import React from 'react';
import classNames from 'classnames';
import { reduxForm, Field } from 'redux-form';

import { required, tel } from 'lib/form/validations';

import Button from 'components/commons/Button';
import RenderField from 'components/reduxForm/RenderField';

import './KakaoAuthForm.scss';

function KakaoAuthForm({
  handleSubmit,
  invalid,
  isDupplicated,
  isNotDuplicated,
  onChangeField,
  onClickCheck,
  onSubmit
}) {
  return (
    <form className="signin-form kakao" onSubmit={handleSubmit(onSubmit)}>
      <h3>회원가입 여부 확인</h3>
      <Field
        name="userName"
        autocomplete="name"
        label="이름"
        type="text"
        component={RenderField}
        onChange={onChangeField}
        placeholder="이름"
        validate={[required]}
        pattern={/\s/g}
        isRequired
      />
      <Field
        name="mobile"
        type="tel"
        component={RenderField}
        label="휴대폰 번호"
        onChange={onChangeField}
        placeholder="- 없이 숫자만 입력"
        pattern={/[^0-9]/g}
        redirectUrl="/"
        validate={[required, tel]}
        isRequired
      >
        <Button
          buttonText="확인"
          className="form-button btn-primary"
          color="primary"
          onClick={onClickCheck}
        />
      </Field>
      <Button
        buttonText="기존회원입니다. 카카오톡 연결하기"
        buttonType="submit"
        className={classNames('kakao-button first', {
          able: !invalid && isDupplicated
        })}
        isDisable={invalid || !isDupplicated}
      />
      <Button
        buttonText="신규회원입니다. 카카오톡으로 가입하기"
        buttonType="submit"
        className={classNames('kakao-button', {
          able: !invalid && isNotDuplicated
        })}
        isDisable={invalid || !isNotDuplicated}
      />
    </form>
  );
}

export default reduxForm({
  asyncBlurFields: ['userName', 'mobile'],
  form: 'kakaoAuthForm'
})(KakaoAuthForm);
