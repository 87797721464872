import React, { Component } from 'react';
import TutorialForm from 'components/tutorial/TutorialForm';
import withUi from 'lib/hoc/withUi';

class TutorialContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlide: 0
    };
    this.SETTING = {
      dots: true,
      infinite: true,
      afterChange: (current) => this.setState({ activeSlide: current }),
      customPaging: () => <div className="custom-slick-dot" />
    };
  }

  componentDidMount() {
    this.props.UiAction.setHeader('프롬소개');
  }

  render() {
    const { activeSlide } = this.state;

    return <TutorialForm SETTING={this.SETTING} activeSlide={activeSlide} />;
  }
}

export default withUi(TutorialContainer);
