import axios from './defaultAxios';

// 발굴 정보를 가지고 온다.
export function getDiscover(discoverId) {
  return axios.get(`/discover/${discoverId}`);
}

// 발굴 정보를 등록한다.
export function postDiscover(values) {
  return axios.post('/discover', values);
}

// 중복된 데이터가 있는지 확인하기 위해 검색한 정보를 가지고 온다.
export function getSearchedDiscover(keyword) {
  return axios.get(`/discover/search`, {
    params: {
      keyword
    }
  });
}

// 발굴 정보 리스트를 가지고 온다.
export function getAllDiscover(params) {
  // eslint-disable-next-line no-console
  // console.log('inside getAllDiscover', params);
  return axios.get('/discover', { params });
}

// 발굴 상태 업데이트
export function putDiscoverStatus(values) {
  return axios.put(`/discover/${values.discoverId}/discoverstatus`, values);
}
