import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCartStore, useCartDispatch } from './CartStore';
import {
  useCheckoutDispatch,
  useCheckoutStore
} from 'components/checkout/CheckoutStore';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import useModal from 'hooks/useModal';
import AlertModal from 'components/modal/AlertModal';
import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';

import CheckoutHeader from 'components/checkout/CheckoutHeader';
import CartItem from './CartItem';
import CartSummary from './CartSummary';

import ProductSlider from 'components/commons/ProductSlider';
import EmptyList from 'components/commons/EmptyList';
import { getPriceFormat } from 'lib/utils/numberUtils';
import { isIPhoneX } from 'lib/utils/envUtils';

import { ButtonWrapper, StyledButton } from 'shared/buttons/ButtonStyle';
import {
  CheckoutWrapper,
  CheckoutMainWrapper,
  StyledCheckoutHeader
} from 'components/checkout/CheckoutStyle';
import { ItemsWrapper } from './ItemStyle';

const isUp = isIPhoneX();

function Cart({ user, UiAction, showHeader }) {
  const history = useHistory();
  const cart = useCartStore();
  const cartDispatch = useCartDispatch();
  const { orders } = useCheckoutStore();

  const {
    message: alertMessage,
    isOpen: isOpenAlertModal,
    handleToggle: toggleAlertModal
  } = useModal();

  const orderIsEmpty = !(orders?.length > 0);
  const cartIsEmpty = cart?.items?.length < 1;

  const addItem = (item) => {
    console.log('dispatching');
    cartDispatch({
      type: 'add',
      payload: item
    });
    console.log('dispatched');
  };

  const removeItem = (productId, optionId) => {
    cartDispatch({ type: 'remove', payload: { productId, optionId } });
  };

  const increment = (productId, optionId, incrementBy = 1) => {
    cartDispatch({
      type: 'increment',
      payload: { productId, optionId, incrementBy }
    });
  };
  const decrement = (productId, optionId) => {
    cartDispatch({
      type: 'increment',
      payload: { productId, optionId, incrementBy: -1 }
    });
  };

  const toggle = (productId, optionId) => {
    cartDispatch({
      type: 'toggle',
      payload: { productId, optionId }
    });
  };

  const onClickOption = (seller, product, provider) => {
    history.push({
      pathname: `/store/${seller.id}/${product.id}/options/choose`,
      state: {
        isInCart: true,
        itemType: 'cart',
        product,
        options: product.options,
        provider,
        seller
      }
    });
  };

  const emptyCart = () => {
    cartDispatch({ type: 'empty' });
  };

  const restoreCart = () => {
    cartDispatch({ type: 'restore' });
  };

  const checkout = (orderType) => {
    // console.log({ orderType });
    if (cart?.checkedTotal?.quantity > 0) {
      history.push({
        pathname: '/order/checkout',
        state: { orderType }
      });
    } else {
      toggleAlertModal('선택된 상품이 없습니다!');
    }
  };

  useEffect(() => {
    UiAction.setHeader(`${user.name || user.userName || '게스트'}님의 바구니`);
    // updateReceiver(oneStore);
  }, []);

  const formattedCartTotal = getPriceFormat(
    cart?.checkedTotal?.totalAmount || 0
  );

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <CheckoutWrapper>
        {orderIsEmpty ? (
          <StyledCheckoutHeader className={!showHeader ? 'hidden-header' : ''}>
            <ButtonWrapper className="grey-button-wrapper">
              {!cartIsEmpty ? (
                <StyledButton
                  className="text-button transparent-button"
                  onClick={emptyCart}
                >
                  <img src="/images/x-icon-large.svg" alt="remove" />
                  <span>전체 바구니 비우기</span>
                </StyledButton>
              ) : (
                <StyledButton
                  className="text-button transparent-button"
                  onClick={restoreCart}
                >
                  <img src="/images/checkbox-checked-black.svg" alt="restore" />
                  <span>이전 바구니 다시 담기</span>
                </StyledButton>
              )}
            </ButtonWrapper>
          </StyledCheckoutHeader>
        ) : (
          <CheckoutHeader step="cart" />
        )}
        <CheckoutMainWrapper>
          {cartIsEmpty ? (
            <EmptyList
              contentText="바구니가 비었습니다."
              subText="선물로 가득 채워주세요 ^^"
            />
          ) : (
            <Fragment>
              <div className="provider-cart-list">
                {cart.providers.map((provider) => (
                  <div key={provider.providerId} className="provider-cart">
                    {/* <h3 className="provider-name">{`${
                      provider?.providerName || '공급자'
                    } 배송`}</h3> */}
                    <ItemsWrapper>
                      {cart.items
                        .filter(
                          (item) => item.provider.id === provider.providerId
                        )
                        .map((item, index) => (
                          <CartItem
                            key={item.option.id}
                            index={index}
                            {...item}
                            {...{
                              removeItem,
                              increment,
                              decrement,
                              toggle,
                              onClickOption
                            }}
                          />
                        ))}
                    </ItemsWrapper>
                    <CartSummary
                      title={`${provider.providerName} 배송`}
                      type="provider"
                      {...cart.subtotals.find(
                        (item) =>
                          item.providerId === provider.providerId &&
                          item.isChecked
                      )}
                    />
                    {/* <ProductSlider
                      className="small small-title"
                      title="*묶음배송을 원한다면"
                      subTitle={`${provider.providerName}의 다른 상품`}
                      searchType="providerId"
                      searchKey={provider.providerId}
                      filterBy={(product) =>
                        !cart.subtotalsByProduct.some(
                          (item) => item.productId === product.id
                        ) &&
                        !(
                          product.flagStopSelling === 11 ||
                          product.flagStopSelling === 12
                        ) &&
                        !(product.isRecipe || product.isCarrige)
                      }
                      sortBy={(a, b) => b.countOf1P - a.countOf1P}
                      // onCardClick={(product) => console.log({ product })}
                    /> */}
                  </div>
                ))}
              </div>
              <CartSummary title="바구니 합계" {...cart.checkedTotal} />
            </Fragment>
          )}
          <ButtonWrapper
            className={`footer-button-wrapper ${
              !showHeader ? 'hidden-buttons' : ''
            } ${isUp ? 'iphone-x-buttons' : ''}`}
          >
            <StyledButton
              type="button"
              className="from-button"
              onClick={() => checkout('gift')}
            >
              친구에게 선물
              {/* <span>{`(${formattedCartTotal}원)`}</span> */}
            </StyledButton>
            <StyledButton
              type="button"
              className="black-button"
              onClick={() => checkout('buy')}
            >
              나에게 선물
              {/* <span>{`(${formattedCartTotal}원)`}</span> */}
            </StyledButton>
          </ButtonWrapper>
          <AlertModal
            contentText={alertMessage}
            isOpen={isOpenAlertModal}
            onToggle={toggleAlertModal}
          />
        </CheckoutMainWrapper>

        {/* <div>
          <pre>{JSON.stringify(cart.total, null, 2)}</pre>
        </div>
        <div>
          <pre>{JSON.stringify(cart.subtotals, null, 2)}</pre>
        </div>
        <div>
          <pre>{JSON.stringify(cart.subtotalsByProduct, null, 2)}</pre>
        </div>
        <div>
          <pre>{JSON.stringify(cart.items, null, 2)}</pre>
        </div> */}
      </CheckoutWrapper>
    </Fragment>
  );
}
export default withUser(withUi(Cart));
