import axios from './defaultAxios';

// 배너 리스트 가지고 옴
// FromPickBanner에서 사용중
export function getBannerList() {
  return axios.get(`/banners`);
}

// 배너 이벤트 정보 가지고옴
export function getBannerInfo(params) {
  return axios.get(`/banners/${params.bannerId}`, { params });
}
