import React from 'react';
import Loader from 'components/commons/Loader';
import EmptyList from 'components/commons/EmptyList';

import './ProviderProductList.scss';

function ProviderProductList({ haveList, isLoading, infiniteRef, children }) {
  return (
    <div className="provider-product-list-wrapper" ref={infiniteRef}>
      <div className="provider-product-list">
        {isLoading && <Loader />}
        {haveList ? children : <EmptyList className="mt-5" />}
      </div>
    </div>
  );
}

export default ProviderProductList;
