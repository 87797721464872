import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import GiftConfirmContainer from 'containers/gift/GiftConfirmContainer';

function GiftConfirm({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <GiftConfirmContainer deliveryId={match.params.deliveryId} />
    </Fragment>
  );
}

export default GiftConfirm;
