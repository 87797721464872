import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProviderInfo from 'containers/provider/ProviderInfo';
import ProviderProductList from 'containers/provider/ProviderProductList';
import { MainSectionWrapper } from 'styles/AppStyle';

function ProviderProducts({ providerId, provider, isLoading }) {
  return (
    <MainSectionWrapper className="provider">
      <ScrollToTopOnMount />
      <ProviderInfo
        providerId={providerId}
        provider={provider}
        isLoading={isLoading}
      />
      <ProviderProductList providerId={providerId} />
    </MainSectionWrapper>
  );
}

export default ProviderProducts;
