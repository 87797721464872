import styled, { css } from 'styled-components';

export const OptionChooserWrapper = styled.div`
  display: grid;
  max-width: var(--max-content-width, 420px);
  grid-template-columns: 20px minmax(255px, 1fr) 20px;
  grid-template-rows: repeat(4, auto);
  column-gap: 5px;
  row-gap: 5px;
  align-items: center;
  justify-items: start;

  color: var(--text-color, black);
  margin: 0px auto;
  padding-bottom: 30px;

  grid-template-areas:
    ' . item-header .'
    ' item-body item-body item-body'
    ' item-action item-action item-action'
    ' item-slider item-slider item-slider';

  .product-name {
    grid-area: item-header;
    width: 100%;
    height: auto;

    padding: 20px;

    font-size: 1rem;
    font-weight: bold;
    line-height: 1.3;
    a {
      color: var(--text-color, black);
    }
  }

  .option-list {
    grid-area: item-body;
    border-top: solid 1px var(--border-color, #f5f4f4);
  }

  .product-slider {
    grid-area: item-slider;
    width: 100%;
  }

  .product-action {
    grid-area: item-action;
    margin-top: 20px;
  }
`;
