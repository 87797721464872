/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import styled, { css } from 'styled-components';
// import ReactDatePicker from 'react-datepicker';

const defaultControlStyle = css`
  grid-area: input-control;
  box-sizing: border-box;
  padding: 5px;
  width: 100%;
  border-radius: 1px;
  border: 1px solid #ced4da;
  font-size: 0.875rem;
  line-height: 1.5;

  &:focus {
    /* border: 2px solid black; */
  }

  &:disabled {
    opacity: 0.4;
  }
`;

export const StyledForm = styled.form`
  display: grid;
  width: 100%;
  max-width: var(--max-content-width, 420px);
  grid-template-columns: 20px minmax(300px, 1fr) 20px;
  grid-template-rows: repeat(4, auto);
  column-gap: 0px;
  row-gap: 0px;
  align-items: center;
  justify-items: center;
  margin: 30px auto;

  grid-template-areas:
    'form-header-left form-header-center form-header-right'
    ' . form-title . '
    ' . form-body .'
    ' . form-button . ';

  &.payment-form {
    grid-template-areas:
      'form-header-left form-header-center form-header-right'
      'form-title form-title form-title'
      'form-body form-body form-body'
      'form-button form-button form-button';
  }
`;

export const FormHeaderWrapper = styled.div`
  grid-area: form-header-right;
  display: flex;
  justify-self: end;
`;

export const StyledFormTitle = styled.div`
  grid-area: form-title;
  width: 100%;
  font-size: 20px;
  font-weight: bold;

  /* text-align: center; */
`;

export const FormBodyWrapper = styled.div`
  grid-area: form-body;
  width: 100%;
`;

export const SubFormWrapper = styled.div`
  width: 100%;
  padding: 0px 20px;

  &:before {
    content: ' ';
    display: block;
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: 9px solid var(--border-color, #f2f2f2);
  }
`;

export const FormButtonWrapper = styled.div`
  grid-area: form-button;
  width: 100%;
  display: flex;
  justify-content: space-between;

  &.relay-santa {
    justify-content: center;
    button {
      width: 330px;
      height: 48px;
      padding: 10px 20px;
      background-color: #ff5700;
      color: white;
      font-size: 18px;
      font-weight: 800;
      border-radius: 28px;
      box-shadow: 3px 3px 2px #dad7d7;
    }
  }
`;

export const FormControlGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(100px, 1fr) 3fr max-content;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'input-label input-control input-button'
    '.           error-message .';

  column-gap: 0px;
  row-gap: 0px;
  align-items: center;
  justify-items: start;
  margin: 20px auto;

  &.one-column {
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      '. input-label .'
      '. input-control input-button'
      '.           error-message .';
  }

  &.checkbox {
    grid-template-columns: max-content 1fr max-content;
    grid-template-areas:
      'input-control input-label input-button'
      '.           error-message .';
    margin: 10px auto;
    &.round-checkbox {
      margin-top: 16px;
    }
  }

  &.gift-point {
    grid-template-columns: minmax(100px, 1fr) 2fr minmax(75px, 1fr);
    grid-template-areas:
      'input-label input-button input-control'
      '.           error-message .';
  }

  .react-datepicker-wrapper {
    grid-area: input-control;
    display: block;
    width: 100%;
    .react-datepicker__input-container input {
      ${defaultControlStyle}
      grid-area: none;
    }
  }
`;

export const StyledLabel = styled.label`
  grid-area: input-label;
  display: block;
  width: 90%;
  text-align: left;
  padding-right: 10px;
  color: var(--text-color, black);
  font-size: 0.875rem;
  font-weight: bold;

  &.required {
    &::after {
      display: inline;
      color: var(--danger-color, red);
      content: '*';
    }
  }

  &.round-checkbox {
    font-weight: normal;
  }
`;

export const StyledSubTitle = styled.h3`
  width: 100%;
  margin-top: 16px;
  color: var(--text-color);
  font-size: 15px;
  font-weight: 800;
`;

export const StyledText = styled.p`
  width: 100%;
  margin-top: 16px;
  color: var(--secondary-color);
  font-size: 11px;
  font-weight: bold;
`;

export const StyledError = styled.div`
  grid-area: error-message;

  color: #bf1650;
  font-size: 10px;
  margin-left: 5px;

  &::before {
    display: inline;
    content: '⚠ ';
  }
`;

export const StyledInput = styled.input`
  ${defaultControlStyle}

  &.gift-point {
    text-align: right;
  }
`;

export const StyledTextArea = styled.textarea`
  ${defaultControlStyle}
`;

export const StyledCheckBox = styled.input.attrs({ type: 'checkbox' })`
  ${defaultControlStyle}
  /* display: flex;
  align-items: center; */
  /* min-width: 100px; */
  /* border-color: red; */
  /* border: none; */
  /* min-width: 50px; */
  /* margin-right: 20px; */
  /* padding: 10px !important; */
  width: 20px;
  height: 21px;
  margin-right: 10px;
  background-color: white;
  border: 1px solid var(--text-light-color);
  box-sizing: border-box;
  &:checked {
    background-image: url('/images/checkbox-checked.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 13px 14px;
  }

  &.round-checkbox {
    border: none;
    background-image: url('/images/checkbox-unchecked-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    &:checked {
      background-image: url('/images/checkbox-checked-icon.svg');
    }
  }
`;

export const StyledRadioButtons = styled.div`
  ${defaultControlStyle}
  border: none;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  input[type='radio'] {
    width: 0px;
    height: 0px;
    border-color: none;
    &:checked {
    }
  }

  label {
    flex: 1;
    max-width: 150px;
    padding: 10px;
    text-align: center;
    color: var(--placeholder-color, #d1d2d3);
    border: solid 1px var(--button-border-color, #d1d1d1);
    &.checked {
      border: solid 2px var(--from-color, #37e211);
      color: var(--from-color, #37e211);
    }
  }
`;

export const StyledSelect = styled.select`
  ${defaultControlStyle}
`;

export const ControlWrapper = styled.div`
  grid-area: input-control;
  box-sizing: border-box;
  width: 100%;
`;

// const StyledDatePicker = styled(ReactDatePicker)`
//   .react-datepicker-wrapper .react-datepicker__input-container input {
//     ${defaultControlStyle}
//   }
// `;

export const StyledButton = styled.button`
  display: block;
  background: var(--from-color, #37e011);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  margin: 10px 5px;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  height: 70%;
  min-width: 100px;
  max-width: 365px;
  line-height: 17px;

  ${(props) =>
    props.size === 'small' &&
    css`
      padding: 0px 20px;
      height: 25px;
      margin: 5px 5px;
      font-size: 12px;
      min-width: 50px;
    `}

  ${(props) =>
    props.size === 'medium' &&
    css`
      margin: 10px 10px;
      font-size: 14px;
      max-width: 335px;
      height: 55px;
    `}

    ${(props) =>
    props.color === 'white' &&
    css`
      color: var(--from-color);
      background-color: white;
      border: solid 1.8px var(--from-color);
    `}


  ${(props) =>
    props.isProvider === true &&
    css`
      background-color: var(--provider-color, orange);
    `}

  /* &:hover {
    background: white;
    color: var(--from-color, #37e011);
    border: solid 2px var(--from-color, #37e011);
  } */

  &:disabled {
    opacity: 0.7;
  }

  &.cancel {
    background: darkgrey;
  }

  &.close {
    background: none;
  }

  &.load-more {
    border: none;
    background: none;
    margin: 10px auto;
  }

  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    padding: 0px 3px;

    &.loading {
      animation: spinner 3s infinite;
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StyledTextButton = styled(StyledButton)`
  padding: 3px 5px;
  height: 25px;
  margin: 5px 5px;
  font-size: 10px;
  font-weight: bolder;
  background: white;
  min-width: 50px;
  color: var(--from-color);

  /* span {
    font-size: 10px;
  } */
  /* img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  } */
`;

export const StyledInputButton = styled(StyledButton)`
  grid-area: input-button;
  padding: 3px 5px;
  height: 25px;
  margin: 5px 5px;
  font-size: 14px;
  font-weight: bolder;
  background: white;
  min-width: 50px;
  color: var(--from-color);

  &.gift-point {
    justify-self: end;
    color: var(--text-color, black);
    font-size: 14px;
    font-weight: normal;
    background-color: #e5e5e5;
    width: 75px;
    height: 30px;

    margin-right: 5px;
    border: 0.5px solid #90908f;
    border-radius: 1px;
  }
`;
