import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/commons/Button';

import './Agreement.scss';

function Agreement({
  checkboxName,
  className,
  labelText,
  isCheckedAgreement,
  onChangeCheckbox,
  onClickButton
}) {
  return (
    <div className={classNames('agreement', className)}>
      <div className="agreement-checkboxs">
        <label htmlFor={checkboxName}>
          {labelText}
          <input
            type="checkbox"
            name={checkboxName}
            id={checkboxName}
            onChange={onChangeCheckbox}
            checked={isCheckedAgreement}
          />
          <span className="checkmark" />
        </label>
        <Button
          buttonText="약관보기 >"
          buttonType="button"
          className="ml-3 float-right"
          color="line-from"
          onClick={onClickButton}
        />
      </div>
    </div>
  );
}

Agreement.propTypes = {
  checkboxName: PropTypes.string,
  className: PropTypes.string,
  labelText: PropTypes.string,
  isCheckedAgreement: PropTypes.bool,
  onChangeCheckbox: PropTypes.func,
  onClickButton: PropTypes.func
};

Agreement.defaultProps = {
  checkboxName: '',
  className: '',
  labelText: '안내를 모두 읽었으며, 동의합니다.',
  isCheckedAgreement: false,
  onChangeCheckbox: () => {},
  onClickButton: () => {}
};

export default Agreement;
