import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import DiscoverRequestStatusContainer from 'containers/users/DiscoverRequestStatusContainer';

function DiscoverRequestStatus() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <DiscoverRequestStatusContainer />
    </Fragment>
  );
}

export default DiscoverRequestStatus;
