/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import useModal, { useSimpleDialog } from 'hooks/useModal';
import Button from 'components/commons/Button';

import FortuneModal from 'components/modal/FortuneModal';

import ToggleList from 'components/commons/ToggleList';
import TrackingDelivery from 'components/oneStore/TrackingDelivery';
import { getImagePath } from 'lib/utils/imageUtils';
import { getFormatTimeStempDate } from 'lib/time';
import { getPriceFormat } from 'lib/utils/numberUtils';

import './OrderStatusItem.scss';

export default function OrderStatusItem({ index, deliveryItem, history }) {
  const [isOpenTrackingDelivery, setIsOpenTrackingDelivery] = useState(false);
  const {
    isOpen: isOpenShareModal,
    handleToggle: toggleShareModal
  } = useModal();

  const handleConfirmReceipt = (storeId, paymentUid, deliveryId) => {
    // handleToggleDialog();

    let confirmRedirectPath = ``;

    if (deliveryId) {
      confirmRedirectPath = `/store/${storeId}/setting/order/${paymentUid}/${deliveryId}/confirm`;
    }
    // eslint-disable-next-line no-console
    // console.log({ confirmRedirectPath });
    history.push(confirmRedirectPath);
  };

  const {
    id: deliveryId,
    buyer: { id: storeId },
    paymentUid,
    createDate,
    product: {
      id: productId,
      name: productName,
      isFortune,
      officialProductImgPath: productImgPath,
      providerId
    },
    option,
    order,
    receiver,
    recipient,
    deliverer,
    status,
    payType,
    experienceType,
    provider
  } = deliveryItem || {};

  const { optionName, quantity, discountPrice } = order || {};

  const { id: delivererId, name: delivererName, trackingNumber } =
    deliverer || {};
  const isGift = payType === 'gift' ? true : false;
  const statusLabel = isGift ? '선물' : '구매';

  return (
    <Fragment>
      {/* 주문 정보 */}
      <div
        className={classNames('order-status-item', {
          'order-status-item-canceled': status === 'canceled'
        })}
      >
        <div className="order-status-item-header">
          <div className="main">
            <span>{`${statusLabel} `}</span>
            <span>{getFormatTimeStempDate(createDate, 'YYYY-MM-DD H:mm')}</span>
          </div>
          <div className="sub">
            <span>상품배송번호</span>
            <br />
            <span>{deliveryId}</span>
          </div>
        </div>
        <div className="order-status-item-link">
          {status === 'canceled' ? (
            <Button buttonText="취소완료" buttonType="button" color="none" />
          ) : (
            (status === 'confirmed' || status === 'issued') &&
            !isGift && (
              <Button
                buttonType="button"
                className="confirm"
                color="none"
                buttonText={status === 'confirmed' ? '직접 수령' : '사용 하기'}
                onClick={() =>
                  handleConfirmReceipt(storeId, paymentUid, deliveryId)
                }
              />
            )
          )}
        </div>

        <div className="order-status-item-image">
          <Link to={`/store/${storeId}/${productId}`}>
            <img
              className="main-image"
              src={
                `${getImagePath(
                  option?.imagePath || productImgPath,
                  250,
                  250
                )}` || '/images/default-image.svg'
              }
              alt="상품 이미지"
            />
            {/* {isGift ? (
              <img
                className="sub-image"
                src="/images/gift-circle-icon.svg"
                alt="선물아이콘"
              />
            ) : (
              <img
                className="sub-image"
                src="/images/onestore-crown-circle-icon.svg"
                alt="응원자왕관"
              />
            )} */}
          </Link>
        </div>
        <div className="order-status-item-body">
          <li>
            <Link to={`/store/${storeId}/${productId}`}>
              <span className="value">
                {`${index + 1} ${
                  productName || deliveryItem?.product?.productName
                } - ${optionName} `}
              </span>
              <span className="label"> / {quantity}개</span>
            </Link>
          </li>
          <li>
            <span className="label">받는이: </span>
            <span className="value">
              {`${receiver.name} ${receiver.mobile.replace(
                /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                '$1-$2-$3'
              )}`}
            </span>
          </li>
          {recipient && experienceType !== 'gift-recv' && recipient.addr && (
            <li>
              <span className="label">받는주소 : </span>
              <span className="value">
                {`${recipient.addr} ${recipient.addrSub}`}
              </span>
            </li>
          )}

          {receiver.giftMessage && (
            <li>
              <span className="label">선물메세지 : </span>
              <span className="value">{receiver.giftMessage}</span>
            </li>
          )}
          {!isFortune && (
            <li>
              <span className="label">주문금액 : </span>
              <span className="value">{`${getPriceFormat(
                order.total
              )}원`}</span>
            </li>
          )}
        </div>
        <div className="order-status-item-footer">
          {!isFortune && (
            <div>
              <span className="label">배송상태 : </span>
              {!deliverer ? (
                <span className="value">
                  {status === 'ordered'
                    ? '선물을 아직 확인하지 않으셨습니다'
                    : status === 'confirmed'
                    ? '주문 처리 중입니다.'
                    : '배송 준비 중 입니다.'}
                </span>
              ) : (
                <span className="value">
                  {`${delivererName} ${trackingNumber}`}
                  <Button
                    buttonType="button"
                    className={isOpenTrackingDelivery ? 'open' : 'closed'}
                    color="none"
                    buttonText={isOpenTrackingDelivery ? '' : ''}
                    onClick={() =>
                      setIsOpenTrackingDelivery(!isOpenTrackingDelivery)
                    }
                  />
                </span>
              )}
            </div>
          )}
          {isOpenTrackingDelivery && (
            <TrackingDelivery status={status} deliverer={deliverer} />
          )}
        </div>
        <div className="order-status-item-button">
          {!isFortune ? (
            <Link to={`/provider/${providerId}`}>
              <Button
                buttonText={`${
                  provider?.name ? provider.name : '공급자'
                }에게 문의하기`}
              />
            </Link>
          ) : (
            <Button
              buttonText={isOpenShareModal ? '닫기' : '포춘쿠키 열기'}
              onClick={toggleShareModal}
            />
          )}
        </div>
      </div>
      <FortuneModal
        metaData={{ ...deliverer, referrer: deliveryItem?.buyer?.id }}
        isOpen={isOpenShareModal}
        onToggle={toggleShareModal}
      />
    </Fragment>
  );
}
