import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CommonModal from 'components/modal/CommonModal';

function AlertModal({
  className,
  contentText,
  headerText,
  isOpen,
  onToggle,
  isProviderModal
}) {
  return (
    <CommonModal
      className={classNames('alert-modal', className)}
      contentText={contentText}
      headerText={headerText}
      headerImage="/images/header-image/alert-modal-header-image-sungyoung.svg"
      isOpen={isOpen}
      onToggle={onToggle}
      isProviderModal={isProviderModal}
    />
  );
}

AlertModal.propTypes = {
  contentText: PropTypes.string,
  headerText: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func
};

AlertModal.defaultProps = {
  contentText: '',
  headerText: '안내',
  isOpen: false,
  onToggle: () => {}
};

export default AlertModal;
