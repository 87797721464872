import React from 'react';
import './ProviderCrown.scss';

function ProviderCrown() {
  return (
    <img
      className="provider-crown"
      src={`/images/provider-crown.svg`}
      alt="공급자왕관"
    />
  );
}

export default ProviderCrown;
