/* eslint-disable no-console */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { NavLink } from 'react-router-dom';
import { NavBarWrapper, StyledNavBarTitle, StyledNavBar } from './NavBarStyle';

function NavBar({ className, title, navLinks, showHeader, children }) {
  // console.log({ navLinks });

  return (
    <NavBarWrapper className={showHeader ? '' : 'hidden-nav-bar'}>
      {title && <StyledNavBarTitle>{title}</StyledNavBarTitle>}
      {children && children}
      <StyledNavBar className={className}>
        {navLinks.map((link) => {
          return (
            <li className="nav-link" key={link.path}>
              <NavLink exact activeClassName="active" to={link.path}>
                <div className="nav-tab">
                  <div>{link.name || link.label}</div>
                  {className !== 'with-border' && (
                    <div className="bottom-dot" />
                  )}
                </div>
              </NavLink>
            </li>
          );
        })}
      </StyledNavBar>
    </NavBarWrapper>
  );
}
const mapStateToProps = ({ ui }) => {
  return {
    showHeader: ui.showHeader
  };
};

export default compose(connect(mapStateToProps))(NavBar);
