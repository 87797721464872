import { add } from 'lodash';
import makeStore from 'shared/store';
import { questions, options, results, descriptions, cdnUrl } from './MbtiData';

const addVector = (a, b) => {
  if (!a) return b;
  return a.map((e, i) => e + b[i]);
};
const initialState = {
  questions,
  options,
  results,
  descriptions,
  currentQuestion: 0,
  lastQuestionAnswered: false,
  answers: [],
  tester: {},
  cdnUrl
};

const reducer = (state, action) => {
  console.log('MbtiStore reducer');
  console.log({ state });
  console.log({ action });
  switch (action.type) {
    case 'reset': {
      return {
        ...initialState
      };
    }

    case 'initialize': {
      return {
        ...state,
        currentQuestion: 0,
        lastQuestionAnswered: false,
        answers: [],
        weights: null,
        result: null
      };
    }

    case 'addTester': {
      return { ...state, tester: action.payload };
    }

    case 'answerQuestion': {
      if (!action.payload.answer || !action.payload.weight) return state;
      const answers = [...state.answers, action.payload.answer];
      const weights = addVector(state.weights, action.payload.weight);
      const result = weights.indexOf(Math.max(...weights)) + 1;
      return { ...state, answers, weights, result };
    }

    case 'nextQuestion': {
      const lastQuestionAnswered =
        state.currentQuestion + action.payload === state.questions.length;
      return lastQuestionAnswered
        ? { ...state, lastQuestionAnswered }
        : {
            ...state,
            currentQuestion: state.currentQuestion + action.payload
          };
    }
    case 'goToQuestion': {
      const isOutOfRange =
        action.payload >= state.questions.length || action.payload < 0;
      return isOutOfRange
        ? state
        : {
            ...state,
            currentQuestion: action.payload
          };
    }
    case 'field': {
      return {
        ...state,
        [action.fieldName]: action.payload
      };
    }
    default:
      return state;
  }
};

const [MbtiProvider, useMbtiStore, useMbtiDispatch] = makeStore(
  reducer,
  initialState
);

export { MbtiProvider, useMbtiStore, useMbtiDispatch };
