import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import Button from 'components/commons/Button';
import RenderField from 'components/reduxForm/RenderField';
import RenderFieldTextArea from 'components/reduxForm/RenderFieldTextArea';
import PictureInput from 'components/commons/PictureInput';
import PictureView from 'components/commons/PictureView';
import { getImagePath } from 'lib/utils/imageUtils';

import { required, numeric, tel } from 'lib/form/validations';

import './DiscoverField.scss';

function DiscoverField({
  haveProductImages,
  onOpenProductModal,
  onOpenProviderModal,
  onRemovePicture,
  onSelectProductImage,
  productImageList,
  isPossibleEdit
}) {
  return (
    <Fragment>
      <div className="discover-info-form">
        <div className="discover-info-text">
          <div>
            <span className="discover-info-text-title">
              *발굴하면 뭐가 좋아요?
            </span>
          </div>
          <div>
            <span> 내가 좋아하는 상품을 응원하는 가장 좋은 방법입니다.</span>
            <span>프롬에 소개되어 더 많은 응원자를 만나게 되거든요.</span>{' '}
            <span>
              그리고 상품이 판매될 때마다 발굴자 인센티브*로 판매가격의 1%에
              해당하는 금액을 프롬 적립금으로 받게 됩니다.
            </span>
          </div>
          <div>
            <span className="discover-info-text-title">
              *발굴자 인센티브란?
            </span>
          </div>
          <div>
            <span>
              {' '}
              1. 판매가의 1%에 해당하는 금액을 (30,000원 상품의 경우, 300원)
              매달 15일, 프롬 적립금으로 지급합니다.{' '}
            </span>
            <span>
              2. 프롬 적립금은 프롬 서비스 내에서 현금처럼 사용가능합니다
            </span>{' '}
            <span>3. 발굴 과정이 메세지로 전달됩니다.</span>
          </div>
        </div>
      </div>
      <div className="section-bar" />
      <Field
        className="discover-field form-with-button"
        component={RenderField}
        isRequired
        label="소개 상품"
        name="productName"
        onFocus={onOpenProductModal}
        placeholder="오가닉 마케팅"
        type="text"
        readOnly={!isPossibleEdit}
        validate={[required]}
      >
        {isPossibleEdit && (
          <Button
            buttonText="중복확인"
            buttonType="button"
            className="form-button form-input-in-button"
            onClick={onOpenProductModal}
          />
        )}
      </Field>
      <Field
        className="discover-field"
        component={RenderFieldTextArea}
        isRequired
        label="추천사"
        name="recommendation"
        placeholder="발굴하기 추천사는 등록시 상품 정보에서 노출됩니다."
        validate={[required]}
        readOnly={!isPossibleEdit}
      />
      <Field
        className="discover-field"
        component={RenderField}
        isRequired
        label="태그"
        name="tags"
        placeholder="ex) #책 #필독"
        type="text"
        validate={[required]}
        readOnly={!isPossibleEdit}
      />
      <Field
        className="discover-field"
        component={RenderField}
        isRequired
        label="생산자/공급자"
        name="providerName"
        placeholder="ex) 오가닉 미디어 랩"
        type="text"
        validate={[required]}
        readOnly={!isPossibleEdit}
      >
        {isPossibleEdit && (
          <Button
            buttonText="공급자 검색"
            buttonType="button"
            className="form-button form-provider-button form-input-in-button"
            onClick={onOpenProviderModal}
          />
        )}
      </Field>
      <Field
        className="discover-field form-with-button"
        component={RenderField}
        label="생산자/공급자 전화번호"
        name="providerTel"
        pattern={/[^0-9]/g}
        placeholder="생산자/공급자 대표 전화번호"
        type="text"
        validate={[numeric, tel]}
        readOnly={!isPossibleEdit}
      />
      <Field
        className="discover-field"
        component={RenderField}
        label="담당자 이름"
        name="picName"
        placeholder="cs 담당자"
        type="text"
        validate={[]}
        readOnly={!isPossibleEdit}
      />
      <Field
        className="discover-field"
        component={RenderField}
        label="담당자 휴대폰 번호"
        name="picMobile"
        pattern={/[^0-9]/g}
        placeholder="cs 담당자 연락처"
        type="text"
        validate={[numeric, tel]}
        readOnly={!isPossibleEdit}
      />
      <Field
        className="discover-field"
        component={RenderField}
        label="담당자 이메일"
        name="picEmail"
        placeholder="cs 담당자 이메일"
        type="email"
        validate={[]}
        readOnly={!isPossibleEdit}
      />
      <Field
        className="discover-field"
        component={RenderField}
        label="상품 URL"
        name="productUrl"
        placeholder="상품 설명이 적혀 있는 URL"
        validate={[]}
        readOnly={!isPossibleEdit}
        type="text"
      />
      <div className="discover-field discover-image form-group form-image d-flex flex-wrap align-items-top">
        <span className="form-label d-inline-block text-left pr-2 ">
          이미지 첨부
          <span className="text-danger">*</span>
        </span>
        <div className="discover-field-image">
          {productImageList.map((image, index) => {
            return (
              <PictureView
                key={image.id}
                imageSrc={image.image || getImagePath(image)}
                onClick={() => {
                  onRemovePicture(index);
                }}
              />
            );
          })}
          {productImageList && productImageList.length < 5 && (
            <PictureInput
              className="discover-field-image-input"
              onSelect={onSelectProductImage}
              isShownImage={false}
              flagMultiple
            />
          )}
          <span className="discover-field-image-description d-block w-100">
            * (필수) 인증샷을 포함해서 제품 이미지를 올려주세요 ^^
          </span>
        </div>
        {!haveProductImages && (
          <span className="d-block invalid-feedback invalid-feedback-with-label ml-auto">
            *필수 정보 입니다.
          </span>
        )}
      </div>
    </Fragment>
  );
}

DiscoverField.propTypes = {
  isPossibleEdit: PropTypes.bool,
  haveProductImages: PropTypes.bool,
  onOpenProductModal: PropTypes.func,
  onOpenProviderModal: PropTypes.func,
  onRemovePicture: PropTypes.func,
  onSelectProductImage: PropTypes.func,
  productImageList: PropTypes.arrayOf(PropTypes.shape({}))
};

DiscoverField.defaultProps = {
  isPossibleEdit: true,
  haveProductImages: false,
  onOpenProductModal: () => {},
  onOpenProviderModal: () => {},
  onRemovePicture: () => {},
  onSelectProductImage: () => {},
  productImageList: []
};

export default DiscoverField;
