import React from 'react';
import PropTypes from 'prop-types';
import CDN_URL from 'components/commons/globalUrl';

function Loader({ isLoading }) {
  if (!isLoading) {
    return null;
  }

  return (
    <div>
      <img
        src={`${CDN_URL}/from/from_loading_1.gif`}
        className="loader-spinner"
        alt="로딩 이미지"
      />
    </div>
  );
}

Loader.propTypes = {
  isLoading: PropTypes.bool
};

Loader.defaultProps = {
  isLoading: false
};

export default Loader;
