import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProviderLeavePage from 'components/provider/ProviderLeavePage';

function ProviderLeave({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ProviderLeavePage providerId={match.params.providerId} />
    </Fragment>
  );
}

export default ProviderLeave;
