import React, { Fragment } from 'react';
import { CookiesProvider } from 'react-cookie';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProviderSignInContainer from 'containers/provider/ProviderSignInContainer';

function ProviderSignIn() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <CookiesProvider>
        <ProviderSignInContainer />
      </CookiesProvider>
    </Fragment>
  );
}

export default ProviderSignIn;
