import React from 'react';
import classNames from 'classnames';
import OneStoreListItemTemplate from 'components/oneStore/OneStoreListTemplate/OneStoreListItemTemplate';

import CommonProfilePicture from 'components/picture/CommonProfilePicture';

import './OneStoreFollowItem.scss';

function OneStoreFollowItem({ follow, isProvider }) {
  const { targetId, targetName, targetDetailInfo } = follow;
  const { profileImgPath, providerImgPath } = targetDetailInfo || {};

  const linkPath = !isProvider ? `/store/${targetId}` : `/provider/${targetId}`;

  const followProfileImgPath = !isProvider ? profileImgPath : providerImgPath;

  return (
    <OneStoreListItemTemplate linkPath={linkPath} name={targetName}>
      <CommonProfilePicture
        profileImgPath={followProfileImgPath}
        name={targetName}
        size={30}
        className={classNames('onestore-list-template-item-profile-image', {
          'is-provider-profile': isProvider
        })}
      />
    </OneStoreListItemTemplate>
  );
}

export default OneStoreFollowItem;
