import React from 'react';

// https://slides.com/tannerlinsley/custom-hooks-in-react#/64
export default function makeStore(userReducer, initialState, key) {
  const dispatchContext = React.createContext();
  const storeContext = React.createContext();

  console.log({ initialState });

  try {
    initialState = key
      ? JSON.parse(localStorage.getItem(key)) || initialState
      : initialState;
  } catch (err) {
    console.log({ err });
  }

  const reducer = (state, action) => {
    // console.log({ state });
    // console.log({ action });
    const newState = userReducer(state, action);
    if (key) localStorage.setItem(key, JSON.stringify(newState));
    return newState;
  };

  const StoreProvider = ({ children }) => {
    const [store, dispatch] = React.useReducer(reducer, initialState);

    // console.log({ store });
    // console.log({ dispatch });

    return (
      <dispatchContext.Provider value={dispatch}>
        <storeContext.Provider value={store}>{children}</storeContext.Provider>
      </dispatchContext.Provider>
    );
  };

  function useDispatch() {
    return React.useContext(dispatchContext);
  }

  function useStore() {
    return React.useContext(storeContext);
  }

  return [StoreProvider, useStore, useDispatch];
}
