import React, { Fragment, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import Loader from 'components/commons/Loader';
import EmptyList from 'components/commons/EmptyList';

import { useCartStore } from './CartStore';
import { useProvider } from 'hooks/useProviders';

import OptionItem from './OptionItem';
import CartSummary from './CartSummary';
import ProductSlider from 'components/commons/ProductSlider';

import { OptionChooserWrapper } from './OptionChooserStyle';
import { ButtonWrapper, StyledButton } from 'shared/buttons/ButtonStyle';
import { ItemsWrapper } from 'components/cart/ItemStyle';

function OptionChooser({ user, isLoggedIn, UiAction }) {
  const location = useLocation();
  const history = useHistory();
  const {
    itemType = 'cart',
    product = {},
    options = [],
    seller = {},
    // provider = {},
    isOneProduct = true
  } = location.state;

  // console.log({ location });

  const { subtotalsByProduct = [] } = useCartStore();
  const {
    data: provider,
    isLoading,
    isError,
    error
  } = useProvider({
    providerId: product?.providerId
  });
  const {
    htmlProductInfo,
    incentiveStruct,
    priceList,
    // options: oldOptions,
    provider: oldProvider,
    discovery,
    ...newProduct
  } = product;
  // console.log({ newProduct });
  // console.log({ provider });

  useEffect(
    () => {
      UiAction.setHeader(
        itemType !== 'product' ? '상품 옵션 변경' : '상품 옵션 담기'
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (options.length < 1) {
    return (
      <EmptyList
        contentText="상품 옵션이 없습니다."
        subText="다른 상품을 선택해 주세요 ^^"
      />
    );
  }
  // console.log({ subtotalsByProduct });
  const subtotal = subtotalsByProduct.find(
    (subtotal) => subtotal.productId === product.id
  );

  if (isError) return <p>{error.message};</p>;

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <OptionChooserWrapper>
        {isLoading && <Loader />}
        <div className="product-name">
          <Link to={`/product/${product.id}`}>
            {product.name || product.productName}
          </Link>
        </div>

        <ItemsWrapper className="option-list">
          {options.map((option, index) => {
            const { id, isOnDisplay = true } = option;
            if (!isOnDisplay) {
              return null;
            }
            return (
              <OptionItem
                key={id}
                itemType={itemType}
                index={index}
                product={newProduct}
                option={option}
                provider={provider}
                seller={seller}
                buyerIsSeller={seller?.id === user?.id}
                isOneProduct={isOneProduct}
              />
            );
          })}
        </ItemsWrapper>
        {/* <CartSummary className="product-total" type="product" {...subtotal} /> */}
        <ButtonWrapper className="product-action">
          <StyledButton
            type="button"
            onClick={() => history.goBack()}
            className="from-button"
          >
            {itemType !== 'product' ? '옵션 변경 완료' : '쇼핑 계속하기'}
          </StyledButton>
          {itemType === 'product' && (
            <StyledButton
              type="button"
              onClick={() => history.push('/order/cart')}
              className="black-button"
            >
              바구니로 이동
            </StyledButton>
          )}
        </ButtonWrapper>
        {!isLoading && (
          <div className="product-slider">
            <ProductSlider
              className="small small-title"
              // title="*묶음배송을 원한다면"
              title={`${provider?.providerName}의 다른 상품`}
              searchType="providerId"
              searchKey={provider?.id}
              filterBy={
                (item) =>
                  product.id !== item.id &&
                  !(item.flagStopSelling === 11 || item.flagStopSelling === 12)
                // &&
                // !(item.isRecipe || item.isCarrige)
              }
              // sortBy={(a, b) => b.countOf1P - a.countOf1P}
              sortBy={(a, b) => b.sortDate - a.sortDate}

              // onCardClick={(product) => console.log({ product })}
            />
          </div>
        )}
      </OptionChooserWrapper>
    </Fragment>
  );
}

export default withUser(withUi(OptionChooser));
