import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import configureStore from 'redux/configureStore';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import { ThemeProvider } from 'styled-components';
import { ReactQueryDevtools } from 'react-query-devtools';
// import { ModalProvider } from "react-modal-hook";

import { CartProvider } from 'components/cart/CartStore';
import { CheckoutProvider } from 'components/checkout/CheckoutStore';
import { MbtiProvider } from 'components/mbti/MbtiStore';

import { isDevelop } from 'lib/utils/envUtils';
import { GlobalStyle } from 'shared/theme/GlobalStyle';
import { theme } from 'shared/theme/theme';

// pollyfill
// import 'core-js';
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/includes';
import 'core-js/fn/number/is-nan';
import 'core-js/fn/object/entries';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './scss/styles.scss';
import './index.scss';

const isDev = isDevelop();

// window 객체에서 state 값을 받아서 store에 전달 후 삭제
const state = window.__STATE__;
const store = configureStore(state);
delete window.__STATE__;

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <CartProvider>
            <CheckoutProvider>
              <MbtiProvider>
                <BrowserRouter>
                  <Component />
                  {/* {isDev && <ReactQueryDevtools initialIsOpen={false} />} */}
                </BrowserRouter>
              </MbtiProvider>
            </CheckoutProvider>
          </CartProvider>
        </ThemeProvider>
      </Provider>
    </AppContainer>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App.js', () => {
    const NextApp = require('./App.js').default;

    render(NextApp);
  });
}

serviceWorker.unregister();

if (window && window.Raven) {
  const raven = window.Raven;
  const environment = isDev ? 'development' : 'production';
  const opt = {
    environment,
    logger: 'javascript',
    shouldSendCallback: () => {
      return true;
    }
  };

  raven
    .config('https://23f388b8b92b46978a48c9d77514bad0@sentry.io/1235345', opt)
    .install();
}
