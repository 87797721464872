import React from 'react';
import './KakaoLoading.scss';

export default function KakaoLoading({ isLoading }) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="payment-alert">
      <div className="payment-alert-modal text-primary">
        카카오톡 연결 중 입니다.
      </div>
      <div className="payment-alert-backdrop" />
    </div>
  );
}
