import styled, { css } from 'styled-components';

export const ListTitleWrapper = styled.div`
  max-width: var(--max-width, 950px);
`;

export const StyledListTitle = styled.div`
  padding: 32px 16px 12px 16px;

  span {
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.3;
  }

  &.market-best-list {
    padding-top: 53px;

    span {
      font-size: 26px;
    }
  }
  &.market-discover-list {
    padding-top: 83px;
    /*원래 padding-top: 53px; 위에 padding을 먹지 않아서 개별로 값을 줌*/
    /*이름이 list-title, discover로 분리되어 두 번 값을 줌. 정리가 필요함*/

    span {
      font-size: 24px;
      line-height: 1.4;
    }
  }

  &.sub-title {
    padding: 0px 16px 6px 16px;

    &.market-discover-list {
      margin-bottom: 20px;
    }

    span {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.5;
    }
  }
`;
