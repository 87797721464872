import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ServiceContactContainer from 'containers/setting/ServiceContactContainer';

function ServiceContact() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ServiceContactContainer />
    </Fragment>
  );
}

export default ServiceContact;
