import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import Cart from 'components/cart/Cart';
import Checkout from 'components/checkout/Checkout';
import OptionChooser from 'components/cart/OptionChooser';
import Payment from 'components/payment2/Payment';
import PaymentCompleted from 'components/payment2/PaymentCompleted';

import { PostForm, PostDetail, PostList } from 'components/Post';

import Market from 'pages/Market';
import OneStore from 'pages/OneStore';
import OneProduct from 'pages/OneProduct';
import Product from 'pages/Product';
import Provider from 'pages/Provider';

import ReceivedGift from 'pages/ReceivedGift';

// 페이지
import SignUp from 'pages/SignUp';
import SignIn from 'pages/SignIn';

import SignInHelp from 'pages/SignInHelp';

import OneStoreFromList from 'pages/OneStoreFromList';
import OneStoreToList from 'pages/OneStoreToList';
import OneStoreDiscoverSuccessList from 'pages/OneStoreDiscoverSuccessList';
import OneStoreFollowCustomer from 'pages/OneStoreFollowCustomer';
import OneStoreFollowProvider from 'pages/OneStoreFollowProvider';

import StoreInfoSetting from 'pages/StoreInfoSetting';
import Discover from 'pages/Discover';
import Notify from 'pages/Notify';

import OneProductToList from 'pages/OneProductToList';

// 선물
import GiftConfirm from 'pages/GiftConfirm';

import Bookmark from 'pages/Bookmark';

import DiscoverHistory from 'pages/DiscoverHistory';
import DiscoverRequestStatus from 'pages/DiscoverRequestStatus';
import TutorialIntro from 'pages/TutorialIntro';
import Tutorial from 'pages/Tutorial';
import OrderList from 'pages/OrderList';
import OrderStatus from 'pages/OrderStatus';
import OrderDeliveryConfirm from 'pages/OrderDeliveryConfirm';

import SavedMoneyStatus from 'pages/SavedMoneyStatus';
import GiftBox from 'pages/GiftBox';
import DiscoverDetail from 'pages/DiscoverDetail';

import KakaoSignUp from 'pages/KakaoSignUp';

// 이용약관
import ServiceAgreement from 'containers/policy/ServiceAgreement';
import ServicePrivacy from 'containers/policy/ServicePrivacy';
//서비스소개
import GuideUserContainer from 'containers/guide/GuideUserContainer';

import ServiceContact from 'pages/ServiceContact';

import FromSearch from 'pages/FromSearch';

import ProviderLeave from 'pages/ProviderLeave';
import ProviderSignup from 'pages/ProviderSignup';
import ProviderSignIn from 'pages/ProviderSignIn';
import ProviderResetPassword from 'pages/ProviderResetPassword';
import ProviderOneProductContainer from 'pages/ProviderOneProductContainer';
import ProviderOneStoreList from 'pages/ProviderOneStoreList';
import ProviderProduct from 'pages/ProviderProduct';
import ProviderSales from 'pages/ProviderSales';
import ProviderShipping from 'pages/ProviderShipping';
import ProviderEdit from 'pages/ProviderEdit';
import ModifyProduct from 'pages/ModifyProduct';

import RelaySanta from 'pages/RelaySanta';

export const routes = [
  { exact: true, path: '/', render: () => <Redirect to="/market/best" /> },
  { path: '/market', component: Market },
  { exact: true, path: '/discover/register', component: Discover },
  { exact: true, path: '/discover/:discoverId', component: DiscoverDetail },
  { exact: true, path: '/tutorialintro', component: TutorialIntro },
  { exact: true, path: '/tutorial', component: Tutorial },
  { exact: true, path: '/search', component: FromSearch },
  /* 유저 */
  { exact: true, path: '/store/signup', component: SignUp },
  { exact: true, path: '/store/signup/kakao', component: KakaoSignUp },
  { exact: true, path: '/store/signin', component: SignIn },
  { path: '/store/signin/help', component: SignInHelp },

  { path: '/user/:storeId', component: OneStore },

  { exact: true, path: '/store/:storeId', component: OneStore },
  {
    exact: true,
    path: '/store/:storeId/:productId/options/choose',
    component: OptionChooser
  },

  { exact: true, path: '/store/:storeId/notification', component: Notify },
  /* { exact: true, path:"/store/:storeId/setting", component: Setting }, */
  { path: '/store/:storeId/setting/edit', component: StoreInfoSetting },
  /* {
            exact: true,
            path:"/store/:storeId/setting/product"
           , component: StoreProductSetting},
          }, */
  { exact: true, path: '/store/:storeId/setting/order', component: OrderList },
  {
    exact: true,
    path: '/store/:storeId/setting/order/:orderId',
    component: OrderStatus
  },
  /* {
            exact: true,
            path:"/store/:storeId/setting/order/:paymentUid/confirm"
           , component: OrderConfirm},
          }, */
  {
    exact: true,
    path: '/store/:storeId/setting/order/:paymentUid/:deliveryId/confirm',
    component: OrderDeliveryConfirm
  },
  {
    exact: true,
    path: '/store/:storeId/setting/balance',
    component: SavedMoneyStatus
  },
  { path: '/store/:storeId/setting/giftbox', component: GiftBox },
  /* one Store */
  { path: '/store/:storeId/bookmark', component: Bookmark },
  {
    exact: true,
    path: '/store/:storeId/follow/stores',
    component: OneStoreFollowCustomer
  },
  {
    exact: true,
    path: '/store/:storeId/follow/provider',
    component: OneStoreFollowProvider
  },
  // {
  //   exact: true,
  //   path: '/store/:storeId/waitdiscover',
  //   component: WaitDiscover
  // },
  // {
  //   exact: true,
  //   path: '/store/:storeId/waitdiscover/:expId',
  //   component: WaitDiscoverView
  // },
  { exact: true, path: '/store/:storeId/from', component: OneStoreFromList },
  { exact: true, path: '/store/:storeId/to', component: OneStoreToList },
  /* {
            exact: true,
            path:"/store/:storeId/product"
           , component: OneStoreProductList},
          }, */
  {
    exact: true,
    path: '/store/:storeId/discoversuccess',
    component: OneStoreDiscoverSuccessList
  },
  {
    exact: true,
    path: '/store/:storeId/discoverhistory',
    component: DiscoverHistory
  },
  {
    path: '/store/:storeId/discoverhistory/:discoverId',
    component: DiscoverRequestStatus
  },
  /* one product */
  {
    // exact: true,
    path: '/store/:storeId/:productId',
    component: OneProduct
  },
  {
    exact: true,
    path: '/store/:storeId/:productId/to',
    component: OneProductToList
  },
  /* 결제 */
  /* {
            exact: true,
            path:"/store/:storeId/:productId/payment"
           , component: Payment},
          }, */
  // { path: '/store/:storeId/:productId/buy', component: Payment2 },
  // { path: '/store/:storeId/:productId/gift', component: Gift },
  // {
  //   exact: true,
  //   path: '/store/:storeId/:productId/payment/complete',
  //   component: PaymentComplete
  // },
  { exact: true, path: '/gift/:deliveryId', component: ReceivedGift },
  { exact: true, path: '/gift/:deliveryId/confirm', component: GiftConfirm },
  /* product */
  {
    // exact: true,
    path: '/product/:productId',
    component: Product
  },
  /* { path:"/product/:productId/faq", component: ProductFAQ }, */

  /* provider  */
  // {
  //   exact: true,
  //   path: '/provider/:providerId',
  //   render: () => (
  //     <Suspense fallback={<div>Loading...</div>}>
  //       <Provider />
  //     </Suspense>
  //   )
  // },
  {
    exact: true,
    path: '/provider/signin',
    component: ProviderSignIn
  },
  {
    exact: true,
    path: '/provider/signup',
    component: ProviderSignup
  },
  {
    exact: true,
    path: '/provider/signin/resetpassword',
    component: ProviderResetPassword
  },
  {
    exact: true,
    path: '/provider/:providerId/setting/saleslist',
    component: ProviderSales
  },
  {
    exact: true,
    path: '/provider/:providerId/setting/deliveryproducts',
    component: ProviderShipping
  },
  {
    // exact: true,
    path: '/provider/:providerId/setting/product',
    component: ProviderProduct
  },
  {
    // exact: true,
    path: '/provider/:providerId/setting/edit',
    component: ProviderEdit
  },
  {
    exact: true,
    path: '/provider/:providerId/oneproduct',
    component: ProviderOneProductContainer
  },
  {
    exact: true,
    path: '/provider/:providerId/onestore',
    component: ProviderOneStoreList
  },
  {
    // exact: true,
    path: '/provider/product/:discoverId',
    component: ModifyProduct
  },
  {
    // exact: true,
    path: '/provider/:providerId',
    component: Provider
  },
  {
    exact: true,
    path: '/provider/:providerId/leave',
    component: ProviderLeave
  },

  { exact: true, path: '/order/cart', component: Cart },
  { path: '/order/checkout', component: Checkout },
  // { exact: true, path: '/order/checkout/:orderNo', component: Checkout },

  { exact: true, path: '/order/payment', component: Payment },
  {
    exact: true,
    path: '/order/payment/completed',
    component: PaymentCompleted
  },

  { exact: true, path: '/posts', component: PostList },
  {
    exact: true,
    path: '/post/:ownerId/:productId/:commentsId',
    component: PostDetail
  },
  { isPrivate: true, path: '/post/edit', component: PostForm },
  /* 이용약관 */
  { path: '/policy/service', component: ServiceAgreement },
  { path: '/policy/privacy', component: ServicePrivacy },
  /* 서비스가이드 */
  { path: '/service/guide/user', component: GuideUserContainer },
  /* { path:"/service/guide/provider", component:  }, */
  { path: '/service/contact', component: ServiceContact },
  /* { path:"/pdf/fall2019", component: Fall2019 }, */
  /* { path:"/orderverify", component: OrderVerify }, */
  { exact: true, path: '/santa', component: RelaySanta },

  { path: '*', render: () => <Redirect to="/market/best" /> }
];
