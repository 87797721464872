import searchAxios from './searchAxios';
import axios from './defaultAxios';

// 1store 검색(admin) - 사용
export function getSearchStores(values) {
  return searchAxios.get(`/1stores/search`, { params: values });
}

// 1product 검색(admin)
export function getSearch1Products(values) {
  return searchAxios.get(`/product/search`, { values });
}

// provider 검색(app) - 사용
export function getSearchProviders(values) {
  return axios.get(`/provider/search`, { params: values });
}

// provider 검색(app) - 사용
export function getSearchProducts(values) {
  return axios.get(`/search/product`, { params: values });
}

export function getCategorizeSearch(category, values) {
  if (category === '1stores') {
    return getSearchStores(values);
  }
  if (category === 'product') {
    return getSearchProducts(values);
  }
  if (category === 'provider') {
    return getSearchProviders(values);
  }
}

// UI - 검색 태그 리스트 가져오기
export function getSearchTags() {
  return axios.get(`/search/tags`);
  // return {
  //   data: {
  //     data: '선물하기좋아요 평화가깃든밥상',
  //     success: true
  //   }
  // };
}
