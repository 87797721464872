import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import * as bannerApi from 'api/banner';

const INITIALIZE_BANNER = 'banner/INITIALIZE_BANNER';
const GET_BANNER = 'banner/GET_BANNER';

export const initializeBanner = createAction(INITIALIZE_BANNER);
export const getBanner = createAction(GET_BANNER, bannerApi.getBannerInfo);
const initialState = {
  banner: null
};

export default handleActions(
  {
    [INITIALIZE_BANNER]: (state) => {
      return {
        ...state,
        banner: null
      };
    },
    ...pender({
      type: GET_BANNER,
      onSuccess: (state, action) => {
        const { data, success } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            banner: null
          };
        }

        return {
          ...state,
          banner: data
        };
      },
      onFailure: (state) => {
        return { ...state, banner: null };
      }
    })
  },
  initialState
);
