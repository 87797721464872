import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

// import { getImagePath } from 'lib/utils/imageUtils';
import { getFormatDate } from 'lib/time';

import useModal, { useSimpleDialog } from 'hooks/useModal';

import ConfirmModal from 'components/modal/ConfirmModal';
import FortuneModal from 'components/modal/FortuneModal';

import Button from 'components/commons/Button';
import TrackingDelivery from 'components/oneStore/TrackingDelivery';

import './GiftConfirmForm.scss';

function GiftConfirmForm({
  isLoggedIn,
  id,
  confirmedDate,
  recipient = {},
  provider = {},
  product = {},
  buyer = {},
  receiver = {},
  deliverer,
  status,
  isReceivedGift
}) {
  const history = useHistory();
  const [isOpenTrackingDelivery, setIsOpenTrackingDelivery] = useState(false);

  const {
    isOpenDialog,
    handleToggleDialog,
    handleConfirm,
    handleDecline,
    isConfirmed
  } = useSimpleDialog();

  const {
    isOpen: isOpenShareModal,
    handleToggle: toggleShareModal
  } = useModal();

  const isDelivery = product.flagDelivery === 1;

  const onConfirmDelivery = () => {
    history.push(`/gift/${id}/confirm`);
  };

  const { isFortune } = product;
  // console.log({ status, deliverer, isFortune });

  return (
    <div className="gift-confirm-form">
      <div className="form-title">{isDelivery ? '배송지' : '받는 분'}</div>
      {confirmedDate && (
        <div className="form-group">
          <span className="form-label">선물 받은 날짜</span>
          <strong>{getFormatDate(confirmedDate, 'YYYY.MM.DD HH:mm')}</strong>
        </div>
      )}
      <div className="form-group">
        <span className="form-label">이름</span>
        <strong>{recipient?.name}</strong>
      </div>
      <div className="form-group">
        <span className="form-label">휴대폰</span>
        <strong>{recipient?.mobile}</strong>
      </div>
      {isDelivery && (
        <Fragment>
          <div className="form-group">
            <span className="form-label">우편번호</span>
            <strong>{recipient?.zipcode}</strong>
          </div>
          <div className="form-group">
            <span className="form-label">주소</span>
            <strong>{recipient?.addr}</strong>
          </div>
          <div className="form-group">
            <span className="form-label">상세주소</span>
            <strong>{recipient?.addrSub}</strong>
          </div>
          {recipient?.deliveryMessage && (
            <div className="form-group">
              <span className="form-label">배송 메세지</span>
              <strong>{recipient?.deliveryMessage}</strong>
            </div>
          )}
          {recipient?.preferredShipDate && (
            <div className="form-group">
              <span className="form-label">희망 발송일</span>
              <strong>{recipient?.preferredShipDate}</strong>
            </div>
          )}
        </Fragment>
      )}
      {!isFortune && (
        <div className="gift-confirm-form">
          <span className="form-desc">
            배송될 주소를 변경하려면 상품 공급자에게 문의하세요
          </span>
          <div className="form-title">상품공급자</div>
          <div className="provider-info">
            <div>
              <span>{provider.providerName || provider.name}</span>
              {provider.mobile &&
                provider.mobile.replace(
                  /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                  '$1-$2-$3'
                )}
            </div>
            <a
              href={`tel:${provider.mobile}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span> 문의하기 &gt;</span>
            </a>
          </div>
        </div>
      )}

      {status === 'canceled' && (
        <div className="mt-3 text-center">
          <Button
            buttonText="구매 취소된 상품 입니다"
            color="disable-confirm-button"
          />
        </div>
      )}
      {status !== 'canceled' && ( // 취소여부
        <div className="order-status-item-footer">
          {!isFortune && (
            <div>
              <span className="label">배송상태 : </span>
              {!deliverer ? (
                <span className="value">
                  {status === 'ordered'
                    ? '선물을 아직 확인하지 않으셨습니다'
                    : status === 'confirmed'
                    ? '주문 처리 중입니다.'
                    : '배송 준비 중 입니다.'}
                </span>
              ) : (
                <span className="value">
                  {`${deliverer?.name} ${deliverer?.trackingNumber}`}
                  <Button
                    buttonType="button"
                    className={isOpenTrackingDelivery ? 'open' : 'closed'}
                    color="none"
                    buttonText={isOpenTrackingDelivery ? '' : ''}
                    onClick={() =>
                      setIsOpenTrackingDelivery(!isOpenTrackingDelivery)
                    }
                  />
                </span>
              )}
            </div>
          )}
          {isOpenTrackingDelivery && (
            <TrackingDelivery status={status} deliverer={deliverer} />
          )}
          <br />
        </div>
      )}
      {status !== 'canceled' && // 취소여부
      isReceivedGift &&
      (status === 'confirmed' || status === 'issued') ? ( // 운송장 입력여부
        <div>
          <Button
            buttonText={status === 'confirmed' ? '수령 확인' : '사용 하기'}
            color="from"
            className="from-confirm-button"
            onClick={handleToggleDialog}
          />
        </div>
      ) : null}
      {isFortune && (
        <Button
          buttonText={
            isOpenShareModal
              ? '닫기'
              : `${deliverer?.message?.typeName || ''} 포춘쿠키 열기`
          }
          color="from"
          className="from-confirm-button"
          onClick={() => toggleShareModal()}
        />
      )}
      <ConfirmModal
        headerText="주의"
        headerImage="/images/header-image/alert-modal-header-image-sungyoung.svg"
        contentText={`상품을 직접 받은 경우에만 확인을 눌러주세요.`}
        isOpen={isOpenDialog}
        okButtonText="확인"
        onClickOk={onConfirmDelivery}
        onToggle={handleToggleDialog}
      />
      <FortuneModal
        metaData={{
          ...deliverer,
          referrer: receiver?.id === 'NOT_ASSIGN' ? buyer?.id : receiver?.id
        }}
        isOpen={isOpenShareModal}
        onToggle={toggleShareModal}
      />
    </div>
  );
}

export default GiftConfirmForm;
