import React from 'react';
import { Link } from 'react-router-dom';

// import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ProductCard from 'components/commons/ProductCard';
import { getImagePath } from 'lib/utils/imageUtils';

import './SearchItem.scss';

function SearchItem({ searchResults, selectOptionIndex }) {
  if (selectOptionIndex === 2) {
    return (
      <Link
        to={`/store/${searchResults.id}`}
        className="from-search-item d-flex align-items-center pr-3 pt-1 pb-1 pl-3"
      >
        {/* <LazyLoadComponent> */}
        <div
          style={{
            backgroundImage: `url("${
              getImagePath(searchResults.profileImgPath, 100, 100) ||
              '/images/default-profile.svg'
            }")`
          }}
          alt="프로필 사진"
          className="from-search-circle-profile mr-3"
        />
        {/* </LazyLoadComponent> */}
        <div className="from-search-name-box">
          <div className="from-search-name font-weight-bold">
            {searchResults.userName}
          </div>
        </div>
      </Link>
    );
  }
  if (selectOptionIndex === 0) {
    return (
      <Link key={searchResults.id} to={`/product/${searchResults.id}`}>
        <ProductCard
          {...searchResults}
          discoveredDate={
            searchResults.discoveredDate || searchResults.createdDate
          }
        />
      </Link>
    );
  }
  if (selectOptionIndex === 1) {
    return (
      <Link
        to={`/provider/${searchResults.id}`}
        className="from-search-item d-flex align-items-center pr-3 pt-1 pb-1 pl-3"
      >
        {/* <LazyLoadComponent> */}
        <div
          style={{
            backgroundImage: `url("${
              getImagePath(searchResults.providerProfileImgPath, 100, 100) ||
              '/images/default-image.svg'
            }")`
          }}
          alt="프로필 사진"
          className="from-search-square-profile mr-3"
        />
        {/* </LazyLoadComponent> */}
        <div className="from-search-name-box">
          <div className="from-search-name font-weight-bold">
            {searchResults.providerName}
          </div>
        </div>
      </Link>
    );
  }
}

export default SearchItem;
