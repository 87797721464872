/* eslint-disable no-console */
import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import withUser from 'lib/hoc/withUser';
import withUi from 'lib/hoc/withUi';

import useFetch from 'hooks/useFetch';
import { getDiscover } from 'api/discover';

import AlertModal from 'components/modal/AlertModal';
import ShareModal from 'components/modal/ShareModal';
import Loader from 'components/commons/Loader';

import DiscoverDetailView from './DiscoverDetailView';

function DiscoverDetailContainer({ match, history, user, UiAction }) {
  const { discoverId } = match.params;
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const { data: discover, isLoading, message, isOpen, handleToggle } = useFetch(
    getDiscover,
    discoverId
  );

  useEffect(
    () => {
      UiAction.setHeader(`${discover.discovererName || '...'}님이 신청해요`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [discover]
  );

  const handleClose = () => {
    // console.log({ history });
    handleToggle();
    history.goBack();
  };

  const handleClickShareModal = () => {
    setIsOpenShareModal(true);
  };

  const handleToggleShareModal = () => {
    setIsOpenShareModal(!isOpenShareModal);
  };

  const metaData = {
    type: 'discover',
    discover,
    path: match.url,
    referrer: user.id
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <DiscoverDetailView
            discover={discover}
            onClickShareModal={handleClickShareModal}
            isOpenShareModal={isOpenShareModal}
          />
          <AlertModal
            type="errorModal"
            contentText={message}
            isOpen={isOpen}
            onClick={handleClose}
            onToggle={handleClose}
          />
          <ShareModal
            isOpen={isOpenShareModal}
            onToggle={handleToggleShareModal}
            // onClickFacebook={handleToggleShareModal}
            // onClickKakao={handleToggleShareModal}
            metaData={metaData}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export default compose(withUi, withUser, withRouter)(DiscoverDetailContainer);
