import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getImagePath } from 'lib/utils/imageUtils';
import { isDevelop } from 'lib/utils/envUtils';

import CommonModal from 'components/modal/CommonModal';
import './PaymentSuccessModal.scss';

const isDev = isDevelop();
function PaymentSuccessModal({
  buyerId,
  paymentId,
  isOpen,
  isFortune,
  isGift,
  onToggle
}) {
  return (
    <CommonModal
      className="text-center payment-success-modal"
      size="sm"
      headerText={
        isGift
          ? '선물을 보냈습니다'
          : isFortune
          ? '프롬 포춘쿠키가 도착했습니다!'
          : '결제가 완료되었습니다'
      }
      headerImage={getImagePath(
        'https://cdn.from.kr/event/fortune/00.jpg',
        150,
        150
      )}
      // headerImage={
      //   isGift
      //     ? '/images/header-image/sungyoung-header-image.svg'
      //     : isFortune
      //     ? getImagePath('https://cdn.from.kr/event/fortune/00.jpg', 150, 150)
      //     : '/images/header-image/sori-header-image.svg'
      // }
      isOpen={isOpen}
      onToggle={onToggle}
      okButtonText={
        isFortune && !isGift ? '행운 확인하러 가기' : '포춘쿠키 뽑으러 가기' // '계속 쇼핑하기'
      }
      linkPath={
        isFortune && !isGift
          ? `/store/${buyerId}/setting/order`
          : `/product/${isDev ? 'p1547213043542' : 'p1612106064364'}` // '/market/best'
      }
      replace
    >
      <p className="success-modal-body-text">
        {!isFortune && (
          <Fragment>
            <span>구매하신 상품이</span>
            <br />
            <span>
              <Link
                className="success-modal-link"
                to={`/store/${buyerId}`}
                replace
              >
                나의 단골상품 바구니
              </Link>
              {'에 추가되었습니다.'}
            </span>

            <br />
            <br />
          </Fragment>
        )}
        {isGift ? (
          <Fragment>
            <span>
              보낸 선물은
              <br />
              <Link
                className="success-modal-link"
                to={`/store/${buyerId}/setting/giftbox/send`}
                replace
              >
                선물함
              </Link>
              에서 확인 가능합니다.
            </span>
            <br />
          </Fragment>
        ) : isFortune ? (
          <Fragment>
            <span>지금 바로 포춘쿠키를 열어</span>
            <br />
            <span>행운을 확인해 보세요!</span>
            <br />
          </Fragment>
        ) : (
          <Fragment>
            <span>
              {`결제 내역은 `}
              <Link
                className="success-modal-link"
                to={`/store/${buyerId}/setting/order`}
                replace
              >
                주문조회
              </Link>
              에서 확인 가능합니다.
            </span>
            <br />
          </Fragment>
        )}
      </p>
    </CommonModal>
  );
}

PaymentSuccessModal.propTypes = {
  buyerId: PropTypes.string,
  isOpen: PropTypes.bool,
  isGift: PropTypes.bool,
  onToggle: PropTypes.func,
  productName: PropTypes.string,
  paymentId: PropTypes.string
};

PaymentSuccessModal.defaultProps = {
  buyerId: '',
  isOpen: false,
  isGift: false,
  isShownProductButton: false,
  onToggle: () => {},
  productName: '',
  paymentId: ''
};

export default PaymentSuccessModal;
