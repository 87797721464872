import React, { Fragment, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { compose } from 'redux';

import { useProvider } from 'hooks/useProviders';

import NavBar from 'components/ui/NavBar';
import ProviderProfile from 'components/provider/ProviderProfile';
import ProviderProducts from './ProviderProducts';
import ProviderPosts from './ProviderPosts';

import Loader from 'components/loader/Loader';
import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

function ProviderContainer({
  providerId,
  user,
  isProvider,
  isLoggedIn,
  UiAction
}) {
  const history = useHistory();
  const { data: provider, isLoading, isError, error } = useProvider({
    providerId
  });

  // console.log({ provider });

  useEffect(() => {
    // console.log('useEffect for provider container');
    if (provider) {
      if (provider.flagLeave) {
        history.replace({
          pathname: `/provider/${providerId}/leave`,
          state: {
            from: 'provider',
            id: `${providerId}`
          }
        });
        return;
      }

      UiAction.setHeader(`${provider?.providerName || '공급자'}`);
    }
  }, [provider]);

  const oneStoreNavLinks = [
    { name: '공급상품', path: `/provider/${providerId}/products` },
    {
      name: '받은이야기',
      path: `/provider/${providerId}/posts/in`
    },
    {
      name: '보낸이야기',
      path: `/provider/${providerId}/posts/out`
    }
  ];

  if (isError) return <span>Error: {error.message}</span>;

  return (
    <Fragment>
      <NavBar className="with-border" navLinks={oneStoreNavLinks}>
        <ProviderProfile
          {...{
            providerId,
            provider,
            user,
            isLoggedIn,
            isProvider
          }}
        />
      </NavBar>
      {isLoading && <Loader />}

      <Switch>
        <Route
          exact
          path="/provider/:providerId"
          render={() => <Redirect to={`/provider/${providerId}/products`} />}
        />
        <Route
          exact
          path="/provider/:providerId/products"
          render={() => <ProviderProducts {...{ providerId, provider }} />}
        />
        <Route
          exact
          path="/provider/:providerId/posts/out"
          render={() => (
            <ProviderPosts {...{ authorId: providerId, provider }} />
          )}
        />
        <Route
          exact
          path="/provider/:providerId/posts/in"
          render={() => <ProviderPosts {...{ providerId, provider }} />}
        />
      </Switch>
    </Fragment>
  );
}

export default compose(withUser, withUi)(ProviderContainer);
