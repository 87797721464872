import React, { Component, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { getStoreBalance, getStoreBalanceHistory } from 'api/store';

import withUi from 'lib/hoc/withUi';
import storage from 'lib/storage';
import RedirectToHome from 'components/commons/RedirectToHome';
import BalanceSavedMoneyStatus from 'components/setting/BalanceSavedMoneyStatus';
import SavedMoneyList from 'components/setting/SavedMoneyList';
import Loader from 'components/commons/Loader';

class SavedMoneyStatusContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBalanceLoading: true,
      isHistoryLoading: true,
      balanceList: [],
      balanceSavedMoney: 0,
      hasLastKey: false
    };

    this.pageCount = 20;
    this.lastKey = null;
  }

  componentDidMount() {
    const { UiAction } = this.props;
    UiAction.setHeader('적립금 현황');

    this.getBalanceHistory();
    this.getStoreBalance();
  }

  getStoreBalance = async () => {
    const { storeId } = this.props;
    const response = await getStoreBalance(storeId);

    if (!response || !response.data.success) {
      return;
    }

    const { data } = response.data;

    this.setState({
      balanceSavedMoney: data ? data.balanceSavedMoney : 0,
      isBalanceLoading: false
    });
  };

  getBalanceHistory = async () => {
    const { storeId } = this.props;

    const params = {
      storeId,
      pageCount: this.pageCount,
      lastKey: this.lastKey
    };

    try {
      const response = await getStoreBalanceHistory(params);

      if (!response || !response.data.success) {
        throw new Error();
      }

      const { data } = response.data;

      this.lastKey = data.lastKey;

      this.setState({
        balanceList: this.state.balanceList.concat(data.items),
        hasLastKey: !!data.lastKey,
        isHistoryLoading: false
      });
    } catch (e) {
      // 임시
    }
  };

  render() {
    const {
      balanceList,
      balanceSavedMoney,
      hasLastKey,
      isBalanceLoading,
      isHistoryLoading
    } = this.state;
    const userInfo = storage.get('user_userInfo');
    const { match } = this.props;
    const { storeId } = match.params;

    if (isBalanceLoading || isHistoryLoading) {
      return <Loader />;
    }

    return (
      <Fragment>
        <RedirectToHome isGotoHome={!userInfo || userInfo.id !== storeId} />
        <BalanceSavedMoneyStatus
          balanceSavedMoney={balanceSavedMoney}
          userName={userInfo.userName}
        />
        <InfiniteScroll
          pageStart={0}
          loadMore={this.getBalanceHistory}
          hasMore={hasLastKey}
        >
          {/* <SavedMoneyStatusList balanceList={balanceList} /> */}
          <SavedMoneyList balanceList={balanceList} />
        </InfiniteScroll>
      </Fragment>
    );
  }
}

export default compose(withRouter, withUi)(SavedMoneyStatusContainer);
