import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import { getGift } from 'api/gift';

const RECEIVED_GIFT = 'gift/RECEIVED_GIFT';
const GET_KAKAO_SIGNED = 'gift/GET_KAKAO_SIGNED';
const ADD_IMAGE_FILE = 'gift/ADD_IMAGE_FILE';
const REMOVE_IMAGE_FILE = 'gift/REMOVE_IMAGE_FILE';
const CHANGE_IMAGE_FILE = 'gift/CHANGE_IMAGE_FILE';
const SET_TOTAL_SAVED_PRICE = 'gift/SET_TOTAL_SAVED_PRICE';
const SET_TOTAL_DELIVERY_PRICE = 'gift/SET_TOTAL_DELIVERY_PRICE';
const SET_TOTAL_COUNT = 'gift/SET_TOTAL_COUNT';
const SET_TOTAL_PRICE = 'gift/SET_TOTAL_PRICE';
const CLEAR_DATA = 'gift/CLEAR_DATA';

export const changeKakaoButton = createAction(GET_KAKAO_SIGNED);
export const addImageFile = createAction(ADD_IMAGE_FILE);
export const removeImageFile = createAction(REMOVE_IMAGE_FILE);
export const changeImageFile = createAction(CHANGE_IMAGE_FILE);
export const getReceivedGift = createAction(RECEIVED_GIFT, getGift);

export const setTotalSavedPrice = createAction(SET_TOTAL_SAVED_PRICE);
export const setTotalDeliveryPrice = createAction(SET_TOTAL_DELIVERY_PRICE);
export const setTotalCount = createAction(SET_TOTAL_COUNT);
export const setTotalPrice = createAction(SET_TOTAL_PRICE);
export const clearData = createAction(CLEAR_DATA);

const initialState = {
  imageFileList: [],
  isSignedKakao: false,
  receivedGift: null,
  totalCount: 0,
  totalDeliveryPrice: 0,
  totalPrice: 0,
  totalSavedPrice: 0
};

export default handleActions(
  {
    [GET_KAKAO_SIGNED]: (state, action) => {
      return {
        ...state,
        isSignedKakao: action.payload
      };
    },
    [ADD_IMAGE_FILE]: (state, action) => {
      const { imageFileList } = state;

      return {
        ...state,
        imageFileList: [...imageFileList, ...action.payload]
      };
    },
    [REMOVE_IMAGE_FILE]: (state, action) => {
      const { imageFileList } = state;

      return {
        ...state,
        imageFileList: [
          ...imageFileList.slice(0, action.payload),
          ...imageFileList.slice(action.payload + 1)
        ]
      };
    },
    [CHANGE_IMAGE_FILE]: (state, action) => {
      const { imageFileList } = state;
      const { index, imageFile } = action.payload;

      return {
        ...state,
        imageFileList: [
          ...imageFileList.slice(0, index),
          { ...imageFile },
          ...imageFileList.slice(index + 1)
        ]
      };
    },
    [SET_TOTAL_SAVED_PRICE]: (state, action) => {
      return {
        ...state,
        totalSavedPrice: action.payload
      };
    },
    [SET_TOTAL_DELIVERY_PRICE]: (state, action) => {
      return {
        ...state,
        totalDeliveryPrice: action.payload
      };
    },
    [SET_TOTAL_COUNT]: (state, action) => {
      return {
        ...state,
        totalCount: action.payload
      };
    },
    [SET_TOTAL_PRICE]: (state, action) => {
      return {
        ...state,
        totalPrice: action.payload
      };
    },
    [CLEAR_DATA]: (state) => {
      return {
        ...state,
        imageFileList: [],
        totalCount: 0,
        totalDeliveryPrice: 0,
        totalSavedPrice: 0,
        totalPrice: 0,
        isSignedKakao: false
      };
    },
    ...pender({
      type: RECEIVED_GIFT,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            receivedGift: null
          };
        }

        return {
          ...state,
          receivedGift: data
        };
      },
      onFailure: (state) => {
        return { ...state, receivedGift: null };
      }
    })
  },
  initialState
);
