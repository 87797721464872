import axios from './defaultAxios';

// 1Product 정보 가져오기
export function getOneProduct(storeId, productId) {
  return axios.get(`/store/${storeId}/${productId}`, {
    params: {
      storeId,
      productId
    }
  });
}

// 1Product From Tp LIst 가져오기
export function getToList(params) {
  return axios.get(`/store/${params.storeId}/${params.productId}/tostores`, {
    params
  });
}

// 1Product 추천사 가져오기
export function getRecommendation(params) {
  return axios.get(
    `/store/${params.storeId}/${params.productId}/recommendation`,
    {
      params
    }
  );
}

// 1Product experience list 가져오기
export function getOneProductExperience(params) {
  return axios.get(
    `/store/${params.storeId}/${params.productId}/1experiences`,
    {
      params
    }
  );
}

// 1Product experience list item 삭제
export function deleteOneProductExperience(params) {
  return axios.delete(
    `/store/${params.storeId}/${params.productId}/1experiences/${params.expId}`,
    {
      params
    }
  );
}

// 1Prodduct expereince 대표 이미지 변경
export function setDefaultExperience(values) {
  return axios.put(
    `/store/${values.storeId}/${values.productId}/representationimg`,
    values
  );
}

// 1Product 상품 incentive 수정
export function putOneProductIncentive(values) {
  return axios.put(
    `/store/${values.storeId}/${values.productId}/incentive`,
    values
  );
}

export function putOneProductPublic(values) {
  return axios.put(
    `/store/${values.storeId}/${values.productId}/public`,
    values
  );
}

export function putRecommendation(values) {
  return axios.put(
    `/store/${values.storeId}/${values.productId}/recommendation`,
    values
  );
}
