import styled, { css } from 'styled-components';

export const CardListWrapper = styled.main`
  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 5px;
  max-width: 100%;
  align-items: center;
  justify-items: center;
  justify-content: center;
 */
  /* display: flex;
  flex-direction: column;
  align-items: center; */

  margin: 20px auto;

  /* grid-template-areas:
    ' . list-title . '
    'list-body list-body list-body'
    ' . list-button . '; */
`;

export const InfiniteListWrapper = styled.div`
  /* grid-area: list-body; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(172.5px, 1fr));
  grid-template-rows: auto;
  column-gap: 15px;
  row-gap: 20px;
  align-items: center;
  justify-items: center;
  justify-content: center;

  margin: 10px auto;
  max-width: var(--max-width, 950px);

  ${(props) =>
    props.isOneColumn &&
    css`
      grid-template-columns: minmax(346px, 500px);
    `}
`;

export const StyledLoadMoreButton = styled.div`
  /* grid-area: list-button; */
  width: 90%;
  height: auto;
  margin: 10px auto;
  padding: 10px;

  font-size: 16px;
  text-align: center;

  img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin: 5px auto;
  }

  span {
    color: var(--from-color, green);
    cursor: pointer;
  }
`;
