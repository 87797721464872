import React from 'react';
// import CDN_URL from 'components/commons/globalUrl';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';

import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { getImagePath } from 'lib/utils/imageUtils';
import { getFormatDate } from 'lib/time';
// import { truncateString } from 'lib/utils/stringUtils';
import Button from 'components/commons/Button';

import './OrderConfirmView.scss';

function OrderConfirmView({
  amount,
  storeId,
  productName,
  providerName,
  afterDate,
  // productImgPath,
  onClickConFirm,
  initialLoading,
  isGiftConfirm,
  btnLoading,
  deliveryId,
  paymentUid,
  providerId,
  selectOpt,
  receiverName,
  receiverMobile
}) {
  const qrcodeObj = { deliveryId, providerId };

  return (
    <div className="order-confirm-view">
      <div className="order-confirm-box">
        <Link
          to={
            !isGiftConfirm
              ? `/store/${storeId}/setting/order/${paymentUid}`
              : `/store/${storeId}/setting/gift/${deliveryId}`
          }
        >
          <LazyLoadImage
            alt="상품 이미지"
            src="/images/confirm-close-icon.png"
            className="confirm-close-img"
            visibleByDefault
          />
        </Link>
        <div className="order-confirm-in-box">
          <div className="confirm-letter-box">
            <div className="mt-3 text-center">
              <div className="gray-text">{providerName}</div>
              <div className="bold-black-text">{productName}</div>
            </div>
            <div className="confirm-letter-img">
              {/* <LazyLoadImage
                alt="상품 이미지"
                src={
                  getImagePath(productImgPath) || '/images/default-image.svg'
                }
                visibleByDefault
              /> */}
              {/* qrcode 렌더 */}
              <QRCode value={JSON.stringify(qrcodeObj)} size={180} />
            </div>
            <div className="confirm-letter-id-box">
              <div className="letter-small-text-box">
                {selectOpt && amount && (
                  <div className="letter-small-text opt-bold">
                    <span>{`${selectOpt} X ${amount}`}</span>
                  </div>
                )}
                <div className="letter-small-text">
                  <span>구매일자</span>
                  <span className="letter-small-right">
                    {getFormatDate(afterDate, 'YYYY.MM.DD')}
                  </span>
                </div>
                <div className="letter-small-text">
                  <span>{receiverName}</span>
                  <span className="letter-small-right">{receiverMobile}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <img
            src="/images/confirm-bg.png"
            className="confirm-letter-envelope"
            alt="편지 봉투"
          /> */}
          <div className="confirm-letter-text-box">
            <div className="confirm-letter-alert">
              * 공급자에게 꼭 보여주시고 확인을 받으세요.
            </div>
            {/* <button
              className="confirm-letter-btn btn "
              onClick={onClickConFirm}
              disabled={initialLoading}
            >
              현장 확인
            </button> */}
            <Button
              buttonText="수령 확인"
              buttonType="button"
              className="confirm-letter-btn"
              color="from"
              onClick={onClickConFirm}
              disabled={initialLoading}
              isLoading={btnLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderConfirmView;
