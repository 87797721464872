import React from 'react';
import CDN_URL from 'components/commons/globalUrl';

import './Loader.scss';

function Loader() {
  return (
    <div className="loader">
      <div className="loader-box">
        <img
          src={`${CDN_URL}/from/from_loading_1.gif`}
          className="loader-spinner"
          alt="로딩 이미지"
        />
        {/* <div className="loader-backdrop" /> */}
      </div>
    </div>
  );
}

export default Loader;
