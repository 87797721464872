import styled, { css } from 'styled-components';

export const CheckoutWrapper = styled.div`
  max-width: var(--max-content-width, 420px);
  margin: 0px auto;

  .provider-cart-list {
    margin-top: 0px;
  }

  .provider-cart {
    margin-top: 18px;

    /* padding-bottom: 16px;
    border-bottom: 9px solid #f2f2f2; */

    .provider-name {
      font-size: 1rem;
      padding-left: 35px;
      padding-bottom: 10px;
      border-bottom: 1px solid #f2f2f2;
    }
  }
`;

export const CheckoutMainWrapper = styled.div`
  margin: 0px auto;
  /* margin-top: 3rem; */
  margin-bottom: 5rem;
`;

export const StyledCheckoutHeader = styled.header`

  z-index: 10;
  position: sticky;
  top: var(--header-height, 60px);
  /* left: 50%;
  transform: translateX(-50%); */

  width: 100%;
  min-height: 4rem;
  /* background-color: var(--border-color); */
  transition: top 0.4s ease-in-out;

  &.hidden-header {
    top: 0px;
    transition: top 0.3s ease-in-out;
  }
  /* button {
    margin: 10px;
    min-width: 50px;
    color: black;
    font-weight: 800;
    color: var(--from-color);
    border: solid 1px var(--from-color);
    background-color: white;
    padding: 5px;
  } */
`;
