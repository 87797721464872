import React, { Fragment } from 'react';

import { ButtonWrapper, StyledButton } from 'shared/buttons/ButtonStyle';
function ProductButtonGroup({
  isVisible = true,
  isProvider,
  isUp,
  isRecipe,
  showHeader,
  onClickOrderButton,
  onClickSubscribeButton,
  onClickShareButton
}) {
  if (!isVisible || isProvider) {
    return null;
  }

  return (
    <ButtonWrapper
      className={`footer-button-wrapper ${
        !showHeader ? 'hidden-buttons' : ''
      } ${isUp ? 'iphone-x-buttons' : ''}`}
    >
      <StyledButton
        type="button"
        onClick={onClickShareButton}
        className="footer-button white-button narrow-button"
      >
        <i
          className="button-image-icon"
          style={{
            backgroundImage: `url('/images/share-icon.svg')`
          }}
        />
        {/* 응원하기 */}
      </StyledButton>
      {isRecipe ? (
        <StyledButton
          type="button"
          onClick={() => onClickOrderButton('buy')}
          // onClick={() => onClickSubscribeButton()}
          className="purchase-button gift-button"
        >
          구독하기
        </StyledButton>
      ) : (
        <Fragment>
          {/* <StyledButton
            type="button"
            onClick={() => onClickOrderButton('gift')}
            className="purchase-button gift-button"
          >
            바로 결제
          </StyledButton> */}

          <StyledButton
            type="button"
            onClick={() => onClickOrderButton('cart')}
            className="footer-button from-button wide-button"
          >
            바구니 담기
          </StyledButton>
        </Fragment>
      )}
    </ButtonWrapper>
  );
}

export default ProductButtonGroup;
