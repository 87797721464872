import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import DiscoverContainer from 'containers/oneStore/DiscoverContainer';

function Discover({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <DiscoverContainer category={match.params.category} />
    </Fragment>
  );
}

export default Discover;
