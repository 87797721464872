import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProviderResetPasswordContainer from 'containers/provider/ProviderResetPasswordContainer';

function ProviderResetPassword() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ProviderResetPasswordContainer />
    </Fragment>
  );
}

export default ProviderResetPassword;
