import React, { useRef } from 'react';
import {
  ImageInputWithPreviewWrapper,
  ImagePreviewWrapper,
  InvisibleImageInput
} from './ImageInputWithPreviewStyle';

function ImagePreview({ image, index, onDelete, onToggle, isLarge }) {
  const imageUrl = image && image.path ? image.path : image;
  // console.log(typeof imageUrl);
  return (
    <ImagePreviewWrapper isLarge={isLarge}>
      <img src={imageUrl} alt="preview" onClick={onToggle} />
      <button type="button" onClick={() => onDelete(index)}>
        <img src="/images/small-x-icon-1.svg" alt="delete" />
      </button>
    </ImagePreviewWrapper>
  );
}

function ImageInputWithPreview({ images, name, ...rest }) {
  const fileInputRef = useRef(null);

  const onAdd = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <ImageInputWithPreviewWrapper>
      {images &&
        images.length > 0 &&
        images.map((image, index) => (
          <ImagePreview
            key={image && image.path ? image.path : image}
            image={image}
            index={index}
            {...rest}
          />
        ))}

      <button type="button" onClick={onAdd}>
        <img src="/images/add-picture-icon-1.svg" alt="이미지 추가" />
      </button>
      <InvisibleImageInput
        ref={fileInputRef}
        id={name}
        name={name}
        type="file"
        accept="image/png, image/jpeg"
        {...rest}
      />
    </ImageInputWithPreviewWrapper>
  );
}

export default ImageInputWithPreview;
