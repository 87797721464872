import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {
  change,
  formValueSelector,
  stopAsyncValidation,
  startAsyncValidation
} from 'redux-form';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { s3Upload } from 'lib/aws/s3Upload';
import ProviderSignupForm from 'components/admin/ProviderSignupForm';
import AlertModal from 'components/modal/AlertModal';
import LogoModal from 'components/modal/LogoModal';
import AgreementModal from 'components/modal/AgreementModal';

import withUi from 'lib/hoc/withUi';

import defaultAxios from 'api/defaultAxios';

import { alphanumeric, idMinLength6 } from 'lib/form/validations';

class SignUpProviderContainer extends Component {
  constructor() {
    super();

    this.state = {
      isOpenErrorModal: false,
      isOpenSuccessModal: false,
      errorMessage: '',
      businessLicenseImage: null,
      passbookImage: null,
      isLoading: false,
      isOpenSearchAddress: false,
      isCheckedId: false,
      providerProfileImage: null
    };
  }

  componentDidMount() {
    const { UiAction } = this.props;

    UiAction.setHeader('공급자 회원가입');
    this.browserCheck();
  }

  /* 이미지 관련 함수 */
  getImage = (image) => {
    if (!image) {
      return null;
    }

    if (typeof image === 'object') {
      if (image.hasOwnProperty('path')) {
        return image.path;
      }

      return image.image;
    }

    return image;
  };

  browserCheck = () => {
    const agent = navigator.userAgent.toLowerCase();
    if (
      (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) ||
      agent.indexOf('msie') !== -1
    ) {
      // ie일 경우
      this.setState({
        isOpenErrorModal: true,
        errorMessage: `현재 브라우저는 IE(인터넷익스플로어)입니다.
        해당 사이트는 크롬에 최적화 되어있습니다.
        공급자가입이 불가하니 크롬을 이용해주세요`
      });
    }
  };

  submitFormData = (values) => {
    return new Promise((resolve, reject) => {
      values.addrGeolocation = this.addrGeolocation;

      defaultAxios
        .post('/provider', values)
        .then((res) => {
          const { data } = res;

          if (!res.data.success) {
            data.errorMessage = '등록에 실패하였습니다.';

            reject(data);
          }

          resolve();
        })
        .catch((err) => {
          err.errorMessage = '등록에 실패하였습니다.';

          reject(err);
        });
    });
  };

  handleSignUp = (values) => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        const {
          providerProfileImage,
          businessLicenseImage,
          passbookImage
        } = this.state;

        try {
          let uploadImage = null;

          if (providerProfileImage) {
            uploadImage = {};

            uploadImage.path = await s3Upload(
              this.getImage(providerProfileImage),
              'providerProfileImage',
              '프로필 사진을 업로드 하는 중 문제가 발생했습니다.'
            );

            uploadImage.width = providerProfileImage.width;
            uploadImage.height = providerProfileImage.height;

            values.providerProfileImgPath = uploadImage;
          }

          if (businessLicenseImage && values.providerType > 1) {
            const businessLicenseImgPath = await s3Upload(
              this.getImage(businessLicenseImage),
              'businessLicenseImage',
              '사업자 등록증 사본을 업로드 하는 중 문제가 발생했습니다.'
            );

            values.businessLicenseImgPath = businessLicenseImgPath;
          }

          if (passbookImage) {
            const passbookImgPath = await s3Upload(
              this.getImage(passbookImage),
              'passbookImage',
              '정산 통장 사본을 업로드 하는 중 문제가 발생했습니다.'
            );

            values.passbookImgPath = passbookImgPath;
          }

          if (values.providerType === '1') {
            delete values.businessLicense;
          }

          await defaultAxios.post('/provider', values).then((res) => {
            if (!res.data.success) {
              this.setState({
                isOpenErrorModal: true,
                errorMessage: '등록에 실패하였습니다.',
                isLoading: false
              });
              return;
            }

            this.setState({
              isLoading: false,
              isOpenSuccessModal: true
            });
          });
        } catch (err) {
          this.setState({
            isOpenErrorModal: true,
            errorMessage: '등록에 실패하였습니다',
            isLoading: false
          });
        }
      }
    );
  };

  handleChangeId = () => {
    this.setState({
      isCheckedId: false
    });
  };

  /* 이벤트 */
  handleChangeImageFile = (imageFileType, imageFile) => {
    this.setState({
      [imageFileType]: imageFile
    });
  };

  handleToggleSearchAddress = (e) => {
    const { isOpenSearchAddress } = this.state;

    this.setState({
      isOpenSearchAddress: !isOpenSearchAddress
    });

    e.target.blur();
  };

  handleChangeaddrGeolocation = (addrGeolocation) => {
    const { changeField } = this.props;
    const { address, zonecode } = addrGeolocation;

    changeField('addr', address);
    changeField('zipcode', zonecode || addrGeolocation.zipcode);
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  handleCheckId = () => {
    const { id } = this.props;
    if (idMinLength6(id) || alphanumeric(id) || !id) {
      return;
    }
    this.props.startAsyncValidation('providerFrontSignUpForm');

    defaultAxios
      .get('/provider/signup', {
        params: {
          id: id.trim()
        }
      })
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              isCheckedId: true
            },
            () => {
              this.props.stopAsyncValidation({});
            }
          );

          return;
        }

        this.setState(
          {
            isCheckedId: false
          },
          () => {
            this.props.stopAsyncValidation({
              id: '이미 회원이시네요. 로그인 해주세요'
            });
          }
        );
      })
      .catch(() => {
        this.setState(
          {
            isCheckedId: false
          },
          () => {
            this.props.stopAsyncValidation({
              id: '통신 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.'
            });
          }
        );
      });
  };

  handleMoveSignin = () => {
    const { history, location } = this.props;
    let signinUrl = '/provider/signin';

    if (location && location.search) {
      signinUrl += location.search;
    }

    this.setState(
      {
        isOpenSuccessModal: false
      },
      () => {
        history.replace(signinUrl);
      }
    );
  };

  handleChangeCheckBox = (checkboxName) => {
    const { [checkboxName]: isChecked } = this.state;

    this.setState({
      [checkboxName]: !isChecked
    });
  };

  handleOpenAgreementModal = (file) => {
    this.setState({
      agreeUrl: file,
      isOpenAgreementModal: true
    });
  };

  render() {
    const { id, providerType } = this.props;
    const {
      isLoading,
      isOpenSearchAddress,
      errorMessage,
      businessLicenseImage,
      isCheckedId,
      isOpenErrorModal,
      isOpenSuccessModal,
      isOpenAgreementModal,
      isCheckdPrivacy,
      isCheckedAgreement,
      agreeUrl
    } = this.state;

    return (
      <Fragment>
        <ProviderSignupForm
          errorMessage={errorMessage}
          businessLicenseImage={businessLicenseImage}
          isCheckedId={isCheckedId}
          isLoading={isLoading}
          isOpenSearchAddress={isOpenSearchAddress}
          isPrivate={providerType === '1'}
          onChangeImage={this.handleChangeImageFile}
          onChangeAddress={this.handleChangeaddrGeolocation}
          onToggleAddress={this.handleToggleSearchAddress}
          onSubmit={this.handleSignUp}
          onToggleError={this.handleToggleSignUpError}
          onCheckId={this.handleCheckId}
          onChangeId={this.handleChangeId}
          urlId={id}
          isCheckedAgreement={isCheckedAgreement}
          isCheckdPrivacy={isCheckdPrivacy}
          onChangeCheckbox={this.handleChangeCheckBox}
          onOpenAgreementModal={this.handleOpenAgreementModal}
        />
        <AgreementModal
          isOpen={isOpenAgreementModal}
          agreeUrl={agreeUrl}
          onToggle={() => {
            this.handleCloseModal('isOpenAgreementModal');
          }}
        />
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
        <LogoModal
          isOpen={isOpenSuccessModal}
          contentText="회원가입이 완료되었습니다."
          buttonText="로그인 화면으로 가기"
          headerText="가입 완료"
          onToggle={this.handleMoveSignin}
          onClick={this.handleMoveSignin}
        />
      </Fragment>
    );
  }
}

const selector = formValueSelector('providerSignUpForm');

const mapStateToProps = (state) => {
  return {
    id: selector(state, 'id'),
    providerType: selector(state, 'providerType')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeField: (field, value) => {
      dispatch(change('providerSignUpForm', field, value));
    },
    stopAsyncValidation: (errorObj) => {
      dispatch(stopAsyncValidation('providerSignUpForm', errorObj));
    },

    startAsyncValidation: () => {
      dispatch(startAsyncValidation('providerSignUpForm'));
    }
  };
};

export default compose(
  withRouter,
  withUi,
  connect(mapStateToProps, mapDispatchToProps)
)(SignUpProviderContainer);
