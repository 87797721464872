import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ModalDialog from 'components/modal/ModalDialog';
import Button from 'components/commons/Button';

import { getImagePath } from 'lib/utils/imageUtils';

function CommonModal({
  className,
  contentText,
  isOpen,
  onToggle,
  headerText,
  headerImage,
  size,
  children,
  // first button
  okButtonText,
  okButtonLoading,
  closeButtonText,
  onClickOk,
  linkPath,
  linkstate,
  replace,
  // second button
  secondButtonText,
  onClickSecond,
  secondLinkPath,
  secondLinkState,
  secondReplace,
  // theme
  isProviderModal,
  // decoration
  hasDecoration,
  topDecoImgPath,
  sideDecoImgPath,
  isYellow,
  isFortune,
  isGray
}) {
  const themeColor = isFortune
    ? 'fortune'
    : isYellow
    ? 'yellow'
    : isGray
    ? 'gray'
    : !isProviderModal
    ? 'from'
    : 'provider';
  return (
    <ModalDialog
      size={size}
      isOpen={isOpen}
      onToggle={onToggle}
      className={classNames(className)}
    >
      {hasDecoration && (
        <img
          src={getImagePath(topDecoImgPath)}
          alt="공지아이콘"
          className="modal-center-top-icon"
        />
      )}
      {headerImage && (
        <div className="modal-header-image">
          {hasDecoration && (
            <div className="decorate-img-wrapper">
              <div
                className="decorate-img"
                style={{
                  backgroundImage: `url("${getImagePath(sideDecoImgPath)}")`
                }}
              />
            </div>
          )}
          <div
            className={classNames(
              'center-circle-image',
              `center-circle-image-border-${themeColor}`
            )}
            style={{
              backgroundImage: `url(${getImagePath(headerImage)})`
            }}
          />
          {hasDecoration && (
            <div className="decorate-img-wrapper">
              <div
                className="decorate-img"
                style={{
                  backgroundImage: `url("${getImagePath(sideDecoImgPath)}")`
                }}
              />
            </div>
          )}
        </div>
      )}
      {headerText && (
        <div className="text-center modal-header-text">{headerText}</div>
      )}
      {children}
      {contentText && (
        <p className="text-center modal-body-text">
          {contentText.split('\n').map((line) => {
            return (
              <span key={line}>
                {line}
                <br />
              </span>
            );
          })}
        </p>
      )}
      <div className="text-center mt-3 col-button-group">
        {okButtonText && (
          <Button
            buttonType={linkPath ? 'link' : ''}
            className="btn"
            onClick={onClickOk}
            color={themeColor === 'fortune' ? 'from' : themeColor}
            buttonText={okButtonText}
            isLoading={okButtonLoading}
            to={linkPath}
            state={linkstate}
            replace={replace}
          />
        )}
        {!secondButtonText && (
          <Button
            className="btn btn-close"
            onClick={onToggle}
            color={`close-${themeColor === 'fortune' ? 'from' : themeColor}`}
            buttonText={closeButtonText || '닫기'}
          />
        )}
        {secondButtonText && (
          <Button
            buttonType={secondLinkPath ? 'link' : ''}
            className="btn"
            onClick={onClickSecond}
            color={`close-${themeColor === 'fortune' ? 'from' : themeColor}`}
            buttonText={secondButtonText}
            to={secondLinkPath}
            state={secondLinkState}
            replace={secondReplace}
          />
        )}
      </div>
    </ModalDialog>
  );
}

CommonModal.propTypes = {
  className: PropTypes.string,
  contentText: PropTypes.string,
  isOpen: PropTypes.bool,
  okButtonText: PropTypes.string,
  onClickOk: PropTypes.func,
  onToggle: PropTypes.func,
  size: PropTypes.string
};

CommonModal.defaultProps = {
  className: '',
  contentText: '',
  isOpen: false,
  okButtonText: '',
  size: 'sm',
  onClickOk: () => {},
  onToggle: () => {}
};

export default CommonModal;
