import React from 'react';
import { Link } from 'react-router-dom';

import { postLogOutUser } from 'api/store';
import sentry from 'lib/sentry';
import { clearSession } from 'lib/users/auth';

import './ProviderSettingMenu.scss';

function ProviderSettingMenu({ providerId, user }) {
  function handleSignOut() {
    const userAgent = navigator.userAgent.toLowerCase();

    let browserType = 'web';

    if (userAgent.indexOf('1store_iphone') > -1) {
      browserType = 'ios';
    }
    if (userAgent.indexOf('1store_android') > -1) {
      browserType = 'android';
    }

    const params = {
      userType: 'provider',
      userId: user.id,
      userAgent: browserType
    };

    try {
      const response = postLogOutUser(params);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`공급자 로그아웃실패 - ${JSON.stringify(response)}`);
      }

      clearSession();

      window.location.href = '/';
    } catch (e) {
      sentry.captureException(JSON.stringify(e));

      clearSession();

      window.location.href = '/';
    }
  }

  return (
    <div className="provider-setting-menu-wrapper">
      <ul>
        <li>
          <Link
            to={`/provider/${providerId}/setting/product/success`}
            className="provider-setting-menu"
          >
            <img
              src="/images/provider-setting-product-icon.svg"
              alt="공급자 상품 관리"
              className="provider-setting-menu-icon"
            />
            상품 관리
            <img
              src="/images/onestore-setting-link-icon.svg"
              alt="화살표"
              className="provider-setting-link-icon"
            />
          </Link>
        </li>
        <li>
          <Link
            to={`/provider/${providerId}/setting/deliveryproducts`}
            className="provider-setting-menu"
          >
            <img
              src="/images/provider-setting-delivery-icon.svg"
              alt="공급자 공급 관리"
              className="provider-setting-menu-icon"
            />
            배송 관리
            <img
              src="/images/onestore-setting-link-icon.svg"
              alt="화살표"
              className="provider-setting-link-icon"
            />
          </Link>
        </li>
        <li>
          <Link
            to={`/provider/${providerId}/setting/saleslist`}
            className="provider-setting-menu"
          >
            <img
              src="/images/provider-setting-sales-icon.svg"
              alt="공급자 결제내역"
              className="provider-setting-menu-icon"
            />
            결제 내역
            <img
              src="/images/onestore-setting-link-icon.svg"
              alt="화살표"
              className="provider-setting-link-icon"
            />
          </Link>
        </li>
        <li>
          <button className="provider-setting-menu" onClick={handleSignOut}>
            <img
              src="/images/provider-setting-logout-icon.svg"
              alt="공급자 로그아웃"
              className="provider-setting-menu-icon"
            />
            로그아웃
            <img
              src="/images/onestore-setting-link-icon.svg"
              alt="화살표"
              className="provider-setting-link-icon"
            />
          </button>
        </li>
      </ul>
    </div>
  );
}

export default ProviderSettingMenu;
