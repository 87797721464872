import React, { Fragment } from 'react';
import useIntersectionObserver from 'shared/hooks/useIntersectionObserver';

import EmptyList from 'components/commons/EmptyList';
import Loader from 'components/commons/Loader';
import {
  InfiniteListWrapper,
  StyledLoadMoreButton
} from './styled/InfiniteListStyle';
import CDN_URL from 'components/commons/globalUrl';

function InfiniteList({
  data,
  filterBy = null,
  status,
  error,
  fetchMore,
  canFetchMore,
  isFetching,
  isFetchingMore,
  isOneColumn = false,
  isDisabled = false,
  children
}) {
  const loadMoreButtonRef = React.useRef(null);

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchMore,
    enabled: canFetchMore && !isDisabled
  });

  // console.log({ data });
  // console.log({filterBy})
  const filterFunc = filterBy ? filterBy : (item) => true;
  return (
    <Fragment>
      {status === 'loading' ? (
        <Loader />
      ) : status === 'error' ? (
        <span>Error: {error.message}</span>
      ) : !(data?.length > 0) ? (
        <EmptyList />
      ) : (
        <Fragment>
          <InfiniteListWrapper isOneColumn={isOneColumn}>
            {data.map((page, i) => (
              <Fragment key={i}>
                {page.Items.filter(filterFunc).map((item) => children(item))}
              </Fragment>
            ))}
          </InfiniteListWrapper>
          <StyledLoadMoreButton
            ref={loadMoreButtonRef}
            onClick={() => {
              if (canFetchMore && !isFetchingMore) {
                // console.log('More Button clicked');
                fetchMore();
              }
            }}
          >
            {isFetchingMore ? (
              <img
                src={`${CDN_URL}/from/from_loading_1.gif`}
                alt="Loading ..."
              />
            ) : canFetchMore ? (
              <span>더보기 ...</span>
            ) : null}
          </StyledLoadMoreButton>
        </Fragment>
      )}

      {/* <LoadMoreButton
        ref={loadMoreButtonRef} // onClick이 작동하기 위해서는 forwardRef 적용해야함
        onClick={() => {
          if (canFetchMore && !isFetchingMore) {
            console.log('More Button clicked');
            fetchMore();
          }
        }}
        isFetchingMore={isFetchingMore}
        canFetchMore={canFetchMore}
      /> */}
    </Fragment>
  );
}

// function LoadMoreButton({ onClick, isFetchingMore, canFetchMore }) {
//   return (
//     <StyledLoadMoreButton onClick={onClick}>
//       {isFetchingMore ? (
//         <img src={`${CDN_URL}/from/from_loading_1.gif`} alt="Loading ..." />
//       ) : canFetchMore ? (
//         'More ...'
//       ) : null}
//       {/* </div> */}
//     </StyledLoadMoreButton>
//   );
// }

export default InfiniteList;
