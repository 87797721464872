import React, { Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';

import Button from 'components/commons/Button';
import RenderField from 'components/reduxForm/RenderField';

import {
  required,
  minLength6,
  maxLength16,
  pwdConfirm
} from 'lib/form/validations';

import './PwdEditForm.scss';

function PwdEditForm({ isPwdLoading, onSubmit, handleSubmit, isHavePassword }) {
  return (
    <Fragment>
      <div className="pwd-edit-form pt-3 pl-3 pr-3">
        <div className="form-headline">비밀번호 변경하기</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isHavePassword && (
            <Field
              name="oldPwd"
              type="password"
              label="현재 비밀번호"
              component={RenderField}
              placeholder="현재 비밀번호"
              validate={[required, minLength6, maxLength16]}
              isRequired
            />
          )}
          <Field
            name="pwd"
            type="password"
            label="신규 비밀번호"
            component={RenderField}
            placeholder="6 ~ 16자 비밀번호 입력"
            validate={[required, minLength6, maxLength16]}
            isRequired
          />
          <Field
            name="pwd2"
            type="password"
            label="신규 비밀번호 확인"
            component={RenderField}
            placeholder="비밀번호 확인"
            validate={[required, pwdConfirm]}
            isRequired
          />
          <div className="excavation-form-buttons mt-5 text-center">
            <Button
              buttonText={isHavePassword ? '비밀번호 변경' : '비밀번호 설정'}
              buttonType="submit"
              className="btn-pwd-edit"
              color="from"
              isDisable={isPwdLoading}
              isLoading={isPwdLoading}
            />
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default reduxForm({
  form: 'pwdEditForm'
})(PwdEditForm);
