/**
 * 가격에 ,를 추가하여 string값으로 반환해준다.
 * @param {int} price 가격
 *
 */
// eslint-disable-next-line
export function getPriceFormat(price) {
  if (!price) {
    if (price === 0) {
      return '0';
    }

    return '';
  }

  return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

/**
 * 소수점 1자리 까지만 나오게
 * 단, 정수는 .0 안나오도록
 * 반올림이 정확하지 않음 1.95833 -> 2
 * @param {float} num 숫자
 */
export function parseFloatFixed(num) {
  if (!num) {
    return num;
  }

  return parseFloat(num.toFixed(1));
}
