/* eslint-disable no-console */
import React from 'react';
import {
  ItemStatusBox,
  ItemImageBox,
  ItemTitleBox,
  ItemPriceBox,
  ItemStatsBox
} from 'components/commons/ItemCard/ItemCard';

import { ItemCardWrapper } from 'components/commons/ItemCard/ItemCardStyle';

export default function ProductCard(props) {
  const {
    className,
    discoveredDate,
    officialProductImgPath,
    productName,
    flagReservation,
    isSamedayDelivery,
    isCarrige,
    flagStopSelling,
    '1pCount': onePCount,
    countOfComment,
    options,
    id,
    discovererId,
    onCardClick
  } = props;

  const category = { isCarrige };
  const status = { flagStopSelling, isSamedayDelivery, flagReservation };
  const stats = {
    numberOfOneProducts: onePCount,
    numberOfComments: countOfComment
  };

  return (
    <ItemCardWrapper
      className={className}
      onClick={onCardClick ? () => onCardClick(props) : null}
    >
      <ItemStatusBox
        className={className}
        status={status}
        type="product"
        category={category}
      />
      <ItemImageBox className={className} imagePath={officialProductImgPath} />
      <ItemTitleBox
        className={className}
        title={productName}
        date={discoveredDate}
        category={category}
      />
      {options && (
        <ItemPriceBox
          className={className}
          options={options}
          category={category}
        />
      )}
      <ItemStatsBox className={className} stats={stats} />
    </ItemCardWrapper>
  );
}
