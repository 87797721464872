import styled, { css } from 'styled-components';

export const ItemSliderWrapper = styled.div`
  padding: 0px 5px;
  margin-top: 65px;
  width: 100%;
  height: auto;
  /* margin-left: 10px; */

  &.small-title {
    margin-top: 35px;
  }

  .title {
    margin-top: 0px;
    padding-left: 5px;
    font-size: 1.25rem;
    font-weight: 800;
    font-size: 20px;

    a {
      color: var(--text-color, black);
    }

    &.small-title {
      font-size: 0.875rem;
      font-weight: normal;
    }
  }
  .subtitle {
    margin-top: 25px;
    padding-left: 5px;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 17px;
    line-height: 2.5;
  }

  .slider-container {
    display: flex;
    height: auto;
    margin: 10px auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      height: 5px;
      background-color: var(--gray-200, #f0f0f0);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: var(--gray-300, #cfd1d2);
    }
  }
`;
