import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import MemberEditContainer from 'containers/users/MemberEditContainer';

function StoreInfoSetting() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <MemberEditContainer />
    </Fragment>
  );
}

export default StoreInfoSetting;
