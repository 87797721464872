import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

LinkButton.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  replace: PropTypes.bool,
  state: PropTypes.shape({}),
  search: PropTypes.string,
  to: PropTypes.string
};

LinkButton.defaultProps = {
  buttonText: '',
  className: '',
  color: '',
  replace: false,
  search: '',
  state: null,
  to: ''
};

export default function LinkButton({
  buttonText,
  className,
  color,
  replace,
  search,
  state,
  to
}) {
  return (
    <Link
      className={classNames('button link-button', color, className)}
      replace={replace}
      to={{
        pathname: to,
        search: search,
        state
      }}
    >
      {buttonText}
    </Link>
  );
}
