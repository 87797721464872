import React from 'react';
import PropTypes from 'prop-types';

// import TextInput from 'components/commons/TextInput';
// import ModalDialog from 'components/modal/ModalDialog';
import CommonModal from 'components/modal/CommonModal';

import './DeliveryNumInputModal.scss';

function DeliveryNumInputModal({
  changeStatusProcess,
  invoiceNumber,
  isOpen,
  isLoading,
  isPersonShipping,
  onChangeNumber,
  onChangeSelect,
  onSaveInvoiceNum,
  onToggle
}) {
  // return (
  //   <ModalDialog size="sm" isOpen={isOpen} onToggle={onToggle}>
  //     <div className="text-center text-secondary mt-3 input-modal-text font-weight-bold">
  //       운송장 번호 입력
  //     </div>
  //     <select
  //       className="form-control form-input form-select d-block w-100 mt-4"
  //       onChange={onChangeSelect}
  //       value={changeStatusProcess}
  //     >
  //       <option value="">택배사선택</option>
  //       <option value="000">직접전달</option>
  //       <option value="01">우체국택배</option>
  //       <option value="04">CJ대한통운</option>
  //       <option value="06">로젠택배</option>
  //       <option value="05">한진택배</option>
  //       <option value="11">일양로지스</option>
  //       <option value="08">롯데택배</option>
  //       <option value="46">CU편의점택배</option>
  //       <option value="23">경동택배</option>
  //       <option value="17">천일택배</option>
  //       <option value="56">KGB택배</option>
  //       <option value="22">대신택배</option>
  //       <option value="32">합동택배</option>
  //       <option value="18">건영택배</option>
  //       <option value="40">굿투럭</option>
  //       <option value="54">홈픽택배</option>
  //       <option value="53">농협택배</option>
  //       <option value="52">세방</option>
  //       <option value="43">애니트랙</option>
  //       <option value="20">한덱스</option>
  //       <option value="16">한의사랑택배</option>
  //       <option value="45">호남택배</option>
  //       <option value="24">CVSnet편의점택배</option>
  //       <option value="30">KGL네트웍스</option>
  //       <option value="44">SLX</option>
  //     </select>
  //     {/* <TextInput
  //       className="text-invoice"
  //       onChange={onChangeNumber}
  //       value={invoiceNumber}
  //       placeholder={isPersonShipping ? '메모 입력' : '운송장번호 입력'}
  //     /> */}
  //     <input
  //       className="form-control text-invoice"
  //       onChange={onChangeNumber}
  //       value={invoiceNumber}
  //       placeholder={isPersonShipping ? '메모 입력' : '숫자만 입력'}
  //     />

  //     <div className="text-center mt-3">
  //       <button
  //         type="button"
  //         className="btn btn-primary w-100"
  //         onClick={onSaveInvoiceNum}
  //       >
  //         입력완료
  //       </button>
  //     </div>
  //   </ModalDialog>
  // );

  return (
    <CommonModal
      className="delivery-num-input-modal"
      size="sm"
      isOpen={isOpen}
      onToggle={onToggle}
      okButtonText="완료"
      okButtonLoading={isLoading}
      onClickOk={onSaveInvoiceNum}
      headerText="운송장 번호 입력"
      headerImage="/images/header-image/delivery-num-modal-header-image.svg"
      isProviderModal
    >
      <select
        className="form-control form-input form-select d-block w-100"
        onChange={onChangeSelect}
        value={changeStatusProcess}
      >
        <option value="">택배사선택</option>
        <option value="000">직접전달</option>
        <option value="01">우체국택배</option>
        <option value="04">CJ대한통운</option>
        <option value="06">로젠택배</option>
        <option value="05">한진택배</option>
        <option value="11">일양로지스</option>
        <option value="08">롯데택배</option>
        <option value="46">CU편의점택배</option>
        <option value="23">경동택배</option>
        <option value="17">천일택배</option>
        <option value="56">KGB택배</option>
        <option value="22">대신택배</option>
        <option value="32">합동택배</option>
        <option value="18">건영택배</option>
        <option value="40">굿투럭</option>
        <option value="54">홈픽택배</option>
        <option value="53">농협택배</option>
        <option value="52">세방</option>
        <option value="43">애니트랙</option>
        <option value="20">한덱스</option>
        <option value="16">한의사랑택배</option>
        <option value="45">호남택배</option>
        <option value="24">CVSnet편의점택배</option>
        <option value="30">KGL네트웍스</option>
        <option value="44">SLX</option>
      </select>
      <input
        className="form-control text-invoice"
        onChange={onChangeNumber}
        value={invoiceNumber}
        placeholder={isPersonShipping ? '메모 입력' : '숫자만 입력'}
      />
    </CommonModal>
  );
}

DeliveryNumInputModal.propTypes = {
  changeStatusProcess: PropTypes.number,
  invoiceNumber: PropTypes.string,
  isOpen: PropTypes.bool,
  onChangeNumber: PropTypes.func,
  onChangeSelect: PropTypes.func,
  onSaveInvoiceNum: PropTypes.func,
  onToggle: PropTypes.func
};

DeliveryNumInputModal.defaultProps = {
  changeStatusProcess: 0,
  invoiceNumber: '',
  isOpen: false,
  onChangeNumber: () => {},
  onChangeSelect: () => {},
  onSaveInvoiceNum: () => {},
  onToggle: () => {}
};

export default DeliveryNumInputModal;
