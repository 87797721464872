import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';

import Loader from 'components/commons/Loader';
import EmptyList from 'components/commons/EmptyList';
import ProviderSalesItem from './ProviderSaleItem2';

import './NotifyList.scss';
import './ProviderSalesList.scss';

function ProviderSalesList({
  dataLoading,
  hasLastKey,
  isLoading,
  onGet,
  salesList
}) {
  // eslint-disable-next-line
  // console.log(salesList);

  const haveSalesList = salesList && salesList.length > 0;

  if (isLoading) {
    return <Loader />;
  }

  if (!haveSalesList) {
    return <EmptyList className="mt-5" />;
  }

  return (
    <div className="notify-list ml-auto mr-auto">
      <InfiniteScroll
        pageStart={0}
        loadMore={onGet}
        hasMore={!dataLoading && hasLastKey}
      >
        {salesList.map((sales) => {
          const {
            id,
            buyer,
            flagDelivery,
            flagCancelPayment,
            paymentAfterDate,
            paymentUid,
            product,
            totalAmount,
            totalQuantity,
            totalPayable,
            lineItems
          } = sales;
          const { name: buyerName } = buyer || {};
          const { name: productName, officialProductImgPath } = product || {};
          const { toProvider, deliveryFee } = totalPayable || {};

          const totalProvider = toProvider + deliveryFee;

          return (
            <ProviderSalesItem
              buyerName={buyerName}
              isDelivery={flagDelivery === 1}
              paymentAfterDate={paymentAfterDate}
              paymentUid={paymentUid}
              productName={productName}
              totalQuantity={totalQuantity}
              totalAmount={totalAmount}
              totalProvider={totalProvider}
              key={id}
              flagCancelPayment={flagCancelPayment}
              lineItems={lineItems}
              officialProductImgPath={officialProductImgPath}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

ProviderSalesList.propTypes = {
  dataLoading: PropTypes.bool,
  hasLastKey: PropTypes.bool,
  isLoading: PropTypes.bool,
  onGet: PropTypes.func
};

ProviderSalesList.defaultProps = {
  dataLoading: false,
  hasLastKey: false,
  isLoading: false,
  onGet: () => {}
};

export default ProviderSalesList;
