import React from 'react';
import { Link } from 'react-router-dom';
import './OneStoreListItemTemplate.scss';

function OneStoreListItemTemplate({ name, linkPath, children }) {
  return (
    <Link to={linkPath}>
      <div className="onestore-list-template-item">
        {/* 달라지는 부분만 자식 컴포넌트로 받는다 */}
        {children}
        <div className="onestore-list-template-item-name">{name}</div>
        <img
          className="onestore-list-template-item-link-icon"
          src="/images/onestore-setting-link-icon.svg"
          alt="화살표"
        />
      </div>
    </Link>
  );
}

export default OneStoreListItemTemplate;
