/* eslint-disable no-console */
import React from 'react';

import ProductList from 'components/product/ProductList';
import CategoryItem from './CategoryItem';
import ToggleList from 'components/commons/ToggleList';

import './MarketCategoryView.scss';

const ITEMS_PER_PAGE = 60;

function MarketCategoryView({
  categoryList,
  recommendationList,
  brandList,
  onClickTag
}) {
  // console.log({ recommendationList });
  return (
    <div className="search-category-view">
      <div className="search-recommend-menu">
        <div className="search-category-item-wrapper">
          {categoryList &&
            categoryList.map((item) => {
              const { name, image, to, noRadius } = item;

              return (
                <CategoryItem
                  key={name}
                  image={image}
                  name={name}
                  onClickTag={() => {
                    window.scrollTo(0, 0);
                    onClickTag(to);
                  }}
                  noRadius={noRadius}
                  type="tag"
                />
              );
            })}
        </div>
      </div>
      <div>
        {recommendationList &&
          recommendationList.map((item) => {
            const { title, subTitle, initialOpen = false, ...rest } = item;
            return (
              <ToggleList
                key={item.searchTag}
                buttonLabel=""
                buttonText={title}
                buttonDescription={subTitle}
                initialOpen={initialOpen}
                className="category-product"
              >
                <ProductList
                  searchKey={item.searchTag}
                  itemsPerPage={ITEMS_PER_PAGE}
                  filterBy={(item) =>
                    !(
                      item.flagStopSelling === 11 || item.flagStopSelling === 12
                    )
                  }
                  className={
                    item.searchTag === '#프롬베스트' ? 'very-large' : 'small'
                  }
                  {...rest}
                />
              </ToggleList>
            );
          })}
      </div>
      <div className="search-recommend-menu">
        {brandList && brandList.length > 0 && (
          <div className="search-recommend-menu-title">
            {/* <img src="/images/category-from-brand.svg" alt="프롬 브랜드" /> */}
            <span>인기 브랜드를 응원합니다!</span>
          </div>
        )}
        <div className="search-category-item-wrapper">
          {brandList &&
            brandList.map((item) => {
              const { name, image, to, noRadius } = item;
              return (
                <CategoryItem
                  key={name}
                  image={image}
                  name={name}
                  to={to}
                  noRadius={noRadius}
                  type="link"
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default MarketCategoryView;
