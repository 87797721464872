import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import Button from 'components/commons/Button';
// import ModalDialog from 'components/modal/ModalDialog';
import CommonModal from 'components/modal/CommonModal';

import './LoginModal.scss';

function LoginModal({
  buttonText,
  headerText,
  headerImage,
  className,
  contentText,
  isOpen,
  redirectUrl,
  onToggle
}) {
  return (
    <CommonModal
      className={classNames('login-modal', className)}
      contentText={contentText}
      headerText={headerText}
      headerImage={headerImage}
      isOpen={isOpen}
      onToggle={onToggle}
      okButtonText={buttonText}
      linkstate={{
        redirectUrl: redirectUrl || '/'
      }}
      linkPath="/store/signin"
    />
  );
}

LoginModal.propTypes = {
  buttonText: PropTypes.string,
  headerText: PropTypes.string,
  className: PropTypes.string,
  contentText: PropTypes.string,
  isOpen: PropTypes.bool
};

LoginModal.defaultProps = {
  buttonText: '로그인',
  headerText: '로그인',
  className: '',
  contentText: '',
  isOpen: false
};

export default LoginModal;
