export default `<p style= "line-height: 1.5;"><span style= "color: rgb(243, 121, 52); font-size: 24px;"><strong><span style="font-weight: 800;;">상품 상세 정보 작성 시 유의사항</span></strong>
</span>
</p>
<p style= "line-height: 1.3;"><span style= "color: rgb(243, 121, 52); font-size: 24px;"><span style='font-family: "Nanum Sqaure"; font-size: 14px;'>1) * 기호가 붙은 텍스트를 상품에 맞게 수정해주세요.</span></span>
</p>
<p><span style= "color: rgb(243, 121, 52);">2) 수정완료 후 * 기호를 지워주세요.</span></p>
<p><span style= "color: rgb(243, 121, 52);">3) 아래 정보들은 최소한의 필수 작성 요소로 임의로 삭제하지 말아주세요.</span></p>
<p><span style= "color: rgb(243, 121, 52);"><br></span></p>
<p><span style= "color: rgb(243, 121, 52);">* 상품상세정보 작성 완료 후 하단 수정버튼(저장) 전, 이 글을 꼭 삭제 하신 뒤 저장해주세요!</span></p>
<p><span style= "color: rgb(243, 121, 52);"><br></span></p>
<p><span style= "color: rgb(243, 121, 52);"><br></span></p>
<p><span style= "color: rgb(243, 121, 52);">* 상품 메인 정사각형 이미지 1장을 가장 상단에 꼭 첨부 해주세요!</span></p>
<p><span style= "color: rgb(243, 121, 52);">(업로드 전, 위 글은 일괄 삭제해주세요.)</span></p>
<p>
<br>
</p>
<p>
<br>
</p>
<p>
<br>
</p>
<p>
<br>
</p>
<p><strong><span style="font-size: 24px; font-family: nanumsquareEB; color: rgb(0, 0, 0);">[공급자명] 상품명 *</span></strong></p>
<p>
<br>
</p>
<p style= "line-height: 1.8;"><strong><span style='font-size: 18px; font-family: "Nanum SqaureEB";'>0원 *</span></strong></p>
<p style= "line-height: 1.8;"><span style='font-size: 18px; color: rgb(55, 224, 17); font-family: "Nanum SqaureB";'>+0원 적립 *</span></p>
<p style= "line-height: 1.8;">
<br>
</p>
<p style= "line-height: 1.8;"><span style='font-size: 16px; color: rgb(0, 0, 0); font-family: "Nanum SqaureB";'>크기 0cm ~ *</span></p>
<p style= "line-height: 1.8;"><span style= "font-size: 16px;"><span style='color: rgb(0, 0, 0); font-family: "Nanum SqaureB";'>중량 0g ~ *</span></span>
</p>
<p style= "line-height: 1.8;">
<br>
</p>
<p style= "line-height: 1.8;"><span style= "font-size: 14px; color: rgb(55, 224, 17);">로그인 후, 적립혜택이 제공됩니다.</span></p>
<p>
<br>
</p>
<p><img src= "https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575872243999_sfKgAattH.png" style= "width: 420px;" class= "fr-fic fr-dib" data-success= "true"></p>
<p>
<br>
</p>
<p style= "line-height: 1.7;"><span style= "color: rgb(177, 177, 177); font-size: 14px;">판매단위</span><span style= "color: rgb(209, 213, 216); font-size: 18px;"><strong> </strong></span> <span style= "color: rgb(0, 0, 0);">  개/박스/팩 등 *</span></p>
<p style= "line-height: 1.7;"><span style= "font-size: 14px; color: rgb(177, 177, 177);">중량/용량</span><span style= "font-size: 18px; color: rgb(209, 213, 216);"><strong> </strong></span> <span style= "color: rgb(0, 0, 0);">   kg/g/ml 등 *</span></p>
<p style= "line-height: 1.7;"><span style= "font-size: 14px;"><span style="color: rgb(177, 177, 177);">배송구분</span></span><span style= "font-size: 18px;"><strong><span style="color: rgb(209, 213, 216);">  </span></strong>
</span> <span style= "color: rgb(0, 0, 0);">  우체국/퀵/택배 등 *</span></p>
<p style= "line-height: 1.7;"><span style= "font-size: 14px;"><span style="color: rgb(177, 177, 177);">원산지</span></span><span style= "font-size: 18px;"><strong><span style="color: rgb(209, 213, 216);">     </span></strong>
</span> <span style= "color: rgb(0, 0, 0);">    국내_제주/해외_필리핀 등 *</span></p>
<p style= "line-height: 1.7;"><span style= "font-size: 14px;"><span style="color: rgb(177, 177, 177);">포장타입</span></span><span style= "font-size: 18px;"><strong><span style="color: rgb(209, 213, 216);">  </span></strong>
</span> <span style= "color: rgb(0, 0, 0);">   냉장_종이포장/냉동_아이스 등 *</span></p>
<p style= "line-height: 1.7;"><span style= "font-size: 14px; color: rgb(177, 177, 177);">안내사항</span><span style= "font-size: 18px; color: rgb(209, 213, 216);"><strong>   </strong></span> <span style= "color: rgb(0, 0, 0);">    배송 및 상품에 오해가 없도록 사전 안내사항 *</span></p>
<p>
<br>
</p>
<p><img src= "https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575872256250_ZN3J9hNGJ.png" style= "width: 420px;" class= "fr-fic fr-dib" data-success= "true"></p>
<p>
<br>
</p>
<p>
<br>
</p>
<p>
<br>
</p>
<p><strong><span style="font-size: 24px;"><span style="font-weight: 800;;">상품소개 헤드라인 텍스트 입력 *</span><img src="https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575870529533_fu4NFSnHq.png" style="width: 316px; height: 6.77143px;" class="fr-fic fr-dib fr-fil" data-success="true"></span></strong></p>
<p>
<br>
</p>
<p>
<br>
</p>
<p><span style='font-size: 20.5px; font-family: "Nanum SqaureEB"; color: rgb(0, 0, 0);'>상품소개 본문 타이틀 텍스트 입력 *</span></p>
<p>
<br>
</p>
<p>
<br>
</p>
<p><span style= "color: rgb(209, 213, 216); font-size: 15px;">이미지 및 동영상 등 자유롭게 파일을 첨부하세요.</span></p>
<p><span style= "color: rgb(209, 213, 216); font-size: 15px;">(상품 수정 완료 시, 이 글은 삭제 후 업로드)</span></p>
<p>
<br>
</p>
<p>
<br>
</p>
<p style= "line-height: 1.6;"><span style= "color: rgb(0, 0, 0); font-size: 15px;">상품소개 본문 글 텍스트 입력 *</span></p>
<p>
<br>
</p>
<p>
<br>
</p>
<p>
<br>
</p>
<p>
<br>
</p>
<p>
<br>
</p>
<p><strong><span style="font-size: 18px; color: rgb(55, 224, 17);"><span style="font-weight: 800;;">From, 원칙</span><img src="https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575870768777_FHg3edzD9.png" style="width: 90px; height: 6px;" class="fr-fic fr-dib fr-fil" data-success="true"></span></strong></p>
<p>
<br>
</p>
<p>
<br>
</p>
<p><span style='font-family: "Nanum SqaureEB"; font-size: 24px; color: rgb(0, 0, 0);'>상품판매원칙 헤드라인 텍스트 입력 *</span></p>
<p>
<br>
</p>
<p>
<br>
</p>
<p dir= "ltr" style= "line-height: 1.6;"><span style= "color: rgb(209, 213, 216); font-size: 15px;">이미지 및 동영상 등 자유롭게 파일을 첨부하세요.</span></p>
<p><span style= "font-size: 15px; color: rgb(209, 213, 216);">(상품 수정 완료 시, 이 글은 삭제 후 업로드)</span></p>
<p><span style= "font-size: 15px; color: rgb(0, 0, 0);"><br></span></p>
<p><span style= "color: rgb(0, 0, 0);"><span style="font-size: 15px;"><br></span></span>
</p>
<p><span style= "color: rgb(0, 0, 0);"><span style="font-size: 15px;">상품판매원칙 본문 글 텍스트 입력 *</span></span>
</p>
<p><span style= "color: rgb(0, 0, 0);"><span style="font-size: 15px;"><br></span></span>
</p>
<p>
<br>
</p>
<p>
<br>
</p>
<p><span style= "font-size: 15px;"><br></span></p>
<p>
<br>
</p>
<p><strong><span style='font-size: 18px; color: rgb(55, 224, 17); font-family: "Nanum SqaureEB";'>From, 공급자 별명 *</span></strong>
<br><img src= "https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575871177434_ZhfDHwcvf.png" style= "width: 90px; height: 6px;" class= "fr-fic fr-dib fr-fil" data-success= "true"></p>
<p>
<br>
</p>
<p>
<br>
</p>
<p><span style='font-family: "Nanum SqaureEB"; font-size: 24px; color: rgb(0, 0, 0);'>공급자소개 헤드라인 텍스트 입력 *</span></p>
<p style= "line-height: 1;">
<br>
</p>
<p style= "line-height: 1;">
<br>
</p>
<p style= "line-height: 1;">
<br>
</p>
<p><span style= "font-size: 15px; color: rgb(209, 213, 216);">이미지 및 동영상 등 자유롭게 파일을 첨부하세요.</span></p>
<p><span style= "font-size: 15px; color: rgb(209, 213, 216);">(상품 수정 완료 시, 이 글은 삭제 후 업로드)</span></p>
<p dir= "ltr">
<br>
</p>
<p dir= "ltr">
<br>
</p>
<p dir= "ltr" style= "line-height: 1.5;"><span style='color: rgb(0, 0, 0); font-size: 20px; font-family: "Nanum SqaureEB";'>공급자소개 본문 타이틀 텍스트 입력 *</span><span style= "font-size: 18px;"><br></span></p>
<p><span style= "color: rgb(0, 0, 0);"><br></span></p>
<p dir= "ltr"><span style= "color: rgb(0, 0, 0);"><span style="font-size: 15px;">공급자소개 본문 글 텍스트 입력 *</span></span>
</p>
<p><span style= "color: rgb(0, 0, 0);"><br><br></span></p>
<p><span style= "color: rgb(0, 0, 0);"><br></span></p>
<p><span style= "color: rgb(0, 0, 0);"><br></span></p>
<p><span style= "color: rgb(0, 0, 0);"><br></span></p>
<p><strong><span style='font-size: 18px; color: rgb(55, 224, 17); font-family: "Nanum SqaureEB";'>From, 배경 *</span></strong>
<br><img src= "https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575871177434_ZhfDHwcvf.png" style= "width: 90px; height: 6px;" class= "fr-fic fr-dib fr-fil" data-success= "true"></p>
<p>
<br>
</p>
<p><span style='font-size: 23px; font-family: "Nanum SqaureEB"; color: rgb(0, 0, 0);'>상품제작배경 헤드라인 텍스트 입력 *</span><span style='font-size: 24px; font-family: "Nanum SqaureEB"; color: rgb(0, 0, 0);'><br></span></p>
<p style= "line-height: 1;">
<br>
</p>
<p style= "line-height: 1;"><span style='font-size: 24px; font-family: "Nanum SqaureEB"; color: rgb(0, 0, 0);'><br></span></p>
<p><span style= "font-size: 15px; color: rgb(209, 213, 216);">이미지 및 동영상 등 자유롭게 파일을 첨부하세요.</span></p>
<p><span style= "font-size: 15px; color: rgb(209, 213, 216);">(상품 수정 완료 시, 이 글은 삭제 후 업로드)</span></p>
<p dir= "ltr">
<br>
</p>
<p dir= "ltr">
<br>
</p>
<p dir= "ltr" style= "line-height: 1.5;"><span style='font-family: "Nanum SqaureEB"; color: rgb(0, 0, 0); font-size: 20px;'>상품제작배경 본문 타이틀 텍스트 입력 *</span></p>
<p>
<br>
</p>
<p dir= "ltr"><span style= "color: rgb(0, 0, 0); font-size: 15px;">상품제작배경 본문 글 텍스트 입력 *</span></p>
<p>
<br>
<br>
</p>
<p>
<br>
</p>
<p>
<br>
</p>
<p><strong><span style="font-size: 18px; color: rgb(55, 224, 17);"><span style="font-weight: 800;;">상품 옵션</span><img src="https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575870768777_FHg3edzD9.png" style="width: 90px; height: 6px;" class="fr-fic fr-dib fr-fil" data-success="true"></span></strong></p>
<p>
<br>
</p>
<p><span style='font-size: 24px; font-family: "Nanum SqaureEB"; color: rgb(0, 0, 0);'>상품옵션 헤드라인 텍스트 입력 *</span></p>
<p><img src= "https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575872243999_sfKgAattH.png" data-success= "true" class= "fr-fic fr-dii"></p>
<p>
<br>
</p>
<p><span style='font-size: 18px; font-family: "Nanum SqaureEB"; color: rgb(0, 0, 0);'>상품상세옵션 텍스트 입력 *</span></p>
<p><span style='font-family: "Nanum SqaureEB"; font-size: 16px; color: rgb(0, 0, 0);'>가격 0 *</span></p>
<p><span style= "color: rgb(0, 0, 0);"><span style="font-weight: bold;">+0원 적립 *</span></span>
</p>
<p dir= "ltr"><span style= "color: rgb(0, 0, 0);"><span style='font-family: "Nanum Sqaure";'>크기 0cm *</span></span>
</p>
<p dir= "ltr"><span style='font-family: "Nanum Sqaure"; color: rgb(0, 0, 0);'>중량 0g *</span></p>
<p><img src= "https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575872256250_ZN3J9hNGJ.png" data-success= "true" class= "fr-fic fr-dii"></p>
<p>
<br>
</p>
<p><span style='color: rgb(0, 0, 0); font-family: "Nanum SqaureEB"; font-size: 18px;'>상품상세옵션 텍스트 입력 *</span></p>
<p><span style='color: rgb(0, 0, 0); font-size: 16px; font-family: "Nanum SqaureEB";'>가격 0 *</span></p>
<p><span style='color: rgb(0, 0, 0); font-family: "Nanum SqaureB";'>+0원 적립 *</span></p>
<p dir= "ltr"><span style= "color: rgb(0, 0, 0);">크기 0cm *</span></p>
<p dir= "ltr"><span style= "color: rgb(0, 0, 0);">중량 0g *</span></p>
<p><img src= "https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575872256250_ZN3J9hNGJ.png" data-success= "true" class= "fr-fic fr-dii"></p>
<p>
<br>
</p>
<p><span style='color: rgb(0, 0, 0); font-size: 18px; font-family: "Nanum SqaureEB";'>상품상세옵션 텍스트 입력 *</span></p>
<p><span style='color: rgb(0, 0, 0); font-family: "Nanum SqaureEB"; font-size: 16px;'>가격 0</span></p>
<p><span style='color: rgb(0, 0, 0); font-family: "Nanum SqaureB";'>+0원 적립 *</span></p>
<p dir= "ltr"><span style= "color: rgb(0, 0, 0);">크기 0cm *</span></p>
<p dir= "ltr"><span style= "color: rgb(0, 0, 0);">중량 0g *</span></p>
<p><img src= "https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575872256250_ZN3J9hNGJ.png" data-success= "true" class= "fr-fic fr-dii"></p>
<p>
<br>
</p>
<p><span style='color: rgb(0, 0, 0); font-size: 18px; font-family: "Nanum SqaureEB";'>상품상세옵션 텍스트 입력 *</span></p>
<p><span style='color: rgb(0, 0, 0); font-size: 16px; font-family: "Nanum SqaureEB";'>가격 0 *</span></p>
<p><span style='color: rgb(0, 0, 0); font-family: "Nanum SqaureB";'>+0원 적립 *</span></p>
<p dir= "ltr"><span style= "color: rgb(0, 0, 0);">크기 0cm *</span></p>
<p dir= "ltr"><span style= "color: rgb(0, 0, 0);">중량 0g *</span></p>
<p><img src= "https://s3.ap-northeast-2.amazonaws.com/from.kr.editor/1575872256250_ZN3J9hNGJ.png" data-success= "true" class= "fr-fic fr-dii"></p>
<p>
<br>
</p>
<p>
<br>
</p>`;
