import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import SearchContainer from 'containers/home/SearchContainer';

export default function FromSearch() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <SearchContainer isTopLevel />
    </Fragment>
  );
}
