import React from 'react';

export default function useIntersectionObserver({
  root,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true
}) {
  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    // console.log({ enabled });

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root: root && root.current,
        rootMargin,
        threshold
      }
    );

    const el = target ? target.current : null;
    // console.log({ target });
    // console.log({ el });

    if (!el) {
      return;
    }

    observer.observe(el);
    // console.log('Observing');

    return () => {
      observer.unobserve(el);
      // console.log('Unobserving');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target.current, enabled]);
}
