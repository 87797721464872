import { useQuery } from 'react-query';

import fetchData from 'api/fetchData';
import { getStore } from 'api/store';
const STALE_TIME = 1000 * 60 * 10;
const ITEMS_PER_PAGE = 20;

const getOneStoreById = async (key, storeId) => {
  try {
    const data = await fetchData(getStore, storeId);

    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export function useOneStore({ storeId, isMyStore = false }) {
  const { data, isLoading, isError, error } = useQuery(
    ['store', storeId],
    getOneStoreById,
    {
      staleTime: STALE_TIME,
      refetchOnMount: isMyStore ? 'always' : 'true'
    }
  );

  return { data, isLoading, isError, error };
}
