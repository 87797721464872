import { useQuery, useInfiniteQuery } from 'react-query';

import fetchData from 'api/fetchData';
import { getOneProduct } from 'api/oneProduct';
import { getOneProductsListOfProduct } from 'api/product';
import { getStoreOneProductsList } from 'api/store';
import { getProviderOneProduct } from 'api/provider';

const STALE_TIME = 1000 * 60 * 10;
const ITEMS_PER_PAGE = 20;

const getOneProductById = async (key, { storeId, productId }) => {
  try {
    const data = await fetchData(getOneProduct, [storeId, productId]);

    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

const getOneProducts = async (
  key,
  { searchType, searchKey, itemsPerPage = ITEMS_PER_PAGE },
  LastEvaluatedKey
) => {
  try {
    // console.log({ LastEvaluatedKey });
    const params = {
      [searchType]: searchKey,
      pageCount: itemsPerPage,
      lastKey: LastEvaluatedKey
    };

    const func =
      searchType === 'storeId'
        ? getStoreOneProductsList
        : searchType === 'productId'
        ? getOneProductsListOfProduct
        : searchType === 'providerId'
        ? getProviderOneProduct
        : null;

    if (func === null)
      throw new Error(`Unknown searchType - ${searchType} !!!`);

    const data = await fetchData(func, params);

    // console.log({ data });

    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export function useOneProduct({ storeId, productId }) {
  const { data, isLoading, isError, error } = useQuery(
    ['oneproduct', { storeId, productId }],
    getOneProductById,
    {
      staleTime: STALE_TIME,
      retry: false
    }
  );

  return { data, isLoading, isError, error };
}

export function useInfiniteOneProducts(searchConditions) {
  const query = useInfiniteQuery(
    ['oneproducts', searchConditions],
    getOneProducts,
    {
      getFetchMore: (lastPage) => {
        // console.log({ lastPage });
        return lastPage.LastEvaluatedKey;
      },
      staleTime: searchConditions.isMyStore ? 0 : STALE_TIME,
      retry: false
    }
  );
  // console.log({ query });
  return query;
}
