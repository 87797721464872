/* eslint-disable no-console */
import React from 'react';
import {
  ItemStatusBox,
  ItemImageBox,
  ItemTitleBox,
  ItemPriceBox
} from 'components/commons/ItemCard/ItemCard';

import { ItemCardWrapper } from 'components/commons/ItemCard/ItemCardStyle';

export default function OptionCard({
  className = 'small-option',
  product,
  option,
  index,
  onCardClick
}) {
  const { name, createDate, imagePath, isInStock = true } = option;
  const { officialProductImgPath: defaultImgagePath } = product;
  const status = { isInStock };
  const options = [option];

  return (
    <ItemCardWrapper
      className={className}
      onClick={() => {
        // console.log('option card clicked');
        // console.log({ index });
        onCardClick(product, option, index);
      }}
    >
      <ItemStatusBox className={className} status={status} type="option" />
      <ItemImageBox
        className={className}
        imagePath={imagePath || defaultImgagePath}
      />
      <ItemTitleBox className={className} title={name} date={createDate} />
      <ItemPriceBox className={className} options={options} />
    </ItemCardWrapper>
  );
}
