import React, { Fragment } from 'react';
import ToggleButton from 'components/commons/ToggleButton';

import './SnsEditForm.scss';

function SnsEditForm({
  isKakaoButtonLoading,
  isConnectedKakao,
  onKakaoToggleButton
}) {
  return (
    <Fragment>
      <div className="sns-edit-form pl-3 pr-3">
        <div className="form-headline">SNS 연결</div>
        <div className="sns-login-box d-flex align-items-center pt-1 pb-1">
          <div className="sns-login-box-left">
            <div className="d-inline-block sns-name">카카오톡 연결</div>
            <img
              src="/images/kakao-btn.svg"
              alt="sns이미지"
              className="sns-img"
            />
          </div>
          <div className="sns-btn-box ml-auto">
            {isKakaoButtonLoading ? (
              <img
                src="/images/bookmark-loader.svg"
                className="loading-spinner"
                alt="로딩 이미지"
              />
            ) : (
              <ToggleButton
                id="kakao"
                isLoading={isKakaoButtonLoading}
                checked={isConnectedKakao}
                onClick={onKakaoToggleButton}
                color="from"
              />
            )}
          </div>
        </div>
        {/* <div className="sns-login-box d-flex align-items-center pr-3 pt-1 pb-1 pl-3">
          <div className="sns-login-box-left">
            <img
              src="/images/sns-facebook-small.svg"
              alt="sns이미지"
              className="sns-img mr-3"
            />
            <div className="d-inline-block sns-name">페이스북 로그인</div>
          </div>
          <div className="sns-btn-box ml-auto">
            <ToggleButton
              id="kakao"
              isLoading={isKakaoButtonLoading}
              checked={isConnectedKakao}
              onClick={onKakaoToggleButton}
            />
          </div>
        </div> */}
      </div>
    </Fragment>
  );
}

export default SnsEditForm;
