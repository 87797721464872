import React, { Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import UserSubForm from 'components/shared/UserSubForm';
import AddressSubForm from 'components/shared/AddressSubForm';
import AuthSubForm from 'components/payment2/AuthSubForm';
import TermsSubForm from 'components/shared/TermsSubForm';

import { ButtonWrapper, StyledButton } from 'shared/buttons/ButtonStyle';

import {
  Input,
  TextArea,
  Select,
  CheckBox,
  RadioButtons,
  DatePicker,
  SubmitButton,
  CancelButton
} from 'shared/components';

import {
  StyledForm,
  FormHeaderWrapper,
  StyledFormTitle,
  FormBodyWrapper,
  StyledTextButton,
  FormButtonWrapper
} from 'shared/styled/componentStyle';

import {
  stringRequired,
  nameRequired,
  emailRequired,
  emailNewRequired,
  mobileRequired,
  mobileNewRequired,
  passwordRequired
} from 'shared/rules/validationRules';

import './GiftDeliveryForm.scss';

const defaultCheckboxValues = {
  acceptAll: false,
  acceptTermsOfService: false,
  acceptPrivacyPolicy: false,
  acceptMarketingTerms: false
};

function GiftDeliveryForm({
  isLoggedIn,
  isSignedKakao,
  experienceType,
  receiver = {},
  product = {},
  className,
  onSubmit
  // flagAgreeMarketing,
  // handleSubmit,
  // isLoadingForm,
  // isLoggedIn,
  // isCheckedAgreement,
  // isCheckedAllCheckbox,
  // isCheckedSameAddress,
  // isCheckedPrivacy,
  // isCheckedBuyerInfoSave,
  // redirectPath,
  // onChangeCheckbox,
  // onChangeSameAddress,
  // onClickKakaoButton,
  // onOpenModal,
  // onOpenAgreementModal,
  // onSubmit,
  // submitting
  // isCheckedReserveDelivery = true,
  // onChangeReserveDelivery
}) {
  const location = useLocation();
  const history = useHistory();
  const isDelivery = product.flagDelivery === 1;
  const buttonText = isLoggedIn ? '선물받기' : '선물받기 및 회원가입';
  const schema = yup.object().shape({
    name: !isLoggedIn ? nameRequired : null,
    mobile: !isLoggedIn ? mobileNewRequired : null,
    email: !isLoggedIn ? emailNewRequired : null,
    pwd: !(isLoggedIn || isSignedKakao) ? passwordRequired : null,
    acceptTermsOfService: !isLoggedIn
      ? yup.boolean().oneOf([true], '이용 약관에 동의해주세요.')
      : null,
    acceptPrivacyPolicy: yup
      .boolean()
      .oneOf([true], '개인정보 수집 및 이용안내에 동의해주세요.'),

    addr: isDelivery ? stringRequired : null
  });
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { ...receiver, ...defaultCheckboxValues }
  });

  const {
    register,
    control,
    errors,
    formState,
    reset,
    watch,
    handleSubmit,
    setValue
  } = methods;

  console.log({ isDelivery });

  return (
    <FormProvider className={className} {...methods}>
      <StyledForm>
        <FormHeaderWrapper>
          {!isLoggedIn && (
            <StyledTextButton
              className="close"
              type="button"
              onClick={() => {
                history.push({
                  pathname: '/store/signin',
                  state: {
                    from: {
                      pathname: location.pathname,
                      state: { from: '/store/signin' }
                    }
                  }
                });
              }}
            >
              로그인
            </StyledTextButton>
          )}
        </FormHeaderWrapper>
        <StyledFormTitle>선물 받는 사람</StyledFormTitle>
        <FormBodyWrapper>
          <UserSubForm showEmail />
          {/* {isGift && (
            <Fragment>
              <TextArea
                className={className}
                name="giftMessage"
                label="선물 메세지"
                placeholder="받는 분에게 마음을 전하세요 ^^ (최대 300자)"
                rows="5"
                {...{ register, control, errors }}
              />
              <RadioButtons
                className={`${className} required`}
                options={{
                  'gift-recv': '받는 분이 입력',
                  'gift-direct': '직접 입력'
                }}
                name="experienceType"
                label="배송지"
                value={experienceTypeWatch}
                {...{ register, errors }}
              />
            </Fragment>
          )} */}

          {experienceType !== 'gift-direct' && isDelivery && (
            <Fragment>
              <AddressSubForm />
              <Input
                className={className}
                name="deliveryMessage"
                label="배송 메세지"
                placeholder="예) 경비실에 맡겨주세요"
                {...{ register, control, errors }}
              />
              <DatePicker
                className={className}
                name="preferredShipDate"
                label="발송요청일"
                placeholder="희망하는 발송일을 선택해 주세요."
                {...{ control, errors }}
              />
            </Fragment>
          )}
          {!isLoggedIn && (
            <Fragment>
              <AuthSubForm
                isSignedKakao={isSignedKakao}
                redirectUrl={location.pathname}
              />
              <TermsSubForm className="payment-form" />
            </Fragment>
          )}
        </FormBodyWrapper>
        <FormButtonWrapper>
          <ButtonWrapper>
            <StyledButton
              type="button"
              className="from-button"
              onClick={handleSubmit(onSubmit)}
            >
              {buttonText}
            </StyledButton>
          </ButtonWrapper>
        </FormButtonWrapper>
        {/* <DevTool control={control} /> */}
      </StyledForm>
    </FormProvider>
  );
}

export default GiftDeliveryForm;
