import React from 'react';
import PropTypes from 'prop-types';

import LinkButton from './LinkButton';
import NormalButton from './NormalButton';
import SubmitButton from './SubmitButton';

import './Button.scss';

Button.propTypes = {
  buttonType: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  isDisable: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  replace: PropTypes.bool,
  search: PropTypes.string,
  state: PropTypes.shape({}),
  to: PropTypes.string
};

Button.defaultProps = {
  buttonType: 'button',
  buttonText: '확인',
  className: '',
  color: 'primary',
  isDisable: false,
  isLoading: false,
  onClick: () => {},
  replace: false,
  search: '',
  state: null,
  to: '/'
};

function Button({
  buttonType,
  buttonText,
  className,
  color,
  isDisable,
  isLoading,
  onClick,
  replace,
  search,
  state,
  to
}) {
  if (buttonType === 'submit') {
    return (
      <SubmitButton
        buttonText={buttonText}
        className={className}
        color={color}
        isDisable={isDisable}
        isLoading={isLoading}
      />
    );
  }

  if (buttonType === 'link') {
    return (
      <LinkButton
        buttonText={buttonText}
        className={className}
        color={color}
        replace={replace}
        search={search}
        state={state}
        to={to}
      />
    );
  }

  return (
    <NormalButton
      buttonText={buttonText}
      className={className}
      color={color}
      isDisable={isDisable}
      isLoading={isLoading}
      onClick={onClick}
    />
  );
}

export default Button;
