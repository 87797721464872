import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import withUser from 'lib/hoc/withUser';
import withUi from 'lib/hoc/withUi';

// import { useLoginStore } from '../../components/Login/LoginStore';
import usePostCard from './usePostCard';

import { uploadImageFiles } from 'lib/utils/uploadUtils';
import { ImageInput } from 'shared/components';
import useImageInput from 'shared/ImageInput/useImageInput';
import MentionTextArea from 'shared/MentionTextArea/MentionTextArea';
import { TextArea, SubmitButton } from 'shared/components';
import {
  StyledForm,
  FormHeaderWrapper,
  StyledFormTitle,
  FormBodyWrapper,
  StyledButton,
  StyledTextButton,
  FormButtonWrapper
} from 'shared/styled/componentStyle';

import { nameRequired } from 'shared/rules/validationRules';
import { useEffect } from 'react';

const schema = yup.object().shape({
  comment: yup.string().required('필수 항목')
});

// const users = [
//   {
//     id: 'walter',
//     display: '노상규'
//   },
//   {
//     id: 'jesse',
//     display: '윤지영'
//   },
//   {
//     id: 'gus',
//     display: '박미내'
//   },
//   {
//     id: 'saul',
//     display: '윤성영'
//   },
//   {
//     id: 'hank',
//     display: 'Hank Schrader'
//   },
//   {
//     id: 'skyler',
//     display: 'Skyler White'
//   },
//   {
//     id: 'mike',
//     display: 'Mike Ehrmantraut'
//   },
//   {
//     id: 'lydia',
//     display: 'Lydìã Rôdarté-Qüayle'
//   }
// ];

const getInitialValues = ({
  post = {},
  user,
  isProvider,
  store,
  product,
  provider,
  mode
}) => {
  // console.log({ mode });
  // console.log({ product });
  // console.log({ user });
  // console.log({ store });
  const depth = mode === 'new' ? 0 : 1;

  const owner = store
    ? {
        id: store.id,
        name: store.userName || store.name,
        mobile: store.mobile,
        iosARN: store.iosARN,
        androidARN: store.androidARN,
        type: 'store'
      }
    : provider
    ? {
        id: provider.id,
        name: provider.providerName,
        imagePath: provider.providerProfileImgPath,
        mobile: provider.mobile,
        iosARN: provider.iosARN,
        androidARN: provider.androidARN,
        type: 'provider'
      }
    : {
        id: user.id,
        name: user.userName || user.providerName,
        imagePath: isProvider
          ? user.providerProfileImgPath
          : user.profileImgPath,
        mobile: user.mobile,
        iosARN: user.iosARN,
        androidARN: user.androidARN,
        type: isProvider ? 'provider' : 'store'
      };
  const ownerId = owner.id;
  // const storeId = ownerId;
  // console.log({ owner });

  const authorId = user.id;
  const author = !isProvider
    ? {
        id: authorId,
        name: user.userName,
        imagePath: user.profileImgPath,
        mobile: user.mobile,
        iosARN: user.iosARN,
        androidARN: user.androidARN,
        type: 'store'
      }
    : {
        id: authorId,
        name: user.providerName,
        imagePath: user.providerProfileImgPath,
        mobile: user.mobile,
        iosARN: user.iosARN,
        androidARN: user.androidARN,
        type: 'provider'
      };

  // console.log({ author });

  const { id: productId, productName, officialProductImgPath, providerId } =
    product || {};

  const newProduct = product
    ? {
        id: productId,
        name: productName,
        imagePaths: [officialProductImgPath]
      }
    : null;

  // console.log({ newProduct });
  const { id, providerName, providerProfileImgPath } = provider || {};

  const newProvider = provider
    ? { id, name: providerName, imagePath: providerProfileImgPath }
    : null;
  // console.log({ newProvider });

  const indexAtrributes = {
    depth_ownerIdProductId: `${depth}_${ownerId}_${productId}`,
    depth_ownerId: `${depth}_${ownerId}`,
    depth_authorId: `${depth}_${authorId}`,
    depth_productId: `${depth}_${productId}`,
    depth_providerId: `${depth}_${provider ? provider.id : providerId}`
  };

  switch (mode) {
    case 'new':
      return {
        ...post,
        expId: `${ownerId}_${productId || ownerId}`,
        parentCommentId: '',
        depth,
        //
        ownerId,
        owner,
        storeId: ownerId,
        // storeType: owner.type,
        // storeName: owner.name,
        // storeMobile: owner.mobile,
        // writePageType: product ? '1P' : provider ? 'PP' : '1S',

        //
        productId,
        product: newProduct,
        // productName: newProduct ? newProduct.name : '',
        //
        providerId: provider ? provider.id : providerId,
        provider: newProvider,
        //
        authorId,
        author,
        // writeUserId: authorId,
        // writeUserName: author.name,
        // writeUserProfileImgPath: author.imagePath,
        // writeUserType: author.type,
        //
        ...indexAtrributes
      };

    case 'reply':
      // console.log({ post });
      const { replyCount, ...rest } = post;
      return {
        ...rest,
        parentCommentId: post.parentCommentId
          ? post.parentCommentId
          : post.commentsId,
        depth,
        //
        authorId,
        author,
        // writeUserId: authorId,
        // writeUserName: author.name,
        // writeUserProfileImgPath: author.imagePath,
        // writeUserType: author.type,
        //

        // writePageType: store ? '1P' : 'P',
        ...indexAtrributes
      };
    case 'edit':
      return post;

    default:
      return post;
  }
};

function PostForm({
  // mode,
  // post,
  // store,
  // product,
  user,
  isLoggedIn,
  isProvider,
  UiAction,
  className = 'one-column'
}) {
  const location = useLocation();
  const history = useHistory();
  // const user = useLoginStore();

  // console.log(location);
  // console.log(history);

  const { mode, post, store, product, provider } = location.state;

  // console.log({ mode });
  // console.log({ post });
  // console.log({ product });
  // console.log({ store });
  // console.log({ user });
  // console.log({ isLoggedIn });
  // console.log({ isProvider });
  // debugger;

  const { comment = '', attachImageList = [] } =
    post && mode === 'edit' ? post : {};

  const {
    imageFiles,
    imageUrls,
    isLarge,
    setImages,
    onAdd,
    onDelete,
    onToggleSize
  } = useImageInput(attachImageList);

  const { register, control, errors, reset, formState, handleSubmit } = useForm(
    {
      mode: 'onBlur',
      resolver: yupResolver(schema),
      defaultValues: { comment, images: [] }
    }
  );

  const { onCreate, onUpdate, isLoading, error } = usePostCard(
    // location.state.post,
    post,
    { user, isLoggedIn, isProvider }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const header =
      product && store
        ? `${store.name || store.userName} @${product.productName}`
        : store
        ? store.name || store.userName
        : provider
        ? provider.providerName
        : '';
    UiAction.setHeader(
      mode === 'new' ? `To: ${header}` : mode === 'reply' ? '답글' : '수정'
    );
  }, []);

  const onSubmit = async (values) => {
    try {
      // console.log({ values });
      const initialValues = getInitialValues({
        mode,
        post,
        user,
        isProvider,
        store,
        product,
        provider
      });
      const images = await uploadImageFiles(imageFiles, 'commentImage');
      const postValues = {
        ...initialValues,
        comment: values.comment,
        attachImageList: images
      };
      // console.log({ postValues });
      mode === 'edit' ? await onUpdate(postValues) : await onCreate(postValues);
    } catch (error) {
      console.log({ error });
    }
  };

  const { isSubmitting } = formState;
  // console.log({ error });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <FormHeaderWrapper>
        <StyledTextButton
          className="close"
          type="button"
          isSubmitting={isLoading || isSubmitting}
          onClick={() => {
            // console.log('close button clicked');
            history.goBack();
          }}
        >
          <img src="/images/x-icon.svg" alt="close" />
        </StyledTextButton>
      </FormHeaderWrapper>

      {/* <StyledFormTitle>
        {mode === 'reply' ? '답글' : mode === 'edit' ? '수정' : '이야기'}
      </StyledFormTitle> */}
      <FormBodyWrapper>
        <ImageInput
          name="images"
          // label="이미지:"
          multiple
          isLarge={isLarge}
          imageUrls={imageUrls}
          onToggle={onToggleSize}
          onChange={onAdd}
          onDelete={onDelete}
          className={className}
        />
        <TextArea
          name="comment"
          // label="이야기:"
          placeholder={
            mode === 'reply' ? '답글을 써주세요' : '이야기를 써주세요'
          }
          rows="5"
          className={className}
          // data={users}
          {...{ register, control, errors }}
        />
      </FormBodyWrapper>
      <FormButtonWrapper>
        <SubmitButton
          size="medium"
          isProvider={isProvider}
          isSubmitting={isLoading || isSubmitting}
        >
          {mode === 'reply'
            ? '작성하기'
            : mode === 'edit'
            ? '수정하기'
            : '작성하기'}
        </SubmitButton>
      </FormButtonWrapper>
      {/* <DevTool control={control} /> */}
    </StyledForm>
  );
}

export default withUi(withUser(PostForm));
