import React, { Component, Fragment } from 'react';

class TagComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { module: null };
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    const { path } = this.props;
    const { path: prevPath } = prevProps;

    if (path !== prevPath) {
      window.scrollTo(0, 0);

      this.initialize();
    }
  }

  initialize() {
    const { path } = this.props;
    import(`../${path}`).then((module) =>
      this.setState({ module: module.default })
    );
  }

  render() {
    const { module: Component } = this.state;
    const { event } = this.props;
    return <Fragment>{Component && <Component event={event} />}</Fragment>;
  }
}
export default TagComponent;
