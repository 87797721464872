import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { routes } from './routes';

function MainRoutes() {
  // console.log({ routes });

  return (
    // <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      {routes.map((route) => {
        return route.isPrivate ? (
          <PrivateRoute key={route.path} {...route} />
        ) : (
          <Route key={route.path} {...route} />
        );
      })}
    </Switch>
    // </Suspense>
  );
}

export default MainRoutes;
