import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import Iframe from 'react-iframe';
// import { scrollToRef } from 'lib/utils/commonUtils';
import withUi from 'lib/hoc/withUi';
import { isDevelop } from 'lib/utils/envUtils';

import { FormButtonWrapper } from 'shared/styled/componentStyle';
import ProductList from 'components/product/ProductList';
import ProductCard from 'components/commons/ProductCard';
import ListTitle from 'components/commons/ListTitle';

import {
  ItemStatusBox,
  ItemImageBox,
  ItemTitleBox,
  ItemPriceBox,
  ItemStatsBox
} from 'components/commons/ItemCard/ItemCard';
import { ItemCardWrapper } from 'components/commons/ItemCard/ItemCardStyle';
import { MainSectionWrapper } from 'styles/AppStyle';
import { InfiniteListWrapper } from 'shared/styled/InfiniteListStyle';

// const scrollToTop = () => {
//   console.log('hello');
//   scrollToRef('top', 120);
// };

// const listener = () => {
//   if (document.activeElement === document.getElementById('relay-santa')) {
//     console.log('clicked');
//     scrollToTop();
//   }
// };

function RelaySanta({ UiAction }) {
  const history = useHistory();
  // useEffect(() => {
  //   console.log('adding listener');
  //   window.addEventListener('click', listener);
  //   return () => {
  //     console.log('removing listener');
  //     window.removeEventListener('click', listener);
  //   };
  // }, []);
  useEffect(() => {
    UiAction.setHeader('2인3각 릴레이 산타 @프롬!!!');
  }, []);

  const isDev = isDevelop();

  return (
    <MainSectionWrapper className="relay-santa">
      <ScrollToTopOnMount />
      <Iframe
        url="https://from23.bubbleapps.io"
        width="380px"
        height="910px"
        id="relay-santa"
        display="initial"
        position="relative"
        scroll="true"
      />
      <FormButtonWrapper className="relay-santa">
        <div>
          <button
            onClick={() =>
              history.push(
                `/store/${
                  isDev
                    ? 's153839858795466/p1573192053027'
                    : 's153681558398645/p1558004675088'
                }/detail`
              )
            }
          >
            2단계 선물이 기부가 되는 찐산타 되기!
            {/* <img
              src="/images/santa/santa-button-3.png"
              alt="To be a Santa"
              width="330px"
            /> */}
          </button>
        </div>
      </FormButtonWrapper>
      <ProductList searchType="tag" searchKey="#릴레이산타" className="large" />
      <FormButtonWrapper className="relay-santa">
        <div>
          <button
            onClick={() =>
              history.push(
                `/store/${
                  isDev
                    ? 's153839858795466/p1573192053027'
                    : 's153681558398645/p1558004675088'
                }/detail`
              )
            }
          >
            3단계 프롬이 산타를 대신해서 일해요!
            {/* <img
              src="/images/santa/santa-button-3.png"
              alt="To be a Santa"
              width="330px"
            /> */}
          </button>
          <br />
        </div>
      </FormButtonWrapper>
      <ListTitle subTitle={' '} />

      <InfiniteListWrapper>
        <ItemCardWrapper>
          <Link
            to={`/store/${
              isDev
                ? 's153839858795466/p1573192053027'
                : 's153681558398645/p1558004675088'
            }/detail`}
          >
            <ItemImageBox imagePath="https://cdn.from.kr/santa/nenia-set.jpeg"></ItemImageBox>
          </Link>
          <ItemTitleBox
            className="product-card"
            title="친구에게 선물하면 아이들에게 전해질 네니아 친환경 간식세트"
          />
        </ItemCardWrapper>
        <a
          href="http://magazine.worldvision.or.kr/5386"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ItemCardWrapper>
            <ItemImageBox imagePath="https://cdn.from.kr/santa/breakfast.jpg"></ItemImageBox>
            <ItemTitleBox
              className="product-card"
              title="아침을 거르는 아이들을 위한 월드비전 아침머꼬 프로그램"
            />
          </ItemCardWrapper>
        </a>
      </InfiniteListWrapper>
    </MainSectionWrapper>
  );
}

export default withUi(RelaySanta);
