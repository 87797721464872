/* eslint-disable no-console */
import React, { useEffect, Fragment } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useInfiniteOrders } from 'hooks/useOrders';
import InfiniteList from 'shared/InfiniteList';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import RedirectToHome from 'components/commons/RedirectToHome';
import OrderItem from 'components/oneStore/OrderItem';

function OrderListContainer({ UiAction, user, isLoggedIn }) {
  const match = useRouteMatch();
  const { storeId } = match.params;

  const isMyStore = isLoggedIn && storeId === user?.id;

  const query = useInfiniteOrders({
    searchType: 'storeId',
    searchKey: storeId,
    itemsPerPage: 20,
    isMyStore
  });

  useEffect(() => {
    UiAction.setHeader('주문 상세');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <RedirectToHome
        isGotoHome={!isLoggedIn}
        redirectPath={`/store/${storeId}/setting/order`}
      />
      {isMyStore && (
        <InfiniteList isOneColumn {...query}>
          {(item) => (
            <OrderItem key={item.id} orderItem={item} storeId={storeId} />
          )}
        </InfiniteList>
      )}
    </Fragment>
  );
}

export default withUi(withUser(OrderListContainer));
