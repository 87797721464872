import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ToggleButton.scss';

class ToggleButton extends Component {
  handleToggleButton = () => {
    const { checked, onClick } = this.props;

    onClick(!checked);
  };

  render() {
    const { checked, isLoading, children, color, className } = this.props;
    return (
      <div className={classNames('toggle-button-box', className)}>
        {isLoading && (
          <img
            src="/images/button-loader.svg"
            className="async-validating loading-spinner"
            alt="input 로딩 중"
          />
        )}
        <label
          className={classNames('toggle-button', this.props.className)}
          htmlFor={this.props.id}
        >
          <input
            id={this.props.id}
            type="checkbox"
            onChange={this.handleToggleButton}
            checked={checked}
          />
          {children}
          <span className={classNames('slider round', color)} />
        </label>
      </div>
    );
  }
}

ToggleButton.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.string
};

ToggleButton.defaultProps = {
  className: '',
  checked: false,
  id: ''
};

export default ToggleButton;
