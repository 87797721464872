/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import withUi from 'lib/hoc/withUi';

import { getCategorizeSearch } from 'api/search';
import SearchInput from 'components/home/SearchInput';
import SearchTagBar from 'components/home/SearchTagBar';
import SearchList from 'components/home/SearchList';
import AlertModal from 'components/modal/AlertModal';
import MarketCategoryContainer from 'containers/home/MarketCategoryContainer';

import { throttle, debounce } from 'lib/utils/commonUtils';

const SEARCH_CATEGORY_LIST = ['product', 'provider', '1stores'];
const QUERY_KEY_LIST = ['productName', 'providerName', 'userName'];

class SearchContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      keyword: '',
      selectOptionIndex: 0,
      searchResultList: [],
      tagList: []
    };

    this.pageCount = 20;
    this.lastKey = null;
    this.getSearchStoresResultListThrottled = throttle(() => {
      this.getSearchStoresResultList();
    }, 500);
    this.getSearchStoresResultListDebounced = debounce(() => {
      this.getSearchStoresResultList();
    }, 500);
    this.isTagSearch = false;
  }

  componentDidMount() {
    const { isTopLevel } = this.props;

    this.initialize();

    if (isTopLevel) {
      const { UiAction } = this.props;

      UiAction.setHeader('프롬 검색');
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;

    if (location.state !== prevLocation.state) {
      this.handleClickClearButton();

      return;
    }
  }

  getSearchStoresResultList = () => {
    const { keyword, selectOptionIndex } = this.state;

    this.setState(
      {
        isLoading: true
      },
      async () => {
        // if (keyword.length < 2) {
        //   this.setState({
        //     isLoading: false,
        //     searchResultList: []
        //   });

        //   return;
        // }
        if (keyword.length < 1) {
          return;
        }

        const isHaveHash = keyword.charAt(0) === '#';
        const isTagSearch = isHaveHash && selectOptionIndex === 0;
        let splitKeyword = '';

        if (isTagSearch) {
          splitKeyword = keyword.substring(1, keyword.length);
        }

        try {
          const response = await getCategorizeSearch(
            SEARCH_CATEGORY_LIST[selectOptionIndex],
            {
              q: encodeURIComponent(
                // api 전송시 앞뒤 공백제거
                `${!isTagSearch ? QUERY_KEY_LIST[selectOptionIndex] : 'tags'}=${
                  !isTagSearch ? keyword.trim() : splitKeyword.trim()
                }`
              ),
              s: encodeURIComponent(`createDate=desc`),
              k: this.lastKey ? encodeURIComponent(this.lastKey) : null
            }
          );

          if (!response || !response.data || !response.data.success) {
            throw new Error({
              errorMessage: '상점 목록을 불러오던 중 오류가 발생했습니다.'
            });
          }

          const { data } = response.data;

          this.lastKey = data.lastKey;

          this.setState({
            isLoading: false,
            hasLastKey: !!data.lastKey,
            searchResultList: data.items.filter(
              (item) =>
                !(item.flagStopSelling === 11 || item.flagStopSelling === 12)
            )
          });
        } catch (e) {
          this.setState({
            isOpenErrorModal: true,
            errorMessage: e.errorMessage || '상점 목록을 불러올 수 없습니다.',
            isLoading: false
          });
        }
      }
    );
  };

  // getSearchTagsResultList = () => {
  //   const { searchTag } = this.state;

  //   this.setState(
  //     {
  //       isLoading: true
  //     },
  //     async () => {
  //       try {
  //         const response = await getCategorizeSearch('product', {
  //           q: encodeURIComponent(
  //             // api 전송시 앞뒤 공백제거
  //             `tags=${searchTag.trim()}`
  //           ),
  //           s: encodeURIComponent(`createDate=desc`),
  //           k: this.lastKey ? encodeURIComponent(this.lastKey) : null
  //         });

  //         if (!response || !response.data || !response.data.success) {
  //           throw new Error({
  //             errorMessage: '상점 목록을 불러오던 중 오류가 발생했습니다.'
  //           });
  //         }

  //         const { data } = response.data;

  //         this.lastKey = data.lastKey;

  //         this.setState({
  //           isLoading: false,
  //           hasLastKey: !!data.lastKey,
  //           searchResultList: data.items
  //         });
  //       } catch (e) {
  //         this.setState({
  //           isOpenErrorModal: true,
  //           errorMessage: e.errorMessage || '상점 목록을 불러올 수 없습니다.',
  //           isLoading: false
  //         });
  //       }
  //     }
  //   );
  // };

  initialize = () => {
    this.setState(
      {
        tagList: []
      }
      // this.getSearchTagsList()
    );
  };

  loadMoreList = async () => {
    // eslint-disable-next-line
    // console.log('more!');

    const { selectOptionIndex, keyword, searchResultList } = this.state;

    const isHaveHash = keyword.charAt(0) === '#';
    const isTagSearch = isHaveHash && selectOptionIndex === 0;
    let splitKeyword = '';

    if (isTagSearch) {
      splitKeyword = keyword.substring(1, keyword.length);
    }

    try {
      const response = await getCategorizeSearch(
        SEARCH_CATEGORY_LIST[selectOptionIndex],
        {
          q: encodeURIComponent(
            // api 전송시 앞뒤 공백제거
            `${!isTagSearch ? QUERY_KEY_LIST[selectOptionIndex] : 'tags'}=${
              !isTagSearch ? keyword.trim() : splitKeyword.trim()
            }`
          ),
          s: encodeURIComponent(`createDate=desc`),
          k: this.lastKey ? encodeURIComponent(this.lastKey) : null
        }
      );

      if (!response || !response.data || !response.data.success) {
        throw new Error({
          errorMessage: '추가로 상점 목록을 불러오던 중 오류가 발생했습니다.'
        });
      }

      const { data } = response.data;

      this.lastKey = data.lastKey;

      this.setState({
        isLoading: false,
        hasLastKey: !!data.lastKey,
        searchResultList: searchResultList.concat(
          data.items.filter(
            (item) =>
              !(item.flagStopSelling === 11 || item.flagStopSelling === 12)
          )
        )
      });
    } catch (e) {
      this.setState({
        isOpenErrorModal: true,
        errorMessage:
          e.errorMessage || '추가로 상점 목록을 불러올 수 없습니다.',
        isLoading: false
      });
    }
  };

  handleChangeValue = (e) => {
    this.lastKey = null;

    this.setState(
      {
        isLoading: true,
        keyword: e.target.value,
        searchResultList: []
      },
      () => {
        // if (this.state.keyword.length > 1) {
        //   // this.getSearchStoresResultList();
        //   // this.getSearchStoresResultListThrottled();
        //   this.getSearchStoresResultListDebounced();
        // }

        this.getSearchStoresResultListDebounced();
      }
    );

    e.stopPropagation();
  };

  handleChangeSelect = (e) => {
    const targetIndex = e.target.selectedIndex;
    this.lastKey = null;

    this.setState(
      {
        selectOptionIndex: targetIndex,
        searchResultList: []
      },
      () => {
        this.getSearchStoresResultList();
      }
    );

    e.stopPropagation();
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  handleClickClearButton = () => {
    this.setState({
      keyword: '',
      searchResultList: []
    });
  };

  handleClickTag = (tag) => {
    this.setState(
      {
        keyword: `#${tag}`,
        selectOptionIndex: 0
      },
      () => {
        this.lastKey = null;
        this.getSearchStoresResultList();
      }
    );
  };

  // searchAndHiddenItem = (list) => {
  //   const hiddenList = list.filter((item) => {
  //     return item.id !== HIDDEN_ITEM;
  //   });

  //   return hiddenList;
  // };

  render() {
    const {
      isLoading,
      keyword,
      selectOptionIndex,
      searchResultList,
      hasLastKey,
      isOpenErrorModal,
      errorMessage,
      tagList
    } = this.state;

    const { isTopLevel } = this.props;

    return (
      <Fragment>
        <SearchInput
          keyword={keyword}
          onChangeValue={this.handleChangeValue}
          selectOptionIndex={selectOptionIndex}
          onChangeSelect={this.handleChangeSelect}
          onClickClearButton={this.handleClickClearButton}
        />
        {isTopLevel && tagList && tagList.length > 0 && (
          <SearchTagBar
            tagList={tagList}
            onClickTag={this.handleClickTag}
            keyword={keyword}
          />
        )}

        {isTopLevel && !keyword ? (
          <MarketCategoryContainer />
        ) : (
          <SearchList
            isInitial={keyword.length === 0}
            onGet={this.loadMoreList}
            selectOptionIndex={selectOptionIndex}
            hasLastKey={hasLastKey}
            searchResultList={searchResultList}
            isLoading={isLoading}
          />
        )}
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
      </Fragment>
    );
  }
}

export default compose(withRouter, withUi)(SearchContainer);
