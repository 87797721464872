import React from 'react';
import { getEnvironment } from 'lib/utils/envUtils';
import CDN_URL from 'components/commons/globalUrl';
import TagComponent from 'components/socialTrial/TagComponent';

const IS_DEVEOP = getEnvironment() === true;

const eventData = [
  {
    eventName: 'drops',
    productId: IS_DEVEOP ? 'p1553089215766' : 'p1553499182563',
    discovererId: IS_DEVEOP ? 's155176981495393' : 's153681558398638',
    eventComp: <TagComponent path="SocialTrialDetail" />
  },
  {
    eventName: 'helpradish',
    productId: IS_DEVEOP ? 'p1554280810335' : 'p1554259601425',
    discovererId: IS_DEVEOP ? 's1538379952398100' : 's155420968624485',
    eventComp: <TagComponent path="HelpRadishDetail" />
  },
  {
    eventName: 'youngmuseo',
    productId: IS_DEVEOP ? 'p1554986456774' : 'p1554979161007',
    discovererId: IS_DEVEOP ? 's1538379952398100' : 's1538662396336100',
    eventComp: <TagComponent path="YoungMuseoDetail" />
  },
  {
    eventName: 'fromday',
    productId: IS_DEVEOP ? 'p1556627658335' : 'p1556639926887',
    discovererId: IS_DEVEOP ? 's155176981495393' : 's153681558398645',
    eventComp: <TagComponent path="FromDayDetail2" />
  },
  {
    eventName: 'earthman',
    productId: IS_DEVEOP ? 'p1556759188866' : 'p1556697327092',
    discovererId: IS_DEVEOP ? 's153681558398638' : 's153681558398645',
    eventComp: <TagComponent path="EarthManDetail" event="earthman" />
  },
  {
    eventName: 'earthmangift',
    productId: IS_DEVEOP ? 'p1556696066336' : 'p1556694128712',
    discovererId: IS_DEVEOP ? 's153681558398638' : 's153681558398645',
    eventComp: <TagComponent path="EarthManDetail" event="earthman" />
  },
  {
    eventName: 'youngmichip',
    productId: IS_DEVEOP ? 'p1557286859305' : 'p1545805164132',
    discovererId: IS_DEVEOP ? 's154150562693721' : 's153681558398645',
    eventComp: <TagComponent path="EarthManDetail" event="youngmichip" />
  },
  {
    eventName: 'vdx',
    productId: IS_DEVEOP ? 'p1557299016023' : 'p1541410604878',
    discovererId: IS_DEVEOP ? 's154150562693721' : 's153681558398632',
    eventComp: <TagComponent path="EarthManDetail" event="vdx" />
  },
  {
    eventName: 'youngmiddok',
    productId: IS_DEVEOP ? 'p1557830370554' : 'p1557990687958',
    discovererId: IS_DEVEOP ? 's154150562693721' : 's15535877381741',
    eventComp: <TagComponent path="CommonDetail" event="youngmiddok" />
  },
  {
    eventName: 'itsbetter',
    productId: IS_DEVEOP ? 'p1557841460749' : 'p1557729912822',
    discovererId: IS_DEVEOP ? 's154150562693721' : 's155722759609912',
    eventComp: <TagComponent path="CommonDetail" event="itsbetter" />
  },
  {
    eventName: 'helloman',
    productId: IS_DEVEOP ? 'p1557830092351' : 'p1557986135511',
    discovererId: IS_DEVEOP ? 's154150562693721' : 's153682837174533',
    eventComp: <TagComponent path="CommonDetail" event="helloman" />
  },
  {
    eventName: 'helloman100',
    productId: IS_DEVEOP ? 'p1558071001952' : 'p1557921315946',
    discovererId: IS_DEVEOP ? 's153681558398638' : 's153682837174533',
    eventComp: <TagComponent path="CommonDetail" event="helloman100" />
  },
  {
    eventName: 'oliveoil',
    productId: IS_DEVEOP ? 'p1562317483025' : 'p1562219519701',
    discovererId: IS_DEVEOP ? 's154150562693721' : 's153683870095058',
    eventComp: <TagComponent path="CommonDetail" event="oliveoil" />
  }
  // 1. 이벤트명, 상품아이디, 발굴자아이디 넣기
  // 2. getEventKakaoParam()에 해당 이벤트
  //    카톡공유 파라미터 집어넣기
  // {
  //   eventName: 'exampleEvent',
  //   productId: IS_DEVEOP ? 'p111111' : 'p222222',
  //   discovererId: IS_DEVEOP ? 's333333' : 's444444'
  // }
];

export function getEventData(_eventName) {
  let evData = {};

  eventData.forEach((data) => {
    if (data.eventName === _eventName) {
      evData = data;
    }
  });

  return evData;
}

export function getEventDataById(_productId) {
  let evData = '';

  eventData.forEach((data) => {
    if (data.productId === _productId) {
      evData = data;
    }
  });

  return evData;
}

export function getEventProductId(_eventName) {
  let eventId = '';

  eventData.forEach((data) => {
    if (data.eventName === _eventName) {
      eventId = data.productId;
    }
  });

  return eventId;
}

export function getEventName(_productId) {
  let name = '';

  eventData.forEach((data) => {
    if (data.productId === _productId) {
      name = data.eventName;
    }
  });

  return name;
}

export function getEventKakaoParam(eventName, url, kakaoShareName) {
  const kakaoParams = {
    buttonText: `둘러보기`,
    description: '프롬에서 추천합니다.',
    imageUrl: `${CDN_URL}/logo.png`,
    link: url,
    title: `프롬이 응원하는 상품`
  };

  if (eventName === 'drops') {
    kakaoParams.description =
      '국민 건강을 위한 드롭스 프라이팬 22cm 써보고 얘기해주세요.이거 진짜 대박인데?';
    kakaoParams.imageUrl = `${CDN_URL}/event/drops-kakao-share6.jpg`;
    kakaoParams.title = `[100인의 챌린저] 드롭스 프라이팬${
      kakaoShareName ? `\n${kakaoShareName}님이 응원하는 드롭스 프라이팬` : ''
    }`;
  }

  if (eventName === 'helpradish') {
    kakaoParams.description =
      '프롬이 우연히 접하게 된 제주 농가의 사연. 함께 돕고자 팔을 걷어 부쳤습니다.';
    kakaoParams.imageUrl = `${CDN_URL}/event/help-radish-kakao.jpg`;
    kakaoParams.title = `프롬이 함께하는 제주 농가 돕기`;
  }

  if (eventName === 'youngmuseo') {
    kakaoParams.description = `똑똑, 안녕하세요, 프롬입니다!\n첫번째 콜라보 상품을 응원합니다~`;
    kakaoParams.imageUrl = `${CDN_URL}/event/youngmu/youngmu-seo-kakao.jpg`;
    kakaoParams.title = `${
      kakaoShareName
        ? `\n${kakaoShareName}님이 응원하는 영미칩 x 카페뮤제오 드립백 커피`
        : '영미칩 x 카페뮤제오 드립백 커피'
    }`;
  }

  if (eventName === 'fromday') {
    kakaoParams.description = `프롬이 준비한 어마어마한 축제, 프롬마켓데이에 여러분을 초대합니다~ 함께 하실거죠? ^^`;
    kakaoParams.imageUrl = `${CDN_URL}/event/fromday/fromday-v2-1.png`;
    kakaoParams.title = `${
      kakaoShareName
        ? `\n${kakaoShareName}님이 응원하는 [518 프롬마켓데이]`
        : '[518 프롬마켓데이]'
    }`;
    kakaoParams.buttonText = '참여신청 하러가기';
  }

  return kakaoParams;
}

// export function getEventComponent(_productId) {
//   if (getEventName(_productId) === 'drops') {
//     import('components/socialTrial/SocialTrialDetail').then(
//       (SocialTrialDetail) => {
//         return <SocialTrialDetail />;
//       }
//     );
//   }

//   if (getEventName(_productId) === 'youngmuseo') {
//     import('components/socialTrial/YoungMuseoDetail').then(
//       (YoungMuseoDetail) => {
//         return <YoungMuseoDetail />;
//       }
//     );
//   }

//   if (getEventName(_productId) === 'fromday') {
//     import('components/socialTrial/FromDayDetail').then((FromDayDetail) => {
//       return <FromDayDetail />;
//     });
//   }
//   return;
// }
