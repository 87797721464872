/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { getFormatDate } from 'lib/time';

import OrderConfirmView from 'components/oneStore/OrderConfirmView';

import axios from 'api/defaultAxios';

import sentry from 'lib/sentry';

// import { getStorePaymentStatus } from 'api/store';
import { getCheckConfirm } from 'api/delivery';
import { getGift } from 'api/gift';
import AlertModal from 'components/modal/AlertModal';
import LogoModal from 'components/modal/LogoModal';
import Loader from 'components/commons/Loader';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

class GiftConfirmContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productImgPath: {
        path: ''
      },
      productName: '',
      providerName: '',
      initialLoading: true
    };

    this.fnInterval = null;
  }

  componentDidMount() {
    this.initialize();
  }

  componentWillUnmount() {
    clearInterval(this.fnInterval);
  }

  getDeliveryStatus = async () => {
    const { deliveryId } = this.props;

    try {
      const response = await getGift(deliveryId);

      if (!response || !response.data || !response.data.success) {
        throw new Error();
      }

      const { data } = response.data;

      const {
        createDate: afterDate,
        status,
        // deliverer,
        order,
        product,
        provider,
        recipient
      } = data;

      // console.log({ data });
      // const { trackingNumber: invoiceNumber } = deliverer || {};
      const { optionName: productSelectOpt, quantity: amount } = order;
      const {
        officialProductImgPath: productImgPath,
        name: productName,
        isTicket
      } = product;
      const { id: providerId, name: providerName } = provider;
      const { mobile: receiverMobile, name: receiverName } = recipient;

      if (
        status !== 'ordered' &&
        status !== 'confirmed' &&
        status !== 'issued'
      ) {
        this.setState({
          errorMessage: isTicket
            ? '이미 사용하셨습니다'
            : '이미 받으신 상품입니다.',
          isOpenErrorModal: true,
          initialLoading: false
        });

        return;
      }

      this.intervalCheck();

      this.setState({
        amount,
        afterDate,
        productImgPath,
        productName,
        providerId,
        providerName,
        receiverMobile,
        receiverName,
        initialLoading: false,
        productSelectOpt
      });
    } catch (e) {
      sentry.captureException(e.message);

      this.setState({
        errorMessage:
          '주문 상세내용 을 불러올 수 없습니다.\n 잠시 후 다시 시도해주세요.',
        isOpenErrorModal: true
      });
    }
  };

  initialize = () => {
    this.setState(
      {
        initialLoading: true
      },
      this.getDeliveryStatus
    );
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });

    this.handleRedirectPage();
  };

  handleCloseSuccessModal = () => {
    this.setState({
      isOpenSuccessModal: false
    });

    this.handleRedirectPage();
  };

  handleDeliveryUpdate = async () => {
    const { deliveryId } = this.props;
    const { providerId } = this.state;

    const values = {
      id: deliveryId,
      deliverer: {
        id: '000',
        name: '직접전달',
        trackingNumber: getFormatDate(Date.now(), 'YYYY.MM.DD HH:mm')
      }
    };

    if (!deliveryId) {
      return;
    }

    try {
      await axios
        .put(`/provider/${providerId}/delivery/${deliveryId}`, values)
        .then((res) => {
          if (!res.data.success) {
            const errorMessage = '정보를 불러오는데 실패하였습니다.';

            this.setState({
              isOpenErrorModal: true,
              btnLoading: false,
              errorMessage
            });
            return;
          }

          this.setState({
            isOpenSuccessModal: true,
            btnLoading: false
          });
        });
    } catch (e) {
      this.setState({
        isOpenErrorModal: true,
        btnLoading: false,
        errorMessage: '상태를 업데이트 하는데 실패하였습니다.'
      });
    }
  };

  handleClickConfirmButton = () => {
    this.setState(
      {
        btnLoading: true
      },
      async () => {
        await this.handleDeliveryUpdate();
      }
    );
  };

  handleRedirectPage = () => {
    const { history } = this.props;
    // eslint-disable-next-line no-console
    // console.log({ history });
    history.goBack(-1);
  };

  // handleRedirectPage = () => {
  //   const { history, user, isOrderConfirm } = this.props;
  //   // eslint-disable-next-line no-console
  //   console.log({ history });
  //   if (isOrderConfirm) {
  //     history.goBack(-1);
  //   }

  //   const redirectPath = `/store/${user.id}/setting/giftbox/recv`;
  //   history.push(redirectPath);
  // };

  checkOrderConfirem = async () => {
    const { deliveryId } = this.props;
    const params = {
      id: deliveryId
    };

    const response = await getCheckConfirm(params);

    if (!response || !response.data || !response.data.success) {
      return;
    }

    const { success, data } = response.data;

    if (success && data.invoiceNumber) {
      clearInterval(this.fnInterval);
      this.setState({
        isOpenSuccessModal: true
      });
    }
  };

  intervalCheck = () => {
    this.fnInterval = setInterval(() => {
      this.checkOrderConfirem();
    }, 2000);
  };

  render() {
    const { user, deliveryId, paymentUid, isOrderConfirm } = this.props;
    const {
      productImgPath,
      productName,
      providerName,
      afterDate,
      isOpenErrorModal,
      errorMessage,
      isOpenSuccessModal,
      initialLoading,
      btnLoading,
      providerId,
      receiverName,
      receiverMobile,
      productSelectOpt,
      amount
    } = this.state;

    if (initialLoading) {
      return <Loader />;
    }

    return (
      <Fragment>
        <OrderConfirmView
          amount={amount}
          storeId={user.id}
          productImgPath={productImgPath}
          productName={productName}
          providerName={providerName}
          afterDate={afterDate}
          onClickConFirm={this.handleClickConfirmButton}
          isOpenSuccessModal={isOpenSuccessModal}
          initialLoading={initialLoading}
          btnLoading={btnLoading}
          deliveryId={deliveryId}
          paymentUid={paymentUid}
          providerId={providerId}
          receiverName={receiverName}
          receiverMobile={receiverMobile}
          selectOpt={productSelectOpt}
          isGiftConfirm={!isOrderConfirm}
        />
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
        <LogoModal
          isOpen={isOpenSuccessModal}
          contentText="확인 되었습니다."
          headerText="수령 확인"
          onToggle={this.handleCloseSuccessModal}
          onClick={this.handleRedirectPage}
        />
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  withUi,
  withUser
)(GiftConfirmContainer);
