import styled, { css } from 'styled-components';

export const StyledLineItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  font-size: 0.875rem;
  font-weight: bold;

  .label {
  }

  .value {
    margin-left: auto;
  }

  &.desc {
    margin-top: 0.5rem;
    color: var(--from-color);
    font-size: 0.625rem;
    line-height: 0.75rem;
  }

  &.total {
    margin-bottom: 1rem;
    .label {
      font-size: 0.9375rem;
      font-weight: 800;
    }

    .value {
      font-size: 1.125rem;
      font-weight: 800;
    }
  }

  /* button {
        border: 0.5px solid #90908f;
        border-radius: 1px;
        background-color: #e5e5e5;
        margin-right: 5px;
        width: 75px;
        height: 30px;
      }

      input {
        border: 1px solid #a09f9f;
        border-radius: 1px;
        background-color: #ffffff;
        width: 75px;
        height: 30px;
        text-align: right;
        padding: 0 8px;

        &:read-only {
          background-color: var(--border-color);
        }
      } */
`;
