import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ModifyProductContainer from 'containers/provider/ModifyProductContainer';

function ModifyProduct({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ModifyProductContainer discoverId={match.params.discoverId} />
    </Fragment>
  );
}

export default ModifyProduct;
