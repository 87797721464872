import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProviderProductContainer from 'containers/provider/ProviderProductContainer';

function ProviderProduct({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ProviderProductContainer providerId={match.params.providerId} />
    </Fragment>
  );
}

export default ProviderProduct;
