import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import ModalDialog from 'components/modal/ModalDialog';
// import Button from 'components/commons/Button';
import CommonModal from 'components/modal/CommonModal';

function ConfirmModal({
  className,
  contentText,
  isOpen,
  okButtonText,
  okButtonLoading,
  closeButtonText,
  onToggle,
  onClickOk,
  headerText,
  headerImage,
  isProviderModal
}) {
  return (
    // <ModalDialog
    //   size="sm"
    //   isOpen={isOpen}
    //   onToggle={onToggle}
    //   className={classNames('confirm-modal', className)}
    // >
    //   {headerText && (
    //     <div className="text-center modal-header-text">{headerText}</div>
    //   )}
    //   <p className="text-center modal-body-text">
    //     {contentText.split('\n').map((line) => {
    //       return (
    //         <span key={line}>
    //           {line}
    //           <br />
    //         </span>
    //       );
    //     })}
    //   </p>
    //   <div className="text-center mt-3 col-button-group">
    //     <Button
    //       className="btn btn-primary"
    //       onClick={onClickOk}
    //       color="from"
    //       buttonText={okButtonText}
    //       isLoading={okButtonLoading}
    //     />
    //     <Button
    //       className="btn btn-close"
    //       onClick={onToggle}
    //       color="close-from"
    //       buttonText="닫기"
    //     />
    //   </div>
    // </ModalDialog>
    <CommonModal
      className={classNames('confirm-modal', className)}
      contentText={contentText}
      headerText={headerText}
      headerImage={headerImage}
      isOpen={isOpen}
      onToggle={onToggle}
      okButtonText={okButtonText}
      okButtonLoading={okButtonLoading}
      closeButtonText={closeButtonText}
      onClickOk={onClickOk}
      isProviderModal={isProviderModal}
    />
  );
}

ConfirmModal.propTypes = {
  className: PropTypes.string,
  contentText: PropTypes.string,
  headerText: PropTypes.string,
  isOpen: PropTypes.bool,
  okButtonText: PropTypes.string,
  onClickOk: PropTypes.func,
  onToggle: PropTypes.func
};

ConfirmModal.defaultProps = {
  className: '',
  contentText: '',
  headerText: '주의',
  isOpen: false,
  okButtonText: '',
  onClickOk: () => {},
  onToggle: () => {}
};

export default ConfirmModal;
