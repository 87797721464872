import React from 'react';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';

import 'froala-editor/js/plugins.pkgd.min.js';

import 'froala-editor/js/languages/ko.js';

// import 'froala-editor/js/froala_editor.pkgd.min.js';

// import 'froala-editor/js/plugins/link.min.js';
// import 'froala-editor/js/plugins/lists.min.js';
// import 'froala-editor/js/third_party/image_tui.min.js';

// import 'froala-editor/css/froala_editor.min.css';
// import 'froala-editor/css/third_party/image_tui.min.css';
// import 'font-awesome/css/font-awesome.css';
// import 'froala-editor/js/third_party/font_awesome.min.js';

// import 'froala-editor/js/froala_editor.min';
// import 'froala-editor/js/plugins.pkgd.min';
// import 'froala-editor/js/languages/ko';

// import 'froala-editor/js/froala_editor.pkgd.min';

// import 'froala-editor/css/froala_editor.pkgd.min.css';
// import 'froala-editor/css/froala_editor.min.css';
// import 'froala-editor/css/froala_style.min.css';

import './EditorComp.scss';

// let FroalaEditor;
// let FroalaEditorView;

// if (typeof window !== 'undefined') {
//   // eslint-disable-next-line
//   require('froala-editor/js/languages/ko');
//   // eslint-disable-next-line
//   require('froala-editor/js/froala_editor.pkgd.min');
//   // eslint-disable-next-line
//   require('froala-editor/js/plugins.pkgd.min');
//   FroalaEditor = require('react-froala-wysiwyg').default;
//   FroalaEditorView = require('react-froala-wysiwyg/FroalaEditorView').default;
// }
function EditorComp({ htmlProductInfo, onModelChange }) {
  const editorConfig = {
    key: 'cJC7bB5C2C2F2G2I2yQNDMIJg1IQNSEa1EUAi1XVFQd1EaG3C2A5A4C4D3B2D4G2E2==',
    language: 'ko',
    imageUploadURL:
      'https://akuotb3hfi.execute-api.ap-northeast-2.amazonaws.com/dev/s3/upload',
    imageDefaultWidth: 420,
    width: 430
    // // 툴바 버튼 종류
    // toolbarButtons: [
    //   // ['fontFamily', 'fontSize', 'textColor', 'backgroundColor'],
    //   [
    //     'insertLink',
    //     'insertImage',
    //     //'insertVideo'
    //     'html'
    //   ]
    // ],
    // // 기본 폰트 크기 설정 안먹음;

    // fontSizeDefaultSelection: 18,
    // // fontSize: [14, 18, 24],
    // // // 폰트 family
    // // fontFamily: {
    // //   'Nanum Sqaure': '보통',
    // //   'Nanum SqaureB': '굵게',
    // //   'Nanum SqaureEB': '더굵게'
    // // }
    // fontFamily: {
    //   'Nanum Sqaure': 'Nanum Sqaure',
    //   'Nanum SqaureB': 'Nanum Sqaure Bold',
    //   'Nanum SqaureEB': 'Nanum Sqaure Extra Bold'
    // },
    // fontSize: [
    //   '8',
    //   '9',
    //   '10',
    //   '11',
    //   '12',
    //   '13',
    //   '14',
    //   '15',
    //   '16',
    //   '17',
    //   '18',
    //   '19',
    //   '20',
    //   '21',
    //   '22',
    //   '23',
    //   '24',
    //   '25',
    //   '26',
    //   '27',
    //   '28',
    //   '29',
    //   '30',
    //   '36',
    //   '48',
    //   '60',
    //   '72',
    //   '96'
    // ],
    // fontFamilyDefaultSelection: 'Nanum Sqaure',

    // lineHeights: {
    //   Default: '',
    //   '1': '1',
    //   '1.1': '1.1',
    //   '1.2': '1.2',
    //   '1.3': '1.3',
    //   '1.4': '1.4',
    //   '1.5': '1.5',
    //   '1.6': '1.6',
    //   '1.7': '1.7',
    //   '1.8': '1.8',
    //   '1.9': '1.9',
    //   '2': '2'
    // }
    // 퀵버튼
    // quickInsertEnabled: false
  };

  return (
    <div className="d-flex">
      <div>
        <div className="editor-comp-label mt-3 mb-3">상품상세정보</div>
        {/* {device() === 'Mobile' && (
            <Fragment>
              <span className="editor-comp-label-text mb-3">
                <span>?</span> 상품상세정보 수정은 PC 크롬브라우저에서만
                가능합니다.
              </span>
              <hr />
            </Fragment>
          )} */}
        <div>
          {FroalaEditorComponent ? (
            <FroalaEditorComponent
              model={htmlProductInfo}
              onModelChange={onModelChange}
              config={editorConfig}
            />
          ) : (
            <div>- 오류로 정보를 불러오지 못했습니다.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditorComp;
