import React, { Component, Fragment } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Helmet } from 'react-helmet';
import { bindActionCreators, compose } from 'redux';
import { change } from 'redux-form';

import * as userActions from 'modules/user';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';
import { getQuerys } from 'lib/utils/commonUtils';
import { clearSession, saveSession } from 'lib/users/auth';

import ProviderSignInForm from 'components/provider/ProviderSignInForm';
//import ProviderResetPasswordContainer from './ProviderResetPasswordContainer';

class ProviderSignInContainer extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = this.props;
    this.savedId = cookies.get('provider_id');

    this.state = {
      isCheckedStoreId: !!this.savedId || true,
      isLoading: false,
      isErrorSignIn: false
    };
  }

  componentDidMount() {
    const { changeField, UiAction } = this.props;

    changeField('id', this.savedId);

    UiAction.setHeader('공급자 로그인');

    this.initialize();
  }

  componentDidUpdate = (prevProps) => {
    const { isLoggedIn } = this.props;
    const { isLoggedIn: prevIsLoggedIn } = prevProps;

    if (isLoggedIn === true && prevIsLoggedIn === false) {
      this.initialize();
    }
  };

  setCookie = (id) => {
    const { cookies } = this.props;
    const exdate = new Date();

    exdate.setDate(exdate.getDate() + 7); // 7일동안 쿠키 보관

    cookies.set('provider_id', id, {
      expires: exdate,
      path: '/'
    });
  };

  removeCookie = () => {
    const { cookies } = this.props;
    const exdate = new Date();

    exdate.setDate(exdate.getDate() - 1);

    cookies.remove('provider_id', {
      expires: exdate
    });
  };

  initialize = () => {
    const { isLoggedIn, isProvider } = this.props;

    // 로그인을 안한 상태면 공급자 로그인을 한다는 뜻으로 간주한다.
    if (!isLoggedIn) {
      return;
    }

    // 공급자 로그인이 되어 있다면 바로 다른 페이지로 이동 시킨다.
    if (isProvider) {
      this.redirectPageAfterLogin();

      return;
    }

    // 그 외에는 1S로 로그인 되어 있다고 가정하고 무조건 로그아웃을 시킨 후 새로 고침을 한다.
    clearSession();

    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  };

  redirectPageAfterLogin = () => {
    const { history, location, user } = this.props;
    const defaultUrl = `/provider/${user.id}`;

    if (!location || !location.search) {
      history.replace(defaultUrl);

      return;
    }

    const querys = getQuerys(location.search);

    if (!querys.case) {
      history.replace(defaultUrl);

      return;
    }

    const isRegisterProductType = querys.case.substring(0, 1) === 'r';

    if (!isRegisterProductType) {
      return;
    }

    const discoverId = querys.case.slice(2);

    history.replace(`/provider/product/${discoverId}/register`);
  };

  handleSubmit = (values) => {
    const { UserActions } = this.props;

    this.setState(
      {
        isLoading: true
      },
      async () => {
        try {
          // login api 호출
          const response = await UserActions.signInProvider(values);

          if (!response || !response.data.success) {
            throw new Error('login error');
          }

          // session 저장
          response.data.userInfo.isProvider = true;
          saveSession(response.data);

          // mobile login callback
          const { userInfo } = response.data;
          const userAgent = navigator.userAgent.toLowerCase();
          if (userAgent.indexOf('1store_iphone') > -1) {
            const loginUserInfo = {
              userId: userInfo.id,
              userType: 'provider'
            };
            // eslint-disable-next-line
            webkit.messageHandlers.userLogIn.postMessage(
              JSON.stringify(loginUserInfo)
            );
          } else if (userAgent.indexOf('1store_android') > -1) {
            const loginUserInfo = {
              userId: userInfo.id,
              userType: 'store'
            };
            // eslint-disable-next-line
            __1STORE_ANDROID.userLogIn(JSON.stringify(loginUserInfo));
          }

          // 이메일 저장에 체크되어 있으면 쿠키에 이메일 주소 저장, 그렇지 않으면 이메일 주소 삭제
          const { isCheckedStoreId } = this.state;
          if (isCheckedStoreId === true) {
            this.setCookie(values.id);
          } else {
            this.removeCookie();
          }

          // page redirection
          const { history, location } = this.props;
          if (location.state && location.state.redirectUrl) {
            const { redirectUrl } = location.state;
            const values = redirectUrl.split('-');
            let pathname = values[0];
            pathname = pathname.replace('$providerId', userInfo.id);
            history.replace(pathname);
          } else {
            history.replace(`/provider/${userInfo.id}`);
          }
        } catch (e) {
          this.setState({
            isErrorSignIn: true,
            isLoading: false
          });
        }
      }
    );
  };

  handleToggleCheckbox = (checked) => {
    this.setState({
      isCheckedStoreId: checked
    });
  };

  render() {
    const { location } = this.props;
    const { isCheckedStoreId, isLoading, isErrorSignIn } = this.state;

    return (
      <Fragment>
        {/* <Helmet bodyAttributes={{ style: 'background-color : #f6f6f6' }} /> */}
        <ProviderSignInForm
          isCheckedStoreId={isCheckedStoreId}
          isLoading={isLoading}
          isErrorSignIn={isErrorSignIn}
          onSubmit={this.handleSubmit}
          onToggleCheckbox={this.handleToggleCheckbox}
          querys={location ? location.search : ''}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => {
    dispatch(change('providerSignInForm', field, value));
  },
  UserActions: bindActionCreators(userActions, dispatch)
});

export default compose(
  withCookies,
  withRouter,
  withUser,
  withUi,
  connect(
    null,
    mapDispatchToProps
  )
)(ProviderSignInContainer);
