import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProductContainer from 'containers/product/ProductContainer';

function Product({ history, match, location }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ProductContainer
        history={history}
        productId={match.params.productId}
        location={location}
      />
    </Fragment>
  );
}
export default Product;
