import axios from 'axios';

const developApi =
  // 'https://dv2bzcouge.execute-api.ap-northeast-2.amazonaws.com/dev';
  'https://admin-api.from.kr/dev';
const productApi =
  'https://dv2bzcouge.execute-api.ap-northeast-2.amazonaws.com/prod';
// 'https://admin-api.from.kr/prod';
// axios.defaults.withCredentials = true;

const searchAxios = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? developApi : productApi,
  // withCredentials: true,
  responseType: 'json'
});

export default searchAxios;
