import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { getPriceFormat } from 'lib/utils/numberUtils';
import { getImagePath } from 'lib/utils/imageUtils';

import { StyledItem } from './ItemStyle';

function CartItem({
  itemType = 'cart',
  index = 0,
  isChecked,
  option,
  product,
  seller,
  provider,
  price,
  quantity,
  amount,
  optionDelivery,
  deliveryFee,
  totalAmount,
  error,
  removeItem,
  increment,
  decrement,
  toggle,
  onClickOption
}) {
  const { name: optionName, imagePath: optionImgPath } = option || {};
  const { providerProfileImgPath } = provider || {};
  const { officialProductImgPath: defaultImgPath } = product || {};
  const { unitPrice, discountPercent, discountPrice, giftPoint } = price || {};

  const isLimitedToOne = product?.isFortune === true || product?.isRecipe === true || product?.isCarrige === true;

  return (
    <StyledItem className={itemType === 'checkout' ? 'checkout-item' : ''}>
      {itemType === 'cart' && (
        <div
          className="item-status"
          onClick={() => toggle(product.id, option.id)}
        >
          {isChecked ? (
            <img src="/images/icon_option_check.svg" alt="선택됨" />
          ) : (
            <img src="/images/icon-option-unchecked.svg" alt="선택않음" />
          )}
        </div>
      )}

      <div className="item-title">
        <Link to={`/product/${product.id}`}>
          {`${product.name || product.productName}`}
        </Link>
      </div>
      <button
        className="remove-item-btn"
        type="button"
        onClick={() => removeItem(product.id, option.id)}
      >
        <img src="/images/form-close-button-icon.svg" alt="아이템취소" />
      </button>
      {itemType !== 'checkout' && (
        <div className="item-image">
          <img
            className="main-image"
            src={getImagePath(optionImgPath || defaultImgPath, 250, 250)}
            alt="상품 이미지"
          />
          <img
            className="sub-image"
            src={getImagePath(
              providerProfileImgPath || '/images/default-profile.svg',
              50,
              50
            )}
            alt="상품 이미지"
          />
        </div>
      )}
      <div
        className="item-subtitle"
        onClick={() => onClickOption(seller, product, provider)}
      >
        {optionName}
        <img src="/images/more-button-2.svg" alt="옵션변경" />
      </div>
      <div className="item-price">
        {!isNaN(discountPrice) && (
          <div>
            <span className="discount-price">
              {getPriceFormat(discountPrice)}원
            </span>
            {giftPoint > 0 && (
              <span className="gift-point">
                {` + ${getPriceFormat(giftPoint)}원 적립`}
              </span>
            )}
          </div>
        )}
        {discountPercent > 0 && itemType !== 'checkout' && (
          <div className="regular-price">{getPriceFormat(unitPrice)}원</div>
        )}
      </div>
      <div className="number-picker">
        <button
          type="button"
          className="minus-button"
          onClick={() => decrement(product.id, option.id)}
        />
        <span>{quantity}</span>
        <button
          type="button"
          className="plus-button"
          onClick={() => increment(product.id, option.id, isLimitedToOne && quantity > 0 ? 0 : 1)}
        />
      </div>
      {itemType === 'cart' && (
        <div className="item-total">
          <span>합계</span>
          {getPriceFormat(totalAmount)}원
        </div>
      )}
    </StyledItem>
  );
}

export default CartItem;
