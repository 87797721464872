import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import axios from 'api/defaultAxios';
import { compose } from 'redux';

import { getDiscover } from 'api/discover';
import { getProvider } from 'api/provider';

import withUser from 'lib/hoc/withUser';
import withUi from 'lib/hoc/withUi';
import { s3Upload, s3Delete } from 'lib/aws/s3Upload';
import sentry from 'lib/sentry';

import DiscoverStatusForm from 'components/oneStore/DiscoverStatusForm/DiscoverStatusForm';
import SearchProductModal from 'components/modal/SearchProductModal';
import SearchProviderModal from 'components/modal/SearchProviderModal';
import AlertModal from 'components/modal/AlertModal';
import LogoModal from 'components/modal/LogoModal';

class DiscoverRequestStatusContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenErrorModal: false,
      isOpenSuccessModal: false,
      errorMessage: '',
      isPossibleEdit: false,
      showMyProductButton: false,
      discovererId: '',
      productName: '',
      providerName: '',
      providerTel: '',
      productId: '',
      storeId: '',
      recommendation: '',
      productImgPathList: [],
      tags: '',
      createDate: '',
      updateDate: '',
      doneDate: '',
      haveProductImages: false,
      isLoading: false,
      historyStatusProcess: [],
      isIniLoading: false
    };

    this.discoverData = {};
    this.firstDate = '';
    this.secondDate = '';
    this.thirdDate = '';
  }

  componentDidMount() {
    this.getDiscover();
  }

  getDiscover = () => {
    this.setState(
      {
        isIniLoading: true
      },
      async () => {
        const { user, match } = this.props;
        const { storeId, discoverId } = match.params;

        // 본인인지 체크
        if (!user || storeId !== user.id) {
          this.props.history.replace('/search/success');
          return;
        }

        try {
          const response = await getDiscover(discoverId);

          if (!response || !response.data || !response.data.success) {
            throw new Error();
          }

          let isPossibleEdit = false;
          let showMyProductButton = false;
          const { UiAction } = this.props;
          const { data } = response.data;
          const {
            nowStatusProcess,
            discovererId,
            productName,
            providerName,
            productId,
            productImgPathList,
            providerTel,
            recommendation,
            tags,
            historyStatusProcess
          } = data;
          const statusNumber = Number(nowStatusProcess);

          if (statusNumber === 0) {
            isPossibleEdit = true;
          }

          if (statusNumber === 2) {
            showMyProductButton = true;
          }

          // 발굴상태 날짜 가져오는 구문
          let tempCreate = 0;
          let tempUpdate = 0;
          let tempDone = 0;

          for (let i = 0; i < historyStatusProcess.length; i += 1) {
            const targetDate = Number(
              Object.entries(historyStatusProcess[i])[0][0]
            );
            const returnDate = Object.entries(historyStatusProcess[i])[0][1];
            if (targetDate === 0) {
              tempCreate = returnDate;
            }
            if (targetDate === 1) {
              tempUpdate = returnDate;
            }
            if (targetDate === 2) {
              tempDone = returnDate;
            }
            if (targetDate === 3) {
              tempDone = returnDate;
            }
          }

          this.discoverData = data;

          UiAction.setHeader(productName);

          this.setState({
            nowStatusProcess,
            discovererId,
            productName,
            providerName,
            productId,
            providerTel,
            recommendation,
            productImgPathList,
            isPossibleEdit,
            showMyProductButton,
            tags,
            storeId,
            historyStatusProcess,
            createDate: tempCreate,
            updateDate: tempUpdate,
            doneDate: tempDone,
            isIniLoading: false
          });

          this.handleChangeDiscover();
        } catch (e) {
          sentry.captureException(JSON.stringify(e));

          this.setState({
            isOpenErrorModal: true,
            errorMessage: '데이터 요청에 실패하였습니다.',
            isIniLoading: false
          });
        }
      }
    );
  };

  handleChangeDiscover = () => {
    const {
      picEmail,
      picName,
      picMobile,
      productName,
      productUrl,
      providerName,
      providerTel,
      recommendation,
      tags
    } = this.discoverData;

    this.props.changeField('productName', productName);
    this.props.changeField('providerName', providerName);
    this.props.changeField('providerTel', providerTel);
    this.props.changeField('recommendation', recommendation);
    this.props.changeField('tags', tags);
    this.props.changeField('picEmail', picEmail);
    this.props.changeField('picName', picName);
    this.props.changeField('picMobile', picMobile);
    this.props.changeField('productUrl', productUrl);
    this.props.changeField('tags', tags);
  };

  handleBack = (e) => {
    e.stopPropagation();

    this.props.history.goBack(-1);
  };

  handleAddProductImage = (productImage) => {
    const { productImgPathList } = this.state;
    const uniqueId = Math.random()
      .toString(36)
      .substr(2, 9);

    productImage.id = uniqueId;

    this.setState({
      productImgPathList: [...productImgPathList, productImage],
      haveProductImages: true
    });
  };

  handleRemovePicture = (index) => {
    const { productImgPathList } = this.state;

    productImgPathList.splice(index, 1);

    this.setState({
      productImgPathList,
      haveProductImages: productImgPathList.length > 0
    });
  };

  handleDeleteImage = async () => {
    let isSuccess = false;

    try {
      const { productImgPathList } = this.discoverData;
      const deleteProductImages = [];

      productImgPathList.forEach((image) => {
        if (!image) {
          return;
        }

        deleteProductImages.push(s3Delete(image.path || ''));
      });

      await Promise.all(deleteProductImages);

      isSuccess = true;
    } catch (err) {
      alert(err.message);
      sentry.captureException(JSON.stringify(err));

      this.setState({
        isLoading: false,
        isOpenErrorModal: true,
        errorMessage:
          '발굴 정보 수정에 실패하였습니다.\n잠시 후 다시 시도해주세요.'
      });
    }

    return isSuccess;
  };

  handleSubmit = (values) => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        try {
          const isDeletedImages = await this.handleDeleteImage();

          if (!isDeletedImages) {
            return;
          }

          const { match } = this.props;
          const { storeId, discoverId } = match.params;
          const { productImgPathList } = this.state;

          values.discoverId = discoverId;

          // // 상품 이미지 업로드
          const uploadProductImages = [];
          const productImgList = [];

          productImgPathList.forEach((image) => {
            if (!image) {
              return;
            }

            uploadProductImages.push(
              s3Upload(image.path || image.image, 'productImage')
            );

            productImgList.push({
              width: image.width,
              height: image.height
            });
          });

          const uploadProductImgPathList = await Promise.all(
            uploadProductImages
          );

          for (let i = 0; i < uploadProductImgPathList.length; i += 1) {
            productImgList[i].path = uploadProductImgPathList[i];
          }

          values.productImgPathList = productImgList;

          this.discoverData = {
            ...this.discoverData,
            ...values
          };

          await axios
            .put(`/store/${storeId}/discovery/${discoverId}`, this.discoverData)
            .then((res) => {
              if (!res.data.success) {
                throw new Error(res);
              }

              this.setState({
                isLoading: false,
                isOpenSuccessModal: true
              });
            });
        } catch (err) {
          sentry.captureException(JSON.stringify(err));

          this.setState({
            isLoading: false,
            isOpenErrorModal: true,
            errorMessage:
              '발굴 정보 수정에 실패하였습니다.\n잠시 후 다시 시도해주세요.'
          });
        }
      }
    );
  };

  handleRedirectPage = () => {
    const { history, match } = this.props;
    const { discoverId } = match.params;

    history.push(`/discover/${discoverId}`);
  };

  handleChangeProduct = (productName) => {
    const { changeField } = this.props;

    changeField('productName', productName);

    this.handleCloseModal('isOpenProductModal');
  };

  handleChangeProvider = (providerName) => {
    const { changeField } = this.props;

    changeField('providerName', providerName);

    this.handleCloseModal('isOpenProviderModal');
  };

  handleSelectProvider = async (providerId) => {
    const { changeField } = this.props;
    try {
      const response = await getProvider(providerId);

      if (!response || !response.data || !response.data.success) {
        throw new Error('공급자 정보호출 실패');
      }

      const { data } = response.data;
      const { id, providerName, mobile } = data;

      this.providerId = id;

      changeField('providerName', providerName);
      changeField('providerTel', mobile);
    } catch (e) {
      sentry.captureException(JSON.stringify(e));

      this.setState({
        isOpenErrorModal: true,
        errorMessage:
          '공급자 정보호출에 실패하였습니다.\n잠시 후 다시 시도해주세요.'
      });
    }
  };

  handleOpenModal = (modalType) => {
    this.setState({
      [modalType]: true
    });
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  render() {
    const {
      isOpenProductModal,
      isOpenProviderModal,
      isOpenErrorModal,
      isOpenSuccessModal,
      errorMessage,
      nowStatusProcess,
      updateDate,
      discovererId,
      productName,
      productId,
      storeId,
      providerName,
      providerTel,
      recommendation,
      productImgPathList,
      tags,
      createDate,
      doneDate,
      isPossibleEdit,
      showMyProductButton,
      haveProductImages,
      isLoading,
      isIniLoading,
      historyStatusProcess
    } = this.state;
    const productImage = productImgPathList[0]
      ? productImgPathList[0].path
      : '/images/default-image.svg';
    return (
      <Fragment>
        <DiscoverStatusForm
          discovererId={discovererId || ''}
          productName={productName || ''}
          providerTel={providerTel || ''}
          recommendation={recommendation || ''}
          tags={tags || ''}
          providerName={providerName || ''}
          productId={productId || ''}
          receiptImagePath={null}
          productImgPathList={productImgPathList || []}
          createDate={createDate || ''}
          updateDate={updateDate || ''}
          doneDate={doneDate || ''}
          isPossibleEdit={isPossibleEdit}
          nowStatusProcess={nowStatusProcess}
          showMyProductButton={showMyProductButton}
          onChangeReceiptImage={this.handleChangeReceiptImage}
          onClickBack={this.handleBack}
          storeId={storeId}
          onSelectProductImage={this.handleAddProductImage}
          onRemovePicture={this.handleRemovePicture}
          onSubmit={this.handleSubmit}
          haveProductImages={haveProductImages}
          isLoading={isLoading}
          isIniLoading={isIniLoading}
          productImage={productImage}
          historyStatusProcess={historyStatusProcess}
          onOpenProductModal={(e) => {
            this.handleOpenModal('isOpenProductModal');

            if (e) {
              e.target.blur();
            }
          }}
          onOpenProviderModal={(e) => {
            this.handleOpenModal('isOpenProviderModal');

            if (e) {
              e.target.blur();
            }
          }}
        />
        <SearchProductModal
          isOpen={isOpenProductModal}
          onClickNew={this.handleChangeProduct}
          onToggle={() => {
            this.handleCloseModal('isOpenProductModal');
          }}
        />
        <SearchProviderModal
          isOpen={isOpenProviderModal}
          onClickNew={this.handleChangeProvider}
          onSelect={this.handleSelectProvider}
          onToggle={() => {
            this.handleCloseModal('isOpenProviderModal');
          }}
        />
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
        <LogoModal
          isOpen={isOpenSuccessModal}
          contentText="수정이 완료되었습니다."
          buttonText="발굴 보기"
          headerText="수정 완료"
          onToggle={() => {
            this.handleCloseModal('isOpenSuccessModal');
          }}
          onClick={this.handleRedirectPage}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeField: (field, value) => {
    dispatch(change('discoverStatusForm', field, value));
  }
});

export default compose(
  withRouter,
  withUi,
  withUser,
  connect(
    null,
    mapDispatchToProps
  )
)(DiscoverRequestStatusContainer);
