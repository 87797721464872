import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation, useQueryCache } from 'react-query';
// import { useLoginStore } from '../Login/LoginStore';
import {
  postComment,
  deleteComment,
  putComment,
  putLikeComment
} from 'api/post';

function usePostCard(
  post = {},
  { isLoggedIn, user, isProvider, isReply = false, isDetail = false } = {}
) {
  // console.log({ post });
  const location = useLocation();
  const history = useHistory();
  // const { isLoggedIn, id, name, profileImgPath } = useLoginStore();
  // console.log({ user });

  const { id, userName: name, profileImgPath } = user || {};

  // console.log({ location });
  // console.log({ history });
  const [showDetail, setShowDetail] = useState(isDetail);
  const [likeUsers, setLikeUsers] = useState(
    post.likeUserList ? post.likeUserList : []
  );
  const [liked, setLiked] = useState(
    likeUsers.some((user) => user.userId === id)
  );
  const [likeCount, setLikeCount] = useState(likeUsers.length);
  const [canAdd, setCanAdd] = useState(isLoggedIn);
  const [canEdit, setCanEdit] = useState(isLoggedIn && post.authorId === id);

  const queryCache = useQueryCache();

  const [mutate, { status, error, isLoading }] = useMutation(
    async ({ values, cb, isLikeUser }) => {
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      // console.log('submitting');
      // return values;
      // console.log({ values });

      const response = await cb(values);
      // console.log({ response });
      return response.data.data;
    },
    {
      onSuccess: (data, variables) => {
        // console.log({ data });
        // console.log({ variables });

        const {
          storeId,
          productId,
          ownerId,
          commentsId,
          depth,
          likeUserList = []
        } = data;
        // console.log({ likeUsers });
        // console.log({ likeUserList });

        setLikeUsers(likeUserList);

        let rootId = commentsId;
        if (depth > 0) {
          rootId = commentsId
            ? `${commentsId.split('-')[0]}-0000`
            : '0000-0000';
        }

        const postLink = `/post/${ownerId || storeId}/${
          productId || ownerId
        }/${rootId}`;

        // console.log({ postLink });
        if (!variables.isLikeUser) {
          history.replace(postLink);
        }
      }
    }
  );

  const [
    mutateDelete,
    { isSuccess: isDeleted, error: deleteError, isLoading: isDeleting }
  ] = useMutation(
    async ({ values }) => {
      const response = await deleteComment(values);

      // console.log({ response });
      return response.data.data;
    },
    {
      onSuccess: (data, variables) => {
        // console.log('Deleted');
        // console.log({ data });
        // console.log({ variables });
        const { expId, commentsId } = variables.values;
        const firstCommentsId = `${commentsId.split('-')[0]}-0000`;
        const id = `${expId}-${firstCommentsId}`;
        // console.log({ id });
        queryCache.invalidateQueries(['post', { id }]);
        queryCache.invalidateQueries('posts');
      }
    }
  );

  useEffect(() => {
    // console.log({ id });
    // console.log({ likeUsers });
    setLiked(likeUsers.some((user) => user.userId === id));
    setLikeCount(likeUsers.length);
  }, [likeUsers]);

  const onShowDetail = () => setShowDetail((old) => !old);

  const onCreate = async (values) => {
    try {
      // console.log('onCreate');
      await mutate({ values, cb: postComment });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const onUpdate = async (values) => {
    try {
      // console.log('onUpdate');
      await mutate({ values, cb: putComment });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const onDelete = async () => {
    try {
      if (!canEdit)
        throw new Error({
          errorMessage: '삭제 권한이 없습니다.'
        });

      const {
        expId,
        commentsId,
        depth,
        storeId,
        productId,
        ownerId,
        owner,
        storeType
      } = post;
      const values = {
        expId,
        commentsId,
        depth
      };

      // console.log({ location });
      await mutateDelete({ values });

      const ownerType = owner?.type === 'provider' ? 'provider' : 'user';
      const pageLink = productId
        ? `/${ownerType}/${ownerId || storeId}/${productId}/posts`
        : `/${ownerType}/${ownerId || storeId}/posts`;

      if (
        location.pathname ===
          `/post/${ownerId || storeId}/${productId || ownerId}/${commentsId}` &&
        depth === 0
      ) {
        // alert('삭제되었습니다');
        history.replace(pageLink);
      }
    } catch (error) {
      console.log({ error });
      throw error;
    }
  };

  const onLike = async () => {
    try {
      if (!canAdd) {
        alert('Login to like');
        return;
      }
      const newLikeUserList = liked
        ? likeUsers.filter((user) => user.userId !== id)
        : [...likeUsers, { userId: id, userName: name, profileImgPath }];
      const values = { ...post, likeUserList: newLikeUserList };
      // console.log('onLike');
      // console.log({ newLikeUserList });
      const { likeUserList } = await mutate({
        values,
        cb: putComment,
        isLikeUser: true
      });
      // console.log({ likeUserList });
      setLikeUsers(likeUserList);
    } catch (error) {
      console.log({ error });
      throw error;
    }
  };

  return {
    showDetail,
    canAdd,
    canEdit,
    liked,
    likeCount,
    onShowDetail,
    onCreate,
    onUpdate,
    onDelete,
    onLike,
    isLoading,
    status,
    error,
    isDeleted,
    isDeleting,
    deleteError
  };
}
export default usePostCard;
