import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import EmptyList from 'components/commons/EmptyList';
import Loader from 'components/commons/Loader';
import CommonProfilePicture from 'components/picture/CommonProfilePicture';

// import ProviderConnectStore from './ProviderConnectStore';

import './ProviderConnectStoreList.scss';

function ProviderConnectStore({ oneStores }) {
  const { profileImgPath, storeId, storeName } = oneStores;

  return (
    <Link to={`/store/${storeId}`}>
      <div className="follow-list-item">
        <CommonProfilePicture
          profileImgPath={profileImgPath}
          name={storeName}
          size={30}
          className={classNames(
            'follow-list-item-profile-image provider-connect-store-profile-image'
          )}
        />
        <div className="follow-list-item-name">{storeName}</div>
        <img
          className="follow-list-item-link-icon"
          src="/images/onestore-setting-link-icon.svg"
          alt="화살표"
        />
      </div>
    </Link>
  );
}

function ProviderConnectStoreList({
  oneStoreList,
  isLoading,
  infiniteRef,
  providerName
}) {
  const haveOneStoreList = oneStoreList && oneStoreList.length > 0;

  return (
    <div className="follow-list-wrapper" ref={infiniteRef}>
      <div className="follow-list-label">
        {providerName ? `${providerName} 응원자` : '응원자'}
      </div>
      <div className="follow-list">
        {isLoading && <Loader />}
        {haveOneStoreList ? (
          oneStoreList.map((oneStores) => {
            return (
              <ProviderConnectStore
                oneStores={oneStores}
                key={`${oneStores.id}`}
              />
            );
          })
        ) : (
          <EmptyList className="mt-5" />
        )}
      </div>
    </div>
  );
}

export default ProviderConnectStoreList;
