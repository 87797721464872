import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useCartStore, useCartDispatch } from 'components/cart/CartStore';
import withUser from 'lib/hoc/withUser';
import withUi from 'lib/hoc/withUi';

import { isDevelop } from 'lib/utils/envUtils';
import { postLogOutUser } from 'api/store';
import { clearSession } from 'lib/users/auth';
import sentry from 'lib/sentry';

import { StyledHeader } from './HeaderStyle';
const PROVIDER_PATH_LIST = ['/provider/signin'];

function HeaderContainer({ user, isProvider, headerTitle, showHeader }) {
  const history = useHistory();
  const { pathname } = history.location;
  const { checkedTotal, uncheckedTotal } = useCartStore();
  const cartDispatch = useCartDispatch();

  const [isProviderHeader, setIsProviderHeader] = useState(isProvider);

  useEffect(() => {
    if (!pathname) return;
    const found = PROVIDER_PATH_LIST.find((k) => pathname.match(k));

    if (found) {
      setIsProviderHeader(true);
    }
  }, [pathname]);

  const handleScrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  const handleGoBack = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  const handleReload = (e) => {
    window.location.reload();
  };

  const onSignOut = async () => {
    const userAgent = navigator.userAgent.toLowerCase();

    let browserType = 'web';

    if (userAgent.indexOf('1store_iphone') > -1) {
      browserType = 'ios';
    }
    if (userAgent.indexOf('1store_android') > -1) {
      browserType = 'android';
    }

    const params = {
      userType: 'store',
      userId: user?.id,
      userAgent: browserType
    };

    try {
      const response = await postLogOutUser(params);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`로그아웃실패 - ${JSON.stringify(response)}`);
      }

      clearSession();

      window.location.href = '/order/cart';
    } catch (e) {
      sentry.captureException(JSON.stringify(e));

      clearSession();

      window.location.href = '/';
    }
  };

  const quantity = checkedTotal?.quantity || 0; // + (uncheckedTotal?.quantity || 0);

  return (
    <StyledHeader
      className={
        !showHeader
          ? 'hidden-header'
          : isProviderHeader
          ? 'provider-header'
          : ''
      }
      onClick={handleScrollToTop}
    >
      <button type="button" className="back-btn" onClick={handleGoBack} />
      <button type="button" className="reload-btn" onClick={handleReload} />

      <h1 className="header-title">{headerTitle}</h1>
      {/* {isDevelop() && (
        <Fragment>
          <button type="button" className="dev-button" onClick={onSignOut}>
            O
          </button>

          <button
            type="button"
            className="dev-button"
            onClick={() => history.push('/order/payment')}
          >
            P
          </button>

          <button
            type="button"
            className="dev-button"
            onClick={() => cartDispatch({ type: 'empty' })}
          >
            X
          </button>
        </Fragment>
      )} */}
      <Link to="/order/cart" className="header-link cart">
        <img src="/images/cart-icon.svg" alt="카트" />
        {quantity > 0 && <div className="badge">{quantity}</div>}
      </Link>
      <Link to="/search" className="header-link search">
        <img src="/images/header-search-icon-w.svg" alt="검색 아이콘" />
      </Link>
    </StyledHeader>
  );
}
export default withUser(withUi(HeaderContainer));
