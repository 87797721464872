import { useInfiniteQuery } from 'react-query';

import fetchData from 'api/fetchData';
import { getStorePushNotifyList } from 'api/store';

const STALE_TIME = 1000 * 60 * 10;
const ITEMS_PER_PAGE = 20;

const getMessages = async (
  key,
  { storeId, itemsPerPage = ITEMS_PER_PAGE },
  LastEvaluatedKey
) => {
  try {
    // console.log({ LastEvaluatedKey });
    const params = {
      storeId,
      pageCount: itemsPerPage,
      lastKey: LastEvaluatedKey
    };
    const func = getStorePushNotifyList;

    const data = await fetchData(func, params);

    // console.log({ data });
    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export function useInfiniteMessages(searchConditions) {
  const query = useInfiniteQuery(
    ['messages-infinite', searchConditions],
    getMessages,
    {
      getFetchMore: (lastPage) => {
        // console.log({ lastPage });
        return lastPage.LastEvaluatedKey;
      },
      staleTime: STALE_TIME
    }
  );
  return query;
}
