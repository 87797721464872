import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import ProfilePicture from 'components/picture/ProfilePicture';
import SummaryText from 'components/commons/SummaryText';
import TextInput from 'components/commons/TextInput';
import Button from 'components/commons/Button';
import AlertModal from 'components/modal/AlertModal';
import FollowButton from 'components/commons/FollowButton';
import sentry from 'lib/sentry';

import { putRecommendation } from 'api/oneProduct';
import { getStore } from 'api/store';
import { getProvider } from 'api/provider';

import { getFormatTimeStempDate } from 'lib/time';

import './RecommendEditBox.scss';

export default function RecommendEditBox({
  name,
  profileImgPath,
  storeId,
  productId,
  createDate,
  recommendation,
  providerId,
  isProvider,
  isDiscoverer,
  isOneStore,
  className,
  isMyOneProduct,
  user,
  isLoggedIn,
  isProviderUser
}) {
  const [viewRecommend, setViewRecommend] = useState(recommendation);
  const [showRecommendation, setShowRecommendation] = useState(true);
  const [longRecommendation, setLongRecommendation] = useState(false);
  const [showEditInput, setShowEditInput] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [editInputValue, setEditInputValue] = useState(recommendation);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [targetInfo, setTargetInfo] = useState({});

  //   const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setViewRecommend(recommendation);
  }, [recommendation]);

  useEffect(() => {
    if (viewRecommend && viewRecommend.length > 50) {
      setShowRecommendation(false);
      setLongRecommendation(true);
    }
  }, [viewRecommend]);

  useEffect(() => {
    async function getTargetInfo() {
      let response;

      if (isProvider) {
        if (!providerId) {
          return;
        }

        response = await getProvider(providerId);
      } else {
        if (!storeId) {
          return;
        }

        response = await getStore(storeId);
      }

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `추천사 컴포넌트 -  데이터 불러오기 실패: ${JSON.stringify(
            response
          )}}`
        );
      }

      setTargetInfo(response.data.data);
    }

    getTargetInfo();
  }, [isProvider, providerId, storeId]);

  async function handleClickRecommendModifyButton() {
    setIsEditLoading(true);

    try {
      if (!editInputValue || !editInputValue.trim()) {
        throw new Error(`추천사가 없습니다.`);
      }

      // eslint-disable-next-line no-console
      // console.log({ editInputValue });

      const response = await putRecommendation({
        storeId,
        productId,
        recommendation: editInputValue
      });

      if (!response || !response.data) {
        return;
      }

      setViewRecommend(editInputValue);
      setShowEditInput(!showEditInput);
      setIsEditLoading(false);

      //   oneProductAction.putOneProductRecommedation(this.state.recommendMessage);
    } catch (err) {
      sentry.captureException(err);
      // eslint-disable-next-line no-console
      console.log({ err });

      setIsEditLoading(false);

      setIsOpenErrorModal(true);
      //   setErrorMessage('추천사 작성중 에러가 발생했습니다');

      //   this.setState({
      //     isOpenErrorModal: true,
      //     errorMessage: '추천사가 없습니다.'
      //   });
    }
  }

  const linkPath = isProvider
    ? `/provider/${providerId}`
    : isDiscoverer
    ? `/store/${storeId}/${productId}`
    : `/store/${storeId}`;

  const {
    id: targetId,
    bookmarkedIdList,
    storeNo,
    profileImgPath: storeProfileImgPath,
    providerProfileImgPath
  } = targetInfo;

  return (
    <Fragment>
      <div
        className={classNames(
          'discoverer-recommend-box recommend-edit-box-wrapper product-discoverer',
          className
        )}
      >
        <div className="product-discoverer-profile">
          <Link to={linkPath}>
            <ProfilePicture
              isProvider={isProvider}
              isDiscoverer={isDiscoverer}
              isOneStore={isOneStore}
              className={classNames('product-discoverer-image', {
                'profile-provider-image': isProvider
              })}
              profileImgPath={profileImgPath || ''}
            />
          </Link>
        </div>
        <div className="product-discoveror-recommend">
          <span className="product-discoverer-label">
            <Link to={linkPath}>
              {isProvider ? '공급자' : isDiscoverer ? '발굴자' : '프롬이'}{' '}
              {name}
            </Link>
            {!isProvider && (
              <span className="product-discoveror-recommend-date">
                {getFormatTimeStempDate(createDate, 'YYYY.MM.DD')}
              </span>
            )}
            {!isProviderUser && (
              <FollowButton
                user={user}
                isLoggedIn={isLoggedIn}
                bookmarkedIdList={bookmarkedIdList}
                targetType={isProvider ? 'PP' : '1S'}
                targetId={targetId}
                targetName={name}
                targetDetailInfo={
                  isProvider
                    ? { providerImgPath: providerProfileImgPath }
                    : { storeNo, profileImgPath: storeProfileImgPath }
                }
              />
            )}
          </span>
          <div className="product-discoverer-recommend-messagge recommend-product-2-wrapper">
            {/* {showEditInput ? (
              <Fragment>
                <TextInput
                  className="comment-input comment-edit-input"
                  value={editInputValue}
                  placeholder="응원글 수정"
                  // onFocus={onFocusCommentInput}
                  onChange={(value) => setEditInputValue(value)}
                  // readOnly={isEditLoading}
                  isPossibleSubmitWithEnter
                  // isShownSubmitButton
                  minHeight={36}
                />
                <div className="d-flex">
                  <div className="ml-auto">
                    <Button
                      className="comment-button recommend-edit-box-edit-close-button"
                      buttonText="닫기"
                      buttonType="button"
                      color="none"
                      onClick={() => {
                        setShowEditInput(!showEditInput);
                      }}
                    />
                    <Button
                      className="comment-button recommend-edit-box-edit-submit-button"
                      buttonText="수정완료"
                      buttonType="button"
                      color="from"
                      onClick={() => {
                        handleClickRecommendModifyButton();
                      }}
                      isLoading={isEditLoading}
                      isDisable={isEditLoading}
                    />
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <SummaryText
                  contentText={viewRecommend}
                  contentTextSlideIndex={47}
                  defaultMessage="아직 응원글을 남기지 않으셨습니다."
                  isLongText={longRecommendation}
                  isShownDetail={showRecommendation}
                  onClick={() => setShowRecommendation(!showRecommendation)}
                />
                {isMyOneProduct && (
                  <div className="d-flex">
                    <Button
                      className="comment-button recommend-edit-box-edit-button ml-auto"
                      buttonText="추천사수정"
                      buttonType="button"
                      color="from"
                      onClick={() => {
                        setEditInputValue(viewRecommend);
                        setShowEditInput(!showEditInput);
                      }}
                    />
                  </div>
                )}
              </Fragment>
            )} */}
            {!showEditInput && (
              <Fragment>
                <SummaryText
                  contentText={viewRecommend}
                  contentTextSlideIndex={47}
                  defaultMessage="아직 이야기를 남기지 않으셨습니다."
                  isLongText={longRecommendation}
                  isShownDetail={showRecommendation}
                  onClick={() => setShowRecommendation(!showRecommendation)}
                />
                {/* {isMyOneProduct && (
                  <div className="d-flex">
                    <Button
                      className="recommend-edit-box-edit-button"
                      buttonText={
                        viewRecommend === ''
                          ? '추천사 작성하기'
                          : '추천사 수정하기'
                      }
                      buttonType="button"
                      color="none"
                      onClick={() => {
                        setEditInputValue(viewRecommend);
                        setShowEditInput(!showEditInput);
                      }}
                    />
                  </div>
                )} */}
              </Fragment>
            )}
          </div>
        </div>
        {showEditInput && (
          <div className="recommend-edit-box-edit-control">
            <TextInput
              className="comment-input comment-edit-input"
              value={editInputValue}
              placeholder="응원글 수정"
              // onFocus={onFocusCommentInput}
              onChange={(value) => setEditInputValue(value)}
              // readOnly={isEditLoading}
              isPossibleSubmitWithEnter
              // isShownSubmitButton
              minHeight={36}
            />
            <div className="text-right">
              <Button
                className="comment-button recommend-edit-box-edit-close-button"
                buttonText="닫기"
                buttonType="button"
                color="none"
                onClick={() => {
                  setShowEditInput(!showEditInput);
                }}
              />
              <Button
                className="comment-button recommend-edit-box-edit-submit-button"
                buttonText="수정완료"
                buttonType="button"
                color="from"
                onClick={() => {
                  handleClickRecommendModifyButton();
                }}
                isLoading={isEditLoading}
                isDisable={isEditLoading}
              />
            </div>
          </div>
        )}
      </div>
      <AlertModal
        isOpen={isOpenErrorModal}
        contentText="추천사 작성중 에러가 발생했습니다"
        onToggle={() => setIsOpenErrorModal(!isOpenErrorModal)}
      />
    </Fragment>
  );
}
