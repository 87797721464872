import { useQuery } from 'react-query';

import fetchData from 'api/fetchData';
import { getProvider } from 'api/provider';
const STALE_TIME = 1000 * 60 * 10;
const ITEMS_PER_PAGE = 20;

const getProviderById = async (key, providerId) => {
  try {
    const data = await fetchData(getProvider, providerId);

    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export function useProvider({ providerId }) {
  const { data, isLoading, isError, error } = useQuery(
    ['provider', providerId],
    getProviderById,
    {
      staleTime: STALE_TIME
    }
  );

  return { data, isLoading, isError, error };
}
