import React, { Fragment, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { checkDuplicateMobile, checkDuplicateEmail } from 'api/store';

import { Input } from 'shared/components';

const isNativeApp = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (
    userAgent.indexOf('1store_iphone') > -1 ||
    userAgent.indexOf('1store_android') > -1
  ) {
    return true;
  }
  return false;
};
// iphone/android 주소록 버튼 함수
const onClickAddress = (elementName) => {
  try {
    // console.log({ elementName });
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('1store_iphone') > -1) {
      // eslint-disable-next-line
      webkit.messageHandlers.callContacts.postMessage(elementName);
    } else if (userAgent.indexOf('1store_android') > -1) {
      // eslint-disable-next-line
      __1STORE_ANDROID.openContact(elementName);
    }
  } catch (err) {
    // sentry.captureException(err);
    console.log({ err });
  }
};

const setUserContact = (elementName, userName, mobile) => {
  // normalize phone number TODO

  // set values
  // console.log(elementName);
  // const elNamePrefix = elementName.split('.')[0];
  const userNameInput = document.getElementsByName('name')[0];
  userNameInput.focus();
  userNameInput.value = userName;
  const mobileInput = document.getElementsByName('mobile')[0];
  mobileInput.focus();
  mobileInput.value = mobile;
  mobileInput.blur();
};

function UserSubForm({ showEmail }) {
  const location = useLocation();
  const history = useHistory();
  const { register, control, errors, setValue, className } = useFormContext();

  useEffect(() => {
    if (!global._native_setUserContact) {
      global._native_setUserContact = setUserContact;
    }
  }, []);

  const onButtonClick = () => {
    history.push({
      pathname: '/store/signin',
      state: {
        from: {
          pathname: location.pathname,
          state: { from: '/store/signin' }
        }
      }
    });
  };
  // console.log({ errors });
  return (
    <Fragment>
      <Input
        className={`${className} required`}
        name="name"
        label="이름"
        placeholder="이름"
        buttonLabel={isNativeApp() ? '주소록 >' : ''}
        onButtonClick={() => onClickAddress('name')}
        {...{ register, control, errors }}
      />
      <Input
        className={`${className} required`}
        type="tel"
        name="mobile"
        label="휴대폰"
        placeholder="-없이 숫자만 입력"
        buttonLabel={
          errors?.mobile?.type === 'checkDuplicateMobile' ? '로그인 >' : null
        }
        onButtonClick={onButtonClick}
        {...{ register, control, errors }}
      />
      {showEmail && (
        <Input
          className={`${className} required`}
          type="email"
          name="email"
          label="이메일"
          placeholder="이메일"
          buttonLabel={
            errors?.email?.type === 'checkDuplicateEmail' ? '로그인 >' : null
          }
          onButtonClick={onButtonClick}
          {...{ register, control, errors }}
        />
      )}
    </Fragment>
  );
}

export default UserSubForm;
