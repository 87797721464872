/* eslint-disable no-console */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { useProduct } from 'hooks/useProducts';
import { useOneProduct } from 'hooks/useOneProducts';

import Loader from 'components/commons/Loader';
import NavBar from 'components/ui/NavBar';
import OneProductMain from 'containers/oneProduct/OneProductMain';
import OneProductPosts from 'containers/oneProduct/OneProductPosts';
import ProductDetail from 'containers/oneProduct/ProductDetail';

import OrderButton from 'containers/product/OrderButton';

import withUi from 'lib/hoc/withUi';
import { isDevelop } from 'lib/utils/envUtils';
import { scrollToRef } from 'lib/utils/commonUtils';
import { setReferrer } from 'lib/referrer';

const isDev = isDevelop();

function ProductContainer({ productId, showHeader, UiAction, initialOpen }) {
  const [selectOptionIndex, setSelectOptionIndex] = useState(0);

  // console.log('OneProductContainer');
  // console.log({ storeId });
  // console.log({ productId });
  const location = useLocation();
  const history = useHistory();
  const {
    data: product,
    isLoading,
    isError,
    error
  } = useProduct({
    productId
  });

  const { storeId, providerId, oneProduct } = product || {};

  // const {
  //   data: oneProduct,
  //   isLoading: isOneProductLoading,
  //   isError: isOneProductError,
  //   error: oneProductError
  // } = useOneProduct({
  //   storeId,
  //   productId
  // });

  useEffect(() => {
    if (typeof window !== 'undefined' && !isDev) {
      window.fbq('track', 'ViewContent', {
        content_ids: productId,
        content_type: 'product'
      });
    }
  }, []);

  useEffect(() => {
    // console.log('useEffect for product container fired');
    if (product) {
      UiAction.setHeader(`FROM ${product.providerName}`);

      const parsed = location ? queryString.parse(location.search) : null;
      // console.log({ parsed });
      const referrerId = parsed && parsed.ref ? parsed.ref : storeId;
      // console.log({ referrerId });
      setReferrer(productId, referrerId);
    }
  }, [product]);

  const onChangeOptionIndex = (index) => {
    setSelectOptionIndex(index);
  };

  const productNavLinks = [
    { name: '기본정보', path: `/product/${productId}/main` },
    {
      name: '상세정보',
      path: `/product/${productId}/detail`
    },

    {
      name: '단골이야기',
      path: `/product/${productId}/posts`
    }
  ];
  // console.log({ product });
  // console.log({ oneProduct });
  // console.log({ isOneProductError });
  if (isError) {
    history.replace(`/provider/${product?.providerId}`);
  }

  // if (product && product.discovererId) {
  //   return <Redirect to={`/store/${product.discovererId}/${productId}`} />;
  // }

  return (
    <Fragment>
      <NavBar
        className="with-border"
        navLinks={productNavLinks}
        title={product?.productName}
      />
      {isLoading && <Loader />}

      <Route
        exact
        path="/product/:productId"
        render={() => <Redirect to={`/product/${productId}/detail`} />}
      />
      <Route
        exact
        path="/product/:productId/main"
        render={() => (
          <OneProductMain
            {...{
              storeId,
              productId,
              product,
              oneProduct,
              selectOptionIndex,
              onChangeOptionIndex
            }}
          />
        )}
      />
      <Route
        exact
        path="/product/:productId/detail"
        render={() => <ProductDetail {...{ storeId, productId, product }} />}
      />
      <Route
        exact
        path="/product/:productId/posts"
        render={() => (
          <OneProductPosts {...{ storeId, productId, product, oneProduct }} />
        )}
      />
      <OrderButton
        product={product}
        productId={productId}
        optionIndex={selectOptionIndex}
        isOneProduct={false}
        oneProduct={oneProduct}
        storeId={storeId}
        showHeader={showHeader}
        initialOpen={initialOpen}
      />
    </Fragment>
  );
}

export default withUi(ProductContainer);
