import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useInfiniteOneProducts } from 'hooks/useOneProducts';

import CommonProfilePicture from 'components/picture/CommonProfilePicture';
import FollowButton from 'components/commons/FollowButton';
import { ProfileWrapper } from 'shared/styled/ProfileStyle';

function ProviderProfile({
  providerId,
  provider,
  isLoggedIn,
  isProvider,
  user,
  isSettingPage
}) {
  const { providerName = '', providerProfileImgPath, bookmarkedIdList = [] } =
    provider || {};
  const isMyPage = user && providerId === user.id;

  const {
    data: oneProducts,
    isLoading: isLoadingOneProducts,
    isError: isErrorOneProducts,
    error: errorOneProducts
  } = useInfiniteOneProducts({
    searchType: 'providerId',
    searchKey: providerId
  });

  const oneProductCount =
    oneProducts && oneProducts[0] && oneProducts[0].TotalCount
      ? oneProducts[0].TotalCount
      : 0;

  return (
    <ProfileWrapper>
      <CommonProfilePicture
        profileImgPath={
          providerProfileImgPath && providerProfileImgPath.path
            ? providerProfileImgPath
            : {}
        }
        isMyProfile={isMyPage}
        name={providerName}
        className="provider"
        size={78}
        onDrawButton={() => {
          return (
            <Link
              to={`/provider/${providerId}/setting/edit/profile`}
              className="onestore-setting provider"
            >
              설정
            </Link>
          );
        }}
      />
      <div className="profile-name">
        <div className="profile-name-value">{providerName}</div>
        <div className="profile-name-label">공급자</div>
      </div>
      <div className="profile-right-menu">
        {isMyPage ? (
          <Link
            to={`/provider/${providerId}/setting/edit/profile`}
            className="profile-edit-btn provider"
          >
            정보 수정
          </Link>
        ) : (
          !isProvider && (
            <FollowButton
              user={user}
              isLoggedIn={isLoggedIn}
              bookmarkedIdList={bookmarkedIdList}
              targetType="PP"
              targetId={providerId}
              targetName={providerName}
              targetDetailInfo={{ providerImgPath: providerProfileImgPath }}
            />
          )
        )}
        {!isSettingPage && oneProductCount && (
          <Link
            to={{
              pathname: `/provider/${providerId}/oneproduct`,
              state: { providerName }
            }}
            className="profile-count"
          >
            <span>{oneProductCount}</span> 프롬이
          </Link>
        )}
      </div>
    </ProfileWrapper>
  );
}

export default ProviderProfile;
