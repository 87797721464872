import React from 'react';
import { Link } from 'react-router-dom';

function ConditionLink({ children, to }) {
  if (to) {
    return <Link to={to}>{children}</Link>;
  } else {
    return children;
  }
}

export default ConditionLink;
