import styled, { css } from 'styled-components';

export const ButtonWrapper = styled.div`
  grid-area: item-action;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  max-width: var(--max-content-width, 425px);
  width: 100%;
  min-height: 60px;
  margin: 10px auto 0px auto;

  background-color: white;
  border: none;

  &.grey-button-wrapper {
    /* max-width: 520px; */
    min-height: 80px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-color: #e3e3e3;
    margin: 0px auto;
    padding: 10px;
  }

  &.footer-button-wrapper {
    position: fixed;
    left: calc(-50vw + 50%);
    right: calc(-50vw + 50%);
    bottom: calc(var(--footer-height, 58px) - 1px);
    min-height: 60px;
    z-index: 5;
    transition: bottom 0.4s ease-in-out;

    &.iphone-x-buttons {
      bottom: calc(
        var(--footer-height, 58px) + var(--iphone-x-offset-height, 33px) - 1px
      );
    }

    &.hidden-buttons {
      bottom: -1px;
      transition: bottom 0.3s ease-in-out;
    }
  }

  &.payment-button-wrapper {
    /* max-width: 520px; */
    flex-wrap: wrap;
    padding: 10px;
  }

  h3 {
    /* flex: 1; */
    font-size: 0.875rem;
    margin: 0.75rem;
    font-weight: bold;
    text-align: center;
  }

  /* button {
    font-size: 0.875rem;
    font-weight: bold;
    border-radius: 0px;
    min-width: 150px;
    height: auto;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;

    span {
      font-size: 0.75rem;
      font-weight: lighter;
      padding-left: 5px;
    }

    .button-image-icon {
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      width: 30px;
      height: 23px;
      margin-right: 0px;
      margin-left: 10px;
    }


    &.short-button {
      height: 40px;
    }

    &.muliti-line-button {
      font-size: 1.125rem;
      flex-direction: column;
      min-height: 97px;
      line-height: 1.5;
      padding: 5px 20px;

      span {
        font-size: 0.875rem;
        font-weight: lighter;
      }
    }

    &.footer-button {
      font-size: 0.875rem;
    }

    &.narrow-button {
      min-width: 80px;
      max-width: 150px;
    }

    &.wide-button {
      min-width: 280px;
    }

    &.from-button {
      color: white;
      background-color: var(--from-color);
      border-color: var(--from-color);
    }

    &.white-button {
      color: var(--text-color, black);
      background-color: var(--white-color, white);
      border-color: var(--text-color, black);
    }

    &.black-button {
      color: var(--white-color, white);
      background-color: var(--black-color, black);
      border-color: var(--black-color, black);
    }

    &.text-button {
      display: flex;
      justify-content: space-between;
      flex: 0 1 auto;
      font-size: 0.75rem;
      font-weight: bold;
      min-width: 108px;
      height: 27px;
      padding: 0px;
      margin: 10px 0px 10px 10px;
      color: var(--text-color, black);
      background-color: white;
      border: none;
      border-radius: 7px;

      button {
        min-width: 16px;
        max-width: 16px;
        height: 16px;
        margin: 5px;
        margin-left: auto;
        color: white;
        background-color: black;
        border-radius: 50%;
      }

      span {
        font-size: 0.75rem;
        font-weight: bold;
        padding: 5px 10px;
      }
      &.transparent-button {
        background-color: transparent;

        span {
          font-size: 0.9rem;
          font-weight: 800;

          padding: 5px 10px;
        }
      }
      &.border-button {
        border: 1px solid var(--from-color, black);
      }
    }
  } */
`;

export const StyledButton = styled.button`
  font-size: 0.875rem;
  font-weight: bold;
  border-radius: 0px;
  min-width: 150px;
  height: auto;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;

  span {
    font-size: 0.75rem;
    font-weight: lighter;
    padding-left: 5px;
  }

  .button-image-icon {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 30px;
    height: 23px;
    margin-right: 0px;
    margin-left: 10px;
  }

  &.muliti-line-button {
    font-size: 1.125rem;
    flex-direction: column;
    min-height: 97px;
    line-height: 1.5;
    padding: 5px 20px;

    span {
      font-size: 0.875rem;
      font-weight: lighter;
    }
  }

  &.footer-button {
    font-size: 0.875rem;
  }

  &.narrow-button {
    min-width: 80px;
    max-width: 150px;
  }

  &.wide-button {
    min-width: 280px;
  }

  &.short-button {
    height: 40px;
  }

  &.tall-button {
      height: 70px;
      font-size: 1rem;
    }

  &.from-button {
    color: white;
    background-color: var(--from-color);
    border-color: var(--from-color);
  }

  &.white-button {
    color: var(--text-color, black);
    background-color: var(--white-color, white);
    border-color: var(--text-color, black);
  }

  &.black-button {
    color: var(--white-color, white);
    background-color: var(--black-color, black);
    border-color: var(--black-color, black);
  }



  &.text-button {
    display: flex;
    justify-content: space-between;
    flex: 0 1 auto;
    font-size: 0.75rem;
    font-weight: bold;
    min-width: 108px;
    height: 27px;
    padding: 0px;
    margin: 10px 0px 10px 10px;
    color: var(--text-color, black);
    background-color: white;
    border: none;
    border-radius: 7px;

    button {
      min-width: 16px;
      max-width: 16px;
      height: 16px;
      margin: 5px;
      margin-left: auto;
      color: white;
      background-color: black;
      border-radius: 50%;
    }

    span {
      font-size: 0.75rem;
      font-weight: bold;
      padding: 5px 10px;
    }
    &.transparent-button {
      background-color: transparent;

      span {
        font-size: 0.9rem;
        font-weight: 800;

        padding: 5px 10px;
      }
    }
    &.border-button {
      border: 1px solid var(--from-color, black);
    }
  }

  &.payment-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

      min-width: 108px;
      height: 80px;
      border: 0.5px solid #a09f9f;
      border-radius: 5px;
      /* text-align: center;
      background-color: var(--white-color, white); */
      padding: 12px 15px;
      margin: 5px;

      img {
        height: 28px;
        width: auto;

        &.kakaopay {
          
          height: 19px;

        }  
      }

      span {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: bold;

    @include max-width-small-phone {
      font-size:13px;
    }
  }

    }
`;
