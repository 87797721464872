import { s3Upload } from 'lib/aws/s3Upload';

const uploadImage = async (fileOrUrl, albumName) => {
  try {
    // console.log({ fileOrUrl });
    // 이미 업로드 된 이미지의 경우
    if (typeof fileOrUrl === 'string' || (fileOrUrl.path && !fileOrUrl.size))
      return fileOrUrl;

    const { width, height } = fileOrUrl;

    const path = await s3Upload(fileOrUrl, albumName);
    return {
      path,
      width,
      height
    };
  } catch (error) {
    console.log({ error });
    return null;
  }
};

export const uploadImageFiles = async (files, albumName) => {
  try {
    const imageUrls = Promise.all(
      files.map((file) => uploadImage(file, albumName))
    );
    return imageUrls;
  } catch (error) {
    console.log({ error });
  }
};
