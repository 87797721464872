import storage from '../storage';

export const KEY_STORAGE_IDENTITYID 		= 'user_identityId';
export const KEY_STORAGE_USER_TOKEN 		= 'user_token';
export const KEY_STORAGE_USER_INFO 			= 'user_userInfo';

/**
 * 사용자 세션 정보 저장 
 * @param {*} siginResultData  
 */
export const saveSession = ({ identityId, token, userInfo }) => {
	storage.set(KEY_STORAGE_IDENTITYID, identityId);
	storage.set(KEY_STORAGE_USER_TOKEN, token);
	storage.set(KEY_STORAGE_USER_INFO, userInfo);
}

export const clearSession = () => {
	storage.remove(KEY_STORAGE_IDENTITYID);
	storage.remove(KEY_STORAGE_USER_TOKEN);
	storage.remove(KEY_STORAGE_USER_INFO);
}

/**
 * 저장되어 있는 세션 정보 반환 
 */
export const loadUserSession = () => {
	const identityId = storage.get(KEY_STORAGE_IDENTITYID);
    const token = storage.get(KEY_STORAGE_USER_TOKEN);
	const userInfo = storage.get(KEY_STORAGE_USER_INFO);
	
	if (!userInfo || !identityId || !token) {
		return null;
	}

	return {
		identityId,
		token,
		userInfo
	};
}
