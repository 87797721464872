import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';

import Button from 'components/commons/Button';
import ToggleButton from 'components/commons/ToggleButton';
import RenderField from 'components/reduxForm/RenderField';

import { required } from 'lib/form/validations';

import './ProviderSignInForm.scss';

function ProviderSignInForm({
  handleSubmit,
  isLoading,
  isErrorSignIn,
  isCheckedStoreId,
  onSubmit,
  onToggleCheckbox,
  querys
}) {
  return (
    <div className="signin provider-signin">
      <img
        className="provider-signin-logo"
        src="/images/provider-logo.svg"
        alt="프롬 공급자 로고"
      />
      <h2 className="provider-signin-text">공급자 로그인</h2>
      <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="id"
          type="text"
          className="provider-field signin-form-control"
          component={RenderField}
          placeholder="아이디"
          validate={[required]}
        />
        <Field
          name="pwd"
          type="password"
          className="provider-field signin-form-control"
          component={RenderField}
          placeholder="비밀번호"
          validate={[required]}
        />
        {isErrorSignIn && (
          <div className="invalid-feedback d-block w-100 mt-0 mb-3">
            아이디와 패스워드를 다시 확인해주세요.
          </div>
        )}
        <ToggleButton
          id="storeId"
          // isLoading={isKakaoButtonLoading}
          checked={isCheckedStoreId}
          onClick={onToggleCheckbox}
          color="provider"
          className="mt-3"
        >
          아이디 저장
        </ToggleButton>
        <div className="signin-form-buttons">
          <Button
            buttonText="로그인"
            buttonType="submit"
            className="signin-form-button signin-button"
            color="orange"
            isDisable={isLoading}
            isLoading={isLoading}
          />
          <Button
            color="no-color"
            buttonText="공급자 회원가입"
            buttonType="link"
            className="signin-form-button"
            to="/provider/signup"
            search={querys}
          />
        </div>
      </form>
      <div className="signin-form-help">
        <Link to="/provider/signin/resetPassword">비밀번호 찾기</Link>
      </div>
    </div>
  );
}

ProviderSignInForm.propTypes = {
  isLoading: PropTypes.bool,
  isErrorSignIn: PropTypes.bool,
  isCheckedStoreId: PropTypes.bool,
  onSubmit: PropTypes.func,
  onToggleCheckbox: PropTypes.func,
  querys: PropTypes.string
};

ProviderSignInForm.defaultProps = {
  isLoading: false,
  isErrorSignIn: false,
  isCheckedStoreId: false,
  onSubmit: () => {},
  onToggleCheckbox: () => {},
  querys: ''
};

// eslint-disable-next-line
ProviderSignInForm = reduxForm({
  form: 'providerSignInForm'
})(ProviderSignInForm);

export default ProviderSignInForm;
