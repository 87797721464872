import React, { Component } from 'react';
import withUi from 'lib/hoc/withUi';

import './Policy.scss';

class ServicePrivacy extends Component {
  constructor() {
    super();
  }
  componentDidMount() {
    this.props.UiAction.setHeader('개인정보처리방침');
  }
  render() {
    return (
      <section className="container policy-container pt-3 pb-3">
        {/* eslint-disable */}
        <h2 className="mb-3">[개인정보처리방침]</h2>
        <p>
          커넥서스컴퍼니(이하 “회사”)는 『정보통신망 이용촉진 및 정보보호에 관한
          법률(이하 “정보통신망법”)』 제27조의2 및 『개인정보보호법』 제30조에
          따라 고객의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게
          처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
          수립·공개합니다.
        </p>
        <h3 className="mt-3 mb-3">제 1 장 (개인정보의 수집 및 이용목적)</h3>
        <p>
          회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
          개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
          변경되는 경우에는 법률에 따라 별도의 동의를 받는 등 필요한 조치를
          이행할 예정입니다.
        </p>
        <table className="mt-4 mb-4">
          <colgroup>
            <col width="30%" />
            <col width="70%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="row">구분</th>
              <th scope="row">목적</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>홈페이지 회원 가입 및 관리</td>
              <td>
                회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별 인증,
                회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지 통지,
                고충처리 등
              </td>
            </tr>
            <tr>
              <td>재화 또는 서비스 제공</td>
              <td>
                물품배송, 서비스 제공, 계약서 청구서 발송, 콘텐츠 제공,
                맞춤서비스 제공, 본인인증, 연령인증, 요금결제 정산 등
              </td>
            </tr>
            <tr>
              <td>마케팅 및 광고</td>
              <td>
                웹 페이지 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계
                이벤트 등 광고성 정보 전달
              </td>
            </tr>
          </tbody>
        </table>
        <h3 className="mt-3 mb-3">제 2 장 (개인정보 수집 항목 및 방법)</h3>
        <p>
          회사는 다음의 개인정보 항목을 수집하여 처리하고 있습니다.
          <br /> 회원 가입 및 관리
          <table className="mt-4 mb-4">
            <colgroup>
              <col width="20%" />
              <col width="40%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="row">구분</th>
                <th scope="row">개인정보 항목</th>
                <th scope="row" />
                <th scope="row">수집방법</th>
              </tr>
              <tr>
                <th scope="row" />
                <th scope="row">필수</th>
                <th scope="row">선택</th>
                <th scope="row" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>일반 회원가입</td>
                <td>이름, 비밀번호, 휴대폰 번호, 이메일</td>
                <td>주소, 프로필사진</td>
                <td>홈페이지</td>
              </tr>
              <tr>
                <td>공급자 회원가입</td>
                <td>
                  아이디, 비밀번호, 공급자명, 대표자명, 공급자 이메일, 휴대폰
                  번호, 우편번호, 주소, 정산통장사본 이미지, 정산계좌 은행
                  이미지, 정산계좌 은행명, 통장계좌번호
                </td>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
          재화 또는 서비스 제공
          <table className="mt-4 mb-4">
            <colgroup>
              <col width="20%" />
              <col width="40%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="row">구분</th>
                <th scope="row">개인정보 항목</th>
                <th scope="row" />
                <th scope="row">수집방법</th>
              </tr>
              <tr>
                <th scope="row" />
                <th scope="row">필수</th>
                <th scope="row">선택</th>
                <th scope="row" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>회원 주문</td>
                <td>
                  주문자 이름, 주문자 휴대폰 번호, 주문자 이메일, 주문자
                  결제정보, 수취인 이름, 수취인 휴대폰 번호, 수취인 주소
                </td>
                <td />
                <td>홈페이지</td>
              </tr>
            </tbody>
          </table>
          ※ 회사의 서비스 이용 과정에서 서비스 이용기록, 방문기록, 불량
          이용기록, IP주소, 쿠키, MAC주소, 모바일 기기정보(앱 버전, OS 버전),
          ADID/IDFA(광고식별자) 등의 정보가 자동으로 생성되어 수집될 수
          있습니다.
        </p>
        <h3 className="mt-3 mb-3">제 3 장 (개인정보의 보유 및 이용기간)</h3>
        <p>
          ① 회사는 법령에 따른 개인정보 보유·이용 기간 또는 이용자로부터
          개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를
          처리·보유합니다.
          <br /> ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
          <br /> 1) 홈페이지 회원 가입 및 관리 : 회원 탈퇴 시 부정이용 방지를
          위하여 30일 동안 보관 후 파기하며, 재화 또는 서비스 제공에 해당하는
          경우는 관련 법령에 의거하여 보유 기간 동안 보관 후 파기합니다.
          <br /> 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지
          <br /> - 관계 법령 위반에 따른 수사 조사 등이 진행중인 경우에는 해당
          수사 조사 종료시까지
          <br /> - 서비스 이용에 따른 채권 채무관계 잔존시에는 해당 채권
          채무관계 정산시까지
          <br /> 2) 개인정보 유효기간제(휴면계정 정책) : 1년 이상 서비스
          이용기록이 없는 이용자의 개인정보는 정보통신망법 제29조에 따라 일반
          이용자의 개인정보와 분리(휴면계정으로 전환)하여 저장 및 관리됩니다.
          회사는 휴면계정으로 전환되기 30일 이전, 해당 내용에 대해 이메일 등을
          통해 이용자에게 사전 통지를 하며 분리 저장된 개인정보는 관련 법령에
          특별한 규정이 있는 경우를 제외하고 해당 개인정보를 이용ㆍ제공하지
          않습니다.
          <br /> 3) 재화 또는 서비스 제공 : 재화·서비스 공급완료 및
          요금결제·정산 완료시까지
          <br /> 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지
          <table className="mt-4 mb-4">
            <colgroup>
              <col width="40%" />
              <col width="40%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="row">대상</th>
                <th scope="row">관련 법 조항</th>
                <th scope="row">보유기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>계약 또는 청약철회 등에 관한 기록</td>
                <td rowSpan="3">전자상거래 등에서 소비자 보호에 관한 법률</td>
                <td>5년</td>
              </tr>
              <tr>
                <td>대금결제 및 재화 등의 공급에 관한 기록</td>

                <td>5년</td>
              </tr>
              <tr>
                <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>

                <td>3년</td>
              </tr>
              <tr>
                <td>본인 확인에 대한 기록</td>
                <td>정보통신망법</td>
                <td>6개월</td>
              </tr>
              <tr>
                <td>인터넷 로그기록자료, 접속지 추적자료</td>
                <td>통신비밀보호법</td>
                <td>3개월</td>
              </tr>
            </tbody>
          </table>
        </p>
        <h3 className="mt-3 mb-3">제 4 장 (개인정보의 파기절차 및 방법)</h3>
        <p>
          ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
          불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
          <br /> ② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나
          처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
          보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로
          옮기거나 보관장소를 달리하여 보존합니다. 별도 보존되는 개인정보는
          법률에 의한 경우가 아니고서는 보존목적 이외의 다른 목적으로 이용되지
          않습니다.
          <br /> ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
          <br /> 1) 파기절차
          <br /> - 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의
          개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
          <br /> 2) 파기방법
          <br /> - 회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을
          재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여
          파기하며, 종이 문서에 기록 저장된 개인정보는 분쇄기로 분쇄하거나
          소각하여 파기합니다.
        </p>
        <h3 className="mt-3 mb-3">제 5 장 (개인정보의 제3자 제공)</h3>
        <p>
          회사는 이용자의 개인정보를 수집 시 명시한 범위 내에서만 처리하며,
          이용자의 동의가 있거나 다른 법률에 특별한 규정이 있는 경우에만
          개인정보를 제3자에게 제공합니다.
        </p>
        <h3 className="mt-3 mb-3">제 6 장 (개인정보처리의 위탁)</h3>
        <p>
          ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보
          처리업무를 위탁하고 있습니다.
          <table className="mt-4 mb-4">
            <colgroup>
              <col width="30%" />
              <col width="70%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="row">위탁 받는 자</th>
                <th scope="row">위탁하는 업무의 내용</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>엔에이치엔엔터테인먼트㈜</td>
                <td>
                  스마트메시지(카카오 알림톡 및 친구톡, SMS/LMS/MMS 발송) 전송
                  서비스
                </td>
              </tr>
              <tr>
                <td>㈜시옷</td>
                <td rowSpan="4">결제 서비스</td>
              </tr>
              <tr>
                <td>㈜케이지이니시스</td>
              </tr>
              <tr>
                <td>엔에이치엔엔터테인먼트㈜</td>
              </tr>
              <tr>
                <td>주식회사 카카오페이</td>
              </tr>
              <tr>
                <td>㈜스윗트래커</td>
                <td>택배 배송 서비스</td>
              </tr>
            </tbody>
          </table>
          ② 회사는 위탁계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지,
          기술적 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리 감독, 손해배상
          등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를
          안전하게 처리하는지를 감독하고 있습니다.
          <br /> ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본
          개인정보 처리방침을 통하여 공개하도록 하겠습니다.
        </p>
        <h3 className="mt-3 mb-3">
          제 7 장 (이용자 및 법정대리인의 권리와 그 행사방법)
        </h3>
        <p>
          ① 이용자는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를
          행사할 수 있습니다.
          <br /> 1) 개인정보 열람요구
          <br /> 2) 오류 등이 있을 경우 정정 요구
          <br /> 3) 삭제요구
          <br /> 4) 처리정지 요구
          <br /> ② 이용자는 홈페이지의 "개인정보수정”, 회원탈퇴" 메뉴 및 회사에
          대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 권리 행사를 하실
          수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.
          <br /> ③이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
          경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를
          이용하거나 제공하지 않습니다.
          <br /> ④ 이용자에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은
          자 등 대리인을 통하여 하실 수 있습니다. 이 경우 위임장을 제출하셔야
          합니다.
          <br /> ⑤ 이용자는 개인정보 보호 유관법령을 위반하여 회사가 처리하고
          있는 이용자 본인이나 타인의 개인정보 및 사생활을 침해하여서는
          아니됩니다.
        </p>
        <h3 className="mt-3 mb-3">
          제 8 장 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)
        </h3>
        <p>
          ① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를
          저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. <br />② 쿠키는
          웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
          브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
          하드디스크에 저장되기도 합니다. <br />
          1) 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한
          방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게
          최적화된 정보 제공을 위해 사용됩니다. <br />
          2) 쿠키의 설치 운영 및 거부 : 웹브라우저 상단의 도구>인터넷
          옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.{' '}
          <br />
          3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
          있습니다.
        </p>
        <h3 className="mt-3 mb-3">
          제 9 장 (모바일 앱 사용 시 광고 식별자(ADID/IDFA) 수집)
        </h3>
        <p>
          회사는 이용자의 ADID/IDFA를 수집할 수 있습니다. ADID/IDFA란 모바일 앱
          이용자의 광고 식별 값으로서, 사용자의 맞춤 서비스 제공이나 더 나은
          환경의 광고를 제공하기 위한 측정을 위해 수집될 수 있습니다.
          <br />
          [거부방법]
          <br />- Android : 설정->구글(구글설정)->광고->광고 맞춤설정 선택 해제
          <br />- iOS : 설정->개인 정보 보호->광고->광고 추적 제한
        </p>
        <h3 className="mt-3 mb-3">제 10 장 (온라인 맞춤형 광고 서비스)</h3>
        <p>
          회사는 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집하도록
          허용하고 있습니다.
          <br />
          1) 행태정보를 수집 및 처리하는 광고 사업자: 구글, 페이스북, Appsflyer,
          Dable, Naver, 카카오
          <br />
          2) 행태정보 수집 방법: 이용자가 당사 웹사이트 방문하거나 앱을 실행할
          때 자동 수집 및 전송
        </p>
        <h3 className="mt-3 mb-3">
          제 11 장 (개인정보의 기술적·관리적 보호조치)
        </h3>
        <p>
          회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
          있습니다.
          <br />
          1) 관리적 조치: 내부관리계획 수립 시행, 정기적 직원 교육 등<br />
          2) 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
          설치, 고유식별정보 등의 암호화, 보안프로그램 설치 등<br />
          3) 물리적 조치: 전산실, 자료보관실 등의 접근통제 등
        </p>
        <h3 className="mt-3 mb-3">제 12 장 (개인정보 보호책임자)</h3>
        <p>
          ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
          처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
          개인정보 보호책임자를 지정하고 있습니다.
          <table className="mt-4 mb-4">
            <colgroup>
              <col width="50%" />
              <col width="50%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="row">개인정보 보호책임자</th>
                <th scope="row">개인정보 보호담당자</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  성명: 안계준
                  <br /> 직책: 이사
                  <br /> 연락처: 02-6951-1234
                  <br /> 이메일: help@from.kr
                </td>
                <td>
                  성명: 안준범
                  <br /> 소속: 개발팀
                  <br /> 전화번호: 02-6951-1234
                  <br /> 이메일: help@from.kr
                </td>
              </tr>
            </tbody>
          </table>
          ② 이용자께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호
          관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및
          담당자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이
          답변 및 처리해드릴 것입니다.
        </p>
        <h3 className="mt-3 mb-3">
          제 13 장 (이용자의 권익침해에 대한 구제방법)
        </h3>
        <p>
          ① 이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을
          문의하실 수 있습니다.
          <br /> ※ 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인
          개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한
          도움이 필요하시면 문의하여 주시기 바랍니다.
          <br /> ② 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
          기관에 문의하시기 바랍니다.
          <table className="mt-4 mb-4">
            <colgroup>
              <col width="35%" />
              <col width="25%" />
              <col width="40%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="row">문의처</th>
                <th scope="row">연락처</th>
                <th scope="row">홈페이지</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>개인정보침해신고센터</td>
                <td>(국번없이) 118</td>
                <td>http://privacy.kisa.or.kr</td>
              </tr>
              <tr>
                <td>개인정보분쟁조정위원회</td>
                <td>1833-6972 </td>
                <td>http://www.kopico.go.kr</td>
              </tr>
              <tr>
                <td>대검찰청 사이버수사과</td>
                <td>(국번없이) 1301</td>
                <td>http://www.spo.go.kr</td>
              </tr>
              <tr>
                <td>경찰청 사이버안전국</td>
                <td>(국번없이) 182</td>
                <td>http://cyber.go.kr</td>
              </tr>
            </tbody>
          </table>
        </p>

        <h3 className="mt-3 mb-3">
          제 14 장 (개인정보처리방침의 개정과 그 공지)
        </h3>
        <p>
          본 개인정보 처리방침을 개정할 경우에는 최소 7일전에 홈페이지 또는
          이메일을 통해 변경 및 내용 등을 공지하도록 하겠습니다. 다만 이용자의
          소중한 권리 또는 의무에 중요한 내용 변경이 발생하는 경우 시행일로부터
          최소 30일 전에 공지하도록 하겠습니다.
        </p>
        <br />
        <p>
          - 공고일자 : 2019년 5월 24일
          <br /> - 시행일자 : 2019년 06월 01일
        </p>
      </section>
    );
  }
}

export default withUi(ServicePrivacy);
