/* eslint-disable no-console */
import React, { useEffect, Fragment } from 'react';
// import { connect } from 'react-redux';
import { compose /*, bindActionCreators */ } from 'redux';
import { Route, useRouteMatch, useHistory } from 'react-router-dom';
import withUser from 'lib/hoc/withUser';
import withUi from 'lib/hoc/withUi';
import { isInternetExplorer } from 'lib/utils/envUtils';

import NavBar from 'components/ui/NavBar';

import DiscoverModal from 'components/modal/DiscoverModal';
import AlertModal from 'components/modal/AlertModal';

import useDiscoverModal from 'hooks/useDiscoverModal';
import useModal from 'hooks/useModal';

import MarketBestContainer from 'containers/home/MarketBestContainer';
import MarketCategoryContainer from 'containers/home/MarketCategoryContainer';
import MarketPostContainer from 'containers/home/MarketPostContainer';
import MarketDiscoverContainer from 'containers/home/MarketDiscoverContainer';

import './style.scss';

// const MarketBestContainer = lazy(() =>
//   import('containers/home/MarketBestContainer')
// );
// const MarketCategoryContainer = lazy(() =>
//   import('containers/home/MarketCategoryContainer')
// );
// const MarketPostContainer = lazy(() =>
//   import('containers/home/MarketPostContainer')
// );
// const MarketDiscoverContainer = lazy(() =>
//   import('containers/home/MarketDiscoverContainer')
// );

function MarketContainer({ isLoggedIn, isProvider, UiAction }) {
  const history = useHistory();
  const { path } = useRouteMatch();

  const {
    message: errorMessage,
    setMessage: setErrorMessage,
    isOpen: isOpenErrorModal,
    handleToggle: toggleErrorModal
  } = useModal();

  const {
    isOpenDiscoverModal,
    toggleDiscoverModal,
    handleGotoDiscoverPage
  } = useDiscoverModal({
    isLoggedIn,
    isProvider,
    history
  });

  useEffect(() => {
    UiAction.setHeader(
      <img
        src={`/images/header-from-logo.svg`}
        style={{
          height: '20px'
        }}
        alt="프롬로고"
      />
    );
    if (isInternetExplorer()) {
      setErrorMessage(`현재 브라우저는 IE(인터넷익스플로어)입니다.
      해당 사이트는 크롬에 최적화 되어있습니다.
      원활한 사용을 위해 크롬 브라우저를 이용해주세요`);
      toggleErrorModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const marketRoutes = [
    {
      name: '카테고리',
      path: `${path}/category`,
      component: MarketCategoryContainer
    },
    {
      name: '프롬마켓',
      path: `${path}/best`,
      component: MarketBestContainer
    },
    {
      name: '프롬이야기',
      path: `${path}/posts`,
      component: MarketPostContainer
    },
    {
      name: '발굴중',
      path: `${path}/discover`,
      component: MarketDiscoverContainer
    }
  ];

  // console.log({ path });
  return (
    <Fragment>
      <NavBar className="nav-bar" navLinks={marketRoutes} />
      {marketRoutes.map((route) => (
        <Route exact key={route.path} path={route.path}>
          <route.component />
        </Route>
      ))}
      <DiscoverModal
        isOpen={isOpenDiscoverModal}
        onRedirect={handleGotoDiscoverPage}
        onToggle={toggleDiscoverModal}
      />
      <AlertModal
        type="errorModal"
        isOpen={isOpenErrorModal}
        contentText={errorMessage}
        onToggle={toggleErrorModal}
      />
    </Fragment>
  );
}

export default compose(withUi, withUser)(MarketContainer);
