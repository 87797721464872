/**
 *카카오톡채널(플러스)친구
 */
export default () => {
  const setting = {
    plusFriendId: '_KJckC'
  };

  const userAgent = navigator.userAgent.toLowerCase();

  // ios-App
  if (userAgent.indexOf('1store_iphone') > -1) {
    try {
      // eslint-disable-next-line
      webkit.messageHandlers.chatKakaoPlus.postMessage(setting.plusFriendId);
    } catch (e) {
      return {
        agent: '1store_iphone',
        error: '앱스토어에서 새 버전을 받아주세요.'
      };
    }

    return;
  }

  // Android-App
  if (userAgent.indexOf('1store_android') > -1) {
    try {
      // eslint-disable-next-line
      __1STORE_ANDROID.chatKakaoPlus(setting.plusFriendId);
    } catch (e) {
      return {
        agent: '1store_android',
        error: '구글플레이에서 새 버전을 받아주세요.'
      };
    }

    return;
  }

  window.Kakao.PlusFriend.chat(setting);
};
