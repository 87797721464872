/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types'

import sweetTracker from 'api/sweetTracker';

import TrackingDeliveryView from './TrackingDeliveryView';

function TrackingDelivery({ status, deliverer }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [senderName, setSenderName] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [receiverAddr, setReceiverAddr] = useState('');
  const [trackingDetails, setTrackingDetails] = useState([]);

  useEffect(() => {
    if (
      !deliverer ||
      deliverer.id === '000' ||
      deliverer.id === '999' ||
      deliverer.id === '995' ||
      deliverer.id === '990'
    )
      return;
    getTrackingDetails(deliverer);
  }, [deliverer]);

  const getTrackingDetails = async (deliverer) => {
    if (!deliverer) {
      return;
    }
    setIsLoading(true);
    const response = await sweetTracker(deliverer.id, deliverer.trackingNumber);

    if (!response || !response.data || response.data.status === false) {
      let errorMessage = '';

      if (response && response.data) {
        errorMessage = response.data.msg;
      }

      setErrorMessage(
        errorMessage || '오류가 발생하여 배송지 정보를 불러오지 못하였습니다.'
      );
      setIsError(true);

      return;
    }
    // console.log({ response });
    const { data } = response;

    const {
      invoiceNo,
      senderName,
      receiverName,
      receiverAddr,
      trackingDetails
    } = data;

    setInvoiceNo(invoiceNo);
    setSenderName(senderName);
    setReceiverName(receiverName);
    setReceiverAddr(receiverAddr);
    setTrackingDetails(trackingDetails.reverse());
    setIsLoading(false);
  };

  return (
    <TrackingDeliveryView
      status={status}
      deliverer={deliverer}
      isLoading={isLoading}
      isError={isError}
      errorMessage={errorMessage}
      invoiceNo={invoiceNo}
      senderName={senderName}
      receiverName={receiverName}
      receiverAddr={receiverAddr}
      trackingDetails={trackingDetails}
    />
  );
}

export default TrackingDelivery;
