import makeStore from 'shared/store';
import {
  isSameItem,
  isSameReceiver,
  lineItemize,
  incrementQuantity,
  summarizeItems,
  replaceItems,
  summarizeOrders
} from './CheckoutStoreUtils';

const initialState = {
  currentOrder: null,
  orders: [],
  orderTotal: {},
  buyer: {}
};

const reducer = (state, action) => {
  // console.log('CheckoutStore reducer');
  // console.log({ state });
  // console.log({ action });
  switch (action.type) {
    case 'reset': {
      return {
        ...initialState
      };
    }

    case 'initialize': {
      const checkedItems = action.payload.items.filter(
        (item) => item.isChecked
      );
      return summarizeOrders({
        ...initialState,
        buyer: state.buyer,
        orders: [summarizeItems({ ...action.payload, items: checkedItems })],
        currentOrder: 0,
        cart: { items: checkedItems }
      });
    }
    case 'updateBuyer': {
      return { ...state, buyer: action.payload };
    }

    case 'addOrder': {
      if (!state.orders[state.currentOrder].receiver)
        return { ...state, errorMessage: '받는 사람을 입력해 주세요' };
      return summarizeOrders({
        ...state,
        orders: [
          ...state.orders,
          summarizeItems({ ...state.cart, orderType: action.payload })
        ],
        currentOrder: state.orders.length
      });
    }
    case 'removeOrder': {
      // if (state.orders.length < 2)
      //   return { ...state, errorMessage: '삭제할 수 없습니다' };
      const orderIndexToRemove =
        action.payload !== undefined ? action.payload : state.currentOrder;
      return summarizeOrders({
        ...state,
        orders: state.orders.filter(
          (order, index) => index !== orderIndexToRemove
        ),
        currentOrder: orderIndexToRemove > 0 ? orderIndexToRemove - 1 : 0
      });
    }
    case 'nextOrder': {
      const isOutOfRange =
        state.currentOrder + action.payload >= state.orders.length ||
        state.currentOrder + action.payload < 0;
      return isOutOfRange
        ? state
        : {
            ...state,
            currentOrder: state.currentOrder + action.payload
          };
    }
    case 'goToOrder': {
      const isOutOfRange =
        action.payload >= state.orders.length || action.payload < 0;
      return isOutOfRange
        ? state
        : {
            ...state,
            currentOrder: action.payload
          };
    }

    case 'addReceiver': {
      if (!action.payload) return state;
      const isInReceivers = state.orders.some(
        (order, index) =>
          isSameReceiver(action.payload, order) && state.currentOrder !== index
      );
      // if (isInReceivers)
      //   return { ...state, errorMessage: '이미 받은 사람입니다' };

      const orders = [...state.orders];
      orders[state.currentOrder].receiver = { ...action.payload };
      return { ...state, orders, errorMessage: '' };
    }
    case 'updateReceiver': {
      const orders = [...state.orders];
      orders[state.currentOrder].receiver = { ...action.payload };
      return { ...state, orders, errorMessage: '' };
    }

    case 'add': {
      const items = state.orders[state.currentOrder].items;
      const isInCart = items.some((item) => isSameItem(action.payload, item));
      if (isInCart) {
        return { ...state, errorMessage: '이미 카트에 담겨있습니다!' };
      }
      const item = lineItemize(action.payload);
      const sortedItems = [...items, item].sort(
        (a, b) =>
          a.provider.id.localeCompare(b.provider.id) ||
          a.product.id.localeCompare(b.product.id) ||
          a.option.seqNo - b.option.seqNo
      );

      return summarizeOrders(replaceItems(state, [...sortedItems]));
    }
    case 'remove': {
      const items = state.orders[state.currentOrder].items.filter(
        (item) => !isSameItem(action.payload, item)
      );
      return summarizeOrders(replaceItems(state, items));
    }
    case 'increment': {
      const items = incrementQuantity(
        [...state.orders[state.currentOrder].items],
        action.payload,
        action.payload.incrementBy
      );
      if (!items)
        return { ...state, errorMessage: '카트에 담기지 않은 상품입니다.' };

      return summarizeOrders(replaceItems(state, items));
    }
    case 'empty': {
      return summarizeOrders(replaceItems(state, []));
    }
    case 'restore': {
      return summarizeOrders(
        replaceItems(state, [
          ...state.cart.items.filter((item) => item.isChecked)
        ])
      );
    }
    // case 'field': {
    //   return {
    //     ...state,
    //     [action.fieldName]: action.payload
    //   };
    // }
    default:
      return state;
  }
};

const [CheckoutProvider, useCheckoutStore, useCheckoutDispatch] = makeStore(
  reducer,
  initialState,
  'checkout'
);

export { CheckoutProvider, useCheckoutStore, useCheckoutDispatch };
