import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'components/ui/Tabs';

import './MemberEditHeader.scss';

function MemberEditHeader({
  // onClick,
  currentTab,
  isHavePassword,
  storeId
}) {
  return (
    // <div className="pwdedit-header-tab ml-auto mr-auto mb-3">
    //   <ul className=" d-flex align-items-center justify-content-center">
    //     <li>
    //       <button
    //         type="button"
    //         onClick={() => {
    //           onClick('profile');
    //         }}
    //         className={`${
    //           currentTab === 'profile' ? 'selected-border' : 'unselect-border'
    //         } `}
    //       >
    //         정보 수정
    //       </button>
    //     </li>
    //     <li>
    //       <button
    //         type="button"
    //         onClick={() => {
    //           onClick('pwd');
    //         }}
    //         className={`${
    //           currentTab === 'pwd' ? 'selected-border' : 'unselect-border'
    //         } `}
    //       >
    //         {isHavePassword ? '비밀번호 변경' : '비밀번호 설정'}
    //       </button>
    //     </li>
    //     <li>
    //       <button
    //         type="button"
    //         onClick={() => {
    //           onClick('sns');
    //         }}
    //         className={`${
    //           currentTab === 'sns' ? 'selected-border' : 'unselect-border'
    //         } `}
    //       >
    //         SNS 연결
    //       </button>
    //     </li>
    //   </ul>
    // </div>

    <Tabs className="pwdedit-header-tab">
      <Tab isSelected={currentTab === 'profile'}>
        <Link to={`/store/${storeId}/setting/edit/profile`}>정보 수정</Link>
        <div className="tab-bottom-dot-icon" />
      </Tab>
      <Tab isSelected={currentTab === 'pwd'}>
        <Link to={`/store/${storeId}/setting/edit/pwd`}>
          {isHavePassword ? '비밀번호 변경' : '비밀번호 설정'}
        </Link>
        <div className="tab-bottom-dot-icon" />
      </Tab>
      <Tab isSelected={currentTab === 'sns'}>
        <Link to={`/store/${storeId}/setting/edit/sns`}>SNS 연결</Link>
        <div className="tab-bottom-dot-icon" />
      </Tab>
      <Tab isSelected={currentTab === 'goodbye'}>
        <Link to={`/store/${storeId}/setting/edit/goodbye`}>탈퇴</Link>
        <div className="tab-bottom-dot-icon" />
      </Tab>

    </Tabs>
  );
}

export default MemberEditHeader;
