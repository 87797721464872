/* eslint-disable no-console */
import React from 'react';
import { usePosts } from 'hooks/usePosts';
import InfiniteList from 'shared/InfiniteList';
import { withRouter } from 'react-router-dom';

import PostView from 'components/Post/PostView';
import { MainSectionWrapper } from 'styles/AppStyle';

function MarketPostContainer() {
  const query = usePosts({});

  return (
    <MainSectionWrapper className="market">
      <InfiniteList isOneColumn {...query}>
        {(item) => (
          <PostView key={`${item.expId}${item.commentsId}`} post={item} />
        )}
      </InfiniteList>
    </MainSectionWrapper>
  );
}

export default withRouter(MarketPostContainer);
