import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { getProfileImage } from 'lib/utils/imageUtils';

import './FooterProfile.scss';

function FooterProfile({
  className,
  footerName,
  isActive,
  isLoggedIn,
  // isProvider,
  profileImgPath,
  redirectUrl,
  to
}) {
  const defaultExtension = '.svg';
  const activeExtension = `-active${defaultExtension}`;

  return (
    <li
      className={classNames('footer-menu-item', className, {
        active: isActive
      })}
    >
      <Link to={isLoggedIn ? to : redirectUrl}>
        {isLoggedIn ? (
          <Fragment>
            <div
              className="footer-menu-profile footer-menu-item-icon"
              style={{
                backgroundImage: `url(${getProfileImage(profileImgPath)})`
              }}
            ></div>
          </Fragment>
        ) : (
          <img
            className="footer-menu-item-icon"
            // src={`/images/footer/footer-login-icon-2${
            //   isActive ? activeExtension : defaultExtension
            // }`}
            src="/images/footer/footer-login-icon-2.svg"
            alt={footerName}
          />
        )}
        <div className="footer-menu-item-name">{footerName}</div>
      </Link>
    </li>
  );
}

FooterProfile.propTypes = {
  className: PropTypes.string,
  footerName: PropTypes.string,
  isActive: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  // isProvider: PropTypes.bool,
  profileImgPath: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  redirectUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),

  to: PropTypes.string
};

FooterProfile.defaultProps = {
  className: '',
  footerName: '',
  isActive: false,
  isLoggedIn: false,
  // isProvider: false,
  profileImgPath: '/images/default-profile.svg',
  redirectUrl: {} || '',
  to: ''
};

export default FooterProfile;
