import React from 'react';
import { getImagePath } from 'lib/utils/imageUtils';
import OneStoreListItemTemplate from 'components/oneStore/OneStoreListTemplate/OneStoreListItemTemplate';

function OneStoreDiscoverSuccessItem({ discovers, storeId }) {
  const { id, officialProductImgPath, productName } = discovers;
  return (
    <OneStoreListItemTemplate
      linkPath={`/store/${storeId}/${id}`}
      name={productName}
    >
      <div className="onestore-list-template-item-image success-list-image">
        <img
          src={
            getImagePath(officialProductImgPath) || '/images/default-image.svg'
          }
          alt="발굴사진"
          className="onestore-list-template-item-main-image"
        />
        <img
          className="onestore-list-template-item-sub-image no-image-border"
          src="/images/discover-success-circle-icon.svg"
          alt="발굴왕관"
        />
      </div>
    </OneStoreListItemTemplate>
  );
}

export default OneStoreDiscoverSuccessItem;
