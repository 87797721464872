import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import sentry from 'lib/sentry';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import useModal, { useSimpleDialog } from 'hooks/useModal';
import AlertModal from 'components/modal/AlertModal';
import ConfirmModal from 'components/modal/ConfirmModal';
import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import Loader from 'components/commons/Loader';
import { isDevelop, isIPhoneApp } from 'lib/utils/envUtils';

import { useOneStore } from 'hooks/useOneStores';
import { oneStoreToBuyer } from 'components/checkout/CheckoutStoreUtils';

import {
  useCheckoutStore,
  useCheckoutDispatch
} from 'components/checkout/CheckoutStore';

import { requestPayment } from './Payment.service';
import PaymentForm from './PaymentForm';
import CheckoutHeader from 'components/checkout/CheckoutHeader';

import {
  CheckoutWrapper,
  CheckoutMainWrapper,
  StyledCheckoutHeader
} from 'components/checkout/CheckoutStyle';

const isDev = isDevelop();
const isIPhone = isIPhoneApp();

const cleanseOrders = (orders) => {
  const cleansedOrders = orders.map((order) => {
    const cleansedItems = order.items.map((item) => {
      const { product } = item;
      const {
        options,
        incentiveStruct,
        discovery,
        discovererEmail,
        discovererMobile,
        discovererName,
        discovererProfileImgPath,
        provider,
        providerEmail,
        providerMobile,
        providerProfileImgPath,
        htmlProductInfo,
        ...rest
      } = product;
      return { ...item, product: rest };
    });
    return { ...order, items: cleansedItems };
  });
  // console.log({ cleansedOrders });
  return cleansedOrders;
};

function Payment({
  user,
  isLoggedIn,
  isSignedKakao,
  UserActions,
  UiAction,
  showHeader
}) {
  const location = useLocation();
  const history = useHistory();
  const checkout = useCheckoutStore();
  const checkoutDispatch = useCheckoutDispatch();

  const { data: oneStore, isLoading, isError, error } = useOneStore({
    storeId: user.id
    // isMyStore: true
  });

  const {
    isOpenDialog,
    handleToggleDialog,
    handleConfirm,
    handleDecline,
    isConfirmed
  } = useSimpleDialog();

  const { isOpen, handleToggle, message, setMessage } = useModal();

  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const orderIsEmpty = !(checkout?.orders?.length > 0);

  const onRequestPayment = async (values, pgMethod, payMethod = 'card') => {
    try {
      // console.log({ values });
      // console.log({ checkout });

      const {
        acceptTermsOfService,
        acceptPrivacyPolicy,
        acceptAll,
        giftPointUsed = 0,
        ...newBuyer
      } = values;
      const { buyer, orders, totalsBySeller, orderTotal } = checkout;
      const totalPayment = orderTotal?.totalAmount - giftPointUsed;
      const paymentTerms = {
        acceptTermsOfService,
        acceptPrivacyPolicy,
        giftPointUsed,
        totalPayment,
        pgMethod,
        payMethod
      };

      // 계좌이체면 sentry로
      if (payMethod === 'trans') {
        sentry.captureException(
          `계좌이체 결제 - 결제 성공여부 확인필요 - ${JSON.stringify(values)}`
        );
      }

      const { snsType = 'kakao', snsId } = location?.state?.userInfo || {};
      // // 카카오 아이디 가져오기
      // if (socialInfo && socialInfo.userInfo) {
      //   values.my1PInfo.snsType = 'kakao';
      //   values.my1PInfo.snsId = socialInfo.userInfo.snsId.toString();
      // }
      setIsPaymentLoading(true);
      const response = await requestPayment({
        isLoggedIn,
        buyer: isLoggedIn
          ? buyer
          : isSignedKakao
          ? { ...newBuyer, snsType, snsId: snsId?.toString() }
          : newBuyer,
        orders: cleanseOrders(orders),
        totalsBySeller,
        orderTotal,
        paymentTerms
      });

      // if (typeof window !== 'undefined' && !isDev) {
      //   window.fbq('track', 'Purchase', {
      //     value: orderTotal?.totalAmount,
      //     currency: 'KRW',
      //     // content_ids: productId,
      //     content_type: 'product'
      //   });
      // }

      if (isIPhone) {
        // eslint-disable-next-line
        webkit.messageHandlers.hideNavigationBar.postMessage('');
      }

      // console.log({ response });

      const containsFortune =
        orders?.length > 0 &&
        orders[0].orderType === 'buy' &&
        orders[0].items.some((item) => item?.product?.isFortune === true);

      setIsPaymentLoading(false);
      history.replace({
        pathname: '/order/payment/completed',
        search: `?point_success=true&buyer_id=${
          response?.buyerId
        }&merchant_uid=${response?.uid}${
          containsFortune ? '&product_type=fortune' : ''
        }`
      });
    } catch (err) {
      console.log({ err });
      // 결제 취소 됐을 때 아임포트 쪽에서 따로 내려오는 에러 코드는 없고 메시지가 리턴되기 때문에 이걸로 결제 취소인지 판단.
      const isCancelPayment = err.error_msg?.includes(
        '사용자가 결제를 취소하셨습니다'
      );
      const errorMessage = isCancelPayment
        ? '결제를 취소 하였습니다.'
        : '결제 도중 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.';
      if (isCancelPayment && isIPhone) {
        // eslint-disable-next-line
        webkit.messageHandlers.hideNavigationBar.postMessage('');
      }
      sentry.captureException(err.error_msg || err.message);
      setIsPaymentLoading(false);
      handleToggle(errorMessage);
    }
  };

  const updateBuyer = (oneStore) => {
    const buyer = oneStoreToBuyer(oneStore);
    // console.log({ oneStore });
    checkoutDispatch({
      type: 'updateBuyer',
      payload: buyer
    });
  };

  useEffect(() => {
    UiAction.setHeader('결제하기');
    // console.log('Payment');
    // console.log({ location });
    if (location?.state?.userInfo?.snsId) {
      UserActions.changeKakaoButton(true);
    }
  }, []);

  useEffect(() => {
    // console.log({ oneStore });
    updateBuyer(isLoggedIn ? oneStore : {});
  }, [oneStore]);

  // useEffect(() => {
  //   // console.log({ isConfirmed });
  //   // console.log({ isOpenDialog });
  //   const search =
  //     isConfirmed === true
  //       ? '?imp_success=true&orderType=pay&imp_uid=imp_uid_testuid&merchant_uid=merchant_uid_test&storeId=storeId'
  //       : '?imp_success=false&orderType=pay&imp_uid=imp_uid_testuid&merchant_uid=merchant_uid_test&storeId=storeId&error_msg=failed';
  //   console.log('redirecting');

  //   if (isConfirmed !== undefined)
  //     history.replace({
  //       pathname: '/order/payment/completed',
  //       search
  //     });
  // }, [isOpenDialog]);

  useEffect(() => {
    if (orderIsEmpty) {
      history.replace('/order/cart');
    }
  }, [orderIsEmpty]);

  if (isError) return <p>{error.message};</p>;

  return (
    <CheckoutWrapper>
      <ScrollToTopOnMount />
      {/* <CheckoutHeader step="payment" /> */}
      <CheckoutMainWrapper>
        {(isLoading || isPaymentLoading) && <Loader />}
        <PaymentForm
          giftPointBalance={oneStore?.balanceSavedMoney}
          onRequestPayment={onRequestPayment}
        />
        <ConfirmModal
          headerText="결제 성공 여부"
          headerImage="/images/header-image/sori-header-image.svg"
          contentText={`성공 또는 실패를 선택해 주세요.`}
          isOpen={isOpenDialog}
          okButtonText="성공"
          onClickOk={handleConfirm}
          onToggle={handleDecline}
        />
        <AlertModal
          contentText={message}
          isOpen={isOpen}
          onToggle={handleToggle}
        />
      </CheckoutMainWrapper>
      {/* <div>
        <pre>{JSON.stringify(checkout?.orderTotal, null, 2)}</pre>
      </div> */}
    </CheckoutWrapper>
  );
}

export default withUi(withUser(Payment));
