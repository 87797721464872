import React from 'react';
import classNames from 'classnames';

// import PropTypes from 'prop-types';
import Button from 'components/commons/Button';

import { getImagePath } from 'lib/utils/imageUtils';

import { getFormatDate } from 'lib/time';
import { getTelFormat } from 'lib/utils/stringUtils';

import './ShippingDetail2.scss';

function ShippingDetail2({
  createDate,
  deliveryName,
  deliveryNumber,
  index,
  isShipping,
  // isPersonShipping,
  onOpenInputModal,
  paymentLogsId,
  productAmount,
  productName,
  productOption,
  receiverAddr,
  receiverAddrSub,
  receiverZipcode,
  receiverName,
  receiverMobile,
  // receiverReserveDate,
  receiverDeliveryMessage,
  flagCancelPayment,
  flagDelivery,
  officialProductImgPath,
  buyerName,
  buyerMobile,
  payType
}) {
  return (
    <div
      className={classNames('shipping-item-wrapper', {
        'is-cancel-shipping-item': flagCancelPayment
      })}
    >
      <div className="shipping-item-top">
        <div className="shipping-item-date-uid">
          <div className="shipping-item-date">
            {`${payType === 'buy' ? '구매' : '선물'} ${getFormatDate(
              createDate,
              'YYYY.MM.DD HH:mm'
            )}`}
          </div>
          <div className="shipping-item-uid">주문번호</div>
          <span className="shipping-item-uid">{paymentLogsId}</span>
        </div>
        <div className="shipping-item-button-wrapper">
          {!flagCancelPayment ? (
            <Button
              className={`${isShipping ? 'shipping-item-modify' : ''}`}
              buttonText={`${isShipping ? '운송장 수정' : '운송장 입력하기'}`}
              buttonType="button"
              color="none"
              onClick={() => {
                onOpenInputModal(index);
              }}
            />
          ) : (
            <Button
              className="shipping-item-cancel-order"
              buttonText="취소완료"
              buttonType="button"
              color="none"
            />
          )}
          {isShipping && (
            <div className="shipping-item-delivery-text">{`${deliveryName} ${deliveryNumber}`}</div>
          )}
        </div>
      </div>
      <div className="shipping-item-content">
        <div className="shipping-item-image">
          <img
            src={
              getImagePath(officialProductImgPath) ||
              '/images/default-image.svg'
            }
            alt="발굴사진"
            className="shipping-item-main-image"
          />
          <img
            className="shipping-item-sub-image"
            src={`${
              payType === 'buy'
                ? '/images/provider-crown-circle-icon.svg'
                : '/images/provider-gift-circle-icon.svg'
            }`}
            alt="발굴왕관"
          />
        </div>
        <div className="shipping-item-info">
          <div className="shipping-item-info-bold-text">
            {`${productName} ${productOption ? `- ${productOption}` : ''} ${
              productAmount ? `X ${productAmount}` : ''
            }`}
          </div>
          <div className="shipping-item-info-text">
            <span>구매자 : </span>
            {`${buyerName} ${getTelFormat(buyerMobile)}`}
          </div>
          {payType === 'gift' && (
            <div className="shipping-item-info-text">
              <span>받는이 : </span>
              {`${receiverName} ${getTelFormat(receiverMobile)}`}
            </div>
          )}
          {flagDelivery && (
            <div className="shipping-item-info-text">
              <span>받는주소 : </span>
              {`${receiverAddr} ${receiverAddrSub ? receiverAddrSub : ''} ${`${
                receiverZipcode ? `(${receiverZipcode})` : ''
              }`}`}
            </div>
          )}
          {receiverDeliveryMessage && (
            <div className="shipping-item-info-text">
              <span>배송메시지 : </span>
              {receiverDeliveryMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShippingDetail2;
