import styled from 'styled-components';

export const NavBarWrapper = styled.nav`
  position: fixed;
  top: var(--header-height, 60px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  width: 100%;
  max-width: var(--max-width, 950px);
  height: auto;
  padding-top: 10px;
  margin: 0 auto;
  transition: top 0.4s ease-in-out;
  background-color: rgba(255, 255, 255, 0.9);

  &.hidden-nav-bar {
    top: 0px;
    transition: top 0.3s ease-in-out;

    &.one-store,
    &.provider {
      top: -130px;
    }
  }
`;
export const StyledNavBarTitle = styled.h3`
  width: 100%;
  min-height: 50px;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: var(--from-color, green);
  line-height: 1.3;
  padding: 15px 20px;
  word-break: keep-all;
  margin: 0 auto;
`;
export const StyledNavBar = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;

  width: 90%;
  max-width: 400px;
  height: 45px;
  margin: 0 auto;

  .nav-link {
    text-align: center;
    a {
      text-decoration: none;
      color: var(--text-color, black);
      font-size: 13.5px;
      font-weight: bold;
    }

    .active {
      color: var(--from-color, green);

      .nav-tab {
        display: flex;
        flex-direction: column;
        align-items: center;
        .bottom-dot {
          width: 6px;
          height: 6px;
          margin-top: 5px;
          border-radius: 50%;
          background-color: var(--from-color, green);
        }
      }
    }
  }

  &.with-border {
    .nav-link {
      flex: 1;
      padding-top: 15px;
      border: solid 1px lightgrey;
      border-bottom: none;

      a {
      }

      /* .nav-tab {
        border: solid 1px lightgrey;
        border-bottom: none;
      } */

      .active {
        color: var(--from-color, green);
        /* border: solid 1px black;
      border-bottom: none; */

        .nav-tab {
        }
      }
    }
  }
`;
