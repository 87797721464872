import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProviderEditContainer from 'containers/provider/ProviderEditContainer';

function ProviderEdit() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ProviderEditContainer />
    </Fragment>
  );
}

export default ProviderEdit;
