/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import useFetch from 'hooks/useFetch';
import { getStorePaymentStatus } from 'api/store';

import AlertModal from 'components/modal/AlertModal';
import Loader from 'components/commons/Loader';
import RedirectToHome from 'components/commons/RedirectToHome';

import OrderStatusView from 'components/oneStore/OrderStatusView';

function OrderStatusContainer({
  orderId,
  storeId,
  user,
  isLoggedIn,
  UiAction
}) {
  const { data, isLoading, message, isOpen, handleToggle } = useFetch(
    getStorePaymentStatus,
    {
      orderId,
      storeId
    }
  );

  useEffect(() => {
    UiAction.setHeader('배송 상세');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <RedirectToHome
        isGotoHome={!isLoggedIn}
        redirectPath="/store/$storeId/setting/order"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <OrderStatusView
            storeId={storeId}
            orderId={orderId}
            orderItem={data}
            // onRedirectLink={handleRedirectLink}
          />
          <AlertModal
            type="errorModal"
            contentText={message}
            isOpen={isOpen}
            onClick={handleToggle}
            onToggle={handleToggle}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export default withUi(withUser(OrderStatusContainer));
