import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import CommonModal from 'components/modal/CommonModal';
import { isDevelop } from 'lib/utils/envUtils';

import './ConfirmGiftSuccessModal.scss';
// import { SytledSuccessModal } from './SuccessModalStyle';

const isDev = isDevelop();

function ConfirmGiftSuccessModal({
  isOpen,
  setIsOpen,
  buyerId,
  buyerName,
  receiverId,
  receiverName,
  productId,
  productName
}) {
  // const history = useHistory();
  const isSanta = false;
  // productId === 'p1558004675088' ||
  // productId === 'p1544601478558' ||
  // (isDev && productId === 'p1573192053027');

  const headerText = isSanta
    ? `${receiverName ? `${receiverName}님` : '당신'}도\n 산타가 될 수 있어요`
    : '선물 받기가 완료되었습니다!';

  // console.log({ productId });
  // console.log({ isSanta });
  // console.log({ receiverName });
  // console.log({ headerText });

  return (
    <CommonModal
      size="sm"
      headerText={headerText}
      headerImage="/images/header-image/gift-header-image.svg"
      isOpen={isOpen}
      onToggle={() => {
        setIsOpen(false);
        // history.replace(receiverId ? `/store/${receiverId}/${productId}` : '/');
      }}
      // okButtonText="이야기 남기기"
      // linkPath={`/user/${buyerId}/posts/in`}
      okButtonText={isSanta ? '릴레이산타 선물 자세히보기' : '선물 자세히보기'}
      linkPath={
        receiverId
          ? `/store/${receiverId}/${productId}/detail`
          : '/store/signin'
      }
      replace
      secondButtonText={isSanta ? '산타 MBTI 하러가기' : '내 단골바구니 보기'}
      secondLinkPath={isSanta ? '/santa' : `/store/${receiverId}`}
      secondReplace
    >
      <p className="success-modal-body-text">
        <span>
          <Link className="success-modal-link" to={`/store/${buyerId}`} replace>
            {buyerName}
          </Link>
          님께
          <br /> 감사의 마음을 전해보세요.
          <br />
          <br /> 받은 선물은
          <br /> 내 바구니에서 확인 가능합니다.
        </span>
      </p>
    </CommonModal>
  );
}

export default ConfirmGiftSuccessModal;
