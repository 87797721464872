import React, { Fragment } from 'react';

import withUser from 'lib/hoc/withUser';

import ListTitle from 'components/commons/ListTitle';
import OneProductList from 'components/oneProduct/OneProductList';

function OneStoreOneProductList({ storeId, user, isLoggedIn }) {
  const isMyStore = isLoggedIn && user ? user.id === storeId : false;

  // console.log({ user });

  return (
    <Fragment>
      {/* <ListTitle
        title="응원하는 상품"
        // subTitle={subTitle}
        // listClassName={listClassName}
      /> */}
      <OneProductList
        searchType="storeId"
        searchKey={storeId}
        isOneStore
        isMyStore={isMyStore}
      />
    </Fragment>
  );
}

export default withUser(OneStoreOneProductList);
