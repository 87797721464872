import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import useModal from 'hooks/useModal';
import AddressModal from 'components/modal/AddressModal';

import { Input } from 'shared/components';

function AddressSubForm({ isOptional }) {
  const { register, control, errors, setValue, className } = useFormContext();

  const { isOpen, handleToggle } = useModal();

  const handleSelectAddress = ({ address, zipcode }) => {
    // console.log({ address });
    setValue('addr', address, {
      shouldValidate: true,
      shouldDirty: true
    });
    setValue('zipcode', zipcode, {
      shouldValidate: true,
      shouldDirty: true
    });
  };
  return (
    <Fragment>
      <Input
        className={`${className} ${isOptional ? '' : 'required'}`}
        onClick={handleToggle}
        name="addr"
        label="주소"
        placeholder="주소"
        {...{ register, control, errors }}
      />
      <Input
        className={className}
        name="addrSub"
        label="상세주소"
        placeholder="상세주소"
        {...{ register, control, errors }}
      />
      <Input
        className={className}
        onClick={handleToggle}
        name="zipcode"
        label="우편번호"
        placeholder="우편번호"
        {...{ register, control, errors }}
      />
      <AddressModal
        isOpen={isOpen}
        onToggle={handleToggle}
        onSelect={handleSelectAddress}
      />
    </Fragment>
  );
}

export default AddressSubForm;
