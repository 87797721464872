import axios from './defaultAxios';

export function postComment(values) {
  return axios.post(
    `/store/${values.storeId}/${values.productId}/comments`,
    values
  );
}

export function getPostList(params) {
  return axios.get(`/posts`, {
    params
  });
}

export function deleteComment(params, storeId, productId) {
  return axios.delete(
    `/store/${storeId}/${productId}/comments/${params.commentsId}`,
    { params }
  );
}

export function putComment(values, storeId, productId) {
  return axios.put(
    `/store/${storeId}/${productId}/comments/${values.commentsId}`,
    values
  );
}

export function putLikeComment(values, storeId, productId) {
  return axios.put(
    `/store/${storeId}/${productId}/comments/${values.commentsId}/likeuser`,
    values
  );
}
