/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useCartStore, useCartDispatch } from './CartStore';
import {
  useCheckoutStore,
  useCheckoutDispatch
} from 'components/checkout/CheckoutStore';

import { getPriceStructure } from 'components/checkout/priceUtils';
import { getPriceFormat } from 'lib/utils/numberUtils';
import { getImagePath } from 'lib/utils/imageUtils';

import { StyledItem } from './ItemStyle';

export default function OptionItem({
  itemType,
  index,
  product,
  option,
  provider,
  seller,
  buyerIsSeller,
  isOneProduct
}) {
  const cart = useCartStore();
  const cartDispatch = useCartDispatch();
  const checkout = useCheckoutStore();
  const checkoutDispatch = useCheckoutDispatch();
  const {
    name: optionName,
    imagePath: optionImagePath,
    isInStock = true
  } = option;
  const { officialProductImgPath: productImagePath } = product;
  const { discountPrice, discountPercent, unitPrice, giftPoint } =
    getPriceStructure({
      option,
      shareRate: seller.shareRate,
      isTaxFree: product.flagTaxFree === 1,
      buyerIsSeller,
      isOneProduct
    });

  const itemInCart = (
    itemType === 'checkout' ? checkout.orders[checkout.currentOrder] : cart
  ).items.find(
    (item) => item.option.id === option.id && item.product.id === product.id
  );
  // console.log({ itemType });
  // console.log({ itemInCart });
  const isChecked = itemInCart?.isChecked;
  const isInCart = itemInCart ? true : false;
  const quantity = itemInCart?.quantity || 0;

  const addItem = (item) => {
    // console.log('addItem');
    // console.log({ item });
    if (itemType === 'checkout') {
      checkoutDispatch({
        type: 'add',
        payload: item
      });
    } else {
      cartDispatch({
        type: 'add',
        payload: item
      });
    }
  };
  const removeItem = (productId, optionId) => {
    if (itemType === 'checkout') {
      checkoutDispatch({ type: 'remove', payload: { productId, optionId } });
    } else {
      cartDispatch({ type: 'remove', payload: { productId, optionId } });
    }
  };

  const increment = (productId, optionId, incrementBy = 1) => {
    if (itemType === 'checkout') {
      checkoutDispatch({
        type: 'increment',
        payload: { productId, optionId, incrementBy }
      });
    } else {
      cartDispatch({
        type: 'increment',
        payload: { productId, optionId, incrementBy }
      });
    }
  };

  const decrement = (productId, optionId) => {
    if (itemType === 'checkout') {
      checkoutDispatch({
        type: 'increment',
        payload: { productId, optionId, incrementBy: -1 }
      });
    } else {
      cartDispatch({
        type: 'increment',
        payload: { productId, optionId, incrementBy: -1 }
      });
    }
  };

  const toggle = (productId, optionId) => {
    if (itemType === 'checkout') {
      checkoutDispatch({
        type: 'toggle',
        payload: { productId, optionId }
      });
    } else {
      cartDispatch({
        type: 'toggle',
        payload: { productId, optionId }
      });
    }
  };

  const onClick = () => {
    if (isInCart) {
      if (isChecked) {
        removeItem(product.id, option.id);
      } else {
        toggle(product.id, option.id);
      }
    } else {
      addItem({
        seller,
        product,
        option,
        provider,
        buyerIsSeller,
        isOneProduct
      });
    }
  };

  useEffect(() => {
    if (!isInStock) removeItem(product.id, option.id);
  }, [isInStock]);

  // console.log({buyerIsSeller})
  // console.log({ seller, isOneProduct });

  const isLimitedToOne =
    product?.isFortune === true ||
    product?.isRecipe === true ||
    product?.isCarrige === true;

  return (
    <StyledItem className={`option-item ${!isInStock ? 'disabled' : ''}`}>
      <div className="item-status" onClick={isInStock ? onClick : null}>
        {isInCart &&
          (isChecked ? (
            <img src="/images/icon_option_check.svg" alt="옵션선택표시" />
          ) : (
            <img src="/images/icon-option-unchecked.svg" alt="선택않음" />
          ))}
      </div>
      <div className="item-image">
        <img
          className="main-image"
          src={getImagePath(optionImagePath || productImagePath, 250, 250)}
          alt="상품 이미지"
        />
      </div>

      <div className="item-subtitle">{`${index + 1}. ${optionName}`}</div>
      <div className="item-price">
        {!isNaN(discountPrice) && (
          <div>
            <span className="discount-price">
              {getPriceFormat(discountPrice)}원
            </span>
            {giftPoint > 0 && (
              <span className="gift-point">
                {` + ${getPriceFormat(giftPoint)}원 적립`}
              </span>
            )}
          </div>
        )}
        {discountPercent > 0 && (
          <div className="regular-price">{getPriceFormat(unitPrice)}원</div>
        )}
      </div>
      <div className="number-picker">
        <button
          type="button"
          className="minus-button"
          onClick={() =>
            quantity > 1
              ? decrement(product.id, option.id)
              : removeItem(product.id, option.id)
          }
        />
        <span>{quantity}</span>
        <button
          type="button"
          className="plus-button"
          disabled={!isInStock}
          onClick={() =>
            isInCart
              ? increment(
                  product.id,
                  option.id,
                  isLimitedToOne && quantity > 0 ? 0 : 1
                )
              : addItem({
                  seller,
                  product,
                  option,
                  provider,
                  buyerIsSeller,
                  isOneProduct
                })
          }
        />
      </div>
    </StyledItem>
  );
}
