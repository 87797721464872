import axios from './defaultAxios';

// 받은 선물 정보 내용 가져오기
export function getReceiveGift(expId, storeId, productId) {
  return axios.get(`/store/${storeId}/${productId}/1experiences/${expId}`, {
    params: {
      expId
    }
  });
}

// 받은 선물 배송지 정보 입력 후 처리
export function postReceiveGift(values, expId, storeId, productId) {
  return axios.post(
    `/store/${storeId}/${productId}/1experiences/${expId}/receive`,
    values
  );
}

// 받은 선물 정보 내용 가져오기
// 선물받기페이지 주소변경에 따른 api 주소 변경
export function getReceiveGiftV2(expId) {
  return axios.get(`/gift/${expId}`, {
    params: {
      expId
    }
  });
}

export function getGift(deliveryId) {
  return axios.get(`/gift`, {
    params: {
      id: deliveryId
    }
  });
}

export function postGift(values) {
  return axios.post(`/gift`, { body: values });
}

export function getReceiveGiftList(params) {
  return axios.get(`/gift/inbox`, {
    params
  });
}

export function getSendGiftList(params) {
  return axios.get(`/gift/outbox`, {
    params
  });
}
