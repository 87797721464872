import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function FooterDiscoverButton({
  className,
  footerName,
  footerImgSrc,
  isActive,
  onOpenDiscoverModal
}) {
  const defaultExtention = '.svg';
  const activedExtention = `-active${defaultExtention}`;

  return (
    <li className={classNames('footer-menu-item', className)}>
      <button
        className={isActive ? 'active' : ''}
        onClick={onOpenDiscoverModal}
      >
        <img
          className="footer-menu-item-icon"
          src={`/images/footer/${footerImgSrc}${
            isActive ? activedExtention : defaultExtention
          }`}
          alt={footerName}
        />
        <div className="footer-menu-item-name">{footerName}</div>
      </button>
    </li>
  );
}

FooterDiscoverButton.propTypes = {
  className: PropTypes.string,
  footerImgSrc: PropTypes.string,
  footerName: PropTypes.string,
  isActive: PropTypes.bool
};

FooterDiscoverButton.defaultProps = {
  className: '',
  footerImgSrc: '',
  footerName: '',
  isActive: false
};
