import React from 'react';

import InfiniteScroll from 'react-infinite-scroller';
import EmptyList from 'components/commons/EmptyList';
// import MarketCategoryView from 'components/home/MarketCategoryView';
import SearchLoader from './SearchLoader';
import SearchItem from './SearchItem';
import './SearchList.scss';

function SearchList({
  // categoryList,
  // recommendationList,
  // brandList,
  onGet,
  // onClickTag,
  // isInitial,
  hasLastKey,
  searchResultList,
  isLoading,
  selectOptionIndex
}) {
  const haveSearchResultList = searchResultList && searchResultList.length > 0;
  // if (isInitial) {
  //   return (
  //     <MarketCategoryView
  //       categoryList={categoryList}
  //       recommendationList={recommendationList}
  //       brandList={brandList}
  //       onClickTag={onClickTag}
  //     />
  //   );
  // }
  if (isLoading) {
    return <SearchLoader />;
    // return null;
  }
  if (!isLoading && !haveSearchResultList) {
    return (
      <EmptyList
        contentText="검색 결과가 없습니다."
        subText="검색어를 정확히 입력했는지 확인해주세요."
        className="from-search-list-background"
      />
    );
  }

  return (
    <div className="from-search-list">
      <InfiniteScroll
        pageStart={0}
        loadMore={onGet}
        hasMore={hasLastKey}
        className="from-search-list-inner"
      >
        <div className={selectOptionIndex === 0 ? 'card-list-container' : ''}>
          {searchResultList.map((searchResults, index) => {
            return (
              <SearchItem
                searchResults={searchResults}
                // eslint-disable-next-line
                key={`${index}${searchResults.createDate}`}
                selectOptionIndex={selectOptionIndex}
              />
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default SearchList;
