import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import KakaoSignUpContainer from 'containers/users/KakaoSignUpContainer';

function Home() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <KakaoSignUpContainer />
    </Fragment>
  );
}

export default Home;
