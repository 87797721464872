import moment from 'moment';
import 'moment/locale/ko';

export const getMoment = () => {
  moment.locale('ko');

  return moment;
};

/**
 * 원하는 date format으로 date를 바꿔서 리턴해주는 함수
 * @param {date || string} date 바꿀 날짜
 * @param {string} formatString  바꾸고 싶은 포맷 형태 ex) 'yyyy-mm-dd'
 */
export function getFormatDate(date, formatString = '') {
  const m = getMoment();

  if (!date) {
    return '';
  }

  return m(date).format(formatString);
}

export function getMilisecond(date) {
  const m = getMoment();

  if (!date) {
    return '';
  }

  return m(date).valueOf();
}

/**
 * 원하는 date format으로 timestemp를 바꿔서 리턴해주는 함수
 * @param {number || string} timestemp 바꿀 날짜 timestemp
 * @param {string} formatString  바꾸고 싶은 포맷 형태 ex) 'yyyy-mm-dd'
 */
export function getFormatTimeStempDate(timeStemp, formatString = '') {
  const m = getMoment();

  if (!timeStemp) {
    return '';
  }

  return m.unix(timeStemp / 1000).format(formatString);
}

// 현재 시간으로부터 얼만큼 멀어졌는지 보여준다
export const fromNow = (date, isShownBeforeText = false) => {
  const m = getMoment();

  if (!date) {
    return '';
  }

  let dateString = '';

  if (typeof date === 'number') {
    dateString = getFormatTimeStempDate(date, '');
  } else {
    dateString = getFormatDate(date, '');
  }

  return m(dateString).fromNow(isShownBeforeText);
};

// 특정일로부터 며칠 지났는지 확인
export const diffDate = (date, dateType = 'days') => {
  const m = getMoment();

  if (!date) {
    return '';
  }

  const today = new Date();
  let dateString = '';

  if (typeof date === 'number') {
    dateString = getFormatTimeStempDate(date, '');
  } else {
    dateString = getFormatDate(date, '');
  }

  const todayMoment = m(today, 'YYYYMMDD');
  const dayMoment = m(dateString, 'YYYYMMDD');

  return todayMoment.diff(dayMoment, dateType);
};
