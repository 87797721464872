import React, { Fragment } from 'react';
// import { withRouter } from 'react-router-dom';

import ProductList from 'components/product/ProductList';

function ProviderProductList({ providerId }) {
  return (
    <Fragment>
      <ProductList
        // title={`판매중인 상품`}
        listClassName="provider-product-list"
        searchType="providerId"
        searchKey={providerId}
      />
    </Fragment>
  );
}
export default ProviderProductList;
