import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: -1px;
  height: var(--footer-height, 58px);
  background-color: #fdfdfd;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.05);
  z-index: 10;
  transition: bottom 0.3s ease-in-out;
  font-weight: bold;

  &.footer-class {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: -1px;
    height: var(--footer-height, 58px);
    background-color: #fdfdfd;
    box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.05);
    z-index: 10;
    transition: bottom 0.3s ease-in-out;
    font-weight: bold;
  }

  &.footer-x {
    height: calc(
      var(--footer-height, 58px) + var(--iphone-x-offset-height, 33px)
    );
    padding-bottom: var(--iphone-x-offset-height, 33px);
  }

  &.hidden-footer {
    bottom: calc(- 1px - var(--footer-height, 58px));
    transition: bottom 0.4s ease-in-out;

    &.footer-x {
      bottom: calc(
        - 1px - var(--footer-height, 58px) - var(--iphone-x-offset-height, 33px)
      );
    }
  }

  /* .shadow-footer {
    background-image: url(/images/sulnal/sulnal-snowing-footer.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: -1px;
    height: var(--footer-height, 50px);
    z-index: 3;
    transition: bottom 0.2s ease-in-out;
    font-weight: bold;

    &.footer-x {
        height: var(--footer-height, 50px) + 16px;
        padding-bottom: 16px;
    }
    } */
`;

export const FooterItemsWrapper = styled.nav`
  width: 100%;
  height: 100%;
  max-width: 420px;
  margin: 0 auto;

  ul {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
`;
