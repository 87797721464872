import React, { Component, Fragment } from 'react';
import { Link, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import storage from 'lib/storage';
import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';
import sentry from 'lib/sentry';

// ui 컴포넌트
import AlertModal from 'components/modal/AlertModal';
import RedirectToHome from 'components/commons/RedirectToHome';
import { Tabs, Tab } from 'components/ui/Tabs';

// 컴포넌트
import ProviderWaitingProductContainer from 'containers/provider/ProviderWaitingProductContainer';
import ProviderProductSuccessContainer from 'containers/provider/ProviderProductSuccessContainer';

import * as providerActions from 'modules/provider';

export class ProviderProductContainer extends Component {
  constructor(props) {
    super(props);

    const userInfo = storage.get('user_userInfo');

    this.state = {
      errorMessage: '',
      isOpenErrorModal: false,
      tabIndex: 0
    };

    this.lastKey = null;
    this.userId = userInfo ? userInfo.id : '';
  }

  componentDidMount = () => {
    this.initialize();
  };

  componentDidUpdate = (prevProps) => {
    const { location, provider } = this.props;
    const { location: prevLocation, provider: prevProvider } = prevProps;

    if (provider !== prevProvider) {
      this.setHeader();
    }

    if (!location) {
      return;
    }

    if (location.pathname !== prevLocation.pathname) {
      this.handleChangeTab();
    }
  };

  componentWillUnmount = () => {
    const { ProviderAction } = this.props;

    ProviderAction.initializeProvider();
  };

  getProvider = async () => {
    const { ProviderAction, match } = this.props;
    const { providerId } = match.params;

    try {
      const response = await ProviderAction.getProvider(providerId);

      if (!response || !response.data || !response.data.success) {
        throw new Error(
          `공급자 상품관리 페이지 - pp 데이터 불러오기 실패: ${JSON.stringify(
            response
          )}}`
        );
      }
    } catch (e) {
      sentry.captureException(e.message);
    }
  };

  setHeader = () => {
    const { UiAction, provider } = this.props;

    const headerText =
      provider && provider.providerName
        ? `${provider.providerName} 상품관리`
        : '상품관리';

    UiAction.setHeader(headerText);
  };

  handleChangeTab = () => {
    const { location } = this.props;
    let tabIndex = 1;

    if (!location || !location.pathname) {
      return;
    }

    const { pathname } = location;

    if (pathname.indexOf('success') > -1) {
      tabIndex = 0;
    }

    this.setState({
      tabIndex
    });
  };

  initialize = () => {
    this.getProvider();
    this.setHeader();
    this.handleChangeTab();
  };

  render() {
    const { history, providerId } = this.props;
    const { errorMessage, isOpenErrorModal, tabIndex } = this.state;

    return (
      <Fragment>
        <RedirectToHome
          isGotoHome={providerId !== this.userId}
          redirectPath={`/provider/${providerId}`}
        />
        <Tabs className="provider-product-tab-2">
          <Tab isSelected={tabIndex === 0}>
            <Link to={`/provider/${providerId}/setting/product/success`}>
              상품등록성공
            </Link>
            <div className="tab-bottom-dot-icon" />
          </Tab>
          <Tab isSelected={tabIndex === 1}>
            <Link to={`/provider/${providerId}/setting/product/waiting`}>
              상품등록대기
            </Link>
            <div className="tab-bottom-dot-icon" />
          </Tab>
        </Tabs>
        <Route
          exact
          path="/provider/:providerId/setting/product/waiting"
          render={() => (
            <ProviderWaitingProductContainer
              history={history}
              providerId={providerId}
            />
          )}
        />
        <Route
          exact
          path="/provider/:providerId/setting/product/success"
          render={() => (
            <ProviderProductSuccessContainer
              history={history}
              providerId={providerId}
            />
          )}
        />
        <AlertModal
          contentText={errorMessage}
          isOpen={isOpenErrorModal}
          onClick={this.handleCloseModal}
          onToggle={this.handleCloseModal}
          isProviderModal
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ provider }) => ({
  provider: provider.provider
});

const mapDispatchToProps = (dispatch) => ({
  ProviderAction: bindActionCreators(providerActions, dispatch)
});

export default compose(
  withRouter,
  withUi,
  withUser,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProviderProductContainer);
