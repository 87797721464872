import React from 'react';
import { getImagePath } from 'lib/utils/imageUtils';
import OneStoreListItemTemplate from 'components/oneStore/OneStoreListTemplate/OneStoreListItemTemplate';
import './ToList.scss';

function OneStoreToListItem({ to }) {
  const { storeId, productImgPath, profileImgPath, storeName } = to;

  return (
    <OneStoreListItemTemplate linkPath={`/store/${storeId}`} name={storeName}>
      <div className="onestore-list-template-item-image">
        <img
          src={getImagePath(productImgPath) || '/images/default-image.svg'}
          alt="상품"
          className="onestore-list-template-item-main-image"
        />
        <img
          className="onestore-list-template-item-sub-image"
          src={getImagePath(profileImgPath) || '/images/default-profile.svg'}
          alt="프로필"
        />
      </div>
    </OneStoreListItemTemplate>
  );
}

export default OneStoreToListItem;
