import { createAction, handleActions } from 'redux-actions';

const SET_HEADER = 'ui/header/SET_HEADER';
const SET_SHOW_HEADER = 'ui/header/SET_SHOW_HEADER';

export const setHeader = createAction(SET_HEADER);
export const setShowHeader = createAction(SET_SHOW_HEADER);

const initialize = {
  headerTitle: '프롬',
  showHeader: true
};

export default handleActions(
  {
    [SET_HEADER]: (state, action) => {
      return {
        ...state,
        headerTitle: action.payload
      };
    },
    [SET_SHOW_HEADER]: (state, action) => {
      return {
        ...state,
        showHeader: action.payload
      };
    }
  },
  initialize
);
