import { isDevelop } from 'lib/utils/envUtils';
import { requestIamportPay } from 'lib/utils/iamportUtils';
import { isMobileApp } from 'lib/mobileApp';
import { postPayment } from 'api/payment';

const isDev = isDevelop();

// iamport 호출에 필요한 param을 리턴.
function getIamportParams({
  buyer,
  items,
  orderType,
  orderTotal,
  paymentTerms
}) {
  const { name, email, mobile, addr, addrSub, zipcode } = buyer;
  const { totalPayment, pgMethod, payMethod } = paymentTerms;

  const { totalAmount, flagDelivery = 1, taxFreeAmount = 0 } = orderTotal;
  const productNames = `${isDev ? '개발 - ' : ''}${
    items[0].product?.productName
  } ${orderTotal.quantity > 1 ? '등 ' : ''}${orderTotal.quantity}개`;

  const containsFortune =
    orderType === 'buy' && items.some((item) => item.product?.isFortune);

  let redirectUrl = '';
  // ssr 방어
  if (typeof window !== 'undefined') {
    const { origin, pathname } = window.location;

    redirectUrl = `${origin}${pathname}/completed?totalAmount=${totalAmount}&buyerName=${name}${
      containsFortune ? '&product_type=fortune' : ''
    }`;
  }

  const paymentRequest = {
    amount: totalPayment,
    buyer_email: email,
    buyer_name: name,
    buyer_tel: mobile,
    name: productNames,
    m_redirect_url: redirectUrl,
    pg: pgMethod,
    pay_method: payMethod,
    tax_free: Math.min(taxFreeAmount, totalPayment),
    // 에스크로 -- 배송상품 && '신용카드' 결제가 아닐시
    escrow: flagDelivery > 0 && payMethod !== 'card'
  };

  if (isMobileApp()) {
    paymentRequest.app_scheme = 'onestore';
  }

  if (flagDelivery === 1) {
    paymentRequest.buyer_addr = `${addr} ${addrSub || ''}`;
    paymentRequest.buyer_postcode = zipcode;
  }

  if (pgMethod === 'kakaopay') {
    paymentRequest.kakaoOpenApp = true;
  }

  return paymentRequest;
}

export const requestPayment = async ({
  isLoggedIn,
  buyer,
  orders,
  orderTotal,
  totalsBySeller,
  paymentTerms
}) => {
  try {
    const paymentRequest = getIamportParams({
      buyer,
      items: orders[0].items,
      orderType: orders[0].orderType,
      orderTotal,
      paymentTerms
    });

    const paymentParams = {
      isLoggedIn,
      buyer,
      orders,
      orderTotal,
      totalsBySeller,
      paymentTerms,
      paymentRequest,
      paymentStatus: 'before'
    };
    // console.log({ paymentParams });
    // alert('before');
    const beforeResponse = await postPayment(paymentParams);

    if (!beforeResponse?.data?.success) {
      throw new Error(
        `결제 실패: before 호출 실패 - ${JSON.stringify(beforeResponse)}`
      );
    }

    paymentParams.uid = beforeResponse.data.data.uid;

    if (paymentTerms.totalPayment <= 0) {
      paymentParams.paymentStatus = 'after';
      paymentParams.paymentResponse = {
        success: true
      };
    } else {
      // iamport 호출
      const iamportResponse = await requestIamportPay({
        ...paymentRequest,
        merchant_uid: paymentParams.uid
      });

      paymentParams.paymentStatus = 'after';
      paymentParams.paymentResponse = iamportResponse;
    }

    // console.log('Are we here after IMP?');

    const afterResponse = await postPayment(paymentParams);

    if (!afterResponse?.data?.success) {
      throw new Error(
        `결제 실패: after 호출 실패 - ${JSON.stringify(afterResponse)}`
      );
    }

    const { data } = afterResponse.data;

    return data;
  } catch (error) {
    throw error;
  }
};
