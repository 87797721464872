import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { useStore } from 'hooks/useOneStores';

import Loader from 'components/loader/Loader';
import OneStoreCountInfo from 'components/oneStore/OneStoreCountInfo';
import OneStoreProfile from 'components/oneStore/OneStoreProfile';
import OneStoreSettingMenu from 'components/oneStore/OneStoreSettingMenu';
import OneStoreOneProductList from 'components/oneStore/OneStoreOneProductList';

import ToggleList from 'components/commons/ToggleList';

import sentry from 'lib/sentry';
import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import { getStoreActivityCount } from 'api/store';
import './style.scss';
import { MainSectionWrapper } from 'styles/AppStyle';

function useStoreStats(storeId) {
  const [data, setData] = useState({});

  useEffect(() => {
    async function getStoreStats() {
      try {
        const response = await getStoreActivityCount(storeId);

        if (!response || !response.data || !response.data.success) {
          throw new Error(
            `get Store count api Error(OneStore) storeId: ${storeId}`
          );
        }

        setData(response.data.data);
      } catch (e) {
        throw e;
      }
    }
    getStoreStats();
  }, []);

  return data;
}

function OneStoreMain({
  storeId,
  store,
  isLoading,
  user,
  isLoggedIn,
  isProvider
}) {
  const { toCount, fromCount, productCount, discoveryCount } = useStoreStats(
    storeId
  );

  return (
    <MainSectionWrapper className="one-store">
      {/* <OneStoreCountInfo
        storeId={store ? store.id : ''}
        storeName={store ? store.userName : ''}
        toCount={toCount}
        fromCount={fromCount}
        productCount={productCount}
        discoveryCount={discoveryCount}
        isLoading={isLoading}
      /> */}
      {/* <OneStoreProfile
        storeId={store ? store.id : ''}
        storeName={store ? store.userName : ''}
        storeNo={store ? store.storeNo : ''}
        isLoading={isLoading}
        profileImgPath={store ? store.profileImgPath : {}}
        isMyStore={user.id === storeId}
        balanceSavedMoney={store ? store.balanceSavedMoney : 0}
        isProvider={isProvider}
        user={user}
        isLoggedIn={isLoggedIn}
        bookmarkedIdList={store ? store.bookmarkedIdList : []}
      /> */}
      {isLoggedIn && user.id === storeId && (
        <Fragment>
          <ToggleList
            buttonText="내 바구니 관리"
            className="setting-menu-toggle"
          >
            <OneStoreSettingMenu
              storeId={store ? store.id : ''}
              isLoading={isLoading}
              balanceSavedMoney={store ? store.balanceSavedMoney : 0}
              user={user}
            />
          </ToggleList>
          {/* <div className="onestore-follow-link-wrapper">
            <Link
              to={{
                pathname: `/store/${storeId}/setting/order`,
                state: { isRefresh: true }
              }}
              className="onestore-setting-menu"
            >
              주문 조회
              <img
                src="/images/onestore-setting-link-icon.svg"
                alt="화살표"
                className="onestore-setting-link-icon-order"
              />
            </Link>
          </div> */}
        </Fragment>
      )}
      {/* <div className="onestore-follow-link-wrapper">
        <Link to={`/store/${store ? store.id : ''}/follow/provider`}>
          공급자 친구들 자세히보기
          <img
            src="/images/follow-link-provider-image.png"
            alt="공급자 친구들 링크"
          />
        </Link>
        <Link to={`/store/${store ? store.id : ''}/follow/stores`}>
          소비자 친구들 자세히보기
          <img
            src="/images/follow-link-customer-image.png"
            alt="소비자 친구들 링크"
            className="follow-link-small-image"
          />
        </Link>
      </div> */}
      <OneStoreOneProductList storeId={storeId} />
    </MainSectionWrapper>
  );
}

export default compose(withRouter, withUser, withUi)(OneStoreMain);
