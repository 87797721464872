import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loader from 'components/commons/Loader';
import { getImagePath } from 'lib/utils/imageUtils';

import './TrackingDeliveryView.scss';

function TrackingDeliveryView({
  status,
  deliverer,
  isLoading,
  isError,
  errorMessage,
  // sweet tracker data
  invoiceNo,
  senderName,
  // receiverName,
  // receiverAddr,
  trackingDetails
}) {
  if (isLoading) {
    return <Loader />;
  }

  if (!deliverer) {
    return (
      <div className="tracking-status tracking-text">
        {status === 'ordered'
          ? '선물을 아직 확인하지 않으셨습니다'
          : status === 'confirmed'
          ? '주문 처리 중입니다.'
          : '배송 준비 중 입니다.'}
      </div>
    );
  }

  if (isError) {
    return <div className="tracking-status tracking-text">{errorMessage}</div>;
  }

  if (deliverer.id === '990') {
    return (
      <div className="tracking-status tracking-text">아직 사용 전입니다.</div>
    );
  }

  if (deliverer.id === '000') {
    return (
      <div className="tracking-status tracking-text">직접 전달 받았습니다.</div>
    );
  }

  if (deliverer.id === '995') {
    return (
      <div className="tracking-status">
        <img
          src={getImagePath(
            `https://cdn.from.kr/event/fortune/${deliverer.message.image}`,
            150,
            150
          )}
          alt="포춘쿠키"
        />
        <div>
          <span className="value">{`"${deliverer.message.result}"`}</span>
          <br />
          <br />
          <span className="label">{` - ${deliverer.message.author}`}</span>
        </div>
      </div>
    );
  }

  const hasTrackingDetails = trackingDetails.length > 0;

  return (
    <Fragment>
      {invoiceNo && (
        <ul className="tracking-status">
          <li>
            <span className="label">택배사 : </span>
            <span className="value">{`${deliverer.name} ${invoiceNo}`}</span>
          </li>
          <li>
            <span className="label">보낸이 : </span>
            <span className="value">{senderName}</span>
          </li>
          {/* <li>
            <span className="label">받는이 : </span>
            <span className="value">{receiverName}</span>
          </li>
          <li>
            <span className="label">받는주소 : </span>
            <span className="value">{receiverAddr}</span>
          </li> */}
        </ul>
      )}
      {hasTrackingDetails && (
        <ul className>
          <li className="tracking-detail-status header">
            <div className="time">처리일시</div>
            <div className="where">현재위치</div>
            <div className="kind">배송상태</div>
          </li>
          {trackingDetails.map((item, index) => {
            const { kind, time, timeString, where } = item;

            return (
              <li
                className={classNames(
                  'tracking-detail-status tracking-detail',
                  {
                    'last-detail': index === 0
                  }
                )}
                key={time}
              >
                <div className="time">{timeString}</div>
                <div className="where">{where}</div>
                <div className="kind">{kind}</div>
              </li>
            );
          })}
        </ul>
      )}
    </Fragment>
  );
}

TrackingDeliveryView.propTypes = {
  errorMessage: PropTypes.string,
  invoiceNo: PropTypes.string,
  isError: PropTypes.bool,
  // receiverName: PropTypes.string,
  // receiverAddr: PropTypes.string,
  senderName: PropTypes.string,
  trackingDetails: PropTypes.arrayOf(PropTypes.shape({}))
};

TrackingDeliveryView.defaultProps = {
  errorMessage: '',
  invoiceNo: '',
  isError: false,
  // receiverName: '',
  // receiverAddr: '',
  senderName: '',
  trackingDetails: []
};

export default TrackingDeliveryView;
