import { useState } from 'react';
import { fromEvent } from 'file-selector';

const readFileAsDataUrl = (file) => {
  // console.log({ file });
  // console.log(typeof file);
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onloadend = (e) => {
      // console.log({ fileReader });
      if (fileReader.readyState === 2) resolve(fileReader.result);
      reject('');
    };
    fileReader.readAsDataURL(file);
  });
};

function useImageInput(initalImageUrls = []) {
  const [imageUrls, setImageUrls] = useState(initalImageUrls);
  const [imageFiles, setImageFiles] = useState(initalImageUrls);
  const [isLarge, setisLarge] = useState(false);

  const setImages = (imageUrls) => {
    setImageFiles(imageUrls);
    setImageUrls(imageUrls);
  };

  const addImages = async (newImageFiles) => {
    // console.log({ newImageFiles });

    const newImageUrls = await Promise.all(
      newImageFiles.map((file) => readFileAsDataUrl(file))
    );

    // console.log({ newImageUrls });
    setImageFiles([...imageFiles, ...newImageFiles]);
    setImageUrls([...imageUrls, ...newImageUrls]);
  };

  const onAdd = async (event) => {
    try {
      // console.log({ event });
      const files = await fromEvent(event);

      // console.log({ files });
      addImages(files);
    } catch (error) {
      console.log({ error });
    }
  };

  const onDelete = (index) => {
    setImageFiles(imageFiles.filter((file, i) => i !== index));
    setImageUrls(imageUrls.filter((url, i) => i !== index));
  };

  const onToggleSize = () => {
    // console.log({ isLarge });
    setisLarge((prev) => !prev);
  };

  // console.log({ imageUrls });

  return {
    imageUrls,
    imageFiles,
    isLarge,
    setImages,
    onAdd,
    onDelete,
    onToggleSize
  };
}

export default useImageInput;
