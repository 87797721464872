import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './FooterItem.scss';

export default function FooterItem({
  className,
  footerName,
  footerImgSrc,
  isActive,
  isLoggedIn,
  isProtected = true,
  redirectUrl,
  to
}) {
  const defaultExtension = '.svg';
  const activeExtension = `-active${defaultExtension}`;

  return (
    <li className={classNames('footer-menu-item', className)}>
      <Link
        to={!isProtected || isLoggedIn || !redirectUrl ? to : redirectUrl}
        className={isActive ? 'active' : ''}
      >
        <img
          className="footer-menu-item-icon"
          src={`/images/footer/${footerImgSrc}${
            isActive ? activeExtension : defaultExtension
          }`}
          alt={footerName}
        />
        <div className="footer-menu-item-name">{footerName}</div>
      </Link>
    </li>
  );
}

FooterItem.propTypes = {
  className: PropTypes.string,
  footerImgSrc: PropTypes.string,
  footerName: PropTypes.string,
  isActive: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  redirectUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
};

FooterItem.defaultProps = {
  className: '',
  footerImgSrc: '',
  footerName: '',
  isActive: false,
  isLoggedIn: false,
  redirectUrl: '' || {},
  to: '' || {}
};
