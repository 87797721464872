import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import OrderListContainer from 'containers/oneStore/OrderListContainer';

function OrderList() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <OrderListContainer />
    </Fragment>
  );
}

export default OrderList;
