import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Loader from 'components/commons/Loader';
import EmptyList from 'components/commons/EmptyList';
// import Button from 'components/commons/Button';
import OverlapPictureBox from 'components/commons/OverlapPictureBox';

import { getFormatTimeStempDate } from 'lib/time';
import { getImagePath } from 'lib/utils/imageUtils';

// import 'react-virtualized/styles.css';
import './GiftReceiveList.scss';
import './GiftItemList.scss';

function GiftItem({ giftInfo, giftBoxType, onClicKShareKakao }) {
  const { id, createDate, buyer, product, order, receiver, status } =
    giftInfo || {};
  const { id: buyerId, name: buyerName, profileImgPath: buyerProfileImgPath } =
    buyer || {};
  const { name: receiverName, profileImgPath: receiverProfileImgPath } =
    receiver || {};
  const { id: productId, name: productName, officialProductImgPath } =
    product || {};
  const { optionName, quantity } = order || {};

  let statusLabel = '';

  if (status === 'ordered') {
    statusLabel = '미확인';
  } else if (status === 'canceled') {
    statusLabel = '결제취소';
  } else {
    statusLabel = '확인완료';
  }

  let gifterName = '';
  let gifterImg = {};

  if (giftBoxType === 'recv') {
    gifterName = buyerName;
    gifterImg = buyerProfileImgPath;
  } else {
    gifterName = receiverName;
    gifterImg = receiverProfileImgPath;
  }

  return (
    <div className="ordergift-list-item-wrapper">
      <div
        className={classNames('ordergift-list-item', {
          'is-confirmed-item': status === 'canceled'
        })}
      >
        <Link to={`/gift/${id}`}>
          <div className="ordergift-list-item-header">
            <div className={classNames('ordergift-list-item-header-type')}>
              {statusLabel}
            </div>
            <div className="ordergift-list-item-small-text">
              {getFormatTimeStempDate(createDate, 'YYYY.MM.DD.H:mm')}
            </div>
            <div className="ordergift-list-item-header-right">
              {statusLabel === '미확인' ? (
                giftBoxType === 'recv' ? (
                  <div className="button status-ordered-recv">
                    선물이 도착했어요!
                  </div>
                ) : (
                  <button
                    className="button status-ordered-send"
                    onClick={(e) => {
                      e.preventDefault();

                      onClicKShareKakao(giftInfo);
                    }}
                  >
                    <img src="/images/small-kakao-icon.svg" alt="카카오 공유" />
                    선물톡보내기
                  </button>
                )
              ) : (
                <img
                  src="/images/onestore-setting-link-icon.svg"
                  alt="화살표"
                />
              )}
            </div>
          </div>
        </Link>
        <Link to={`/store/${buyerId}/${productId}`}>
          <div className="ordergift-list-content">
            <div className="ordergift-list-content-product-info">
              <OverlapPictureBox
                className="ordergift-list-content-product-image"
                mainImage={{
                  src: officialProductImgPath || '/images/default-image.svg',
                  alt: '선물 이미지'
                }}
                subImage={{
                  src:
                    gifterImg && gifterImg.path
                      ? getImagePath(gifterImg)
                      : '/images/default-profile.svg',
                  alt: '선물 보낸사람',
                  border: 'from'
                }}
              />
              <div className="ordergift-list-content-product-info-text">
                <div className="ordergift-list-content-product-name">
                  {gifterName}
                  {`${giftBoxType === 'recv' ? '님이' : '님에게'} 보낸 선물`}
                </div>
                <div className="ordergift-list-content-product-name">
                  {productName}
                </div>
                <div className="ordergift-list-content-product-name">
                  {optionName && quantity && `${optionName} X ${quantity}`}
                </div>
              </div>
            </div>
            {/* {giftBoxType === 'recv' && status === 'ordered' && (
            <Button
              color="w-from"
              buttonText="선물이 도착했어요!"
              buttonType="link"
              className="ordergift-recv-button"
              to={`/gift/${id}`}
            />
          )}
          {status !== 'ordered' && (
            <div className="ordergift-list-content-overlay">
              {status === 'canceled' ? (
                <img src="/images/stemp-red.svg" alt="스탬프 취소" />
              ) : (
                <img src="/images/stemp-green.svg" alt="스탬프 확인" />
              )}
            </div>
          )} */}
          </div>
        </Link>
      </div>
    </div>
  );
}
function GiftItemList({ isLoading, giftList, giftBoxType, onClicKShareKakao }) {
  const haveRecvList = giftList && giftList.length > 0;

  if (isLoading) {
    return <Loader />;
  }

  if (!haveRecvList) {
    return <EmptyList className="mt-5" />;
  }

  return (
    <div className="ordergift-list">
      {giftList.map((list) => {
        return (
          <GiftItem
            giftInfo={list}
            key={`${list.id}`}
            giftBoxType={giftBoxType}
            onClicKShareKakao={onClicKShareKakao}
          />
        );
      })}
    </div>
  );
}

export default GiftItemList;
