import styled from 'styled-components';
import { FormControlGrid } from '../styled/componentStyle';

export const MentionTextAreaGrid = styled(FormControlGrid)`
  .mentions-input {
    grid-area: input-control;
    display: block;
    width: 90%;

    .mention-input__control {
      width: 100%;
      height: 50px;
      background-color: '#fff';
      font-size: 14px;
      font-weight: bold;

      .mention-input__highlighter {
        padding: 9px;
        border: 1px solid transparent;
      }

      textarea[style] {
        box-sizing: border-box;
        padding: 9px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid darkgray !important;
        font-size: 14px;
        line-height: 1.5;

        &:focus {
          border: 2px solid black;
        }

        &:disabled {
          opacity: 0.4;
        }
      }
    }
    .mention-input__suggestions {
      z-index: 2;
      .list[style] {
        background-color: grey !important;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 14px;
      }

      .item {
        padding: 5px 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        &:focus {
          background-color: #cee4e5;
        }
      }
    }
  }
`;
