import styled, { css } from 'styled-components';

export const StyledHeader = styled.header`
  position: sticky;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height, 56px);
  background-color: var(--from-color, #37e211);
  z-index: 5;
  text-align: center;
  transition: top 0.3s ease-in-out;

  &.hidden-header {
    top: calc(0px - var(--header-height, 56px));
    transition: top 0.4s ease-in-out;
  }

  &.provider-header {
    background-color: var(--provider-color, #f68b1f);
  }

  h1 {
    color: white;
    width: 100%;
    padding: 0 15px;
    font-size: 1rem;
    font-weight: bold;
    line-height: var(--header-height, 56px);
    overflow: hidden;
    white-space: nowrap;
    word-wrap: normal;
    text-overflow: ellipsis;

    img {
      max-height: 40px;
      vertical-align: middle;
    }
  }

  .back-btn {
    display: block;
    width: 50px;
    height: 35px;
    padding: 5px;
    margin-left: 3px;
    margin-right: 6px;
    background-image: url('/images/header-back-w.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  .reload-btn {
    display: block;
    width: 50px;
    height: 35px;
    padding: 5px;
    margin-right: 6px;
    background-image: url('/images/header-reload-w.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  .dev-button {
    display: block;
    color: red;
    width: 50px;
    height: 35px;
    padding: 5px;
    margin-right: 5px;
  }

  .header-link {
    display: block;
    width: 30px;
    height: 35px;
    padding: 5px 0px;
    margin-right: 12px;
    position: relative;

    &.cart {
      padding-top: 8px;
    }

    img {
      object-fit: cover;
    }

    .badge {
      font-size: 0.625rem;
      color: var(--from-color);
      background-color: white;
      border-radius: 50%;
      position: absolute;
      top: 0px;
      right: -2px;
    }
  }
`;
