import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import { signInStore, reqCheckSession } from 'api/store';
import * as providerApi from 'api/provider';
import { saveSession, clearSession } from 'lib/users/auth';

const SIGNIN_STORE = 'user/SIGNIN_STORE';
const SIGNIN_PROVIDER = 'user/SIGNIN_PROVIDER';
const CHECK_SESSION = 'user/CHECK_SESSION';
const SET_USER = 'user/SET_USER';
const SET_PROFILE_IMAGE = 'user/SET_PROFILE_IMAGE';
const SET_PROVIDER_PROFILE_IMAGE = 'user/SET_PROVIDER_PROFILE_IMAGE';
const GET_PROVIDER = 'user/GET_PROVIDER';
// const SIGNIN_PROVIDER = 'signin/SIGNIN_PROVIDER';
const SET_KAKAO_SIGNED = 'user/SET_KAKAO_SIGNED';

/// user actions
export const signIn = createAction(SIGNIN_STORE, signInStore);
export const signInProvider = createAction(
  SIGNIN_PROVIDER,
  providerApi.signInProvider
);
export const checkSession = createAction(CHECK_SESSION, handleCheckSession);
export const setAuth = createAction(SET_USER);
export const setProfileImage = createAction(SET_PROFILE_IMAGE);
export const setProviderProfileImage = createAction(SET_PROVIDER_PROFILE_IMAGE);

export const getProviderInfo = createAction(GET_PROVIDER, signInStore);
// export function signInProvider() {
//   createAction(SIGNIN_PROVIDER, providerSignIn);
// }
export const changeKakaoButton = createAction(SET_KAKAO_SIGNED);

// 사용자 세션 체크 및 업데이트 처리
function handleCheckSession(session) {
  return new Promise((resolve, reject) => {
    const { identityId, token, userInfo } = session;

    let userType = userInfo.isProvider ? 'provider' : 'store';
    let loginId = userInfo.isProvider ? userInfo.id : userInfo.email;
    reqCheckSession({
      userType,
      loginId,
      identityId,
      token
    })
      .then((result) => {
        const { success, data } = result.data;
        if (!success) {
          throw new Error(`세션 체크 실패 - ${JSON.stringify(result.data)}`);
        }

        const newSession = {
          identityId: data.IdentityId,
          token: data.Token,
          userInfo
        };
        saveSession(newSession);
        resolve(newSession);
      })
      .catch((err) => {
        clearSession();
        reject(err);
      });
  });
}

const initialState = {
  user: {},
  isLoggedIn: false,
  isProvider: false,
  socialInfo: {},
  isSignedKakao: false
};

export default handleActions(
  {
    [SET_KAKAO_SIGNED]: (state, action) => {
      return {
        ...state,
        isSignedKakao: action.payload
      };
    },

    [SET_USER]: (state, action) => {
      const { user, isProvider } = action.payload;

      return {
        ...state,
        isLoggedIn: true,
        isProvider,
        user
      };
    },
    [SET_PROFILE_IMAGE]: (state, action) => {
      const { profileImgPath } = action.payload;

      return {
        ...state,
        user: {
          ...state.user,
          profileImgPath
        }
      };
    },
    [SET_PROVIDER_PROFILE_IMAGE]: (state, action) => {
      const { providerProfileImgPath } = action.payload;

      return {
        ...state,
        user: {
          ...state.user,
          providerProfileImgPath
        }
      };
    },
    ...pender({
      type: SIGNIN_STORE,
      onSuccess: (state, action) => {
        const { userInfo, success } = action.payload.data;

        if (!success) {
          return {
            ...state,
            isLoggedIn: false,
            user: null
          };
        }

        return {
          ...state,
          isLoggedIn: true,
          user: {
            ...userInfo
          }
        };
      }
    }),
    ...pender({
      type: SIGNIN_PROVIDER,
      onSuccess: (state, action) => {
        const { userInfo, success } = action.payload.data;

        if (!success) {
          return {
            ...state,
            isLoggedIn: false,
            user: null
          };
        }

        return {
          ...state,
          isLoggedIn: true,
          isProvider: true,
          user: {
            ...userInfo
          }
        };
      }
    }),
    ...pender({
      type: CHECK_SESSION,
      onSuccess: (state, action) => {
        const { userInfo } = action.payload;
        return {
          ...state,
          isLoggedIn: true,
          isProvider: userInfo.isProvider ? userInfo.isProvider : false,
          user: {
            ...userInfo
          }
        };
      },
      onFailure: (state) => {
        return {
          ...state,
          isLoggedIn: false,
          user: null
        };
      }
    })
  },
  initialState
);
