import styled from 'styled-components';

export const AppWrapper = styled.div`
  position: relative;
  /* max-width: var(--max-width, 950px); */
  min-height: 100%;
  /* margin: 0px auto; */
`;

export const MainWrapper = styled.main`
  position: relative;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: var(--header-height, 56px);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;

  @include min-width-desktop {
    width: 950px;
  }
`;
export const MainSectionWrapper = styled.section`
  max-width: var(--max-width, 950px);
  margin: 0px auto;
  padding-top: 56px;

  &.relay-santa {
    padding-top: 0px;
    max-width: 380px;
  }

  &.market {
    padding-top: 56px;
  }

  &.provider {
    padding-top: 190px;
  }

  &.one-store {
    padding-top: 190px;
  }

  &.one-product {
    padding-top: 128px;

    &.product-detail {
      max-width: var(--max-content-width, 420px);
    }
  }

  &.narrow-section {
    max-width: var(--max-content-width, 420px);
  }
`;
