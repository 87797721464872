import React, { Component, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';
import ReactGA from 'react-ga';
import PullToRefresh from 'pulltorefreshjs';

import { useScroll } from 'shared/hooks/useScroll';
import { getHost, isDevelop } from 'lib/utils/envUtils';

// 레이아웃
import HeaderContainer from 'containers/header/HeaderContainer';
import MainContainer from 'containers/main/MainContainer';
import FooterContainer from 'containers/footer/FooterContainer';
import CompanyInfoContainer from 'containers/company/CompanyInfoContainer';

// import cognitoConfig from 'lib/aws/cognitoConfig';
import withUser from 'lib/hoc/withUser';
import withUi from 'lib/hoc/withUi';
import CDN_URL from 'components/commons/globalUrl';
import { loadUserSession } from 'lib/users/auth';
import { AppWrapper } from 'styles/AppStyle';
// import './App.scss';

const isDev = isDevelop();

function App({ UserActions, UiAction }) {
  const { pathname } = useLocation();
  const { y, direction } = useScroll();
  const [previousHash, setPreviousHash] = useState(null);

  useEffect(() => {
    // console.log({ direction });
    // console.log({ y });

    UiAction.setShowHeader(direction !== 'down' || y < 100);
  }, [y]);

  useEffect(() => {
    initializeUser();
    if (!isDev) {
      ReactGA.initialize('UA-136339861-1'); // google analytics init

      if (typeof window !== 'undefined') {
        window.fbq('init', '459782297925568');
      } // facebook pixel init
    }

    PullToRefresh.init({
      mainElement: 'main',
      onRefresh: function () {
        window.location.reload();
      },
      shouldPullToRefresh: function () {
        return (
          window.scrollY === 0 &&
          !document.body.classList.contains('modal-open') &&
          !document.getElementsByClassName('non-scroll').length > 0
        );
      },
      instructionsPullToRefresh: ' ',
      instructionsReleaseToRefresh: ' ',
      instructionsRefreshing: ' ',
      iconArrow: '&darr;'
    });

    return () => {
      PullToRefresh.destroyAll();
      // window.removeEventListener('scroll', handleScrollThrottled);
    };
  }, []);

  useEffect(() => {
    // console.log({ pathname });
    if (!pathname) return;
    fetchSource();
    if (!isDev) {
      ReactGA.pageview(pathname); // google analytics pageview check

      if (typeof window !== 'undefined') {
        window.fbq('track', 'PageView');
      } // facebook pixel pageview check
    }
  }, [pathname]);

  const getHash = (str) => {
    const len = str.length;
    let hash = 0;
    if (len === 0) return hash;
    let i;
    for (i = 0; i < len; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  const fetchSource = () => {
    return fetch(`${getHost()}/index.html`)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('offline');
        }

        return response.text();
      })
      .then((html) => {
        const hash = getHash(html);

        if (!previousHash) {
          setPreviousHash(hash);

          return;
        }

        if (previousHash !== hash) {
          setPreviousHash(hash);

          window.location.reload();
        }
      })
      .catch(() => {
        /* do nothing */
      });
  };

  const initializeUser = () => {
    // 기존 세션 로드
    const userSession = loadUserSession();
    if (!userSession) {
      return;
    }

    // 세션 체크
    UserActions.checkSession(userSession);
  };

  return (
    <AppWrapper>
      <Helmet>
        <title>
          {isDev ? '[개발]프롬 - 내 친구의 바구니' : '프롬 - 내 친구의 바구니'}
        </title>
        <meta property="fb:app_id" content="185210252132808" />
        <meta name="author" content="프롬" />
        <meta name="title" content="프롬 - 내 친구의 바구니" />
        <meta property="og:title" content="프롬 - 내 친구의 바구니" />
        <meta
          name="description"
          content="내 친구와 내가 발굴하고 응원하는 단골상품이 가득한 프롬입니다."
        />
        <meta
          property="og:description"
          content="내 친구와 내가 발굴하고 응원하는 단골상품이 가득한 프롬입니다."
        />
        <meta property="og:image" content={`${CDN_URL}/logo.png`} />
        <meta property="og:site_name" content="www.from.kr" />
        <meta property="og:type" content="website" />
        <meta
          property="keywords"
          content="제철과일, 발굴하기, 유기농, 농산물, 간식, 커피, 건강, 다이어트음식, 건강이유식, 건강식단, 건강식품, 좋은재료"
        />
      </Helmet>
      <HeaderContainer />
      <MainContainer />
      <CompanyInfoContainer />
      <FooterContainer />
    </AppWrapper>
  );
}

export default compose(withUser, withUi)(App);
