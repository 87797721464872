import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ConfirmGift from 'containers/gift/ConfirmGift';

function ReceivedGift({ match }) {
  // console.log({ match });
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ConfirmGift deliveryId={match.params.deliveryId} />
    </Fragment>
  );
}

export default ReceivedGift;
