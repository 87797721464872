import React from 'react';
import classNames from 'classnames';

import { getPriceFormat } from 'lib/utils/numberUtils';
import { getFormatTimeStempDate } from 'lib/time';

// import 'react-virtualized/styles.css';
import './SavedMoneyList.scss';
import '../SavedMoneyStatusList/SavedMoneyStatusList.scss';

function SavedMoneyItem({ balance }) {
  let headerLabel = '';

  if (balance.payType.indexOf('cancel') > -1) {
    headerLabel = '취소';
  }

  return (
    <div className="saved-money-item">
      <div className="saved-money-item-header">
        {headerLabel && (
          <div
            className={classNames('saved-money-item-header-type', {
              // 'is-saved-type': balance.savedMoney > balance.usedMoney,
              // 'is-used-type': balance.savedMoney < balance.usedMoney
              'is-cancel-type': headerLabel === '취소'
            })}
          >
            {headerLabel}
          </div>
        )}
        <div className="saved-money-item-small-text">
          {`${getFormatTimeStempDate(
            balance.logDate,
            'YY.MM.DD H:mm'
          )} | 결제번호 ${balance.paymentUid}`}
        </div>
        <div className="saved-money-item-header-right">
          <div className="saved-money-item-small-text">잔액</div>
          <img
            className="saved-money-item-point-icon"
            src="/images/icon-point-won.svg"
            alt="원"
          />
          <div className="saved-money-item-balance">
            {getPriceFormat(balance.balance)}
          </div>
        </div>
      </div>
      <div className="saved-money-item-content">
        <div className="saved-money-item-content-text">
          {balance.description}
        </div>
        <div className="saved-money-item-content-money-wrapper">
          <div className="saved-money-item-small-text">적립금</div>
          <div className="saved-money-item-content-money">
            <img
              className="saved-money-item-point-icon"
              src="/images/icon-point-blue.svg"
              alt="번돈"
            />
            <div className="saved-money-item-content-money-price saved-money">
              {getPriceFormat(balance.savedMoney)}
            </div>
            <img
              className="saved-money-item-point-icon"
              src="/images/icon-point-red.svg"
              alt="쓴돈"
            />
            <div className="saved-money-item-content-money-price used-money">
              {getPriceFormat(balance.usedMoney)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SavedMoneyList({ balanceList }) {
  return (
    <div className="saved-money-list">
      {balanceList.map((balance) => {
        return (
          <SavedMoneyItem
            balance={balance}
            key={`${balance.logDate}${balance.usedMoney}`}
          />
        );
      })}
    </div>
  );
}

export default SavedMoneyList;
