/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PictureInput from 'components/commons/PictureInput';
import { getImageFile } from 'lib/utils/imageUtils';

import './RenderImage.scss';

// redux-form 에 사용되는 input Component
function RenderImage({
  input: { value, onChange },
  imagePath,
  className,
  onChangeImage,
  index,
  meta: { error, touched }
}) {
  return (
    <div
      className={classNames(
        'form-group d-flex flex-wrap align-items-top',
        className
      )}
    >
      <PictureInput
        imageFile={getImageFile(imagePath)}
        onChange={onChangeImage}
        index={index}
      />
    </div>
  );
}

export default RenderImage;
