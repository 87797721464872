/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';

import CommonModal from 'components/modal/CommonModal';
import sentry from 'lib/sentry';

import shareFacebook from 'lib/facebook/shareFacebook';
import shareTwitter from 'lib/twitter/shareTwitter';
import shareKakao from 'lib/kakao/shareKakao';

import useModal from 'hooks/useModal';
import AlertModal from 'components/modal/AlertModal';

import { getImagePath } from 'lib/utils/imageUtils';
import { isDevelop, getHost } from 'lib/utils/envUtils';

import CDN_URL from 'components/commons/globalUrl';

import './FortuneModal.scss';

const isDev = isDevelop();

const ERR_MSG =
  '오류가 발생하여 공유를 할 수 없습니다.\n주소를 복사 후 공유해 주세요.';

function FortuneModal({ isOpen, onToggle, metaData = {} }) {
  const [isCopied, setIsCopied] = useState(false);
  const {
    message: errorMessage,
    setMessage: setErrorMessage,
    isOpen: isOpenErrorModal,
    handleToggle: toggleErrorModal
  } = useModal();

  const { referrer, message = {} } = metaData;
  const path = `/store/${referrer}/${
    isDev ? 'p1547213043542' : 'p1612106064364'
  }`;
  // console.log({ metaData, path });

  const imagePath = `https://cdn.from.kr/event/fortune/${message?.image}`;
  // useEffect(() => {
  //   setIsCopied(false);
  // }, []);

  const getUrl = () => {
    let url = getHost();

    if (path) {
      url += path;
    }

    if (referrer) {
      url += `?ref=${referrer}`;
    }
    // console.log({ isCopied });
    // console.log({ url });

    return url;
  };

  const handleClickShareFacebook = () => {
    try {
      shareFacebook(getUrl());

      onToggle();
    } catch (e) {
      sentry.captureException(e);
      console.log({ e });
      setErrorMessage(ERR_MSG);
      toggleErrorModal();
    }
  };

  const handleClickShareTwitter = () => {
    try {
      const text = message?.result?.substring(0, 200).concat('...');
      const hashtags = 'ㅍㄹ본부,프롬,프롬포춘쿠키,포춘쿠키,설날';
      const encodedUri = encodeURI(
        `https://twitter.com/intent/tweet?text=${text}&hashtags=${hashtags}&url=${getUrl()}`
      );
      shareTwitter(encodedUri);

      onToggle();
    } catch (e) {
      sentry.captureException(e);
      console.log({ e });
      setErrorMessage(ERR_MSG);
      toggleErrorModal();
    }
  };

  const handleClickShareKakao = () => {
    try {
      const link = getUrl();

      const description = message?.result?.substring(0, 200).concat('...');

      const kakaoParams = {
        buttonText: `내 포춘쿠키 뽑으러 가기`, // '선물과 기부를 동시에! 찐산타되기',
        description,
        imageUrl: getImagePath(imagePath, 250, 250) || `${CDN_URL}/logo.png`,
        link,
        title: `프롬 ${message?.typeName}`
      };
      // console.log({ kakaoParams });

      shareKakao(kakaoParams);

      onToggle();
    } catch (e) {
      sentry.captureException(e);
      console.log({ e });
      setErrorMessage(ERR_MSG);
      toggleErrorModal();
    }
  };

  const copyUrl = () => {
    // console.log({ isCopied });
    const dummyTextArea = document.createElement('textarea');
    document.body.appendChild(dummyTextArea);
    dummyTextArea.value = getUrl();
    dummyTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(dummyTextArea);
    setIsCopied(true);
    // console.log({ isCopied });
  };

  return (
    <Fragment>
      <CommonModal
        className="fortune-modal"
        size="lg"
        isOpen={isOpen}
        onToggle={() => {
          setIsCopied(false);
          onToggle();
        }}
        headerText={`나의 포춘쿠키 - ${message?.typeName}`}
        // headerImage="/images/santa/santa-profile.jpg"
        headerImage={getImagePath(imagePath, 200, 200)}
        isFortune
        okButtonText={'포춘쿠키 선물하기'}
        linkPath={path}
        secondButtonText={'닫기'}
        onClickSecond={() => {
          setIsCopied(false);
          onToggle();
        }}
        secondReplace
      >
        <div className="fortune-content-wrapper">
          {/* <hr />
          <br /> */}
          {`"${message?.result}"`}
          <br />
          <br />
          <span>{`- ${message?.author} -`}</span>
          <br />
          <br />
          <hr />
          <br />
          <span>프롬 포춘쿠키 결과 공유 이벤트 진행중!</span>
          <br />
          <span>
            자세한 정보는 <Link to={path}>포춘쿠키 상세페이지</Link>를
          </span>
          <br />
          <span>확인하세요!</span>
        </div>
        <div className="share-button-title">포춘쿠키 공유하기</div>
        <div className="share-button-wrapper">
          <button onClick={handleClickShareFacebook}>
            {/* <div className="">페이스북 공유</div> */}
            <img
              className="share-button-img"
              src="/images/facebook-btn2.svg"
              alt="페이스북 공유"
            />
          </button>
          <button onClick={handleClickShareTwitter}>
            <img
              className="share-button-img"
              src="/images/twitter.png"
              alt="트위터 공유"
            />
          </button>
          <button onClick={handleClickShareKakao}>
            {/* <div className="">카카오톡 공유</div> */}
            <img
              className="share-button-img"
              src="/images/kakao-btn2.svg"
              alt="카카오 공유"
            />
          </button>
          <button onClick={copyUrl}>
            {/* <div className="">URL 복사하기</div> */}
            <div
              className="share-button-img"
              style={{
                backgroundImage: `url("/images/copy-clip-icon2.svg")`
              }}
            >
              {isCopied && (
                <img
                  className="success-copy-url-image"
                  src="/images/copy-url-success.svg"
                  alt="복사버튼"
                />
              )}
            </div>
          </button>
        </div>
      </CommonModal>
      <AlertModal
        type="errorModal"
        contentText={errorMessage}
        isOpen={isOpenErrorModal}
        onClick={toggleErrorModal}
        onToggle={toggleErrorModal}
      />
    </Fragment>
  );
}

export default FortuneModal;
