import React, { Fragment } from 'react';
import classNames from 'classnames';
import { reduxForm, Field } from 'redux-form';
import { Modal, ModalBody } from 'reactstrap';

import Agreement from 'components/users/Agreement';
import Button from 'components/commons/Button';
import BuyerInfoSaveCheckBox from 'components/users/BuyerInfoSaveCheckBox';
import RenderField from 'components/reduxForm/RenderField';
import RenderFieldTextArea from 'components/reduxForm/RenderFieldTextArea';
import PictureInput from 'components/commons/PictureInput';
import AddressModal from 'components/modal/AddressModal';

import {
  checkEmailType,
  required,
  minLength6,
  maxLength16,
  tel,
  pwdConfirm,
  checkDupilcationWhenSignUp
} from 'lib/form/validations';
import { getImageFile } from 'lib/utils/imageUtils';

import './SignUpForm.scss';

function SignUp({
  flagAgreeMarketing,
  profileImage,
  handleSubmit,
  isOpenSearchAddress,
  isCheckedAgreement,
  isCheckedAllCheckbox,
  isCheckdPrivacy,
  isErrorSignUp,
  isKakaoSignUp,
  pristine,
  invalid,
  submitting,
  onChangeEmail,
  onChangeCheckbox,
  onChangeAddress,
  onChangeImage,
  onToggle,
  onToggleSignUp,
  onSignUp,
  isLoading,
  onOpenAgreementModal
}) {
  return (
    <Fragment>
      <div className="signup p-3">
        <form onSubmit={handleSubmit(onSignUp)} className="mt-3">
          <h3 className="form-headline">필수 가입정보 입력</h3>
          <Field
            name="userName"
            autocomplete="name"
            label="이름"
            type="text"
            component={RenderField}
            placeholder="이름"
            validate={[required]}
            pattern={/\s/g}
            isRequired
          />
          <Field
            name="mobile"
            type="tel"
            component={RenderField}
            label="휴대폰"
            placeholder="- 없이 숫자만 입력"
            pattern={/[^0-9]/g}
            redirectUrl="/"
            validate={[required, tel]}
            isRequired
          />
          <Field
            name="email"
            label="이메일"
            type="email"
            className="form-with-button"
            component={RenderField}
            onChange={onChangeEmail}
            placeholder="이메일"
            redirectUrl="/"
            validate={[required, checkEmailType]}
            pattern={/\s/g}
            isRequired
          />
          {!isKakaoSignUp && (
            <Fragment>
              <Field
                name="pwd"
                type="password"
                label="비밀번호"
                component={RenderField}
                placeholder="6 ~ 16자 비밀번호 입력"
                validate={[required, minLength6, maxLength16]}
                isRequired
              />
              <Field
                name="pwd2"
                type="password"
                component={RenderField}
                label="비밀번호 확인"
                placeholder="비밀번호 확인"
                validate={[required, pwdConfirm]}
                isRequired
              />
            </Fragment>
          )}

          <div className="signup-optional-form">
            <h3 className="form-headline">추가 가입정보 입력</h3>
            <div>
              <div className="form-group clearfix">
                <label
                  className="form-label form-picture-label float-left text-left pr-3"
                  htmlFor="profile-image"
                >
                  프로필 사진
                </label>
                <div className="float-left">
                  <PictureInput
                    imageFile={getImageFile(profileImage)}
                    onChange={onChangeImage}
                  />
                </div>
              </div>
            </div>
            <Field
              name="zipcode"
              type="text"
              className="form-with-button"
              component={RenderField}
              label="우편번호"
              placeholder="우편번호"
              onFocus={onToggle}
              readOnly
            >
              <button
                type="button"
                className="form-button btn-primary form-input-in-button"
                onClick={onToggle}
              >
                찾기
              </button>
            </Field>
            <Field
              name="addr"
              autoComplete="address-line1"
              type="text"
              className="received-gift-addr"
              component={RenderFieldTextArea}
              minHeight={39}
              label="주소"
              placeholder="주소"
              readOnly
              onFocus={onToggle}
            />
            <Field
              name="addrSub"
              type="text"
              component={RenderField}
              label="상세주소"
              placeholder="상세주소"
            />
          </div>
          <div className="agreement-form">
            <h3 className="form-headline">이용약관동의</h3>
            <BuyerInfoSaveCheckBox
              checkboxName="isCheckedAllCheckbox"
              className="pl-0"
              labelText="모두 동의합니다."
              isCheckedBuyerInfoSave={isCheckedAllCheckbox}
              onChangeCheckbox={() => {
                onChangeCheckbox('isCheckedAllCheckbox');
              }}
            />
            <Agreement
              checkboxName="isCheckedAgreement"
              labelText={
                <Fragment>
                  <span>[필수]</span> 이용약관
                </Fragment>
              }
              isCheckedAgreement={isCheckedAgreement}
              onChangeCheckbox={() => {
                onChangeCheckbox('isCheckedAgreement');
              }}
              onClickButton={() => {
                onOpenAgreementModal('/terms/terms-user-new.html');
              }}
            />
            <Agreement
              checkboxName="isCheckdPrivacy"
              className="mt-2"
              labelText={
                <Fragment>
                  <span>[필수]</span> 개인정보처리방침
                </Fragment>
              }
              isCheckedAgreement={isCheckdPrivacy}
              onChangeCheckbox={() => {
                onChangeCheckbox('isCheckdPrivacy');
              }}
              onClickButton={() => {
                onOpenAgreementModal('/terms/terms-privacy-confirm-new.html');
              }}
            />
            <Agreement
              checkboxName="flagAgreeMarketing"
              className="mt-2"
              labelText={
                <Fragment>
                  <span>[선택]</span> 마케팅 동의
                </Fragment>
              }
              isCheckedAgreement={flagAgreeMarketing}
              onChangeCheckbox={() => {
                onChangeCheckbox('flagAgreeMarketing');
              }}
              onClickButton={() => {
                onOpenAgreementModal('/terms/terms-marketing.html');
              }}
            />
          </div>
          <Button
            buttonText={isKakaoSignUp ? '카카오톡으로 가입하기' : '가입하기'}
            buttonType="submit"
            className={classNames('signup-button', {
              kakao: isKakaoSignUp
            })}
            isLoading={isLoading}
            isDisable={
              pristine ||
              invalid ||
              submitting ||
              !isCheckdPrivacy ||
              !isCheckedAgreement
            }
          />
        </form>
        <AddressModal
          isOpen={isOpenSearchAddress}
          onToggle={onToggle}
          onSelect={onChangeAddress}
        />
      </div>
      <Modal
        className="signup-error-modal"
        isOpen={isErrorSignUp}
        toggle={onToggleSignUp}
      >
        <ModalBody>
          <div className="p-3 text-center">
            통신이 불안정하여 회원가입에 실패했습니다.
            <br />
            잠시 후 다시 시도해주세요. <br />
            <button
              type="button"
              className="btn btn-primary mt-3"
              onClick={onToggleSignUp}
            >
              확인
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

// eslint-disable-next-line
SignUp = reduxForm({
  form: 'signUpForm',
  asyncValidate: checkDupilcationWhenSignUp,
  asyncBlurFields: ['email', 'mobile']
})(SignUp);

export default SignUp;
