import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import OneStoreFromListContainer from 'containers/oneStore/OneStoreFromListContainer';

function OneStoreFromList({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <OneStoreFromListContainer storeId={match.params.storeId} />
    </Fragment>
  );
}

export default OneStoreFromList;
