import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './SignInHelpTab.scss';

export default function SignInHelpTab({ isCheckedId, isCheckedPassword }) {
  return (
    <ul className="signin-help-tab">
      <li
        className={classNames({
          'selected-border': isCheckedId,
          'unselect-border': isCheckedPassword
        })}
      >
        <Link className="text-dark" to="/store/signin/help/id">
          아이디 찾기
        </Link>
      </li>
      <li
        className={classNames({
          'selected-border': isCheckedPassword,
          'unselect-border': isCheckedId
        })}
      >
        <Link className="text-dark" to="/store/signin/help/password">
          비밀번호 찾기
        </Link>
      </li>
    </ul>
  );
}
