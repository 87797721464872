import React, { Component, Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import InfiniteScroll from 'react-infinite-scroller';

import storage from 'lib/storage';
import withUi from 'lib/hoc/withUi';
import shareKakao from 'lib/kakao/shareKakao';
import { getHost } from 'lib/utils/envUtils';
import { getImagePath } from 'lib/utils/imageUtils';

import AlertModal from 'components/modal/AlertModal';
import RedirectToHome from 'components/commons/RedirectToHome';
import GiftItemList from 'components/setting/GiftBox/GiftItemList';
import GiftBoxHeader from 'components/setting/GiftBox/GiftBoxHeader';
import CDN_URL from 'components/commons/globalUrl';

import { getReceiveGiftList, getSendGiftList } from 'api/gift';

class GiftBoxContainer extends Component {
  // 뒤로가기 했을때 state 설정
  static getDerivedStateFromProps(nextProps, prevState) {
    const { pathname } = nextProps.location;
    const splitPathname = pathname ? pathname.split('/') : [];
    const subPath = splitPathname[splitPathname.length - 1]
      ? splitPathname[splitPathname.length - 1]
      : '';

    if (subPath !== prevState.currentTab) {
      return {
        currentTab: subPath
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { location } = this.props;
    const { pathname } = location;
    const splitPathname = pathname ? pathname.split('/') : [];
    const subPath = splitPathname[splitPathname.length - 1]
      ? splitPathname[splitPathname.length - 1]
      : '';

    this.state = {
      currentTab: subPath,
      hasRecvLastKey: false,
      hasSendLastKey: false,
      isLoading: false,
      isOpenErrorModal: false,
      recvGiftList: [],
      sendGiftList: []
    };

    this.pageCount = 20;
    this.recvLastKey = null;
    this.sendLastKey = null;
  }

  componentDidMount() {
    const { UiAction } = this.props;
    UiAction.setHeader('선물함');
    this.handleGetList();

    // 점검 모달
    // this.errorRedirect();
  }

  getReceiveList = async () => {
    const { storeId } = this.props;
    const { recvGiftList } = this.state;

    const params = {
      storeId,
      pageCount: this.pageCount,
      lastKey: this.recvLastKey
    };

    try {
      const response = await getReceiveGiftList(params);

      if (!response || !response.data.success) {
        throw new Error();
      }

      const { data } = response.data;

      this.recvLastKey = data.LastEvaluatedKey;

      this.setState({
        recvGiftList: recvGiftList.concat(data.Items || []),
        hasRecvLastKey: !!data.LastEvaluatedKey,
        isLoading: false
      });
    } catch (e) {
      this.setState({
        isLoading: false
      });
    }
  };

  getSendList = async () => {
    const { storeId } = this.props;
    const { sendGiftList } = this.state;

    const params = {
      storeId,
      pageCount: this.pageCount,
      lastKey: this.sendLastKey
    };

    try {
      const response = await getSendGiftList(params);

      if (!response || !response.data.success) {
        throw new Error();
      }

      const { data } = response.data;

      this.sendLastKey = data.LastEvaluatedKey;

      this.setState({
        sendGiftList: sendGiftList.concat(data.Items || []),
        hasSendLastKey: !!data.LastEvaluatedKey,
        isLoading: false
      });
    } catch (e) {
      this.setState({
        isLoading: false
      });
    }
  };

  handleChangeTab = (path) => {
    const { history, storeId } = this.props;
    this.setState(
      {
        currentTab: path
      },
      () => {
        this.handleGetList();
      }
    );

    history.push(`/store/${storeId}/setting/giftbox/` + path);
  };

  handleGetList = () => {
    const { recvGiftList, sendGiftList, currentTab } = this.state;

    if (currentTab === 'recv') {
      if (recvGiftList.length === 0) {
        this.setState(
          {
            isLoading: true
          },
          () => {
            this.getReceiveList();
          }
        );
      }
      return;
    }

    if (currentTab === 'send') {
      if (sendGiftList.length === 0) {
        this.setState(
          {
            isLoading: true
          },
          () => {
            this.getSendList();
          }
        );
      }
    }
  };

  handleClickShareKakao = (data) => {
    if (!data) {
      this.setState({
        isOpenErrorModal: true,
        errorMessage:
          '오류가 발생하여 카카오 공유를 할 수 없습니다.\n주소를 복사 후 카카오톡으로 공유해 주세요.'
      });
      return;
    }

    // const {
    //   buyerInfo,
    //   expId,
    //   giftInfo,
    //   productName,
    //   officialProductImgPath
    // } = data;
    // const { storeName } = buyerInfo;
    // const { giftMessage } = giftInfo;

    const { buyer, order, product, id: deliveryId } = data;
    const { name: storeName } = buyer || {};
    const { giftMessage } = order || {};
    const { name: productName, officialProductImgPath } = product || {};

    const kakaoParams = {
      buttonText: `${storeName || '프롬'}님의 선물 받기`,
      description: giftMessage || `${storeName}`,
      imageUrl: getImagePath(officialProductImgPath) || `${CDN_URL}/logo.png`,
      link: `${getHost()}/gift/${deliveryId}`,
      title: productName
    };

    shareKakao(kakaoParams);
  };

  handleCloseErrorModal = () => {
    this.setState({
      isOpenErrorModal: false
    });
  };

  handleRedirectHome = () => {
    this.setState({
      isOpenRedirectModal: false
    });

    this.props.history.replace('/');
  };

  errorRedirect = () => {
    this.setState({
      isOpenRedirectModal: true
    });
  };

  render() {
    const {
      currentTab,
      errorMessage,
      hasRecvLastKey,
      hasSendLastKey,
      isLoading,
      isOpenErrorModal,
      isOpenRedirectModal,
      recvGiftList,
      sendGiftList
    } = this.state;
    const userInfo = storage.get('user_userInfo');
    const { match } = this.props;
    const { storeId } = match.params;

    return (
      <Fragment>
        <RedirectToHome
          isGotoHome={!userInfo || userInfo.id !== storeId}
          redirectPath={`/store/$storeId/setting/giftbox/${currentTab}`}
        />
        <GiftBoxHeader onClick={this.handleChangeTab} currentTab={currentTab}>
          {/* 상위탭 */}
          <Route
            path="/store/:storeId/setting/giftbox/recv"
            render={() => (
              <InfiniteScroll
                pageStart={0}
                loadMore={this.getReceiveList}
                hasMore={hasRecvLastKey}
              >
                <GiftItemList
                  isLoading={isLoading}
                  giftList={recvGiftList}
                  giftBoxType="recv"
                />
                {/* <GiftReceiveList
                  isLoading={isLoading}
                  recvGiftList={recvGiftList}
                /> */}
              </InfiniteScroll>
            )}
          />
          <Route
            path="/store/:storeId/setting/giftbox/send"
            render={() => (
              <InfiniteScroll
                pageStart={0}
                loadMore={this.getSendList}
                hasMore={hasSendLastKey}
              >
                <GiftItemList
                  isLoading={isLoading}
                  giftList={sendGiftList}
                  giftBoxType="send"
                  onClicKShareKakao={this.handleClickShareKakao}
                />
                {/* <GiftSendList
                  isLoading={isLoading}
                  onClicKShareKakao={this.handleClickShareKakao}
                  sendGiftList={sendGiftList}
                /> */}
              </InfiniteScroll>
            )}
          />
        </GiftBoxHeader>
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={this.handleCloseErrorModal}
        />
        <AlertModal
          isOpen={isOpenRedirectModal}
          contentText="죄송합니다. 점검중입니다."
          onToggle={this.handleRedirectHome}
        />
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  withUi
)(GiftBoxContainer);
