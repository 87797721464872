import React, { Fragment } from 'react';

import './GiftBoxHeader.scss';

function GiftBoxHeader({ onClick, children, currentTab }) {
  return (
    <Fragment>
      <div className="giftbox-header-tab ml-auto mr-auto mb-3">
        <ul className=" d-flex align-items-center justify-content-center">
          <li>
            <button
              type="button"
              onClick={() => {
                onClick('recv');
              }}
              className={`${
                currentTab === 'recv'
                  ? 'gift-selected-border'
                  : 'gift-unselect-border'
              } `}
            >
              {/* <span className="gift-unread-notify">1</span> 미확인 선물 알림 */}
              받은 선물
              <div className="tab-bottom-dot-icon" />
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => {
                onClick('send');
              }}
              className={`${
                currentTab === 'send'
                  ? 'gift-selected-border'
                  : 'gift-unselect-border'
              } `}
            >
              {/* <img
                src={
                  currentTab === 'send'
                    ? '/images/delivery-icon-on.svg'
                    : '/images/delivery-icon.svg'
                }
                alt="선물 탭"
              /> */}
              보낸 선물
              <div className="tab-bottom-dot-icon" />
            </button>
          </li>
        </ul>
      </div>
      <div className="gift-box-list">{children}</div>
    </Fragment>
  );
}

export default GiftBoxHeader;
