import styled, { css } from 'styled-components';

export const ItemCardWrapper = styled.div`
  display: grid;
  max-width: 340px;
  grid-template-columns: 10px minmax(152.5px, 180px) 10px;
  grid-template-rows: repeat(7, auto);
  column-gap: 0px;
  row-gap: 2px;
  align-items: center;
  justify-items: start;
  margin: 5px 5px;
  color: var(--text-color, black);

  grid-template-areas:
    '. item-header .'
    'item-image item-image item-image'
    '. item-title .'
    '. item-profile .'
    '. item-text .'
    '. item-footer .'
    '. item-action .';

  &.hidden-card {
    opacity: 0.3;
    transition: 400ms;
  }

  &.small {
    max-width: 160px;
    grid-template-columns: 5px 150px 5px;
  }

  &.small-option {
    max-width: 140px;
    grid-template-columns: 5px 130px 5px;
  }

  &.very-large {
    max-width: 340px;
    grid-template-columns: 10px 320px 10px;
  }

  &.selected {
    opacity: 0.4;
  }
`;

export const StyledItemLoader = styled.div`
  grid-row: 1 / -1;
  grid-column: 1/ -1;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;

  img {
    width: 30px;
    opacity: 80%;
  }
`;
export const StyledItemImage = styled.div`
  grid-area: item-image;
  margin-bottom: 10px;
  justify-self: center;
  position: relative;

  .image {
    width: 172.5px;
    height: 175px;
    object-fit: cover;
    border-radius: 7px;

    &.small {
      width: 160px;
      height: 160px;
    }

    &.small-option {
      width: 140px;
      height: 140px;
    }

    &.large {
      width: 250px;
      height: 250px;
    }

    &.very-large {
      width: 340px;
      height: 200px;
    }
  }
  .item-selected-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    opacity: 1;
    z-index: 2;
  }
`;

export const StyledItemTitle = styled.div`
  grid-area: item-title;
  height: 58px;
  width: 100%;
  line-height: 1.3;
  font-size: 0.875rem;
  font-weight: bold;
  white-space: initial;
  overflow: hidden;

  &.small {
    height: 44px;
    font-size: 0.75rem;
  }

  &.large {
    height: 64px;
    font-size: 1rem;
  }

  .title-icon {
    margin-left: 3px;
    width: 19px;
    height: 19px;
    vertical-align: middle;
  }
`;

export const StyledItemProfile = styled.div`
  grid-area: item-profile;
  display: flex;
  align-items: center;
  height: 42px;
  width: 100%;

  .profile-image {
    width: 31px;
    height: 31px;
    object-fit: cover;
    border-radius: 50%;
    // border: 2px solid var(--from-color, #37e011);
    z-index: 1;

    &.small {
      width: 24px;
      height: 24px;
    }

    &.large {
      width: 54px;
      height: 54px;
    }

    &.provider {
      border: 2px solid var(--provider-color, orange);
    }
  }

  .profile-name {
    padding-left: 10px;
    font-size: 0.875rem;
    font-weight: 800;
    color: #2d2a2a;
  }
`;

export const StyledItemPrice = styled.div`
  grid-area: item-profile;
  height: 35px;
  font-size: 0.75rem;

  .discount-price {
    font-size: 0.875rem;
    font-weight: 800;
    &.small {
      font-size: 0.75rem;
    }
  }

  .gift-point {
    margin-left: 3px;
    font-size: 0.625rem;
    font-weight: bold;
    color: var(--from-color, #37e011);
  }

  .regular-price {
    font-size: 0.875rem;
    margin-top: 6px;
    color: #e2e2e2;
    font-weight: bold;
    &.small {
      font-size: 0.75rem;
    }
  }
`;

export const StyledItemText = styled.div`
  grid-area: item-text;
  height: 36px;
  width: 100%;
  margin-top: 3px;

  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1.4;

  white-space: initial;
  overflow: hidden;
`;

export const StyledItemSetting = styled.div`
  grid-area: item-image;
  margin-top: 10px;
  margin-right: 10px;
  align-self: start;
  justify-self: end;
  z-index: 1;
`;

export const StyledItemStatus = styled.div`
  grid-area: item-header;
  height: 21px;
  align-self: start;
  justify-self: start;
  display: flex;
  z-index: 1;

  .status-circle {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    border: solid 2px #ffffff;

    .text-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .text-in-circle {
        font-size: 10px;
        font-weight: bold;
        color: #ffffff;
        width: 30px;
      }
    }

    &.out-of-stock-circle {
      background: #ffdd45;
      margin-right: 5px;
    }

    &.reservation-circle {
      background: var(--from-color, #37e011);
      margin-right: 5px;
    }

    &.sameday-delivery-circle {
      background: var(--from-color, #37e011);
      margin-right: 5px;
    }

    &.from-carrige-circle {
      background: var(--from-light-color, #37e011);
      margin-right: 5px;
    }
  }
`;

export const StyledItemStats = styled.div`
  grid-area: item-footer;
  display: flex;
  width: 100%;
  height: 33px;
  margin-right: 8px;
  color: #9b9b9b;
  justify-content: space-between;

  .stat-with-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      display: inline-block;
    }
    span {
      font-size: 0.625rem;
      padding-left: 8px;
      padding-right: 40px;
    }
  }
`;

export const StyledItemReward = styled.div`
  grid-area: item-action;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  justify-content: center;
  .reward-status {
    display: flex;
    align-items: center;

    .profile-image {
      width: 20px;
      height: 20px;
      object-fit: cover;
      border-radius: 50%;
      // border: 2px solid var(--from-color, #37e011);
      z-index: 1;
    }

    span {
      font-size: 10px;
      font-weight: bold;
      padding-left: 8px;
    }
  }

  .reward-control-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    span {
      color: var(--from-color, #37e011);
      font-size: 10px;
      font-weight: bold;
    }

    .control-bar {
      position: relative;
      align-items: center;
      width: 75%;

      .control-bar-line {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        &.progress {
          height: 5px;
        }
        .progress-bar {
          background-color: var(--from-color, #37e011);
        }
      }

      .control-bar-label {
        z-index: 2;

        &::after {
          content: '';
          display: block;
          width: 11px;
          height: 11px;
          border-radius: 100%;
          margin-top: 0px;
          background-image: url('/images/radio-unchecked.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 11px;
          box-sizing: border-box;
        }

        &.label-left {
          position: absolute;
          padding: 5px;

          top: 50%;
          left: 0;
          transform: translate(-50%, -50%);
        }

        &.label-center {
          position: absolute;
          padding: 5px;

          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &.label-right {
          position: absolute;
          padding: 5px;
          top: 50%;
          right: 0;
          transform: translate(50%, -50%);
        }

        &.active {
          &::after {
            width: 20px;
            height: 20px;
            margin-top: 1px;
            background-image: url('/images/radio-checked.svg');
            background-size: 20px;
          }
        }
        .control-bar-radio {
          width: 0;
          height: 0;
          top: 0;
          left: 0;
          margin-top: 0;
          position: absolute;
        }
      }
    }
    .control-bar-value {
      color: var(--from-color, #37e011);
      font-size: 10px;
      font-weight: bold;
    }

    &.disabled-bar {
      span {
        color: #808080;
      }

      .control-bar {
        .control-bar-line {
          &.progress {
            border-radius: 0;
          }
          .progress-bar {
            background-color: #808080;
          }
        }

        .control-bar-label::after {
          visibility: hidden;
        }
      }

      .control-bar-value {
        color: #808080;
      }
    }
  }
`;
