import { useQuery, useInfiniteQuery } from 'react-query';

import fetchData from 'api/fetchData';
import { getStorePaymentList, getStorePaymentStatus } from 'api/store';

const STALE_TIME = 1000 * 60 * 10;
const ITEMS_PER_PAGE = 20;

const getOrderById = async (key, { paymentUid, storeId }) => {
  try {
    const data = await fetchData(getStorePaymentStatus, {
      paymentUid,
      storeId
    });

    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

const getOrders = async (
  key,
  { searchType, searchKey, itemsPerPage = ITEMS_PER_PAGE },
  LastEvaluatedKey
) => {
  try {
    // console.log({ LastEvaluatedKey });
    const params = {
      [searchType]: searchKey,
      pageCount: itemsPerPage,
      lastKey: LastEvaluatedKey
    };

    const func = searchType === 'storeId' ? getStorePaymentList : null;

    if (func === null)
      throw new Error(`Unknown searchType - ${searchType} !!!`);

    const data = await fetchData(func, params);

    // console.log({ data });

    return data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export function useOrder(orderId) {
  const { data, isLoading, isError, error } = useQuery(
    ['Order', orderId],
    getOrderById,
    {
      staleTime: STALE_TIME,
      retry: false
    }
  );

  return { data, isLoading, isError, error };
}

export function useInfiniteOrders(searchConditions) {
  const query = useInfiniteQuery(['Orders', searchConditions], getOrders, {
    getFetchMore: (lastPage) => {
      // console.log({ lastPage });
      return lastPage.LastEvaluatedKey;
    },
    staleTime: 0,
    retry: false
  });
  // console.log({ query });
  return query;
}
