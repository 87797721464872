import styled, { css } from 'styled-components';

export const StyledMain = styled.main`
  position: relative;
  width: 100%;
  /* padding-top: var(--header-height, 56px); */
  padding-bottom: 100px;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;

  @include min-width-desktop {
    width: 950px;
  }
`;
