import { config, CognitoIdentityCredentials } from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';

import cognitoConfig from './cognitoConfig';

const s3ProfileUpload = (file, userId, errorMessage) => {
  const { region } = cognitoConfig;

  return new Promise((resolve, reject) => {
    if (!file) {
      resolve('');

      return;
    }

    if (typeof file === 'string') {
      resolve(file);

      return;
    }

    config.update({
      region: cognitoConfig.region,
      credentials: new CognitoIdentityCredentials({
        IdentityPoolId: cognitoConfig.IdentityPoolId
      })
    }); // config

    config.credentials.get(() => {
      const { accessKeyId, secretAccessKey, sessionToken } = config.credentials;

      config.update({
        region: cognitoConfig.region,
        accessKeyId,
        secretAccessKey,
        sessionToken
      });

      const s3 = new S3({
        region,
        params: { Bucket: '1store.io.web.upload' }
      });

      const randomNumber = Math.floor(Math.random() * 100000);
      const randomNumbers = Math.random()
        .toString()
        .slice(2, randomNumber);

      const fileName = file.name.split('.');
      const fileType =
        fileName.length > 0 ? fileName[fileName.length - 1] : fileName;

      const changeFileName = `${new Date().getTime()}-${randomNumbers}.${fileType}`;
      const photoKey = '1s/' + changeFileName;

      s3.putObject(
        {
          Key: photoKey,
          Body: file,
          ACL: 'public-read',
          ContentType: file.type
        },
        (err) => {
          if (err) {
            err.errorMessage = errorMessage;

            reject(err);

            return;
          }

          const imagePath =
            'https://s3.ap-northeast-2.amazonaws.com/1store.io.web.upload/' +
            photoKey;

          resolve(imagePath);
        }
      );
    }); // get
  }); // promise
};

export default s3ProfileUpload;
