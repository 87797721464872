import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import CommonProfilePicture from 'components/picture/CommonProfilePicture';
import { getPriceFormat } from 'lib/utils/numberUtils';
import FollowButton from 'components/commons/FollowButton';

// import './OneStoreProfile.scss';
import { ProfileWrapper } from 'shared/styled/ProfileStyle';

function OneStoreProfile({
  storeId,
  storeName,
  isSanta,
  storeNo,
  isLoading,
  profileImgPath,
  isMyStore,
  balanceSavedMoney,
  isProvider,
  user,
  isLoggedIn,
  bookmarkedIdList
}) {
  if (isLoading) {
    return null;
  }

  return (
    <ProfileWrapper>
      {isSanta && (
        <img
          className="santa-hat"
          src="/images/santa/santa-hat2.png"
          alt="santa"
          width="25px"
        />
      )}
      <CommonProfilePicture
        profileImgPath={profileImgPath || ''}
        isMyProfile={isMyStore}
        name={storeName}
        isStore
        size={75}
        onDrawButton={() => {
          return (
            <Link
              to={`/store/${storeId}/setting/edit/profile`}
              className="onestore-setting"
            >
              설정
            </Link>
          );
        }}
      />
      <div className="profile-name">
        <div className="profile-name-value">{storeName}</div>
        <div className="profile-name-label">프롬이</div>
      </div>
      <div className="profile-right-menu">
        {isMyStore ? (
          <Link
            to={{
              pathname: `/store/${storeId}/setting/order`,
              state: { isRefresh: true }
            }}
            className="profile-edit-btn"
          >
            주문배송
          </Link>
        ) : (
          !isProvider && (
            <FollowButton
              user={user}
              isLoggedIn={isLoggedIn}
              bookmarkedIdList={bookmarkedIdList}
              targetType="1S"
              targetId={storeId}
              targetName={storeName}
              targetDetailInfo={{ storeNo, profileImgPath }}
            />
          )
        )}
        {isMyStore && (
          <Link
            to={`/store/${storeId}/setting/balance`}
            className="profile-savemoney"
          >
            {`${getPriceFormat(balanceSavedMoney)}원`}
            <img
              src="/images/onestore-profile-link-icon.svg"
              alt="화살표"
              className="profile-link-icon"
            />
          </Link>
        )}
      </div>
    </ProfileWrapper>
  );
}

export default OneStoreProfile;
