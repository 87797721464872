import React, { useState, useEffect, Fragment } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import { getProvider, getProviderOneStore } from 'api/provider';

import sentry from 'lib/sentry';
import withUi from 'lib/hoc/withUi';

// import AlertModal from 'components/modal/AlertModal';

import ProviderConnectStoreList from 'components/provider/ProviderConnectStoreList';

function ProviderOneStoreContainer({ providerId, UiAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [oneStoreList, setOneStoreList] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [lastKey, setLastKey] = useState(null);

  const [provider, setProvider] = useState({});

  useEffect(
    () => {
      UiAction.setHeader(
        provider
          ? `${provider.providerName} 응원자 자세히보기`
          : '응원자 자세히보기'
      );
    },
    [UiAction, provider]
  );

  useEffect(() => {
    getProviderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infiniteRef = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMoreItems,
    onLoadMore: getProviderOneStoreList,
    scrollContainer: 'window'
  });

  async function getProviderOneStoreList() {
    const params = {
      lastKey,
      pageCount: 20,
      providerId
    };

    try {
      setIsLoading(true);
      const response = await getProviderOneStore(params);

      if (!response || !response.data || !response.data.success) {
        throw new Error('error!');
      }

      const { data } = response.data;
      const { items } = data;

      setIsLoading(false);
      setLastKey(data.lastKey);

      setOneStoreList([...oneStoreList, ...items]);
      setHasMoreItems(!!data.lastKey);
    } catch (e) {
      // setIsLoading(false);
      sentry.captureException(e.message);
    }
  }

  async function getProviderInfo() {
    try {
      const response = await getProvider(providerId);

      if (!response || !response.data.data || !response.data.success) {
        throw new Error(
          `ProviderOnestore api Error(ProviderInfo) - providerId: ${providerId}`
        );
      }

      setProvider(response.data.data);
    } catch (e) {
      sentry.captureException(e);
    }
  }

  return (
    <Fragment>
      <ProviderConnectStoreList
        oneStoreList={oneStoreList}
        infiniteRef={infiniteRef}
        isLoading={isLoading}
        providerName={provider ? provider.providerName : ''}
      />
    </Fragment>
  );
}

export default compose(
  withUi,
  withRouter
)(ProviderOneStoreContainer);
