import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CommonModal from 'components/modal/CommonModal';

import './StopSellingModal.scss';

function StopSellingModal({
  className,
  contentText,
  buttonText,
  headerText,
  headerImage,
  isOpen,
  onClick,
  onToggle
}) {
  return (
    <CommonModal
      size="sm"
      className={classNames('stop-selling-modal', className)}
      isOpen={isOpen}
      onToggle={onToggle}
      onClickOk={onClick}
      headerText={headerText}
      headerImage={headerImage}
      okButtonText={buttonText}
      contentText={contentText}
      hasDecoration
      topDecoImgPath="/images/provider-crown-2.svg"
      sideDecoImgPath="/images/gift-decorate-star-icon.svg"
    />
  );
}

StopSellingModal.propTypes = {
  contentText: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func
};

StopSellingModal.defaultProps = {
  contentText: '',
  isOpen: false,
  onToggle: () => {}
};

export default StopSellingModal;
