import React from 'react';
import { Field } from 'redux-form';

import { required } from 'lib/form/validations';

import RenderFieldIncludeUnit from 'components/reduxForm/RenderFieldIncludeUnit';
import './DefaultRateField.scss';

function DefaultRateField({ isEditIncentive }) {
  return (
    <div className="mt-3">
      <span className="incentive-struct-label">기본 인센티브</span>
      <div className="form-description mt-2">
        <span className="text-left pr-1">
          *상품 옵션별 인센티브 설정은 다음단계(옵션 및 가격설정)에서 상세히
          입력하실 수 있습니다 :)
        </span>
      </div>
      <div className="p-2">
        <ul className="default-rate-form">
          <li>
            <span className="incentive-struc-form-label">구매 할인율</span>
            <Field
              className="form-incentive-input-width mb-0"
              name="defaultRate.discount"
              type="text"
              component={RenderFieldIncludeUnit}
              pattern={/[^0-9.]/g}
              validate={[required]}
              readOnly={!isEditIncentive}
              unit="%"
            />
          </li>
          <li>
            <span className="incentive-struc-form-label">소비자 적립율</span>
            <Field
              className="form-incentive-input-width mb-0"
              name="defaultRate.seller"
              type="text"
              component={RenderFieldIncludeUnit}
              pattern={/[^0-9.]/g}
              validate={[required]}
              readOnly={!isEditIncentive}
              unit="%"
            />
          </li>
          <li>
            <span className="incentive-struc-form-label">프롬</span>
            <Field
              className="form-incentive-input-width mb-0"
              name="defaultRate.cnx"
              type="text"
              component={RenderFieldIncludeUnit}
              pattern={/[^0-9.]/g}
              validate={[required]}
              readOnly={!isEditIncentive}
              unit="%"
            />
          </li>
          {/* <li className="text">
            <span className="incentive-struc-form-label">공급율</span>
            <div className="ml-auto">
              <span className="form-incentive-input form-incentive-provider mb-0">
                {`${Number.isNaN(provider) ? 0 : provider.toFixed(2)}`}
              </span>
              <span className="ml-1">%</span>
            </div>
          </li>
          <li>
            <span className="incentive-struc-form-label">전체</span>
            <div className="ml-auto">
              <span className="form-incentive-input form-incentive mb-0">
                {`${Number.isNaN(totalPercent) ? 0 : totalPercent.toFixed(2)}`}
              </span>
              <span className="ml-1">%</span>
            </div>
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default DefaultRateField;
