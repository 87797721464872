import React, { useRef, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { MentionsInput, Mention } from 'react-mentions';
import classNames from './MentionTextArea.module.css';
import defaultStyle from './defaultStyle';
import defaultMentionStyle from './defaultMentionStyle';
import { MentionTextAreaGrid } from './MentionTextAreaStyle';

import {
  FormControlGrid,
  StyledLabel,
  StyledError,
  ControlWrapper
} from '../styled/componentStyle';

function MentionTextArea({
  control,
  errors,
  rules,
  name,
  label,
  data,
  ...rest
}) {
  const inputRef = useRef();

  useEffect(() => {
    // console.log({ inputRef });
    inputRef.current.focus();
  }, []);

  console.log({ errors });
  return (
    <FormControlGrid>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <ControlWrapper>
        <Controller
          control={control}
          name={name}
          rules={rules}
          onFocus={() => inputRef.current.focus()}
          render={({ onChange, onBlur, value }) => {
            return (
              <MentionsInput
                inputRef={inputRef}
                className="mentions"
                classNames={classNames}
                // style={defaultStyle}
                id={name}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                {...rest}
              >
                <Mention
                  markup="@[__display__](user:__id__)"
                  trigger="@"
                  data={data}
                  // style={defaultMentionStyle}
                  className={classNames.mentions__mention}
                  renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay,
                    index,
                    focused
                  ) => (
                    <div className={`user ${focused ? 'focused' : ''}`}>
                      {highlightedDisplay}
                    </div>
                  )}
                />
              </MentionsInput>
            );
          }}
        />
      </ControlWrapper>
      <ErrorMessage errors={errors} name={name} as={StyledError} />
    </FormControlGrid>
  );
}

export default MentionTextArea;
