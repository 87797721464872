import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal, ModalBody } from 'reactstrap';

import './ModalDialog.scss';

function ModalDialog({
  className,
  backdropClassName,
  isOpen,
  labelledBy,
  size,
  onToggle,
  children
}) {
  return (
    <Modal
      className={classNames('os-modal', className)}
      backdropClassName={backdropClassName}
      isOpen={isOpen}
      labelledBy={labelledBy}
      size={size}
      toggle={onToggle}
      centered
    >
      <ModalBody>
        <button type="button" onClick={onToggle} className="modal-close-button">
          닫기
        </button>
        <div>{children}</div>
      </ModalBody>
    </Modal>
  );
}

ModalDialog.propTypes = {
  className: PropTypes.string,
  backdropClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  labelledBy: PropTypes.string,
  onToggle: PropTypes.func,
  size: PropTypes.string
};

ModalDialog.defaultProps = {
  className: '',
  backdropClassName: '',
  isOpen: false,
  labelledBy: '',
  onToggle: () => {},
  size: 'sm'
};

export default ModalDialog;
