import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SummaryText from 'components/commons/SummaryText';
// import CDN_URL from 'components/commons/globalUrl';

import { getImagePath } from 'lib/utils/imageUtils';

import './GiftMessage.scss';

function GiftMessage({
  product = {},
  option = {},
  buyer = {},
  order = {},
  receiver = {},
  isConfirmed
}) {
  const [isLongText, setIsLongText] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const buyerName = buyer.name || buyer.userName || '';
  const productName = product.name || product.productName || '';
  const optionName = option.name || '';
  const giftMessage = order.giftMessage || receiver.giftMessage || '';

  useEffect(() => {
    if (giftMessage.length > 50) setIsLongText(true);
  }, [giftMessage]);

  // console.log({ product, buyer, receiver, order });
  return (
    <Fragment>
      <div
        className="gift-message-background"
        style={{
          backgroundImage: `url("${
            getImagePath(product.officialProductImgPath, 250, 250) ||
            '/images/default-image.svg'
          }")`
        }}
      >
        <div className="gift-message-story">
          <div className="in-form">
            <div className="d-flex align-items-center">
              <div className="decorate-img-wrapper">
                <div
                  className="decorate-img"
                  style={{
                    backgroundImage: `url("/images/gift-decorate-star-icon.svg")`
                  }}
                />
              </div>
              <Link to={`/store/${buyer.id}`}>
                <div className="circle-profile-2">
                  {getImagePath(buyer.profileImgPath) ? (
                    <div
                      className="sender-img"
                      style={{
                        backgroundImage: `url("${
                          getImagePath(buyer.profileImgPath, 150, 150) ||
                          '/images/default-profile.svg'
                        }")`
                      }}
                    />
                  ) : (
                    <div className="store-list-item-text">
                      <div className="store-list-item-text-name">
                        {buyerName}
                      </div>
                    </div>
                  )}
                  <div className="circle-profile-small-icon">
                    <div
                      className="icon"
                      style={{
                        backgroundImage: `url("/images/gift-decorate-present-icon-green.svg")`
                        // backgroundImage: `url("/images/sulnal/sulnal-gift-icon.svg")`
                      }}
                    />
                  </div>
                </div>
              </Link>
              <div className="decorate-img-wrapper">
                <div
                  className="decorate-img"
                  style={{
                    backgroundImage: `url("/images/gift-decorate-star-icon.svg")`
                  }}
                />
              </div>
            </div>
            <div className="gift-title-msg-2">
              <span>{buyerName}님</span>의
              <br />
              {!isConfirmed ? '선물이 도착했어요!' : '선물입니다.'}
            </div>
            <Link to={`/store/${buyer.id}/${product.id}`}>
              <div className="gift-message-product-name">{`${productName} - ${optionName}`}</div>
            </Link>
            <div className="gift-title">
              <div className="d-inline-block gift-title-msg">
                From, {buyerName}
              </div>
            </div>
            <div className="gift-message-box">
              <div className="gift-message">
                <SummaryText
                  contentText={giftMessage}
                  contentTextSlideIndex={47}
                  isLongText={isLongText}
                  isShownDetail={showDetail}
                  onClick={() =>
                    setShowDetail((prevShowDetail) => !prevShowDetail)
                  }
                  defaultMessage={`${buyerName}님의 선물입니다.`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default GiftMessage;
