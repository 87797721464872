import React, { Fragment } from 'react';
import { formatMobileNumber } from 'lib/utils/utils';

import './ProviderServiceInfo.scss';

function ProviderServiceInfo({ provider = {} }) {
  const {
    email = '',
    picEmail = '',
    phone = '',
    mobile = '',
    picMobile = '',
    providerName = '',
    addr = '',
    addrSub = '',
    businessLicense = '',
    ceoName = ''
  } = provider;

  const providerMobile = phone || mobile || picMobile;
  const providerEmail = picEmail || email;
  return (
    <Fragment>
      <div className="provider-service-info-wrapper">
        <div className="provider-service-contact-info">
          <div className="provider-service-contact">
            {/* <div>상품 및 배송 문의</div> */}
            <a
              href={`tel:${providerMobile}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {formatMobileNumber(providerMobile, '-')}
            </a>
            <a
              href={`mailto:${providerEmail}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {providerEmail}
            </a>
          </div>
        </div>
        <div className="provider-service-provider-info">
          <div className="provider-service-provider-name">
            {providerName} 소개
          </div>
          <ul className="provider-info-help">
            {ceoName && (
              <li>
                <span className="title">대표자명</span>
                <span className="sub">{ceoName}</span>
              </li>
            )}
            {addr && (
              <li>
                <span className="title">주소</span>
                <span className="sub">
                  {addr} {addrSub}
                </span>
              </li>
            )}
            {businessLicense && (
              <li>
                <span className="title">사업자번호</span>
                <span className="sub">
                  {businessLicense.replace(/\D/g, '')}
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </Fragment>
  );
}

export default ProviderServiceInfo;
