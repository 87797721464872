import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import device from 'lib/device';

import './TextInput.scss';

class TextInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    isShownSubmitButton: PropTypes.bool,
    minHeight: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func
  };

  static defaultProps = {
    className: '',
    placeholder: '',
    minHeight: 35,
    isShownSubmitButton: false,
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onDragStart: () => {},
    onDrop: () => {}
  };

  constructor(props) {
    super(props);

    const { minHeight } = this.props;

    this.state = {
      height: minHeight,
      isOneLine: true
    };

    this.device = device();
  }

  componentDidMount = () => {
    this.changeTextInputHeght();
  };

  componentDidUpdate = (prevProps) => {
    const { value } = this.props;
    const { value: prevValue } = prevProps;

    if (value !== prevValue) {
      this.changeTextInputHeght();
    }
  };

  changeTextInputHeght = () => {
    const { ghostTextArea } = this;

    if (!ghostTextArea) {
      return;
    }

    const { minHeight } = this.props;
    const { clientHeight } = ghostTextArea;

    // eslint-disable-next-line
    // console.log({ clientHeight });

    this.setState({
      height: clientHeight,
      isOneLine: clientHeight <= minHeight
    });
  };

  /* 이벤트 함수  */

  handleOnChange = (e) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(e.target.value);
    }
  };

  handleOnKeyUp = () => {
    this.changeTextInputHeght();
  };

  handleKeyDown = (e) => {
    if (!e.keyCode || e.keyCode !== 13) {
      return;
    }

    if (e.shiftKey) {
      return;
    }

    if (e.ctrlKey) {
      const { onChange, value } = this.props;

      onChange(`${value}\n`);
      return;
    }

    const { onEnter } = this.props;

    if (onEnter) {
      onEnter();

      e.preventDefault();
    }
  };

  render() {
    const { height, isOneLine } = this.state;
    const {
      className,
      isShownSubmitButton,
      name,
      maxLength,
      minHeight,
      onSubmit,
      onFocus,
      onDragStart,
      onBlur,
      onDrop,
      placeholder,
      readOnly,
      value
    } = this.props;

    return (
      <div
        className={classNames(
          'text-input',
          { 'text-input-with-button': isShownSubmitButton },
          className
        )}
      >
        <div
          className="text-input-ghost"
          ref={(ghostTextArea) => {
            this.ghostTextArea = ghostTextArea;
          }}
          aria-hidden="true"
        >
          {value}
        </div>
        <textarea
          autoComplete="nope"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          className="text-input-textarea"
          name={name}
          maxLength={maxLength}
          onBlur={onBlur}
          onChange={this.handleOnChange}
          onDragStart={onDragStart}
          onDrop={onDrop}
          onFocus={onFocus}
          onKeyDown={this.device == 'Desktop' ? this.handleKeyDown : () => {}}
          onKeyUp={this.handleOnKeyUp}
          placeholder={placeholder}
          readOnly={readOnly}
          style={{
            minHeight: `${minHeight}px`,
            height: `${height}px`,
            resize: isOneLine ? 'none' : null
          }}
          value={value}
        />
        {isShownSubmitButton && (
          <button
            type="button"
            className="text-input-button text-primary"
            onClick={onSubmit}
          >
            완료
          </button>
        )}
      </div>
    );
  }
}

export default TextInput;
