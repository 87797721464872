import React from 'react';

import FooterProfile from './FooterProfile';
import FooterItem from './FooterItem';
import FooterDiscoverButton from './FooterDiscoverButton';

import { FooterItemsWrapper } from './FooterStyle';

function FooterItems({
  storeId,
  isLoggedIn,
  storeImage,
  selectedTab,
  onOpenDiscoverModal
}) {
  return (
    <FooterItemsWrapper>
      <ul>
        <FooterItem
          footerName="프롬마켓"
          footerImgSrc="footer-from-market-icon-2"
          isActive={selectedTab === 'market'}
          isLoggedIn={isLoggedIn}
          isProtected={false}
          to={{
            pathname: '/market/best',
            state: { isRefresh: true }
          }}
        />
        <FooterDiscoverButton
          footerName="발굴신청"
          footerImgSrc="footer-discover-button-icon-2"
          onOpenDiscoverModal={onOpenDiscoverModal}
          isLoggedIn={isLoggedIn}
          isActive={selectedTab === 'discover'}
        />
        <FooterProfile
          footerName={isLoggedIn ? '내 바구니' : '로그인'}
          isActive={selectedTab === 'store'}
          isLoggedIn={isLoggedIn}
          profileImgPath={storeImage}
          redirectUrl={{
            pathname: '/store/signin',
            state: {
              redirectUrl: `/market/best`
            }
          }}
          to={`/store/${storeId}`}
        />
        <FooterItem
          footerName="선물함"
          footerImgSrc="footer-gift-icon-2"
          isActive={selectedTab === 'gift'}
          isLoggedIn={isLoggedIn}
          redirectUrl={{
            pathname: '/store/signin',
            state: {
              redirectUrl: `/store/${storeId}/setting/giftbox/recv`
            }
          }}
          to={`/store/${storeId}/setting/giftbox/recv`}
        />
        <FooterItem
          footerName="알림"
          footerImgSrc="footer-notify-icon-2"
          isActive={selectedTab === 'notification'}
          isLoggedIn={isLoggedIn}
          redirectUrl={{
            pathname: '/store/signin',
            state: {
              redirectUrl: `/store/${storeId}/notification`
            }
          }}
          to={`/store/${storeId}/notification`}
        />
      </ul>
    </FooterItemsWrapper>
  );
}

export default FooterItems;
