import axios from './defaultAxios';

// 이메일 중복 체크
export function checkDuplicateEmail(email) {
  return axios.get('/store/signup', {
    params: {
      email
    }
  });
}

// 핸드폰 번호 중복 체크
export function checkDuplicateMobile(mobile) {
  return axios.get('/store/mobile', {
    params: {
      mobile
    }
  });
}

// 회원가입
export function signUpUser() {}

// 회원탈퇴
export function goodByeUser(params) {
  return axios.post(`/store/goodbye`, params);
}

// 로그인
export function signInStore(params) {
  return axios.get(`/store/signin`, { params });
}

// 세션 체크
export function reqCheckSession(params) {
  return axios.post('/auth', params);
}

// 소셜 로그인 시도 시 계정 존재하는지 확인
export function checkSnS(params) {
  return axios.get(`/store/signin/sns`, { params });
}

// 소셜 로그인이랑 기존 계정 매핑
export function mappingSns(values) {
  return axios.put(`/store/signin/sns/mapping`, values);
}

export function signinWithKakao(params) {
  return axios.get('/store/signin/sns/kakao', { params });
}

// 카카오 연결해제
export function disconnectKakao(values) {
  return axios.post('store/signin/sns/kakao/unlink', values);
}

// 아이디 찾기
export function findId(params) {
  return axios.get('/store/signin/find/id', { params });
}

// 비밀번호 찾기
export function findPassword(params) {
  return axios.get('/store/signin/find/pwd', { params });
}

// 상점 정보 가지고 오기
export function getStore(storeId) {
  return axios.get(`/store/${storeId}`);
}

// 성인 인증 업데이트 - 다날 본인 인증 성공 시에만 호출
export function putStoreAdultAuth(storeId) {
  return axios.put(`/store/${storeId}/adultauth`, {
    flagAdultAuth: 1, // 성인 인증 받았다는 뜻
    storeId
  });
}

// 상점주의 발굴 성공 목록(1S 노출용) 가져 오기
export function getStoreSuccessList(params) {
  return axios.get(`/store/${params.storeId}/home/discoverlist`, { params });
}

// 상점주의 from 리스트 가져오기
export function getStoreFromList(params) {
  return axios.get(`/store/${params.storeId}/home/fromlist`, { params });
}

// 상점주의 to 리스트 가져오기
export function getStoreToList(params) {
  return axios.get(`/store/${params.storeId}/home/tolist`, { params });
}

// 상점주의 발굴 갯수, from 수, to 수 가지고 오기
export function getStoreActivityCount(storeId) {
  return axios.get(`/store/${storeId}/home`);
}

// 상점주의 발굴 목록 가져 오기
export function getStoreDiscoveryList(params) {
  return axios.get(`/store/${params.storeId}/discovery`, { params });
}

// 상점주의 경험 리스트 가져오기
export function getStoreOneExperienceList(params) {
  return axios.get(`/store/${params.storeId}/1exps`, {
    params
  });
}

// 상점주의 1p 리스트 가져오기
export function getStoreOneProductsList(params) {
  return axios.get(`/store/${params.storeId}/1products`, {
    params
  });
}

// 상점주의 상품 리스트 가져오기
export function getStoreProductList(params) {
  return axios.get(`/store/${params.storeId}/home/productlist`, {
    params
  });
}

// 결제, 선물 페이지에서 적립금 불러오기
export function getStoreBalance(storeId) {
  return axios.get(`/store/${storeId}/balance`);
}

// 결제 내역 확인
export function getStorePaymentList(params) {
  return axios.get(`/store/${params.storeId}/payments`, {
    params
  });
}

// 결제 상세 내역 확인
export function getStorePaymentStatus(params) {
  return axios.get(`/store/${params.storeId}/payments/${params.orderId}`, {
    params: {
      orderId: params.orderId,
      paymentUid: params.paymentUid
    }
  });
}

// 적립금 내역 확인
export function getStoreBalanceHistory(params) {
  return axios.get(`/store/${params.storeId}/savedaccount`, {
    params
  });
}

// 받은 선물 내역
export function getStoreGiftReceiveList(params) {
  return axios.get(`/store/${params.storeId}/giftbox`, { params });
}

// 보낸 선물 내역
export function getStoreGiftSendList(params) {
  return axios.get(`/store/${params.storeId}/giftbox/send`, { params });
}

// 받을 푸쉬 알림 내역
export function getStorePushNotifyList(params) {
  return axios.get(`/store/${params.storeId}/notification`, { params });
}

// 로그아웃 처리
export function postLogOutUser(params) {
  return axios.post(`/store/signout`, params);
}

// 결제취소
export function postCancelPayment(params) {
  return axios.post(`/store/${params.storeId}/payments/cancel`, params);
}

// 구 북마크 -> 현 팔로우
// params: { storeId,
//           targetType,
//           pageCount,
//           lastKey }
export function getBookmark(params) {
  return axios.get(`/store/${params.storeId}/bookmarks/types`, { params });
}
