import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

SubmitButton.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  isDisable: PropTypes.bool,
  isLoading: PropTypes.bool
};

SubmitButton.defaultProps = {
  buttonText: '확인',
  className: '',
  color: 'primary',
  isDisable: false,
  isLoading: false
};

export default function SubmitButton({
  buttonText,
  className,
  color,
  isDisable,
  isLoading
}) {
  return (
    <button
      type="submit"
      className={classNames('button submit-button', color, className)}
      disabled={isDisable || isLoading}
      onClick={(e) => {
        if (e) {
          e.target.blur();
        }
      }}
    >
      {isLoading ? (
        <img
          src="/images/button-loader.svg"
          className="loading-spinner"
          alt="로딩 이미지"
        />
      ) : (
        <Fragment>{buttonText}</Fragment>
      )}
    </button>
  );
}
