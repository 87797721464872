import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import SavedMoneyStatusContainer from 'containers/setting/SavedMoneyStatusContainer';

function Discover({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <SavedMoneyStatusContainer storeId={match.params.storeId} />
    </Fragment>
  );
}

export default Discover;
