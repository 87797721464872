/* eslint-disable react/jsx-indent */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { useProducts } from 'hooks/useProducts';
import ProductCard from 'components/commons/ProductCard';
// import AlertModal from 'components/modal/AlertModal';

import { ItemSliderWrapper } from 'components/commons/ProductSlider/ItemSliderStyle';

function ProductSlider({
  className,
  title,
  subTitle,
  searchType,
  searchKey,
  filterBy,
  sortBy,
  onCardClick
}) {
  // console.log({ searchType });
  // console.log({ searchKey });
  const { data = {}, isLoading, isError, error } = useProducts({
    searchType,
    searchKey,
    itemsPerPage: 50
  });

  // console.log({ data });
  const items = data.items || data.Items || [];
  // console.log({ items });

  const filteredItems = !filterBy ? items : items.filter(filterBy);
  const products = !sortBy ? filteredItems : [...filteredItems.sort(sortBy)];

  return (
    <ItemSliderWrapper className={className}>
      <div className={`title ${className}`}>{title}</div>
      <div className="subtitle">{subTitle}</div>
      <div className="slider-container">
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>{error.message}</div>
        ) : (
          <Fragment>
            {products.length > 0 &&
              products.map((item) => {
                return onCardClick ? (
                  <ProductCard
                    key={item.id}
                    className={className}
                    onCardClick={onCardClick}
                    {...item}
                  />
                ) : (
                  <Link key={item.id} to={`/product/${item.id}`}>
                    <ProductCard className={className} {...item} />
                  </Link>
                );
              })}
          </Fragment>
        )}
        {/* <AlertModal
          type="errorModal"
          contentText={error.message}
          isOpen={isOpen}
          onClick={handleToggle}
          onToggle={handleToggle}
        /> */}
      </div>
    </ItemSliderWrapper>
  );
}

export default ProductSlider;
