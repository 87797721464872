import React, { Fragment } from 'react';

import { useInfiniteOneProducts } from 'hooks/useOneProducts';
import InfiniteList from 'shared/InfiniteList';

import OneProductCard from 'components/oneProduct/OneProductCard';

function OneProductList({
  searchType,
  searchKey,
  itemsPerPage,
  isOneStore,
  isMyStore
}) {
  const query = useInfiniteOneProducts({
    searchType,
    searchKey,
    itemsPerPage,
    isMyStore
  });

  const filterBy = !isMyStore ? (item) => item.flagPublic : null;

  return (
    <Fragment>
      <InfiniteList filterBy={filterBy} {...query}>
        {(item) => (
          <OneProductCard
            key={`${item.storeId}${item.productId}`}
            isOneStore={isOneStore}
            isMyStore={isMyStore}
            {...item}
          />
        )}
      </InfiniteList>
    </Fragment>
  );
}

export default OneProductList;
