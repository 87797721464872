import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import FromPickBanner from 'components/home/FromPickBanner';
import ProductList from 'components/product/ProductList';

import { MainSectionWrapper } from 'styles/AppStyle';
// import './MarketBestContainer.scss';

function MarketBestContainer({ isAllProducts }) {
  return (
    <MainSectionWrapper className="market">
      {!isAllProducts && <FromPickBanner bannerType="best" />}
      <ProductList
        title={`믿을 수 있는 내 친구의 바구니\n프롬`}
        subTitle={`소비자가 발굴하고 소개하고 응원합니다.`}
        listClassName="market-best-list"
      />
    </MainSectionWrapper>
  );
}

export default withRouter(MarketBestContainer);
