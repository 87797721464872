import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import * as productApi from 'api/product';

import getProductData from 'api/product/getProductDetail';

const INITIALIZE_PRODUCT = 'product/INITIALIZE_PRODUCT';
const GET_PRODUCT = 'product/GET_PRODUCT';
const SET_PRODUCT_COMMENT = 'product/SET_PRODUCT_COMMENT';
const GET_ONE_PRODUCTS_LIST_OF_PRODUCT =
  'product/GET_ONE_PRODUCTS_LIST_OF_PRODUCT';
const GET_ONE_PRODUCTS_AMOUNT_OF_PRODUCT =
  'product/GET_ONE_PRODUCTS_AMOUNT_OF_PRODUCT';
const CHANGE_COMMENT_TOTAL_COUNT = 'product/CHANGE_COMMENT_TOTAL_COUNT';

export const initializeProduct = createAction(INITIALIZE_PRODUCT);
export const setProductComment = createAction(SET_PRODUCT_COMMENT);
export const getProduct = createAction(GET_PRODUCT, getProductData);

export const getOneProductsListOfProduct = createAction(
  GET_ONE_PRODUCTS_LIST_OF_PRODUCT,
  productApi.getOneProductsListOfProduct
);
export const getOneProductsAmountOfProduct = createAction(
  GET_ONE_PRODUCTS_AMOUNT_OF_PRODUCT,
  productApi.getOneProductsAmountOfProduct
);
export const changeCommentTotalCount = createAction(CHANGE_COMMENT_TOTAL_COUNT);

const initialState = {
  product: null,
  oneProductOfProduct: null,
  product1PCount: null,
  flagChangeComment: false
};

export default handleActions(
  {
    [INITIALIZE_PRODUCT]: (state) => {
      return {
        ...state,
        commentIndex: 0,
        product: null,
        productRecommandation: null,
        oneProductOfProduct: null,
        product1PCount: null
      };
    },
    [CHANGE_COMMENT_TOTAL_COUNT]: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          countOfComment: action.payload
        }
      };
    },
    [SET_PRODUCT_COMMENT]: (state) => {
      const { flagChangeComment } = state;
      return {
        ...state,
        flagChangeComment: !flagChangeComment
      };
    },
    ...pender({
      type: GET_PRODUCT,
      onSuccess: (state, action) => {
        const { allVisbleOption } = action.payload;
        const { data, success } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            product: null
          };
        }

        // 옵션 모두 공개
        if (allVisbleOption) {
          return {
            ...state,
            product: data
          };
        }

        return {
          ...state,
          product: {
            ...data,
            options: data.options && data.options.filter((op) => op.isOnDisplay === true)
          }
        };
      }
    }),
    ...pender({
      type: GET_ONE_PRODUCTS_LIST_OF_PRODUCT,
      onSuccess: (state, action) => {
        const { data, success } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            oneProductOfProduct: null
          };
        }

        return {
          ...state,
          oneProductOfProduct: data
        };
      }
    }),
    ...pender({
      type: GET_ONE_PRODUCTS_AMOUNT_OF_PRODUCT,
      onSuccess: (state, action) => {
        const { data, success } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            product1PCount: null
          };
        }

        return {
          ...state,
          product1PCount: data
        };
      }
    })
  },
  initialState
);
