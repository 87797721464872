/* eslint-disable no-console */
import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';

// eslint-disable-next-line no-unused-vars
import { getCalcPriceObject, getTotalPrice } from 'lib/utils/newPriceUtils';

import { getPriceFormat, parseFloatFixed } from 'lib/utils/numberUtils';

import OptionCard from 'components/commons/OptionCard';

import {
  ProductOptionsWrapper,
  OptionDetailWrapper
} from './ProductOptionsStyle';

function ProductOptions({
  productName,
  options,
  basicDeliveryFee,
  flagDelivery,
  onePSaveStruct,
  officialProductImgPath,
  selectOptionIndex,
  onChangeOptionIndex,
  children
}) {
  const location = useLocation();
  const selectedOptionObject = getCalcPriceObject(
    options ? options[selectOptionIndex] : {}
  );
  const {
    discountedPrice,
    price: defaultPrice,
    percentRate,
    sellerPrice: saveSumPrice
  } = selectedOptionObject;
  const { discount: discountPercent, seller: saveSumPercent } =
    percentRate || {};
  const toSavePercent = onePSaveStruct ? onePSaveStruct.toSave : 1;
  const totalPrice = discountedPrice + basicDeliveryFee;

  const product = { officialProductImgPath };
  const isDeliveryProduct = flagDelivery === 1;
  // console.log({ location, selectedOptionObject });

  return (
    <ProductOptionsWrapper>
      <span className="thick-border-label">상품 옵션</span>
      <div className="product-name">{productName}</div>
      <div className="card-slider-container">
        {options &&
          options.map((option, index) => {
            if (!option.isOnDisplay) {
              return;
            }

            return (
              <OptionCard
                key={option.id}
                className={`${
                  selectOptionIndex === index
                    ? 'small-option selected'
                    : 'small-option'
                }`}
                index={index}
                product={product}
                option={option}
                onCardClick={() => {
                  // console.log({ selectOptionIndex });
                  // console.log({ index });
                  if (selectOptionIndex !== index) {
                    onChangeOptionIndex(index);
                  }
                }}
              />
            );
          })}
      </div>
      <OptionDetailWrapper>
        <div className="option-price">
          <span className="option-price-label">정가</span>
          <div className="option-price-value">
            <span
              className={classNames({
                cancel: discountedPrice < defaultPrice
              })}
            >
              {`${getPriceFormat(defaultPrice)}원`}
            </span>
          </div>
        </div>
        {discountedPrice < defaultPrice && (
          <div className="option-price">
            <span className="option-price-label">
              {`할인가 (${parseFloatFixed(discountPercent)}%)`}
            </span>
            <div className="option-price-value">
              {`${getPriceFormat(discountedPrice)}원`}
            </div>
          </div>
        )}
        {isDeliveryProduct && (
          <div className="option-price">
            {basicDeliveryFee <= 0 ? (
              <span className="option-price-label">무료배송</span>
            ) : (
              <Fragment>
                <span className="option-price-label">배송비</span>
                <div className="option-price-value">
                  {`${getPriceFormat(basicDeliveryFee)}원`}
                </div>
              </Fragment>
            )}
          </div>
        )}
        <div className="option-price payment-amount">
          <span className="option-price-label ">결제할 금액</span>
          <div className="option-price-value">
            {`${getPriceFormat(totalPrice)}원`}
          </div>
        </div>
        {saveSumPrice > 0 && (
          <div className="option-price incentive-amount">
            <span className="option-price-label ">
              {`선물받을 적립금 (${parseFloatFixed(
                saveSumPercent * toSavePercent
              )}%)`}
            </span>
            <div className="option-price-value">
              {`+${getPriceFormat(saveSumPrice * toSavePercent)}원`}
            </div>
          </div>
        )}{' '}
      </OptionDetailWrapper>
      {children}
    </ProductOptionsWrapper>
  );
}

export default ProductOptions;
