import { createAction, handleActions } from 'redux-actions';
import { pender } from 'redux-pender';

import * as oneProductApi from 'api/oneProduct';

const INITIALIZE_ONEPRODUCT = 'oneProduct/INITIALIZE_ONEPRODUCT';

const GET_ONE_PRODUCT = 'oneProduct/GET_ONE_PRODUCT';
const GET_ONE_PRODUCT_RECOMMENDATION =
  'oneProduct/GET_ONE_PRODUCT_RECOMMENDATION';

// 1expereince
const GET_ONE_PRODUCT_EXPERIENCE = 'oneProduct/GET_ONE_PRODUCT_EXPERIENCE';
const ADD_ONE_PRODUCT_EXPERIENCE = 'oneProduct/ADD_ONE_PRODUCT_EXPERIENCE';
const DELETE_ONE_PRODUCT_EXPERIENCE =
  'oneProduct/DELETE_ONE_PRODUCT_EXPERIENCE';

// ui Event
const CHANGE_COMMENT_TOGGLE = 'oneProduct/CHANGE_COMMENT_TOGGLE';
const CHANGE_COMMENT_TOTAL_COUNT = 'oneProduct/CHANGE_COMMENT_TOTAL_COUNT';
const CHANGE_EXPERIENCE_SLIDE = 'ondProduct/CHANGE_EXPERIENCE_SLIDE';

const PUT_ONE_PRODUCT_PUBLIC = 'oneProduct/PUT_ONE_PRODUCT_PUBLIC';
const PUT_ONE_PRODUCT_INCENTIVE = 'oneProduct/PUT_ONE_PRODUCT_INCENTIVE';

const PUT_ONE_PRODUCT_RECOMMENDATION =
  'oneProduct/PUT_ONE_PRODUCT_RECOMMENDATION';
const PUT_ONE_PRODUCT_DEFAULT_EXPERIENCE =
  'oneProduct/PUT_ONE_PRODUCT_DEFAULT_EXPERIENCE';

// 1p
export const initializeOneProduct = createAction(INITIALIZE_ONEPRODUCT);
export const getOneProduct = createAction(
  GET_ONE_PRODUCT,
  oneProductApi.getOneProduct
);
export const getOneProductRecommendation = createAction(
  GET_ONE_PRODUCT_RECOMMENDATION,
  oneProductApi.getRecommendation
);

export const getOneProductExperience = createAction(
  GET_ONE_PRODUCT_EXPERIENCE,
  oneProductApi.getOneProductExperience
);

export const putOneProductPublic = createAction(
  PUT_ONE_PRODUCT_PUBLIC,
  oneProductApi.putOneProductPublic
);

export const putOneProductIncentive = createAction(
  PUT_ONE_PRODUCT_INCENTIVE,
  oneProductApi.putOneProductIncentive
);

export const addOneProductExperience = createAction(ADD_ONE_PRODUCT_EXPERIENCE);
export const deleteOneProductExperience = createAction(
  DELETE_ONE_PRODUCT_EXPERIENCE
);
export const changeCommentToggle = createAction(CHANGE_COMMENT_TOGGLE);
export const changeSlide = createAction(CHANGE_EXPERIENCE_SLIDE);
export const changeCommentTotalCount = createAction(CHANGE_COMMENT_TOTAL_COUNT);
export const putOneProductRecommedation = createAction(
  PUT_ONE_PRODUCT_RECOMMENDATION
);
export const putOneProductDefaultExperience = createAction(
  PUT_ONE_PRODUCT_DEFAULT_EXPERIENCE
);

const initialState = {
  commentTotalCount: 0,
  oneProduct: null,
  // ui state
  isShownCommentList: true,
  activeSlide: -1,

  // expereince list
  experienceImageList: [],
  nowExperienceImage: {}
};

export default handleActions(
  {
    [INITIALIZE_ONEPRODUCT]: (state) => {
      return {
        ...state,
        activeSlide: -1,
        commentTotalCount: 0,
        experienceImageList: [],
        isShownCommentList: true,
        nowExperienceImage: {},
        oneProduct: null
      };
    },
    [CHANGE_COMMENT_TOGGLE]: (state, action) => {
      return {
        ...state,
        isShownCommentList: action.payload
      };
    },
    [CHANGE_COMMENT_TOTAL_COUNT]: (state, action) => {
      return {
        ...state,
        commentTotalCount: action.payload
      };
    },
    [CHANGE_EXPERIENCE_SLIDE]: (state, action) => {
      return {
        ...state,
        activeSlide: action.payload.activeSlide,
        nowExperienceImage: action.payload.nowExperienceImage
      };
    },
    [PUT_ONE_PRODUCT_RECOMMENDATION]: (state, action) => {
      return {
        ...state,
        oneProduct: {
          ...state.oneProduct,
          storeRecommendation: action.payload
        }
      };
    },
    [PUT_ONE_PRODUCT_DEFAULT_EXPERIENCE]: (state, action) => {
      return {
        ...state,
        oneProduct: {
          ...state.oneProduct,
          productImgPath: action.payload.productImgPath,
          representationExpId: action.payload.representationExpId
        }
      };
    },
    [ADD_ONE_PRODUCT_EXPERIENCE]: (state, action) => {
      const { experienceImageList } = state;

      return {
        ...state,
        experienceImageList: [...experienceImageList, ...action.payload]
      };
    },
    [DELETE_ONE_PRODUCT_EXPERIENCE]: (state, action) => {
      const { experienceImageList } = state;

      return {
        ...state,
        experienceImageList: [
          ...experienceImageList.slice(0, action.payload),
          ...experienceImageList.slice(action.payload + 1)
        ]
      };
    },
    ...pender({
      type: GET_ONE_PRODUCT,
      onSuccess: (state, action) => {
        const { data, success } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            oneProduct: null
          };
        }

        const { storeName, productImgPathList } = data;

        data.oneProductHeader = `${storeName}님이 응원해요`;
        // 준범, 8/28 1P상품이미지 Path stirng-> object 변경
        // data.productImgPath = '';

        if (productImgPathList && productImgPathList.length > 0) {
          [data.productImgPath] = productImgPathList;
        }

        return {
          ...state,
          oneProduct: data
        };
      }
    }),
    ...pender({
      type: GET_ONE_PRODUCT_EXPERIENCE,
      onSuccess: (state, action) => {
        const { success, data } = action.payload.data;

        if (!success || !data) {
          return {
            ...state,
            experienceImageList: []
          };
        }

        return {
          ...state,
          experienceImageList: data.items
        };
      },
      onFailure: (state) => {
        return { ...state, experienceImageList: [] };
      }
    })
  },
  initialState
);
