import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import DiscoverDetailContainer from 'components/discover/DiscoverDetailContainer';

function Discover() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <DiscoverDetailContainer />
    </Fragment>
  );
}

export default Discover;
