import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as userActions from 'modules/user';

const withUser = (Component) => {
  const WithUser = (props) => <Component {...props} />;

  return connect(
    ({ user }) => ({
      isLoggedIn: user.isLoggedIn,
      isProvider: user.isProvider,
      user: user.user,
      isSignedKakao: user.isSignedKakao,
      socialInfo: user.socialInfo
    }),
    (dispatch) => ({
      UserActions: bindActionCreators(userActions, dispatch)
    })
  )(WithUser);
};

export default withUser;
