import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as uiActions from 'modules/ui';

const withUi = (Component) => {
  const WithUi = (props) => <Component {...props} />;

  return connect(
    ({ ui }) => ({
      headerTitle: ui.headerTitle,
      showHeader: ui.showHeader
    }),
    (dispatch) => ({
      UiAction: bindActionCreators(uiActions, dispatch)
    })
  )(WithUi);
};

export default withUi;
