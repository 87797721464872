import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import Agreement from 'components/users/Agreement';
import Button from 'components/commons/Button';
import RenderField from 'components/reduxForm/RenderField';
import RenderFieldTextArea from 'components/reduxForm/RenderFieldTextArea';
import PictureInput from 'components/commons/PictureInput';
import AddressModal from 'components/modal/AddressModal';

import { required, tel, blankCheck } from 'lib/form/validations';

import './MemberEditForm.scss';

function MemberEditForm({
  flagAgreeMarketing,
  handleSubmit,
  isLoading,
  isOpenSearchAddress,
  onChangeAddress,
  onChangeCheckbox,
  onChangeEmail,
  onChangeImage,
  // onClickBack,
  onOpenAgreementModal,
  onSubmit,
  onToggle,
  profileImgPath
}) {
  return (
    <Fragment>
      <div className="member-edit-form pt-3 pl-3 pr-3">
        <div className="form-headline">회원정보 수정하기</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field
            name="userName"
            autocomplete="name"
            label="이름"
            type="text"
            component={RenderField}
            placeholder="이름"
            validate={[required, blankCheck]}
            isRequired
          />
          <Field
            name="mobile"
            type="tel"
            component={RenderField}
            label="휴대폰"
            placeholder="- 없이 숫자만 입력"
            pattern={/[^0-9]/g}
            validate={[required, tel]}
            isRequired
          />
          <Field
            name="email"
            label="이메일"
            type="email"
            component={RenderField}
            onChange={onChangeEmail}
            placeholder="이메일"
            validate={[required]}
            readOnly
            isRequired
          />

          <div>
            <div className="form-group clearfix">
              <label
                className="form-label form-picture-label float-left text-left pr-3"
                htmlFor="profile-image"
              >
                프로필 사진
              </label>
              <div className="float-left">
                <PictureInput
                  imageFile={profileImgPath}
                  onChange={(imageFile) => {
                    onChangeImage(imageFile);
                  }}
                  memberEdit
                />
              </div>
            </div>
          </div>
          <Field
            name="zipcode"
            type="text"
            className="form-with-button"
            component={RenderField}
            label="우편번호"
            placeholder="우편번호"
            onFocus={onToggle}
            readOnly
          >
            <button
              type="button"
              className="form-button btn-primary form-input-in-button"
              onClick={onToggle}
            >
              찾기
            </button>
          </Field>
          <Field
            name="addr"
            type="text"
            className="received-gift-addr"
            component={RenderFieldTextArea}
            minHeight={39}
            label="주소"
            placeholder="주소"
            readOnly
            onFocus={onToggle}
          />
          <Field
            name="addrSub"
            type="text"
            component={RenderField}
            label="상세주소"
            placeholder="상세주소"
          />
          <div className="agreement-form">
            <h3 className="form-headline mt-4 mb-4">이용약관동의</h3>
            <Agreement
              checkboxName="flagAgreeMarketing"
              className="mt-2"
              labelText={
                <Fragment>
                  <span>[선택]</span> 마케팅 동의
                </Fragment>
              }
              isCheckedAgreement={flagAgreeMarketing}
              onChangeCheckbox={() => {
                // eslint-disable-next-line
                // console.log(onChangeCheckbox);
                onChangeCheckbox('flagAgreeMarketing');
              }}
              onClickButton={() => {
                onOpenAgreementModal('/terms/terms-marketing.html');
              }}
            />
          </div>
          <div className="excavation-form-buttons mt-5 text-center">
            {/* <Button
              buttonText="취소"
              buttonType="button"
              className="btn-request"
              color="red"
              onClick={onClickBack}
            /> */}
            <Button
              buttonText="수정"
              buttonType="submit"
              color="from"
              isDisable={isLoading}
              isLoading={isLoading}
            />
          </div>
        </form>
        <AddressModal
          isOpen={isOpenSearchAddress}
          onToggle={onToggle}
          onSelect={onChangeAddress}
        />
      </div>
    </Fragment>
  );
}
MemberEditForm.propTypes = {
  flagAgreeMarketing: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOpenSearchAddress: PropTypes.bool,
  onChangeAddress: PropTypes.func,
  onChangeCheckbox: PropTypes.func,
  onChangeEmail: PropTypes.func,
  onChangeImage: PropTypes.func,
  // onClickBack: PropTypes.func,
  onOpenAgreementModal: PropTypes.func,
  onSubmit: PropTypes.func,
  onToggle: PropTypes.func,
  profileImgPath: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
};

MemberEditForm.defaultProps = {
  flagAgreeMarketing: false,
  isLoading: false,
  isOpenSearchAddress: false,
  onChangeAddress: () => {},
  onChangeCheckbox: () => {},
  onChangeEmail: () => {},
  onChangeImage: () => {},
  // onClickBack: () => {},
  onOpenAgreementModal: () => {},
  onSubmit: () => {},
  onToggle: () => {},
  profileImgPath: null || ''
};

export default reduxForm({
  form: 'memberEditForm'
})(MemberEditForm);
