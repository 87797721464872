import axios from 'axios';

/**
 *  택배 운송장 번호 조회 api
 */

const url = 'https://info.sweettracker.co.kr';

const key = 'zjQK4qL87Fn4q2vzKBlg8g'; // 23.5.6.~

export default (deliveryCode, invoiceNumber) => {
  const noHyphenNumber = invoiceNumber.replace(/\D/g, ''); // 하이픈(-) 제거

  return axios.get(`${url}/api/v1/trackingInfo`, {
    params: {
      t_key: key,
      t_code: deliveryCode,
      t_invoice: noHyphenNumber
    }
  });
};
