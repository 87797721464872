import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProviderSalesListContainer from 'containers/provider/ProviderSalesListContainer';

function ProviderSales() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ProviderSalesListContainer />
    </Fragment>
  );
}

export default ProviderSales;
