import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { required, checkEmailType, tel } from 'lib/form/validations';

import Button from 'components/commons/Button';
import RenderField from 'components/reduxForm/RenderField';

import 'components/users/SignInHelpId/SignInHelpId.scss';

function SignInHelpPassword({
  handleSubmit,
  isLoading,
  invalid,
  onSubmit,
  pristine,
  submitting
}) {
  return (
    <form className="signin-help" onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="userName"
        type="text"
        component={RenderField}
        isRequired
        label="이름"
        placeholder="이름"
        validate={[required]}
        pattern={/\s/g}
      />
      <Field
        name="mobile"
        type="tel"
        component={RenderField}
        isRequired
        label="휴대폰"
        placeholder="- 없이 숫자만 입력"
        pattern={/[^0-9]/g}
        validate={[required, tel]}
      />
      <Field
        name="email"
        type="email"
        component={RenderField}
        isRequired
        label="이메일"
        placeholder="이메일"
        pattern={/\s/g}
        validate={[required, checkEmailType]}
      />
      <div className="signin-help-buttons">
        <Button
          buttonText="확인"
          buttonType="submit"
          color="from"
          isLoading={isLoading}
          isDisable={invalid || isLoading || pristine || submitting}
        />
        <Button
          buttonText="취소"
          buttonType="link"
          className="mt-3"
          color="close-from"
          to="/store/signin"
        />
      </div>
    </form>
  );
}

SignInHelpPassword.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func
};

SignInHelpPassword.defaultProps = {
  isLoading: false,
  onSubmit: () => {}
};

export default reduxForm({
  form: 'helpPasswordForm'
})(SignInHelpPassword);
