import styled from 'styled-components';

export const ToggleListButtonStyle = styled.div`
  margin: 75px auto 16px auto;
  padding: 0 1rem;
  max-width: 420px;

  .thick-border-label {
    display: inline-block;
    font-weight: 800;
    font-size: 17px;
    color: var(--from-color);
    border-bottom: 5px solid var(--from-color);
    padding-bottom: 7px;
    margin-bottom: 18px;
  }

  button {
    font-size: 22px;
    font-weight: 800;

    position: relative;
    display: block;
    width: 100%;
    padding: 0 40px 23px 0;
    text-align: left;
    line-height: 1.8;
    word-break: keep-all;
    border-bottom: 1px solid var(--border-color);

    /* &:before {
      position: absolute;
      content: '';
      display: block;
      width: 17px;
      height: 10px;
      right: 10px;
      bottom: 31px;
      background-image: url('/images/more-button-2.svg');
      background-repeat: no-repeat;
    } */
    &:after {
      position: absolute;
      content: '';
      display: inline-block;
      width: 17px;
      height: 10px;
      right: 10px;
      bottom: 31px;
      background-image: url('/images/more-button-2.svg');
      background-repeat: no-repeat;
    }

    &.active {
      &:after {
        background-image: url('/images/more-button-close-2.svg');
      }
    }
  }

  &.category-product {
    margin: 32px auto 16px auto;

    button {
      font-size: 16px;
      font-weight: 800;
      span {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  &.payment {
    margin: 0px auto 0px auto;
    padding: 0px 20px;

    button {
      font-size: 16px;
      font-weight: 800;
      padding: 0 40px 10px 0;

      border-bottom: none;
      span {
        font-size: 14px;
        font-weight: bold;
      }

      &:after {
        bottom: 20px;
      }
    }
  }

  &.setting-menu-toggle {
    margin: 1.5rem auto 0 auto;
    padding: 0;

    button {
      font-size: 14px;
      font-weight: 800;

      padding: 0 1rem 1.5rem 1rem;
      border-bottom: 1px solid var(--border-color);
      &:after {
        right: 1rem;
        top: 5px;
      }

      &.active {
        border-bottom: 0;
      }
    }
  }
`;

export const ToggleListWrapper = styled.div`
  &.payment {
    padding: 0px 20px;
  }
`;
