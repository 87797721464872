import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isDevelop } from 'lib/utils/envUtils';
import CDN_URL from 'components/commons/globalUrl';

const IS_DEVELOP = isDevelop();

export default function CategoryItem({
  name,
  image,
  type,
  to,
  noRadius,
  onClickTag
}) {
  const component = (
    <Fragment>
      <img
        className={classNames('search-category-item-image', {
          'category-item-image-noradius': noRadius

          // 'border border-from-color': type === 'link'
        })}
        src={`${CDN_URL}${image}` || '/images/default-image.svg'}
        alt="카테고리 이미지"
      />
      <div className="search-category-item-name">{name}</div>
    </Fragment>
  );

  if (type === 'link') {
    return (
      <Link
        className="search-category-item"
        to={IS_DEVELOP ? '/product/p1552464056463' : to}
      >
        {component}
      </Link>
    );
  }
  return (
    <button className="slide-search-category-item" onClick={onClickTag}>
      {component}
    </button>
  );
}
