import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';

import RenderField from 'components/reduxForm/RenderField';
import { required, minLength6, maxLength16 } from 'lib/form/validations';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import Loader from 'components/commons/Loader';
import Button from 'components/commons/Button';

import useModal, { useSimpleDialog } from 'hooks/useModal';
import ConfirmModal from 'components/modal/ConfirmModal';
import AlertModal from 'components/modal/AlertModal';

import { goodByeUser } from 'api/store';

function GoodByeForm({ storeId, isLoggedIn, handleSubmit, handleSignOut }) {
  const [isLoading, setIsLoading] = useState(false);

  const {
    message: successMessage,
    isOpen: isOpenSuccessModal,
    handleToggle: toggleSuccessModal
  } = useModal();

  const {
    message: alertMessage,
    isOpen: isOpenAlertModal,
    handleToggle: toggleAlertModal
  } = useModal();

  const {
    isOpenDialog,
    handleToggleDialog,
    handleConfirm,
    handleDecline,
    isConfirmed
  } = useSimpleDialog();

  // useEffect(() => {
  //   console.log({ isConfirmed, isLoggedIn });
  //   if (isConfirmed === true && isLoggedIn === true) {
  //     handleSubmit(onSubmit);
  //   }
  // }, [isConfirmed]);

  const onSuccess = () => {
    toggleSuccessModal();
    handleSignOut();
  };
  const onSubmit = async ({ oldPwd }) => {
    try {
      setIsLoading(true);
      if (!isLoggedIn) throw new Error('탈퇴실패');
      const response = await goodByeUser({ oldPwd, storeId, isLoggedIn });
      console.log({ response });
      if (!response?.data?.success) {
        throw new Error('탈퇴실패');
      }
      toggleSuccessModal('탈퇴되었습니다.\n 감사합니다!.');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toggleAlertModal('탈퇴에 실패했습니다.\n 잠시 후 시도해 주세요.');
    }
  };

  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className="pwd-edit-form pt-3 pl-3 pr-3">
        <div className="form-headline">회원 탈퇴하기</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field
            name="oldPwd"
            type="password"
            label="현재 비밀번호"
            component={RenderField}
            placeholder="현재 비밀번호"
            validate={[required, minLength6, maxLength16]}
            isRequired
          />
          <div className="excavation-form-buttons mt-5 text-center">
            <Button
              buttonText={'회원 탈퇴하기'}
              buttonType="submit"
              // onClick={handleToggleDialog}
              className="btn-pwd-edit"
              color="from"
              isDisable={isLoading}
              isLoading={isLoading}
            />
          </div>
        </form>
      </div>
      <ConfirmModal
        headerText="탈퇴 확인"
        headerImage="/images/header-image/sori-header-image.svg"
        contentText={`탈퇴하시겠습니까?`}
        isOpen={isOpenDialog}
        okButtonText="탈퇴하기"
        closeButtonText="취소하기"
        onClickOk={handleConfirm}
        onToggle={handleDecline}
      />
      <AlertModal
        contentText={successMessage}
        isOpen={isOpenSuccessModal}
        onToggle={onSuccess}
      />

      <AlertModal
        contentText={alertMessage}
        isOpen={isOpenAlertModal}
        onToggle={toggleAlertModal}
      />
    </Fragment>
  );
}

export default reduxForm({
  form: 'goodByeForm'
})(GoodByeForm);
