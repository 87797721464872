import React, { Fragment } from 'react';
import { CookiesProvider } from 'react-cookie';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import SignInContainer from 'containers/users/SignInContainer';

function SignIn() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <CookiesProvider>
        <SignInContainer />
      </CookiesProvider>
    </Fragment>
  );
}

export default SignIn;
