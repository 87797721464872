import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import Button from 'components/commons/Button';
import DiscoverField from 'components/discover/DiscoverField';

import './DiscoverForm.scss';

function DiscoverForm({
  handleSubmit,
  haveProductImages,
  invalid,
  isSubmitLoading,
  onClickBack,
  onSelectProductImage,
  onOpenProductModal,
  onOpenProviderModal,
  onRemovePicture,
  pristine,
  productImageList,
  submitting
}) {
  return (
    <form onSubmit={handleSubmit} className="discover-form mt-3 mb-3 pl-3 pr-3">
      <DiscoverField
        haveProductImages={haveProductImages}
        onOpenProductModal={onOpenProductModal}
        onOpenProviderModal={onOpenProviderModal}
        onSelectProductImage={onSelectProductImage}
        onRemovePicture={onRemovePicture}
        productImageList={productImageList}
        isPossibleEdit
      />
      <div className="discover-form-buttons mt-5 text-center">
        <Button
          buttonText="발굴요청"
          buttonType="submit"
          color="from"
          isDisable={invalid || isSubmitLoading || pristine || submitting}
          isLoading={isSubmitLoading}
        />
        <Button
          buttonText="취소"
          buttonType="button"
          className="mt-3"
          color="close-from"
          onClick={onClickBack}
        />
      </div>
    </form>
  );
}

DiscoverForm.propTypes = {
  haveProductImages: PropTypes.bool,
  isSubmitLoading: PropTypes.bool,
  onSelectProductImage: PropTypes.func,
  onOpenProductModal: PropTypes.func,
  onRemovePicture: PropTypes.func,
  productImageList: PropTypes.arrayOf(PropTypes.shape({}))
};

DiscoverForm.defaultProps = {
  haveProductImages: false,
  isSubmitLoading: false,
  onSelectProductImage: () => {},
  onOpenProductModal: () => {},
  onRemovePicture: () => {},
  productImageList: []
};

export default reduxForm({
  form: 'discoverForm'
})(DiscoverForm);
