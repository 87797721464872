import styled, { css } from 'styled-components';

export const ItemsWrapper = styled.ul`
  max-width: var(--max-content-width, 420px);
  margin: 0px auto;
`;

export const StyledItem = styled.li`
  display: grid;
  max-width: 640px;
  grid-template-columns: 25px 75px minmax(180px, 1fr) 25px;
  grid-template-rows: repeat(5, auto);
  column-gap: 5px;
  row-gap: 5px;
  align-items: center;
  justify-items: start;
  /* margin: 20px 10px 0px 10px; */
  color: var(--text-color, black);
  padding: 20px 20px 30px 20px;
  border-bottom: solid 1px var(--border-color, #f5f4f4);

  &:last-child {
    border-bottom: none;
  }

  grid-template-areas:
    'item-header1 item-header2 item-header2 item-header3'
    ' . item-image item-title item-title'
    ' . item-image item-profile item-profile'
    ' . item-action item-action item-action'
    'item-footer1 item-footer2 item-footer3 item-footer4';

  &.checkout-item {
    grid-template-columns: 0px 75px minmax(180px, 1fr) 25px;
    grid-template-rows: repeat(3, auto);

    grid-template-areas:
      ' item-header1 item-header2 item-header2 item-header3'
      ' . item-title item-title item-title'
      ' . item-profile item-profile item-action';
    padding-bottom: 15px;
    &:first-child {
      border-top: solid 1px var(--border-color, #f5f4f4);
    }
  }

  &.option-item {
    grid-template-columns: 25px 75px minmax(180px, 1fr) 25px;
    grid-template-rows: repeat(2, auto);

    grid-template-areas:
      ' item-header1 item-image item-title item-title'
      ' . item-image item-profile item-action';

    padding-bottom: 15px;
  }

  &.disabled {
    opacity: 0.4;
  }

  .item-status {
    grid-area: item-header1;
    align-self: start;
    width: 20px;
    height: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .item-title {
    grid-area: item-header2;
    align-self: start;
    width: 100%;
    height: auto;
    padding-bottom: 5px;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.5;
    &:first-line {
      line-height: 1.4;
    }
    a {
      color: var(--text-color, black);
    }
  }

  .remove-item-btn {
    grid-area: item-header3;
    display: block;
    justify-self: end;
    align-self: start;
    width: 20px;
    height: 20px;

    img {
      width: 60%;
      height: auto;
    }
  }

  .item-image {
    grid-area: item-image;
    justify-self: start;
    align-self: start;
    display: flex;
    width: 55px;
    height: 55px;
    position: relative;

    .main-image {
      width: 55px;
      height: 55px;
      border-radius: 4px;
    }

    .sub-image {
      width: 30px;
      height: 30px;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -15px;

      border-radius: 50%;
    }

    // object-fit: cover;
    // overflow: hidden;
    // cursor: pointer;
  }

  .item-subtitle {
    grid-area: item-title;
    font-size: 0.875rem;
    font-weight: bold;
    height: 35px;
    line-height: 1.3;
    img {
      width: 24px;
      padding: 5px;
      margin-left: 5px;
    }
  }

  .item-price {
    grid-area: item-profile;
    height: 54px;
    font-size: 0.75rem;
    font-weight: bold;

    .discount-price {
      font-size: 1rem;
      font-weight: 800;
    }

    .gift-point {
      margin-left: 3px;
    }

    .regular-price {
      margin-top: 10px;
      color: #bebebe;
    }
  }

  .item-text {
    grid-area: item-text;
    height: 36px;
    width: 100%;
    margin-top: 3px;

    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1.4;

    white-space: initial;
    overflow: hidden;
  }

  .number-picker {
    grid-area: item-action;
    justify-self: end;
    align-self: end;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;

    span {
      display: block;
      width: 25px;
      text-align: right;
      padding: 5px;
      font-size: 0.875rem;
      font-weight: bold;
    }

    button {
      width: 30px;
      height: 30px;
      background-position: center;

      &.plus-button {
        background-image: url('/images/plus-btn-2.svg');
      }

      &.minus-button {
        background-image: url('/images/minus-btn-2.svg');
      }
    }
  }

  .item-total {
    grid-area: item-footer4;
    justify-self: end;
    align-self: center;
    display: flex;
    width: 150px;
    height: auto;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 1rem;
    font-weight: 800;
    padding: 15px 0px;

    span {
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
`;
