import React from 'react';
import { reduxForm, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';

// import { mendatoryValidation } from "lib/form/validation";
import Button from 'components/commons/Button';

import RenderOptionPriceListField from 'components/reduxForm/RenderOptionPriceListField';
import 'components/admin/RegisterProductForm/RenderPriceListField.scss';
import './OptionPriceListForm.scss';

function OptionPriceListForm({
  handleSubmit,
  isSubmitLoading,
  onSubmit,
  options,
  onChangePrice,
  onSwapIndex,
  onClickDeleteOption,
  defaultRate,
  // eslint-disable-next-line no-unused-vars
  onChangeImage
}) {
  return (
    <div className="register-product-form option-price-list-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FieldArray
          name="options"
          component={RenderOptionPriceListField}
          onChangePrice={onChangePrice}
          onSwapIndex={onSwapIndex}
          onClickDeleteOption={onClickDeleteOption}
          options={options}
          defaultRate={defaultRate}
          onChangeImage={onChangeImage}
        />
        <div className="product-regist-buttons">
          <Button
            buttonText="저장 하기"
            color="provider"
            buttonType="submit"
            isLoading={isSubmitLoading}
            isDisable={isSubmitLoading}
          />
        </div>
      </form>
    </div>
  );
}

OptionPriceListForm.propTypes = {
  onSubmit: PropTypes.func,
  onSwapIndex: PropTypes.func,
  onClickDeleteOption: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  onChangePrice: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  defaultRate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

OptionPriceListForm.defaultProps = {
  onSubmit: () => {},
  onSwapIndex: () => {},
  onClickDeleteOption: () => {},
  handleSubmit: () => {},
  isSubmitLoading: false,
  onChangePrice: () => {},
  options: [],
  defaultRate: {}
};

export default reduxForm({
  form: 'optionPriceListForm',
  initialValues: {}
})(OptionPriceListForm);
