import React from 'react';
import PropTypes from 'prop-types';
import { getHost } from 'lib/utils/envUtils';

import 'components/users/SignInForm/SignInForm.scss';
import './KakaoAuthButton.scss';

function KakaoAuthButton({
  buttonText,
  buttonType,
  onClick,
  redirectUrl,
  redirectType
}) {
  if (buttonType === 'button') {
    return (
      <button
        type="button"
        className="signin-social-button kakao-login"
        onClick={onClick}
      >
        <img src="/images/kakao-login-btn.svg" alt="카카오톡으로 시작하기" />
        {buttonText}
      </button>
    );
  }

  const state = JSON.stringify({
    url: redirectUrl,
    type: redirectType
  });

  // console.log({ state });

  const href = `https://kauth.kakao.com/oauth/authorize?client_id=74c8ac5d959fcd96c7e4e96e79e054ab&redirect_uri=${getHost()}/store/signin&response_type=code&encode_state=true&state=${encodeURIComponent(
    state
  )}`;

  return (
    // <a className="signin-social-button kakao-login" href={href}>
    //   <img src="/images/kakao-login-btn.svg" alt="카카오톡으로 시작하기" />
    //   {buttonText}
    // </a>
    <button
      className="signin-social-button kakao-login"
      onClick={() => onClick(href)}
    >
      <img src="/images/kakao-login-btn.svg" alt="카카오톡 계정으로 로그인" />
      <span>카카오톡 계정으로 로그인</span>
    </button>
  );
}

KakaoAuthButton.propTypes = {
  buttonText: PropTypes.string,
  buttonType: PropTypes.string,
  onClick: PropTypes.func,
  redirectUrl: PropTypes.string,
  redirectType: PropTypes.string
};

KakaoAuthButton.defaultProps = {
  buttonText: '카카오톡으로 시작하기',
  buttonType: 'link',
  onClick: () => {},
  redirectUrl: '/store/$storeId',
  redirectType: '1store'
};

export default KakaoAuthButton;
