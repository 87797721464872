import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import TutorialContainer from 'containers/tutorial/TutorialContainer';

function Tutorial() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <TutorialContainer />
    </Fragment>
  );
}

export default Tutorial;
