/* eslint-disable no-console */
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

// import OneProductTabs from 'components/oneProduct/OneProductTabs';
import OneProductDetail from 'components/oneProduct/OneProductDetail';
import ProductOptions from 'components/product/ProductOptions';
import ProductSlider from 'components/commons/ProductSlider';
import OneProductSlider from 'components/commons/OneProductSlider';
import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';

import Button from 'components/commons/Button';
import { MainSectionWrapper } from 'styles/AppStyle';

function OneProductMain({
  storeId,
  productId,
  product,
  oneProduct,
  selectOptionIndex,
  onChangeOptionIndex
}) {
  const infoRef = useRef(null);
  // const postRef = useRef(null);
  // const storeRef = useRef(null);
  // const history = useHistory();
  // console.log('OneProductMain');
  // console.log({ storeId });
  // console.log({ productId });

  // console.log({ product });
  // console.log({ oneProduct });
  // console.log({ isOneProductError });

  return (
    <MainSectionWrapper className="one-product">
      <ScrollToTopOnMount />

      {/* <OneProductTabs {...product} onChangeTabs={onChangeTabs} /> */}
      <OneProductDetail
        storeId={storeId}
        productId={productId}
        oneProduct={oneProduct}
        product={product}
      />
      <div ref={infoRef}>
        <ProductOptions
          {...product}
          onChangeOptionIndex={onChangeOptionIndex}
          selectOptionIndex={selectOptionIndex}
        >
          <div className="best-product-button option-button">
            <Button
              color="w-from"
              buttonText={`상세정보 보기`}
              buttonType="link"
              to={`/store/${storeId}/${productId}/detail`}
            />
          </div>
        </ProductOptions>
      </div>

      {product && product.providerId && (
        <ProductSlider
          className="small"
          title="이 공급자의 다른 상품 >"
          searchType="providerId"
          searchKey={product.providerId}
          filterBy={(product) => product.id !== productId}
          sortBy={(a, b) => b.sortDate - a.sortDate}
        />
      )}
      {storeId && (
        <OneProductSlider
          className="small"
          title={`${
            oneProduct ? oneProduct.storeName : '이 프롬이'
          }님의 단골상품 >`}
          searchType="storeId"
          searchKey={storeId}
          filterBy={(item) => item.productId !== productId && item.flagPublic}
          sortBy={(a, b) => b.updateDate - a.updateDate}
        />
      )}
    </MainSectionWrapper>
  );
}

export default OneProductMain;
