import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { required, tel } from 'lib/form/validations';

import Button from 'components/commons/Button';
import RenderField from 'components/reduxForm/RenderField';

// 사용자 비밀번호 폼 scss file
import 'components/users/SignInHelpId/SignInHelpId.scss';

function ProviderResetPassword({
  handleSubmit,
  isLoading,
  invalid,
  onSubmit,
  pristine,
  submitting
}) {
  return (
    <div className="provider-reset-password">
      <form className="sign-help" onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="providerId"
          type="text"
          component={RenderField}
          isRequired
          label="공급자 ID"
          placeholder="공급자 ID를 입력해 주세요"
          validate={[required]}
          pattern={/\s/g}
        />
        <Field
          name="picName"
          type="text"
          component={RenderField}
          isRequired
          label="담당자 이름"
          placeholder="담당자 이름을 입력해 주세요"
          validate={[required]}
          pattern={/\s/g}
        />
        <Field
          name="picMobile"
          type="text"
          component={RenderField}
          isRequired
          label="담당자 휴대폰"
          placeholder="- 없이, 숫자만 입력해 주세요"
          validate={[required, tel]}
          pattern={/[^0-9]/g}
        />
        <div className="signin-help-buttons">
          <Button
            buttonText="확인"
            buttonType="submit"
            color="from"
            isLoading={isLoading}
            isDisable={invalid || isLoading || pristine || submitting}
          />
          <Button
            buttonText="취소"
            buttonType="link"
            className="mt-3"
            color="close-from"
            to="/provider/signin"
          />
        </div>
      </form>
    </div>
  );
}

ProviderResetPassword.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func
};

ProviderResetPassword.defaultProps = {
  isLoading: false,
  onSubmit: () => {}
};

export default reduxForm({
  form: 'providerResetPasswordForm'
})(ProviderResetPassword);
