import React from 'react';
import { Link } from 'react-router-dom';

import { getFormatTimeStempDate } from 'lib/time';
import { getImagePath } from 'lib/utils/imageUtils';

// import Button from 'components/commons/Button';

import './ProviderProductItem.scss';

function ProviderSuccessProductItem({ products }) {
  const {
    createDate,
    discoverId,
    id: productId,
    officialProductImgPath,
    productName
  } = products;

  return (
    <Link
      to={{
        pathname: `/provider/product/${productId}/register`,
        search: `?discoverId=${discoverId}`
      }}
    >
      <div className="provider-product-item-wrapper">
        <div className="provider-product-item-image success">
          <img
            src={getImagePath(officialProductImgPath)}
            alt="상품 사진"
            className="provider-product-item-main-image"
          />
          <img
            className="provider-product-item-sub-image no-image-border"
            src="/images/provider-crown-circle-icon.svg"
            alt="공급자왕관"
          />
        </div>
        <div className="provider-product-item-info">
          <div className="provider-product-item-info-name">{productName}</div>
          <div className="provider-product-item-info-date">
            <span>{getFormatTimeStempDate(createDate, 'YYYY-MM-DD')}</span>
          </div>
        </div>
        <img
          className="provider-product-link-icon"
          src="/images/onestore-setting-link-icon.svg"
          alt="화살표"
        />
      </div>
    </Link>
  );
}

export default ProviderSuccessProductItem;
