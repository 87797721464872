import styled from 'styled-components';

export const StyledOrderItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 3fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'header header link'
    'image body body'
    'image footer footer';
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  align-items: center;
  justify-items: start;

  padding: 20px 10px;
  margin: 0px auto;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color, black);
}

&.order-item-canceled {
  opacity: 0.4;
}

.order-item-header {
  grid-area: header;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px;
  margin-bottom: 10px;

  span {
    font-size: 0.625rem;
  }

  a {
    color: var(--text-color, black);
  }

  .main {
    font-weight: bold;
  }

  .sub {
    font-weight: normal;
    color: #999898;
  }
}

.order-item-link {
  grid-area: link;
  justify-self: end;
  align-self: start;
  
  span {
    font-size: 0.75rem;
    color: var(--text-color, black);
    padding: 0px 10px;
  }

  button {
    width: 140px;
    height: 20px;
    border-color: var(--from-color);
    background-color: var(--from-color);
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0;
    border-radius: 2.5px;

    &.canceled-button {
      border-color: #848587;
    background-color: white;
    color: #1d1d1b;

    } 
  }
}

.order-item-image {
  grid-area: image;
  justify-self: start;
  align-self: start;
  display: flex;
  flex-direction: column;
  width: 55px;
  /* height: 55px; */
  position: relative;

  .main-image {
    width: 55px;
    height: 55px;
    border-radius: 4px;
    
    &.receiver-image {
      border-radius: 50%;
    }
  }

  .sub-image {
    width: 30px;
    height: 30px;

    position: absolute;
    top: 30px;
    transform: translateY(-50%);
    right: -15px;

    border-radius: 50%;
  }

  p {
    color: var(--text-color);
    padding: 10px;
    font-size: 0.75rem;
    font-weight: bold;
  }
  // object-fit: cover;
  // overflow: hidden;
  // cursor: pointer;
}

.order-item-footer {
  grid-area: footer;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px;
  line-height: 1.3;

  .label {
    font-size: 0.7rem;
    font-weight: normal;
    color: #999898;
  }
  .value {
    font-size: 0.75rem;
    font-weight: 800;
  }
}

.order-line-items {
  grid-area: body;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.53;
  width: 100%;

  .line-item {
    padding: 0px 0px;

    .value {
      font-size: 0.75rem;
      font-weight: 800;
    }
    .label {
      font-size: 0.75rem;
      font-weight: normal;
      color: #999898;
    }
  }
`;
