import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Linkify from 'react-linkify';

import './SummaryText.scss';

function SummaryText({
  contentText,
  contentTextSlideIndex,
  defaultMessage,
  isLongText,
  isShownDetail,
  onClick
}) {
  const hasContentText = !!contentText;

  if (hasContentText) {
    contentText =
      isLongText && !isShownDetail
        ? contentText.substring(0, contentTextSlideIndex).concat('...')
        : contentText;

    contentText = contentText.split('\n');
  }

  return (
    <div
      className={classNames(
        'summary-text',
        { summary: !isShownDetail },
        { detail: isShownDetail }
      )}
    >
      {hasContentText ? (
        <Fragment>
          {contentText.map((line, index) => {
            if (!line) {
              return null;
            }

            return (
              <Linkify
                className="product-text-introduce"
                // eslint-disable-next-line react/no-array-index-key
                key={`recommend-header-${index}`}
                properties={{ target: '_blank' }}
              >
                {line}
                {contentText.length - 1 !== index && <br />}
              </Linkify>
            );
          })}
          {isLongText && (
            <button
              type="button"
              className="summary-button ml-1"
              onClick={onClick}
            >
              {isShownDetail ? '접기' : '더보기'}
            </button>
          )}
        </Fragment>
      ) : (
        <span>{defaultMessage}</span>
      )}
    </div>
  );
}

SummaryText.propTypes = {
  contentText: PropTypes.string,
  contentTextSlideIndex: PropTypes.number,
  isLongText: PropTypes.bool,
  isShownDetail: PropTypes.bool,
  onClick: PropTypes.func
};

SummaryText.defaultProps = {
  contentText: null,
  contentTextSlideIndex: 0,
  isLongText: false,
  isShownDetail: true,
  onClick: () => {}
};

export default SummaryText;
