import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PostCard from './PostCard';
import { StyledButton } from 'shared/styled/componentStyle';

function PostView({ post }) {
  if (!post) return null;

  // console.log({ post });

  return (
    <Fragment>
      <PostCard post={post}></PostCard>

      {/* <Link to={`/posts/${post.expId}/${post.commentsId}`}>
        <StyledButton>상세보기</StyledButton>
      </Link> */}
    </Fragment>
  );
}

export default PostView;
