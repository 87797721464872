import React from 'react';
import { Link } from 'react-router-dom';
import './CompanyInfo.scss';

function CompanyInfo() {
  return (
    <div className="company-info-form">
      <div className="company-agreement">
        <Link to="/tutorial">프롬 소개</Link>
        <Link to="/service/contact">고객 센터</Link>
        <Link to="/policy/service" className="gray-agreement-link">
          이용약관
        </Link>
        <Link to="/policy/privacy" className="gray-agreement-link">
          개인정보처리방침
        </Link>
      </div>
      <div className="company-info-text">
        <div>
          <span className="company-info-text-title">상호</span>
          (주)커넥서스컴퍼니
          <span className="division-line">|</span>
          <span className="company-info-text-title">대표</span>윤성영, 윤지영
        </div>
        <div>
          <span className="company-info-text-title">개인정보관리책임자</span>
          윤성영<span className="division-line">|</span>
          <span className="company-info-text-title">사업자번호</span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7578600677&apv_perm_no="
          >
            757-86-00677
          </a>
        </div>
        <div>
          <span className="company-info-text-title">주소</span> 경기 성남시
          수정구 달래내로369번길 14
        </div>
        <div>
          <span className="company-info-text-title">통신판매업 신고번호</span>제
          2017-서울서초-1027호
        </div>
        <div>
          <span className="company-info-text-title">전화</span>
          <a href="tel:02-6951-1234">02-6951-1234</a>
          <span className="division-line">|</span>
          <span className="company-info-text-title">메일</span>
          <a href="mailto:help@from.kr">help@from.kr</a>
        </div>
      </div>
    </div>
  );
}

export default CompanyInfo;
