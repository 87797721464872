import React from 'react';
import CDN_URL from 'components/commons/globalUrl';

import './PaymentLoader.scss';

function PaymentLoader({ isLoading, isSuccess }) {
  return (
    <div className="payment-complete-loader text-primary text-center">
      <div className="payment-complete-loader-box">
        <div className="payment-complete-loader-spinner">
          {isLoading ? (
            <img src={`${CDN_URL}/from/from_loading_1.gif`} alt="결제 중" />
          ) : (
            <img src="/images/from-logo-2.svg" alt="결제완료" />
          )}
          <span className="payment-complete-loader-text d-block w-100 mt-3 text-center">
            {isLoading ? '결제 중...' : isSuccess ? '결제 성공' : '결제 실패'}
          </span>
        </div>
      </div>
    </div>
  );
}
export default PaymentLoader;
