import React from 'react';
import CDN_URL from 'components/commons/globalUrl';

import './SearchLoader.scss';

function SearchLoader() {
  return (
    <div className="search-loader">
      <img
        src={`${CDN_URL}/from/from_loading_1.gif`}
        alt="로딩 이미지"
        className="d-block search-loader-gif"
      />
    </div>
  );
}

export default SearchLoader;
