import React, { Component, Fragment } from 'react';
import ServiceContactForm from 'components/guide/ServiceContactForm';

import connectKakaoPlusFriend from 'lib/kakao/connectKakaoPlusFriend';
import ConfirmModal from 'components/modal/ConfirmModal';

import withUi from 'lib/hoc/withUi';

class ServiceContactContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.UiAction.setHeader('고객센터');
  }

  handleClickKakaoPlusButton = () => {
    const connetFunc = connectKakaoPlusFriend();

    if (connetFunc) {
      this.setState({
        isOpenErrorModal: true,
        // errorMessage: connetFunc.error,
        errorMessage:
          '새로운 버전이 출시되었습니다\n 지금 업데이트 하시겠습니까?',
        agent: connetFunc.agent
      });
    }
  };

  handleToggleModal = () => {
    const { isOpenErrorModal } = this.state;

    this.setState({
      isOpenErrorModal: !isOpenErrorModal
    });
  };

  linkStore = () => {
    const ANDROID_APP_INTENT = `market://details?id=io.connexus.onestore`;
    const IOS_APP_ITUNES =
      'https://itunes.apple.com/us/app/%ED%94%84%EB%A1%AC/id1456988582?l=ko&ls=1&mt=8';
    const { agent } = this.state;

    if (agent === '1store_iphone') {
      window.location.href = IOS_APP_ITUNES;
      return;
    }

    if (agent === '1store_android') {
      window.location.href = ANDROID_APP_INTENT;
      return;
    }
  };

  render() {
    const { isOpenErrorModal, errorMessage } = this.state;

    return (
      <Fragment>
        <ServiceContactForm
          onClickKakaoPlusButton={this.handleClickKakaoPlusButton}
        />
        <ConfirmModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={this.handleToggleModal}
          onClickOk={this.linkStore}
          okButtonText="업데이트 하기"
        />
      </Fragment>
    );
  }
}

export default withUi(ServiceContactContainer);
