import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { useCheckoutStore, useCheckoutDispatch } from './CheckoutStore';

import withUi from 'lib/hoc/withUi';

import { StyledCheckoutHeader } from './CheckoutStyle';
import { ButtonWrapper, StyledButton } from 'shared/buttons/ButtonStyle';

function CheckoutHeader({ step = 'checkout', showHeader }) {
  // console.log({ showHeader });
  const method = useFormContext();
  const history = useHistory();
  const { orders, currentOrder } = useCheckoutStore();
  const checkoutDispatch = useCheckoutDispatch();

  const orderIsEmpty = !(orders?.length > 0);

  const onGoToOrder = (index, values) => {
    try {
      // console.log({ values, index });
      if (step === 'checkout')
        checkoutDispatch({ type: 'addReceiver', payload: { ...values } });
      checkoutDispatch({ type: 'goToOrder', payload: index });
      history.push(`/order/checkout`);
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <StyledCheckoutHeader className={!showHeader ? 'hidden-header' : ''}>
      {!orderIsEmpty ? (
        <ButtonWrapper className="grey-button-wrapper">
          <h3>받는 분:</h3>
          {orders.map((order, index) => {
            // if (!order.receiver?.name && index === currentOrder) return null;
            return (
              <StyledButton
                key={index}
                className={`text-button ${
                  currentOrder === index && step === 'checkout'
                    ? 'border-button'
                    : ''
                }`}
                onClick={method ? method.handleSubmit((values) =>
                  onGoToOrder(index, values)) : () => onGoToOrder(index)
                }
                // onClick={() => {
                //   checkoutDispatch({ type: 'addReceiver', payload: receiver });
                //   checkoutDispatch({ type: 'goToOrder', payload: index });
                //   history.push(`/order/checkout`);
                // }}
              >
                <span>{order.receiver?.name || 'To ...'}</span>
                <StyledButton
                  className="text-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    checkoutDispatch({ type: 'removeOrder', payload: index });
                  }}
                >
                  <img src="/images/x-icon-black.svg" alt="remove" />
                </StyledButton>
              </StyledButton>
            );
          })}
        </ButtonWrapper>
      ) : (
        <ButtonWrapper className="grey-button-wrapper">
          <StyledButton
            className="text-button  transparent-button"
            onClick={() => history.push('/order/cart')}
          >
            <img src="/images/checkbox-checked-black.svg" alt="cart" />
            <span>내 바구니 가기</span>
          </StyledButton>
        </ButtonWrapper>
      )}
      {/* <ButtonWrapper className="grey-button-wrapper">
          {currentOrder > 0 ? (
            <StyledButton className="text-button" onClick={prevOrder}>
              {checkout?.orders[currentOrder - 1]?.receiver?.name || '이전'}
            </StyledButton>
          ) : (
            <StyledButton
              className="text-button"
              onClick={() => history.push('/order/cart')}
            >{`내 바구니`}</StyledButton>
          )}
          <StyledButton className="text-button">{`${
            receiver?.name ? receiver.name : '받는 분을 입력해 주세요'
          }`}</StyledButton>
          {currentOrder < checkout?.orderTotal?.orderCount - 1 && (
            <StyledButton className="text-button" onClick={nextOrder}>
              {checkout?.orders[currentOrder + 1]?.receiver?.name || '다음'}
            </StyledButton>
          )}
          <StyledButton className="text-button" onClick={addOrder}>
            받는분 추가
          </StyledButton>
        </ButtonWrapper> */}
    </StyledCheckoutHeader>
  );
}
export default withUi(CheckoutHeader);
