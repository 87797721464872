import React from 'react';
import { getImagePath } from 'lib/utils/imageUtils';
import OneStoreListItemTemplate from 'components/oneStore/OneStoreListTemplate/OneStoreListItemTemplate';

function OneStoreFromListItem({ from }) {
  const { fromStoreId, productImgPath, fromStore } = from;
  const { profileImgPath, name } = fromStore;
  return (
    <OneStoreListItemTemplate linkPath={`/store/${fromStoreId}`} name={name}>
      <div className="onestore-list-template-item-image">
        <img
          src={getImagePath(productImgPath) || '/images/default-image.svg'}
          alt="발굴사진"
          className="onestore-list-template-item-main-image"
        />
        <img
          className="onestore-list-template-item-sub-image"
          src={getImagePath(profileImgPath) || '/images/default-profile.svg'}
          alt="발굴왕관"
        />
      </div>
    </OneStoreListItemTemplate>
  );
}

export default OneStoreFromListItem;
