export default (link) => {
  if (
    typeof window === 'undefined' /* || typeof window.twttr === 'undefined' */
  ) {
    return;
  }

  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent === 'undefined'
  ) {
    return;
  }

  const twLinkObj = {
    method: 'share',
    display: 'popup',
    href: link
  };

  const userAgent = navigator.userAgent.toLowerCase();

  // OS App
  if (userAgent.indexOf('1store_iphone') > -1) {
    twLinkObj.snsType = 'twitter';

    // eslint-disable-next-line
    webkit.messageHandlers.shareSNS.postMessage(JSON.stringify(twLinkObj));

    return;
  }

  // Android App
  if (userAgent.indexOf('1store_android') > -1) {
    twLinkObj.snsType = 'twitter';
    // eslint-disable-next-line
    __1STORE_ANDROID.shareSNS(JSON.stringify(twLinkObj));
    return;
  }
  // console.log(window.twttr);
  window.open(link);
  // window.open(
  //   `https://twitter.com/intent/tweet?text=%23%EC%82%B0%ED%83%80MBTI%20%23%ED%94%84%EB%A1%AC%20%23%EC%9B%94%EB%93%9C%EB%B9%84%EC%A0%84%20%23%EC%9C%84%EA%B8%B0%EC%95%84%EB%8F%99%20%23%EC%84%A0%EB%AC%BC%EA%B3%BC_%EA%B8%B0%EB%B6%80%EB%A5%BC_%EB%8F%99%EC%8B%9C%EC%97%90%20%23%EC%B0%90%EC%82%B0%ED%83%80%EB%90%98%EA%B8%B0%20%232%EC%9D%B83%EA%B0%81%EB%A6%B4%EB%A0%88%EC%9D%B4%EC%82%B0%ED%83%80&url=https%3A%2F%2Ffrom23.bubbleapps.io%2Fblank1`
  // );
  // window.twttr.ui(twLinkObj);
};
