import React, { Component, Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import axios from 'api/defaultAxios';
import { bindActionCreators, compose } from 'redux';

import storage from 'lib/storage';
import { getHost } from 'lib/utils/envUtils';
import { mappingSns, disconnectKakao } from 'api/store';
import { getImageFile, getImagePath } from 'lib/utils/imageUtils';
import s3ProfileUpload from 'lib/aws/s3ProfileUpload';

import AgreementModal from 'components/modal/AgreementModal';
import RedirectToHome from 'components/commons/RedirectToHome';
import MemberEditHeader from 'components/users/MemberEditForm/MemberEditHeader';
import MemberEditForm from 'components/users/MemberEditForm';
import PwdEditForm from 'components/users/PwdEditForm';
import SnsEditForm from 'components/users/SnsEditForm';
import GoodByeForm from 'components/users/GoodByeForm';

import AlertModal from 'components/modal/AlertModal';
import LogoModal from 'components/modal/LogoModal';

import * as userActions from 'modules/user';

import withUi from 'lib/hoc/withUi';
import withUser from 'lib/hoc/withUser';

import sentry from 'lib/sentry';

class MemberEditContainer extends Component {
  // 뒤로가기 했을때 state 설정
  static getDerivedStateFromProps(nextProps, prevState) {
    const { pathname } = nextProps.location;
    const splitPathname = pathname ? pathname.split('/') : [];
    const subPath = splitPathname[splitPathname.length - 1]
      ? splitPathname[splitPathname.length - 1]
      : '';

    if (subPath !== prevState.currentTab) {
      return {
        currentTab: subPath
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { location } = this.props;
    const { pathname } = location;
    const splitPathname = pathname ? pathname.split('/') : [];
    const subPath = splitPathname[splitPathname.length - 1]
      ? splitPathname[splitPathname.length - 1]
      : '';

    this.state = {
      agreeUrl: '',
      addr: '',
      addrSub: '',
      currentTab: subPath,
      flagAgreeMarketing: false,
      isOpenAgreementModal: false,
      isOpenSearchAddress: false,
      isOpenErrorModal: false,
      isOpenSuccessModal: false,
      errorMessage: '',
      successMessage: '',
      successButtonText: '',
      id: '',
      mobile: '',
      email: '',
      profileImgPath: '',
      zipcode: '',
      isLoading: false,
      isPwdLoading: false,
      isKakaoButtonLoading: false, // sns
      isConnectedKakao: false // sns
    };

    this.editData = {};
    this.successType = ''; // type = "memberedit" or "pwdEdit"
    this.errorType = ''; // type = "error" or "notpwd" or "kakaoconnect"
  }

  componentDidMount() {
    this.props.UiAction.setHeader('회원 정보 수정');
    this.getUser();
    this.handleChangeTab();
  }

  //뒤로가기 했을때 주소 비교하고 함수 실행시켜줌
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;

    if (location.pathname !== prevLocation.pathname) {
      this.handleGetList();
      this.handleChangeTab();
    }
  }

  getUser = () => {
    const { match, location } = this.props;
    const { storeId } = match.params;

    axios
      .get(`/store/${storeId}`)
      .then((res) => {
        const {
          flagAgreeMarketing,
          userName,
          id,
          mobile,
          email,
          profileImgPath,
          zipcode,
          addr,
          addrSub,
          kakaoId,
          flagExistPwd
        } = res.data.data;

        this.editData = res.data.data;

        let tempflagAgreeMarketing = true;

        //   flagAgreeMarketing
        //  "true"거나 없으면 체크
        //  "false"면 미체크
        if (flagAgreeMarketing === null || flagAgreeMarketing === undefined) {
          tempflagAgreeMarketing = true;
        } else {
          tempflagAgreeMarketing = flagAgreeMarketing;
        }

        this.setState({
          flagAgreeMarketing: tempflagAgreeMarketing,
          userName,
          id,
          mobile,
          email,
          profileImgPath:
            profileImgPath && profileImgPath.width ? profileImgPath : {},
          // api에서 내려오는 값이 {path: " "}이어서 profileImgPath.width로 이미지유무 판단해서 보낼떈 {}로
          zipcode,
          addr,
          addrSub,
          kakaoId,
          isConnectedKakao: !!kakaoId,
          isHavePassword: flagExistPwd
        });
        this.handleChangeEdit();

        if (!kakaoId && location && location.state && location.state.userInfo) {
          this.handleMappingKakao();
        }
      })
      .catch(() => {
        this.errorType = 'error';
        this.setState({
          isOpenErrorModal: true,
          errorMessage: '데이터 요청에 실패하였습니다.'
        });
      });
  };

  handleChangeEdit = () => {
    const { changeField } = this.props;
    const { userName, mobile, email, addr, addrSub, zipcode } = this.state;

    changeField('memberEditForm', 'userName', userName);
    changeField('memberEditForm', 'mobile', mobile);
    changeField('memberEditForm', 'email', email);
    changeField('memberEditForm', 'zipcode', zipcode ? zipcode.trim() : '');
    changeField('memberEditForm', 'addr', addr ? addr.trim() : '');
    changeField('memberEditForm', 'addrSub', addrSub ? addrSub.trim() : '');
  };

  handleToggleSearchAddress = (e) => {
    const { isOpenSearchAddress } = this.state;

    this.setState({
      isOpenSearchAddress: !isOpenSearchAddress
    });

    if (e) {
      e.target.blur();
    }
  };

  handleChangeaddrGeolocation = (addrGeolocation) => {
    const { changeField } = this.props;
    const { address, zonecode } = addrGeolocation;

    changeField(
      'memberEditForm',
      'zipcode',
      zonecode || addrGeolocation.zipcode
    );
    changeField('memberEditForm', 'addr', address);
    changeField('memberEditForm', 'addrSub', '');
  };

  handleChangeProfileImage = (profileImgPath) => {
    this.setState({
      profileImgPath
    });
  };

  handleBack = () => {
    const { history } = this.props;

    history.goBack(-1);
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  handleSubmit = (values) => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        try {
          const { match } = this.props;
          const { storeId } = match.params;
          const { flagAgreeMarketing, id, profileImgPath } = this.state;
          values.id = id;
          let uploadImage = {};

          if (profileImgPath && profileImgPath.image) {
            uploadImage.path = await s3ProfileUpload(
              profileImgPath.path
                ? getImagePath(profileImgPath)
                : getImageFile(profileImgPath),
              storeId,
              '프로필 사진을 업로드 하는 중 문제가 발생했습니다.'
            );

            uploadImage.width = profileImgPath.width;
            uploadImage.height = profileImgPath.height;
          } else {
            uploadImage = profileImgPath;
          }

          values.profileImgPath = uploadImage;
          values.flagAgreeMarketing = flagAgreeMarketing;

          await axios.put(`/store/${id}`, values).then((res) => {
            if (!res.data.success) {
              let errorMessage =
                '데이터 통신이 원활하지 않아 정보 수정에 실패하였습니다.\n잠시 후 다시 시도해주세요.';

              if (res.data.err && res.data.err.code === 'ValidationException') {
                errorMessage = '필수 입력 정보가 입력되지 않았습니다.';
              }

              this.errorType = 'error';
              this.setState({
                isLoading: false,
                isOpenErrorModal: true,
                errorMessage
              });
              return;
            }

            const user = storage.get('user_userInfo');

            if (user) {
              user.profileImgPath = uploadImage;

              storage.set('user_userInfo', user);
            }

            this.successType = 'memberedit';
            this.setState(
              {
                isLoading: false,
                isOpenSuccessModal: true,
                successMessage: '수정이 완료되었습니다.',
                successButtonText: '내 바구니로 돌아가기'
              },
              () => {
                const { UserAction } = this.props;

                UserAction.setProfileImage({
                  profileImgPath: uploadImage
                });
              }
            );
          });
        } catch (e) {
          this.errorType = 'error';
          sentry.captureException(e.message);
          this.setState({
            isLoading: false,
            isOpenErrorModal: true,
            errorMessage: '데이터 전송에 실패하였습니다.'
          });
        }
      }
    );
  };

  handlePwdSubmit = (values) => {
    this.setState(
      {
        isPwdLoading: true
      },
      async () => {
        try {
          let params = {};
          const { email, id, isHavePassword } = this.state;
          params.email = email;
          params.pwd = isHavePassword ? values.oldPwd : null;
          params.newPwd = values.pwd;

          if (params.pwd === params.newPwd) {
            let errorMessage =
              '현재 비밀번호가 신규 비밀번호와 일치합니다.\n새로운 비밀번호를 입력해주세요';
            this.errorType = 'error';
            this.setState({
              isPwdLoading: false,
              isOpenErrorModal: true,
              errorMessage
            });
            return;
          }

          await axios.put(`/store/${id}/pwd`, params).then((res) => {
            if (!res.data.success) {
              let errorMessage =
                '입력하신 현재 비밀번호가 맞지 않습니다.\n 다시 확인해 주세요.';

              this.errorType = 'error';
              this.setState({
                isPwdLoading: false,
                isOpenErrorModal: true,
                errorMessage
              });
              return;
            }

            this.successType = 'pwdedit';
            this.setState({
              isPwdLoading: false,
              isOpenSuccessModal: true,
              successMessage:
                '요청하신 비밀번호로 변경했습니다.\n신규 비밀번호로 로그인하세요.',
              successButtonText: '로그인 하러 가기'
            });
          });
        } catch (e) {
          this.errorType = 'error';
          sentry.captureException(e.message);
          this.setState({
            isPwdLoading: false,
            isOpenErrorModal: true,
            errorMessage:
              '입력하신 현재 비밀번호가 맞지 않습니다.\n 다시 확인해 주세요.'
          });
        }
      }
    );
  };

  handleGetList = () => {
    const { email, userName, mobile, currentTab } = this.state;

    if (currentTab === 'profile') {
      if (email && userName && mobile) {
        this.handleChangeEdit();
      }
      return;
    }
  };

  handleChangeTab = () => {
    const { location } = this.props;
    let currentTab = 0;

    if (!location || !location.pathname) {
      return;
    }

    const { pathname } = location;

    if (pathname.indexOf('profile') > -1) {
      currentTab = 'profile';
    } else if (pathname.indexOf('pwd') > -1) {
      currentTab = 'pwd';
    } else if (pathname.indexOf('sns') > -1) {
      currentTab = 'sns';
    }

    this.setState({
      currentTab
    });
  };

  handleSignOut = () => {
    storage.remove('user_identityId');
    storage.remove('user_token');
    storage.remove('user_userInfo');
    const isSSr = typeof window === 'undefined';

    if (!isSSr) {
      window.location.href = '/store/signin';
    }
  };

  handleKakaoToggleButton = () => {
    const { isConnectedKakao, isHavePassword, id } = this.state;

    this.setState(
      {
        isKakaoButtonLoading: true
      },
      async () => {
        try {
          if (!isHavePassword) {
            this.errorType = 'notpwd';
            this.setState({
              isKakaoButtonLoading: false,
              errorMessage:
                '비밀번호 설정후 카카오톡 계정과의\n연결 해제가 가능합니다.',
              isOpenErrorModal: true
            });
            return;
          }

          if (!isConnectedKakao) {
            const state = JSON.stringify({
              url: `/store/${id}/setting/edit/sns`,
              type: 'kakao'
            });

            const kakaoLink = `https://kauth.kakao.com/oauth/authorize?client_id=74c8ac5d959fcd96c7e4e96e79e054ab&redirect_uri=${getHost()}/store/signin&response_type=code&encode_state=true&state=${encodeURI(
              state
            )}`;

            window.location.href = kakaoLink;
          } else {
            this.handleDisconnectKakao();
          }
        } catch (e) {
          this.errorType = 'error';
          this.setState({
            isKakaoButtonLoading: false,
            errorMessage: '연결해제중 에러가 발생했습니다.',
            isOpenErrorModal: true
          });
        }
      }
    );
  };

  handleSuccessModalButton = (type) => {
    const { id } = this.state;

    // type = "memberedit" or "pwdedit"
    if (type === 'pwdedit') {
      this.handleSignOut();
      return;
    } else {
      this.props.history.replace(`/store/${id}`);
    }
  };

  handleErrorModalButton = (type) => {
    // type = "error" or "notpwd" or "kakaoconnect" or "kakaodisconnect"
    const { id } = this.state;

    if (type === 'notpwd') {
      this.props.history.replace(`/store/${id}/setting/edit/pwd`);
    }

    this.handleCloseModal('isOpenErrorModal');
  };

  handleMappingKakao = () => {
    const { location, history } = this.props;
    const { pathname } = location;
    const { id } = this.state;

    const values = {
      storeId: id,
      snsType: 'kakao',
      snsId: location.state.userInfo.snsId.toString()
    };

    this.setState(
      {
        isKakaoButtonLoading: true
      },
      async () => {
        try {
          const response = await mappingSns(values);

          if (!response || !response.data || !response.data.success) {
            throw new Error(
              `카카오 정보 매핑 실패 - ${JSON.stringify(response)}`
            );
          }

          this.setState({
            isKakaoButtonLoading: false,
            isConnectedKakao: true,
            kakaoId: location.state.userInfo.snsId.toString()
          });

          history.replace({
            pathname,
            state: {}
          });
        } catch (e) {
          sentry.captureException(e.message);

          this.errorType = 'error';
          this.setState({
            isKakaoButtonLoading: false,
            errorMessage: '카카오연결중 에러가 발생했습니다.',
            isOpenErrorModal: true
          });

          history.replace({
            pathname,
            state: {}
          });
        }
      }
    );
  };

  handleDisconnectKakao = async () => {
    const { id, kakaoId } = this.state;

    const values = {
      id,
      kakaoId
    };

    try {
      const response = await disconnectKakao(values);

      if (!response || !response.data || !response.data.success) {
        throw new Error(`카카오 매핑 해제 실패 - ${JSON.stringify(response)}`);
      }

      this.setState({
        isKakaoButtonLoading: false,
        isConnectedKakao: false
      });
    } catch (e) {
      sentry.captureException(e.message);

      this.errorType = 'error';
      this.setState({
        isKakaoButtonLoading: false,
        errorMessage: '카카오연결해제중 에러가 발생했습니다.',
        isOpenErrorModal: true
      });
    }
  };

  handleChangeCheckBox = (checkboxName) => {
    const { [checkboxName]: isChecked } = this.state;

    this.setState({
      [checkboxName]: !isChecked
    });
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  handleOpenAgreementModal = (file) => {
    this.setState({
      agreeUrl: file,
      isOpenAgreementModal: true
    });
  };

  render() {
    const {
      agreeUrl,
      flagAgreeMarketing,
      isOpenAgreementModal,
      isOpenSearchAddress,
      isOpenErrorModal,
      isOpenSuccessModal,
      errorMessage,
      successMessage,
      successButtonText,
      isLoading,
      isPwdLoading,
      profileImgPath,
      currentTab,
      isKakaoButtonLoading,
      isConnectedKakao,
      isHavePassword
    } = this.state;
    const userInfo = storage.get('user_userInfo');
    const { match, isLoggedIn } = this.props;
    const { storeId } = match.params;

    return (
      <Fragment>
        <RedirectToHome isGotoHome={!userInfo || userInfo.id !== storeId} />
        <MemberEditHeader
          currentTab={currentTab}
          storeId={storeId}
          isHavePassword={isHavePassword}
        />
        <Route
          path="/store/:storeId/setting/edit/profile"
          render={() => (
            <MemberEditForm
              flagAgreeMarketing={flagAgreeMarketing}
              isLoading={isLoading}
              isOpenSearchAddress={isOpenSearchAddress}
              onClickBack={this.handleBack}
              onChangeAddress={this.handleChangeaddrGeolocation}
              onChangeCheckbox={this.handleChangeCheckBox}
              onChangeImage={this.handleChangeProfileImage}
              onOpenAgreementModal={this.handleOpenAgreementModal}
              onSubmit={this.handleSubmit}
              onToggle={this.handleToggleSearchAddress}
              profileImgPath={getImageFile(profileImgPath)}
            />
          )}
        />
        <Route
          path="/store/:storeId/setting/edit/pwd"
          render={() => (
            <PwdEditForm
              onSubmit={this.handlePwdSubmit}
              isPwdLoading={isPwdLoading}
              isHavePassword={isHavePassword}
            />
          )}
        />
        <Route
          path="/store/:storeId/setting/edit/sns"
          render={() => (
            <SnsEditForm
              isKakaoButtonLoading={isKakaoButtonLoading}
              isConnectedKakao={isConnectedKakao}
              onKakaoToggleButton={this.handleKakaoToggleButton}
            />
          )}
        />
        <Route
          path="/store/:storeId/setting/edit/goodbye"
          render={() => (
            <GoodByeForm
              storeId={storeId}
              isLoggedIn={isLoggedIn}
              handleSignOut={this.handleSignOut}
            />
          )}
        />
        <AgreementModal
          isOpen={isOpenAgreementModal}
          agreeUrl={agreeUrl}
          onToggle={() => {
            this.handleCloseModal('isOpenAgreementModal');
          }}
        />
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={() => {
            this.handleErrorModalButton(this.errorType);
          }}
        />
        <LogoModal
          isOpen={isOpenSuccessModal}
          contentText={successMessage}
          headerText="수정 완료"
          buttonText={successButtonText}
          onToggle={() => {
            this.handleSuccessModalButton(this.successType);
          }}
          onClick={() => {
            this.handleSuccessModalButton(this.successType);
          }}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  UserAction: bindActionCreators(userActions, dispatch),
  changeField: (form, field, value) => {
    dispatch(change(form, field, value));
  }
});

export default compose(
  withRouter,
  withUi,
  withUser,
  connect(null, mapDispatchToProps)
)(MemberEditContainer);
