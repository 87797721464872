import React, { Component, Fragment } from 'react';
import axios from 'api/defaultAxios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector } from 'redux-form';

import { s3Upload } from 'lib/aws/s3Upload';
import withUi from 'lib/hoc/withUi';
import { getImageFile } from 'lib/utils/imageUtils';

import SignUpForm from 'components/users/SignUpForm';
import AlertModal from 'components/modal/AlertModal';
import LogoModal from 'components/modal/LogoModal';

import AgreementModal from 'components/modal/AgreementModal';

class SignUpContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agreeUrl: '',
      flagAgreeMarketing: false,
      isOpenSearchAddress: false,
      isOpenAgreementModal: false,
      isOpenErrorModal: false,
      isCheckedAgreement: false,
      isCheckedAllCheckbox: false,
      isCheckdPrivacy: false,
      isCheckedEmail: false,
      isKakaoSignUp: false,
      isErrorSignUp: false,
      profileImage: null,
      isLoading: false,
      errorMessage: ''
    };

    this.addrGeolocation = null;
    this.socialUserInfo = null;
  }

  componentDidMount() {
    const { UiAction } = this.props;

    UiAction.setHeader('회원가입');

    this.browserCheck();
    this.getSocialInfo();
  }

  getSocialInfo = () => {
    const { changeField, location } = this.props;

    if (location.state === undefined || location.state === null) {
      return;
    }

    const { userInfo } = location.state;
    const { email, mobile, profileImgPath, userName } = userInfo;

    changeField('email', email);
    changeField('mobile', mobile);
    changeField('userName', userName);

    this.socialUserInfo = userInfo;

    this.setState({
      isKakaoSignUp: userInfo.snsType === 'kakao',
      profileImage: profileImgPath
    });
  };

  browserCheck = () => {
    const agent = navigator.userAgent.toLowerCase();
    if (
      (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) ||
      agent.indexOf('msie') !== -1
    ) {
      // ie일 경우
      this.setState({
        isOpenErrorModal: true,
        errorMessage: `현재 브라우저는 IE(인터넷익스플로어)입니다.
        해당 사이트는 크롬에 최적화 되어있습니다.
        회원가입이 불가하니 크롬을 이용해주세요`
      });
    }
  };

  handleChangeEmail = () => {
    this.setState({
      isCheckedEmail: false
    });
  };

  handleChangeProfileImage = (imageFile) => {
    this.setState({
      profileImage: imageFile
    });
  };

  handleToggleSearchAddress = (e) => {
    const { isOpenSearchAddress } = this.state;

    this.setState({
      isOpenSearchAddress: !isOpenSearchAddress
    });

    if (e) {
      e.target.blur();
    }
  };

  handleToggleSignUpError = () => {
    this.setState({
      isErrorSignUp: false
    });
  };

  handleChangeaddrGeolocation = (addrGeolocation) => {
    const { address, zonecode } = addrGeolocation;

    this.props.changeField('addr', address);
    this.props.changeField('zipcode', zonecode || addrGeolocation.zipcode);
  };

  handleChangeCheckBox = (checkboxName) => {
    const { [checkboxName]: isChecked } = this.state;

    if (checkboxName === 'isCheckedAllCheckbox') {
      this.setState({
        flagAgreeMarketing: !isChecked,
        isCheckedAllCheckbox: !isChecked,
        isCheckedAgreement: !isChecked,
        isCheckdPrivacy: !isChecked
      });

      return;
    }

    this.setState({
      [checkboxName]: !isChecked,
      isCheckedAllCheckbox: false
    });
  };

  handleSubmit = (values) => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        const { flagAgreeMarketing, profileImage } = this.state;
        try {
          const uploadImage = {};

          if (profileImage) {
            uploadImage.path = await s3Upload(
              getImageFile(profileImage),
              'profileImage'
            );

            uploadImage.width = profileImage.width;
            uploadImage.height = profileImage.height;
          }

          values.profileImgPath = uploadImage;
          values.flagAgreeMarketing = flagAgreeMarketing;

          if (this.socialUserInfo) {
            values.snsId = this.socialUserInfo.snsId.toString();
            values.snsType = this.socialUserInfo.snsType;
          }

          await axios.post('/store/signup', values).then((res) => {
            if (!res.data.success) {
              this.setState({
                isErrorSignUp: true,
                isLoading: false
              });
              return;
            }
            this.setState({
              isLoading: false,
              isOpenSuccessModal: true
            });
          });
        } catch (err) {
          this.setState({
            isErrorSignUp: true,
            isLoading: false
          });
        }
      }
    );
  };

  handleCloseModal = (modalType) => {
    this.setState({
      [modalType]: false
    });
  };

  handleOpenAgreementModal = (file) => {
    this.setState({
      agreeUrl: file,
      isOpenAgreementModal: true
    });
  };

  handleMoveSignin = () => {
    const { history, location } = this.props;
    let signinUrl = '/store/signin';

    if (location && location.search) {
      signinUrl += location.search;
    }

    this.setState(
      {
        isOpenSuccessModal: false
      },
      () => {
        history.replace(signinUrl);
      }
    );
  };

  handleMoveTutorial = () => {
    const { history } = this.props;
    let signinUrl = '/tutorialintro';

    this.setState(
      {
        isOpenSuccessModal: false
      },
      () => {
        history.replace(signinUrl);
      }
    );
  };

  render() {
    const {
      flagAgreeMarketing,
      isCheckedAllCheckbox,
      isOpenAgreementModal,
      isOpenSuccessModal,
      isOpenErrorModal,
      isOpenSearchAddress,
      isCheckedEmail,
      isCheckedAgreement,
      isCheckdPrivacy,
      isErrorSignUp,
      isKakaoSignUp,
      profileImage,
      isLoading,
      errorMessage,
      agreeUrl
    } = this.state;

    return (
      <Fragment>
        <SignUpForm
          flagAgreeMarketing={flagAgreeMarketing}
          profileImage={profileImage}
          isOpenSearchAddress={isOpenSearchAddress}
          isCheckedAgreement={isCheckedAgreement}
          isCheckdPrivacy={isCheckdPrivacy}
          isCheckedAllCheckbox={isCheckedAllCheckbox}
          isCheckedEmail={isCheckedEmail}
          isErrorSignUp={isErrorSignUp}
          isKakaoSignUp={isKakaoSignUp}
          onChangeEmail={this.handleChangeEmail}
          onChangeCheckbox={this.handleChangeCheckBox}
          onChangeImage={this.handleChangeProfileImage}
          onChangeAddress={this.handleChangeaddrGeolocation}
          onSignUp={this.handleSubmit}
          onToggle={this.handleToggleSearchAddress}
          onToggleSignUp={this.handleToggleSignUpError}
          isLoading={isLoading}
          onOpenAgreementModal={this.handleOpenAgreementModal}
        />
        <AgreementModal
          isOpen={isOpenAgreementModal}
          agreeUrl={agreeUrl}
          onToggle={() => {
            this.handleCloseModal('isOpenAgreementModal');
          }}
        />
        <AlertModal
          isOpen={isOpenErrorModal}
          contentText={errorMessage}
          onToggle={() => {
            this.handleCloseModal('isOpenErrorModal');
          }}
        />
        <LogoModal
          isOpen={isOpenSuccessModal}
          contentText={`프롬이 궁금하시다면\n간단한 프롬소개를 확인해보세요.`}
          buttonText="로그인 하러 가기"
          secondButtonText="프롬소개"
          headerText="회원가입이 완료되었습니다!"
          onToggle={this.handleMoveSignin}
          onClick={this.handleMoveSignin}
          onClickSecond={this.handleMoveTutorial}
        />
      </Fragment>
    );
  }
}

const selector = formValueSelector('signUpForm');

const mapStateToProps = (state) => {
  const email = selector(state, 'email');

  return {
    email
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeField: (field, value) => {
      dispatch(change('signUpForm', field, value));
    }
  };
};

export default compose(
  withRouter,
  withUi,
  connect(mapStateToProps, mapDispatchToProps)
)(SignUpContainer);
