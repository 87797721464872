import React, { Component } from 'react';
import withUi from 'lib/hoc/withUi';

import './Policy.scss';

class ServiceAgreement extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    const { UiAction } = this.props;

    UiAction.setHeader('이용약관');
  }

  render() {
    return (
      <section className="container policy-container pt-3 pb-3">
        {/* eslint-disable */}
        <h2>[ 프롬 이용 약관 ]</h2>
        <h3 className="mt-3 mb-3">제 1 조 (목적)</h3>
        <p>
          이 약관은 ㈜커넥서스컴퍼니(이하 “회사”라 합니다)가 운영하는
          프롬(from.kr, 이하 “사이트”라 합니다)이 스마트폰 등 이동통신기기를
          통해 제공하는 전자상거래 관련 각종 서비스(이하 “서비스”라 합니다)를
          회원이 이용함에 있어. 상품 또는 용역을 거래하는 자 간의 권리, 의무 등
          필요사항, 회원과 회사 간의 권리, 의무, 책임사항 및 회원의 서비스
          이용절차 등에 관한 사항을 규정함을 목적으로 합니다.
        </p>
        <h3 className="mt-3 mb-3">제 2 조 (정의)</h3>
        <p>
          이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <br />① “사이트”란 회사가 재화 또는 서비스 상품(이하 “상품”이라
          합니다)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신 설비를
          이용하여 상품을 거래할 수 있도록 설정한 가상의 영업장을 말합니다.
          <br />② “회원”이라 함은 사이트에 회원 등록을 한 개인 또는 사업자(법인
          포함)로서 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를
          말하며, 회원은 아래와 같이 서비스 이용 목적에 따라 “프롬 회원”과
          “공급자 회원”으로 구분되고, 상호 간 회사가 정하는 바에 따라 중복적
          지위를 취득할 수 있습니다. 중복적 지위를 취득한 회원인 경우 이
          약관뿐만 아니라 공급자 회원약관이 적용됩니다.
          <br /> 1. 프롬 회원 : 개인인 경우 만 14세 이상이면 누구나 가입
          가능(외국인 등록번호를 소지한 국내 거주 외국인 포함) 하며, 사업자인
          경우 사업자 정보로 회원 등록하여야 합니다. 프롬 회원은 제2조 제7항에
          기재된 추천상품을 구매하는 다른 프롬 회원과 해당 추천상품을 공급하는
          공급자 회원 사이의 거래에 있어 중개인의 지위에 있으며, 해당 추천상품을
          실제 판매하는 자는 아닙니다.
          <br />
          2. 공급자 회원 : 판매활동을 하는 법인 또는 개인으로서, 프롬 회원의
          발굴 신청과 회사의 심사를 거쳐 공급자 회원의 지위를 취득할 수 있으며,
          사업자인 경우 사업자 정보로 회원 등록하여야 합니다. 다만, 회사가 여타
          사업자로부터 특정 상품을 직접 구매하여 프롬 회원에게 공급하는 경우에는
          회사가 본 약관상의 공급자 회원의 지위를 취득하게 됩니다.
          <br /> ③ “아이디(ID)”라 함은 회원의 식별과 회원의 서비스 이용을 위하여
          회원이 설정하고 회사가 승인하여 회사에 등록된 영문, 숫자 또는 영문과
          숫자의 조합을 말하며, 회원이 보유하는 이메일 주소가 아이디가 됩니다.
          <br /> ④ “비밀번호(Password)”라 함은 회원인지를 확인하고 비밀을
          보호하기 위하여 회원이 스스로가 설정해 회사에 등록한 영문과 숫자의
          조합을 말합니다.
          <br /> ⑤ “프롬”이라 함은 프롬 회원이 됨으로써 자동으로 개설되고, 해당
          프롬 회원 내지 다른 프롬 회원에 의해 발굴된 “추천상품”을 통하여
          전자상거래가 이루어지며, 전자상거래를 통해 프롬 회원이 “적립금”을
          획득하는 사이트 상의 가상 공간을 의미합니다.
          <br /> ⑥ “발굴 신청”이라 함은 프롬 회원이 특정 상품을 해당 프롬 회원이
          관리하는 프롬의 “추천상품”으로 지정해줄 것을 회사에 신청하는 절차를
          의미하며, 공급자 회원은 자신이 판매하는 “공급 상품”에 대해 발굴 신청을
          할 수 없습니다.
          <br />⑦ “추천상품”이라 함은, 프롬 회원이 특정 상품을 회사에 발굴
          신청을 한 후, 회사의 심사 및 공급자 회원의 동의를 통해 사이트상에서
          거래가 가능하게 된(이하 “발굴 승인”이라고 합니다) 상품을 의미합니다.
          특정 상품이 발굴 승인을 통해 추천상품이 되면, 그 상품을 발굴 신청한
          프롬 회원이 최초 발굴자의 지위를 취득하게 되며, 해당 프롬 회원의 다른
          프롬 회원은 그 상품을 재차 발굴 신청할 수 없습니다. 최초 발굴자인 프롬
          회원이 보유한 추천상품을 공급자로부터 구매하거나 혹은 구매를 한 프롬
          회원으로부터 무상 제공(선물) 받은 프롬 회원은 자신의 프롬에 추천상품을
          보유하게 되며(다만 자신의 프롬에 보유된 추천상품을 다른 프롬 회원이
          보거나 구매하지 못하도록 비공개로 할 수 있습니다), 다른 프롬 회원이
          해당 프롬이 보유한 추천상품을 공급자 회원으로부터 구매하거나 구매 후
          제3의 프롬 회원에게 무상 제공(선물) 해 주는 경우에도 마찬가지로 구매를
          한 다른 프롬 회원 및 무상 제공(선물) 받은 제3의 프롬 회원은 자신의
          프롬에 추천상품을 보유하게 됩니다(이 경우에도 자신의 프롬에 보유된
          추천상품을 다른 프롬 회원이 보거나 구매하지 못하도록 비공개로 할 수
          있습니다).
          <br /> ⑧ “공급 상품”이라 함은 공급자 회원이 공급하는 상품으로서, 프롬
          회원의 발굴 신청, 회사의 심사를 거쳐 추천상품으로의 전환이 가능한
          상품을 의미합니다.
          <br /> ⑨ “적립금”라 함은 특정 프롬 회원이 다른 프롬 회원이 관리하는
          프롬에 있는 추천상품을 구매할 때 발생하는 금액으로서, 대금 결제
          수단으로 사용할 수 있는 현금 등가의 결제수단을 의미합니다. 다만,
          적립금은 현금으로 인출할 수 없으며 사이트 내에서 발생하는
          전자상거래에서만 대금 결제 수단으로 사용 가능합니다.
          <br /> ⑩ “영업일”이라 함은 회사가 정상적으로 서비스를 제공한 날로서
          토요일, 일요일 및 법정 공휴일을 제외한 날을 말합니다.
          <br /> ⑪ “구매확정기간”이라 함은 매 구매자 거래에 대하여 구매
          종료(구매확정, 반품, 교환)의 의사표시를 해야 하는 기간으로, 배송
          완료일로부터 14일이 되는 날(설치 상품 등 배송 완료일을 확인할 수 없을
          때에는 발송 완료일로부터 21일이 되는 날을 배송 완료일로 간주하고
          그로부터 14일이 되는 날)까지를 의미합니다.
        </p>
        <h3 className="mt-3 mb-3">제 3 조 (약관의 명시와 개정)</h3>
        <p>
          ① 회사는 이 약관의 내용과 상호, 영업소 소재지 주소(소비자의 불만을
          처리할 수 있는 곳의 주소를 포함), 대표자의 성명, 사업자등록번호,
          통신판매업 신고번호, 연락처(전화, 전자우편 주소 등) 등을 회원이 쉽게
          알 수 있도록 사이트의 초기 서비스 화면(전면)에 게시합니다. 다만,
          약관의 내용은 회원이 연결 화면을 통하여 보도록 할 수 있습니다.
          <br /> ② 회사는 『전자상거래 등에서의 소비자보호에 관한 법률』,
          『약관의 규제 등에 관한 법률』, 『전자 문서 및 전자거래기본법』,
          『전자서명법』, 『정보통신망 이용 촉진 등에 관한 법률』, 『방문판매
          등에 관한 법률』, 『소비자기본법』 등 관련 법을 위배하지 않는 범위에서
          이 약관을 개정할 수 있습니다.
          <br /> ③ 회사는 약관을 개정할 경우에는 적용일자 및 개정 사유 명시하여
          현행 약관과 함께 사이트의 초기화면에 그 적용일자 7일 이전부터 적용일자
          전일까지 공지합니다. 다만, 회원에게 불리하게 약관 내용을 변경하는
          경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우
          회사는 개정 전과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록
          표시합니다.
          <br /> ④ 회원은 변경된 약관에 동의하지 않을 권리가 있으며, 변경된
          약관에 동의하지 않을 경우에는 서비스 이용을 중단하고 탈퇴를 요청할 수
          있습니다. 다만, 회원이 제3항의 방법 등으로 별도 고지한 약관 개정 공지
          기간 내에 회사에 개정 약관에 동의하지 않는다는 명시적인 의사표시를
          하지 않는 경우 변경된 약관에 동의한 것으로 간주합니다.
          <br /> ⑤ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는
          『전자상거래 등에서의 소비자 보호에 관한 법률』, 『약관의 규제 등에
          관한 법률』, 공정거래위원회가 정하는 『전자상거래 등에서의 소비자보호
          지침』 및 관계 법령 또는 상 관례에 따릅니다.
        </p>
        <h3 className="mt-3 mb-3">제 4 조 (서비스의 제공)</h3>
        <p>
          ① 회사가 제공하는 서비스는 다음과 같습니다.
          <br /> 1. 전자상거래 서비스(회사가 사이트를 통하여 회원 간에 상품의
          매매가 이루어질 수 있도록 온라인 거래장소를 제공하는 서비스 및 관련
          부가서비스를 의미하며, 구체적인 내용은 아래 각 목과 같습니다)
          <br /> 가. 판매 관련 업무지원 서비스
          <br /> 나. 구매 관련 지원 서비스
          <br /> 다. 매매계약 체결 관련 서비스 <br />
          라. 상품 정보검색 서비스
          <br /> 마. 기타 전자상거래 관련 서비스
          <br /> 2. 광고 집행 및 프로모션 서비스
          <br /> ② 회사가 제공하는 전항의 서비스는 회원이 상품을 거래할 수
          있도록 사이버몰인 사이트의 이용을 허락하거나, 통신판매를 알선하는 것을
          목적으로 하며, 개별 공급자 회원이 사이트에 등록한 상품과 관련해서는
          일체의 책임을 지지 않습니다.
        </p>
        <h3 className="mt-3 mb-3">제 5 조 (서비스의 중단)</h3>
        <p>
          ① 회사는 컴퓨터 등 정보통신 설비의 보수 점검·교체 및 고장, 통신의 두절
          등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수
          있습니다.
          <br /> ② 회사는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로
          인하여 회원이 입은 손해에 대하여 배상합니다. 단, 회사가 고의 또는
          과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
          <br /> ③ 회사는 서비스의 원활한 수행을 위하여 필요한 기간을 정하여
          사전에 공지하고 서비스를 중지할 수 있습니다. 단, 불가피하게 긴급한
          조치를 필요로 하는 경우 사후에 통지할 수 있습니다.
          <br /> ④ 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로
          서비스를 제공할 수 없게 되는 경우에는 회사는 제8조에 정한 방법으로
          회원에게 통지하고 당초 회사에서 제시한 조건에 따라 회원에게
          보상합니다. 다만, 회사가 보상기준 등을 고지하지 아니한 경우에는
          사이트에서 통용되는 통화가치에 상응하는 적립금 또는 현금으로 회원에게
          지급합니다.
        </p>
        <h3 className="mt-3 mb-3">
          제 6 조 (회원가입 및 서비스 이용계약의 성립)
        </h3>
        <p>
          ① 서비스 이용 계약(이하 “이용계약”이라고 합니다)은 회사가 제공하는
          서비스를 이용하고자 하는 자의 이용 신청에 대하여 회사가 이를
          승낙함으로써 성립하고, 이용계약을 체결하고자 하는 자는 회사가 정한
          가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를
          함으로서 회원가입을 신청합니다.
          <br /> ② 회사는 다음의 각 호 중 어느 하나에 해당하는 회원가입(이용
          신청)에 대해서는 승인을 하지 않거나, 승인을 취소할 수 있습니다.
          <br /> 1. 가입신청자가 이 약관 제7조 제2항 및 제3항에 의하여 이전에
          회원자격을 상실한 적이 있는 경우, 다만 제7조 제2항 및 제3항에 의한
          회원자격 상실 후 6개월이 경과한 자로서 회사의 회원 재가입 승낙을 얻은
          경우에는 예외로 합니다.
          <br /> 2. 회원 등록 내용에 허위, 기재누락, 오기가 있는 경우
          <br /> 3. 회원의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한
          제반 사항을 위반하며 신청하는 경우
          <br /> 4. 만 14세 미만의 아동으로서 부모 등 법정대리인의 동의를 얻지
          아니한 경우
          <br /> 5. 이미 가입 절차를 완료하여 아이디가 부여된 회원
          <br /> 6. 필수 회원정보를 기입하지 아니한 경우
          <br /> ③ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상
          문제가 있는 경우에는 승낙을 유보할 수 있습니다.
          <br /> ④ 이용계약의 성립시기는 회사의 승낙이 회원에게 도달한 시점으로
          합니다.
          <br /> ⑤ 회원은 제6조 제1항에 의한 등록사항에 변경이 있는 경우, 상당한
          기간 이내에 직접 온라인으로 개인 정보를 수정하거나 즉시 전자우편 또는
          기타 방법으로 회사에 그 변경사항을 알려야 합니다.
          <br /> ⑥ 회원이 입력한 회원정보를 변경하고자 하는 경우, 회사가 정한
          본인 확인 절차 등을 거치도록 할 수 있습니다.
          <br /> ⑦ 구매자은 제10조에 따라 추천상품을 구매하여 회원이 아닌 자에게
          상품을 무상으로 제공할 수 있고, 제공받는 자는 구매자로부터 무상으로
          추천상품을 제공받을 수 있는 링크를 전송받은 후 제1항의 절차를 거친
          회원가입과 동시에 추천상품을 제공받습니다.
        </p>
        <h3 className="mt-3 mb-3">제 7 조 (회원 탈퇴 및 자격 상실)</h3>
        <p>
          ① 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 이와 같은
          요청을 받았을 경우 사이트를 통하여 회사가 고지한 방법에 따라 신속하게
          회원 탈퇴를 처리합니다.
          <br /> ② 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을
          제한 및 정지시킬 수 있습니다.
          <br /> 1. 가입 신청 시에 허위 내용을 등록한 경우
          <br /> 2. 사이트를 이용하여 구입한 상품의 대금, 기타 사이트 이용과
          관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
          <br /> 3. 다른 사람의 사이트 이용을 방해하거나 그 정보를 도용하는 등
          전자상거래 질서를 위협하는 경우
          <br /> 4. 사이트를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에
          반하는 행위를 하는 경우
          <br /> ③ 회사가 경고 또는 사이트 이용의 정지 조치를 한 경우, 동일한
          행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는
          경우 회사는 회원자격을 상실시킬 수 있습니다. 단, 회원의 위반 정도가
          중한 경우 즉시 이용계약을 해지할 수 있습니다.
          <br /> ④ 회사가 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다.
          단, 회원 등록 말소 전에 관련 법률 등이 정하는 한도 내에서 일정한
          기간을 정하여 소명할 기회를 부여할 수 있습니다.
          <br /> ⑤ 회원 등록이 말소되더라도 회원이 구매한 추천상품, 추천상품의
          거래와 관련한 정보는 삭제되지 않고, 다른 회원이 해당 정보에 대해서는
          사이트상으로 열람이 가능합니다(다만 회원 본인의 인적 사항은 공개되지
          않으며, “탈퇴한 회원”으로 사이트상으로 표시됩니다).
        </p>
        <h3 className="mt-3 mb-3">제 8 조 (회원에 대한 통지)</h3>
        <p>
          ① 회사는 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여
          지정한 전화 및 전자우편 주소, 휴대전화 문자메시지, 회원의 연락처와
          연동된 SNS 서비스 등으로 할 수 있습니다. 이 경우 회원의 통신환경 또는
          요금 구조 등에 따라 회원이 데이터 요금 등을 부담할 수 있습니다.
          <br /> ② 회사는 불특정 다수 회원에 대한 통지의 경우 1주일 이상 회사
          게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원
          본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별
          통지합니다.
        </p>
        <h3 className="mt-3 mb-3">제 9 조 (금지행위)</h3>
        <p>
          ① 회사가 제공하는 서비스를 이용하지 않고 프롬 회원과 공급자 회원이
          직접적으로 거래하는 직거래는 거래의 안전을 위하여 금지됩니다. 직거래를
          통하여 발생한 제반 문제에 대한 책임은 거래 당사자에게 있으며 회사는
          이로 인하여 발생된 문제에 대하여 어떠한 책임도 지지 않습니다.
          <br /> ② 회사가 제공하는 서비스를 비정상적으로 이용하거나 시스템에
          접근하는 행위는 금지됩니다. 회사는 위와 같은 부정행위를 행한 회원에
          대하여 회사가 제공한 혜택을 회수하거나 서비스 이용 제한 및 회원자격
          박탈 등의 조치를 할 수 있으며, 회원의 부정행위로 인하여 발생한 손해에
          대하여 배상을 청구할 수 있습니다.
          <br /> ③ 타인의 명의나 카드 정보, 계좌 정보 등을 도용하여 회사가
          제공하는 서비스를 이용하는 행위는 금지됩니다.
          <br /> ④ 여신 전문금융업법 등 각종 법령을 위반하여 비정상적인 결제를
          하거나 상품의 실질적인 구매의사가 없이 구매행위를 하는 것은 금지되며,
          이 경우 회사는 회원자격을 박탈하거나 회원의 거래를 중지시키고 관련
          기관에 통보할 수 있습니다.
          <br /> ⑤ 제3자에게 재판매하기 위한 목적으로 회사가 제공하는 서비스를
          부당하게 이용하는 행위는 금지되며, 재판매 목적의 거래 행위가 확인된
          경우 회사는 해당 회원에 대하여 서비스 이용 제한, 회원자격 박탈 등의
          조치를 취할 수 있으며, 이러한 행위로 손해가 발생할 경우 배상을 청구할
          수 있습니다.
        </p>
        <h3 className="mt-3 mb-3">제 10 조 (매매계약의 체결 및 대금 결제)</h3>
        <p>
          ① 상품의 매매계약은, 특정 프롬 회원(이하 “판매자”라고도 합니다)의
          프롬이 보유한 추천 상품 &#123;공급자 회원(이하 “공급자”라고도
          합니다)이 제시한 상품의 판매 조건이 기재되어 있습니다 &#125;을
          구매하려는 프롬 회원(이하 “구매자”라고도 합니다)이 판매 조건에 응하여
          청약의 의사표시를 하고, 이에 대하여 구매자가 승낙의 의사표시를
          함으로써 체결됩니다(공급자와 구매자 간 매매계약 체결되고, 판매자는
          매매계약과 관련하여 법적 의무를 부담하지 않습니다).
          <br /> ② 회사는 구매자가 카드 기타의 방법으로 매매 대금을 결제할 수
          있는 방법을 제공합니다.
          <br /> ③ 매매 대금의 결제와 관련하여 회원이 입력한 정보 및 그 정보와
          관련하여 발생한 책임과 불이익은 전적으로 회원이 부담하여야 합니다.
          <br /> ④ 구매자가 상품을 주문한 후 일정 기간 내에 매매 대금을 결제하지
          않을 경우 회사는 당해 주문을 회원의 동의 없이 취소할 수 있습니다.
          <br /> ⑤ 회사는 회원의 상품 매매계약 체결 내용을 나의 쇼핑 내역을 통해
          확인할 수 있도록 조치하며, 매매계약의 취소 방법 및 절차를 안내합니다.
          <br /> ⑥ 회사는 구매자가 매매 대금 결제 시 사용한 결제수단에 대해
          정당한 사용 권한을 가지고 있는지의 여부를 확인할 수 있으며, 이에 대한
          확인이 완료될 때까지 거래 진행을 중지하거나, 확인이 불가한 해당 거래를
          취소할 수 있습니다.
          <br /> ⑦ 구매자가 실제로 결제하는 금액은 공급자가 정한 공급원가가
          적용된 금액(실 구매액)이며 구매자에게 발행되는 구매 증빙서(신용카드
          매출전표 등)는 실 구매액으로 발행됩니다.
        </p>
        <h3 className="mt-3 mb-3">제 11 조 (배송 및 거래 완료)</h3>
        <p>
          ① 배송 소요기간은 입금 또는 대금 결제 확인일의 익일을 기산일로 하여
          배송이 완료되기까지의 기간을 말합니다.
          <br /> ② 회사는 구매자의 입금 또는 대금 결제에 대한 확인 통지를 받은
          후 영업일 3일 이내에 공급자에게 배송에 필요한 조치를 취하도록
          안내합니다.
          <br /> ③ 천재지변 등 불가항력적인 사유가 발생한 경우 그 해당 기간은
          배송 소요기간에서 제외됩니다.
          <br /> ④ 회사는 배송과 관련하여 구매자과 공급자, 배송업체, 금융기관
          등과의 사이에 발생한 분쟁은 당사자들 간의 해결을 원칙으로 하며, 회사는
          어떠한 책임도 부담하지 않습니다.
          <br /> ⑤ 공급자의 발송 확인 처리 이후에 구매자가 수취 확인을 하지 않아
          배송 중 상태가 지연될 경우, 회사는 발송 확인 일로부터 2주 이내에 수취
          확인 요청 안내를 할 수 있습니다. 회사의 안내 이후에도 구매자의 수취
          확인이 이루어지지 않을 경우 3일이 경과한 시점에 자동으로 배송 완료로
          전환될 수 있으며 이 경우 실제 구매자가 상품을 수령하지 못한 경우에
          구매자는 미 수취 신고를 할 수 있습니다.
          <br /> ⑥ 배송 완료 전환 후 구매확정 기간 내에 구매자에게서 구매확정,
          교환 또는 반품에 대한 의사표시가 없으면 회사는 해당 거래에 대하여
          구매확정의 의사가 있는 것으로 간주하여 다음 영업일에 자동 구매확정으로
          처리(이하 “자동 구매확정”이라고 합니다) 할 수 있습니다. 다만, 구매확정
          기간 내에 구매자가 실제 상품을 수령하지 못했다면 구매자는 상품 미도착
          신고를 할 수 있습니다.
          <br /> ⑦ 자동 구매확정 처리가 완료되면, 회사는 보관 중인 결제 대금 중
          공급자에게 지급할 상품 판매대금을 공급자가 지정한 방법으로 지급할 수
          있습니다.
          <br /> ⑧ 자동 구매확정이 이루어졌다면 구매자는 상품 미수령, 반품 등의
          사유로 회사에 이의를 제기할 수 없으며, 상품 구매대금의 환불, 상품의
          교환 등 모든 문제는 공급자와 직접 해결해야 합니다.
        </p>
        <h3 className="mt-3 mb-3">제 12 조 (취소)</h3>
        <p>
          ① 구매자는 구매한 상품이 발송되기 전까지 구매를 취소할 수 있으며, 배송
          중인 경우에는 취소가 아닌 반품 절차에 따라 처리됩니다.
          <br /> ② 공급자는 구매자의 입금 또는 대금 결제에 대한 통지를 수령한 후
          상품 재고의 부족 등의 사유가 있어 상품의 발송 등 제공이 불가능함을
          발견한 경우, 해당 매매계약을 취소하여야 하며 입금된 대금은 구매자에게
          환불됩니다.
          <br /> ③ 구매자가 결제를 완료한 후 배송 대기, 배송 요청 상태에서는
          취소 신청 접수 시 특별한 사정이 없는 한 즉시 취소 처리가 완료됩니다.
          <br /> ④ 배송 준비 상태에서 취소 신청한 때에 이미 상품이 발송이 된
          경우에는 발송된 상품의 왕복 배송비를 구매자가 부담하는 것을 원칙으로
          하며, 취소가 아닌 반품 절차에 따라 처리됩니다.
          <br /> ⑤ 취소 처리에 따른 환불은 카드 결제의 경우 취소 절차가 완료된
          즉시 결제가 취소되며, 구매 시에 적립금을 사용한 경우에는 결제 취소
          시에 적립금이 즉시 환불됩니다.
          <br /> ⑥ 구매 취소의 경우에도 구매자의 프롬에는 구매 취소한 상품이
          추천상품으로 게시되며(비공개 여부는 본 약관 제2조 제7항 기재와 같이
          구매자가 결정할 수 있습니다), “구매 취소한 상품”이라고 표시됩니다.
        </p>
        <h3 className="mt-3 mb-3">제 13 조 (반품)</h3>
        <p>
          ① 구매자는 공급자의 상품 발송 시로부터 배송 완료일 후 14일 이내까지
          관계법령에 의거하여 반품을 신청할 수 있습니다. <br /> ② 반품에 관한
          일반적인 사항은 전자상거래 등에서의 소비자보호에 관한 법률 등 관련
          법령이 구매자가 제시한 조건보다 우선합니다. <br /> ③ 반품에 소요되는
          비용은 반품에 대한 귀책사유가 있는 자에게 일반적으로 귀속됩니다. (예를
          들어, 단순 변심 : 구매자 부담, 상품 하자 : 구매자 부담 등) <br /> ④
          반품 신청 시 반품 송장번호를 미기재하거나 반품 사유에 관하여
          구매자에게 정확히 통보(구두 또는 서면으로) 하지 않을 시 반품 처리 및
          환불이 지연될 수 있습니다. <br /> ⑤ 반품에 따른 환불은 반품 상품이
          구매자에게 도착되고 반품 사유 및 반품 배송비 부담자가 확인된 이후에
          현금결제의 경우에는 영업일 3일 이내에 적립금으로 환불 되고, 카드
          결제의 경우 즉시 결제가 취소됩니다. <br /> ⑥ 반품 배송비를 구매자가
          부담하여야 하는 경우 반품 배송비의 추가 결제가 이루어지지 않으면
          환불이 지연될 수 있습니다.
        </p>

        <h3 className="mt-3 mb-3">제 14 조 (교환)</h3>
        <p>
          ① 회원은 공급자의 상품 발송 시로부터 배송 완료일 후 14일 이내까지
          전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령에 의거하여
          교환을 신청할 수 있습니다.
          <br /> ② 교환 신청을 하더라도 공급자에게 교환할 물품의 재고가 없는
          경우에는 교환이 불가능하며, 이 경우에 해당 교환 신청은 반품으로
          처리됩니다.
          <br /> ③ 교환에 소요되는 비용은 물품 하자의 경우에는 공급자가 왕복
          배송비를 부담하나 구매자의 변심에 의한 경우에는 구매자가 부담합니다.
        </p>

        <h3 className="mt-3 mb-3">제 15 조 (환불)</h3>
        <p>
          ① 회사는 구매자의 취소 또는 반품에 의하여 환불 사유가 발생할 시 카드
          결제의 경우에는 즉시 결제가 취소됩니다. <br /> ② 카드 결제를 통한 구매
          건의 환불은 원칙적으로 카드 결제 취소를 통해서만 가능합니다.
        </p>

        <h3 className="mt-3 mb-3">제 16 조 (반품/교환/환불의 적용 배제)</h3>
        <p>
          구매자는 다음 각 호의 경우에는 환불 또는 교환을 요청할 수 없습니다.
          <br /> 1. 구매자의 귀책사유로 말미암아 상품이 멸실·훼손된 경우
          <br /> 2. 구매자의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히
          감소한 경우
          <br /> 3. 시간의 경과에 의하여 재판매가 곤란할 정도로 상품의 가치가
          현저히 감소한 경우
          <br />
          4. 복제가 가능한 상품의 포장을 훼손한 경우
          <br /> 5. 주문에 따라 개별적으로 생산되는 물품 등 구매자에게 회복할 수
          없는 중대한 피해가 예상되는 경우로서 사전에 해당 거래에 대하여 별도로
          그 사실을 고지하고 구매자의 서면(전자 문서를 포함)에 의한 동의를 받은
          경우
        </p>

        <h3 className="mt-3 mb-3">제 17 조 (환급)</h3>
        <p>
          ① 회사가 처리하는 상품 중 구매 후 공급자 또는 공급자가 지정하는
          장소에서 본인 확인 절차를 거쳐 서비스를 받을 수 있는 “서비스 상품”의
          경우, 구매자는 사전에 서비스 상품의 개별 판매 조건상에 명시된 유효
          기간 내에 한해서만 업체의 서비스를 제공받을 수 있습니다.
          <br /> ② 구매자는 서비스 상품 구매 확인 목적으로 전달되는 이메일,
          문자메시지 기타 영수증으로 본인확인 절차를 대신할 수 없으며, 무단으로
          서비스 상품을 타인에게 양도하거나 담보로 제공할 수 없습니다.
          <br /> ③ 회원이 명시된 유효기간 내에 서비스 상품을 이용하지 못한 경우
          회사가 정하는 바에 따라 적립금으로 환급을 받을 수 있습니다.
          <br /> ④ 본 조의 적용을 받는 서비스 상품의 경우 해당 서비스 상품을
          공급하는 공급자 회원의 정책에 따라 서비스 상품의 사용 및 환불 등의
          조건이 정해지며, 구매자는 사이트에 명시된 내용 및 개별
          주의사항(이용조건)을 면밀히 확인하여야 할 책임이 있으며, 명시된
          이용조건에 따라서 서비스 상품을 사용하실 수 있습니다.
        </p>

        <h3 className="mt-3 mb-3">제 18 조 (적립금)</h3>
        <p>
          ① 적립금은 회사의 정책에 따라 구매와 동시에 즉시 판매자 내지
          구매자에게 지급되며, 적립금의 지급률은 회사가 정하는 바에 따르되,
          판매자가 자신과 구매자에게 지급되는 적립금 비율을 직접 조정할 수
          있습니다. <br /> ② 적립금의 유효기간은 회사에서 회원에게 적립 시
          공지한 유효기간에 따르며, 유효기간 동안 사용하지 않을 경우 자동으로
          소멸됩니다. <br /> ③ 회원 탈퇴 시 미 사용한 적립금은 자동으로
          소멸되며, 회사는 적립금에 대하여서는 별도의 보상을 하지 않습니다.
        </p>

        <h3 className="mt-3 mb-3">제 19 조 (공급자 회원 할인)</h3>
        <p>
          ① “공급자 회원 할인”은, 사이트를 통하여 상품을 구매하는 구매자에게
          무상으로 지급되며, 할인금액은 상품별로 공급자 회원이 정한 바에 따라
          상이하며 품목별로 사이트에서 확인 가능합니다.
          <br /> ② 할인금액은 현금으로 출금되거나 기타 현금과 동일한 가치로
          회원이 취득할 수 없습니다.
          <br /> ③ 회사는 회원이 부정한 목적과 방법으로 공급자 할인을 획득하거나
          사용하는 사실이 확인될 경우, 해당 회원에 대한 할인금액을 회수 또는
          소멸시키거나 회원자격을 제한할 수 있습니다.
          <br /> ④ 공급자 할인의 제공 내용 및 운영 방침은 회사 정책 및 공급자
          회원의 자사 내규에 따라 달라질 수 있습니다.
        </p>

        <h3 className="mt-3 mb-3">제 20 조 (개인 정보의 보호)</h3>
        <p>
          ① 회사는 회원의 개인 정보 수집 시 서비스 제공을 위하여 필요한 범위에서
          최소한의 개인 정보를 수집합니다.
          <br /> ② 회사는 회원가입 시 구매계약 이행에 필요한 정보를 미리
          수집하지 않습니다. 다만, 관련 법령상 의무 이행을 위하여 구매계약
          이전에 본인확인이 필요한 경우로서 최소한의 특정 개인 정보를 수집할 수
          있습니다.
          <br /> ③ 회사는 회원의 개인 정보를 수집·이용하는 때에는 당해 회원에게
          그 목적을 고지하고 동의를 받습니다.
          <br /> ④ 회사는 수집된 개인 정보를 목적 외의 용도로 이용할 수 없으며,
          새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는
          이용·제공 단계에서 당해 회원에게 그 목적을 고지하고 동의를 받습니다.
          다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
          <br /> ⑤ 회사가 제3항과 제4항에 의해 회원의 동의를 받아야 하는
          경우에는 개인 정보 보호 책임자의 신원(소속, 성명 및 전화번호, 기타
          연락처), 정보의 수집 목적 및 이용목적, 제3자에 대한 정보제공 관련
          사항(제공받은 자, 제공 목적 및 제공할 정보의 내용) 등 『정보통신망
          이용 촉진 및 정보보호 등에 관한 법률』에서 규정한 사항을 미리
          명시하거나 고지하며 회원은 언제든지 이 동의를 철회할 수 있습니다.
          <br /> ⑥ 회사는 개인 정보 보호를 위하여 회원의 개인 정보를 처리하는
          자를 최소한으로 제한하여야 하며, 회사의 귀책사유에 의한 신용카드, 은행
          계좌 등을 포함한 회원의 개인 정보의 분실, 도난, 유출, 동의 없는 제3자
          제공, 변조 등으로 인한 회원의 손해에 대하여 모든 책임을 집니다.
          <br /> ⑦ 회사 또는 그로부터 개인 정보를 제공받은 제3자는 개인 정보의
          수집 목적 또는 제공받은 목적을 달성한 때에는 당해 개인 정보를 지체
          없이 파기합니다.
          <br /> ⑧ 회원은 언제든지 회사가 가지고 있는 자신의 개인 정보에 대해
          열람 및 오류 정정을 요구할 수 있으며, 회사는 이에 대해 지체 없이
          필요한 조치를 취할 의무를 집니다. 회원이 오류의 정정을 요구한 경우에는
          회사는 그 오류를 정정할 때까지 당해 개인 정보를 이용하지 않습니다.
          <br /> ⑨ 회사는 개인 정보의 수집, 이용, 제공에 관한 동의란을 미리
          선택한 것으로 설정해두지 않습니다. 또한 개인 정보의 수집, 이용, 제공에
          관한 회원의 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수
          수집항목이 아닌 개인 정보의 수집, 이용, 제공에 관한 회원의 동의 거절을
          이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
        </p>

        <h3 className="mt-3 mb-3">제 21 조 (정보의 제공 및 광고의 게재)</h3>
        <p>
          ① 회사는 서비스 이용 중 필요하다고 인정되는 다양한 마케팅 정보 등을
          전자우편이나 서신 우편, SMS, 전화, 알림 메시지(Push Notification),
          회원 연락처와 연동된 SNS 서비스 등의 방법으로 회원에게 제공할 수
          있으며, 회원은 이에 동의합니다. 이 경우, 회원의 통신환경 또는 요금
          구조 등에 따라 회원이 데이터 요금 등을 부담할 수 있습니다.
          <br /> ② 회원은 관련 법에 따른 거래 관련 정보 및 고객 문의 등에 대한
          답변 등을 제외하고는 언제든지 전항의 전자우편 등에 대해서 수신 거절을
          할 수 있으며, 이 경우 회사는 즉시 전항의 마케팅 정보 등을 제공하는
          행위를 중단합니다.
        </p>

        <h3 className="mt-3 mb-3">제 22조 (회사의 의무)</h3>
        <p>
          ① 회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지
          않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 사이트를
          운영하는데 최선을 다하여야 합니다.
          <br /> ② 회사는 회원이 안전하게 인터넷 서비스를 이용할 수 있도록
          회원의 개인 정보(신용 정보 포함) 보호를 위한 보안 시스템을 갖추어야
          합니다.
          <br /> ③ 회사는 상품 등에 대하여 『표시·광고의 공정화에 관한 법률』
          소정의 부당한 표시·광고행위를 함으로써 회원이 손해를 입은 때에는 이를
          배상할 책임을 부담합니다.
          <br /> ④ 회사는 회원이 원하지 않는 영리목적의 광고성 전자 우편을
          발송하지 않습니다.
        </p>

        <h3 className="mt-3 mb-3">
          제 23 조 (회원의 아이디 및 비밀번호에 대한 관리 의무)
        </h3>
        <p>
          ① 아이디(이메일을 아이디로 사용하는 경우에는 이메일) 및 비밀번호의
          관리 책임은 회원에게 있습니다.
          <br /> ② 회원은 자신의 아이디 및 비밀번호를 제3자에게 이용하게 해서는
          안됩니다. 회사는 회사의 귀책사유 없이 발생한 아이디 및 비밀번호 공유
          또는 도용으로 인한 불이익에 대해 책임지지 않습니다. 또한, 아이디 및
          비밀번호 공유 중에 제3자가 회원 본인의 아이디를 이용하여 약관
          위반행위가 발생한다고 하더라도, 약관에 따라 회원 및 해당 아이디의
          서비스 이용이 제한될 수 있습니다.
          <br /> ③ 회원이 자신의 아이디 및 비밀번호를 도난당하거나 제3자가
          사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가
          있는 경우에는 그에 따라야 합니다.
        </p>

        <h3 className="mt-3 mb-3">제 24 조 (회원의 의무)</h3>
        <p>
          ① 회원은 다음 각 호의 행위를 하여서는 안됩니다.
          <br /> 1. 신청 또는 변경 시 허위 내용의 등록
          <br /> 2. 타인의 정보 도용
          <br /> 3. 부정한 방법으로 사이트에 게시된 정보의 변경
          <br /> 4. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신
          또는 게시
          <br /> 5. 회사 기타 제3자의 저작권 등 지적재산권에 대한 침해 및 명예를
          손상시키거나 업무를 방해하는 행위
          <br /> 6. 서비스 신청·이용 과정에서 다른 사람의 정보를 이용하거나
          허위의 정보를 제공하는 행위
          <br /> 7. 제3자의 아이디를 이용하거나 자신의 아이디를 제3자에게
          이용하게 하는 행위
          <br /> 8. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에
          반하는 정보를 몰에 공개 또는 게시하는 행위
          <br /> 9. 회원이 상품을 구입한 후 환불 요청 및 환불을 받은 상태로
          구매자에게서 상품을 제공받는 행위를 하는 행위
          <br /> 10. 회원이 구매자에서 상품을 제공받은 후, 환불 등의 사유를 들어
          회사에 적립금 지급을 요구하는 행위
          <br /> 11. 진정한 구매 의사 없이 공급자 할인 및 적립금 등을 적용하여
          상품 구매 후 재판매하기 위한 목적으로 서비스를 이용하는 행위
          <br /> 12. 오프라인이나 다른 사이트에서 발생한 구매에 대해 적립금 등을
          부당하게 확보할 목적으로 서비스를 이용하는 행위
          <br /> 13. 회사의 허락 없이 서비스를 이용하여 영업/광고 활동 등을
          하거나, 회사가 허락한 내용과 범위를 벗어난 영업/광고 활동 행위
          <br /> 14. 타인의 신용카드, 은행 계좌, 휴대폰 등을 무단으로 이용 또는
          도용하여 상품 등을 구매하는 행위
          <br /> 15. 회사 또는 타인의 명예를 손상시키거나 확인되지 않은 사실을
          유포하는 행위 타인의 권리를 침해하는 행위 및 회사의 업무 수행에 현저한
          지장을 초래하는 행위
          <br /> ② 회원은 회원의 정보가 변경된 경우에는 이를 회사에 즉시 알려야
          하며, 회원의 변경 정보를 회사에 알리지 않아 발생하는 불이익에 대해
          회사는 책임을 지지 않습니다.
          <br /> ③ 회원은 이 약관 기타 회사의 내부 규정 및 관계 법령을 준수해야
          합니다.
          <br /> ④ 회원은 이 약관에서 규정하는 사항 및 사이트 상의 공지사항 등
          회사가 정한 제반 정책이나 규정을 수시로 확인하여야 합니다.
        </p>

        <h3 className="mt-3 mb-3">제 25 조 (게시물의 관리)</h3>
        <p>
          ① “게시물”이라 함은 회원이 게시한 문자, 문서, 그림, 음성 또는 이들의
          조합으로 이루어진 정보 등의 모든 정보를 말합니다.
          <br /> ② 회사는 회원의 게시물을 소중하게 생각하며, 변조, 훼손,
          삭제되지 않도록 최선을 다하여 보호합니다. 다만, 회사는 다음 각 호에
          해당하는 게시물이나 자료를 사전통지 없이 삭제하거나 이동 또는 등록
          거부를 할 수 있습니다.
          <br /> 1. 다른 회원 또는 제3자에게 심한 모욕을 주거나 명예를
          손상시키는 내용인 경우
          <br />
          2. 회원이 음란물을 게재하거나 음란사이트를 링크한 경우
          <br /> 3. 회사 또는 제3자의 저작권 등 기타 지적재산권을 포함한 일체의
          권리를 침해하는 내용인 경우
          <br /> 4. 게시판의 성격에 부합하지 않는 게시물의 경우
          <br /> 5. 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크시키는
          경우
          <br /> 6. 영리를 목적으로 하는 광고성 내용인 경우
          <br /> 7. 범죄와 결부된다고 객관적으로 인정되는 내용일 경우
          <br /> 8. 회사에서 규정한 게시물 원칙에 어긋나거나 게시판 성격에
          부합하지 않는 경우
          <br /> 9. 회사나 서비스의 정상적인 운영을 방해하는 경우
          <br /> 10. 기타 관계법령에 위배된다고 판단되는 경우
          <br /> ③ 사이트의 게시물로 인하여 법률상 이익이 침해된 자는 관련
          법령과 고객센터에서 정한 절차에 따라 당해 게시물의 게시중단 및 삭제
          등을 요청할 수 있으며, 회사는 관련 법령에 따라 필요한 조치를 취하여야
          합니다.
          <br /> ④ 회원은 사이트를 탈퇴한 이후에는 기존에 작성한 게시물의
          편집이나 삭제가 불가하므로, 탈퇴 이전에 해당 게시물에 대한 조치를
          취하여야 합니다.
          <br /> ⑤ 회원의 게시물로 인한 법률상 이익 침해를 근거로 다른 회원 또는
          제3자가 회원 또는 회사를 대상으로 하여 민형사상의 법적 조치(예: 고소,
          가처분 신청, 손해배상청구 소송)를 취하는 동시에 법적 조치와 관련된
          게시물의 삭제를 요청해오는 경우, 회사는 동 법적 조치의 결과(예: 검찰의
          기소, 법원의 가처분 결정, 손해배상 판결)가 있을 때까지 관련 게시물에
          대한 접근을 잠정적으로 제한할 수 있습니다.
          <br /> ⑥ 제2항 각호로 인하여 발생한 각종 손해 및 관계 기관에 의한 법적
          조치에 따르는 책임은 해당 회원 본인이 부담합니다.
          <br /> ⑦ 회원의 게시물로 인하여 제3자의 회사에 대한 소송, 형사고소
          기타 일체의 분쟁이 발생한 경우 회원은 그 해결에 소요되는 비용을
          부담하고 회사를 위하여 분쟁을 처리하여야 하며, 회사가 제3 자에게
          배상하거나 회사에 손해가 발생한 경우 해당 회원은 회사에 이를
          배상하여야 합니다.
        </p>

        <h3 className="mt-3 mb-3">제 26 조 (저작권의 귀속 및 이용 제한)</h3>
        <p>
          ① 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에
          귀속합니다. <br /> ② 회원은 사이트를 이용함으로써 얻은 정보 중 회사에
          지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판,
          배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게
          이용하게 하여서는 안됩니다. <br /> ③ 회원이 사이트에 등록한 각종
          게시물의 저작권은 회원에게 귀속되며, 회원은 회사에 회원의 게시물을
          무상, 비독점적으로 사용(복제, 배포, 전송, 전시 및 이러한 권리에 대한
          재사용) 할 권리를 부여합니다.
        </p>

        <h3 className="mt-3 mb-3">제 27 조 (손해배상)</h3>
        <p>
          회사 또는 회원이 관련 법령 및 본 약관 등을 위반하여 상대방에게 손해가
          발생하는 경우 귀책 당사자는 해당 손해를 배상해야 합니다.
        </p>

        <h3 className="mt-3 mb-3">제 28 조 (분쟁 해결)</h3>
        <p>
          ① 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를
          보상처리하기 위하여 피해 보상 처리 기구를 설치·운영합니다.
          <br /> ② 회사는 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그
          사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그
          사유와 처리 일정을 즉시 통보해 줍니다.
          <br /> ③ 회사와 회원 간에 발생한 전자상거래 분쟁과 관련하여 회원의
          피해 구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는
          분쟁 조정 기관의 조정에 따를 수 있습니다.
        </p>

        <h3 className="mt-3 mb-3">제 29 조 (면책)</h3>
        <p>
          ① 회사는 전시, 사변, 천재지변, 국가비상사태, 해결이 곤란한 기술적 결함
          기타 불가항력적 사유로 서비스를 제공할 수 없는 경우에는 책임이
          면제됩니다.
          <br /> ② 회사는 회원의 귀책사유로 인한 서비스의 중지/이용 장애에
          대하여 책임을 지지 않습니다.
          <br /> ③ 회사는 기간 통신 사업자가 전기통신서비스를 중지하거나
          정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에는 책임이
          면제됩니다.
          <br /> ④ 회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검,
          공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에
          대해서는 책임이 면제됩니다.
          <br /> ⑤ 회사는 회원이 사이트에 게시 또는 전송한 정보, 자료, 사실의
          신뢰도, 정확성 등 내용에 대해서는 책임을 지지 않습니다.
          <br /> ⑥ 회사는 회원 상호 간 또는 회원과 제3자 간에 서비스를 매개로
          발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도
          없습니다.
        </p>

        <h3 className="mt-3 mb-3">제 30 조 (재판관할 및 준거법)</h3>
        <p>
          ① 회사와 회원 간에 발생한 전자거래 분쟁에 관한 소송은 제소 당시의
          회원의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의
          전속관할로 합니다. 다만, 제소 당시 회원의 주소 또는 거소가 분명하지
          않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
          <br /> ② 회사와 회원 간에 제기된 전자거래 분쟁에 관한 소송에는 한국
          법을 적용합니다.
        </p>
        <br />
        <h3 className="mt-3 mb-3">부 칙</h3>
        <p>
          제1조 (시행일)
          <br /> 이 약관은 2019년 6월 1일부터 시행합니다.
        </p>
      </section>
    );
  }
}

export default withUi(ServiceAgreement);
