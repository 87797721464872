import React, { Component, Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import withUi from 'lib/hoc/withUi';

import SignInHelpTab from 'components/users/SignInHelpTab';
import SignInHelpIdContainer from './SignInHelpIdContainer';
import SignInHelpPasswordContainer from './SignInHelpPasswordContainer';

class SignInHelpContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCheckedId: this.getTabType() === 'id',
      isCheckedPassword: this.getTabType() === 'password'
    };
  }

  componentDidMount = () => {
    this.initialize();
  };

  componentDidUpdate = (prevProps) => {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;

    if (location.pathname !== prevLocation.pathname) {
      this.setTab();
    }
  };

  getTabType = () => {
    const { location } = this.props;

    if (!location) {
      return;
    }

    const { pathname } = location;
    const tabType = pathname.split('/');
    const lastIndex = tabType.length > 0 ? tabType.length - 1 : -1;

    return lastIndex > -1 ? tabType[lastIndex] : '';
  };

  setTab = () => {
    const tabType = this.getTabType();

    this.setState({
      isCheckedId: tabType === 'id',
      isCheckedPassword: tabType === 'password'
    });
  };

  initialize = () => {
    const { UiAction } = this.props;

    UiAction.setHeader('아이디 / 비밀번호 찾기');
  };

  handleBackSignIn = () => {
    const { history } = this.props;

    history.push('/store/signin');
  };

  render() {
    const { isCheckedId, isCheckedPassword } = this.state;

    return (
      <Fragment>
        <SignInHelpTab
          isCheckedId={isCheckedId}
          isCheckedPassword={isCheckedPassword}
        />
        <Route
          path="/store/signin/help/id"
          render={() => (
            <SignInHelpIdContainer onGotoSignIn={this.handleBackSignIn} />
          )}
        />
        <Route
          path="/store/signin/help/password"
          render={() => (
            <SignInHelpPasswordContainer onGotoSignIn={this.handleBackSignIn} />
          )}
        />
      </Fragment>
    );
  }
}

export default withRouter(withUi(SignInHelpContainer));
