import React, { Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ImageInputWithPreview from './ImageInput/ImageInputWithPreview';

import {
  FormControlGrid,
  StyledInput,
  StyledSelect,
  StyledCheckBox,
  StyledRadioButtons,
  StyledTextArea,
  StyledLabel,
  StyledError,
  StyledButton,
  ControlWrapper,
  StyledInputButton
} from './styled/componentStyle';

export function Form({
  className,
  resolver,
  defaultValues,
  onSubmit,
  children
}) {
  const methods = useForm({ defaultValues, resolver, mode: 'onBlur' });
  const { handleSubmit } = methods;

  // console.log({ children });
  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      {Array.isArray(children)
        ? children.map((child) => {
            return child.props.name
              ? React.createElement(child.type, {
                  ...{
                    ...child.props,
                    register: methods.register,
                    control: methods.control,
                    errors: methods.errors,
                    key: child.props.name
                  }
                })
              : child;
          })
        : children}
    </form>
  );
}

export function Input({
  register,
  errors,
  rules,
  name,
  label,
  buttonLabel,
  onButtonClick,
  className,
  ...rest
}) {
  // console.log({ name });
  // console.log({ rules });
  return (
    <FormControlGrid className={className}>
      <StyledLabel htmlFor={name} className={className}>
        {label}
      </StyledLabel>
      <StyledInput
        id={name}
        name={name}
        className={className}
        ref={register(rules)}
        {...rest}
      />
      {buttonLabel && (
        <StyledInputButton
          type="button"
          onClick={onButtonClick}
          className={className}
        >
          {buttonLabel}
        </StyledInputButton>
      )}

      <ErrorMessage errors={errors} name={name} as={StyledError} />
    </FormControlGrid>
  );
}

export function TextArea({
  register,
  errors,
  rules,
  name,
  label,
  children,
  className,
  ...rest
}) {
  // console.log({rest})
  return (
    <FormControlGrid className={className}>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <StyledTextArea id={name} name={name} ref={register(rules)} {...rest} />
      {children}
      <ErrorMessage errors={errors} name={name} as={StyledError} />
    </FormControlGrid>
  );
}
export function CheckBox({
  register,
  errors,
  rules,
  name,
  label,
  buttonLabel,
  onButtonClick,
  className,
  ...rest
}) {
  return (
    <FormControlGrid className={`${className} checkbox`}>
      <StyledLabel htmlFor={name} className={className}>
        {label}
      </StyledLabel>
      <StyledCheckBox
        id={name}
        name={name}
        defaultChecked
        className={className}
        ref={register(rules)}
        {...rest}
      />
      {buttonLabel && (
        <StyledInputButton type="button" onClick={onButtonClick}>
          {buttonLabel}
        </StyledInputButton>
      )}
      <ErrorMessage errors={errors} name={name} as={StyledError} />
    </FormControlGrid>
  );
}
export function RadioButtons({
  register,
  errors,
  rules,
  name,
  label,
  options,
  value,
  className,
  ...rest
}) {
  // console.log({value})
  //   console.log(Object.entries(options));
  return (
    <FormControlGrid className={className}>
      <StyledLabel className={className}>{label}</StyledLabel>
      <StyledRadioButtons>
        {Object.entries(options).map((option) => (
          <Fragment key={option[0]}>
            <label
              htmlFor={option[0]}
              className={option[0] === value ? 'checked' : ''}
            >
              {option[1]}
              <input
                type="radio"
                name={name}
                id={option[0]}
                value={option[0]}
                defaultChecked={option[0] === value}
                // label={option[1]}
                ref={register(rules)}
                {...rest}
              />
            </label>
          </Fragment>
        ))}
      </StyledRadioButtons>
      <ErrorMessage errors={errors} name={name} as={StyledError} />
    </FormControlGrid>
  );
}

export function Select({
  register,
  errors,
  rules,
  name,
  label,
  options,
  className,
  ...rest
}) {
  //   console.log(Object.entries(options));
  return (
    <FormControlGrid className={className}>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <StyledSelect id={name} name={name} ref={register(rules)} {...rest}>
        {Object.entries(options).map((option, index) => (
          <option
            key={option[1]}
            value={option[0]}
            disabled={index === 0 && rules && rules.required}
          >
            {option[1]}
          </option>
        ))}
      </StyledSelect>
      <ErrorMessage errors={errors} name={name} as={StyledError} />
    </FormControlGrid>
  );
}

export function DatePicker({
  control,
  errors,
  rules,
  name,
  label,
  className,
  ...rest
}) {
  return (
    <FormControlGrid className={className}>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ onChange, onBlur, value }) => (
          <ReactDatePicker
            id={name}
            placeholderText={rest ? rest.placeholder : ''}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
            {...rest}
          />
        )}
      />
      <ErrorMessage errors={errors} name={name} as={StyledError} />
    </FormControlGrid>
  );
}

export function ImageInput({ name, label, imageUrls, className, ...rest }) {
  // console.log({ rest });

  return (
    <FormControlGrid className={className}>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <ControlWrapper>
        <ImageInputWithPreview name={name} images={imageUrls} {...rest} />
      </ControlWrapper>
    </FormControlGrid>
  );
}

export function SubmitButton({ isSubmitting = false, children, ...rest }) {
  return (
    <StyledButton type="submit" disabled={isSubmitting} {...rest}>
      {isSubmitting ? (
        <img
          className="loading"
          src="/images/button-loader.svg"
          alt="Submitting ..."
        />
      ) : (
        children
      )}
    </StyledButton>
  );
}

export function CancelButton({ isSubmitting = false, children }) {
  return (
    <StyledButton type="button" disabled={isSubmitting}>
      {children}
    </StyledButton>
  );
}
