import React from 'react';
import classNames from 'classnames';
// import Button from 'components/commons/Button';
import './SearchTagBar.scss';

function SearchList({ tagList, onClickTag, keyword }) {
  const isHaveHash = keyword.charAt(0) === '#';
  let splitKeyword = '';

  if (isHaveHash) {
    splitKeyword = keyword.substring(1, keyword.length);
  }

  return (
    <div className="from-search-tag-bar">
      {tagList.map((tagel) => {
        return (
          //   <Button
          //     key={`${tagel}`}
          //     buttonText={tagel}
          //     buttonType="button"
          //     className={`search-tag-${index}`}
          //     onClick={() => {
          //       onClickTag(tagel);
          //     }}
          //   />
          <button
            key={`${tagel}`}
            onClick={() => {
              onClickTag(tagel);
            }}
            className={classNames('from-search-tag', {
              'tag-active': splitKeyword === tagel
            })}
          >
            {`#${tagel}`}
          </button>
        );
      })}
    </div>
  );
}

export default SearchList;
