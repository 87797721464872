import React from 'react';
import PropTypes from 'prop-types';

import ModalDialog from 'components/modal/ModalDialog';
import Button from 'components/commons/Button';

// import CDN_URL from 'components/commons/globalUrl';
// import classNames from 'classnames';

// import CommonModal from 'components/modal/CommonModal';

import './DiscoverModal.scss';

function DiscoverModal({ isOpen, onToggle, onRedirect }) {
  const contentText = '“당신이 바로 프롬의 발굴자!“';
  const contentText2 = `프롬마켓의 상품들은\n소비자가 직접 발굴한 상품입니다.
  \n내가 응원하는 상품이\n프롬에 발굴되는 기쁨을 느껴보세요!
  \n여러분이 응원하는 상품이 있나요?\n프롬에 발굴해 주세요!`;

  return (
    <ModalDialog
      size="sm"
      isOpen={isOpen}
      onToggle={onToggle}
      className="discover-modal-2 text-center"
    >
      <img
        // src={`/images/sulnal-modal-bok-icon.png`}
        src={`/images/discover-modal-header-icon.svg`}
        alt="공지아이콘"
        className="modal-center-top-icon"
      />
      <div className="d-flex align-items-center mt-1">
        <div className="decorate-img-wrapper">
          <div
            className="decorate-img"
            style={{
              backgroundImage: `url("/images/gift-decorate-star-icon.svg")`
            }}
          />
        </div>
        <div
          className="center-circle-image"
          style={{
            backgroundImage: `url("/images/discover-modal-crop-image.png")`
          }}
        />
        <div className="decorate-img-wrapper">
          <div
            className="decorate-img"
            style={{
              backgroundImage: `url("/images/gift-decorate-star-icon.svg")`
            }}
          />
        </div>
      </div>

      {contentText && (
        <p className="text-center modal-body-text">
          {contentText.split('\n').map((line) => {
            return (
              <span key={line}>
                {line}
                <br />
              </span>
            );
          })}
        </p>
      )}
      {contentText2 && (
        <p className="text-center modal-body-text2">
          {contentText2.split('\n').map((line) => {
            return (
              <span key={line}>
                {line}
                <br />
              </span>
            );
          })}
        </p>
      )}
      <div className="text-center mt-3 col-button-group">
        <Button
          className="btn"
          onClick={onRedirect}
          color="from"
          buttonText="발굴신청"
        />
        <Button
          className="btn btn-close mt-2"
          onClick={onToggle}
          color="none"
          buttonText="닫기"
        />
      </div>
    </ModalDialog>
  );
}

DiscoverModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func
};

DiscoverModal.defaultProps = {
  isOpen: false,
  onToggle: () => {}
};

export default DiscoverModal;
