/* eslint-disable no-console */
import React, { Fragment } from 'react';
import DompurifyHTML from 'components/commons/DompurifyHTML';
import Button from 'components/commons/Button';
import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';

import { MainSectionWrapper } from 'styles/AppStyle';
function ProductDetail({ storeId, productId, product, isLoading }) {
  const { flagHtmlProductInfo, htmlProductInfo, providerId } = product || {};

  // console.log({storeId});
  // console.log({productId})

  return (
    <MainSectionWrapper className="one-product product-detail">
      <ScrollToTopOnMount />
      {flagHtmlProductInfo && htmlProductInfo && (
        <DompurifyHTML innerHtml={htmlProductInfo} />
      )}
      <div className="best-product-button one-product-button">
        <Button
          color="w-from"
          buttonText="공급자에게 문의하기"
          buttonType="link"
          to={`/provider/${providerId}`}
        />
      </div>
    </MainSectionWrapper>
  );
}

export default ProductDetail;
