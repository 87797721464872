import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import SignUpProviderContainer from 'containers/provider/SignUpProviderContainer';

function ProviderSignup() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <SignUpProviderContainer />
    </Fragment>
  );
}

export default ProviderSignup;
