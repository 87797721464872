import React, { Fragment } from 'react';

import ScrollToTopOnMount from 'components/commons/ScrollToTopOnMount';
import ProviderOneStoreContainer from 'containers/provider/ProviderOneStoreContainer';

function ProviderOneStoreList({ match }) {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <ProviderOneStoreContainer providerId={match.params.providerId} />
    </Fragment>
  );
}

export default ProviderOneStoreList;
